import { useUnmount } from 'react-use';
import { QueryFunctionContext, QueryKey, useQuery, useQueryClient, UseQueryOptions } from '@tanstack/react-query';
import { compileApiUrl } from '@egym/api';

export const useEventSourceQuery = <D>(
  queryKey: QueryKey,
  url: string,
  options?: Omit<UseQueryOptions<any, any, D>, 'queryKey' | 'queryFn'> & {
    urlPrefix?: string;
    urlParams?: Record<string, any>;
  },
) => {
  const apiUrl = compileApiUrl(url, options?.urlParams, options?.urlPrefix);
  const queryClient = useQueryClient();

  const fetchData = ({ signal }: QueryFunctionContext) => {
    return new Promise((resolve, reject) => {
      const eventSource = new EventSource(apiUrl, { withCredentials: true });
      signal?.addEventListener('abort', () => {
        eventSource.close();
        reject();
      });
      eventSource.onmessage = event => {
        const eventData = event.data && JSON.parse(event.data);

        if (eventData.status === 'IN_PROGRESS') {
          queryClient.setQueryData(queryKey, eventData);
        }
        if (eventData.status === 'COMPLETED') {
          eventSource.close();
          resolve(eventData);
        }
      };
      eventSource.onerror = () => {
        eventSource.close();
        reject();
      };
    });
  };

  useUnmount(async () => {
    await queryClient.cancelQueries(queryKey, { exact: true });
  });
  return useQuery(queryKey, fetchData, { enabled: false, refetchOnMount: false, ...options });
};
