import React, { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, Typography } from '@mui/material';
import { Field } from 'formik';
import { Option } from '@egym/types';
import { CheckboxFormField, SelectFormField, Stack } from '@egym/ui';
import { AppPreviewerPalette, PreviewerState, WidgetPreview } from '@components';
import { MoveWidgetsFormValues } from '@types';

type Props = {
  currentWidgets: MoveWidgetsFormValues['currentWidgets'];
  previewerPalette: AppPreviewerPalette;
  previewerState: Partial<PreviewerState>;
  tabsOptions: Option[];
  onlyCoreFeatures?: boolean;
};

const MoveWidgetsFormFields: FC<Props> = ({
  currentWidgets,
  previewerPalette,
  previewerState,
  tabsOptions,
  onlyCoreFeatures = false,
}) => {
  const { t } = useTranslation();

  return (
    <Stack
      sx={{ m: 0, width: '100%' }}
      spacing={6}
      divider={<Divider orientation="horizontal" variant="fullWidth" flexItem />}
    >
      {currentWidgets.map((currentWidget, index) => {
        return (
          <Stack direction="row" key={currentWidget.props.id}>
            <WidgetPreview
              wrapperSx={{ minWidth: '305px', flex: 1, mr: 6 }}
              widget={currentWidget}
              previewerPalette={previewerPalette}
              previewerState={previewerState}
            />
            <Stack sx={{ maxWidth: '305px', width: '50%', mt: 5 }}>
              <Field
                name={`currentWidgets.${index}.newTabId`}
                options={tabsOptions}
                component={SelectFormField}
                label={t('common.buttons.moveToTheTab')}
                isViewMode={false}
                disabled={currentWidget.shouldBeRemoved}
                SelectProps={{
                  MenuProps: {
                    PaperProps: {
                      sx: { maxWidth: '305px' },
                    },
                  },
                }}
              />
              {currentWidget.canBeRemoved && (
                <Field
                  id={`currentWidgets.${index}.shouldBeRemoved`}
                  name={`currentWidgets.${index}.shouldBeRemoved`}
                  component={CheckboxFormField}
                  label="common.buttons.removeFeature"
                  wrapperSx={{ pt: 1, ml: '-9px' }}
                />
              )}
              {!currentWidget.canBeRemoved && !onlyCoreFeatures && (
                <Typography sx={{ mt: 3 }} variant="caption">
                  {t('appDesign.labels.coreFeaturesCantBeRemoved') as ReactNode}
                </Typography>
              )}
            </Stack>
          </Stack>
        );
      })}
    </Stack>
  );
};

export default MoveWidgetsFormFields;
