import { Components } from '@mui/material/styles';

const MuiOutlinedInput: Components['MuiOutlinedInput'] = {
  styleOverrides: {
    input: {
      padding: '12.5px 14px !important',
    },
    multiline: {
      padding: '0px !important',
    },
  },
};

export default MuiOutlinedInput;
