import { alpha } from '@mui/material';
import { AppPreviewerPalette } from '@components';
import { ColorThemeEnum } from '@types';
import { colorThemeColors } from './constants';

export const getPreviewerPalette = (colorTheme, customColors): AppPreviewerPalette => {
  if (colorTheme === ColorThemeEnum.CUSTOM) {
    return customColors;
  }

  const predefinedColors = colorTheme ? colorThemeColors[colorTheme] : colorThemeColors[ColorThemeEnum.ORANGE_ACCENT];

  return {
    ...predefinedColors,
    dashboard3BackgroundPrimary: customColors.dashboard3BackgroundPrimary,
    dashboard3BackgroundSecondary: customColors.dashboard3BackgroundSecondary,
  };
};

export const getPresetColors = (palette, opacity = 1, extraColor = { color: 'rgba(0, 0, 0, 1)', title: 'black' }) => {
  if (!palette) return [];

  return [{ color: palette.primary, title: 'primary' }, { color: palette.secondary, title: 'secondary' }, extraColor]
    .filter(color => color.color)
    .map(color => ({ ...color, color: alpha(color.color, opacity) }));
};
