import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { SystemStyleObject } from '@mui/system/styleFunctionSx/styleFunctionSx';
import { Button, Icons, Popover, Stack, Typography } from '@egym/ui';
import { PredefinedTabIcon } from '@types';
import PredefinedIconsLibraryPopoverContent from './components/PredefinedIconsLibraryPopoverContent';

type Props = {
  onSelectIcon: (icon: PredefinedTabIcon) => void;
  icons: PredefinedTabIcon[];
  predefinedIconId?: number | null;
  hintText?: string;
  buttonText?: string;
  wrapperSx?: SystemStyleObject;
};

const PredefinedIconsLibraryPopover: React.FC<Props> = ({
  onSelectIcon,
  predefinedIconId,
  icons,
  hintText = 'appDesign.labels.doNotHaveAnIcon',
  buttonText = 'common.buttons.addFromLibrary',
  wrapperSx,
}) => {
  const { t } = useTranslation();

  return (
    <Stack spacing={3} sx={wrapperSx}>
      <Typography variant="body1" sx={{ color: 'grey.700' }}>
        {t(hintText) as ReactNode}
      </Typography>
      <Popover
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Button
          startIcon={<Icons.Add />}
          text={buttonText}
          color="inherit"
          variant="text"
          sx={{ p: 0, textTransform: 'uppercase' }}
        />
        <PredefinedIconsLibraryPopoverContent
          icons={icons}
          predefinedIconId={predefinedIconId}
          onSelectIcon={onSelectIcon}
        />
      </Popover>
    </Stack>
  );
};

export default PredefinedIconsLibraryPopover;
