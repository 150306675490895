import React from 'react';
import { MorningIcon, AfternoonIcon, EveningIcon } from '../../../Icons';

export const mockActivityHistoryV2 = [
  {
    id: 0,
    icon: <MorningIcon htmlColor="rgb(150, 150, 150)" />,
    name: 'appPreviewer.labels.morningWorkout',
    activityPoints: 27,
    breadcrumbs: [
      {
        text: 'appPreviewer.labels.exercisesCount',
        params: { count: 4 },
      },
      {
        text: 'appPreviewer.labels.calCount',
        params: { count: 27 },
      },
    ],
  },
  {
    id: 1,
    icon: <AfternoonIcon htmlColor="rgb(150, 150, 150)" />,
    name: 'appPreviewer.labels.afternoonWorkout',
    activityPoints: 53,
    breadcrumbs: [
      {
        text: 'appPreviewer.labels.exercisesCount',
        params: { count: 9 },
      },
      {
        text: 'appPreviewer.labels.calCount',
        params: { count: 53 },
      },
    ],
  },
  {
    id: 2,
    icon: <EveningIcon htmlColor="rgb(150, 150, 150)" />,
    name: 'appPreviewer.labels.eveningWorkout',
    activityPoints: 44,
    breadcrumbs: [
      {
        text: 'appPreviewer.labels.exercisesCount',
        params: { count: 7 },
      },
      {
        text: 'appPreviewer.labels.calCount',
        params: { count: 44 },
      },
    ],
  },
];
