import { Uuid } from '@egym/types';
import { useRouter } from '@egym/ui';
import { useClientApplicationDesign } from '@hooks';

const useClientApplicationDesignByRouter = () => {
  const { urlParams } = useRouter<{ uuid: Uuid }>();

  return useClientApplicationDesign(urlParams.uuid);
};

export default useClientApplicationDesignByRouter;
