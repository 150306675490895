import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const ConnectedAppsIcon: React.FC<SvgIconProps> = ({ sx, htmlColor = '#4C4A4A', ...rest }) => {
  return (
    <SvgIcon viewBox="0 0 24 24" sx={{ width: '24px', height: '24px', ...sx }} {...rest}>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <g>
                <path
                  d="M0 0L24 0 24 24 0 24z"
                  transform="translate(-24 -438) translate(0 418) translate(20 16) translate(4 4)"
                />
                <path
                  fill={htmlColor}
                  d="M10 13c.553 0 1 .447 1 1v5c0 1.654-1.346 3-3 3H5c-1.654 0-3-1.346-3-3v-3c0-1.654 1.346-3 3-3zm9 0c1.654 0 3 1.346 3 3v3c0 1.654-1.346 3-3 3h-3c-1.654 0-3-1.346-3-3v-5c0-.553.447-1 1-1zM9 15H5c-.552 0-1 .448-1 1v3c0 .552.448 1 1 1h3c.552 0 1-.448 1-1v-4zm10 0h-4v4c0 .552.448 1 1 1h3c.552 0 1-.448 1-1v-3c0-.552-.448-1-1-1zM8 2c1.654 0 3 1.346 3 3v5c0 .553-.447 1-1 1H5c-1.654 0-3-1.346-3-3V5c0-1.654 1.346-3 3-3zm11 0c1.654 0 3 1.346 3 3v3c0 1.654-1.346 3-3 3h-5c-.553 0-1-.447-1-1V5c0-1.654 1.346-3 3-3zM8 4H5c-.552 0-1 .448-1 1v3c0 .552.448 1 1 1h4V5c0-.552-.448-1-1-1zm11 0h-3c-.552 0-1 .448-1 1v4h4c.552 0 1-.448 1-1V5c0-.552-.448-1-1-1z"
                  transform="translate(-24 -438) translate(0 418) translate(20 16) translate(4 4)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export default ConnectedAppsIcon;
