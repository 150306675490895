import React, { FC } from 'react';

type Props = {
  assetColor?: string;
};

const Overlay: FC<Props> = ({ assetColor }) => {
  return assetColor ? (
    <div
      style={{
        position: 'absolute',
        background: assetColor,
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
      }}
    />
  ) : null;
};

export default Overlay;
