import { FC } from 'react';
import { CustomPickerProps } from 'react-color';
import { Stack } from '@mui/material';
import { SxProps } from '@mui/system';
import { Hue as ReactColorHue } from 'react-color/lib/components/common';
import ReactColorSlider from 'react-color/lib/components/slider/Slider';
import styles from './Slider.module.scss';

type Props = { wrapperSx?: SxProps } & CustomPickerProps<ReactColorHue>;

const Slider: FC<Props> = ({ wrapperSx, ...rest }) => {
  return (
    <Stack className={styles.wrapper} sx={{ width: '100%', ...wrapperSx }}>
      <ReactColorSlider {...rest} />
    </Stack>
  );
};

export default Slider;
