import React from 'react';
import { Navigate, RouteObject } from 'react-router';
import { pageUrls } from '@constants';
import { AuthTokenInfo } from '@types';
import { PrivateRouteObject } from '../defaultAppPages';

export const protectAppPages =
  (isParentProtected: boolean, tokenInfo?: AuthTokenInfo) => (pages: PrivateRouteObject[]) => {
    return pages
      .map(page => {
        if (page.path?.startsWith('login') && tokenInfo) {
          return {
            ...page,
            element: <Navigate to="/" />,
          };
        }
        return page;
      })
      .map(page => {
        if ((isParentProtected || !!page.isProtected) && !tokenInfo) {
          return {
            ...page,
            element: <Navigate to={pageUrls.login} />,
          };
        }
        return page;
      })
      .map(page => {
        if (page.role && !tokenInfo?.roles?.find(role => role === page.role)) {
          return {
            ...page,
            element: <Navigate to={pageUrls.login} />,
          };
        }
        return page;
      })
      .map(page => {
        if (page.children) {
          return {
            ...page,
            children: protectAppPages(isParentProtected || !!page.isProtected, tokenInfo)(page.children),
          };
        }
        return page;
      });
  };

export const getPagesFullPath = (pageConfig: RouteObject, parentPath?: string): string[] => {
  if (pageConfig.children) {
    return pageConfig.children.flatMap(child =>
      getPagesFullPath(
        child,
        parentPath ? `${parentPath}/${pageConfig.path}`.replace('//', '/') : String(pageConfig.path),
      ),
    );
  }
  return parentPath ? [`${parentPath}/${pageConfig.path}`.replace('//', '/')] : [String(pageConfig.path)];
};
