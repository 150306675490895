import React from 'react';
import { SxProps } from '@mui/system';
import { EditableInput as ReactColorEditableInput } from 'react-color/lib/components/common';
import { EditableInputProps } from 'react-color/lib/components/common/EditableInput';
import { Stack } from '@egym/ui';
import { isValidHex } from '../../helpers';

const styles = {
  wrap: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  label: {
    fontSize: '12px',
    letterSpacing: '-0.2px',
    color: '#666',
    textTransform: 'capitalize',
    lineHeight: '20px',
    marginTop: '1px',
  },
  input: {
    fontSize: '12px',
    height: '20px',
    lineHeight: '20px',
    border: '1px solid #ccc',
    padding: '4px 10% 3px',
    boxSizing: 'border-box',
    width: '100%',
  },
};

type Props = { wrapperSx?: SxProps } & EditableInputProps;

const EditableInput: React.FC<Props> = ({ wrapperSx, ...rest }) => {
  // https://github.com/casesandberg/react-color/blob/master/src/components/sketch/SketchFields.js
  const handleChange = (data, e) => {
    if (!rest.onChange) return;

    if (data.hex) {
      if (isValidHex(data.hex)) {
        // @ts-ignore
        rest.onChange(
          {
            hex: data.hex,
            // @ts-ignore
            source: 'hex',
          },
          e,
        );
      }
    } else if (data.r || data.g || data.b) {
      rest.onChange(
        {
          // @ts-ignore
          r: data.r || rest.rgb.r,
          // @ts-ignore
          g: data.g || rest.rgb.g,
          // @ts-ignore
          b: data.b || rest.rgb.b,
          // @ts-ignore
          a: rest.rgb.a,
          source: 'rgb',
        },
        e,
      );
    } else if (data.a) {
      let dataAlpha = data.a;

      if (data.a < 0) {
        dataAlpha = 0;
      } else if (data.a > 100) {
        dataAlpha = 100;
      }

      dataAlpha /= 100;

      rest.onChange(
        {
          // @ts-ignore
          h: rest.hsl.h,
          // @ts-ignore
          s: rest.hsl.s,
          // @ts-ignore
          l: rest.hsl.l,
          // @ts-ignore
          a: dataAlpha,
          source: 'rgb',
        },
        e,
      );
    }
  };

  return (
    <Stack sx={{ position: 'relative', ...wrapperSx }}>
      <ReactColorEditableInput
        // @ts-ignore
        style={styles}
        {...rest}
        onChange={handleChange}
      />
    </Stack>
  );
};

export default EditableInput;
