import { Components } from '@mui/material/styles';

const MuiAlert: Components['MuiAlert'] = {
  styleOverrides: {
    icon: {
      alignItems: 'center',
    },
  },
};

export default MuiAlert;
