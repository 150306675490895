export enum AnalyticsEvents {
  // Welcome screen
  A_Get_Started = 'A_Get_Started',

  // Dashboard
  A_Interface_Language = 'A_Interface_Language',
  A_Interface_Language_Selected = 'A_Interface_Language_Selected',
  A_Design_Form = 'A_Design_Form',
  A_Settings_Form = 'A_Settings_Form',

  // Form sent for review
  A_Sent_For_Review = 'A_Sent_For_Review',
  A_Unsent_For_Review = 'A_Unsent_For_Review',

  // App design
  A_Design_Form_Saved = 'A_Design_Form_Saved',
  A_Design_Menu_Item_Selected = 'A_Design_Menu_Item_Selected',

  // App settings
  A_Settings_Form_Saved = 'A_Settings_Form_Saved',
  A_Settings_Menu_Item_Selected = 'A_Settings_Menu_Item_Selected',

  // App Languages
  A_Add_App_Language = 'A_Add_App_Language',
  A_Add_App_Language_Selected = 'A_Add_App_Language_Selected',
  A_App_Language_Deleted = 'A_App_Language_Deleted',
  A_Primary_Language_Selected = 'A_Primary_Language_Selected',
  A_App_Layout_Selected = 'A_App_Layout_Selected',

  // App Previewer
  A_Previewer_Language_Selected = 'A_Previewer_Language_Selected',

  // hz
  A_Design_First_Review_Clicked = 'A_Design_First_Review_Clicked',
  A_Design_Share_Preview_Clicked = 'A_Design_Share_Preview_Clicked',
  A_Design_Go_Back_Clicked = 'A_Design_Go_Back_Clicked',
  A_Settings_Go_Back_Clicked = 'A_Settings_Go_Back_Clicked',
}
