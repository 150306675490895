import React, { PropsWithChildren } from 'react';
import { SxProps } from '@mui/system';
import { Stack } from '@egym/ui';

type Props = {
  wrapperSx?: SxProps;
  bgColor?: string;
} & PropsWithChildren;

const IconWrapper: React.FC<Props> = ({ children, wrapperSx, bgColor }) => {
  return (
    <Stack
      sx={{
        width: '32px',
        height: '32px',
        background: bgColor || 'rgba(255, 255, 255, 0.1)',
        borderRadius: '8px',
        ...wrapperSx,
      }}
      alignItems="center"
      justifyContent="center"
    >
      {children}
    </Stack>
  );
};

export default IconWrapper;
