import React, { ReactNode } from 'react';
import { SxProps } from '@mui/system';
import { differenceInCalendarDays } from 'date-fns';
import { Box, Paper, Stack, useFormatDate } from '@egym/ui';
import { AppLayoutFeatureComponentProps } from '../../../../AppPreviewerProps';
import IconWrapper from '../../../Icons/IconWrapper';
import { StyledBreadcrumbs } from './PreviewDealItem.styles';

type Props = AppLayoutFeatureComponentProps & {
  image: string;
  name: string;
  startDate: Date;
  endDate: Date;
  amountDiscount: number;
  unlimitedUse: boolean;
  wrapperSx?: SxProps;
};

const PreviewDealItem: React.FC<Props> = ({
  tFunction,
  previewerState,
  name,
  image,
  startDate,
  endDate,
  amountDiscount,
  unlimitedUse,
  wrapperSx,
}) => {
  const { format } = useFormatDate(previewerState.selectedLocale);

  return (
    <Paper
      sx={{
        position: 'relative',
        overflow: 'hidden',
        borderRadius: '8px',
        boxShadow: '0 4px 6px rgba(223, 223, 223, 0.7)',
        ...wrapperSx,
      }}
    >
      <Box sx={{ height: '110px', width: '100%', position: 'relative' }}>
        <Box component="img" sx={{ height: '100%', width: '100%', objectFit: 'cover' }} src={image} />
        <span
          style={{
            fontSize: '10px',
            color: 'white',
            fontWeight: 500,
            background: 'rgba(33, 33, 33, 0.6)',
            padding: '2px 6px',
            borderRadius: '4px',
            position: 'absolute',
            top: '10px',
            left: '10px',
          }}
        >
          {
            tFunction('appPreviewer.labels.daysLeftCount', {
              count: differenceInCalendarDays(endDate, new Date()),
            }) as ReactNode
          }
        </span>
        <span
          style={{
            fontSize: '10px',
            color: 'white',
            fontWeight: 500,
            background: 'rgb(229, 57, 53)',
            padding: '2px 6px',
            borderRadius: '4px',
            position: 'absolute',
            bottom: '10px',
            left: '10px',
          }}
        >
          {tFunction('appPreviewer.labels.amountDiscount', { count: amountDiscount }) as ReactNode}
        </span>
        <IconWrapper
          wrapperSx={{ position: 'absolute', right: '10px', top: '10px' }}
          bgColor="rgba(255, 255, 255, 0.2)"
        >
          <Box
            component="img"
            src="/images/icSaveOutlined@2x.png"
            sx={{
              width: '24px',
              height: '24px',
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          />
        </IconWrapper>
      </Box>
      <Box sx={{ color: 'rgb(76, 74, 74)', fontSize: '13px', p: '10px' }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Stack>
            <span style={{ fontSize: '14px', fontWeight: 600, lineHeight: '20px', marginBottom: '1px' }}>
              {tFunction(name) as ReactNode}
            </span>
            <StyledBreadcrumbs
              separator="&bull;"
              sx={{ fontSize: 'inherit', color: 'rgb(76, 74, 74)', lineHeight: '20px' }}
            >
              <span>
                {unlimitedUse
                  ? (tFunction('appPreviewer.labels.unlimited') as ReactNode)
                  : (tFunction('appPreviewer.labels.limited') as ReactNode)}
              </span>
              <span>
                <span>{format(startDate, 'LLL dd')}</span> - <span>{format(endDate, 'LLL dd')}</span>
              </span>
            </StyledBreadcrumbs>
          </Stack>
        </Stack>
      </Box>
    </Paper>
  );
};

export default PreviewDealItem;
