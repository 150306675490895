import React, { forwardRef } from 'react';
import { WidgetViewStyle } from '@types';
import { AppLayoutFeatureComponentProps } from '../../../../AppPreviewerProps';
import PreviewBasicWidget from '../PreviewBasicWidget';

const PreviewRequestTrainer = forwardRef<HTMLDivElement, AppLayoutFeatureComponentProps>(
  ({ tFunction, palette, previewerState, widgetProps, wrapperSx }, ref) => {
    return (
      <PreviewBasicWidget
        palette={palette}
        wrapperSx={wrapperSx}
        previewerState={previewerState}
        widgetProps={widgetProps}
        tFunction={tFunction}
        defaultName="appPreviewer.labels.trainingGuidance"
        defaultCallToAction="appPreviewer.labels.requestTrainer"
        defaultViewStyle={WidgetViewStyle.Image}
        defaultImagePath="/images/previewerDefaultImages/features/request_a_trainer.png"
        ref={ref}
      />
    );
  },
);

export default PreviewRequestTrainer;
