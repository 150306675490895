import { useMemo } from 'react';
import { ApplicationLayout, ApplicationPackage } from '@types';
import useAppPreviewerState from '../../../../hooks/useAppPreviewerState';
import { profileFeatures } from '../../constants';
import { PreviewerProfileRouteContainerProps, UsePreviewerProfileRouteResult } from '../../PreviewerProfileRouteProps';

const usePreviewerProfileRoute = ({ id }: PreviewerProfileRouteContainerProps): UsePreviewerProfileRouteResult => {
  const { palette, previewerState } = useAppPreviewerState(id);

  const shownFeatures = useMemo(() => {
    if (!previewerState.appPackage || !previewerState.appLayout) {
      return profileFeatures[ApplicationPackage.Basic][ApplicationLayout.Workouts];
    }

    return profileFeatures[previewerState.appPackage][previewerState.appLayout];
  }, [previewerState.appLayout, previewerState.appPackage]);

  return {
    palette,
    shownFeatures,
  };
};

export default usePreviewerProfileRoute;
