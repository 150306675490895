import { forwardRef } from 'react';
import useSignInLogo from './hooks/useSignInLogo';
import SignInLogo from './SignInLogo';
import { SignInLogoContainerProps } from './SignInLogoProps';

const SignInLogoContainer = forwardRef<any, SignInLogoContainerProps>((props, ref) => {
  const { logoWrapperRef, ...result } = useSignInLogo(props, ref);

  return <SignInLogo {...props} {...result} ref={logoWrapperRef} />;
});

export default SignInLogoContainer;
