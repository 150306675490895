import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const FiFiFlagIcon: React.FC<SvgIconProps> = ({ sx, ...props }) => {
  return (
    <SvgIcon viewBox="0 0 640 480" {...props} sx={{ width: '18px', ...sx }}>
      <path fill="#fff" d="M0 0h640v480H0z" />
      <path fill="#003580" d="M0 174.5h640v131H0z" />
      <path fill="#003580" d="M175.5 0h130.9v480h-131z" />
    </SvgIcon>
  );
};

export default FiFiFlagIcon;
