import React, { ChangeEvent, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Language } from '@egym/types';
import { ConditionalRender, Icons, LinkCore, Popover, Stack, Typography } from '@egym/ui';
import PopoverContent from '../PopoverContent';

type Props = {
  filteredPossibleLanguages: Language[];
  possibleLanguages: Language[];
  searchValue: string;
  onSearchChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onPossibleLanguageSelect: (selectedLocale: string) => void;
  onAddLanguageClick?: () => void;
};

const PopoverRoot: React.FC<Props> = ({
  searchValue,
  filteredPossibleLanguages,
  possibleLanguages,
  onSearchChange,
  onPossibleLanguageSelect,
  onAddLanguageClick,
}) => {
  const { t } = useTranslation();

  return (
    <ConditionalRender condition={Boolean(possibleLanguages.length)}>
      <Popover
        onAnchorClick={onAddLanguageClick}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <LinkCore component="button" variant="body2Medium" type="button" underline="none" color="text.primary">
          <Stack direction="row" alignItems="center">
            <Icons.Add />
            <Typography sx={{ pl: 2, textTransform: 'uppercase' }} variant="body2Medium">
              {t('common.labels.addLanguage') as ReactNode}
            </Typography>
          </Stack>
        </LinkCore>
        <PopoverContent
          searchValue={searchValue}
          filteredPossibleLanguages={filteredPossibleLanguages}
          onSearchChange={onSearchChange}
          onPossibleLanguageSelect={onPossibleLanguageSelect}
        />
      </Popover>
    </ConditionalRender>
  );
};

export default PopoverRoot;
