import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const EditProfileIcon: React.FC<SvgIconProps> = ({ sx, htmlColor = '#4C4A4A', ...rest }) => {
  return (
    <SvgIcon viewBox="0 0 24 24" sx={{ width: '24px', height: '24px', ...sx }} {...rest}>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <path d="M0 0H24V24H0z" transform="translate(-1268 -2235) translate(1268 2235)" />
            <path
              fill={htmlColor}
              d="M9 8c0-1.654 1.346-3 3-3s3 1.346 3 3-1.346 3-3 3-3-1.346-3-3m3 5c1.613 0 3.098.554 4.283 1.475l1.426-1.426c-.697-.574-1.485-1.038-2.334-1.384C16.367 10.751 17 9.453 17 8c0-2.757-2.243-5-5-5S7 5.243 7 8c0 1.451.632 2.749 1.623 3.663C5.33 13.002 3 16.231 3 20c0 .553.447 1 1 1h8v-2H5.071c.487-3.388 3.408-6 6.929-6m6.24 2.34l1.42 1.42L15.41 21H14v-1.41l4.24-4.25zm2.608 3.055c.095.521.152 1.056.152 1.605 0 .553-.447 1-1 1h-1.758zm-.488-4.754c.26 0 .52.09.71.289.39.39.39 1.02 0 1.41l-.71.71-1.41-1.41.71-.71c.19-.2.45-.29.7-.29z"
              transform="translate(-1268 -2235) translate(1268 2235)"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export default EditProfileIcon;
