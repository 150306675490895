import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const EditIconInverted: React.FC<SvgIconProps> = ({ sx, htmlColor = '#FFF', ...props }) => {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props} sx={{ width: '24px', height: '24px', ...sx }}>
      <defs>
        <filter colorInterpolationFilters="auto" id="xy5i8a4lta">
          <feColorMatrix
            in="SourceGraphic"
            values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0"
          />
        </filter>
        <filter colorInterpolationFilters="auto" id="7md2dao84b">
          <feColorMatrix
            in="SourceGraphic"
            values="0 0 0 0 0.447059 0 0 0 0 0.447059 0 0 0 0 0.447059 0 0 0 1.000000 0"
          />
        </filter>
      </defs>
      <g transform="translate(-466 -16)" filter="url(#xy5i8a4lta)" fill="none" fillRule="evenodd">
        <g transform="translate(466 16)" filter="url(#7md2dao84b)">
          <path d="M0 0h24v24H0z" />
          <path
            d="M4.985 17.612 15.218 7.378l1.405 1.404L6.388 19.015H4.985v-1.403zM17.323 5.274a.996.996 0 0 1 1.404-.001.996.996 0 0 1 0 1.404l-.701.702-1.404-1.405.701-.7zm2.545 13.741H9.194l9.533-9.531 1.404-1.403a2.985 2.985 0 0 0 0-4.211v-.001a2.984 2.984 0 0 0-4.21 0l-1.405 1.404L3.291 16.5a.99.99 0 0 0-.291.702v2.806c0 .548.444.992.992.992h15.876a.991.991 0 1 0 0-1.985z"
            fill={htmlColor}
          />
        </g>
      </g>
    </SvgIcon>
  );
};
