import { createApiRequest } from '@egym/api';
import { Uuid } from '@egym/types';

const APPLICATION_DETAILS_BASE_URL = 'applications-admin/applications/:applicationUuid/application-details';
export const APPLICATION_DETAILS_WITH_PROGRESS_BASE_URL = `${APPLICATION_DETAILS_BASE_URL}-progress`;

export const getApplicationDetails = createApiRequest<Blob, unknown, { applicationUuid: Uuid }>(
  APPLICATION_DETAILS_BASE_URL,
  'get',
  undefined,
  'blob',
);
