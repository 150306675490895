import { createApiRequest } from '@egym/api';
import { AppDesignLayoutTabWidget, WidgetViewStyle } from '@types';
import {
  APPLICATION_DESIGN_ALL_WIDGETS_URL,
  APPLICATION_DESIGN_WIDGET_ASSET_URL,
  APPLICATION_DESIGN_WIDGETS_BY_TAB_URL,
} from './constants';

export const getAllWidgets = createApiRequest<AppDesignLayoutTabWidget[], unknown, { applicationUuid: string }>(
  APPLICATION_DESIGN_ALL_WIDGETS_URL,
);

export const postAllWidgets = createApiRequest<
  AppDesignLayoutTabWidget[],
  AppDesignLayoutTabWidget[],
  { applicationUuid: string }
>(APPLICATION_DESIGN_ALL_WIDGETS_URL, 'put');

export const putWidgetsForTab = createApiRequest<
  AppDesignLayoutTabWidget[],
  AppDesignLayoutTabWidget[],
  { applicationUuid: string; tabId: string }
>(APPLICATION_DESIGN_WIDGETS_BY_TAB_URL, 'put');

export const deleteWidgets = createApiRequest<unknown, { ids: number[] }, { applicationUuid: string }>(
  APPLICATION_DESIGN_ALL_WIDGETS_URL,
  'delete',
);

export const postWidgetAsset = createApiRequest<
  AppDesignLayoutTabWidget,
  FormData,
  { applicationUuid: string; widgetId: number; style: WidgetViewStyle }
>(APPLICATION_DESIGN_WIDGET_ASSET_URL, 'post');

export const deleteWidgetAsset = createApiRequest<
  AppDesignLayoutTabWidget,
  FormData,
  { applicationUuid: string; widgetId: number; style?: string }
>(APPLICATION_DESIGN_WIDGET_ASSET_URL, 'delete', { url: { style: 'asset' } });
