import { useCallback, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { useEffectOnce, useUpdateEffect } from 'react-use';
import { v4 as uuidv4 } from 'uuid';
import { useContentDialog, useSnackbar } from '@egym/ui';
import {
  castArray,
  getReadableFileSize,
  imageMimeTypes,
  blobToDataURL,
  imageSize,
  convertFileFormatsToAcceptableStructure,
} from '@egym/utils';
import FileUploadCropper from '../../components/FileUploadCropper';
import { FileUploadContainerProps, UseFileUploadResult } from '../../FileUploadProps';

const useFileUpload = ({
  multiple,
  maxSize,
  minImageDimension,
  maxImageDimension,
  accept,
  name,
  onChange,
  isViewMode,
  value,
  setError,
  shouldShowToastOnError = true,
  shouldShowHelperTextOnError,
  hints,
  cropperProps,
  cropperStyle,
  cropperExtraElement,
  onFilePick,
  viewModeFallbackValue,
}: FileUploadContainerProps): UseFileUploadResult => {
  const { t } = useTranslation();
  const [files, setFiles] = useState([]);
  const [droppedFiles, setDroppedFiles] = useState<any[]>([]);
  const { openSnackbar } = useSnackbar();
  const { openContentDialog, closeContentDialog } = useContentDialog();

  const handleChange = useCallback(
    acceptedFiles => {
      if (onFilePick) onFilePick(acceptedFiles);

      onChange({ target: { name, value: acceptedFiles } });
    },
    [name, onChange, onFilePick],
  );

  const setupFiles = useCallback(async () => {
    if (value || viewModeFallbackValue) {
      const valueResult = value || viewModeFallbackValue;

      const newFiles = await castArray(valueResult).reduce(async (acc, image) => {
        let newFile;

        if (typeof image === 'string') {
          newFile = { preview: image, path: image };
        } else {
          const objectUrl = await blobToDataURL(image);
          newFile = { ...image, preview: objectUrl, path: image.path || objectUrl };
        }

        return [...(await acc), newFile];
      }, Promise.resolve([]));

      setFiles(newFiles);
    } else {
      setFiles([]);
    }
  }, [value, viewModeFallbackValue]);

  useEffectOnce(() => {
    setupFiles();
  });

  useUpdateEffect(() => {
    setupFiles();
  }, [value, viewModeFallbackValue]);

  const maxSizeReadable = useMemo(() => {
    if (!maxSize) return null;

    return getReadableFileSize(maxSize);
  }, [maxSize]);

  const fileUploadRequirementsParams = useMemo(() => {
    if (!accept?.length || !maxSizeReadable) return null;

    return {
      formats: accept.join(', '),
      count: accept.length,
      maxSize: maxSizeReadable.size,
      unit: maxSizeReadable.unit,
    };
  }, [accept, maxSizeReadable]);

  const fileUploadRequirementsHint = useMemo(() => {
    if (!fileUploadRequirementsParams) return null;

    return t('common.fileUpload.typeAndMaxSize', fileUploadRequirementsParams);
  }, [t, fileUploadRequirementsParams]);

  const validateMinImageSize = useCallback(
    (minReqDimension: number[], width: number, height: number) => {
      if (minReqDimension.length === 1) {
        const dimension = minReqDimension[0];
        if (dimension > width && dimension > height) {
          return t('common.fileUpload.atLeastWidthOrHeight', { pixels: dimension });
        }
      }
      if (minReqDimension.length === 2) {
        const reqWidth = minReqDimension[0];
        const reqHeight = minReqDimension[1];
        if (reqWidth > width || reqHeight > height) {
          return t('common.fileUpload.atLeastWidthAndHeight', {
            widthPixels: reqWidth,
            heightPixels: reqHeight,
          });
        }
      }
      return undefined;
    },
    [t],
  );

  const validateMaxImageSize = useCallback(
    (maxReqDimension: number[], width: number, height: number) => {
      if (maxReqDimension.length === 1) {
        const dimension = maxReqDimension[0];
        if (dimension < width && dimension < height) {
          return t('common.fileUpload.atMostWidthOrHeight', { pixels: dimension });
        }
      }
      if (maxReqDimension.length === 2) {
        const reqWidth = maxReqDimension[0];
        const reqHeight = maxReqDimension[1];
        if (reqWidth < width || reqHeight < height) {
          return t('common.fileUpload.atMostWidthAndHeight', {
            widthPixels: reqWidth,
            heightPixels: reqHeight,
          });
        }
      }
      return undefined;
    },
    [t],
  );

  const validateImageSize = useCallback(
    async (file: File) => {
      const { width, height } = await imageSize(URL.createObjectURL(file));
      if (minImageDimension && minImageDimension.length) {
        const result = validateMinImageSize(minImageDimension, width, height);
        if (result) {
          return result;
        }
      }
      if (maxImageDimension && maxImageDimension.length) {
        const result = validateMaxImageSize(maxImageDimension, width, height);
        if (result) {
          return result;
        }
      }
      return undefined;
    },
    [minImageDimension, maxImageDimension, validateMinImageSize, validateMaxImageSize],
  );

  const onDrop = useCallback(
    (acceptedFiles, rejectedFiles) => {
      if (rejectedFiles?.length && setError && fileUploadRequirementsHint) {
        const errorText = `${t('common.fileUpload.uploadError')}: ${fileUploadRequirementsHint}`;

        if (shouldShowToastOnError) {
          openSnackbar(errorText, { severity: 'error' });
        }
        if (shouldShowHelperTextOnError) {
          setError(errorText);
        }
        return;
      }

      (async () => {
        if (!acceptedFiles.length) {
          return;
        }
        if ((minImageDimension && minImageDimension.length) || (maxImageDimension && maxImageDimension.length)) {
          const errorMessage = await validateImageSize(acceptedFiles[0]);
          if (errorMessage) {
            const errorText = `${t('common.fileUpload.uploadError')}: ${errorMessage}`;

            if (shouldShowToastOnError) {
              openSnackbar(errorText, { severity: 'error' });
            }
            if (shouldShowHelperTextOnError && setError) {
              setError(errorText);
            }
            return;
          }
        }

        if (cropperProps && acceptedFiles.some(file => imageMimeTypes.includes(file.type))) {
          setDroppedFiles(
            acceptedFiles.map(file => ({
              ...file,
              preview: URL.createObjectURL(file),
              type: file.type,
              name: file.name,
              file,
            })),
          );
        } else {
          handleChange(acceptedFiles);
        }
      })();
    },
    [
      setError,
      fileUploadRequirementsHint,
      cropperProps,
      t,
      shouldShowToastOnError,
      shouldShowHelperTextOnError,
      openSnackbar,
      handleChange,
      minImageDimension,
      maxImageDimension,
      validateImageSize,
    ],
  );

  const onCropConfirm = useCallback(
    (croppedImage: Blob) => {
      const originalFile = droppedFiles[0].file;
      const cropFileName =
        originalFile.name && originalFile.name.includes('.')
          ? `${uuidv4()}.${originalFile.name.split('.').pop()}`
          : uuidv4();
      handleChange([new File([croppedImage], cropFileName, { type: croppedImage.type }), droppedFiles[0].file]);
      closeContentDialog();
    },
    [handleChange, closeContentDialog, droppedFiles],
  );

  const openCropper = useCallback(() => {
    openContentDialog({
      title: 'common.cropper.cropImage',
      component: FileUploadCropper,
      showCancelButton: false,
      contentProps: {
        sx: {
          pt: 0,
        },
      },
      componentProps: {
        droppedFile: droppedFiles[0],
        aspect: cropperProps?.ratio,
        onCropConfirm,
        cropperStyle,
        cropperExtraElement,
      },
    });
  }, [openContentDialog, droppedFiles, cropperProps, onCropConfirm, cropperStyle, cropperExtraElement]);

  useUpdateEffect(() => {
    if (droppedFiles.length) {
      openCropper();
    }
  }, [droppedFiles]);

  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: isViewMode,
    noKeyboard: isViewMode,
    onDrop,
    multiple,
    accept: accept ? convertFileFormatsToAcceptableStructure(accept) : undefined,
    maxSize,
  });

  const hintsResult = useMemo(() => {
    let result = hints || [];
    if (fileUploadRequirementsParams && maxSize) {
      result = [t('common.fileUpload.maxSize', fileUploadRequirementsParams), ...result];
    }
    return result;
  }, [fileUploadRequirementsParams, maxSize, hints, t]);

  return {
    files,
    getRootProps,
    getInputProps,
    open,
    fileUploadRequirementsParams,
    hintsResult,
  };
};

export default useFileUpload;
