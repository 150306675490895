import { FC } from 'react';
import { Menu, MenuItem, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { Icons } from '../../icons';
import useNestedMenuItem from './hooks/useNestedMenuItem';
import { NestedMenuItemProps } from './NestedMenuItemProps';

const NestedMenuItem: FC<NestedMenuItemProps> = props => {
  const { option, label, onClick, rootMenuItemProps, options, onSelectChildOption, children } = props;

  const {
    containerRef,
    handleMouseEnter,
    handleMouseLeave,
    handleKeyDown,
    menuItemRef,
    open,
    setOpen,
    menuContainerRef,
    onSelectChildOptionAndClose,
  } = useNestedMenuItem(props);

  return (
    <Box
      ref={containerRef}
      role="none"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onKeyDown={handleKeyDown}
      sx={{ minWidth: '200px' }}
    >
      <MenuItem
        data-testid={`nested-menu-item-${option.value}`}
        ref={menuItemRef}
        onClick={onClick}
        {...rootMenuItemProps}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          py: 2.5,
          ...rootMenuItemProps?.sx,
        }}
      >
        {label}
        <Icons.ChevronRight sx={{ ml: 5 }} />
      </MenuItem>
      <Menu
        open={open}
        id="basic-menu2"
        anchorEl={menuItemRef.current}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        autoFocus={false}
        disableAutoFocus
        disableEnforceFocus
        onClose={() => {
          setOpen(false);
        }}
        sx={{ pointerEvents: 'none' }}
        MenuListProps={{ sx: { minWidth: '200px' } }}
      >
        <div ref={menuContainerRef} style={{ pointerEvents: 'auto' }}>
          {Array.isArray(options)
            ? options.map(childOption => {
                const {
                  icon: ChildIcon,
                  iconProps: childIconProps,
                  children: childChildren,
                  label: childLabel,
                  value: childValue,
                } = childOption;

                return childChildren ? (
                  <NestedMenuItem
                    {...props}
                    option={childOption}
                    key={childValue}
                    label={
                      ChildIcon ? (
                        <Stack direction="row" alignItems="center">
                          <ChildIcon {...childIconProps} />
                          <Typography variant="body1">{childLabel}</Typography>
                        </Stack>
                      ) : (
                        <Typography variant="body1">{childLabel}</Typography>
                      )
                    }
                    options={childChildren}
                    onSelectChildOption={onSelectChildOptionAndClose}
                  />
                ) : (
                  <MenuItem
                    data-testid={`nested-menu-item-${childOption.value}`}
                    key={childValue}
                    sx={{ minWidth: '180px', py: 2.5 }}
                    onClick={() => onSelectChildOption && onSelectChildOptionAndClose(childOption)}
                  >
                    {childLabel}
                  </MenuItem>
                );
              })
            : children}
        </div>
      </Menu>
    </Box>
  );
};

export default NestedMenuItem;
