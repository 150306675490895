import tinycolor from 'tinycolor2';
import { GradientPickerType, GradientPoint } from './types';

export const isValidHex = hex => {
  if (hex === 'transparent') {
    return true;
  }
  // disable hex4 and hex8
  const lh = String(hex).charAt(0) === '#' ? 1 : 0;
  return hex.length !== 4 + lh && hex.length < 7 + lh && tinycolor(hex).isValid();
};

export const generateGradientStyle = (points: GradientPoint[], type: GradientPickerType, degree: number) => {
  let style = '';
  const sortedPoints = points.slice().sort((a, b) => a.left - b.left);

  if (type === GradientPickerType.Linear) {
    style = `linear-gradient(${degree}deg,`;
  } else {
    style = 'radial-gradient(';
  }

  sortedPoints.forEach((point, index) => {
    style += `rgba(${point.rgb.r}, ${point.rgb.g}, ${point.rgb.b}, ${point.rgb.a}) ${point.left}%`;

    if (index !== sortedPoints.length - 1) {
      style += ',';
    }
  });

  style += ')';

  return style;
};
