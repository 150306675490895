import React, { FC, ReactElement } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Button, Icons } from '@egym/ui';
import { BackButtonProps, NavigationBackUrl } from './BackButtonProps';

const BackButton: FC<BackButtonProps> = ({ backButton, onBackButtonClick }) => {
  if ((backButton as ReactElement).type) {
    return backButton as ReactElement;
  }

  const buttonParams = backButton as NavigationBackUrl;

  return (
    <Button
      // @ts-ignore
      component={buttonParams.url ? RouterLink : undefined}
      startIcon={<Icons.ArrowBack />}
      text={buttonParams.title}
      to={buttonParams.url ? { pathname: buttonParams.url, search: buttonParams.search } : undefined}
      onClick={onBackButtonClick}
      color="inherit"
      variant="text"
      sx={{ p: 0, textTransform: 'uppercase' }}
    />
  );
};

export default BackButton;
