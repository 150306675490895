import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const SubmitSettingsIcon: React.FC<SvgIconProps> = ({ htmlColor = '#000', sx, ...props }) => {
  return (
    <SvgIcon viewBox="0 0 48 48" {...props} sx={{ width: '48px', height: '48px', ...sx }}>
      <svg width="48" height="48" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <filter colorInterpolationFilters="auto" id="a">
            <feColorMatrix
              in="SourceGraphic"
              values="0 0 0 0 0.796078 0 0 0 0 0.796078 0 0 0 0 0.796078 0 0 0 1.000000 0"
            />
          </filter>
        </defs>
        <g transform="translate(-1124 -784)" filter="url(#a)" fill="none" fillRule="evenodd" opacity=".8">
          <path d="M1124 784h48v48h-48z" />
          <path
            d="m1168 794.36-22.82 22.84-8.48-8.48 2.82-2.82 5.66 5.66 20-20 2.82 2.8zm-4.42 10.08c.26 1.14.42 2.34.42 3.56 0 8.84-7.16 16-16 16s-16-7.16-16-16 7.16-16 16-16c3.16 0 6.08.92 8.56 2.5l2.88-2.88A19.8 19.8 0 0 0 1148 788c-11.04 0-20 8.96-20 20s8.96 20 20 20 20-8.96 20-20c0-2.38-.44-4.66-1.2-6.78l-3.22 3.22z"
            fill={htmlColor}
            fillRule="nonzero"
          />
        </g>
      </svg>
    </SvgIcon>
  );
};
