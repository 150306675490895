import { useCallback } from 'react';
import { Uuid } from '@egym/types';
import { useContentDialog, useRouter } from '@egym/ui';
import { AnalyticsEvents, pageUrls } from '@constants';
import { useTrackEventByRouter } from '@hooks';
import { UseAppDesignSharePreviewButtonResult } from '../../AppDesignSharePreviewButtonProps';
import AppDesignSharePreviewButtonContent from '../../components/AppDesignSharePreviewButtonContent';

const useAppDesignSharePreviewButton = (): UseAppDesignSharePreviewButtonResult => {
  const { urlParams } = useRouter<{ uuid: Uuid }>();
  const { openContentDialog } = useContentDialog();
  const trackEvent = useTrackEventByRouter();

  const onShareClick = useCallback(() => {
    openContentDialog({
      title: 'appDesign.labels.shareAppPreview',
      component: AppDesignSharePreviewButtonContent,
      componentProps: {
        link: `${window.location.origin}${pageUrls.appDesignView({ uuid: urlParams.uuid })}`,
      },
    });
    trackEvent(AnalyticsEvents.A_Design_Share_Preview_Clicked);
  }, [openContentDialog, urlParams.uuid, trackEvent]);

  return {
    onShareClick,
  };
};

export default useAppDesignSharePreviewButton;
