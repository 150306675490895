import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useContentDialog } from '../../../hooks';
import { Button } from '../../inputs';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '../Dialog';

export const ContentDialog = () => {
  const { t } = useTranslation();

  const { isOpen, closeContentDialog, dialogConfig, showCancelButton } = useContentDialog();

  const ContentComponent = dialogConfig?.component;

  return ContentComponent ? (
    <Dialog open={isOpen} onClose={closeContentDialog} fullWidth maxWidth={dialogConfig.maxWidth ?? 'sm'}>
      {dialogConfig?.title && (
        <DialogTitle onClose={closeContentDialog} wrapperSx={dialogConfig?.dialogTitleSx}>
          {t(dialogConfig?.title, dialogConfig?.titleParams) as ReactNode}
        </DialogTitle>
      )}
      <DialogContent {...dialogConfig?.contentProps} sx={{ py: 4, px: 8, ...dialogConfig?.contentProps?.sx }}>
        <ContentComponent {...dialogConfig?.componentProps} onCancel={closeContentDialog} />
      </DialogContent>
      {showCancelButton && (
        <DialogActions sx={{ pt: 5, pb: 10, px: 8 }} disableSpacing>
          <Button
            variant={dialogConfig?.cancelButtonVariant ?? 'outlined'}
            color="primary"
            onClick={closeContentDialog}
            text={t(dialogConfig?.cancelText || 'common.buttons.cancel') as ReactNode}
            sx={{ minWidth: '100px' }}
          />
        </DialogActions>
      )}
    </Dialog>
  ) : null;
};
