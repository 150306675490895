import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const InfoOutlinedIcon: React.FC<SvgIconProps> = ({ sx, htmlColor = '#4C4A4A', ...rest }) => {
  return (
    <SvgIcon viewBox="0 0 24 24" sx={{ width: '20px', height: '20px', ...sx }} {...rest}>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <path d="M0 0H24V24H0z" transform="translate(-164 -1652) translate(164 1652)" />
            <path
              fill={htmlColor}
              d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2zm0 2c-4.418 0-8 3.582-8 8s3.582 8 8 8 8-3.582 8-8-3.582-8-8-8zm0 7c.552 0 1 .448 1 1v4c0 .552-.448 1-1 1s-1-.448-1-1v-4c0-.552.448-1 1-1zm0-4c.663 0 1.2.537 1.2 1.2 0 .663-.537 1.2-1.2 1.2-.663 0-1.2-.537-1.2-1.2 0-.663.537-1.2 1.2-1.2z"
              transform="translate(-164 -1652) translate(164 1652)"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export default InfoOutlinedIcon;
