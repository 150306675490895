import { useClientApplicationByRouter } from '@hooks';
import { UseHeaderResultProps } from '../../HeaderProps';

const useHeader = (): UseHeaderResultProps => {
  const { applicationResponse } = useClientApplicationByRouter();

  return {
    application: applicationResponse.data,
  };
};

export default useHeader;
