import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { StaticDatePicker } from '@mui/lab';
import { Divider, TextField, Typography } from '@mui/material';
import { Icons, Stack } from '@egym/ui';
import { IconButton } from '../../../../../../inputs/IconButton';

type Props = {
  handleClose?: () => void; // passed from Popover
  fromDateValue: Date | null;
  onSetFromDate: (date: Date | null) => void;
  toDateValue: Date | null;
  onSetToDate: (date: Date | null) => void;
};

const TableFilterDatesRangePopoverContent: React.FC<Props> = ({
  fromDateValue,
  onSetFromDate,
  toDateValue,
  onSetToDate,
}) => {
  const { t } = useTranslation();

  return (
    <Stack>
      <Stack direction="row" divider={<Divider variant="middle" orientation="vertical" flexItem />} spacing={2}>
        <Stack>
          <Stack sx={{ p: 5.75, pb: 0, pt: 2 }} direction="row" alignItems="center" justifyContent="space-between">
            <Typography sx={{ height: '36px', lineHeight: '36px' }} variant="body1Bold">
              {t('common.labels.after') as ReactNode}
            </Typography>
            {fromDateValue && (
              <IconButton sx={{ fontSize: '20px' }} edge="end" onClick={() => onSetFromDate(null)} size="large">
                <Icons.CancelOutlined fontSize="inherit" color="action" />
              </IconButton>
            )}
          </Stack>
          <StaticDatePicker
            displayStaticWrapperAs="desktop"
            value={fromDateValue}
            maxDate={toDateValue || undefined}
            onChange={onSetFromDate}
            renderInput={params => <TextField {...params} />}
          />
        </Stack>
        <Stack>
          <Stack sx={{ p: 5.75, pb: 0, pt: 2 }} direction="row" alignItems="center" justifyContent="space-between">
            <Typography sx={{ height: '36px', lineHeight: '36px' }} variant="body1Bold">
              {t('common.labels.before') as ReactNode}
            </Typography>
            {toDateValue && (
              <IconButton sx={{ fontSize: '20px' }} edge="end" onClick={() => onSetToDate(null)} size="large">
                <Icons.CancelOutlined fontSize="inherit" color="action" />
              </IconButton>
            )}
          </Stack>
          <StaticDatePicker
            displayStaticWrapperAs="desktop"
            value={toDateValue}
            minDate={fromDateValue || undefined}
            defaultCalendarMonth={fromDateValue || undefined}
            onChange={onSetToDate}
            renderInput={params => <TextField {...params} />}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default TableFilterDatesRangePopoverContent;
