import { Uuid } from '@egym/types';
import { useRouter } from '@egym/ui';
import useAppDesignProgress from '../useAppDesignProgress';

const useAppDesignProgressByRouter = () => {
  const { urlParams } = useRouter<{ uuid: Uuid }>();

  return useAppDesignProgress(urlParams.uuid);
};

export default useAppDesignProgressByRouter;
