import { Uuid } from '@egym/types';
import { ApplicationSettingsStatus, FormProgress } from '../applications';

export type ApplicationSettings = {
  id: Uuid;
  status?: ApplicationSettingsStatus;
  progress?: FormProgress;
  adminEmail?: string;
  companyName?: string;
  brandName?: string;
  companyWebsite?: string;
  companyContactEmail?: string;
  memberManagingSoftware?: MemberManagementSoftware;
  mmsNotInTheList?: boolean;
  verifiedLoginEnabled?: boolean;
  scannerType?: ScannerType;
  fitnessHubPresent?: boolean;
  appStoreAccountName?: string;
  appStoreSubmissionName?: string;
  iosAppIconName?: string;
};

export type MemberManagementSoftware = {
  id: number;
  name?: string;
};

export enum ScannerType {
  QR = 'QR',
  Barcode = 'BARCODE',
}

export enum AppSettingsTabsEnum {
  General = 'General',
  AppleDevProgram = 'AppleDevProgram',
  GymLocations = 'GymLocations',
}
