import React, { forwardRef, ReactNode } from 'react';
import { Paper, Stack } from '@egym/ui';
import { AppLayoutFeatureComponentProps } from '../../../../AppPreviewerProps';
import PlusIcon from '../../../Icons/PlusIcon';
import PreviewContainer from '../../../PreviewContainer';

const PreviewWorkoutTracking = forwardRef<HTMLDivElement, AppLayoutFeatureComponentProps>(
  ({ tFunction, palette, wrapperSx }, ref) => {
    return (
      <PreviewContainer ref={ref} wrapperSx={wrapperSx}>
        <Paper
          sx={{ background: palette.primary, fontSize: '14px', fontWeight: 500, color: 'white', py: '8px' }}
          elevation={0}
        >
          <Stack direction="row" justifyContent="center" alignItems="center">
            <PlusIcon htmlColor="white" />
            <span style={{ marginLeft: '10px', lineHeight: '14px' }}>
              {tFunction('appPreviewer.labels.logWorkout') as ReactNode}
            </span>
          </Stack>
        </Paper>
      </PreviewContainer>
    );
  },
);

export default PreviewWorkoutTracking;
