import { atom, selector } from 'recoil';
import { LS_LOCALE, toLocaleApiFormat } from '@egym/utils';
import { localStorageEffectRaw } from './effects';

const keys = {
  currentLocale: 'localeState.currentLocale',
  currentLocaleSplitted: 'localeState.currentLocaleSplitted',
  currentLocaleUnderscore: 'localeState.currentLocaleUnderscore',
};

const atoms = {
  currentLocale: atom({
    key: keys.currentLocale,
    default: 'en-US',
    effects_UNSTABLE: [localStorageEffectRaw(LS_LOCALE)],
  }),
};

const selectors = {
  currentLocaleSplitted: selector({
    key: keys.currentLocaleSplitted,
    get: ({ get }) => {
      const currentLocale = get(atoms.currentLocale);

      return currentLocale ? currentLocale.split('_')[0] : '';
    },
  }),
  currentLocaleUnderscore: selector({
    key: keys.currentLocaleUnderscore,
    get: ({ get }) => {
      const currentLocale = get(atoms.currentLocale);

      return currentLocale ? toLocaleApiFormat(currentLocale) : '';
    },
  }),
};

export const localeState = {
  keys,
  atoms,
  selectors,
};
