import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { equals, map } from 'ramda';
import { Uuid } from '@egym/types';
import { useSnackbar } from '@egym/ui';
import { castArray, toLocaleFormat } from '@egym/utils';
import {
  getClientApplicationDesign,
  postAppIcon,
  postSignInBackgroundImagePredefined,
  postMainTabHeaderIcon,
  postSignInLogo,
  putClientApplicationDesign,
  setCustomSignInBackgroundImage,
  submitApplicationDesign,
  unsubmitApplicationDesign,
  deleteMainTabHeaderIcon,
} from '@api';
import { appDesignProgressAllSteps, calculateAppDesignProgress, transformTabForApi } from '@helpers';
import {
  AppDesignLayoutTab,
  ApplicationDesignDto,
  ApplicationDesignStatus,
  ApplicationLayout,
  ColorThemeEnum,
  AppDesignFormValues,
} from '@types';
import { LngNamespace } from 'src/i18n';
import useAppDesignCustomTabs from '../useAppDesignCustomTabs';
import useAppDesignTabsWidgets from '../useAppDesignTabsWidgets';
import useClientApplication from '../useClientApplication';

const useClientApplicationDesign = (appUuid: Uuid, enabledCustomTabsQuery = false) => {
  const { openSnackbar } = useSnackbar();
  const { isArchived, demo } = useClientApplication(appUuid);
  const { t } = useTranslation();
  const { t: previewerT } = useTranslation(LngNamespace.Previewer);
  const queryClient = useQueryClient();
  const {
    appDesignLayoutTabsQuery,
    createAppDesignLayoutTabsMutation,
    editAppDesignLayoutTabsMutation,
    deleteAppDesignLayoutTabsMutation,
    replaceCustomTabsInCache,
  } = useAppDesignCustomTabs({ appUuid, enabledQuery: enabledCustomTabsQuery });
  const { allWidgetsQuery, createOrEditAllWidgetsMutation, deleteWidgetsMutation } = useAppDesignTabsWidgets({
    appUuid,
    enabledAllWidgetsQuery: false,
  });
  const queryKey = useMemo(() => ['appDesign', appUuid], [appUuid]);

  const appDesign = useQuery(
    queryKey,
    async () => {
      return getClientApplicationDesign({ urlParams: { applicationUuid: appUuid } });
    },
    {
      enabled: Boolean(appUuid),
      retryOnMount: false,
      refetchOnMount: false,
      keepPreviousData: true,
      select: result => result.data,
    },
  );

  const isViewMode = useMemo(
    () =>
      (appDesign.isSuccess &&
        ![ApplicationDesignStatus.NOT_COMPLETED, ApplicationDesignStatus.REJECTED].includes(appDesign.data?.status)) ||
      isArchived,
    [isArchived, appDesign.isSuccess, appDesign.data],
  );

  const replaceAppDesignInCache = response => {
    queryClient.setQueryData(queryKey, (prevData: any) => ({
      ...prevData,
      data: response.data,
    }));
  };

  const updateAppDesign = useMutation(
    async (data: Partial<ApplicationDesignDto>) => {
      const prevData = appDesign.data;
      return putClientApplicationDesign({
        payload: {
          id: appUuid,
          ...prevData,
          signIn: { ...prevData?.signIn, ...data.signIn },
          appIcon: { ...prevData?.appIcon, ...data.appIcon },
          ...data,
        },
        urlParams: { applicationUuid: appUuid },
      });
    },
    {
      onError: () => {
        openSnackbar(t('common.netError'), { severity: 'error' });
      },
    },
  );

  const submitAppDesign = useMutation(
    async () => submitApplicationDesign({ urlParams: { applicationUuid: appUuid } }),
    {
      onSuccess: async res => {
        replaceAppDesignInCache(res);
        openSnackbar('appDesign.labels.appDesignSentForReview');
      },
      onError: () => {
        openSnackbar(t('common.netError'), { severity: 'error' });
      },
    },
  );

  const unsubmitAppDesign = useMutation(
    async () => unsubmitApplicationDesign({ urlParams: { applicationUuid: appUuid } }),
    {
      onSuccess: async res => {
        replaceAppDesignInCache(res);
        openSnackbar('appDesign.labels.appDesignUnsubmitted');
      },
      onError: () => {
        openSnackbar(t('common.netError'), { severity: 'error' });
      },
    },
  );

  const uploadAppIcon = useMutation(async ({ file }: { file: File }) => {
    const formData = new FormData();
    formData.append('icon', file, file.name || '');
    return postAppIcon({ payload: formData, urlParams: { applicationUuid: appUuid } });
  });

  const uploadMainTabHeaderIcon = useMutation(
    async ({ file, predefinedIconId }: { file: File; predefinedIconId?: number | null }) => {
      const formData = new FormData();
      formData.append('icon', file, file.name || '');
      formData.append('predefinedIconId', String(predefinedIconId || 0));

      return postMainTabHeaderIcon({ payload: formData, urlParams: { applicationUuid: appUuid } });
    },
  );

  const resetMainTabHeaderIcon = useMutation(async ({ applicationUuid }: { applicationUuid: string }) => {
    return deleteMainTabHeaderIcon({ urlParams: { applicationUuid } });
  });

  const uploadSignInBackgroundImagePredefined = useMutation(
    async ({ file, predefinedImageId }: { file: File; predefinedImageId?: number | null }) => {
      const formData = new FormData();
      formData.append('image', file, file.name || 'dot.png');
      formData.append('predefinedImageId', String(predefinedImageId));

      return postSignInBackgroundImagePredefined({ payload: formData, urlParams: { applicationUuid: appUuid } });
    },
  );

  const doSetSignInBackgroundImageCustom = useMutation(async (customImageId: string) => {
    return setCustomSignInBackgroundImage({
      urlParams: { applicationUuid: appUuid },
      queryParams: { customImageId },
    });
  });

  const uploadSignInLogo = useMutation(async ({ file }: { file: File }) => {
    const formData = new FormData();
    formData.append('logo', file, file.name || '');
    return postSignInLogo({ payload: formData, urlParams: { applicationUuid: appUuid } });
  });

  const uploadSignInScreenImages = useMutation(
    async ({
      backgroundImage,
      predefinedImageId,
      customSignInBgImageId,
      logo,
    }: {
      backgroundImage?: File | File[] | string | null;
      predefinedImageId?: number | null;
      customSignInBgImageId?: string | null;
      logo?: File[] | string | null;
    }) => {
      let result;
      if (
        typeof predefinedImageId === 'number' &&
        [File, Blob].some(fileType => castArray(backgroundImage)?.[0] instanceof fileType)
      ) {
        result = await uploadSignInBackgroundImagePredefined.mutateAsync({
          file: castArray(backgroundImage)?.[0],
          predefinedImageId,
        });
      } else if (customSignInBgImageId) {
        result = await doSetSignInBackgroundImageCustom.mutateAsync(customSignInBgImageId);
      }

      if (logo?.[0] instanceof File) {
        result = await uploadSignInLogo.mutateAsync({ file: logo[0] });
      }

      return result;
    },
  );

  const saveAppDesignForm = useMutation(
    async (values: AppDesignFormValues) => {
      let tabsResult;

      let appDesignResult = await updateAppDesign.mutateAsync({
        layout: values.layout as ApplicationLayout,
        colorTheme: values.colorTheme,
        locales: values.locales as string[],
        customColors: values.customColors,
        appIcon: { backgroundColor: values.appIconBackground },
        signIn: {
          logoDisabled: values.signInLogoDisabled,
          backgroundImageOverlayColor: values.signInBackgroundImageOverlayColor,
          logoPositionLeft: values.signInLogoPositionLeft,
          logoPositionTop: values.signInLogoPositionTop,
          logoWidth: values.signInLogoWidth,
          logoHeight: values.signInLogoHeight,
        },
        mainTabBrandingTabVisited: Boolean(
          appDesign.data?.mainTabBrandingTabVisited || values.mainTabBrandingTabVisited,
        ),
      });

      if (appDesign.data?.mainTabHeader.iconLink && values.mainTabPredefinedIconId === 31) {
        await resetMainTabHeaderIcon.mutateAsync({
          applicationUuid: appUuid,
        });
        appDesignResult = {
          ...appDesignResult,
          data: { ...appDesignResult.data, mainTabHeader: { iconLink: null, iconPredefinedId: null } },
        };
      } else if (values.mainTabHeaderIcon?.[0] instanceof File) {
        appDesignResult = await uploadMainTabHeaderIcon.mutateAsync({
          file: values.mainTabHeaderIcon[0],
          predefinedIconId: values.mainTabPredefinedIconId,
        });
      }
      if (values.appIcon?.[0] instanceof File) {
        appDesignResult = await uploadAppIcon.mutateAsync({ file: values.appIcon[0] });
      }

      if (
        typeof values.signInPredefinedImageId === 'number' ||
        values.signInCustomImageId ||
        values.signInLogo?.[0] instanceof File
      ) {
        const signInScreenResult = await uploadSignInScreenImages.mutateAsync({
          backgroundImage: values.signInBackgroundImage,
          predefinedImageId: values.signInPredefinedImageId,
          customSignInBgImageId: values.signInCustomImageId,
          logo: values.signInLogo,
        });
        appDesignResult = signInScreenResult || appDesignResult;
      }

      if (
        values.appLayoutTabs.length &&
        values.layout &&
        appDesignLayoutTabsQuery.isSuccess &&
        allWidgetsQuery.isSuccess
      ) {
        const convertedTabs: AppDesignLayoutTab[] = values.appLayoutTabs.map(tab => {
          const transformedTab = transformTabForApi(tab);

          return {
            ...transformedTab,
            localizedName: values.locales.reduce((acc, locale) => {
              const localizedName = transformedTab.localizedName[locale];
              const fallbackName = tab.defaultTitleKey
                ? previewerT(tab.defaultTitleKey, { lng: toLocaleFormat(locale) })
                : t('common.buttons.newTab', { lng: toLocaleFormat(locale) });

              return {
                ...acc,
                [locale]: localizedName || fallbackName,
              };
            }, {}),
          };
        });

        const hasMissingTabsNameLocales = values.appLayoutTabs.some(tab => {
          const tabAddedLocales = Object.keys(tab.name).filter(locale => tab.name[locale]);

          return !values.locales.every(locale => tabAddedLocales.includes(locale));
        });

        if (!appDesignLayoutTabsQuery.data.length) {
          tabsResult = await createAppDesignLayoutTabsMutation.mutateAsync(convertedTabs);
        } else if (appDesign.data?.layout !== values.layout) {
          await deleteAppDesignLayoutTabsMutation.mutateAsync();
          tabsResult = await createAppDesignLayoutTabsMutation.mutateAsync(convertedTabs);
        } else if (
          // order changed ?
          !equals(
            map(it => it.id, convertedTabs),
            map(it => it.id, appDesignLayoutTabsQuery.data),
          ) ||
          hasMissingTabsNameLocales
        ) {
          tabsResult = await editAppDesignLayoutTabsMutation.mutateAsync(convertedTabs);
        }

        tabsResult = tabsResult?.data || tabsResult;

        if (tabsResult && (!allWidgetsQuery.data.length || appDesign.data?.layout !== values.layout)) {
          const widgets = values.appLayoutTabs.flatMap(tab => {
            const newTabId = tabsResult.find(
              tabResult => tabResult.order === tab.order && tabResult.type === tab.type,
            ).id;

            return tab.features.flatMap(feature => ({ ...feature.props, tabId: newTabId }));
          });

          const updatedWidgets = await createOrEditAllWidgetsMutation.mutateAsync(widgets);
          const widgetsIdsWithoutTab = updatedWidgets.filter(it => !it.tabId).map(it => Number(it.id));
          if (widgetsIdsWithoutTab.length) {
            await deleteWidgetsMutation.mutateAsync({ ids: widgetsIdsWithoutTab, shouldReplaceState: true });
          }
        }
        // fix case when old application does not have saved widgets, open tab menu item -> infinite loading on features tab
        else if (values.appLayoutTabs.some(tab => tab.features.some(widget => !widget.props.id))) {
          const widgets = values.appLayoutTabs.flatMap(tab => tab.features.map(widget => widget.props));

          await createOrEditAllWidgetsMutation.mutateAsync(widgets);
        }
      }

      const doneSteps = calculateAppDesignProgress(appDesignResult.data);
      await updateAppDesign.mutateAsync({
        ...appDesignResult.data,
        progress: { doneSteps, allSteps: appDesignProgressAllSteps },
      });

      return {
        appDesignResult,
        tabsResult,
      };
    },
    {
      onSuccess: async ({ appDesignResult, tabsResult }) => {
        replaceAppDesignInCache(appDesignResult);
        if (tabsResult) {
          replaceCustomTabsInCache(tabsResult);
        }
      },
      onError: () => {
        openSnackbar(t('common.netError'), { severity: 'error' });
      },
    },
  );

  const isAllDataFilled = useMemo(() => {
    if (!appDesign.isSuccess) return false;

    const { layout, colorTheme, customColors, appIcon, signIn } = appDesign.data;

    const hasColorTheme =
      (colorTheme === ColorThemeEnum.CUSTOM && customColors.primary && customColors.secondary) || Boolean(colorTheme);

    return Boolean(
      layout &&
        hasColorTheme &&
        appIcon.backgroundColor &&
        appIcon.link &&
        signIn.backgroundImageOverlayColor &&
        (typeof signIn.predefinedImageId === 'number' || signIn.customImageId) &&
        (signIn.logoLink || signIn.logoDisabled),
    );
  }, [appDesign.isSuccess, appDesign.data]);

  const appLocales = useMemo(
    () => (appDesign.isSuccess && appDesign.data?.locales?.length ? appDesign.data.locales : ['en_US']),
    [appDesign.isSuccess, appDesign.data?.locales],
  );

  return {
    appDesign,
    updateAppDesign,
    submitAppDesign,
    unsubmitAppDesign,
    uploadAppIcon,
    uploadSignInBackgroundImagePredefined,
    uploadMainTabHeaderIcon,
    uploadSignInLogo,
    uploadSignInScreenImages,
    saveAppDesignForm,
    isAllDataFilled,
    isViewMode,
    isArchived,
    appLocales,
    demo,
  };
};

export default useClientApplicationDesign;
