import React, { FC } from 'react';
import { Form, Formik } from 'formik';
import { withFormikDevtools } from 'formik-devtools-extension';
import { FormikScrollToError } from '@egym/ui';
import MoveWidgetsFormFields from './MoveWidgetsFormFields';
import { MoveWidgetsFormProps } from './MoveWidgetsFormProps';

const MoveWidgetsForm: FC<MoveWidgetsFormProps> = ({ formProps, previewerPalette, previewerState, tabsOptions }) => {
  return formProps ? (
    <Formik {...formProps}>
      {formikProps =>
        withFormikDevtools(
          formikProps,
          <Form placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            <FormikScrollToError>
              <MoveWidgetsFormFields
                currentWidgets={formikProps.values.currentWidgets}
                previewerPalette={previewerPalette}
                previewerState={previewerState}
                tabsOptions={tabsOptions}
              />
            </FormikScrollToError>
          </Form>,
        )
      }
    </Formik>
  ) : null;
};

export default MoveWidgetsForm;
