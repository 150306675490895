import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const PlusIcon: React.FC<SvgIconProps> = ({ sx, htmlColor = '#4C4A4A', ...rest }) => {
  return (
    <SvgIcon viewBox="0 0 24 24" sx={{ width: '20px', height: '20px', ...sx }} {...rest}>
      <g fill="none" fillRule="inherit">
        <g>
          <g>
            <path d="M0 0H24V24H0z" transform="translate(-164 -1060) translate(164 1060)" />
            <path
              fill={htmlColor}
              d="M12 4c.552 0 1 .448 1 1v5.999L19 11c.552 0 1 .448 1 1s-.448 1-1 1l-6-.001V19c0 .552-.448 1-1 1s-1-.448-1-1v-6.001L5 13c-.552 0-1-.448-1-1s.448-1 1-1l6-.001V5c0-.552.448-1 1-1z"
              transform="translate(-164 -1060) translate(164 1060)"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export default PlusIcon;
