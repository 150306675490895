import React, { FC, useCallback, useRef } from 'react';
import { Stack } from '@mui/material';
import { FormikProps } from 'formik';
import { motion } from 'framer-motion';
import { Button, Divider } from '@egym/ui';
import { DrawerWarnings } from '@constants';
import { getRemainingFullTabToCompactWidgets } from '@helpers';
import { AppDesignLayoutTabWidget, MoveWidgetsFormValues, RemainingWidget, WidgetWithTab } from '@types';
import { AddFullTabAndMoveRemainingFeatures } from '../ActionRequired';
import { AppPreviewerPalette, AppPreviewerTab, fullTabToCompactFeatureMap, PreviewerState } from '../AppPreviewer';
import { FeatureUsedAlert, NotificationPositionAlert } from '../Warnings';

type Props = {
  onBackButtonClick?: () => void; // passed from Drawer
  widget: WidgetWithTab;
  group: AppDesignLayoutTabWidget;
  onAppendWidget: (widget: WidgetWithTab, remainingWidgets?: RemainingWidget[]) => void;
  warnings: DrawerWarnings[];
  appLayoutTabs: AppPreviewerTab[];
  previewerPalette: AppPreviewerPalette;
  previewerState: Partial<PreviewerState>;
};

const WarningsDrawerPage: FC<Props> = ({
  widget,
  group,
  onAppendWidget,
  onBackButtonClick,
  warnings,
  appLayoutTabs,
  previewerPalette,
  previewerState,
}) => {
  const moveRemainingFeaturesFormRef = useRef<FormikProps<MoveWidgetsFormValues>>(null);

  const onConfirm = useCallback(async () => {
    if (moveRemainingFeaturesFormRef.current) {
      await moveRemainingFeaturesFormRef.current.setTouched({
        currentWidgets: moveRemainingFeaturesFormRef.current.values.currentWidgets.map(() => ({
          newTabId: true,
        })),
      });
      await moveRemainingFeaturesFormRef.current.submitForm();

      if (moveRemainingFeaturesFormRef.current.isValid) {
        // change widget names from full tab to compact
        const fullTabToCompactWidgets = getRemainingFullTabToCompactWidgets(
          appLayoutTabs,
          moveRemainingFeaturesFormRef.current.values.currentWidgets,
        )
          .map(it => ({
            props: it,
            newTabId: it.tabId,
            prevTabId: it.tabId,
            shouldBeRemoved: false,
          }))
          .filter(it => it.props.name !== fullTabToCompactFeatureMap[widget.name]);

        onAppendWidget(widget, [
          ...moveRemainingFeaturesFormRef.current.values.currentWidgets.map(it => ({
            props: it.props,
            newTabId: it.newTabId,
            prevTabId: it.props.tabId,
            shouldBeRemoved: it.shouldBeRemoved,
          })),
          ...fullTabToCompactWidgets,
        ]);
      }
    } else {
      onAppendWidget(widget);
    }
  }, [appLayoutTabs, onAppendWidget, widget]);

  return (
    <Stack sx={{ height: '100%' }}>
      <Stack sx={{ flex: 1, position: 'relative' }}>
        <Stack
          sx={{ p: 8, maxWidth: '710px', minWidth: '634px', flex: 1, boxSizing: 'border-box' }}
          spacing={8}
          divider={<Divider orientation="horizontal" variant="fullWidth" flexItem />}
        >
          {warnings.map(warning => {
            switch (warning) {
              case DrawerWarnings.FeatureUsed:
                return (
                  <FeatureUsedAlert
                    key={warning}
                    widget={widget}
                    group={group}
                    currentLocale={previewerState.selectedLocale}
                  />
                );
              case DrawerWarnings.NotificationsPosition:
                return <NotificationPositionAlert key={warning} widget={widget} />;
              case DrawerWarnings.MoveFeaturesToAnotherTabForm:
                return (
                  <AddFullTabAndMoveRemainingFeatures
                    moveRemainingFeaturesFormRef={moveRemainingFeaturesFormRef}
                    key={warning}
                    widget={widget}
                    appLayoutTabs={appLayoutTabs}
                    previewerPalette={previewerPalette}
                    previewerState={previewerState}
                  />
                );
              default:
                return null;
            }
          })}
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          sx={{ position: 'sticky', bottom: '0', boxShadow: 5, px: 7, py: 5, bgcolor: 'common.white', zIndex: 10 }}
          component={motion.div}
          initial={{ translateY: '100%' }}
          animate={{ translateY: 0 }}
          exit={{ translateY: '100%' }}
          transition={{ duration: 0.3 }}
        >
          <Button
            text="common.buttons.add"
            sx={{ minWidth: '140px', mr: 5 }}
            variant="contained"
            color="primary"
            onClick={onConfirm}
          />
          <Button
            text="common.buttons.back"
            sx={{ minWidth: '140px' }}
            variant="outlined"
            color="inherit"
            onClick={onBackButtonClick}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default WarningsDrawerPage;
