import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const RoRoFlagIcon: React.FC<SvgIconProps> = ({ sx, ...props }) => {
  return (
    <SvgIcon viewBox="0 0 640 480" {...props} sx={{ width: '18px', ...sx }}>
      <g data-name="Layer 2" id="Layer_2">
        <g data-name="flag  en-us" id="flag_en-us">
          <rect fill="#e10017" height="480" width="214" x="426" />
          <rect fill="#002884" height="480" width="214" />
          <rect fill="#ffd100" height="480" width="214" x="213" />
        </g>
      </g>
    </SvgIcon>
  );
};

export default RoRoFlagIcon;
