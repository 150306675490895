import {
  MainTabHeaderDefaultIcon2,
  MainTabHeaderDefaultIcon3,
  MainTabHeaderDefaultIcon4,
  MainTabHeaderDefaultIcon5,
  MainTabHeaderDefaultIcon6,
  MainTabHeaderDefaultIcon7,
  MainTabHeaderDefaultIcon8,
  MainTabHeaderDefaultIcon9,
  MainTabHeaderDefaultIcon10,
  MainTabHeaderDefaultIcon11,
  MainTabHeaderDefaultIcon12,
  MainTabHeaderDefaultIcon13,
  MainTabHeaderDefaultIcon14,
  MainTabHeaderDefaultIcon15,
  MainTabHeaderDefaultIcon16,
  MainTabHeaderDefaultIcon17,
  MainTabHeaderDefaultIcon18,
  MainTabHeaderDefaultIcon19,
  MainTabHeaderDefaultIcon20,
  MainTabHeaderDefaultIcon21,
  MainTabHeaderDefaultIcon22,
  MainTabHeaderDefaultIcon23,
  MainTabHeaderDefaultIcon24,
  MainTabHeaderDefaultIcon25,
  MainTabHeaderDefaultIcon26,
  MainTabHeaderDefaultIcon27,
  MainTabHeaderDefaultIcon28,
  MainTabHeaderDefaultIcon29,
  MainTabHeaderDefaultIcon30,
  MainTabHeaderDefaultIcon31,
} from '@svg';
import { PredefinedTabIcon } from '@types';

export const predefinedMainTabIcons: PredefinedTabIcon[] = [
  {
    id: 7,
    path: '/images/previewerDefaultImages/mainTabHeaderIcons/mainTabHeaderDefaultIcon7.svg',
    component: MainTabHeaderDefaultIcon7,
  },
  {
    id: 12,
    path: '/images/previewerDefaultImages/mainTabHeaderIcons/mainTabHeaderDefaultIcon12.svg',
    component: MainTabHeaderDefaultIcon12,
  },
  {
    id: 2,
    path: '/images/previewerDefaultImages/mainTabHeaderIcons/mainTabHeaderDefaultIcon2.svg',
    component: MainTabHeaderDefaultIcon2,
  },
  {
    id: 28,
    path: '/images/previewerDefaultImages/mainTabHeaderIcons/mainTabHeaderDefaultIcon28.svg',
    component: MainTabHeaderDefaultIcon28,
  },
  {
    id: 3,
    path: '/images/previewerDefaultImages/mainTabHeaderIcons/mainTabHeaderDefaultIcon3.svg',
    component: MainTabHeaderDefaultIcon3,
  },
  {
    id: 13,
    path: '/images/previewerDefaultImages/mainTabHeaderIcons/mainTabHeaderDefaultIcon13.svg',
    component: MainTabHeaderDefaultIcon13,
  },
  {
    id: 10,
    path: '/images/previewerDefaultImages/mainTabHeaderIcons/mainTabHeaderDefaultIcon10.svg',
    component: MainTabHeaderDefaultIcon10,
  },
  {
    id: 4,
    path: '/images/previewerDefaultImages/mainTabHeaderIcons/mainTabHeaderDefaultIcon4.svg',
    component: MainTabHeaderDefaultIcon4,
  },
  {
    id: 5,
    path: '/images/previewerDefaultImages/mainTabHeaderIcons/mainTabHeaderDefaultIcon5.svg',
    component: MainTabHeaderDefaultIcon5,
  },
  {
    id: 6,
    path: '/images/previewerDefaultImages/mainTabHeaderIcons/mainTabHeaderDefaultIcon6.svg',
    component: MainTabHeaderDefaultIcon6,
  },
  {
    id: 8,
    path: '/images/previewerDefaultImages/mainTabHeaderIcons/mainTabHeaderDefaultIcon8.svg',
    component: MainTabHeaderDefaultIcon8,
  },
  {
    id: 9,
    path: '/images/previewerDefaultImages/mainTabHeaderIcons/mainTabHeaderDefaultIcon9.svg',
    component: MainTabHeaderDefaultIcon9,
  },
  {
    id: 11,
    path: '/images/previewerDefaultImages/mainTabHeaderIcons/mainTabHeaderDefaultIcon11.svg',
    component: MainTabHeaderDefaultIcon11,
  },
  {
    id: 14,
    path: '/images/previewerDefaultImages/mainTabHeaderIcons/mainTabHeaderDefaultIcon14.svg',
    component: MainTabHeaderDefaultIcon14,
  },
  {
    id: 15,
    path: '/images/previewerDefaultImages/mainTabHeaderIcons/mainTabHeaderDefaultIcon15.svg',
    component: MainTabHeaderDefaultIcon15,
  },
  {
    id: 16,
    path: '/images/previewerDefaultImages/mainTabHeaderIcons/mainTabHeaderDefaultIcon16.svg',
    component: MainTabHeaderDefaultIcon16,
  },
  {
    id: 17,
    path: '/images/previewerDefaultImages/mainTabHeaderIcons/mainTabHeaderDefaultIcon17.svg',
    component: MainTabHeaderDefaultIcon17,
  },
  {
    id: 18,
    path: '/images/previewerDefaultImages/mainTabHeaderIcons/mainTabHeaderDefaultIcon18.svg',
    component: MainTabHeaderDefaultIcon18,
  },
  {
    id: 19,
    path: '/images/previewerDefaultImages/mainTabHeaderIcons/mainTabHeaderDefaultIcon19.svg',
    component: MainTabHeaderDefaultIcon19,
  },
  {
    id: 20,
    path: '/images/previewerDefaultImages/mainTabHeaderIcons/mainTabHeaderDefaultIcon20.svg',
    component: MainTabHeaderDefaultIcon20,
  },
  {
    id: 21,
    path: '/images/previewerDefaultImages/mainTabHeaderIcons/mainTabHeaderDefaultIcon21.svg',
    component: MainTabHeaderDefaultIcon21,
  },
  {
    id: 22,
    path: '/images/previewerDefaultImages/mainTabHeaderIcons/mainTabHeaderDefaultIcon22.svg',
    component: MainTabHeaderDefaultIcon22,
  },
  {
    id: 23,
    path: '/images/previewerDefaultImages/mainTabHeaderIcons/mainTabHeaderDefaultIcon23.svg',
    component: MainTabHeaderDefaultIcon23,
  },
  {
    id: 24,
    path: '/images/previewerDefaultImages/mainTabHeaderIcons/mainTabHeaderDefaultIcon24.svg',
    component: MainTabHeaderDefaultIcon24,
  },
  {
    id: 25,
    path: '/images/previewerDefaultImages/mainTabHeaderIcons/mainTabHeaderDefaultIcon25.svg',
    component: MainTabHeaderDefaultIcon25,
  },
  {
    id: 26,
    path: '/images/previewerDefaultImages/mainTabHeaderIcons/mainTabHeaderDefaultIcon26.svg',
    component: MainTabHeaderDefaultIcon26,
  },
  {
    id: 27,
    path: '/images/previewerDefaultImages/mainTabHeaderIcons/mainTabHeaderDefaultIcon27.svg',
    component: MainTabHeaderDefaultIcon27,
  },
  {
    id: 29,
    path: '/images/previewerDefaultImages/mainTabHeaderIcons/mainTabHeaderDefaultIcon29.svg',
    component: MainTabHeaderDefaultIcon29,
  },
  {
    id: 30,
    path: '/images/previewerDefaultImages/mainTabHeaderIcons/mainTabHeaderDefaultIcon30.svg',
    component: MainTabHeaderDefaultIcon30,
  },
  {
    id: 31,
    path: '/images/previewer-default-logo.png',
    component: MainTabHeaderDefaultIcon31,
  },
];
