import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const AfternoonIcon: React.FC<SvgIconProps> = ({ sx, htmlColor = '#4C4A4A', ...rest }) => {
  return (
    <SvgIcon viewBox="0 0 24 24" sx={{ width: '20px', height: '20px', ...sx }} {...rest}>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <path d="M0 0H24V24H0z" transform="translate(-164 -2309) translate(164 2309)" />
            <path
              fill={htmlColor}
              d="M12 20c.552 0 1 .448 1 1v2c0 .552-.448 1-1 1s-1-.448-1-1v-2c0-.552.448-1 1-1zm5.657-2.343c.39-.39 1.024-.39 1.414 0l1.414 1.414c.39.39.39 1.024 0 1.414-.39.39-1.023.39-1.414 0l-1.414-1.414c-.39-.39-.39-1.023 0-1.414zm-12.728 0c.39-.39 1.023-.39 1.414 0 .39.39.39 1.024 0 1.414l-1.414 1.414c-.39.39-1.024.39-1.414 0-.39-.39-.39-1.023 0-1.414zM12 6c3.314 0 6 2.686 6 6s-2.686 6-6 6-6-2.686-6-6 2.686-6 6-6zm0 2c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm-9 3c.552 0 1 .448 1 1s-.448 1-1 1H1c-.552 0-1-.448-1-1s.448-1 1-1zm20 0c.552 0 1 .448 1 1s-.448 1-1 1h-2c-.552 0-1-.448-1-1s.448-1 1-1zM3.514 3.515c.391-.39 1.024-.39 1.415 0l1.414 1.414c.39.39.39 1.023 0 1.414-.39.39-1.024.39-1.415 0L3.514 4.929c-.39-.39-.39-1.024 0-1.414zm15.557 0c.39-.39 1.024-.39 1.414 0 .39.39.39 1.023 0 1.414l-1.414 1.414c-.39.39-1.023.39-1.414 0-.39-.39-.39-1.024 0-1.414zM12 0c.552 0 1 .448 1 1v2c0 .552-.448 1-1 1s-1-.448-1-1V1c0-.552.448-1 1-1z"
              transform="translate(-164 -2309) translate(164 2309)"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export default AfternoonIcon;
