import { alpha } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Tabs, Tab, tabsClasses, tabClasses } from '../../../../navigation/Tabs';

export const StyledTabs = styled(Tabs)`
  & .${tabsClasses.indicator} {
    display: none;
  }
`;

export const StyledTab = styled(Tab)`
  &.${tabClasses.root} {
    border: ${({ theme }) => `1px solid ${theme.palette.grey[300]}`};
    text-transform: uppercase;
    font-size: 12px;
    padding: 0;
    min-height: 24px;
    &:first-of-type {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
    &:last-of-type {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
  &.${tabClasses.textColorInherit} {
    color: ${({ theme }) => theme.palette.grey[600]};
    opacity: 1;
    letter-spacing: 0.43px;
  }
  &.${tabClasses.selected} {
    border: ${({ theme }) => `1px solid ${theme.palette.primary.main}`};
    background-color: ${({ theme }) => alpha(theme.palette.primary.main, 0.08)};
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;
