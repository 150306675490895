import React, { useMemo } from 'react';
import { SxProps } from '@mui/system';
import { isValid } from 'date-fns';
import { FieldProps, useFormikContext } from 'formik';
import { FormFieldWithLabel } from '@egym/ui';
import { getByStringPath } from '@egym/utils';
import useFormatDate from '../../../hooks/useFormatDate';
import { Typography } from '../../dataDisplay/Typography';
import { TimePicker, TimePickerProps } from '../TimePicker';

type Props = {
  id?: string;
  label?: string;
  name: string;
  wrapperSx?: SxProps;
  labelSx?: SxProps;
  fieldSx?: SxProps;
  viewModeFieldSx?: SxProps;
  isViewMode?: boolean;
  hiddenLabel?: boolean;
  parser?: (value: Date) => Date;
  viewModeFormat?: string;
} & FieldProps &
  TimePickerProps;

export const TimePickerFormField: React.FC<Props> = ({
  wrapperSx,
  labelSx,
  fieldSx,
  viewModeFieldSx,
  isViewMode,
  viewModeFormat,
  field,
  form,
  helperText,
  parser,
  label,
  hiddenLabel,
  id,
  ...props
}) => {
  const { setFieldValue, setFieldTouched } = useFormikContext();

  const value = useMemo(() => {
    if (!field.value) return null;

    return field.value instanceof Date && !Number.isNaN(field.value.valueOf()) ? field.value : new Date(field.value);
  }, [field.value]);

  const getByFieldName = useMemo(() => getByStringPath(field.name), [field.name]);
  const hasError = useMemo(() => {
    return Boolean(getByFieldName(form.touched) && getByFieldName(form.errors));
  }, [getByFieldName, form.touched, form.errors]);

  const { format } = useFormatDate();

  return (
    <FormFieldWithLabel
      wrapperSx={wrapperSx}
      labelSx={labelSx}
      label={label}
      hiddenLabel={hiddenLabel}
      id={id}
      isViewMode={isViewMode}
    >
      {({ fieldSx: labelFieldSx, viewModeFieldSx: labelViewModeFieldSx, helperTextSx: labelHelperTextSx }) => {
        const typographySx = { ...labelViewModeFieldSx, ...viewModeFieldSx };
        const timePickerSx = { ...labelFieldSx, ...fieldSx };

        return isViewMode ? (
          <Typography variant="body1" sx={typographySx}>
            {field.value && isValid(field.value) ? format(field.value, viewModeFormat) : '-'}
          </Typography>
        ) : (
          <TimePicker
            {...props}
            {...field}
            onChange={timeValue => {
              setFieldValue(field.name, parser && timeValue ? parser(timeValue as Date) : timeValue, true);
            }}
            onBlur={() => setFieldTouched(field.name, true, true)}
            value={value}
            label={hiddenLabel ? null : label}
            error={hasError}
            helperText={helperText || (hasError && getByFieldName(form.errors))}
            sx={timePickerSx}
            FormHelperTextProps={{
              sx: labelHelperTextSx,
              ...props.FormHelperTextProps,
            }}
          />
        );
      }}
    </FormFieldWithLabel>
  );
};
