import { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { inspectletTrackEvent, pushToDataLayer } from '@egym/analytics';
import { localeState } from '@egym/ui';
import { AnalyticsEvents } from '@constants';
import {
  useAppDesignProgress,
  useAppSettingsProgress,
  useClientApplication,
  useClientApplicationDesign,
  useClientApplicationSettings,
} from '@hooks';
import {
  ApplicationDesignStatus,
  ApplicationLayout,
  ApplicationPackage,
  ApplicationSettingsStatus,
  ApplicationStatus,
  ApplicationType,
} from '@types';

export type TrackProps = {
  applicationName?: string;
  applicationLayout?: ApplicationLayout;
  applicationPackage?: ApplicationPackage;
  applicationType?: ApplicationType;
  applicationStatus?: ApplicationStatus;
  applicationDesignStatus?: ApplicationDesignStatus;
  applicationSettingsStatus?: ApplicationSettingsStatus;
  appDesignProgress?: number;
  appSettingsProgress?: number;
  selectedLocale?: string;
  selectedTab?: string;
  language?: string;
  form_name?: 'Design_Form' | 'Settings_Form';
  page?: string;
};

const useTrackEvent = applicationUuid => {
  const { applicationResponse } = useClientApplication(applicationUuid);
  const { appDesign } = useClientApplicationDesign(applicationUuid);
  const { applicationSettings } = useClientApplicationSettings(applicationUuid);
  const { doneStepsCount: appDesignDoneSteps, allStepsCount: appDesignAllSteps } =
    useAppDesignProgress(applicationUuid);
  const { doneStepsCount: appSettingsDoneSteps, allStepsCount: appSettingsAllSteps } =
    useAppSettingsProgress(applicationUuid);
  const selectedLocaleCode = useRecoilValue(localeState.atoms.currentLocale);

  return useCallback(
    (eventName: AnalyticsEvents, properties: TrackProps = {}) => {
      const propertiesResult = {
        applicationName: applicationResponse.data?.name || '-- Not inside app --',
        applicationLayout: applicationResponse.data?.layout,
        applicationPackage: applicationResponse.data?.appPackage,
        applicationType: applicationResponse.data?.appType,
        appDesignProgress: `${appDesignDoneSteps}/${appDesignAllSteps}`,
        appSettingsProgress: `${appSettingsDoneSteps}/${appSettingsAllSteps}`,
        applicationStatus: applicationResponse.data?.status,
        applicationDesignStatus: appDesign.data?.status,
        applicationSettingsStatus: applicationSettings.data?.status,
        selectedLocale: selectedLocaleCode,
        ...properties,
      };

      inspectletTrackEvent(eventName, propertiesResult);
      pushToDataLayer({
        event: eventName,
        properties: propertiesResult,
      });
    },
    [
      applicationResponse.data,
      appDesignDoneSteps,
      appDesignAllSteps,
      appSettingsDoneSteps,
      appSettingsAllSteps,
      appDesign.data,
      applicationSettings.data,
      selectedLocaleCode,
    ],
  );
};

export default useTrackEvent;
