const useMouseEvents = (mouseDownHandler, mouseMoveHandler, mouseUpHandler) => {
  return event => {
    let positions = mouseDownHandler(event);

    const onMouseMove = mouseMoveEvent => {
      positions = mouseMoveHandler(mouseMoveEvent, positions) || positions;
    };

    window.addEventListener('mousemove', onMouseMove);

    window.addEventListener(
      'mouseup',
      mouseUpEvent => {
        window.removeEventListener('mousemove', onMouseMove);

        if (mouseUpHandler) {
          mouseUpHandler(mouseUpEvent, positions);
        }
      },
      { once: true },
    );
  };
};

export default useMouseEvents;
