import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { alpha, Paper, Stack } from '@mui/material';
import { Box } from '@egym/ui';
import { IconWrapper } from '../Icons';
import ArrowBackIcon from '../Icons/ArrowBackIcon';
import EditPencilIcon from '../Icons/EditPencilIcon';
import WellDoneProgressCircleIcon from '../Icons/WellDoneProgressCircleIcon';
import { PreviewerWellDoneRouteProps } from './PreviewerWellDoneRouteProps';

const PreviewerWellDoneRoute: React.FC<PreviewerWellDoneRouteProps> = ({ palette, shouldInvertColors }) => {
  const { t } = useTranslation();
  return (
    <Box style={{ position: 'relative', background: 'white', height: '100%' }}>
      <Stack
        className="hide-scrollbar"
        alignItems="center"
        style={{
          width: '100%',
          boxSizing: 'border-box',
          background: `linear-gradient(0deg, rgb(241, 242, 244), rgb(255,255,255))`,
          height: 'calc(100% - 138px)',
          overflow: 'scroll',
        }}
      >
        <Stack
          alignItems="center"
          style={{
            width: '100%',
            boxSizing: 'border-box',
            background:
              'url(/images/previewerDefaultImages/features/well_done_background_confetti.png) no-repeat center top',
            backgroundSize: '300px',
            zIndex: 2,
          }}
        >
          <IconWrapper bgColor={palette.primaryTransparent} wrapperSx={{ ml: '15px', mt: '36px', mr: 'auto' }}>
            <ArrowBackIcon htmlColor={palette.primary} />
          </IconWrapper>
          <Stack
            alignItems="center"
            style={{ width: '100%', height: '130px', marginTop: '10px', position: 'relative' }}
          >
            <WellDoneProgressCircleIcon
              htmlColor={shouldInvertColors ? palette.secondary : palette.primary}
              sx={{ height: '130px' }}
            />
            <Stack
              alignItems="center"
              spacing="0px"
              sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
            >
              <Stack direction="row">
                <span
                  style={{
                    fontSize: '28px',
                    fontWeight: 700,
                    color: '#212121',
                  }}
                >
                  7
                </span>
                <span
                  style={{
                    fontSize: '16px',
                    fontWeight: 500,
                    paddingTop: '10px',
                    color: '#212121',
                  }}
                >
                  /12
                </span>
              </Stack>
              <span
                style={{
                  fontSize: '14px',
                  fontWeight: 500,
                  color: 'rgb(76, 74, 74)',
                }}
              >
                {t('appPreviewer.labels.exercises') as ReactNode}
              </span>
            </Stack>
          </Stack>
          <span
            style={{
              fontSize: '32px',
              fontWeight: 700,
              marginTop: '20px',
              marginBottom: '15px',
              color: '#212121',
            }}
          >
            {t('appPreviewer.labels.wellDone') as ReactNode}
          </span>
          <Stack
            alignItems="center"
            direction="row"
            sx={{
              backgroundColor: shouldInvertColors ? alpha(palette.secondary, 0.1) : palette.primaryTransparent,
              color: shouldInvertColors ? palette.secondary : palette.primary,
              fontSize: '11px',
              fontWeight: 500,
              px: 1.5,
              py: 1.3,
              borderRadius: '4px',
            }}
          >
            190 {t('appPreviewer.labels.activityPoints') as ReactNode}
          </Stack>

          <Paper
            sx={{
              background: 'transparent',
              width: '100%',
              boxSizing: 'border-box',
              mt: '30px',
              boxShadow: 'none',
              px: '15px',
            }}
          >
            <Stack spacing="10px" style={{ padding: '14px', borderRadius: '8px', background: 'white' }}>
              <span
                style={{
                  fontSize: '12px',
                  fontWeight: 500,
                  color: '#212121',
                }}
              >
                {t('appPreviewer.labels.workoutSummary') as ReactNode}
              </span>
              <Stack direction="row" spacing="10px">
                <Paper
                  style={{
                    flexBasis: '33%',
                    padding: '10px',
                    backgroundColor: 'rgb(249, 249, 249)',
                    boxShadow: 'none',
                  }}
                >
                  <Stack spacing="5px">
                    <span style={{ fontSize: '11px', fontWeight: 500, color: 'rgb(76, 74, 74)' }}>
                      {t('appPreviewer.labels.energy') as ReactNode}
                    </span>
                    <span style={{ fontSize: '12px', fontWeight: 500, marginTop: '2px', color: '#212121' }}>
                      {t('appPreviewer.labels.kcalCount', { count: 355 }) as ReactNode}
                    </span>
                  </Stack>
                </Paper>
                <Paper
                  style={{
                    flexBasis: '34%',
                    padding: '10px',
                    backgroundColor: 'rgb(249, 249, 249)',
                    boxShadow: 'none',
                  }}
                >
                  <Stack spacing="5px">
                    <span style={{ fontSize: '11px', fontWeight: 500, color: 'rgb(76, 74, 74)' }}>
                      {t('appPreviewer.labels.time') as ReactNode}
                    </span>
                    <span style={{ fontSize: '12px', fontWeight: 500, marginTop: '2px', color: '#212121' }}>
                      {t('appPreviewer.labels.minutesAndSecondsCount', { mCount: 31, sCount: 20 }) as ReactNode}
                    </span>
                  </Stack>
                </Paper>
                <Paper
                  style={{
                    flexBasis: '33%',
                    padding: '10px',
                    backgroundColor: 'rgb(249, 249, 249)',
                    boxShadow: 'none',
                  }}
                >
                  <Stack spacing="5px">
                    <span style={{ fontSize: '11px', fontWeight: 500, color: 'rgb(76, 74, 74)' }}>
                      {t('appPreviewer.labels.distance') as ReactNode}
                    </span>
                    <span style={{ fontSize: '12px', fontWeight: 500, marginTop: '2px', color: '#212121' }}>
                      {t('appPreviewer.labels.kmCount', { count: 1 }) as ReactNode}
                    </span>
                  </Stack>
                </Paper>
              </Stack>
              <Paper style={{ padding: '10px', backgroundColor: 'rgb(249, 249, 249)', boxShadow: 'none' }}>
                <Stack direction="row" justifyContent="space-between">
                  <Stack spacing="5px">
                    <span style={{ fontSize: '11px', fontWeight: 500, color: 'rgb(76, 74, 74)' }}>
                      {t('appPreviewer.labels.date') as ReactNode}
                    </span>
                    <span style={{ fontSize: '12px', fontWeight: 500, marginTop: '2px', color: '#212121' }}>
                      {t('appPreviewer.labels.wellDoneDate') as ReactNode}
                    </span>
                  </Stack>
                  <IconWrapper bgColor="rgba(33, 33, 33, 0.1)">
                    <EditPencilIcon htmlColor={palette.primary} />
                  </IconWrapper>
                </Stack>
              </Paper>
            </Stack>
          </Paper>
        </Stack>
      </Stack>

      <Stack
        sx={{
          background: 'white',
          px: '15px',
          pb: '30px',
          alignItems: 'center',
          zIndex: 3,
          boxShadow: '0 -0.7px 0 rgba(0, 0, 0, 0.2)',
        }}
      >
        <Paper
          sx={{
            background: palette.primary,
            borderRadius: '8px',
            height: '38px',
            lineHeight: '38px',
            width: '100%',
            mt: '16px',
            color: 'white',
            fontSize: '14px',
            textAlign: 'center',
          }}
        >
          {t('appPreviewer.labels.saveWorkout') as ReactNode}
        </Paper>

        <Paper
          elevation={0}
          sx={{
            background: '#eee',
            color: palette.primary,
            borderRadius: '8px',
            height: '38px',
            lineHeight: '38px',
            width: '100%',
            mt: '16px',
            fontSize: '14px',
            textAlign: 'center',
          }}
        >
          {t('appPreviewer.labels.saveAndCreateTemplate') as ReactNode}
        </Paper>
      </Stack>
    </Box>
  );
};

export default PreviewerWellDoneRoute;
