import React, { useMemo } from 'react';
import { SxProps } from '@mui/system';
import { FieldProps } from 'formik';
import { getByStringPath } from '@egym/utils';
import { FormFieldWithLabel } from '../FormFieldWithLabel';
import { RadioGroup, Props as RadioGroupProps } from '../RadioGroup';

type RadioGroupFormFieldProps = {
  label?: string;
  wrapperSx?: SxProps;
  optionsWrapperSx?: SxProps;
  fieldSx?: SxProps;
  labelSx?: SxProps;
  hiddenLabel?: boolean;
  isViewMode?: boolean;
  helperText?: string;
};

type Props = FieldProps & RadioGroupProps & RadioGroupFormFieldProps;

export const RadioGroupFormField: React.FC<Props> = ({
  wrapperSx,
  optionsWrapperSx,
  labelSx,
  fieldSx,
  label,
  hiddenLabel,
  field,
  form,
  helperText,
  isViewMode,
  ...rest
}) => {
  const getByFieldName = useMemo(() => getByStringPath(field.name), [field.name]);

  return (
    <FormFieldWithLabel
      wrapperSx={wrapperSx}
      labelSx={labelSx}
      label={label}
      hiddenLabel={hiddenLabel}
      isViewMode={isViewMode}
    >
      {({ fieldSx: labelFieldSx, viewModeFieldSx: labelViewModeFieldSx, helperTextSx: labelHelperTextSx }) => {
        const radioGroupWrapperSx = { ...labelFieldSx, ...fieldSx };

        return (
          <RadioGroup
            name={field.name}
            value={field.value}
            onChange={field.onChange}
            error={Boolean(getByFieldName(form.errors))}
            helperText={helperText || (getByFieldName(form.touched) && getByFieldName(form.errors))}
            FormHelperTextProps={{
              sx: labelHelperTextSx,
              ...rest.FormHelperTextProps,
            }}
            isViewMode={isViewMode}
            wrapperSx={radioGroupWrapperSx}
            optionsWrapperSx={optionsWrapperSx}
            viewModeSx={labelViewModeFieldSx}
            {...rest}
          />
        );
      }}
    </FormFieldWithLabel>
  );
};
