import { subBusinessDays } from 'date-fns';

const today = new Date();

export const mockActivityHistory = [
  {
    id: 0,
    image: '/images/chessPressIcon.png',
    date: subBusinessDays(today, 1),
    name: 'appPreviewer.labels.chessPress',
    activityPoints: 90,
    breadcrumbs: [
      {
        text: 'appPreviewer.labels.setsCount',
        params: { count: 1 },
      },
      {
        text: 'appPreviewer.labels.repsCounts',
        params: { count: 8 },
      },
      {
        text: 'appPreviewer.labels.kgCount',
        params: { count: 20 },
      },
    ],
  },
  {
    id: 1,
    image: '/images/chessPressIcon.png',
    date: subBusinessDays(today, 3),
    name: 'appPreviewer.labels.chessPress',
    activityPoints: 90,
    breadcrumbs: [
      {
        text: 'appPreviewer.labels.setsCount',
        params: { count: 1 },
      },
      {
        text: 'appPreviewer.labels.repsCounts',
        params: { count: 8 },
      },
      {
        text: 'appPreviewer.labels.kgCount',
        params: { count: 20 },
      },
    ],
  },
  {
    id: 2,
    image: '/images/dailyRoutineIcon.png',
    date: subBusinessDays(today, 4),
    name: 'appPreviewer.labels.dailyRoutine',
    activityPoints: 10,
    breadcrumbs: [
      {
        text: '00:05:00',
      },
      {
        text: 'appPreviewer.labels.miCount',
        params: { count: 5 },
      },
      {
        text: 'appPreviewer.labels.kcalCount',
        params: { count: 98 },
      },
    ],
  },
];
