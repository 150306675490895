import { useRef, useState, KeyboardEvent, useCallback } from 'react';
import { Option } from '@egym/types';
import { NestedMenuItemContainerProps, UseNestedMenuItemResultProps } from '../../NestedMenuItemProps';

const useNestedMenuItem = ({ onSelectChildOption }: NestedMenuItemContainerProps): UseNestedMenuItemResultProps => {
  const [open, setOpen] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);
  const menuItemRef = useRef<HTMLLIElement>(null);
  const menuContainerRef = useRef<HTMLDivElement>(null);

  const handleMouseEnter = useCallback(() => {
    setOpen(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setOpen(false);
  }, []);

  // Check if any immediate children are active
  const isSubmenuFocused = () => {
    const active = containerRef.current?.ownerDocument?.activeElement;
    // eslint-disable-next-line no-restricted-syntax
    for (const child of menuContainerRef.current?.children ?? []) {
      if (child === active) {
        return true;
      }
    }
    return false;
  };

  const handleKeyDown = useCallback((event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Escape') {
      return;
    }

    if (isSubmenuFocused()) {
      event.stopPropagation();
    }

    const active = containerRef.current?.ownerDocument?.activeElement;

    if (event.key === 'ArrowLeft' && isSubmenuFocused()) {
      containerRef.current?.focus();
    }

    if (event.key === 'ArrowRight' && event.target === containerRef.current && event.target === active) {
      const firstChild = menuContainerRef.current?.children[0] as HTMLElement | undefined;
      firstChild?.focus();
    }
  }, []);

  const onSelectChildOptionAndClose = useCallback(
    (option: Option) => {
      if (onSelectChildOption) onSelectChildOption(option);

      setOpen(false);
    },
    [onSelectChildOption],
  );

  return {
    open,
    setOpen,
    handleKeyDown,
    handleMouseEnter,
    handleMouseLeave,
    containerRef,
    menuItemRef,
    menuContainerRef,
    onSelectChildOptionAndClose,
  };
};

export default useNestedMenuItem;
