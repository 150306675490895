import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { Box, Stack } from '@egym/ui';
import useAppPreviewerState from '../../hooks/useAppPreviewerState';
import EmptyFeaturesSkeleton from './components/EmptyFeaturesSkeleton';
import PreviewerFooter from './components/PreviewerFooter';
import PreviewHeader from './components/PreviewHeader';
import { PreviewerLayoutRouteProps } from './PreviewerLayoutRouteProps';

const transition = {
  type: 'tween',
  duration: 0.4,
};

const PreviewerLayoutRoute: React.FC<PreviewerLayoutRouteProps> = ({
  id,
  tabsResult,
  currentFeatures,
  hideLayoutRouteFooter,
  disabled,
  appLayoutScrollRef,
  isMainTabSelected,
  activeWidgetId,
  activeWidgetGroupId,
  isActiveFullTabWidget,
}) => {
  const { palette, previewerState, changeSelectedTab } = useAppPreviewerState(id);
  const { t } = useTranslation();

  const gradient = useMemo(
    () => `linear-gradient(135deg, ${palette.dashboard3BackgroundPrimary}, ${palette.dashboard3BackgroundSecondary})`,
    [palette.dashboard3BackgroundPrimary, palette.dashboard3BackgroundSecondary],
  );

  return (
    <Box
      style={{
        background: palette.primary,
        position: 'relative',
        height: '100%',
      }}
    >
      <>
        <Box
          className="hide-scrollbar"
          ref={appLayoutScrollRef}
          sx={{
            height: `calc(100% - ${hideLayoutRouteFooter ? 0 : 80}px)`,
            overflow: currentFeatures.length ? 'scroll' : 'hidden',
            backgroundImage: gradient,
            backgroundSize: `100% ${170}px`,
          }}
        >
          <PreviewHeader
            gradient={gradient}
            palette={palette}
            previewerId={id}
            previewerState={previewerState}
            showWelcomeScreen={isMainTabSelected}
            activeWidgetId={isActiveFullTabWidget ? undefined : activeWidgetId}
          />
          <Stack
            id="app-layout-content"
            sx={{
              height: 'auto',
              minHeight: currentFeatures.length ? `calc(100% - ${isMainTabSelected ? 170 : 71}px)` : '100%',
              boxSizing: 'border-box',
              position: 'relative',
              background: `linear-gradient(0deg, rgb(241, 242, 244), rgb(255,255,255))`,
              borderTopLeftRadius: '10px',
              borderTopRightRadius: '10px',
              paddingTop: '15px',
              paddingBottom: '20px',
              zIndex: 2,
              '& > *': {
                ':not(:last-child)': {
                  marginBottom: '17px',
                },
              },
            }}
          >
            {currentFeatures.length ? (
              currentFeatures
                .filter(({ props }) => (isActiveFullTabWidget ? props.id === activeWidgetId : true))
                .map(({ component: FeatureComponent, props }) => (
                  <motion.div id={`preview-feature-${props.id}`} key={props.id} layout transition={transition}>
                    <FeatureComponent
                      previewerId={id}
                      activeWidgetId={activeWidgetId}
                      activeWidgetGroupId={activeWidgetGroupId}
                      palette={palette}
                      previewerState={previewerState}
                      widgetProps={props}
                      tFunction={t}
                      wrapperSx={{ filter: activeWidgetId && props.id !== activeWidgetId ? 'blur(4px)' : null }}
                      itemWrapperSx={{ filter: activeWidgetId && props.id !== activeWidgetId ? 'blur(4px)' : null }}
                    />
                  </motion.div>
                ))
            ) : (
              <EmptyFeaturesSkeleton />
            )}
          </Stack>
        </Box>
        {!hideLayoutRouteFooter && tabsResult && (
          <PreviewerFooter
            tFunction={t}
            tabs={tabsResult}
            highlightPreviewLayoutFooterButtons={!disabled}
            palette={palette}
            changeSelectedTab={changeSelectedTab}
          />
        )}
      </>
    </Box>
  );
};

export default PreviewerLayoutRoute;
