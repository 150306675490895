import React, { forwardRef, ReactNode } from 'react';
import { Stack, Paper, useFormatDate } from '@egym/ui';
import { getPercent } from '@egym/utils';
import { AppLayoutFeatureComponentProps } from '../../../../AppPreviewerProps';
import PreviewContainer from '../../../PreviewContainer';
import PreviewFeatureTitle from '../../../PreviewFeatureTitle';
import { currentClasses, weekDays } from './data';
import { StyledBreadcrumbs } from './PreviewFindAClassOnTheTab.styles';

const today = new Date();

type Props = AppLayoutFeatureComponentProps & {
  headerBg?: string;
  contentBg?: string;
};

const PreviewFindAClassOnTheTab = forwardRef<HTMLDivElement, Props>(
  ({ tFunction, palette, previewerState, headerBg, contentBg, wrapperSx }, ref) => {
    const { format } = useFormatDate(previewerState.selectedLocale);

    return (
      <div ref={ref}>
        <Stack
          direction="row"
          justifyContent="space-evenly"
          sx={{
            borderBottom: '1px solid',
            borderColor: 'rgb(244, 244, 244)',
            pb: '6px',
            backgroundColor: headerBg,
            ...wrapperSx,
          }}
        >
          {weekDays.map(({ date, currentDay }) => {
            return (
              <Stack
                key={String(date)}
                sx={{
                  color: 'rgb(76, 74, 74)',
                  background: currentDay ? 'rgb(244, 244, 244)' : 'transparent',
                  padding: '6px',
                  borderRadius: '8px',
                }}
                alignItems="center"
              >
                <span style={{ fontSize: '12px', lineHeight: '16px', fontWeight: 500 }}>{format(date, 'eee')}</span>
                <span style={{ fontSize: '16px', lineHeight: '20px', fontWeight: 700 }}>{format(date, 'd')}</span>
                {currentDay && (
                  <div
                    style={{
                      width: '4px',
                      height: '4px',
                      borderRadius: '50%',
                      background: palette.primary,
                      marginTop: '1px',
                    }}
                  />
                )}
              </Stack>
            );
          })}
        </Stack>
        <PreviewContainer wrapperSx={{ pt: '10px', backgroundColor: contentBg }}>
          <PreviewFeatureTitle tFunction={tFunction} palette={palette} title={format(today, 'eee, LLL d')} />
          {currentClasses.map(currentClass => {
            return (
              <Paper
                key={currentClass.id}
                sx={{
                  borderRadius: '8px',
                  boxShadow: '0 4px 6px rgba(223, 223, 223, 0.7)',
                  boxSizing: 'border-box',
                  marginBottom: '10px',
                }}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  sx={{ px: '10px', py: '5px', borderBottom: '1px solid', borderColor: 'rgb(244, 244, 244)' }}
                >
                  <img
                    src="/images/ic16PxTrack@2x.png"
                    alt={currentClass.name}
                    style={{ width: '14px', height: '14px', marginRight: '5px' }}
                  />
                  <StyledBreadcrumbs
                    separator="&bull;"
                    sx={{ fontSize: '13px', color: 'rgb(76, 74, 74)', lineHeight: '20px', mt: '2px' }}
                  >
                    <span>{format(currentClass.date, 'p')}</span>
                    <span>
                      {
                        tFunction('appPreviewer.labels.durationMinutesShort', {
                          minutes: currentClass.durationMinutes,
                        }) as ReactNode
                      }
                    </span>
                  </StyledBreadcrumbs>
                  {currentClass.bookedClass && (
                    <span
                      style={{
                        marginLeft: 'auto',
                        fontSize: '11px',
                        fontWeight: 600,
                        color: 'white',
                        padding: '3px 8px',
                        background: 'rgb(63, 175, 150)',
                        borderRadius: '4px',
                      }}
                    >
                      {tFunction('appPreviewer.labels.booked') as ReactNode}
                    </span>
                  )}
                  {!!currentClass.spotsLeft && (
                    <Stack alignItems="flex-end" sx={{ marginLeft: 'auto' }}>
                      <span
                        style={{
                          fontSize: '11px',
                          fontWeight: 600,
                          textAlign: 'right',
                          marginBottom: '2px',
                          color: currentClass.spotsLeft <= 3 ? 'rgb(245, 166, 35)' : 'rgb(63, 175, 150)',
                        }}
                      >
                        {tFunction('appPreviewer.labels.spotsLeft', { count: currentClass.spotsLeft }) as ReactNode}
                      </span>

                      <div
                        style={{
                          width: '70px',
                          height: '3px',
                          background: 'rgb(235, 235, 235)',
                          borderRadius: '2px',
                          overflow: 'hidden',
                        }}
                      >
                        <div
                          style={{
                            width: `${getPercent(currentClass.spotsLeft, currentClass.totalSpots)}%`,
                            height: '100%',
                            background: currentClass.spotsLeft <= 3 ? 'rgb(245, 166, 35)' : 'rgb(63, 175, 150)',
                            marginLeft: 'auto',
                          }}
                        />
                      </div>
                    </Stack>
                  )}
                </Stack>
                <Stack sx={{ py: '10px', px: '10px' }}>
                  <span style={{ fontSize: '14px', fontWeight: 700, color: 'rgb(37, 37, 37)', marginBottom: '2px' }}>
                    {tFunction(currentClass.name) as ReactNode}
                  </span>
                  <span style={{ color: 'rgb(76, 74, 74)', fontSize: '13px', lineHeight: '18px' }}>
                    {tFunction('appPreviewer.labels.withName', { name: currentClass.trainer }) as ReactNode}
                  </span>
                  <span style={{ color: 'rgb(76, 74, 74)', fontSize: '13px', lineHeight: '18px' }}>
                    {currentClass.gym}
                  </span>
                </Stack>
              </Paper>
            );
          })}
        </PreviewContainer>
      </div>
    );
  },
);

export default PreviewFindAClassOnTheTab;
