import { forEachObjIndexed, mergeAll } from 'ramda';
import { atom, RecoilState, RecoilValueReadOnly, selector } from 'recoil';
import { PaginationQueryParams } from '@egym/api';
import { TableState } from '@egym/ui';

export const filteringPrefix = 'filtering-';

const parsedSearch = new URLSearchParams(window.location.search.replace('?', ''));

export const tableStateAtom = (
  key: string,
  initState: TableState = {},
  filteringPersist?: string[],
): RecoilState<TableState> =>
  atom<TableState>({
    key,
    default: {
      ...initState,
      pagination: {
        size: parseInt(parsedSearch.get('size') as string, 10) || initState?.pagination?.size || 10,
        page: parseInt(parsedSearch.get('page') as string, 10) || initState?.pagination?.page || 0,
      },
      filtering: {
        ...initState.filtering,
        ...mergeAll(
          Array.from(parsedSearch.entries())
            .filter(
              ([paramKey]) =>
                paramKey &&
                paramKey.startsWith(filteringPrefix) &&
                filteringPersist?.includes(paramKey.replace(filteringPrefix, '')),
            )
            .map(([paramKey, paramValue]) => ({
              [paramKey.replace(filteringPrefix, '')]: paramValue,
            })),
        ),
      },
    },
    effects_UNSTABLE: [
      ({ onSet }) => {
        onSet(value => {
          const searchParams = new URLSearchParams();
          searchParams.set('size', `${value.pagination?.size}`);
          searchParams.set('page', `${value.pagination?.page}`);
          if (value.filtering) {
            forEachObjIndexed((filteringValue, filteringKey) => {
              if (filteringPersist?.includes(filteringKey) && (filteringValue || typeof filteringValue === 'boolean')) {
                searchParams.set(`${filteringPrefix}${filteringKey}`, `${filteringValue}`);
              }
            }, value.filtering);
          }
          const newRelativePathQuery = `${window.location.pathname}?${searchParams.toString()}`;
          window.history.pushState(null, '', newRelativePathQuery);
        });
      },
    ],
  });

export const tableQuerySelector = (
  key: string,
  table: RecoilState<TableState>,
): RecoilValueReadOnly<PaginationQueryParams> =>
  selector<PaginationQueryParams>({
    key,
    get: ({ get }) => {
      const tableObject = get(table);
      return {
        sort: tableObject.sorting
          ? Object.entries(tableObject.sorting).map(([field, direction]) => `${field},${direction}`)
          : undefined,
        page: tableObject.pagination?.page,
        size: tableObject.pagination?.size,
        ...tableObject.filtering,
      };
    },
  });
