import axios, { AxiosRequestConfig } from 'axios';

export type CustomAxiosRequestConfig = AxiosRequestConfig & {
  allowForbidden?: boolean;
};

const API = axios.create({});

API.defaults.baseURL = '/';

export const defaultPrefix = '/api/';

export const addApiClientResponseInterceptor = (
  onFulfilled: (value: any) => any | Promise<any>,
  onRejected?: (error: any) => any,
) => {
  API.interceptors.response.use(onFulfilled, onRejected);
};

export const addApiClientRequestInterceptor = (
  onFulfilled: (value: any) => any | Promise<any>,
  onRejected?: (error: any) => any,
) => {
  API.interceptors.request.use(onFulfilled, onRejected);
};

export default API;
