import { useLocation, useParams, useSearchParams } from 'react-router-dom';

const useRouter = <UrlParams = any, State = any>() => {
  const location = useLocation();
  const params = useParams();
  const [queryParams, setQueryParams] = useSearchParams();

  return {
    ...location,
    locationState: (location.state || {}) as unknown as State,
    urlParams: params as unknown as UrlParams,
    queryParams,
    setQueryParams,
  };
};

export default useRouter;
