import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const ShareIcon: React.FC<SvgIconProps> = ({ sx, ...props }) => {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props} sx={{ width: '24px', height: '24px', ...sx }}>
      <defs>
        <filter colorInterpolationFilters="auto" id="a">
          <feColorMatrix
            in="SourceGraphic"
            values="0 0 0 0 0.000000 0 0 0 0 0.000000 0 0 0 0 0.000000 0 0 0 1.000000 0"
          />
        </filter>
      </defs>
      <g filter="url(#a)" fill="none" fillRule="evenodd">
        <path
          d="M8.17 8.073a1 1 0 0 1 .117 1.993l-.116.007H6v9.497h12.021v-9.497h-1.449a1 1 0 0 1-.993-.883l-.007-.117a1 1 0 0 1 .883-.993l.117-.007h2.45a1 1 0 0 1 .993.883l.006.117v11.498a1 1 0 0 1-.883.993l-.117.007H5a1 1 0 0 1-.993-.884L4 20.571V9.073a1 1 0 0 1 .883-.993L5 8.073h3.17zm3.836-5.484 3.772 3.723-1.405 1.424-1.346-1.33-.025 7.562-2-.008.025-7.555L9.708 7.73 8.292 6.318l3.714-3.729z"
          fill="#FFF"
        />
      </g>
    </SvgIcon>
  );
};
