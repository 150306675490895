import React, { cloneElement, ReactElement, useCallback, useState } from 'react';
import EasyCropper, { CropperProps } from 'react-easy-crop';
import { Stack } from '@mui/material';
import { Area, Point } from 'react-easy-crop/types';
import { getCroppedImg } from '@egym/utils';
import { Box } from '../../../../layout';
import { Button } from '../../../Button';
import { AcceptedFile } from '../../FileUploadProps';
import ZoomSlider from './components/ZoomSlider';
import { cropContainerStyles } from './FileUploadCropper.styles';

type Props = Partial<CropperProps> & {
  onCancel?: () => void; // passed from ContentDialog
  droppedFile: AcceptedFile;
  onCropConfirm: (file: any) => void;
  cropperStyle?: CropperProps['style'];
  cropperExtraElement?: ReactElement | null;
};

const FileUploadCropper: React.FC<Props> = ({
  droppedFile,
  crop: initialCrop = { x: 0, y: 0 },
  aspect = 4 / 3,
  minZoom = 1,
  maxZoom = 4,
  onCancel,
  onCropConfirm,
  cropperStyle,
  cropperExtraElement,
}) => {
  const [crop, setCrop] = useState<Point>(initialCrop);
  const [zoom, setZoom] = useState(minZoom);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState({ width: 0, height: 0, x: 0, y: 0 });
  const [cropperAreaSize, setCroppedAreaSize] = useState<{ width: number; height: number }>();

  const handleMediaLoaded = useCallback(() => {
    setTimeout(() => {
      const cropper = document.querySelector('[data-testid="cropper"]');

      if (cropper) {
        const { width, height } = cropper.getBoundingClientRect();
        setCroppedAreaSize({ width, height });
      }
    });
  }, []);

  const onCropComplete = useCallback(async (croppedArea: Area, newCroppedAreaPixels: Area) => {
    setCroppedAreaPixels(newCroppedAreaPixels);
  }, []);

  const handleCropConfirm = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        { imageSrc: droppedFile.preview, imageType: droppedFile.type },
        croppedAreaPixels,
      );

      onCropConfirm(croppedImage);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('crop error', e);
    }
  }, [croppedAreaPixels, droppedFile, onCropConfirm]);

  return (
    <Stack>
      <Box sx={cropContainerStyles}>
        <EasyCropper
          image={droppedFile.preview}
          crop={crop}
          zoom={zoom}
          minZoom={minZoom}
          maxZoom={maxZoom}
          onCropChange={setCrop}
          aspect={aspect}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
          showGrid={false}
          style={cropperStyle}
          onMediaLoaded={handleMediaLoaded}
        />
        {cropperAreaSize && cropperExtraElement && (
          <Box
            sx={{
              pointerEvents: 'none',
              position: 'absolute',
              left: '50%',
              top: '50%',
              transform: 'translate(-50%, -50%)',
              width: `${cropperAreaSize.width}px`,
              height: `${cropperAreaSize.height}px`,
            }}
          >
            {cloneElement(cropperExtraElement, { cropperAreaSize })}
          </Box>
        )}
      </Box>
      <ZoomSlider wrapperSx={{ pt: 4 }} zoom={zoom} minZoom={minZoom} maxZoom={maxZoom} setZoom={setZoom} />
      <Stack direction="row" justifyContent="flex-end" sx={{ pt: 5, pb: 5 }}>
        <Button
          variant="outlined"
          color="primary"
          onClick={onCancel}
          text="common.buttons.cancel"
          sx={{ minWidth: '100px', mr: 4 }}
        />
        <Button text="common.buttons.confirm" variant="contained" color="primary" onClick={handleCropConfirm} />
      </Stack>
    </Stack>
  );
};

export default FileUploadCropper;
