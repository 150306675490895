import React from 'react';
import { Box } from '@egym/ui';

const DefaultLogo: React.FC = () => {
  return (
    <Box
      component="img"
      sx={{ position: 'absolute', bottom: '-45px', right: '0px' }}
      width={130}
      src="/images/previewer-default-logo.png"
    />
  );
};

export default DefaultLogo;
