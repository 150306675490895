import React, { useCallback, useState } from 'react';
import { Stack } from '@mui/material';
import { Box, SxProps } from '@mui/system';
import useMouseEvents from '../../../hooks/useMouseEvents';
import { StyledTextField } from './DegreePicker.styles';
import { calculateDegree } from './helpers';

type Props = {
  wrapperSx?: SxProps;
  value: number;
  onChange: (degree: number) => void;
};

const DegreePicker: React.FC<Props> = ({ wrapperSx, value, onChange }) => {
  const [disableClick, setDisableClick] = useState(false);

  const onClickGradientDegree = useCallback(() => {
    if (disableClick) {
      setDisableClick(false);
      return;
    }

    let gradientDegree = value + 45;

    if (gradientDegree >= 360) {
      gradientDegree = 0;
    }

    onChange(parseInt(String(gradientDegree), 10));
  }, [disableClick, value, onChange]);

  const mouseDownHandler = useCallback(event => {
    const pointer = event.target;
    const pointerBox = pointer.getBoundingClientRect();
    const centerY = pointerBox.top + parseInt(String(8 - window.pageYOffset), 10);
    const centerX = pointerBox.left + parseInt(String(8 - window.pageXOffset), 10);

    return {
      centerY,
      centerX,
    };
  }, []);

  const mouseMoveHandler = useCallback(
    (event, { centerX, centerY }) => {
      setDisableClick(true);

      const newDegree = calculateDegree(event.clientX, event.clientY, centerX, centerY);

      onChange(parseInt(String(newDegree), 10));

      return { centerX, centerY };
    },
    [onChange],
  );

  const mouseUpHandler = event => {
    const targetId = event.target.id;

    if (targetId === 'degree-picker-control') {
      return;
    }

    setDisableClick(false);
  };

  const mouseEvents = useMouseEvents(mouseDownHandler, mouseMoveHandler, mouseUpHandler);

  const onMouseDown = event => {
    mouseEvents(event);
  };

  return (
    <Stack direction="row" alignItems="center" sx={{ ...wrapperSx }}>
      <Stack
        onMouseDown={onMouseDown}
        onClick={onClickGradientDegree}
        id="degree-picker-control"
        sx={{
          position: 'relative',
          justifyContent: 'center',
          alignItems: 'center',
          width: '24px',
          height: '24px',
          border: '3px solid',
          borderColor: 'common.black',
          borderRadius: '18px',
          boxSizing: 'border-box',
          mr: 2,
        }}
      >
        <Box
          sx={{
            position: 'relative',
            width: '6px',
            height: '22px',
            pointerEvents: 'none',
            transform: `rotate(${value}deg)`,
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              width: '6px',
              height: '6px',
              top: '2px',
              borderRadius: '3px',
              background: '#1F2667',
            }}
          />
        </Box>
      </Stack>
      <StyledTextField
        value={value}
        type="number"
        inputProps={{
          min: 0,
          max: 360,
          maxLength: 3,
        }}
        onChange={e => onChange(Number(e.target.value))}
        sx={{ padding: '0 !important' }}
      />
    </Stack>
  );
};

export default DegreePicker;
