import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import { Box, Icons } from '@egym/ui';
import { IconWrapper } from '../Icons';
import ProfileHeader from './components/ProfileHeader';
import { profileMenuItemsConfig } from './constants';
import { PreviewerProfileRouteProps } from './PreviewerProfileRouteProps';

const PreviewerProfileRoute: React.FC<PreviewerProfileRouteProps> = ({ palette, shownFeatures }) => {
  const { t } = useTranslation();
  return (
    <Box style={{ background: '#f9f9f9', position: 'relative', height: '100%' }}>
      <ProfileHeader palette={palette} />
      <Stack
        className="hide-scrollbar"
        alignItems="center"
        style={{
          width: '100%',
          boxSizing: 'border-box',
          height: 'calc(100% - 136px)',
          overflow: 'scroll',
        }}
      >
        <Stack alignItems="center" style={{ width: '100%', height: '170px' }}>
          <Box
            component="img"
            src="/images/previewerDefaultImages/avatars/Lukas.png"
            sx={{ height: '100px', width: '100px', mt: '8px' }}
          />
          <span
            style={{
              fontSize: '18px',
              fontWeight: 700,
              color: '#212121',
            }}
          >
            {t('appPreviewer.labels.accountName') as ReactNode}
          </span>
          <span
            style={{
              fontSize: '14px',
              fontWeight: 400,
              color: '#212121',
              marginTop: '4px',
              marginBottom: '13px',
            }}
          >
            {t('appPreviewer.labels.gymName') as ReactNode}
          </span>
        </Stack>

        <Box sx={{ width: '100%' }}>
          {shownFeatures.map(profileMenuItem => {
            const { icon: Icon, label } = profileMenuItemsConfig[profileMenuItem];

            return (
              <Stack
                key={profileMenuItem}
                px="15px"
                py="9px"
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ background: 'white', ':not(:last-child)': { borderBottom: '1px solid #f4f4f4' } }}
              >
                <Stack direction="row" alignItems="center">
                  <IconWrapper bgColor={palette.primaryTransparent} wrapperSx={{ mr: '10px' }}>
                    <Icon htmlColor={palette.primary} />
                  </IconWrapper>
                  <span
                    style={{
                      fontSize: '14px',
                      fontWeight: 400,
                      color: '#212121',
                    }}
                  >
                    {t(label) as ReactNode}
                  </span>
                </Stack>
                <Icons.ArrowForwardIos fontSize="small" htmlColor="#828080" />
              </Stack>
            );
          })}
        </Box>
      </Stack>
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{
          borderTop: '0.5px solid rgba(0, 0, 0, 0.2)',
          background: 'white',
          height: '60px',
          zIndex: 1,
        }}
      >
        <span style={{ color: '#e53935', fontSize: '14px', marginBottom: '20px' }}>
          {t('appPreviewer.labels.signOut') as ReactNode}
        </span>
      </Stack>
    </Box>
  );
};

export default PreviewerProfileRoute;
