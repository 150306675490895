import { generatePath } from 'react-router';
import { Uuid } from '@egym/types';

const getAppByUuidUrl = url => (params?: { uuid?: Uuid }) => {
  // @ts-ignore
  return params ? generatePath(url, params) : url;
};

export const pageUrls = {
  login: '/login',
  adminDashboard: '/dashboard',
  appsRoot: '/apps',
  get appByUuidRoot() {
    return getAppByUuidUrl(`${this.appsRoot}/:uuid`);
  },
  get appMain() {
    return getAppByUuidUrl(`${this.appByUuidRoot()}/main`);
  },
  get appDemo() {
    return getAppByUuidUrl(`${this.appByUuidRoot()}/demo`);
  },
  get appSettings() {
    return getAppByUuidUrl(`${this.appByUuidRoot()}/app-settings`);
  },
  get appDesign() {
    return getAppByUuidUrl(`${this.appByUuidRoot()}/app-design`);
  },
  get appDesignReview() {
    return getAppByUuidUrl(`${this.appDesign()}/review`);
  },
  get appDesignView() {
    return getAppByUuidUrl(`${this.appDesign()}/view`);
  },
};
