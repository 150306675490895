import { Method, AxiosResponse, ResponseType } from 'axios';
import qs from 'qs';
import axiosClient, { CustomAxiosRequestConfig } from './axiosClient';
import { compileApiUrl } from './utils';

export type PaginationQueryParams = {
  page?: number;
  size?: number;
  sort?: string[];
  [param: string]: string | number | string[] | boolean | undefined;
};

export type ApiRequestOptions<Payload = unknown, QueryParams = PaginationQueryParams, UrlParams = unknown> = {
  payload?: Payload;
  queryParams?: QueryParams;
  urlParams?: UrlParams;
};

export const createApiRequest =
  <Result = unknown, Payload = Result, UrlParams = unknown, QueryParams = PaginationQueryParams>(
    url: string,
    method: Method = 'get',
    defaultParams?: { query?: Partial<QueryParams>; url?: Partial<UrlParams> },
    responseType?: ResponseType,
    allowForbidden = false,
    urlPrefix?: string,
  ) =>
  (options?: ApiRequestOptions<Payload, QueryParams, UrlParams>): Promise<AxiosResponse<Result>> => {
    const urlParams = { ...defaultParams?.url, ...options?.urlParams };
    const queryParams = { ...defaultParams?.query, ...options?.queryParams };

    return axiosClient.request({
      method,
      url: compileApiUrl(url, urlParams, urlPrefix),
      data: options?.payload,
      params: queryParams,
      responseType,
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
      allowForbidden,
    } as CustomAxiosRequestConfig);
  };
