import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const MyAccountIcon: React.FC<SvgIconProps> = ({ sx, htmlColor = '#4C4A4A', ...rest }) => {
  return (
    <SvgIcon viewBox="0 0 24 24" sx={{ width: '24px', height: '24px', ...sx }} {...rest}>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <path d="M0 0H24V24H0z" transform="translate(-1268 -2309) translate(1268 2309)" />
            <path
              fill={htmlColor}
              d="M12 3c2.762 0 5 2.238 5 5 0 1.45-.616 2.754-1.601 3.667C18.682 13.01 21 16.24 21 20c0 .553-.448 1-1 1H4c-.553 0-1-.447-1-1 0-3.76 2.318-6.99 5.6-8.333C7.616 10.754 7 9.449 7 8c0-2.762 2.238-5 5-5zm0 10c-3.521 0-6.443 2.612-6.929 6h13.857c-.486-3.388-3.408-6-6.928-6zm0-8c-1.654 0-3 1.346-3 3s1.346 3 3 3 3-1.346 3-3-1.346-3-3-3z"
              transform="translate(-1268 -2309) translate(1268 2309)"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export default MyAccountIcon;
