import {
  ArAeFlagIcon,
  BgBgFlagIcon,
  CaEsFlagIcon,
  DaDkFlagIcon,
  DeDeFlagIcon,
  ElGrFlagIcon,
  EnAuFlagIcon,
  EnCaFlagIcon,
  EnGbFlagIcon,
  EnUsFlagIcon,
  EsEsFlagIcon,
  EsMxFlagIcon,
  FiFiFlagIcon,
  FrCaFlagIcon,
  FrFrFlagIcon,
  HeIlFlagIcon,
  IsIsFlagIcon,
  ItItFlagIcon,
  JaJpFlagIcon,
  KoKrFlagIcon,
  NbNoFlagIcon,
  NlNlFlagIcon,
  PlPlFlagIcon,
  PtPtFlagIcon,
  RoRoFlagIcon,
  RuRuFlagIcon,
  SvSeFlagIcon,
  ThThFlagIcon,
  TrTrFlagIcon,
  ZhCnFlagIcon,
  ZhTwFlagIcon,
} from '../../svg';

export const languageIconMap = {
  ca_ES: CaEsFlagIcon,
  da_DK: DaDkFlagIcon,
  de_DE: DeDeFlagIcon,
  en_AU: EnAuFlagIcon,
  en_CA: EnCaFlagIcon,
  en_GB: EnGbFlagIcon,
  en_US: EnUsFlagIcon,
  es_ES: EsEsFlagIcon,
  es_MX: EsMxFlagIcon,
  fr_FR: FrFrFlagIcon,
  fr_CA: FrCaFlagIcon,
  is_IS: IsIsFlagIcon,
  it_IT: ItItFlagIcon,
  nl_NL: NlNlFlagIcon,
  nb_NO: NbNoFlagIcon,
  pl_PL: PlPlFlagIcon,
  pt_PT: PtPtFlagIcon,
  ro_RO: RoRoFlagIcon,
  fi_FI: FiFiFlagIcon,
  sv_SE: SvSeFlagIcon,
  tr_TR: TrTrFlagIcon,
  el_GR: ElGrFlagIcon,
  bg_BG: BgBgFlagIcon,
  ru_RU: RuRuFlagIcon,
  he_IL: HeIlFlagIcon,
  ar_AE: ArAeFlagIcon,
  th_TH: ThThFlagIcon,
  zh_CN: ZhCnFlagIcon,
  zh_TW: ZhTwFlagIcon,
  ja_JP: JaJpFlagIcon,
  ko_KR: KoKrFlagIcon,
};

export const allLanguages = [
  {
    locale: 'ca_ES',
    name: 'Catalá',
    shortName: 'Ca',
    fullNameEn: 'Catalan',
  },
  {
    locale: 'da_DK',
    name: 'Dansk',
    shortName: 'Da',
    fullNameEn: 'Danish',
  },
  {
    locale: 'de_DE',
    name: 'Deutsch',
    shortName: 'De',
    fullNameEn: 'German',
  },
  {
    locale: 'en_AU',
    name: 'English (AU)',
    shortName: 'En (AU)',
    fullNameEn: 'English_AU',
  },
  {
    locale: 'en_CA',
    name: 'English (CA)',
    shortName: 'En (CA)',
    fullNameEn: 'English_CA',
  },
  {
    locale: 'en_GB',
    name: 'English (UK)',
    shortName: 'En (UK)',
    fullNameEn: 'English_UK',
  },
  {
    locale: 'en_US',
    name: 'English (US)',
    shortName: 'En (US)',
    fullNameEn: 'English_US',
  },
  {
    locale: 'es_ES',
    name: 'Español',
    shortName: 'Es',
    fullNameEn: 'Spanish',
  },
  {
    locale: 'es_MX',
    name: 'Español (mexicano)',
    shortName: 'Es (MX)',
    fullNameEn: 'Spanish_MX',
  },
  {
    locale: 'fr_FR',
    name: 'Français',
    shortName: 'Fr',
    fullNameEn: 'French',
  },
  {
    locale: 'fr_CA',
    name: 'Français (CA)',
    shortName: 'Fr (CA)',
    fullNameEn: 'French_CA',
  },
  {
    locale: 'is_IS',
    name: 'Icelandic',
    shortName: 'Is',
    fullNameEn: 'Icelandic',
  },
  {
    locale: 'it_IT',
    name: 'Italiano',
    shortName: 'It',
    fullNameEn: 'Italian',
  },
  {
    locale: 'nl_NL',
    name: 'Nederlands',
    shortName: 'Nl',
    fullNameEn: 'Dutch',
  },
  {
    locale: 'no_NO',
    name: 'Norsk',
    shortName: 'No',
    fullNameEn: 'Norwegian',
  },
  {
    locale: 'nb_NO',
    name: 'Norsk (Bokmål)',
    shortName: 'Nb',
    fullNameEn: 'Norwegian_Bokmål',
  },
  {
    locale: 'pl_PL',
    name: 'Polski',
    shortName: 'Pl',
    fullNameEn: 'Polish',
  },
  {
    locale: 'pt_PT',
    name: 'Português',
    shortName: 'Pt',
    fullNameEn: 'Portuguese',
  },
  {
    locale: 'ro_RO',
    name: 'Română',
    shortName: 'Ro',
    fullNameEn: 'Romanian',
  },
  {
    locale: 'fi_FI',
    name: 'Suomi',
    shortName: 'Fi',
    fullNameEn: 'Finnish',
  },
  {
    locale: 'sv_SE',
    name: 'Svenska',
    shortName: 'Sv',
    fullNameEn: 'Swedish',
  },
  {
    locale: 'tr_TR',
    name: 'Türkçe',
    shortName: 'Tr',
    fullNameEn: 'Turkish',
  },
  {
    locale: 'el_GR',
    name: 'Ελληνικά',
    shortName: 'El',
    fullNameEn: 'Greek',
  },
  {
    locale: 'bg_BG',
    name: 'Български',
    shortName: 'Bg',
    fullNameEn: 'Bulgarian',
  },
  {
    locale: 'ru_RU',
    name: 'Русский',
    shortName: 'Ru',
    fullNameEn: 'Russian',
  },
  {
    locale: 'iw_IL',
    name: 'עברית',
    shortName: 'Iw',
    fullNameEn: 'Hebrew',
  },
  {
    locale: 'he_IL',
    name: 'עברית',
    shortName: 'He',
    fullNameEn: 'Hebrew',
  },
  {
    locale: 'ar_AE',
    name: 'العربية',
    shortName: 'Ar',
    fullNameEn: 'Arabic',
  },
  {
    locale: 'th_TH',
    name: 'ภาษาไทย',
    shortName: 'Th',
    fullNameEn: 'Thai',
  },
  {
    locale: 'zh_CN',
    name: '中文 (简体)',
    shortName: 'Zh (CN)',
    fullNameEn: 'Chinese_China',
  },
  {
    locale: 'zh_TW',
    name: '中文 (繁體)',
    shortName: 'Zh (TN)',
    fullNameEn: 'Chinese_Taiwan',
  },
  {
    locale: 'ja_JP',
    name: '日本語',
    shortName: 'Ja',
    fullNameEn: 'Japanese',
  },
  {
    locale: 'ko_KR',
    name: '한국어',
    shortName: 'Ko',
    fullNameEn: 'Korean',
  },
];

export const getLanguageByLocale = (lang: string) => {
  const locale = lang?.replace('-', '_');
  return allLanguages.find(it => it.locale === locale);
};
