import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const EditDesignIcon: React.FC<SvgIconProps> = ({ sx, htmlColor = 'black', ...props }) => {
  return (
    <SvgIcon viewBox="0 0 22 28" {...props} sx={{ width: '22px', height: '28px', ...sx }}>
      <path
        d="M14.8139 21.2727H3.02455V6.72729H14.8139V7.93941H17.1717V3.09093C17.1717 1.7576 16.1107 0.666687 14.8139 0.666687H3.02455C1.72773 0.666687 0.666687 1.7576 0.666687 3.09093V24.9091C0.666687 26.2424 1.72773 27.3334 3.02455 27.3334H14.8139C16.1107 27.3334 17.1717 26.2424 17.1717 24.9091V20.0606H14.8139V21.2727ZM3.02455 3.09093H14.8139V4.30305H3.02455V3.09093ZM14.8139 24.9091H3.02455V23.697H14.8139V24.9091Z"
        fill={htmlColor}
      />
      <path d="M11 16.6193V19H13.3391L20 12.3585L17.6386 10L11 16.6193Z" fill={htmlColor} />
      <path
        d="M21.8198 9.87387L20.1261 8.18018C19.8859 7.93994 19.5135 7.93994 19.2733 8.18018L18 9.45345L20.5465 12L21.8198 10.7267C22.0601 10.4865 22.0601 10.1141 21.8198 9.87387Z"
        fill={htmlColor}
      />
    </SvgIcon>
  );
};
