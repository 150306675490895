import * as Yup from 'yup';
import { AnyObject, Maybe } from 'yup';
import { extractTextFromHtml, REG_EXPS } from '@egym/utils';

Yup.addMethod(Yup.string, 'richTextMaxLength', function (maxLength, message) {
  return this.test('richTextMaxLength', message, function (value) {
    const { createError } = this;

    return (
      extractTextFromHtml(value)?.length <= maxLength ||
      createError({ message: message || `Max length is ${maxLength}!` })
    );
  });
});

Yup.addMethod(Yup.string, 'richTextRequired', function (message) {
  return this.test('richTextRequired', message, function (value) {
    const { createError, path } = this;

    return extractTextFromHtml(value)?.length || createError({ message: message || `${path} is required` });
  });
});

Yup.addMethod(Yup.string, 'phone', function (message) {
  return this.transform(value => value || null).matches(
    /^((\+[1-9]{1,4}[ -]*)|(\([0-9]{2,3}\)[ -]*)|([0-9]{2,4})[ -]*)*?[0-9]{3,4}?[ -]*[0-9]{3,4}?$/,
    message || 'Phone number is not valid',
  );
});

Yup.addMethod(Yup.string, 'url2', function (message) {
  return this.transform(value => value || null).matches(REG_EXPS.URL, message || 'Must be a valid URL');
});

declare module 'yup' {
  // @ts-ignore
  interface StringSchema<
    TType extends Maybe<string> = string | undefined,
    TContext extends AnyObject = AnyObject,
    TOut extends TType = TType,
  > extends Yup.StringSchema<TType, TContext, TOut> {
    richTextMaxLength(maxLength: number, message: string): this;

    richTextRequired(message: string): this;

    phone(message: string): this;

    url2(message: string): this;
  }
}

export default Yup;
