import { atom, atomFamily, selector } from 'recoil';
import { Icons } from '@egym/ui';
import { toLocaleApiFormat } from '@egym/utils';
import { AppPreviewerTab, PreviewerRoute } from '@components';
import { AppDesignMenuItemEnum, DesignValidationError } from '@types';
import { AppDesignMenuItemConfig } from '../AppDesignProps';

const keys = {
  menuItem: 'appDesign.menuItem',
  allMenuItems: 'appDesign.allMenuItems',
  selectedMenuItem: 'appDesign.selectedMenuItem',
  selectedLayoutTabMenuItem: 'appDesign.selectedLayoutTabMenuItem',
  previewerLocale: 'appDesign.previewerLocale',
  previewerLocaleApiFormat: 'appDesign.previewerLocaleApiFormat',
  formChanged: 'appDesign.formChanged',
  sentForReviewCount: 'appDesign.sentForReviewCount',
  savingChanges: 'appDesign.savingChanges',
  reviewValidationErrors: 'appDesign.validation.errors',
  currentTabReviewValidationErrors: 'appDesign.validation.errors.currentTab',
};

const atoms = {
  menuItem: atomFamily<AppDesignMenuItemConfig, AppDesignMenuItemEnum>({
    key: keys.menuItem,
    default: menuItemName => ({
      menuItemName,
      selected: menuItemName === AppDesignMenuItemEnum.AppLanguages,
      completed: false,
      icon: {
        [AppDesignMenuItemEnum.AppLanguages]: Icons.Translate,
        [AppDesignMenuItemEnum.AppLayout]: Icons.ViewQuiltOutlined,
        [AppDesignMenuItemEnum.ColorTheme]: Icons.PaletteOutlined,
        [AppDesignMenuItemEnum.MainTabBranding]: Icons.AutoAwesomeOutlined,
        [AppDesignMenuItemEnum.AppIcon]: Icons.FilterVintageOutlined,
        [AppDesignMenuItemEnum.SignInScreen]: Icons.MobileScreenShare,
        [AppDesignMenuItemEnum.TabsOrder]: Icons.ListAlt,
      }[menuItemName],
      previwerRoute: {
        [AppDesignMenuItemEnum.AppLanguages]: PreviewerRoute.Layout,
        [AppDesignMenuItemEnum.AppLayout]: PreviewerRoute.Layout,
        [AppDesignMenuItemEnum.ColorTheme]: PreviewerRoute.Layout,
        [AppDesignMenuItemEnum.MainTabBranding]: PreviewerRoute.Layout,
        [AppDesignMenuItemEnum.AppIcon]: PreviewerRoute.AppIconIos,
        [AppDesignMenuItemEnum.SignInScreen]: PreviewerRoute.SignInScreen,
        [AppDesignMenuItemEnum.TabsOrder]: PreviewerRoute.Layout,
        [AppDesignMenuItemEnum.TabCustomization]: PreviewerRoute.Layout,
      }[menuItemName],
      previewerProps: undefined,
      selectedLayoutTab: undefined,
    }),
  }),
  previewerLocale: atom<string>({ key: keys.previewerLocale, default: undefined }),
  formChanged: atom({ key: keys.formChanged, default: false }),
  sentForReviewCount: atom({ key: keys.sentForReviewCount, default: 0 }),
  savingChanges: atom({ key: keys.savingChanges, default: false }),
  reviewValidationErrors: atom<DesignValidationError[]>({
    key: keys.reviewValidationErrors,
    default: [],
  }),
};

const selectors = {
  allMenuItems: selector<AppDesignMenuItemConfig[]>({
    key: keys.allMenuItems,
    get: ({ get }) => Object.values(AppDesignMenuItemEnum).map(menuItemName => get(atoms.menuItem(menuItemName))),
  }),
  selectedMenuItem: selector<AppDesignMenuItemConfig>({
    key: keys.selectedMenuItem,
    get: ({ get }) => {
      const all = get(selectors.allMenuItems);

      return all.find(menuItem => menuItem.selected);
    },
  }),
  selectedLayoutTabMenuItem: selector<AppPreviewerTab | undefined>({
    key: keys.selectedLayoutTabMenuItem,
    get: ({ get }) => {
      const selectedMenuItem = get<AppDesignMenuItemConfig>(selectors.selectedMenuItem);

      return selectedMenuItem.selectedLayoutTab;
    },
  }),
  previewerLocaleApiFormat: selector<string>({
    key: keys.previewerLocaleApiFormat,
    get: ({ get }) => {
      const previewerLocale = get(atoms.previewerLocale);

      return toLocaleApiFormat(previewerLocale);
    },
  }),
  currentTabReviewValidationErrors: selector<DesignValidationError[]>({
    key: keys.currentTabReviewValidationErrors,
    get: ({ get }) => {
      const selectedTabId = get<AppPreviewerTab | undefined>(selectors.selectedLayoutTabMenuItem)?.id;
      return selectedTabId ? get(atoms.reviewValidationErrors).filter(it => it.tabId === selectedTabId) : [];
    },
    set: ({ set, get }, newValue) => {
      const selectedTabId = get<AppPreviewerTab | undefined>(selectors.selectedLayoutTabMenuItem)?.id;
      if (selectedTabId && Array.isArray(newValue)) {
        set(atoms.reviewValidationErrors, prevValue => [
          ...prevValue.filter(it => it.tabId !== selectedTabId),
          ...newValue,
        ]);
      }
    },
  }),
};

export const appDesignState = {
  keys,
  atoms,
  selectors,
};
