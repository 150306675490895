export const getCanUseInspectlet = (shouldWarn = false) => {
  const isDev = process.env.NODE_ENV === 'development';

  if (isDev && shouldWarn) {
    // eslint-disable-next-line no-console
    console.warn('[@egym/analytics/inspectlet] Inspectlet is disabled in dev mode');
  }

  return !isDev;
};

export const embedInspectlet = id => {
  if (!getCanUseInspectlet(true)) return;

  window.__insp = window.__insp || [];
  window.__insp.push(['wid', id]);
  const ldinsp = function () {
    if (typeof window.__inspld !== 'undefined') return;
    window.__inspld = 1;
    const insp = document.createElement('script');
    insp.type = 'text/javascript';
    insp.async = true;
    insp.id = 'inspsync';
    insp.src = `${window.location.protocol}//cdn.inspectlet.com/inspectlet.js?wid=${id}&r=${Math.floor(
      new Date().getTime() / 3600000,
    )}`;
    const x = document.getElementsByTagName('script')[0];
    x.parentNode?.insertBefore(insp, x);
  };
  setTimeout(ldinsp, 0);
};

export const inspectletIdentifySession = identity => {
  if (!window.__insp || !getCanUseInspectlet()) return;

  window.__insp.push(['identify', identity]);
};

export const inspectletTrackEvent = (eventName, metaData = {}) => {
  if (!window.__insp || !eventName || !getCanUseInspectlet()) return;

  window.__insp.push(['tagSession', { eventName, ...metaData }]);
};
