import { useCallback, useMemo } from 'react';
import { useRecoilState } from 'recoil';
import { getLanguageByLocale, localeState, useLanguages } from '@egym/ui';
import { AnalyticsEvents } from '@constants';
import { useTrackEventByRouter } from '@hooks';
import i18n, { supportedLngs } from 'src/i18n';
import { GlobalLanguageSelectorProps } from '../GlobalLanguageSelectorProps';

const useGlobalLanguageSelector = (): GlobalLanguageSelectorProps => {
  const { languages } = useLanguages();

  const [selectedLocaleCode, setCurrentLocaleCode] = useRecoilState(localeState.atoms.currentLocale);
  const trackEvent = useTrackEventByRouter();

  const supportedLanguages = useMemo(() => {
    return languages.filter(it => supportedLngs.includes(it.locale));
  }, [languages]);

  const setSelectedLanguage = useCallback(
    async localeCode => {
      await i18n.changeLanguage(localeCode);
      setCurrentLocaleCode(localeCode);
      trackEvent(AnalyticsEvents.A_Interface_Language_Selected, {
        language: getLanguageByLocale(localeCode)?.fullNameEn,
      });
    },
    [setCurrentLocaleCode, trackEvent],
  );

  const selectedLanguage = useMemo(
    () => supportedLanguages.find(lang => lang.locale === selectedLocaleCode),
    [selectedLocaleCode, supportedLanguages],
  );

  const onChangeLanguageButtonClick = useCallback(() => {
    trackEvent(AnalyticsEvents.A_Interface_Language);
  }, [trackEvent]);

  return {
    languages: supportedLanguages,
    selectedLanguage,
    setSelectedLanguage,
    onChangeLanguageButtonClick,
  };
};

export default useGlobalLanguageSelector;
