import React, { ChangeEvent, MouseEvent, ReactNode } from 'react';
import { TableBody as MuiTableBody } from '@mui/material';
import { TableColumn, TableRenderSubComponentParams, TableRowType } from '../../TableProps';
import TableBodyRow from '../TableBodyRow';

type Props = {
  columns: TableColumn[];
  rows: any[];
  checkboxSelectionProp?: string;
  selectedRows: string[];
  onRowClick: (event: MouseEvent<unknown> | ChangeEvent<HTMLInputElement>, row: any) => void;
  tdProps?: Record<string, any>;
  renderSubComponent?: (params: TableRenderSubComponentParams) => ReactNode;
  totalColumnsCount: number;
  getIsCheckboxSelected?: (row: TableRowType) => boolean;
  getIsCheckboxIndeterminate?: (row: TableRowType) => boolean;
  indeterminateRowIds?: string[];
  expandAll?: boolean;
  isViewMode?: boolean;
};

const TableBody: React.FC<Props> = ({
  columns,
  rows,
  checkboxSelectionProp,
  selectedRows,
  onRowClick,
  tdProps,
  renderSubComponent,
  totalColumnsCount,
  getIsCheckboxSelected,
  getIsCheckboxIndeterminate,
  indeterminateRowIds,
  expandAll,
  isViewMode,
}) => {
  return (
    <MuiTableBody>
      {rows.map((row, index) => {
        return (
          <TableBodyRow
            key={row.id}
            row={row}
            rowIndex={index}
            columns={columns}
            checkboxSelectionProp={checkboxSelectionProp}
            selectedRows={selectedRows}
            onRowClick={onRowClick}
            tdProps={tdProps}
            renderSubComponent={renderSubComponent}
            totalColumnsCount={totalColumnsCount}
            getIsCheckboxSelected={getIsCheckboxSelected}
            getIsCheckboxIndeterminate={getIsCheckboxIndeterminate}
            indeterminateRowIds={indeterminateRowIds}
            expandAll={expandAll}
            isViewMode={isViewMode}
          />
        );
      })}
    </MuiTableBody>
  );
};

export default TableBody;
