import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FormikConfig } from 'formik';
import { map, mergeAll } from 'ramda';
import { customYup, toLocaleApiFormat } from '@egym/utils';
import { predefinedHorizontalWidgetImages, predefinedVerticalWidgetImages } from '@constants';
import { AppDesignLayoutTabWidget, Orientation, WidgetViewStyle } from '@types';
import useWidgetPreferencesForm from '../../../../hooks/useWidgetPreferencesForm';
import {
  GroupWebWidgetPreferencesFormContainerProps,
  GroupWebWidgetPreferencesFormikProps,
  GroupWebWidgetPreferencesFormValues,
  UseGroupWebWidgetPreferencesFormResult,
} from '../../GroupWebWidgetPreferencesFormProps';

const useGroupWebWidgetPreferencesForm = (
  props: GroupWebWidgetPreferencesFormContainerProps,
  ref,
): UseGroupWebWidgetPreferencesFormResult => {
  const { t } = useTranslation();
  const { currentWidget, updateWidget } = props;

  const { innerRef, appLocales, availableLanguages, openLanguagesMenuTab, onSubmit } = useWidgetPreferencesForm(
    props,
    ref,
  );

  const formConfig = useMemo<FormikConfig<GroupWebWidgetPreferencesFormValues>>(() => {
    return {
      enableReinitialize: false,
      initialValues: {
        orientation: currentWidget.preferences?.orientation || Orientation.Vertical,
        title: mergeAll(
          map(
            (locale: string) => ({
              [locale]: currentWidget.preferences?.title?.[locale] ?? '',
            }),
            map(locale => toLocaleApiFormat(locale), appLocales || []),
          ),
        ),
      },
      validationSchema: customYup.object().shape({
        title: customYup
          .object()
          .required()
          .shape(
            mergeAll(
              map(
                (locale: string) => ({
                  [locale]: customYup.string().required(t('common.validation.required')),
                }),
                map(locale => toLocaleApiFormat(locale), appLocales || []),
              ),
            ),
          ),
      }),
      innerRef,
      onSubmit,
      validateOnMount: false,
    };
  }, [currentWidget.preferences?.orientation, currentWidget.preferences?.title, appLocales, innerRef, onSubmit, t]);

  const updatePredefinedImagesInInnerWidgets = (
    innerWidgets: AppDesignLayoutTabWidget[],
    orientation: Orientation,
  ): AppDesignLayoutTabWidget[] => {
    const predefinedImagesSet =
      orientation === Orientation.Horizontal ? predefinedHorizontalWidgetImages : predefinedVerticalWidgetImages;
    return innerWidgets.map(innerWidget =>
      innerWidget?.preferences?.style === WidgetViewStyle.Image && innerWidget?.preferences?.asset?.id
        ? {
            ...innerWidget,
            preferences: {
              ...innerWidget.preferences,
              asset: {
                id: innerWidget.preferences.asset.id,
                link: predefinedImagesSet.find(it => it.id === innerWidget.preferences?.asset?.id)?.path,
              },
            },
          }
        : innerWidget,
    );
  };

  const onFormChanged = useCallback(
    async ({ values, touched, setTouched, initialValues }: GroupWebWidgetPreferencesFormikProps) => {
      const initialOrientationChanged = values.orientation !== initialValues.orientation;
      if (touched.orientation || initialOrientationChanged) {
        setTouched({ ...touched, orientation: initialOrientationChanged }, false);
        const innerWidgets = currentWidget.preferences?.innerWidgets;
        if (Array.isArray(innerWidgets) && innerWidgets.length) {
          const updatedInnerWidgets = updatePredefinedImagesInInnerWidgets(innerWidgets, values.orientation);
          updateWidget({ id: currentWidget.id, preferences: { ...values, innerWidgets: updatedInnerWidgets } });
          return;
        }
      }
      updateWidget({ id: currentWidget.id, preferences: { ...values } });
    },
    [currentWidget, updateWidget],
  );

  return { formConfig, availableLanguages, openLanguagesMenuTab, onFormChanged };
};

export default useGroupWebWidgetPreferencesForm;
