import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const WellDoneProgressCircleIcon: React.FC<SvgIconProps> = ({ sx, htmlColor = '#4C4A4A', ...rest }) => {
  return (
    <SvgIcon viewBox="0 0 150 150" sx={{ width: '150px', height: '150px', ...sx }} {...rest}>
      <title>Group 2</title>
      <g id="Restyle:-Workouts" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Log-Workout-Well-Done-Screen" transform="translate(-114.000000, -97.000000)">
          <g id="Group-7" transform="translate(-0.000000, -7.000000)">
            <g id="Group-2" transform="translate(119.000000, 109.000000)">
              <circle id="Oval" stroke="#EBEBEB" strokeWidth="6" cx="70" cy="70" r="70" />
              <path
                d="M70,140 C108.659932,140 140,108.659932 140,70 C140,31.3400675 108.659932,0 70,0 C31.3400675,0 0,31.3400675 0,70"
                id="Oval"
                stroke={htmlColor}
                strokeWidth="10"
                strokeLinecap="round"
                strokeLinejoin="round"
                transform="translate(70.000000, 70.000000) rotate(90.000000) translate(-70.000000, -70.000000) "
              />
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export default WellDoneProgressCircleIcon;
