import React, { ReactNode } from 'react';
import { TFunction } from 'i18next';
import { Paper, Stack } from '@egym/ui';
import { AppPreviewerPalette } from '../../../../AppPreviewerProps';

type Props = {
  tFunction: TFunction;
  palette: Partial<AppPreviewerPalette>;
  name: string;
  years: number;
  image: string;
  checkMuscle: boolean;
};

const StatsCarouselItem: React.FC<Props> = ({ tFunction, palette, name, years, image, checkMuscle }) => {
  return (
    <Paper
      sx={{
        borderRadius: '8px',
        width: '100%',
        height: '200px',
        boxShadow: '0 4px 6px rgba(223, 223, 223, 0.7)',
        p: '10px',
        boxSizing: 'border-box',
      }}
    >
      <Stack alignItems="center" sx={{ position: 'relative' }}>
        {checkMuscle && (
          <div
            style={{
              position: 'absolute',
              top: '-1px',
              left: '50%',
              transform: 'translateX(-50%)',
              padding: '4px 2px',
              borderRadius: '8px',
              width: '100%',
              textAlign: 'center',
              color: 'rgb(245, 166, 35)',
              background: 'rgba(33, 33, 33, 0.05)',
              fontSize: '11px',
              fontWeight: 500,
            }}
          >
            {tFunction('appPreviewer.labels.checkMuscleImbalances') as ReactNode}
          </div>
        )}
        <img
          style={{ width: '40px', height: '40px', marginTop: '50px', marginBottom: '18px' }}
          src={image}
          alt={name}
        />
        <span style={{ color: 'rgb(33, 33, 33)', marginBottom: '2px' }}>
          <span
            style={{
              fontSize: '23px',
              fontWeight: 700,
              letterSpacing: '-0.5px',
              marginRight: '5px',
            }}
          >
            {years}
          </span>
          <span style={{ fontSize: '14px', fontWeight: 700, letterSpacing: '-0.5px', textTransform: 'lowercase' }}>
            {tFunction('appPreviewer.labels.year', { count: years }) as ReactNode}
          </span>
        </span>
        <span style={{ color: 'rgb(76, 74, 74)', fontSize: '14px', lineHeight: '20px' }}>
          {tFunction(name) as ReactNode}
        </span>
        <span
          style={{ width: '8px', height: '8px', borderRadius: '8px', background: palette.primary, marginTop: '8px' }}
        />
      </Stack>
    </Paper>
  );
};

export default StatsCarouselItem;
