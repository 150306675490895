import { RefObject, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FormikConfig, FormikProps } from 'formik';
import { Option } from '@egym/types';
import { customYup } from '@egym/utils';
import { getIsFeatureFullTab, TabIconPreview } from '@components';
import { getIsFeatureUnremovable } from '@helpers';
import { MoveWidgetsFormValues } from '@types';
import { MoveWidgetsFormContainerProps, UseMoveWidgetsFormResult } from '../../MoveWidgetsFormProps';

const useMoveWidgetsForm = ({
  widget,
  appLayoutTabs,
  moveRemainingFeaturesFormRef,
}: MoveWidgetsFormContainerProps): UseMoveWidgetsFormResult => {
  const { t } = useTranslation();

  const currentTab = useMemo(() => appLayoutTabs.find(tab => tab.selected), [appLayoutTabs]);

  const tabsOptions = useMemo<Option[]>(() => {
    return appLayoutTabs
      .filter(tab => !tab.selected)
      .map(tab => {
        return {
          label: tab.nameTranslated || tab.name.en_US,
          description: tab.features.some(
            tabFeature => getIsFeatureFullTab(tabFeature.props.name) && tabFeature.props.name !== widget?.name,
          )
            ? t('appDesign.labels.changeFullTabToCompactOnDeleteTab')
            : undefined,
          value: tab.id,
          icon: TabIconPreview,
          iconProps: {
            wrapperSx: { backgroundColor: 'rgb(142, 139, 139)', width: '16px', height: '16px', mr: 3 },
            iconLink: tab.icon,
          },
        };
      });
  }, [appLayoutTabs, t, widget?.name]);

  const onSubmit = useCallback(() => {}, []);

  const formProps = useMemo<FormikConfig<MoveWidgetsFormValues> | null>(() => {
    return {
      enableReinitialize: true,
      initialValues: {
        currentWidgets:
          currentTab?.features
            .filter(feature => !widget?.id || feature?.props?.id !== widget.id)
            .map(feature => ({
              ...feature,
              newTabId: undefined,
              shouldBeRemoved: false,
              canBeRemoved: !getIsFeatureUnremovable(feature.props.name),
            })) || [],
      },
      onSubmit,
      validateOnMount: false,
      validationSchema: customYup.object().shape({
        currentWidgets: customYup.array().of(
          customYup.object().shape({
            newTabId: customYup
              .number()
              .test('newTabId', 'newTabId', (value, { parent: { shouldBeRemoved, canBeRemoved }, createError }) => {
                if (!canBeRemoved && !value) {
                  return createError({ message: t('common.errors.chooseNewTab') });
                }
                if (!shouldBeRemoved && !value) {
                  return createError({
                    message: t('common.errors.chooseNewTabOrRemove', {
                      checkboxName: t('common.buttons.removeFeature'),
                    }),
                  });
                }

                return true;
              }),
          }),
        ),
      }),
      innerRef: moveRemainingFeaturesFormRef as RefObject<FormikProps<MoveWidgetsFormValues>>,
    };
  }, [currentTab?.features, moveRemainingFeaturesFormRef, onSubmit, t, widget]);

  return {
    formProps,
    tabsOptions,
  };
};

export default useMoveWidgetsForm;
