import React from 'react';
import useMultiLanguageSelectorFormField from './hooks/useMultiLanguageSelectorFormField';
import MultiLanguageSelectorFormField from './MultiLanguageSelectorFormField';
import { MultiLanguageSelectorFormFieldContainerProps } from './MultiLanguageSelectorFormFieldProps';

const MultiLanguageSelectorFormFieldContainer: React.FC<MultiLanguageSelectorFormFieldContainerProps> = props => {
  const messageLocalizationProps = useMultiLanguageSelectorFormField(props);

  return <MultiLanguageSelectorFormField {...props} {...messageLocalizationProps} />;
};

export default MultiLanguageSelectorFormFieldContainer;
