import { SvgIconComponent } from '@mui/icons-material';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';

export type FooterMenuItem = {
  tKey: string;
  href: string;
};

export const footerMenuItems: FooterMenuItem[] = [
  {
    tKey: 'footer.menuItems.smartStrength',
    href: 'https://egym.com/us/workouts/smartstrength',
  },
  {
    tKey: 'footer.menuItems.brandedMemberApp',
    href: 'https://egym.com/us/digital/brandedmemberapp',
  },
  {
    tKey: 'footer.menuItems.contact',
    href: 'https://egym.com/us/contact',
  },
  {
    tKey: 'footer.menuItems.smartCardio',
    href: 'https://egym.com/us/workouts/smartcardio',
  },
  {
    tKey: 'footer.menuItems.egymTrainer',
    href: 'https://egym.com/us/digital/trainer',
  },
  {
    tKey: 'footer.menuItems.support',
    href: 'https://egym.com/us/support',
  },
  {
    tKey: 'footer.menuItems.trainingPrograms',
    href: 'https://egym.com/us/workouts/trainingprograms',
  },
  {
    tKey: 'footer.menuItems.egymCloud',
    href: 'https://egym.com/us/digital/cloud',
  },
  {
    tKey: 'footer.menuItems.career',
    href: 'https://career.egym.com',
  },
];

export const footerCopyrightItems: FooterMenuItem[] = [
  {
    tKey: 'footer.copyrightItems.cookies',
    href: 'https://egym.com/us/cookies',
  },
  {
    tKey: 'footer.copyrightItems.legal',
    href: 'https://egym.com/us/legal',
  },
  {
    tKey: 'footer.copyrightItems.privacy',
    href: 'https://egym.com/us/privacy',
  },
  {
    tKey: 'footer.copyrightItems.terms',
    href: 'https://egym.com/us/terms',
  },
];

export type FooterSocialItem = {
  icon: SvgIconComponent;
  href: string;
};

export const footerSocialItems: FooterSocialItem[] = [
  {
    icon: InstagramIcon,
    href: 'https://www.instagram.com/egym_usa/',
  },
  {
    icon: FacebookIcon,
    href: 'https://www.facebook.com/EGYMUSA/',
  },
  {
    icon: YouTubeIcon,
    href: 'https://www.youtube.com/user/egymfitness/',
  },
  {
    icon: LinkedInIcon,
    href: 'https://www.linkedin.com/company/egym-inc/',
  },
];
