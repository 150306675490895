import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { toLocaleApiFormat } from '@egym/utils';

export const previewerLngs = ['en-US', 'nl-NL', 'fr-FR', 'de-DE', 'es-ES', 'ja-JP', 'fi-FI', 'nb-NO'];
export const supportedLngs = ['en-US', 'nl-NL', 'fr-FR', 'de-DE', 'es-ES', 'ja-JP', 'fi-FI', 'nb-NO'];
export enum LngNamespace {
  Previewer = 'previewer',
}

const i18nConfig = {
  fallbackLng: {
    'ca-ES': ['es-ES', 'en-US'],
    default: ['en-US'],
  },
  debug: false,
  // Allow keys to be phrases having `:`, `.`
  nsSeparator: '@@@',

  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },

  load: 'currentOnly',

  supportedLngs,
  preload: previewerLngs,

  react: {
    useSuspense: true,
  },

  backend: {
    loadPath: ([lang], [namespace]) => {
      if (Object.values(LngNamespace).includes(namespace)) {
        return `/locales/${namespace}/${toLocaleApiFormat(lang)}.json`;
      }
      return `/locales/${toLocaleApiFormat(lang)}.json`;
    },
    queryStringParams: { t: Date.now() },
  },
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  // @ts-ignore
  .init(i18nConfig);

export default i18n;
