import { createApiRequest } from '@egym/api';
import { Uuid } from '@egym/types';
import { ApplicationDesignDto, CustomSignInBackgroundImage } from '@types';
import { APPLICATION_DESIGN_BASE_URL } from './constants';

const APPLICATION_DESIGN_APP_ICON_BASE_URL = `${APPLICATION_DESIGN_BASE_URL}/icon`;
const APPLICATION_DESIGN_MAIN_TAB_HEADER_ICON_BASE_URL = `${APPLICATION_DESIGN_BASE_URL}/mainTabHeaderIcon`;
const APPLICATION_DESIGN_SIGN_IN_LOGO_BASE_URL = `${APPLICATION_DESIGN_BASE_URL}/signIn/logo`;
const APPLICATION_DESIGN_SIGN_IN_BG_IMAGE_PREDEFINED = `${APPLICATION_DESIGN_BASE_URL}/signIn/background/predefined`;
const SET_APPLICATION_DESIGN_SIGN_IN_BG_IMAGE_CUSTOM = `${APPLICATION_DESIGN_BASE_URL}/signIn/background/custom`;
const APPLICATION_DESIGN_SIGN_IN_BG_IMAGE_CUSTOM = `${APPLICATION_DESIGN_BASE_URL}/signIn/background/custom-images`;
const APPLICATION_DESIGN_SIGN_IN_BG_IMAGE_CUSTOM_BY_ID = `${APPLICATION_DESIGN_SIGN_IN_BG_IMAGE_CUSTOM}/:id`;
const SUBMIT_APPLICATION_DESIGN_URL = `${APPLICATION_DESIGN_BASE_URL}/submit`;
const UNSUBMIT_APPLICATION_DESIGN_URL = `${APPLICATION_DESIGN_BASE_URL}/unsubmit`;

export * from './applicationDesignTabs';
export * from './applicationDesignWidgets';

export const getClientApplicationDesign = createApiRequest<ApplicationDesignDto, unknown, { applicationUuid: string }>(
  APPLICATION_DESIGN_BASE_URL,
);

export const putClientApplicationDesign = createApiRequest<
  ApplicationDesignDto,
  Partial<ApplicationDesignDto>,
  { applicationUuid: string }
>(APPLICATION_DESIGN_BASE_URL, 'put');

export const postAppIcon = createApiRequest<ApplicationDesignDto, FormData, { applicationUuid: string }>(
  APPLICATION_DESIGN_APP_ICON_BASE_URL,
  'post',
);

export const postMainTabHeaderIcon = createApiRequest<ApplicationDesignDto, FormData, { applicationUuid: string }>(
  APPLICATION_DESIGN_MAIN_TAB_HEADER_ICON_BASE_URL,
  'post',
);
export const deleteMainTabHeaderIcon = createApiRequest<unknown, unknown, { applicationUuid: string }>(
  APPLICATION_DESIGN_MAIN_TAB_HEADER_ICON_BASE_URL,
  'delete',
);

export const postSignInLogo = createApiRequest<ApplicationDesignDto, FormData, { applicationUuid: string }>(
  APPLICATION_DESIGN_SIGN_IN_LOGO_BASE_URL,
  'post',
);

export const postSignInBackgroundImagePredefined = createApiRequest<
  ApplicationDesignDto,
  FormData,
  { applicationUuid: string }
>(APPLICATION_DESIGN_SIGN_IN_BG_IMAGE_PREDEFINED, 'post');

export const setCustomSignInBackgroundImage = createApiRequest<
  ApplicationDesignDto,
  unknown,
  { applicationUuid: string },
  { customImageId: string }
>(SET_APPLICATION_DESIGN_SIGN_IN_BG_IMAGE_CUSTOM, 'post');

export const postSignInBackgroundImageCustom = createApiRequest<
  CustomSignInBackgroundImage,
  FormData,
  { applicationUuid: string }
>(APPLICATION_DESIGN_SIGN_IN_BG_IMAGE_CUSTOM, 'post');

export const getCustomSignInBackgroundImages = createApiRequest<
  CustomSignInBackgroundImage[],
  unknown,
  { applicationUuid: string }
>(APPLICATION_DESIGN_SIGN_IN_BG_IMAGE_CUSTOM);

export const deleteCustomSignInBackgroundImage = createApiRequest<
  unknown,
  unknown,
  { applicationUuid: string; id: string }
>(APPLICATION_DESIGN_SIGN_IN_BG_IMAGE_CUSTOM_BY_ID, 'delete');

export const submitApplicationDesign = createApiRequest<ApplicationDesignDto, unknown, { applicationUuid: Uuid }>(
  SUBMIT_APPLICATION_DESIGN_URL,
  'post',
);

export const unsubmitApplicationDesign = createApiRequest<ApplicationDesignDto, unknown, { applicationUuid: Uuid }>(
  UNSUBMIT_APPLICATION_DESIGN_URL,
  'post',
);
