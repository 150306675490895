import { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { useUpdateEffect } from 'react-use';
import { MouseSensor, useSensor, useSensors } from '@dnd-kit/core';
import { useFormikContext } from 'formik';
import { Language } from '@egym/types';
import { reorder, toLocaleFormat } from '@egym/utils';
import useLanguages from '../../../../../hooks/useLanguages';
import {
  MultiLanguageSelectorFormFieldContainerProps,
  UseMultiLanguageSelectorFormFieldResult,
} from '../../MultiLanguageSelectorFormFieldProps';

const useMultiLanguageSelectorFormField = ({
  fieldName,
  defaultLocale,
  onLanguageAdded,
  onLanguageDeleted,
  onFirstLanguageChange,
}: MultiLanguageSelectorFormFieldContainerProps): UseMultiLanguageSelectorFormFieldResult => {
  const { languages } = useLanguages();
  const { values, setFieldValue } = useFormikContext<any>();

  const [searchValue, setSearchValue] = useState('');
  const [activeDraggingId, setActiveDraggingId] = useState();

  const onSearchChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setSearchValue(e.target.value);
    },
    [setSearchValue],
  );

  const [addedLanguages, setAddedLanguages] = useState(
    (values[fieldName] && values[fieldName].length ? values[fieldName] : [defaultLocale]).map(locale =>
      languages.find(lang => lang.locale === toLocaleFormat(locale)),
    ) as Language[],
  );

  const [possibleLanguages, setPossibleLanguages] = useState(
    languages.filter(it => !addedLanguages.find(lang => lang.locale === it.locale)),
  );

  const activeDraggingLanguage = useMemo(
    () => addedLanguages.find(language => language.locale === activeDraggingId),
    [addedLanguages, activeDraggingId],
  );

  useUpdateEffect(() => {
    setPossibleLanguages(languages.filter(it => !addedLanguages.find(lang => lang.locale === it.locale)));
  }, [addedLanguages, setPossibleLanguages]);

  const onPossibleLanguageSelect = useCallback(
    selectedLocale => {
      const newAddedLanguages = [
        ...addedLanguages,
        possibleLanguages.find(lang => lang.locale === selectedLocale) as Language,
      ];

      setAddedLanguages(newAddedLanguages);

      setFieldValue(
        fieldName,
        newAddedLanguages.map(it => it.locale),
      );
      if (onLanguageAdded) {
        onLanguageAdded(selectedLocale);
      }
      if (newAddedLanguages.length === 1 && onFirstLanguageChange) {
        onFirstLanguageChange(selectedLocale);
      }
    },
    [addedLanguages, possibleLanguages, setFieldValue, fieldName, onLanguageAdded, onFirstLanguageChange],
  );

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 5,
      },
    }),
  );

  const filteredPossibleLanguages = useMemo(() => {
    if (!searchValue) return possibleLanguages;

    const pattern = new RegExp(searchValue, 'i');

    return possibleLanguages.filter(lang => pattern.test(lang.name));
  }, [searchValue, possibleLanguages]);

  const onRemoveAddedLanguage = useCallback(
    localeToRemove => {
      const newAddedLanguages = addedLanguages.filter(lang => lang.locale !== localeToRemove);

      setAddedLanguages(newAddedLanguages);

      setFieldValue(
        fieldName,
        newAddedLanguages.map(it => it.locale),
      );
      if (onLanguageDeleted) {
        onLanguageDeleted(localeToRemove);
      }
    },
    [addedLanguages, fieldName, setFieldValue, onLanguageDeleted],
  );

  const onDragStart = useCallback(event => {
    setActiveDraggingId(event.active.id);
  }, []);

  const onDragEnd = useCallback(() => {
    setFieldValue(
      fieldName,
      addedLanguages.map(it => it.locale),
    );
  }, [addedLanguages, fieldName, setFieldValue]);

  const onDragOver = useCallback(
    event => {
      const { active, over } = event;
      if (!active || !over || active.id === over.id) {
        return;
      }

      setAddedLanguages(prevAddedLanguages => {
        const from = prevAddedLanguages.find(it => it.locale === active.id);
        const to = prevAddedLanguages.find(it => it.locale === over.id);
        if (!from || !to) {
          return prevAddedLanguages;
        }
        const fromIndex = prevAddedLanguages.indexOf(from);
        const toIndex = prevAddedLanguages.indexOf(to);
        const result = reorder(prevAddedLanguages, fromIndex, toIndex) as Language[];
        if (result[0] !== prevAddedLanguages[0] && onFirstLanguageChange) {
          onFirstLanguageChange(result[0].locale);
        }
        return result;
      });
    },
    [onFirstLanguageChange],
  );

  return {
    filteredPossibleLanguages,
    possibleLanguages,
    searchValue,
    onSearchChange,
    onPossibleLanguageSelect,
    onRemoveAddedLanguage,
    addedLanguages,
    onDragStart,
    onDragEnd,
    onDragOver,
    defaultLocale,
    sensors,
    activeDraggingLanguage,
  };
};

export default useMultiLanguageSelectorFormField;
