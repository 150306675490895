import { ComponentType } from 'react';
import { atom } from 'recoil';
import { ConfirmDialogConfig, ConfirmDialogConfigText } from '../types';

type DialogState = (ConfirmDialogConfig & { text?: ComponentType<any> | ConfirmDialogConfigText[] }) | null;

const keys = {
  confirmDialogConfig: 'confirmDialogGlobalState.confirmDialogConfig',
};

const atoms = {
  confirmDialogConfig: atom<DialogState>({
    key: keys.confirmDialogConfig,
    default: null,
  }),
};

export const confirmDialogGlobalState = {
  keys,
  atoms,
};
