import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const MainTabHeaderDefaultIcon17: React.FC<SvgIconProps> = ({ htmlColor = '#FFF', sx, ...props }) => {
  return (
    <SvgIcon viewBox="0 0 48 48" {...props} sx={{ width: '48px', height: '48px', ...sx }}>
      <g fill={htmlColor} fillRule="evenodd">
        <path d="M36 24a2 2 0 0 1 1.995 1.85L38 26v10a5 5 0 0 1-4.783 4.995L33 41H15a5 5 0 0 1-4.995-4.783L10 36V26a2 2 0 0 1 3.995-.15L14 26v10a1 1 0 0 0 .883.993L15 37h18a1 1 0 0 0 .993-.883L34 36V26a2 2 0 0 1 2-2zM24.034 6l.04.001a2.001 2.001 0 0 1 .249.025l.035.006c.041.007.083.016.124.026l.02.006c.043.01.084.023.125.036l.034.012a1.638 1.638 0 0 1 .232.098 1.903 1.903 0 0 1 .26.156l-.129-.085c.037.022.073.046.109.07l.02.015 17 12 .13.1a2 2 0 0 1-2.436 3.168L24 10.448 8.153 21.634a2 2 0 0 1-2.437-3.168l.13-.1 17-12 .03-.021.1-.064-.13.085a2.025 2.025 0 0 1 .488-.252l.042-.014c.042-.014.084-.027.126-.038a1.685 1.685 0 0 1 .14-.03l.035-.006a1.541 1.541 0 0 1 .248-.025L24 6h.034z" />
        <path
          d="M27 20a2 2 0 1 1 0 4h-4v4a2 2 0 1 1-4 0v-6a2 2 0 0 1 2-2h6zm9-11a2 2 0 0 1 2 2v5a2 2 0 1 1-4 0v-5a2 2 0 0 1 2-2z"
          opacity=".6"
        />
      </g>
    </SvgIcon>
  );
};
