import React from 'react';
import { SxProps } from '@mui/system';
import { Box } from '../../layout/Box';
import { panelFooterStyles } from './Panel.styles';

type Props = {
  sx?: SxProps;
  tools?: React.ReactNode;
};

const PanelFooter: React.FC<Props> = ({ sx, tools }) => {
  return <Box sx={{ ...panelFooterStyles, ...sx }}>{tools}</Box>;
};

export default PanelFooter;
