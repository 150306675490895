import React, { useCallback, useMemo, useState } from 'react';
import { ColorChangeHandler, CustomPicker, RGBColor } from 'react-color';
import { useDebounce, useEffectOnce } from 'react-use';
import { ExportedColorProps, InjectedColorProps } from 'react-color/lib/components/common/ColorWrap';
import { SketchPickerProps } from 'react-color/lib/components/sketch/Sketch';
import { Box, Stack } from '@egym/ui';
import { generateGradientStyle } from '../../helpers';
import { GradientPickerType, GradientPoint } from '../../types';
import CustomColorPicker from '../CustomColorPicker';
import GradientSlider from '../GradientSlider';
import GradientTools from '../GradientTools';
import { getGradientPropsFromString } from './helpers';

type Props = InjectedColorProps &
  Omit<ExportedColorProps, 'color'> & {
    disableAlpha?: boolean;
    disableGradientTools?: boolean;
    presetColors?: SketchPickerProps['presetColors'];
    value?: RGBColor | string;
    error?: string;
    popoverOpen?: boolean;
  };

const CustomGradientPicker: React.FC<Props> = ({
  value,
  onChangeComplete,
  disableAlpha,
  disableGradientTools,
  presetColors,
  error,
  popoverOpen,
}) => {
  const [selectedGradientType, setSelectedGradientType] = useState<GradientPickerType>(GradientPickerType.Linear);
  const [gradientDegree, setGradientDegree] = useState<number>(0);
  const [activeGradientPointIndex, setActiveGradientPointIndex] = useState(0);
  const [points, setPoints] = useState<GradientPoint[]>([]);

  useEffectOnce(() => {
    const {
      points: initialPoints,
      gradientDegree: initialGradientDegree,
      gradientType: initialGradientType,
    } = getGradientPropsFromString(value, presetColors);

    setPoints(initialPoints);
    setGradientDegree(initialGradientDegree);
    setSelectedGradientType(initialGradientType);
  });

  const onChangeActiveColor = useCallback<ColorChangeHandler>(
    colorResult => {
      setPoints(prev =>
        prev.map((point, index) => ({
          ...point,
          rgb: activeGradientPointIndex === index ? { ...colorResult.rgb, a: colorResult.rgb.a || 1 } : point.rgb,
        })),
      );
    },
    [activeGradientPointIndex],
  );

  useDebounce(
    () => {
      if (onChangeComplete) {
        // @ts-ignore
        onChangeComplete(generateGradientStyle(points, selectedGradientType, gradientDegree));
      }
    },
    300,
    [points, gradientDegree, selectedGradientType],
  );

  const activePoint = useMemo(() => {
    return points[activeGradientPointIndex];
  }, [points, activeGradientPointIndex]);

  const updateGradientLeft = useCallback((newLeft, index) => {
    setPoints(prev =>
      prev.map((point, pointIndex) => ({
        ...point,
        left: pointIndex === index ? newLeft : point.left,
      })),
    );
  }, []);

  return (
    <Stack>
      {!disableGradientTools && (
        <GradientTools
          selectedGradientType={selectedGradientType}
          gradientDegree={gradientDegree}
          setSelectedGradientType={setSelectedGradientType}
          setGradientDegree={setGradientDegree}
        />
      )}
      <Box sx={{ px: 3, py: 4, borderTop: '1px solid', borderBottom: '1px solid', borderColor: 'grey.200' }}>
        <GradientSlider
          disableGradientTools
          updateGradientLeft={updateGradientLeft}
          points={points}
          activeGradientPointIndex={activeGradientPointIndex}
          setActiveGradientPointIndex={setActiveGradientPointIndex}
        />
      </Box>
      {activePoint && (
        <CustomColorPicker
          color={activePoint.rgb}
          onChange={onChangeActiveColor}
          // @ts-ignore
          disableAlpha={disableAlpha}
          presetColors={presetColors}
          error={error}
          popoverOpen={popoverOpen}
        />
      )}
    </Stack>
  );
};

export default CustomPicker(CustomGradientPicker);
