import React, { FC } from 'react';
import { InputBaseProps } from '@mui/material/InputBase';
import { Field } from 'formik';
import { Language } from '@egym/types';
import { Box, Stack, Typography, TextFormField } from '@egym/ui';

type Props = {
  name: string;
  filedWidth?: string;
  languages: Language[];
  isViewMode?: boolean;
  inputProps?: InputBaseProps['inputProps'];
  onBlur?: InputBaseProps['onBlur'];
};

const MultiLanguageTextField: FC<Props> = ({ name, languages, isViewMode, ...rest }) => {
  return (
    <Box
      sx={{
        display: 'grid',
        gridGap: '24px',
        gridTemplateColumns: '1fr 1fr',
        maxHeight: '100%',
      }}
    >
      {languages.map(({ locale, name: langName, icon: Icon }) => (
        <Stack key={locale} sx={{ width: 1 }} direction="column" spacing={3}>
          <Stack direction="row" alignItems="center" spacing={2}>
            {Icon && <Icon />}
            <Typography component="label" variant="body1Medium" htmlFor={`${name}.${locale}`}>
              {langName}
            </Typography>
          </Stack>
          <Field
            id={`${name}.${locale}`}
            name={`${name}.${locale}`}
            component={TextFormField}
            isViewMode={isViewMode}
            hiddenLabel
            {...rest}
          />
        </Stack>
      ))}
    </Box>
  );
};

export default MultiLanguageTextField;
