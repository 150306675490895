import React from 'react';
import { CustomPicker, CustomPickerProps } from 'react-color';
import { InjectedColorProps } from 'react-color/lib/components/common/ColorWrap';
import { SketchPickerProps } from 'react-color/lib/components/sketch/Sketch';
import ColorPicker from '../ColorPicker';

type Props = {
  disableAlpha?: boolean;
  presetColors?: SketchPickerProps['presetColors'];
  error?: string;
  popoverOpen?: boolean;
} & CustomPickerProps<any> &
  InjectedColorProps;

const CustomColorPicker: React.FC<Props> = props => {
  return <ColorPicker {...props} wrapperSx={{ py: 3 }} />;
};

export default CustomPicker(CustomColorPicker);
