import React from 'react';
import { ConditionalRender, Icons, Popover, Button, Stack, Typography } from '@egym/ui';
import GlobalLanguageSelectorPopoverContent from './components/GlobalLanguageSelectorPopoverContent';
import { GlobalLanguageSelectorProps } from './GlobalLanguageSelectorProps';

const GlobalLanguageSelector: React.FC<GlobalLanguageSelectorProps> = ({
  languages,
  selectedLanguage,
  setSelectedLanguage,
  onChangeLanguageButtonClick,
}) => {
  const FlagIcon = selectedLanguage?.icon;
  return (
    <ConditionalRender condition={Boolean(languages.length)}>
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onAnchorClick={onChangeLanguageButtonClick}
      >
        {selectedLanguage && FlagIcon && (
          <Button
            sx={{ p: 0 }}
            color="inherit"
            variant="text"
            text={
              <Stack direction="row" alignItems="center">
                <FlagIcon />
                <Typography sx={{ ml: 1 }} variant="body2Medium">
                  {selectedLanguage.shortName || selectedLanguage.name}
                </Typography>
                <Icons.ExpandMore sx={{ mt: 0.5, ml: 0.5, opacity: 0.5 }} />
              </Stack>
            }
          />
        )}
        <GlobalLanguageSelectorPopoverContent
          languages={languages}
          onLanguageSelect={setSelectedLanguage}
          selectedLanguage={selectedLanguage}
        />
      </Popover>
    </ConditionalRender>
  );
};

export default GlobalLanguageSelector;
