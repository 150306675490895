import { ApplicationSettings, ApplicationType } from '@types';

export const getIsAppleDevProgramAvailable = (type: ApplicationType) => type === ApplicationType.Standalone;

export const allSettingsProgressStepsCount = (isAppleDevProgramAvailable: boolean) => {
  return isAppleDevProgramAvailable ? 3 : 2;
};

export const appSettingsProgressConditions = (
  applicationSettings: Partial<ApplicationSettings>,
  isAppleDevProgramAvailable: boolean,
  gymLocationsCount,
  gymLocationsErrorsCount,
): any[] => {
  const {
    adminEmail,
    companyName,
    companyWebsite,
    companyContactEmail,
    appStoreAccountName,
    appStoreSubmissionName,
    iosAppIconName,
  } = applicationSettings || {};

  return [
    {
      type: 'generalSettings',
      condition: Boolean(adminEmail) && Boolean(companyName) && Boolean(companyWebsite) && Boolean(companyContactEmail),
    },
    {
      type: 'appleDevProgram',
      condition: Boolean(appStoreAccountName) && Boolean(appStoreSubmissionName) && Boolean(iosAppIconName),
    },
    {
      type: 'gymLocations',
      condition: Boolean(gymLocationsCount && !gymLocationsErrorsCount),
    },
  ].filter(it => {
    if (it.type === 'appleDevProgram') {
      return isAppleDevProgramAvailable;
    }
    return true;
  });
};
