import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const TwitterIcon: React.FC<SvgIconProps> = ({ sx, htmlColor = '#4C4A4A', ...rest }) => {
  return (
    <SvgIcon viewBox="0 0 24 24" sx={{ width: '20px', height: '20px', ...sx }} {...rest}>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <path d="M0 0H24V24H0z" transform="translate(-1820 -1275) translate(1820 1275)" />
            <path
              fill={htmlColor}
              d="M13.196 5.723c-.468.369-.851.837-1.092 1.375l-.141.341c-.072.198-.128.425-.156.651-.014.1-.029.199-.029.298v.199l.015.312.056.411h-.014l-.156.014h-.099c-1.205-.014-2.353-.284-3.403-.751l-.609-.298c-.113-.057-.226-.127-.326-.185-.681-.411-1.29-.906-1.843-1.473-.34-.37-.638-.752-.921-1.164-.298.568-.468 1.206-.468 1.886 0 .255.029.511.071.751.014.1.043.199.071.298.198.737.596 1.39 1.134 1.886l.269.226-.34-.07c-.439-.114-.878-.256-1.29-.426.057.879.397 1.688.936 2.325.496.61 1.176 1.064 1.956 1.29l-.907.057-.752-.043c.567 1.376 1.857 2.368 3.402 2.496-1.417 1.034-3.175 1.63-5.06 1.644 1.389.837 2.991 1.361 4.707 1.46h1.134c5.33-.297 9.554-4.706 9.554-10.108l-.014-.524c.198-.156.411-.326.595-.511.453-.439.865-.921 1.219-1.431-.553.354-1.219.567-1.941.567h-.073l.03-.014c.666-.426 1.19-1.078 1.445-1.843-.523.255-1.092.482-1.672.638l-.085.014-.426.099c-.651-.68-1.545-1.105-2.537-1.12h-.07c-.284 0-.554.029-.823.085-.397.1-.765.255-1.092.468l-.255.17z"
              transform="translate(-1820 -1275) translate(1820 1275)"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export default TwitterIcon;
