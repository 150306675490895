import { Uuid } from '@egym/types';
import { useRouter } from '@egym/ui';
import useCustomSignInImages from '../useCustomSignInImages';

const useCustomSignInImagesByRouter = () => {
  const { urlParams } = useRouter<{ uuid: Uuid }>();

  return useCustomSignInImages(urlParams.uuid);
};

export default useCustomSignInImagesByRouter;
