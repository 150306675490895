import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const InstagramIcon: React.FC<SvgIconProps> = ({ sx, htmlColor = '#4C4A4A', ...rest }) => {
  return (
    <SvgIcon viewBox="0 0 24 24" sx={{ width: '20px', height: '20px', ...sx }} {...rest}>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <path d="M0 0H24V24H0z" transform="translate(-1820 -1199) translate(1820 1199)" />
            <path
              fill={htmlColor}
              d="M15.637 3C18.595 3 21 5.406 21 8.363v7.275C21 18.595 18.595 21 15.637 21H8.363C5.406 21 3 18.595 3 15.638V8.363C3 5.406 5.405 3 8.363 3zm0 1.81H8.363C6.4 4.81 4.81 6.4 4.81 8.364v7.275c0 1.962 1.59 3.552 3.552 3.552h7.274c1.962 0 3.552-1.59 3.552-3.552V8.363c0-1.962-1.59-3.552-3.552-3.552zM12 7.346c2.567 0 4.655 2.088 4.655 4.655 0 2.568-2.088 4.656-4.655 4.656S7.345 14.568 7.345 12c0-2.567 2.088-4.655 4.655-4.655zm0 1.81c-1.571 0-2.845 1.274-2.845 2.845 0 1.572 1.274 2.845 2.845 2.845 1.571 0 2.845-1.273 2.845-2.845 0-1.571-1.274-2.844-2.845-2.844zm4.664-2.891c.616 0 1.116.5 1.116 1.116 0 .616-.5 1.115-1.116 1.115-.615 0-1.115-.5-1.115-1.115 0-.616.5-1.116 1.115-1.116z"
              transform="translate(-1820 -1199) translate(1820 1199)"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export default InstagramIcon;
