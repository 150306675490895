export const uploadPaneWrapperStyles = {
  width: '100%',
  height: '182px',
};

export const uploadPaneStyles = disabled => ({
  width: '100%',
  height: '100%',
  boxSizing: 'border-box',
  color: 'grey.500',
  border: '1px dashed',
  borderColor: 'grey.400',
  borderRadius: 2,
  p: 5,
  pt: 3,
  cursor: disabled ? 'auto' : 'pointer',
  flex: '1',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '32px',
  bgcolor: disabled ? 'grey.100' : 'transparent',
  transition: 'all 0.2s linear',
  ':hover': {
    bgcolor: 'grey.100',
  },
});
