import React from 'react';
import Table from './Table';
import { TableContainerProps } from './TableProps';
import useTable from './useTable';

const TableContainer: React.FC<TableContainerProps> = props => {
  const useTableProps = useTable(props);

  return <Table {...props} {...useTableProps} />;
};

export default TableContainer;
