import { convertDate } from '@egym/utils';
import { WidgetWithTab } from '@types';

export const getImageOverlayGradientPreview = (primary, secondary) => {
  return `linear-gradient(0deg, ${primary} 0%, ${secondary}) 100%`;
};

export const sortWebWidgetsFn = (widgetA: WidgetWithTab, widgetB: WidgetWithTab) => {
  if (widgetA.createdAt && widgetB.createdAt) {
    // @ts-ignore
    return convertDate(widgetB.createdAt) - convertDate(widgetA.createdAt);
  }
  if (widgetA.createdAt) {
    return 1;
  }
  return 0;
};
