import { useMemo } from 'react';
import { useRoutes } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { principalState } from '@globalState';
import defaultAppPages from './defaultAppPages';
import { protectAppPages, getPagesFullPath } from './helpers';

const useAppPages = () => {
  const tokenInfo = useRecoilValue(principalState.selectors.authTokenInfo);

  const availableAppPages = useMemo(() => {
    return protectAppPages(false, tokenInfo)(defaultAppPages);
  }, [tokenInfo]);

  const availableAppPagesFullPaths = useMemo(
    () => availableAppPages.flatMap(pageConfig => getPagesFullPath(pageConfig)),
    [availableAppPages],
  );

  const appRoutes = useRoutes(availableAppPages);
  return {
    appRoutes,
    availableAppPagesFullPaths,
  };
};

export default useAppPages;
