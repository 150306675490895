import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { TableRow as MuiTableRow, TableCell as MuiTableCell, TableBody as MuiTableBody } from '@mui/material';
import { Typography } from '../../../Typography';

type Props = {
  noRowsLabel: string;
  noRowsWithFiltersLabel?: string;
  totalColumnsCount: number;
  hasSelectedFilters: boolean;
};

const TableEmptyState: React.FC<Props> = ({
  noRowsLabel,
  noRowsWithFiltersLabel,
  totalColumnsCount,
  hasSelectedFilters,
}) => {
  const { t } = useTranslation();

  return (
    <MuiTableBody>
      <MuiTableRow>
        <MuiTableCell colSpan={totalColumnsCount} size="medium" sx={{ py: 10 }}>
          <Typography variant="body2" align="center">
            {t(hasSelectedFilters && noRowsWithFiltersLabel ? noRowsWithFiltersLabel : noRowsLabel) as ReactNode}
          </Typography>
        </MuiTableCell>
      </MuiTableRow>
    </MuiTableBody>
  );
};

export default TableEmptyState;
