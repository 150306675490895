import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { Form, Formik } from 'formik';
import { useFormDialog } from '../../../hooks';
import { Button, FormikScrollToError } from '../../inputs';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '../Dialog';

export const FormDialog = () => {
  const { t } = useTranslation();

  const { isOpen, formDialogConfig, closeFormDialog, showCancelButton, showConfirmButton, maxWidth } = useFormDialog();

  const FormComponent = formDialogConfig?.formComponent;
  const ActionsComponent = formDialogConfig?.actionsComponent;

  return FormComponent && formDialogConfig?.formProps ? (
    <Dialog open={isOpen} onClose={closeFormDialog} fullWidth maxWidth={maxWidth} {...formDialogConfig?.muiDialogProps}>
      <Formik {...formDialogConfig.formProps}>
        {({ values, isSubmitting }) => {
          return (
            // https://github.com/egym/app-configurator-portal/pull/290
            <Form placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
              {formDialogConfig?.title && (
                <DialogTitle onClose={closeFormDialog} isLoading={isSubmitting}>
                  {t(formDialogConfig?.title, formDialogConfig?.titleParams) as ReactNode}
                </DialogTitle>
              )}
              <DialogContent sx={{ py: 4, px: 8 }} {...formDialogConfig?.contentProps}>
                <FormikScrollToError>
                  <FormComponent {...values} {...formDialogConfig.formComponentProps} />
                </FormikScrollToError>
              </DialogContent>
              {(showCancelButton || (!formDialogConfig.formComponentProps?.isViewMode && showConfirmButton)) && (
                <DialogActions
                  sx={{
                    py: 5,
                    px: 8,
                    position: 'sticky',
                    bottom: 0,
                    bgcolor: 'common.white',
                    boxShadow: '0 -5px 4px 0 rgba(0, 0, 0, 0.11)',
                    zIndex: 11,
                    justifyContent: ActionsComponent ? 'space-between' : 'right',
                  }}
                  disableSpacing
                >
                  {ActionsComponent && <ActionsComponent {...values} />}
                  <Box>
                    {showCancelButton && (
                      <Button
                        variant="outlined"
                        color="primary"
                        disabled={isSubmitting}
                        onClick={closeFormDialog}
                        text={t(formDialogConfig?.cancelText || 'common.buttons.cancel') as ReactNode}
                        sx={{ minWidth: '100px' }}
                        {...formDialogConfig?.cancelButtonProps}
                      />
                    )}
                    {!formDialogConfig.formComponentProps?.isViewMode && showConfirmButton && (
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        text={t(formDialogConfig?.confirmText || 'common.buttons.yes') as ReactNode}
                        sx={{ ml: 4, minWidth: '100px' }}
                        loading={isSubmitting}
                        {...formDialogConfig?.confirmButtonProps}
                      />
                    )}
                  </Box>
                </DialogActions>
              )}
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  ) : null;
};
