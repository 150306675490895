import { useCallback, useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';
import { Uuid } from '@egym/types';
import {
  deleteGymLocation,
  getGymLocations,
  getGymLocationTemplate,
  postGymLocation,
  putGymLocation,
  uploadGymLocations,
} from '@api';
import { gymLocationsTableState } from '@globalState';
import { GymLocationPayload } from '@types';
import useGymLocationsCount, {
  gymLocationsCountQueryName,
  gymLocationsErrorsCountQueryName,
} from '../useGymLocationsCount';

const gymLocationsQueryName = 'gymLocations';

const useGymLocations = (applicationUuid: Uuid) => {
  const queryClient = useQueryClient();
  const tableQueryParams = useRecoilValue(gymLocationsTableState.selectors.queryParams);
  const gymLocationQueryKey = useMemo(
    () => [gymLocationsQueryName, applicationUuid, tableQueryParams],
    [applicationUuid, tableQueryParams],
  );
  const { gymLocationsCountResponse, gymLocationsErrorsCountResponse } = useGymLocationsCount(applicationUuid);

  const invalidate = useCallback(async () => {
    await queryClient.invalidateQueries(gymLocationQueryKey);
    await queryClient.invalidateQueries([gymLocationsCountQueryName, applicationUuid]);
    await queryClient.invalidateQueries([gymLocationsErrorsCountQueryName, applicationUuid]);
  }, [applicationUuid, queryClient, gymLocationQueryKey]);

  const gymLocationsResponse = useQuery(
    gymLocationQueryKey,
    async () =>
      getGymLocations({
        urlParams: { applicationUuid },
        queryParams: tableQueryParams,
      }),
    {
      select: result => result.data,
      keepPreviousData: true,
    },
  );

  const gymLocationsTemplateQuery = useQuery(
    ['gymLocationsTemplate'],
    async () =>
      getGymLocationTemplate({
        urlParams: { applicationUuid },
      }),
    {
      enabled: false, // turned off by default, manual refetch is needed
      select: result => ({ blob: result.data, headers: result.headers }),
    },
  );

  const createGymLocationsRequest = useMutation(
    async (data: GymLocationPayload) => postGymLocation({ payload: data, urlParams: { applicationUuid } }),
    { onSuccess: () => invalidate() },
  );

  const updatedGymLocationsRequest = useMutation(
    async ({ id, data }: { id: number; data: GymLocationPayload }) =>
      putGymLocation({ payload: data, urlParams: { applicationUuid, id } }),
    {
      onSuccess: () => invalidate(),
    },
  );

  const deleteGymLocationsRequest = useMutation(
    async (id: number) => deleteGymLocation({ urlParams: { applicationUuid, id } }),
    {
      onSuccess: () => invalidate(),
    },
  );

  const uploadGymLocationsRequest = useMutation(
    async ({ file }: { file: File }) => {
      const formData = new FormData();
      formData.append('csv', file, file.name || '');
      return uploadGymLocations({ payload: formData, urlParams: { applicationUuid } });
    },
    { onSuccess: () => invalidate() },
  );

  return {
    gymLocationsResponse,
    gymLocationsCountResponse,
    gymLocationsErrorsCountResponse,
    gymLocationsTemplateQuery,
    createGymLocationsRequest,
    updatedGymLocationsRequest,
    deleteGymLocationsRequest,
    uploadGymLocationsRequest,
  };
};

export default useGymLocations;
