import React from 'react';
import { useDebounce } from 'react-use';
import { FormikProps, useFormikContext } from 'formik';

type Props = {
  onFormChanged: (form: FormikProps<any>) => void;
  debounce: number;
};

const FormikOnChangeWithDebounce: React.FC<Props> = ({ onFormChanged, debounce }) => {
  const ctx = useFormikContext<any>();
  useDebounce(
    () => {
      onFormChanged(ctx);
    },
    debounce > 0 ? debounce : 0,
    [ctx.values],
  );
  return null;
};

export default FormikOnChangeWithDebounce;
