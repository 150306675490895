import React from 'react';
import { Fade } from '@mui/material';
import { Box, Stack, SwitchChildren } from '@egym/ui';
import { contentStyles, wrapperStyles } from './AppPreviewer.styles';
import { AppPreviewerProps, PreviewerRoute } from './AppPreviewerProps';
import PreviewerAboutRoute from './components/PreviewerAboutRoute';
import PreviewerAppIconRoute from './components/PreviewerAppIconRoute';
import PreviewerFindClassRoute from './components/PreviewerFindClassRoute';
import PreviewerLanguageSelector from './components/PreviewerLanguageSelector';
import PreviewerLayoutRoute from './components/PreviewerLayoutRoute';
import PreviewerProfileRoute from './components/PreviewerProfileRoute';
import PreviewerSignInScreen from './components/PreviewerSignInScreen';
import PreviewerWellDoneRoute from './components/PreviewerWellDoneRoute';

const AppPreviewer: React.FC<AppPreviewerProps> = ({
  wrapperSx,
  containerSize,
  currentRoute,
  scrollRef,
  id,
  disabled,
  ready,
  handleWheel,
  onMouseLeave,
  onClick,
  hideLayoutRouteFooter,
  showDemoTab,
  showTabWithNotification,
  size,
  locales,
  appLayoutTabs,
  tabId,
  signInScreenRef,
  onAdjustSignInLogo,
  isViewMode,
  fadeTimeout = 500,
  selectedLocale,
  onChangeSelectedLocale,
  activeWidgetId,
  activeWidgetGroupId,
}) => {
  const wrapperResultSx = { ...wrapperStyles, ...wrapperSx };
  return (
    <Stack sx={wrapperResultSx}>
      <Stack
        id={`app-previewer-device-${id}`}
        onClick={onClick}
        sx={{
          backfaceVisibility: 'hidden',
          width: `${containerSize.deviceWidth}px`,
          height: `${containerSize.deviceHeight}px`,
          willChange: 'transform',
          transform: `scale(${containerSize.deviceScale}) translateZ(0) translate3d( 0, 0, 0)`,
          transformOrigin: 'center top',
          margin: containerSize.deviceMargin,
          transition: 'transform 0.4s linear',
        }}
      >
        {ready && (
          <Box
            sx={{
              width: `${containerSize.deviceWidth}px`,
              height: `${containerSize.deviceHeight}px`,
              background: `url(/images/@2xPhone_mockup.png)`,
              backgroundSize: '100%',
              backgroundRepeat: 'no-repeat',
              pointerEvents: 'none',
              zIndex: 2,
            }}
          />
        )}
        <Stack
          id={`previewer-content-wrapper-${id}`}
          sx={{
            ...contentStyles,
            width: `${containerSize.contentWidth}px`,
            height: `${containerSize.contentHeight}px`,
            boxShadow: ready ? `0 0 5px #000000` : undefined,
            borderRadius: '12px',
            borderTopLeftRadius: `${containerSize.topRadius}px`,
            borderTopRightRadius: `${containerSize.topRadius}px`,
            borderBottomLeftRadius: `${containerSize.bottomRadius}px`,
            borderBottomRightRadius: `${containerSize.bottomRadius}px`,
            overflow: 'hidden',
            transform: `translate(calc(-50% + 1px), calc(-50% - 1px)) scale(${containerSize.contentScale})`,
            backfaceVisibility: 'hidden',
            background: ready ? 'black' : 'transparent',
            zIndex: 1,
          }}
        >
          <Box
            className="hide-scrollbar"
            sx={{
              height: '100%',
              overflowY: disabled ? 'hidden' : 'scroll',
              scrollBehavior: 'smooth',
              overscrollBehavior: 'contain',
              pointerEvents: disabled ? 'none' : 'auto',
            }}
            ref={scrollRef}
            onWheel={handleWheel}
            onMouseOver={handleWheel}
            onMouseLeave={onMouseLeave}
          >
            <Fade in={ready} unmountOnExit timeout={fadeTimeout}>
              <div style={{ height: '100%' }}>
                <SwitchChildren value={currentRoute}>
                  <PreviewerLayoutRoute
                    value={PreviewerRoute.Layout}
                    id={id}
                    scrollRef={scrollRef}
                    hideLayoutRouteFooter={hideLayoutRouteFooter}
                    disabled={disabled}
                    showDemoTab={showDemoTab}
                    showTabWithNotification={showTabWithNotification}
                    appLayoutTabs={appLayoutTabs}
                    tabId={tabId}
                    activeWidgetId={activeWidgetId}
                    activeWidgetGroupId={activeWidgetGroupId}
                  />
                  <PreviewerAppIconRoute value={PreviewerRoute.AppIconIos} id={id} size={size} />
                  <PreviewerAppIconRoute value={PreviewerRoute.AppIconAndroid} id={id} size={size} />
                  <PreviewerSignInScreen
                    value={PreviewerRoute.SignInScreen}
                    id={id}
                    ref={signInScreenRef}
                    onAdjustSignInLogo={onAdjustSignInLogo}
                    isViewMode={isViewMode}
                  />
                  <PreviewerProfileRoute value={PreviewerRoute.Profile} id={id} />
                  <PreviewerWellDoneRoute value={PreviewerRoute.WellDone} id={id} />
                  <PreviewerFindClassRoute value={PreviewerRoute.FindClass} id={id} />
                  <PreviewerAboutRoute value={PreviewerRoute.About} id={id} />
                </SwitchChildren>
              </div>
            </Fade>
          </Box>
        </Stack>
      </Stack>
      <PreviewerLanguageSelector
        disabled={disabled}
        previewerId={id}
        locales={locales}
        outerSelectedLocale={selectedLocale}
        setOuterSelectedLocale={onChangeSelectedLocale}
        wrapperSx={{
          mt: 5,
          width: '100%',
          height: '24px',
        }}
      />
    </Stack>
  );
};

export default AppPreviewer;
