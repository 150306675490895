import { addApiClientRequestInterceptor, addApiClientResponseInterceptor } from '@egym/api';
import { goToAppPage } from '@egym/utils';

addApiClientResponseInterceptor(
  value => value,
  error => {
    if (error?.response?.status === 403 && !error.config.allowForbidden) {
      goToAppPage('login');
    }
    return Promise.reject(error);
  },
);

addApiClientRequestInterceptor(config => {
  const jwtToken = sessionStorage.getItem('auth#token');
  if (jwtToken) {
    // eslint-disable-next-line no-param-reassign
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${jwtToken}`,
    };
  }
  return config;
});
