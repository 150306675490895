import React, { FC, ReactNode } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { IconButton, InputAdornment } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { withFormikDevtools } from 'formik-devtools-extension';
import {
  Box,
  ColorPickerFormField,
  ConditionalRender,
  Divider,
  FileUploadFormField,
  FormikOnChangeWithDebounce,
  FormikScrollToError,
  Icons,
  LinkCore,
  MultiLanguageTextField,
  PickerType,
  RadioGroupFormField,
  Stack,
  TextFormField,
  Typography,
} from '@egym/ui';
import { FileFormatName, getBytesFromMb, openInNewTab } from '@egym/utils';
import { PredefinedImagesLibraryPopover } from '@components';
import { predefinedHorizontalWidgetImages, predefinedVerticalWidgetImages, predefinedWidgetIcons } from '@constants';
import { ResizeIcon } from '@svg';
import { Orientation, WidgetViewStyle } from '@types';
import WidgetIconWithBackgroundColorField from '../WidgetIconWIthBackgroundColorField';
import PreviewWebWidgetStylesOptions from './components/PreviewWebWidgetStylesOptions';
import { WebWidgetPreferencesFormProps } from './WebWidgetPreferencesFormProps';

const WebWidgetPreferencesForm: FC<WebWidgetPreferencesFormProps> = ({
  formConfig,
  availableLanguages,
  openLanguagesMenuTab,
  presetColors,
  onSelectPredefinedIcon,
  onSelectPredefinedImage,
  onFormChanged,
  previewerPalette,
  onCustomIconPick,
  onCustomImagePick,
  onCropOriginalFile,
  orientation,
  imageDimensions,
}) => {
  const { t } = useTranslation();

  return (
    <Formik {...formConfig}>
      {formProps => {
        const urlMeta = formProps.getFieldMeta('url');

        return withFormikDevtools(
          formProps,
          <Form placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            <FormikOnChangeWithDebounce onFormChanged={onFormChanged} debounce={300} />
            <FormikScrollToError />
            <Stack
              spacing={7}
              direction="column"
              sx={{ px: 8, maxWidth: '710px', boxSizing: 'border-box' }}
              divider={<Divider flexItem />}
            >
              <Stack spacing={5} sx={{ pt: 7 }}>
                <Typography variant="h5Bold">{t('common.labels.style') as ReactNode}</Typography>
                <Stack>
                  <Field
                    id="style"
                    name="style"
                    component={RadioGroupFormField}
                    hiddenLabel
                    row
                    optionsSpacing={6}
                    optionsWrapperSx={{
                      width: '100%',
                      justifyContent: 'space-between',
                    }}
                    formControlLabelWrapperSx={{
                      width: '50%',
                    }}
                    optionLabelTypographyProps={{
                      variant: 'body1',
                    }}
                    options={[
                      { value: WidgetViewStyle.Image, label: 'common.labels.image', sx: { p: 0, mr: 3 } },
                      { value: WidgetViewStyle.Icon, label: 'common.labels.icon', sx: { p: 0, mr: 3 } },
                    ]}
                  />
                  <PreviewWebWidgetStylesOptions previewerPalette={previewerPalette} orientation={orientation} />
                </Stack>
              </Stack>

              <ConditionalRender condition={formProps.values?.style === WidgetViewStyle.Image}>
                <Stack spacing={5}>
                  <Typography variant="h5Bold">{t('common.labels.image') as ReactNode}</Typography>
                  <Field
                    name="image"
                    component={FileUploadFormField}
                    accept={[FileFormatName.PNG, FileFormatName.JPG]}
                    acceptHintsCommon={[
                      {
                        tKey: 'common.fileUpload.atLeastWidthAndHeight',
                        tKeyParams: { widthPixels: imageDimensions.width, heightPixels: imageDimensions.height },
                      },
                    ]}
                    cropperProps={{
                      ratio: imageDimensions.ratio,
                    }}
                    minImageDimension={[imageDimensions.width, imageDimensions.height]}
                    maxSize={getBytesFromMb(10)}
                    onFilePick={files => onCustomImagePick(files, formProps)}
                    wrapperSx={{ mt: 10 }}
                    uploadPaneProps={{
                      justifyContent: 'space-between',
                      spacing: 6,
                    }}
                    previewWrapperSx={{
                      height: '100%',
                      width: '100%',
                      justifyContent: 'flex-start',
                      alignItems: 'baseline',
                      p: 0,
                    }}
                    previewImageProps={(filePreviewResult: string) => ({
                      sx:
                        orientation === Orientation.Horizontal
                          ? {
                              height: '220px',
                              width: '200px',
                              m: 5,
                              mb: 0,
                              borderRadius: 2,
                              background: `url(${filePreviewResult}) no-repeat center/100%`,
                            }
                          : {
                              height: '90px',
                              width: '100%',
                              background: `url(${filePreviewResult}) no-repeat center/100%`,
                            },
                      component: 'div',
                    })}
                    uploadPaneWrapperSx={{
                      minHeight: '300px',
                      height: orientation === Orientation.Horizontal ? '420px' : '274px',
                      '& > *': {
                        width: '50%',
                        height: '100%',
                        flex: 1,
                      },
                    }}
                    previewTool={
                      !formProps.values.imagePredefinedId && (
                        <IconButton
                          sx={{
                            position: 'absolute',
                            top: '8px',
                            right: '8px',
                            bgcolor: 'common.black',
                            borderRadius: 1,
                            opacity: 0.8,
                            p: 1.5,
                            ':hover': {
                              bgcolor: 'common.black',
                            },
                          }}
                          onClick={() => onCropOriginalFile(formProps)}
                        >
                          <ResizeIcon fontSize="inherit" htmlColor="#000" />
                        </IconButton>
                      )
                    }
                    uploadImageTool={
                      <Stack spacing={8} sx={{ ml: 5, mt: 8, mb: 7 }}>
                        <Field
                          name="imageOverlay"
                          component={ColorPickerFormField}
                          fieldLabel="common.labels.imageOverlay"
                          presetColors={presetColors}
                          pickers={[PickerType.OneColor, PickerType.Gradient]}
                          colorPickerFieldAnchorSx={{ p: 0 }}
                        />
                        <PredefinedImagesLibraryPopover
                          onSelectImage={image => onSelectPredefinedImage(image, formProps)}
                          images={
                            orientation === Orientation.Horizontal
                              ? predefinedHorizontalWidgetImages
                              : predefinedVerticalWidgetImages
                          }
                          predefinedImageId={formProps.values.imagePredefinedId || 1}
                          wrapperSx={{
                            '& > button': {
                              justifyContent: 'initial',
                            },
                          }}
                          gridSx={
                            orientation === Orientation.Horizontal
                              ? {
                                  gridGap: '20px',
                                  gridTemplateColumns: 'repeat(3, 165px)',
                                }
                              : undefined
                          }
                        />
                      </Stack>
                    }
                  />
                </Stack>
                <WidgetIconWithBackgroundColorField
                  previewerPalette={previewerPalette}
                  presetColors={presetColors}
                  predefinedIcons={predefinedWidgetIcons}
                  onCustomIconPickAction={onCustomIconPick}
                  onSelectPredefinedIconAction={onSelectPredefinedIcon}
                />
              </ConditionalRender>

              <Stack spacing={5}>
                <Typography variant="h5Bold">{t('appDesign.labels.widgets.webUrl') as ReactNode}*</Typography>
                <Field
                  fieldSx={{ width: '100%' }}
                  id="url"
                  name="url"
                  component={TextFormField}
                  hiddenLabel
                  inputProps={{
                    maxLength: 120,
                    mask: '{https://}*[**************************************************************************************************************]',
                    lazy: false,
                    placeholderChar: '',
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" sx={{ mr: 0 }}>
                        <IconButton
                          color={urlMeta.error ? 'error' : 'success'}
                          onClick={() => openInNewTab(formProps.values.url)}
                          disabled={Boolean(urlMeta.error)}
                          sx={{ p: 0 }}
                        >
                          <Icons.Link />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <Typography variant="body2" sx={{ color: 'grey.500' }}>
                  {t('appDesign.labels.widgets.descriptions.webUrl') as ReactNode}
                </Typography>
              </Stack>

              <Stack spacing={5}>
                <Typography variant="h5Bold">{t('appDesign.labels.widgets.name') as ReactNode}*</Typography>
                <MultiLanguageTextField name="title" languages={availableLanguages} inputProps={{ maxLength: 20 }} />
              </Stack>

              {!orientation && (
                <Stack spacing={5}>
                  <Typography variant="h5Bold">{t('appDesign.labels.widgets.callToAction') as ReactNode}</Typography>
                  <MultiLanguageTextField
                    name="callToAction"
                    languages={availableLanguages}
                    inputProps={{ maxLength: 35 }}
                  />
                </Stack>
              )}

              <Box sx={{ mt: 10 }}>
                <Typography variant="body2" sx={{ color: 'grey.500' }}>
                  <Trans
                    i18nKey="appDesign.labels.customizeLayout.tabsSettingsDescription"
                    components={[
                      <LinkCore
                        component="button"
                        sx={{ textDecoration: 'underline' }}
                        color="inherit"
                        onClick={() => openLanguagesMenuTab(formProps)}
                      />,
                    ]}
                  />
                </Typography>
              </Box>
            </Stack>
          </Form>,
        );
      }}
    </Formik>
  );
};

export default WebWidgetPreferencesForm;
