import { createApiRequest } from '@egym/api';
import { Uuid } from '@egym/types';
import { ApplicationSettings } from '@types';

const APPLICATION_SETTINGS_BASE_URL = 'client/applications/:applicationUuid/settings';
const SUBMIT_APPLICATION_SETTINGS_URL = `${APPLICATION_SETTINGS_BASE_URL}/submit`;
const UNSUBMIT_APPLICATION_SETTINGS_URL = `${APPLICATION_SETTINGS_BASE_URL}/unsubmit`;
const VALIDATE_BRAND_NAME_APPLICATION_SETTINGS_URL = `${APPLICATION_SETTINGS_BASE_URL}/validate-brand`;

export const getApplicationSettings = createApiRequest<ApplicationSettings, unknown, { applicationUuid: Uuid }>(
  APPLICATION_SETTINGS_BASE_URL,
);

export const saveApplicationSettings = createApiRequest<
  ApplicationSettings,
  ApplicationSettings,
  { applicationUuid: Uuid }
>(APPLICATION_SETTINGS_BASE_URL, 'put');

export const submitApplicationSettings = createApiRequest<ApplicationSettings, unknown, { applicationUuid: Uuid }>(
  SUBMIT_APPLICATION_SETTINGS_URL,
  'post',
);

export const unsubmitApplicationSettings = createApiRequest<ApplicationSettings, unknown, { applicationUuid: Uuid }>(
  UNSUBMIT_APPLICATION_SETTINGS_URL,
  'post',
);

export const getBrandNameValidationResult = createApiRequest<
  any,
  unknown,
  { applicationUuid: Uuid },
  { brandName?: string }
>(VALIDATE_BRAND_NAME_APPLICATION_SETTINGS_URL);
