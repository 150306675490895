import { useMemo } from 'react';
import { Uuid } from '@egym/types';
import { allSettingsProgressStepsCount, appSettingsProgressConditions } from '@helpers';
import useClientApplication from '../useClientApplication';
import useClientApplicationSettings from '../useClientApplicationSettings';
import useGymLocationsCount from '../useGymLocationsCount';

const useAppSettingsProgress = (appUuid: Uuid) => {
  const { applicationSettings } = useClientApplicationSettings(appUuid);
  const { gymLocationsCountResponse, gymLocationsErrorsCountResponse } = useGymLocationsCount(appUuid);
  const { isAppleDevProgramAvailable } = useClientApplication(appUuid);

  const progressConditions = useMemo(() => {
    if (
      !(
        applicationSettings.isSuccess ||
        gymLocationsCountResponse.isSuccess ||
        gymLocationsErrorsCountResponse.isSuccess
      )
    )
      return [];
    return appSettingsProgressConditions(
      applicationSettings.data || {},
      isAppleDevProgramAvailable,
      gymLocationsCountResponse.data?.count,
      gymLocationsErrorsCountResponse.data?.count,
    );
  }, [
    applicationSettings.data,
    applicationSettings.isSuccess,
    gymLocationsCountResponse.isSuccess,
    gymLocationsCountResponse.data,
    gymLocationsErrorsCountResponse.isSuccess,
    gymLocationsErrorsCountResponse.data,
    isAppleDevProgramAvailable,
  ]);

  const doneStepsCount = useMemo(() => {
    return progressConditions.filter(it => it.condition).length;
  }, [progressConditions]);

  const generalSettingsDone = useMemo(() => {
    const generalSettingsCondition = progressConditions.find(it => it.type === 'generalSettings');
    return Boolean(generalSettingsCondition?.condition);
  }, [progressConditions]);

  const appleDevProgramDone = useMemo(() => {
    if (!isAppleDevProgramAvailable) return true;

    const appleDevProgramCondition = progressConditions.find(it => it.type === 'appleDevProgram');
    return Boolean(appleDevProgramCondition?.condition);
  }, [progressConditions, isAppleDevProgramAvailable]);

  const gymLocationsDone = useMemo(() => {
    const gymLocationsCondition = progressConditions.find(it => it.type === 'gymLocations');
    return Boolean(gymLocationsCondition?.condition);
  }, [progressConditions]);

  const allStepsCount = useMemo(() => {
    return allSettingsProgressStepsCount(isAppleDevProgramAvailable);
  }, [isAppleDevProgramAvailable]);

  return {
    doneStepsCount,
    allStepsCount,
    generalSettingsDone,
    appleDevProgramDone,
    gymLocationsDone,
    completed: doneStepsCount === allStepsCount,
  };
};

export default useAppSettingsProgress;
