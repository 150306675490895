import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, TableCell as MuiTableCell, TableSortLabel } from '@mui/material';
import { SystemStyleObject } from '@mui/system';
import { ConditionalRender, TableState, Typography } from '@egym/ui';
import { GroupedTableColumn, TableColumn } from '../../../../TableProps';

type Props = {
  group: GroupedTableColumn;
  column: TableColumn;
  first: boolean;
  headersCellSx?: SystemStyleObject;
  tableState: TableState;
  updateTableStateSorting: (fieldName: string) => void;
};

const ThGroupedColumnCell: React.FC<Props> = ({
  group,
  first,
  column,
  headersCellSx,
  tableState,
  updateTableStateSorting,
}) => {
  const { t } = useTranslation();
  const tableCellSx = {
    pb: 1,
    verticalAlign: 'bottom',
    ...headersCellSx,
    ...column.headerCellSx,
    ...group.parentHeaderCellSx,
  };

  return (
    <MuiTableCell
      variant="head"
      component="th"
      key={column.field}
      align={column.numeric ? 'right' : 'left'}
      padding={column.disablePadding ? 'none' : 'normal'}
      width={column.width}
      sx={tableCellSx}
      data-testid={`table-header-cell-${column.field}`}
    >
      <Stack direction="column">
        <ConditionalRender condition={first}>
          <Typography variant="body2Bold" sx={{ mb: 0.75 }}>
            {t(group.parentHeaderTitle) as ReactNode}
          </Typography>
        </ConditionalRender>
        <ConditionalRender condition={column.sortable}>
          <TableSortLabel
            active={Boolean(tableState.sorting?.[column.field])}
            direction={tableState.sorting?.[column.field]}
            onClick={() => updateTableStateSorting(column.field)}
          >
            <Typography variant="caption" color="grey.500" fontWeight="bold">
              {t(column.headerTitle) as ReactNode}
            </Typography>
          </TableSortLabel>
          <Typography variant="caption" color="grey.500" fontWeight="bold">
            {t(column.headerTitle) as ReactNode}
          </Typography>
        </ConditionalRender>
      </Stack>
    </MuiTableCell>
  );
};

export default ThGroupedColumnCell;
