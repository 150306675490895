import { Components } from '@mui/material/styles';

const MuiScopedCssBaseline: Components['MuiScopedCssBaseline'] = {
  styleOverrides: {
    root: {
      backgroundColor: 'transparent',
    },
  },
};

export default MuiScopedCssBaseline;
