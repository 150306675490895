import { Uuid } from '@egym/types';
import { useRouter } from '@egym/ui';
import useTrackEvent from '../useTrackEvent';

const useTrackEventByRouter = () => {
  const { urlParams } = useRouter<{ uuid: Uuid }>();

  return useTrackEvent(urlParams.uuid);
};

export default useTrackEventByRouter;
