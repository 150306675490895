import { Components } from '@mui/material/styles';

const MuiMenuitem: Components['MuiMenuItem'] = {
  styleOverrides: {
    root: {
      textTransform: 'none',
    },
  },
};

export default MuiMenuitem;
