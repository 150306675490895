import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Stack, SwitchChildren, SwitchChildrenItem, UploadedImage } from '@egym/ui';
import { AppPreviewerSizeProps, PreviewerRoute } from '../../AppPreviewerProps';
import useAppPreviewerState from '../../hooks/useAppPreviewerState';

type Props = {
  value: PreviewerRoute.AppIconIos | PreviewerRoute.AppIconAndroid;
  id: string;
  size: AppPreviewerSizeProps['size'];
};

const PreviewerAppIconRoute: React.FC<Props> = ({ value, id, size }) => {
  const {
    previewerState: { appIconLink, appIconBackground, iosAppIconName },
  } = useAppPreviewerState(id);
  const { t } = useTranslation();

  return (
    <Stack sx={{ height: '100%' }}>
      <SwitchChildren value={value}>
        <SwitchChildrenItem value={PreviewerRoute.AppIconIos}>
          <Stack sx={{ position: 'relative', width: '100%', height: '100%' }}>
            <Box
              component="img"
              src="/images/previewerDefaultImages/appIcon/ios.png"
              sx={{ height: '100%', width: '100%' }}
            />
            {(appIconBackground || appIconLink) && (
              <Stack
                alignItems="center"
                sx={{
                  position: 'absolute',
                  top: size === 'x-small' ? '395px' : '387px',
                  left: size === 'x-small' ? '145px' : '140px',
                  width: '92px',
                }}
              >
                <UploadedImage
                  id={`previewer-app-icon-ios-${id}`}
                  wrapperSx={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '48px',
                    height: '48px',
                    background: appIconBackground,
                    borderRadius: '11px',
                    padding: '7px',
                    boxSizing: 'border-box',
                  }}
                  imageSx={{
                    maxWidth: '100%',
                    maxHeight: '100%',
                    height: 'auto',
                  }}
                  file={{ preview: appIconLink || '' }}
                />
                <span
                  style={{
                    fontSize: '8.5px',
                    lineHeight: '16px',
                    textAlign: 'center',
                    marginTop: '3px',
                    color: 'white',
                  }}
                >
                  {iosAppIconName || (t('appPreviewer.labels.appIconName') as ReactNode)}
                </span>
              </Stack>
            )}
          </Stack>
        </SwitchChildrenItem>
        <SwitchChildrenItem value={PreviewerRoute.AppIconAndroid}>
          <Stack sx={{ position: 'relative', width: '100%', height: '100%' }}>
            <Box
              component="img"
              src="/images/previewerDefaultImages/appIcon/android.png"
              sx={{ height: '100%', width: '100%' }}
            />
            {(appIconBackground || appIconLink) && (
              <Stack
                alignItems="center"
                sx={{
                  position: 'absolute',
                  top: size === 'x-small' ? '304px' : '298px',
                  left: size === 'x-small' ? '242px' : '236px',
                  width: '60px',
                }}
              >
                <UploadedImage
                  id={`previewer-app-icon-android-${id}`}
                  wrapperSx={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '38.3px',
                    height: '38.3px',
                    background: appIconBackground,
                    borderRadius: '50%',
                    padding: '7px',
                    boxSizing: 'border-box',
                  }}
                  imageSx={{ maxWidth: '100%', maxHeight: '100%', height: 'auto' }}
                  file={{ preview: appIconLink || '' }}
                />
                <span
                  style={{
                    fontSize: '9px',
                    lineHeight: '16px',
                    textAlign: 'center',
                    marginTop: '6.2px',
                    color: 'white',
                    fontFamily:
                      'Roboto, Inv Maison Neue, Maison Neue,"-apple-system", BlinkMacSystemFont, Open Sans, "open-sans","sans-serif"',
                    textShadow: '0 0 3px rgba(0,0,0,0.88)',
                    width: '100%',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {t('appPreviewer.labels.appIconName') as ReactNode}
                </span>
              </Stack>
            )}
          </Stack>
        </SwitchChildrenItem>
      </SwitchChildren>
    </Stack>
  );
};

export default PreviewerAppIconRoute;
