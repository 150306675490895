import { useCallback } from 'react';
import { useTrackEventByRouter } from '@hooks';
import { BackButtonContainerProps, NavigationBackUrl, UseBackButtonResult } from '../../BackButtonProps';

const useBackButton = ({ backButton }: BackButtonContainerProps): UseBackButtonResult => {
  const trackEvent = useTrackEventByRouter();

  const onBackButtonClick = useCallback(() => {
    const buttonParams = backButton as NavigationBackUrl;
    if (buttonParams?.onClick) {
      buttonParams.onClick();
      return;
    }

    if (buttonParams?.analyticsEvent) {
      trackEvent(buttonParams?.analyticsEvent, buttonParams?.analyticsEventProps);
    }
  }, [backButton, trackEvent]);

  return {
    onBackButtonClick,
  };
};

export default useBackButton;
