import { styled } from '@mui/material/styles';
import { Tabs, Tab, tabsClasses, tabClasses } from '@egym/ui';

export const StyledTabs = styled(Tabs)`
  & .${tabsClasses.indicator} {
    display: none;
  }
`;

export const StyledTab = styled(Tab)`
  &.${tabClasses.textColorInherit} {
    color: #1d1d1d;
    opacity: 1;
    letter-spacing: 0.03125rem;
  }
  &.${tabClasses.selected} {
    background-color: #e0e0e0;
  }
`;
