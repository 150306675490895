import { forwardRef } from 'react';
import useSimpleTitleCustomizedWidgetPreferencesForm from './hooks/useSimpleTitleCustomizedWidgetPreferencesForm';
import SimpleTitleCustomizedWidgetPreferencesForm from './SimpleTitleCustomizedWidgetPreferencesForm';
import { SimpleTitleCustomizedWidgetPreferencesFormContainerProps } from './SimpleTitleCustomizedWidgetPreferencesFormProps';

const SimpleTitleCustomizedWidgetPreferencesFormContainer = forwardRef<
  any,
  SimpleTitleCustomizedWidgetPreferencesFormContainerProps
>((props, ref) => {
  const result = useSimpleTitleCustomizedWidgetPreferencesForm(props, ref);
  return <SimpleTitleCustomizedWidgetPreferencesForm {...props} {...result} />;
});

export default SimpleTitleCustomizedWidgetPreferencesFormContainer;
