import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const CalendarIcon: React.FC<SvgIconProps> = ({ sx, htmlColor = '#4C4A4A', ...rest }) => {
  return (
    <SvgIcon viewBox="0 0 24 24" sx={{ width: '20px', height: '20px', ...sx }} {...rest}>
      <g fill="none" fillRule="evenodd">
        <g fill={htmlColor}>
          <g>
            <g>
              <g>
                <g>
                  <g>
                    <path
                      d="M3.177 0c.56 0 1.012.47 1.012 1.05v.789h5.222V1.05C9.41.47 9.864 0 10.423 0h.04c.559 0 1.012.47 1.012 1.05v.789h1.113c.559 0 1.012.47 1.012 1.05v11.26c0 .58-.453 1.051-1.012 1.051H1.012C.453 15.2 0 14.73 0 14.15V2.89c0-.58.453-1.051 1.012-1.051h1.113V1.05C2.125.47 2.578 0 3.137 0zM11.9 5.011H1.7v8.53h10.2V5.01zM4.847 9.648c.09 0 .162.076.162.168v1.828c0 .093-.073.168-.162.168h-1.76c-.09 0-.163-.075-.163-.168V9.816c0-.092.073-.168.162-.168zm2.833 0c.09 0 .162.076.162.168v1.828c0 .093-.072.168-.162.168H5.92c-.09 0-.162-.075-.162-.168V9.816c0-.092.072-.168.162-.168zm2.834 0c.09 0 .162.076.162.168v1.828c0 .093-.073.168-.162.168H8.753c-.09 0-.162-.075-.162-.168V9.816c0-.092.073-.168.162-.168zm-5.667-2.94c.09 0 .162.074.162.167v1.828c0 .093-.073.168-.162.168h-1.76c-.09 0-.163-.075-.163-.168V6.875c0-.093.073-.168.162-.168zm2.833 0c.09 0 .162.074.162.167v1.828c0 .093-.072.168-.162.168H5.92c-.09 0-.162-.075-.162-.168V6.875c0-.093.072-.168.162-.168zm2.834 0c.09 0 .162.074.162.167v1.828c0 .093-.073.168-.162.168H8.753c-.09 0-.162-.075-.162-.168V6.875c0-.093.073-.168.162-.168z"
                      transform="translate(-34.000000, -801.000000) translate(20.000000, 723.000000) translate(0.000000, 34.000000) translate(0.000000, 38.000000) translate(14.000000, 6.000000) translate(5.000000, 4.000000)"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export default CalendarIcon;
