import React, { FC, ReactNode, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { SystemStyleObject } from '@mui/system/styleFunctionSx/styleFunctionSx';
import { Alert, alertClasses, Box, Button, ConditionalRender, Icons, Stack, useConfirmDialog } from '@egym/ui';
import LegalAccessibilityRequirementsDialog from './components/LegalAccessibilityRequirementsDialog';

type Props = {
  message: ReactNode;
  onClose?: () => void;
  wrapperSx?: SystemStyleObject;
  withDetailsButton?: boolean;
};

const ColorContrastAlert: FC<Props> = ({ message, onClose, withDetailsButton = true, wrapperSx }) => {
  const { t } = useTranslation();

  const { openConfirmDialog } = useConfirmDialog();
  const onClickSeeColorRequirementsDetails = useCallback(() => {
    openConfirmDialog({
      title: 'appDesign.legalAccessibilityDialog.title',
      text: LegalAccessibilityRequirementsDialog,
      maxWidth: 'md',
      showCancelButton: true,
      confirmText: 'common.buttons.gotIt',
      onConfirm: onClose,
    });
  }, [openConfirmDialog, onClose]);

  return (
    <Alert
      icon={<Icons.TipsAndUpdatesOutlined color="info" fontSize="inherit" />}
      severity="info"
      color="info"
      variant="standard"
      sx={{
        border: '1px solid',
        borderColor: 'info.light',
        borderRadius: 2,
        color: 'info.main',
        [`& .${alertClasses.icon}`]: {
          alignItems: 'inherit',
        },
        ...wrapperSx,
      }}
      onClose={onClose}
    >
      <Stack spacing={2}>
        <Box>
          {message}. {t('appDesign.validation.colorIsTooLightDescription') as ReactNode}
        </Box>
        <ConditionalRender condition={withDetailsButton}>
          <Button
            text="appDesign.buttons.colorIsTooLightDetails"
            variant="outlined"
            color="info"
            sx={{
              width: 'fit-content',
            }}
            onClick={onClickSeeColorRequirementsDetails}
          />
        </ConditionalRender>
      </Stack>
    </Alert>
  );
};

export default ColorContrastAlert;
