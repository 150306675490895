import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const DeDeFlagIcon: React.FC<SvgIconProps> = ({ sx, ...props }) => {
  return (
    <SvgIcon viewBox="0 0 640 480" {...props} sx={{ width: '18px', ...sx }}>
      <path fill="#ffce00" d="M0 320h640v160H0z" />
      <path fill="#000000" d="M0 0h640v160H0z" />
      <path fill="#d00" d="M0 160h640v160H0z" />
    </SvgIcon>
  );
};

export default DeDeFlagIcon;
