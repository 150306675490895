import { forwardRef } from 'react';
import FullTabWidgetPreferencesForm from './FullTabWidgetPreferencesForm';
import { FullTabWidgetPreferencesFormContainerProps } from './FullTabWidgetPreferencesFormProps';
import useFullTabWidgetPreferencesForm from './hooks/useFullTabWidgetPreferencesForm';

const FullTabWidgetPreferencesFormContainer = forwardRef<any, FullTabWidgetPreferencesFormContainerProps>(
  (props, ref) => {
    const result = useFullTabWidgetPreferencesForm(props, ref);
    return <FullTabWidgetPreferencesForm {...props} {...result} />;
  },
);

export default FullTabWidgetPreferencesFormContainer;
