import { Uuid } from '@egym/types';
import { useRouter } from '@egym/ui';
import useAppDesignCustomTabs, { UseAppDesignCustomTabsProps } from '../useAppDesignCustomTabs/useAppDesignCustomTabs';

type Props = Omit<UseAppDesignCustomTabsProps, 'appUuid'>;

const useAppDesignCustomTabsByRouter = (props?: Props) => {
  const { urlParams } = useRouter<{ uuid: Uuid }>();

  return useAppDesignCustomTabs({ appUuid: urlParams.uuid, ...props });
};

export default useAppDesignCustomTabsByRouter;
