import { FC } from 'react';
import { SxProps } from '@mui/system';
import { Avatar, Box, Icons } from '@egym/ui';

const ColorContrastBadgeContent: FC<{
  iconAvatarSx?: SxProps;
  onClick?: () => void;
}> = ({ iconAvatarSx, onClick }) => {
  return (
    <Box
      onClick={e => {
        if (onClick) {
          e.stopPropagation();
          onClick();
        }
      }}
    >
      <Avatar
        sx={{
          width: 22,
          height: 22,
          backgroundColor: 'white',
          color: 'info.light',
          border: '2px solid',
          borderColor: 'grey.400',
          fontSize: 14,
          ...iconAvatarSx,
        }}
      >
        <Icons.TipsAndUpdatesOutlined fontSize="inherit" />
      </Avatar>
    </Box>
  );
};

export default ColorContrastBadgeContent;
