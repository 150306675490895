import { addDays, subDays, format, isSameDay, addHours, addMinutes, startOfHour } from 'date-fns';

const today = new Date();

const currenDayOfWeek = Number(format(today, 'e')) - 1;

export const weekDays = Array.from({ length: 7 }).map((_, dayNumber) => {
  const date =
    currenDayOfWeek >= dayNumber
      ? subDays(today, currenDayOfWeek - dayNumber)
      : addDays(today, dayNumber - currenDayOfWeek);

  return {
    date,
    currentDay: isSameDay(today, date),
  };
});

export const currentClasses = [
  {
    id: 0,
    date: startOfHour(addMinutes(addHours(today, 4), 15)),
    durationMinutes: 45,
    name: 'Sculpt Guide Program',
    trainer: 'Darren Adams',
    gym: 'Super Gym',
    spotsLeft: 12,
    totalSpots: 30,
    bookedClass: false,
  },
  {
    id: 1,
    date: startOfHour(addMinutes(addHours(today, 1), 37)),
    durationMinutes: 30,
    name: 'Dance Mix',
    trainer: 'Rebeca',
    gym: 'Fitness Gym Berlin',
    spotsLeft: 0,
    totalSpots: 0,
    bookedClass: false,
  },
  {
    id: 2,
    date: startOfHour(addMinutes(addHours(today, 3), 22)),
    durationMinutes: 45,
    name: 'Special Program: Build Summer Body in 2 Weeks',
    trainer: 'Tristan Balgemann',
    gym: 'Super Gym',
    spotsLeft: 0,
    totalSpots: 0,
    bookedClass: true,
  },
  {
    id: 3,
    date: startOfHour(addMinutes(addHours(today, 2), 47)),
    durationMinutes: 50,
    name: 'Lilly`s HIIT Cardios',
    trainer: 'Lilly Fletcher',
    gym: 'Super Gym',
    spotsLeft: 0,
    totalSpots: 0,
    bookedClass: false,
  },
];
