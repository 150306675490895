import { useQuery } from '@tanstack/react-query';
import { Option } from '@egym/types';
import { getUsersEmails } from '@api';

const useUsers = (searchEmailEnabled: boolean, searchEmail?: string) => {
  const userEmailsResponse = useQuery(
    ['usersEmails', searchEmail],
    async () => getUsersEmails({ queryParams: { email: searchEmail } }),
    {
      select: result => result.data.map(email => ({ label: email, value: email }) as Option<string>),
      keepPreviousData: true,
      enabled: searchEmailEnabled,
    },
  );

  return {
    userEmailsResponse,
  };
};

export default useUsers;
