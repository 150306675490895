import { useMemo } from 'react';
import { colorThemeColors } from 'src/pages/AppDesign/constants';
import useAppPreviewerState from '../../../../hooks/useAppPreviewerState';
import { invertedColorThemes } from '../../constants';
import {
  PreviewerWellDoneRouteContainerProps,
  UsePreviewerWellDoneRouteResult,
} from '../../PreviewerWellDoneRouteProps';

const usePreviewerWellDoneRoute = ({ id }: PreviewerWellDoneRouteContainerProps): UsePreviewerWellDoneRouteResult => {
  const { palette } = useAppPreviewerState(id);

  const shouldInvertColors = useMemo(() => {
    return invertedColorThemes
      .map(it => colorThemeColors[it])
      .some(it => it.primary === palette.primary && it.secondary === palette.secondary);
  }, [palette]);

  return {
    palette,
    shouldInvertColors,
  };
};

export default usePreviewerWellDoneRoute;
