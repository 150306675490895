import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { allLanguages } from '@egym/ui';

const supportedLocales = allLanguages.map(lang => lang.locale.replace('_', '-'));

const i18nPreviewer = i18n.createInstance();

const config = {
  fallbackLng: {
    'ca-ES': ['es-ES', 'en-US'],
    default: ['en-US'],
  },
  debug: false,
  // Allow keys to be phrases having `:`, `.`
  nsSeparator: '@@@',

  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },

  load: 'currentOnly',

  supportedLngs: supportedLocales,

  react: {
    useSuspense: true,
  },

  backend: {
    loadPath: ([lang]) => `/locales/previewer/${lang.replace('-', '_')}.json`,
    queryStringParams: { t: Date.now() },
  },
};

i18nPreviewer
  .use(Backend)
  .use(initReactI18next)
  // @ts-ignore
  .init(config);

export default i18nPreviewer;
