import React, { useCallback, useMemo } from 'react';
import { Box, Paper } from '@egym/ui';
import { PredefinedImage } from '@types';

type Props = {
  id: number;
  path: string;
  selectedImage?: PredefinedImage;
  setSelectedImage: (image: PredefinedImage) => void;
};

const PredefinedViewImage: React.FC<Props> = ({ id, path, selectedImage, setSelectedImage }) => {
  const isSelected = useMemo(() => {
    return selectedImage?.id === id;
  }, [selectedImage, id]);

  const onSetSelectedImage = useCallback(() => {
    setSelectedImage({ id, path });
  }, [setSelectedImage, id, path]);

  return (
    <Paper
      variant="outlined"
      onClick={onSetSelectedImage}
      sx={{
        cursor: 'pointer',
        borderRadius: 1,
        border: '3px solid',
        borderColor: isSelected ? 'primary.main' : 'transparent',
        bgcolor: isSelected ? 'primary.main' : 'transparent',
        boxSizing: 'border-box',
      }}
    >
      <Box
        component="img"
        src={path}
        sx={{
          borderRadius: 1,
          width: '100%',
          height: '100%',
          objectFit: 'cover',
        }}
      />
    </Paper>
  );
};

export default PredefinedViewImage;
