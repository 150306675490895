import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const BulletIcon: React.FC<SvgIconProps> = ({ sx, ...props }) => {
  return (
    <SvgIcon viewBox="0 0 10 10" {...props} sx={{ width: '10px', height: '10px', ...sx }}>
      <g id="UI-Pre-Final" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Fill-1" transform="translate(-1.000000, -1.000000)" fill="#878787">
          <path d="M6.00025,8.00025 C4.89625,8.00025 4.00025,7.10425 4.00025,6.00025 C4.00025,4.89625 4.89625,4.00025 6.00025,4.00025 C7.10425,4.00025 8.00025,4.89625 8.00025,6.00025 C8.00025,7.10425 7.10425,8.00025 6.00025,8.00025 M10.70725,5.29325 L6.70725,1.29325 C6.31625,0.90225 5.68425,0.90225 5.29325,1.29325 L1.29325,5.29325 C0.90225,5.68425 0.90225,6.31625 1.29325,6.70725 L5.29325,10.70725 C5.48825,10.90225 5.74425,11.00025 6.00025,11.00025 C6.25625,11.00025 6.51225,10.90225 6.70725,10.70725 L10.70725,6.70725 C11.09825,6.31625 11.09825,5.68425 10.70725,5.29325" />
        </g>
      </g>
    </SvgIcon>
  );
};
