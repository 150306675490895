import React, { ReactElement, ReactNode } from 'react';
import { TFunction } from 'i18next';
import { Stack } from '@egym/ui';
import { IconWrapper } from '../../../Icons';

type Props = {
  tFunction: TFunction;
  icon: ReactElement;
  name: string;
  description: string;
};

const PreviewHealthBenefitItem: React.FC<Props> = ({ tFunction, icon, name, description }) => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        background: 'rgb(249, 241, 239)',
        minHeight: '80px',
        boxSizing: 'border-box',
        borderRadius: '12px',
        p: '12px',
      }}
    >
      <IconWrapper wrapperSx={{ borderRadius: '50%', width: '40px', height: '40px' }} bgColor="rgb(243, 228, 224)">
        {icon}
      </IconWrapper>
      <Stack sx={{ flex: 1, ml: '12px' }}>
        <span
          style={{
            fontSize: '15px',
            fontWeight: 700,
            lineHeight: '20px',
            color: 'rgb(33, 33, 33)',
            marginBottom: '4px',
          }}
        >
          {tFunction(name) as ReactNode}
        </span>
        <span style={{ fontSize: '13px', fontWeight: 300, lineHeight: '18px', color: 'rgb(41, 41, 41)' }}>
          {tFunction(description) as ReactNode}
        </span>
      </Stack>
    </Stack>
  );
};

export default PreviewHealthBenefitItem;
