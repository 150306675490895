import { useAppDesignProgressByRouter } from '@hooks';
import { UseAppDesignProgressBarResult } from '../../AppDesignProgressBarProps';

const useAppDesignProgressBar = (): UseAppDesignProgressBarResult => {
  const { doneStepsCount, allStepsCount } = useAppDesignProgressByRouter();

  return {
    doneStepsCount,
    allStepsCount,
  };
};

export default useAppDesignProgressBar;
