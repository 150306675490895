import React, { forwardRef, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Chip, Typography } from '@mui/material';
import { SxProps } from '@mui/system';

type Props = {
  wrapperSx?: SxProps;
};

const MainTabChip = forwardRef<any, Props>(({ wrapperSx }, ref) => {
  const { t } = useTranslation();

  return (
    <Chip
      ref={ref}
      color="primary"
      size="small"
      sx={{
        textTransform: 'uppercase',
        fontSize: '10px',
        backgroundColor: '#fbf2eb',
        borderRadius: '10.5px',
        color: ({ palette: { primary } }) => primary.main,
        letterSpacing: '0.42px',
        height: 'auto',
        minHeight: '20px',
        textAlign: 'center',
        boxSizing: 'border-box',
        py: 0.5,
        ...wrapperSx,
      }}
      label={
        <Typography variant="body2" sx={{ whiteSpace: 'normal', fontSize: '10px' }}>
          {t('appDesign.labels.customizeLayout.mainTab') as ReactNode}
        </Typography>
      }
    />
  );
});

export default MainTabChip;
