export const getPreviewerScale = previewerId => {
  const previewerElement = document.getElementById(`app-previewer-device-${previewerId}`);
  let scale = 1;
  if (document.defaultView && previewerElement) {
    const previewerTransform = document.defaultView.getComputedStyle(previewerElement).transform;

    scale = previewerTransform.match(/[+-]?\d+(\.\d+)?/g)?.[0] as unknown as number;

    // if deviceScale is unchanged, then get contentScale
    if (Number(scale) === 1) {
      const contentElement = document.getElementById(`previewer-content-wrapper-${previewerId}`);

      const contentTransform = contentElement && document.defaultView.getComputedStyle(contentElement).transform;

      scale = contentTransform?.match(/[+-]?\d+(\.\d+)?/g)?.[0] as unknown as number;
    }
  }

  return scale;
};
