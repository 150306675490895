import React, { PropsWithChildren } from 'react';

type Props = {
  condition?: boolean;
} & PropsWithChildren;

// @ts-ignore
export const ConditionalRender: React.FC<Props> = ({ children, condition }) => {
  const childrenArray = React.Children.toArray(children);
  if (!childrenArray.length) return null;
  return condition ? childrenArray[0] : childrenArray[1] || null;
};
