import { forwardRef } from 'react';
import { Box, IconButton, Icons, UploadedImage } from '@egym/ui';
import { SignInLogoProps } from './SignInLogoProps';

const SignInLogo = forwardRef<any, SignInLogoProps>(
  ({ id, logoSrc, init, canModify, logoElementRef, resizerElementRef, handleElementRef, ready, isViewMode }, ref) => {
    return (
      <Box
        ref={ref}
        onClick={init}
        sx={{
          visibility: ready ? 'visible' : 'hidden',
          transition: 'visibility 2s linear',
          position: 'absolute',
          height: 'auto',
          cursor: isViewMode || canModify ? undefined : 'pointer',
          ':active': {
            cursor: canModify ? 'grabbing' : undefined,
          },
          ':hover': !isViewMode
            ? {
                bgcolor: canModify ? undefined : 'action.active',
                borderRadius: canModify ? undefined : 1,
              }
            : undefined,
          outline: '2px dashed',
          outlineColor: ({ palette: { common } }) => (canModify ? common.white : 'transparent'),
          maxWidth: '100%',
        }}
      >
        <IconButton
          ref={handleElementRef}
          color="secondary"
          sx={{
            cursor: 'grab',
            transform: 'translate(-50%, -50%)',
            position: 'absolute',
            left: 0,
            top: 0,
            bgcolor: 'action.active',
            p: 1,
            boxShadow: 3,
            ':hover': {
              bgcolor: 'primary.main',
            },
            ':active': {
              cursor: 'grabbing',
            },
            visibility: canModify ? 'visible' : 'hidden',
          }}
        >
          <Icons.DragIndicator />
        </IconButton>
        <UploadedImage
          ref={logoElementRef}
          id={`signin-logo-${id}`}
          file={{ preview: logoSrc }}
          wrapperSx={{
            width: '100%',
            maxHeight: 'inherit',
            height: 'auto',
          }}
          imageSx={{
            pointerEvents: 'none',
            maxWidth: 'inherit',
            maxHeight: 'inherit',
            height: 'auto',
            svg: {
              pointerEvents: 'none',
              height: '100%',
              width: '100%',
            },
          }}
        />
        <IconButton
          ref={resizerElementRef}
          color="secondary"
          sx={{
            transform: 'translate(50%, 50%) rotate(135deg)',
            position: 'absolute',
            bottom: 0,
            right: 0,
            cursor: 'nwse-resize',
            bgcolor: 'action.active',
            p: 1,
            boxShadow: 3,
            ':hover': {
              bgcolor: 'primary.main',
            },
            visibility: canModify ? 'visible' : 'hidden',
          }}
        >
          <Icons.Height />
        </IconButton>
      </Box>
    );
  },
);

export default SignInLogo;
