import { useEffect, useMemo, useRef } from 'react';
import { useEffectOnce, useUpdateEffect, useDebounce } from 'react-use';
import { compactToFullTabFeatureMap, PreviewNotificationCenter } from '@components';
import useAppPreviewerState from '../../../../hooks/useAppPreviewerState';
import { PreviewerLayoutRouteContainerProps, UsePreviewerLayoutRouteResult } from '../../PreviewerLayoutRouteProps';

const usePreviewerLayoutRoute = ({
  id,
  scrollRef,
  showDemoTab,
  showTabWithNotification,
  appLayoutTabs,
  activeWidgetId,
  activeWidgetGroupId,
  tabId,
}: PreviewerLayoutRouteContainerProps): UsePreviewerLayoutRouteResult => {
  const { previewerState, changeSelectedTab } = useAppPreviewerState(id);
  const appLayoutScrollRef = useRef<HTMLDivElement>(null);

  const tabsResult = useMemo(() => {
    if (!appLayoutTabs || !previewerState.selectedLocale) return null;

    return appLayoutTabs.map(tab => ({
      ...tab,
      nameTranslated: tab.name[previewerState.selectedLocale as string] || tab.name.en_US,
      selected: typeof tab.selected === 'boolean' ? tab.selected : previewerState.selectedTab === tab.id,
    }));
  }, [appLayoutTabs, previewerState.selectedLocale, previewerState.selectedTab]);

  const currentTabId = useMemo(() => {
    return tabsResult?.find(tab => tab.selected)?.id || previewerState.selectedTab;
  }, [tabsResult, previewerState.selectedTab]);

  const currentFeatures = useMemo(() => {
    if (!appLayoutTabs) return [];

    const currentTab = appLayoutTabs?.find(tab => tab.id === currentTabId);

    if (!currentTab) return [];

    return currentTab.features;
  }, [appLayoutTabs, currentTabId]);

  const isActiveFullTabWidget = useMemo(() => {
    if (activeWidgetId) {
      const activeFeatureName = currentFeatures.find(({ props: { id: featureId } }) => featureId === activeWidgetId)
        ?.props?.name;
      return Boolean(activeFeatureName && Object.values(compactToFullTabFeatureMap).includes(activeFeatureName));
    }
    return false;
  }, [activeWidgetId, currentFeatures]);

  useDebounce(
    () => {
      if (activeWidgetId && !isActiveFullTabWidget) {
        const featureElement = window.document.getElementById(
          `preview-feature-${activeWidgetGroupId || activeWidgetId}`,
        );
        if (featureElement) {
          featureElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }
    },
    300,
    [activeWidgetId, activeWidgetGroupId],
  );

  useEffectOnce(() => {
    if (appLayoutTabs?.length) {
      changeSelectedTab(tabId || appLayoutTabs[0].id);
    }
  });

  useUpdateEffect(() => {
    if (appLayoutTabs?.length && currentTabId) {
      const tabIds = appLayoutTabs.map(tab => tab.id);

      if (!tabIds.includes(currentTabId)) {
        changeSelectedTab(appLayoutTabs[0].id);
      }
    }
  }, [appLayoutTabs]);

  useUpdateEffect(() => {
    if (tabId) {
      changeSelectedTab(tabId);
    }
  }, [tabId, id, activeWidgetId, activeWidgetGroupId]);

  useUpdateEffect(() => {
    if (showDemoTab || showTabWithNotification) return;

    if (appLayoutTabs?.length) {
      changeSelectedTab(appLayoutTabs[0].id);
    }
  }, [id, previewerState.appLayout, appLayoutTabs?.[0]?.id]);

  useEffect(() => {
    if (!showDemoTab) return;

    const demoTab = appLayoutTabs?.find(tab => tab.isDemoTab);

    if (demoTab) {
      changeSelectedTab(demoTab.id);
    }
  }, [showDemoTab, tabsResult, changeSelectedTab, scrollRef, appLayoutTabs]);

  useEffect(() => {
    if (!showTabWithNotification) return;

    const tabWithNotification = appLayoutTabs?.find(tab =>
      tab.features.some(feature => feature.component === PreviewNotificationCenter),
    );

    if (tabWithNotification) {
      changeSelectedTab(tabWithNotification.id);
    }
  }, [showTabWithNotification, appLayoutTabs, changeSelectedTab]);

  useUpdateEffect(() => {
    if (appLayoutScrollRef.current) {
      appLayoutScrollRef.current.scrollTo({ left: 0, top: 0, behavior: 'auto' });
    }
  }, [currentTabId]);

  const isMainTabSelected = useMemo(() => {
    if (!appLayoutTabs?.length) return false;

    return currentTabId === appLayoutTabs[0].id;
  }, [appLayoutTabs, currentTabId]);

  return {
    isMainTabSelected,
    tabsResult,
    currentFeatures,
    appLayoutScrollRef,
    isActiveFullTabWidget,
  };
};

export default usePreviewerLayoutRoute;
