import { AlertColor } from '@mui/lab';
import { SxProps } from '@mui/system';
import { Option } from '@egym/types';
import { ApplicationType } from '@types';
import {
  appPackageAdvancedOption,
  appPackageBasicOption,
  appPackageProOption,
} from '../../pages/AdminDashboard/constants';

export const statusStyles = {
  NOT_COMPLETED: { color: 'default', styles: { bgcolor: 'rgba(0, 0, 0, 0.1)' } },
  SUBMITTED: { color: 'info', severity: 'info', styles: { bgcolor: 'rgba(25, 128, 142, 0.1)' } },
  IN_REVIEW: { color: 'warning', severity: 'warning', styles: { bgcolor: 'rgba(150, 112, 0, 0.1)' } },
  APPROVED: { color: 'success', severity: 'success', styles: { bgcolor: 'rgba(23, 132, 59, 0.1)' } },
  REJECTED: { color: 'error', severity: 'error', styles: { bgcolor: 'rgba(217, 40, 69, 0.1)' } },
  MIGRATION: { color: 'success', severity: 'success', styles: { bgcolor: 'rgba(23, 132, 59, 0.1)' } },
};

export const getAppStatusStyles = (status: string): { color: any; severity?: AlertColor; styles: SxProps } => {
  return statusStyles[status] || statusStyles.NOT_COMPLETED;
};

export const getAvailablePackageOptions = (appType: ApplicationType): Option[] => {
  switch (appType) {
    case ApplicationType.Container:
      return [appPackageBasicOption];
    case ApplicationType.Standalone:
      return [appPackageAdvancedOption, appPackageProOption];
    default:
      return [];
  }
};
