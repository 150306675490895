import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useEffectOnce, useUpdateEffect } from 'react-use';
import { allLanguages, languageIconMap } from '@egym/ui';
import { toLocaleApiFormat, toLocaleFormat } from '@egym/utils';
import useAppPreviewerState from '../../../../hooks/useAppPreviewerState';
import {
  PreviewerLanguageSelectorContainerProps,
  UsePreviewerLanguageSelectorResult,
} from '../../PreviewerLanguageSelectorProps';

const usePreviewerLanguageSelector = ({
  previewerId,
  locales,
  outerSelectedLocale,
  setOuterSelectedLocale,
}: PreviewerLanguageSelectorContainerProps): UsePreviewerLanguageSelectorResult => {
  const { previewerState, changeSelectedLocale } = useAppPreviewerState(previewerId);
  const { i18n } = useTranslation();

  useUpdateEffect(() => {
    if (!previewerState.selectedLocale) return;

    i18n.changeLanguage(toLocaleFormat(previewerState.selectedLocale));
  }, [previewerState.selectedLocale]);

  const handleChangeSelectedLocale = useCallback(
    (newLocale: string, source?: string) => {
      const underscoredLocale = toLocaleApiFormat(newLocale);

      changeSelectedLocale(underscoredLocale);

      if (setOuterSelectedLocale) {
        setOuterSelectedLocale(underscoredLocale, source);
      }
    },
    [changeSelectedLocale, setOuterSelectedLocale],
  );

  const localesUnderscored = useMemo(() => {
    if (!locales?.length) return [];

    return locales.map(it => it.replace('-', '_'));
  }, [locales]);

  const languages = useMemo(
    () =>
      localesUnderscored.map(it => ({
        name: it,
        locale: it,
        icon: languageIconMap[it],
        shortName: allLanguages.find(l => l.locale === it)?.shortName,
      })),
    [localesUnderscored],
  );

  useUpdateEffect(() => {
    if (
      outerSelectedLocale &&
      toLocaleApiFormat(outerSelectedLocale) !== toLocaleApiFormat(previewerState.selectedLocale || '')
    ) {
      handleChangeSelectedLocale(outerSelectedLocale);
    }
  }, [outerSelectedLocale]);

  useEffectOnce(() => {
    if (outerSelectedLocale) {
      handleChangeSelectedLocale(outerSelectedLocale);
    } else if (localesUnderscored.length && !previewerState.selectedLocale) {
      handleChangeSelectedLocale(localesUnderscored[0]);
    }
  });

  useUpdateEffect(() => {
    if (outerSelectedLocale && outerSelectedLocale !== previewerState.selectedLocale) {
      handleChangeSelectedLocale(outerSelectedLocale);
    }
  }, [outerSelectedLocale]);

  useUpdateEffect(() => {
    if (!localesUnderscored?.length) return;

    if (
      !previewerState.selectedLocale ||
      (outerSelectedLocale && !localesUnderscored.includes(outerSelectedLocale)) ||
      (!outerSelectedLocale && localesUnderscored.includes(toLocaleApiFormat(previewerState.selectedLocale)))
    ) {
      handleChangeSelectedLocale(localesUnderscored[0]);
    }
    i18n.loadLanguages(localesUnderscored.map(toLocaleFormat));
  }, [localesUnderscored]);

  return {
    selectedLocale: previewerState.selectedLocale,
    handleChangeSelectedLocale,
    languages,
  };
};

export default usePreviewerLanguageSelector;
