import React from 'react';
import { FormControl, FormHelperText, Stack } from '@mui/material';
import { Box } from '@egym/ui';
import FileUploadPane from './components/FileUploadPane';
import PreviewResult from './components/PreviewResult';
import { uploadPaneWrapperStyles } from './FileUpload.styles';
import { FileUploadProps } from './FileUploadProps';

const FileUpload: React.FC<FileUploadProps> = ({
  name,
  fileUploadWrapperSx,
  uploadPaneWrapperSx,
  uploadPaneProps,
  getRootProps,
  getInputProps,
  disabled,
  isViewMode,
  helperText,
  FormHelperTextProps,
  error,
  previewResult,
  hintsResult,
  previewResultWrapperSx,
  previewWrapperSx,
  previewImageSx,
  disablePreview,
  paneSx,
  files,
  accept,
  acceptHints,
  acceptHintsCommon,
  previewTool,
  uploadImageTool,
  previewImageProps,
}) => {
  return (
    <FormControl sx={{ ...fileUploadWrapperSx }} error={error} disabled={disabled || isViewMode}>
      <Stack {...uploadPaneProps} direction="row" sx={{ ...uploadPaneWrapperStyles, ...uploadPaneWrapperSx }}>
        {Boolean(files.length) &&
          !disablePreview &&
          (previewResult || (
            <PreviewResult
              name={name}
              previewResultWrapperSx={previewResultWrapperSx}
              previewWrapperSx={previewWrapperSx}
              previewImageSx={previewImageSx}
              previewImageProps={previewImageProps}
              files={files}
              previewTool={previewTool}
              uploadImageTool={uploadImageTool}
            />
          ))}
        {!isViewMode && (
          <Stack flex="1" alignItems="center" justifyContent="center">
            <Box
              width={1}
              {...getRootProps({
                // Disable click and keydown behavior
                style: {
                  position: 'relative',
                  height: '100%',
                  flexBasis: '100%',
                },
              })}
            >
              <input {...getInputProps({ disabled })} />
              <FileUploadPane
                disabled={disabled}
                hintsResult={hintsResult}
                accept={accept}
                acceptHints={acceptHints}
                acceptHintsCommon={acceptHintsCommon}
                wrapperSx={paneSx}
              />
            </Box>
            {!isViewMode && helperText && (
              <FormHelperText
                error={error}
                {...FormHelperTextProps}
                sx={{ ...(FormHelperTextProps?.sx || {}), mx: 0, fontSize: '13px', textAlign: 'center' }}
              >
                {helperText}
              </FormHelperText>
            )}
          </Stack>
        )}
      </Stack>
    </FormControl>
  );
};

export default FileUpload;
