import { SxProps } from '@mui/system';

export const previewStyles: SxProps = {
  position: 'relative',
  width: '182px',
  height: '182px',
  boxSizing: 'border-box',
  mr: 5,
  borderRadius: 2,

  alignItems: 'center',
  justifyContent: 'center',
  p: 8,
  overflow: 'hidden',
};

export const previewImageStyles = {
  maxWidth: '100%',
  maxHeight: '100%',
};
