import React, { forwardRef, ReactNode } from 'react';
import { Paper, Stack } from '@egym/ui';
import { AppLayoutFeatureComponentProps } from '../../../../AppPreviewerProps';
import PreviewContainer from '../../../PreviewContainer';
import PreviewFeatureTitle from '../../../PreviewFeatureTitle';
import styles from './PreviewRanking.module.scss';

const PreviewRanking = forwardRef<HTMLDivElement, AppLayoutFeatureComponentProps>(
  ({ tFunction, palette, wrapperSx, previewerState, widgetProps }, ref) => {
    return (
      <PreviewContainer ref={ref} wrapperSx={wrapperSx}>
        <PreviewFeatureTitle
          tFunction={tFunction}
          palette={palette}
          title={
            widgetProps?.preferences?.title?.[previewerState.selectedLocale as string] ??
            widgetProps?.preferences?.title?.en_US ??
            'appPreviewer.labels.ranking'
          }
        />
        <Paper
          sx={{
            px: '15px',
            py: '15px',
            textAlign: 'left',
            fontSize: '14px',
            color: 'rgb(33,33,33)',
            borderRadius: '8px',
            boxShadow: '0 4px 6px rgba(223, 223, 223, 0.7)',
            height: '80px',
            boxSizing: 'border-box',
          }}
        >
          <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ height: '100%' }}>
            <Stack>
              <span style={{ fontSize: '12px', lineHeight: '17px', color: 'rgb(76, 74, 74)' }}>
                {tFunction('appPreviewer.labels.yourGymPosition') as ReactNode}
              </span>
              <span style={{ fontSize: '25px', fontWeight: 700, letterSpacing: '-0.5px', color: 'rgb(33, 33, 33)' }}>
                3rd
              </span>
            </Stack>
            <Stack display="inline-flex" direction="row" className={styles.rankingWrapper}>
              <img
                style={{ width: '40px', height: '40px', border: '2px solid white', borderRadius: '50%' }}
                src="/images/previewerDefaultImages/avatars/@2ximg.png"
                alt="1st"
              />
              <img
                style={{ width: '40px', height: '40px', border: '2px solid white', borderRadius: '50%' }}
                src="/images/previewerDefaultImages/avatars/@2ximg2.png"
                alt="1st"
              />
              <div
                style={{
                  width: '40px',
                  height: '40px',
                  lineHeight: '40px',
                  borderRadius: '50%',
                  border: '2px solid white',
                  background: 'rgb(224, 224, 224)',
                  color: 'rgb(0, 0, 0)',
                  textAlign: 'right',
                  fontSize: '11px',
                }}
              >
                <span style={{ paddingRight: '5px' }}>99+</span>
              </div>
            </Stack>
          </Stack>
        </Paper>
      </PreviewContainer>
    );
  },
);

export default PreviewRanking;
