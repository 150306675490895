import React, { forwardRef } from 'react';
import { AppLayoutFeatureComponentProps } from '../../../../AppPreviewerProps';
import PreviewContainer from '../../../PreviewContainer';
import PreviewFeatureTitle from '../../../PreviewFeatureTitle';
import { newChallenge } from './data';
import PreviewChallengeCard from './PreviewChallengeCard';

const PreviewChallenges = forwardRef<HTMLDivElement, AppLayoutFeatureComponentProps>(
  ({ tFunction, palette, wrapperSx, previewerState, widgetProps }, ref) => {
    return (
      <PreviewContainer ref={ref} wrapperSx={wrapperSx}>
        <PreviewFeatureTitle
          tFunction={tFunction}
          palette={palette}
          title={
            widgetProps?.preferences?.title?.[previewerState.selectedLocale as string] ||
            'appPreviewer.labels.defaultFeaturesTitles.challenges'
          }
          withViewAll
        />
        <PreviewChallengeCard tFunction={tFunction} palette={palette} {...newChallenge} />
      </PreviewContainer>
    );
  },
);

export default PreviewChallenges;
