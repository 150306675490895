import { Components } from '@mui/material/styles';
import MuiAlert from './MuiAlert';
import MuiAutocomplete from './MuiAutocomplete';
import MuiBreadcrumbs from './MuiBreadcrumbs';
import MuiButton from './MuiButton';
import MuiButtonBase from './MuiButtonBase';
import MuiDialogContentText from './MuiDialogContentText';
import MuiFormControl from './MuiFormControl';
import MuiInputBase from './MuiInputBase';
import MuiMenuItem from './MuiMenuItem';
import MuiOutlinedInput from './MuiOutlinedInput';
import MuiScopedCssBaseline from './MuiScopedCssBaseline';
import MuiSvgIcon from './MuiSvgIcon';
import MuiTextField from './MuiTextField';
import MuiTypography from './MuiTypography';

export const themeComponents: Components = {
  MuiButton,
  MuiButtonBase,
  MuiTextField,
  MuiInputBase,
  MuiOutlinedInput,
  MuiFormControl,
  MuiSvgIcon,
  MuiDialogContentText,
  MuiAutocomplete,
  MuiScopedCssBaseline,
  MuiBreadcrumbs,
  MuiTypography,
  MuiMenuItem,
  MuiAlert,
};
