import React, { forwardRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { AppLayoutFeatureComponentProps } from '../../../../AppPreviewerProps';
import PreviewContainer from '../../../PreviewContainer';
import PreviewFeatureTitle from '../../../PreviewFeatureTitle';
import { mockDeals } from './data';
import PreviewDealItem from './PreviewDealItem';

const PreviewDeals = forwardRef<HTMLDivElement, AppLayoutFeatureComponentProps>(
  ({ tFunction, palette, previewerState, wrapperSx, itemWrapperSx, widgetProps }, ref) => {
    return (
      <div ref={ref}>
        <PreviewContainer wrapperSx={wrapperSx}>
          <PreviewFeatureTitle
            tFunction={tFunction}
            palette={palette}
            title={
              widgetProps?.preferences?.title?.[previewerState.selectedLocale as string] || 'appPreviewer.labels.deals'
            }
            withViewAll
          />
        </PreviewContainer>

        <Swiper
          style={{ padding: '0 11.25px', maxWidth: '100%', boxSizing: 'border-box' }}
          slidesPerView={1}
          centeredSlides
          spaceBetween={5}
        >
          {mockDeals.map(deal => {
            return (
              <SwiperSlide key={deal.id} style={{ width: '90%' }}>
                <PreviewDealItem
                  tFunction={tFunction}
                  palette={palette}
                  previewerState={previewerState}
                  key={deal.id}
                  wrapperSx={itemWrapperSx}
                  {...deal}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    );
  },
);

export default PreviewDeals;
