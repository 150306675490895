import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const MainTabHeaderDefaultIcon13: React.FC<SvgIconProps> = ({ htmlColor = '#FFF', sx, ...props }) => {
  return (
    <SvgIcon viewBox="0 0 48 48" {...props} sx={{ width: '48px', height: '48px', ...sx }}>
      <g fill={htmlColor} fillRule="evenodd">
        <path d="M37 8a6 6 0 0 1 6 6v22a6 6 0 0 1-6 6H11a6 6 0 0 1-6-6V14a6 6 0 0 1 6-6h26zm2 12H9v16a2 2 0 0 0 1.85 1.995L11 38h26a2 2 0 0 0 1.995-1.85L39 36V20zm-2-8H11a2 2 0 0 0-1.995 1.85L9 14v2h30v-2a2 2 0 0 0-1.85-1.995L37 12z" />
        <g opacity=".6" transform="translate(14 4)">
          <rect width="4" height="8" rx="2" />
          <rect x="16" width="4" height="8" rx="2" />
          <path d="m9.907 29.049-3.663 1.926a.2.2 0 0 1-.29-.211l.673-3.923a.5.5 0 0 0-.144-.443l-2.85-2.778a.2.2 0 0 1 .11-.341l3.94-.573a.5.5 0 0 0 .376-.273l1.762-3.57a.2.2 0 0 1 .358 0l1.762 3.57a.5.5 0 0 0 .376.273l3.94.573a.2.2 0 0 1 .11.34l-2.85 2.78a.5.5 0 0 0-.144.442l.673 3.923a.2.2 0 0 1-.29.21l-3.663-1.925a.2.2 0 0 0-.186 0z" />
        </g>
      </g>
    </SvgIcon>
  );
};
