import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const AddFeaturedIcon: React.FC<SvgIconProps> = ({ sx, htmlColor = '#BDBDBD', ...props }) => {
  return (
    <SvgIcon
      viewBox="0 0 120 120"
      xmlns="http://www.w3.org/2000/svg"
      sx={{ width: '120px', height: '120px', ...sx }}
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h120v120H0z" />
        <g transform="translate(24 25)">
          <rect stroke={htmlColor} strokeWidth="4" x="2" y="2" width="30" height="29" rx="3" />
          <rect stroke={htmlColor} strokeWidth="4" x="39" y="2" width="30" height="29" rx="3" />
          <rect stroke={htmlColor} strokeWidth="4" x="2" y="38" width="30" height="29" rx="3" />
          <path fill={htmlColor} d="M52 43h4v22h-4z" />
          <path fill={htmlColor} d="M65 52v4H43v-4z" />
        </g>
      </g>
    </SvgIcon>
  );
};
