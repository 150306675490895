import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { TabsProps as MuiTabsProps, TabProps as MuiTabProps } from '@mui/material';
import { SxProps } from '@mui/system';
import { StyledTab, StyledTabs } from './PickersTabs.styles';

type Props = {
  sx?: SxProps;
  value: MuiTabsProps['value'];
  onChange: MuiTabsProps['onChange'];
  disabled?: boolean;
  tabs: (MuiTabProps & {
    value: MuiTabProps['value'];
    label: MuiTabProps['label'];
  })[];
};

const PickersTabs: React.FC<Props> = ({ value, onChange, tabs, disabled, sx }) => {
  const { t } = useTranslation();

  return (
    <StyledTabs
      value={value}
      onChange={onChange}
      variant="fullWidth"
      textColor="inherit"
      sx={{ minHeight: '24px', ...sx }}
    >
      {tabs.map(tab => (
        <StyledTab
          key={tab.value}
          {...tab}
          disabled={disabled}
          label={typeof tab.label === 'string' ? (t(tab.label) as ReactNode) : tab.label}
        />
      ))}
    </StyledTabs>
  );
};

export default PickersTabs;
