import { createApiRequest } from '@egym/api';
import { Email } from '@egym/types';
import { PersonInfo } from '@types';

const PRINCIPAL_URL = 'principal';
const USERS_BASE_URL = 'users';
const USERS_EMAILS_URL = `${USERS_BASE_URL}/emails`;

export const getPersonInfo = createApiRequest<PersonInfo>(PRINCIPAL_URL);

export const getUsersEmails = createApiRequest<Email[]>(USERS_EMAILS_URL);
