import jwt from 'jsonwebtoken';
import { atom, selector } from 'recoil';
import { AuthTokenInfo } from '@types';
import { sessionStorageEffectRaw } from './effects';

const keys = {
  authToken: 'principal.authToken',
  authTokenInfo: 'principal.authTokenInfo',
};

const atoms = {
  authToken: atom<string>({
    key: keys.authToken,
    default: '',
    effects_UNSTABLE: [sessionStorageEffectRaw('auth#token')],
  }),
};

const selectors = {
  authTokenInfo: selector({
    key: keys.authTokenInfo,
    get: ({ get }): AuthTokenInfo | undefined => {
      const currentAuthToken = get(atoms.authToken);
      if (currentAuthToken) {
        const tokenData = jwt.decode(currentAuthToken, { complete: false, json: true });
        if (tokenData) {
          return {
            email: tokenData.sub || '',
            expiration: tokenData.exp || 0,
            roles: tokenData.roles,
          };
        }
      }
      return undefined;
    },
  }),
};

export const principalState = {
  keys,
  atoms,
  selectors,
};
