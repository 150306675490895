import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { FieldProps } from 'formik';
import { Option } from '@egym/types';
import { FormFieldWithLabel } from '../FormFieldWithLabel';

type ToggleButtonFormFieldProps = {
  wrapperSx?: SxProps;
  fieldSx?: SxProps;
  required?: boolean;
  options: Option[];
  labelSx?: SxProps;
  label?: string;
  onDefaultChange?: (value: any) => void;
  isViewMode?: boolean;
  disabled?: boolean;
};

type Props = ToggleButtonFormFieldProps & FieldProps;

const ToggleButtonFormField: React.FC<Props> = React.memo(
  ({ fieldSx, wrapperSx, labelSx, options, label, form, field, required, onDefaultChange, isViewMode, disabled }) => {
    const { t } = useTranslation();

    return (
      <FormFieldWithLabel wrapperSx={wrapperSx} labelSx={labelSx} label={label} isViewMode={isViewMode} hiddenLabel>
        {() =>
          isViewMode ? (
            <Typography variant="body1">
              {options
                .filter(option => option.value === field.value)
                .map(option => t(option.label))
                .join(', ')}
            </Typography>
          ) : (
            <ToggleButtonGroup
              sx={fieldSx}
              id={field.name}
              color="standard"
              value={field.value}
              size="medium"
              exclusive
              fullWidth
              onChange={(e, value) => {
                if (!required || value) {
                  form.setFieldValue(field.name, value);
                }
                if (onDefaultChange) {
                  onDefaultChange(value);
                }
              }}
            >
              {options.map(option => (
                <ToggleButton
                  key={option.value}
                  value={option.value}
                  color="standard"
                  disabled={isViewMode || disabled}
                >
                  {t(option.label) as ReactNode}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          )
        }
      </FormFieldWithLabel>
    );
  },
);
export default ToggleButtonFormField;
