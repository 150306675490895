import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const YoutubeIcon: React.FC<SvgIconProps> = ({ sx, htmlColor = '#4C4A4A', ...rest }) => {
  return (
    <SvgIcon viewBox="0 0 24 24" sx={{ width: '20px', height: '20px', ...sx }} {...rest}>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <path d="M0 0H24V24H0z" transform="translate(-1820 -1426) translate(1820 1426)" />
            <path
              fill={htmlColor}
              d="M9.954 15.02V9.071l5.228 2.973-5.228 2.974zM21.582 7.2c-.23-.867-.908-1.549-1.769-1.78C18.254 4.998 12 4.998 12 4.998s-6.254 0-7.814.421c-.86.232-1.539.914-1.768 1.781C2 8.771 2 12.045 2 12.045s0 3.276.418 4.845c.23.866.908 1.55 1.768 1.78 1.56.421 7.814.421 7.814.421s6.254 0 7.813-.42c.861-.232 1.54-.915 1.77-1.78.417-1.57.417-4.846.417-4.846s0-3.275-.418-4.844z"
              transform="translate(-1820 -1426) translate(1820 1426)"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export default YoutubeIcon;
