import { Uuid } from '@egym/types';
import { useRouter } from '@egym/ui';
import useAppSettingsProgress from '../useAppSettingsProgress';

const useAppSettingsProgressByRouter = () => {
  const { urlParams } = useRouter<{ uuid: Uuid }>();

  return useAppSettingsProgress(urlParams.uuid);
};

export default useAppSettingsProgressByRouter;
