export enum AppLayoutFeatureEnum {
  PreviewNotificationCenter = 'NOTIFICATION_CENTER', // Notification Center         + (fix layout)
  PreviewTrainingPlans = 'TRAINING_PLANS', // Your Training Plans                   +
  PreviewActivityHistory = 'LATEST_ACTIVITY', // Latest Activity                    +
  PreviewChallenges = 'CHALLENGES', // Challenges                                   + (fix layout)
  PreviewLocationFinder = 'LOCATION_FINDER', // Home Gym                            +
  PreviewActivityFeed = 'ACTIVITY_FEED', // Activity Feed                           + (fix layout)
  PreviewRewards = 'REWARDS', // Rewards                                            + (fix layout)
  PreviewDeals = 'DEALS', // Deals                                                  +
  PreviewGoalCenter = 'GOAL_CENTER', // Goal                                        +
  PreviewGuestPass = 'GUEST_PASS', // Guest Pass                                    + (fix layout)

  // features with full tab versions
  PreviewFindAClass = 'FIND_A_CLASS', // Classes                                    + (fix layout)
  PreviewFindAClassOnTheTab = 'FIND_A_CLASS_ON_THE_TAB', // Classes on tab
  PreviewActivityLevel = 'ACTIVITY_LEVEL', // Activity level
  PreviewActivityLevelOnTheTab = 'ACTIVITY_LEVEL_ON_THE_TAB', // Activity level on tab
  PreviewBioAge = 'BIO_AGE', // Bio age
  PreviewBioAgeOnTheTab = 'BIO_AGE_ON_THE_TAB', // Bio age on tab
  PreviewRanking = 'RANKING', // Ranking
  PreviewRankingOnTheTab = 'RANKING_ON_THE_TAB', // Ranking on the tab

  // standard web-like features
  PreviewExtras = 'EXTRAS',
  PreviewConnectedApps = 'CONNECTED_APPS',
  PreviewReferAFriend = 'REFER_A_FRIEND',
  PreviewRequestTrainer = 'REQUEST_TRAINER',

  PreviewWebWidget = 'WEB_WIDGET',

  PreviewGroupWebWidget = 'GROUP_WEB_WIDGET',

  // todo: remove EXPLORE_SCHEDULE after https://egym.atlassian.net/browse/WT-564
  PreviewExploreSchedule = 'EXPLORE_SCHEDULE',
  // todo: remove VIRTUAL_CLASSES after https://egym.atlassian.net/browse/WT-564
  PreviewVirtualClasses = 'VIRTUAL_CLASSES', // Virtual classes
  // todo: remove VIRTUAL_CLASSES_ON_THE_TAB after https://egym.atlassian.net/browse/WT-564
  PreviewVirtualClassesOnTheTab = 'VIRTUAL_CLASSES_ON_THE_TAB', // // Virtual classes on tab
  // todo: remove TRACK_WORKOUT after https://egym.atlassian.net/browse/WT-564
  PreviewWorkoutTracking = 'TRACK_WORKOUT', // Track Workout

  // missed widgets:
  //  - Support & FAQ
  //  - ranking on tab
  //  - apple watch app
}
