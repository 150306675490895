import React, { useCallback, useMemo, useState } from 'react';
import { ColorChangeHandler, ColorResult } from 'react-color';
import Color from 'color';
import { SketchPickerProps } from 'react-color/lib/components/sketch/Sketch';
import { Stack } from '../../layout/Stack';
import { SwitchChildren } from '../../utils/SwitchChildren';
import { SwitchChildrenItem } from '../../utils/SwitchChildrenItem';
import CustomColorPicker from './components/CustomColorPicker';
import CustomGradientPicker from './components/CustomGradientPicker';
import PickersTabs from './components/PickersTabs';
import { PickerType } from './types';

type Props = {
  handleChange: ColorChangeHandler;
  value?: ColorResult['rgb'] | string;
  presetColors?: SketchPickerProps['presetColors'];
  disableAlpha?: boolean;
  disableGradientTools?: boolean;
  pickers?: PickerType[];
  error?: string;
  popoverOpen?: boolean;
};

const getDefaultSelectedColor = value => {
  if (typeof value === 'string') {
    const rgbaObject = Color(value.match(/rgba\(.*?\)/g)?.[0]).object();

    return {
      ...rgbaObject,
      a: rgbaObject.alpha || 1,
    };
  }

  return value;
};

const ColorPickerContent: React.FC<Props> = ({
  value,
  handleChange,
  presetColors = [],
  disableAlpha,
  disableGradientTools,
  pickers,
  error,
  popoverOpen,
}) => {
  const availablePickers = useMemo(() => (pickers?.length ? pickers : [PickerType.OneColor]), [pickers]);
  const [selectedPicker, setSelectedPicker] = useState<PickerType>(
    typeof value === 'string' ? availablePickers[1] : availablePickers[0],
  );
  const [selectedColor, setSelectedColor] = useState<ColorResult['rgb'] | undefined>(getDefaultSelectedColor(value));

  const handleColorChange = useCallback<ColorChangeHandler>(colorResult => {
    setSelectedColor(colorResult.rgb);
  }, []);

  const tabs = useMemo(() => {
    return availablePickers.map(picker => ({
      label: picker === PickerType.OneColor ? 'common.labels.color' : 'common.labels.gradient',
      value: picker,
    }));
  }, [availablePickers]);

  return (
    <div>
      {tabs.length > 1 && (
        <Stack sx={{ px: 3, pt: 2 }}>
          <PickersTabs tabs={tabs} value={selectedPicker} onChange={(_, tabValue) => setSelectedPicker(tabValue)} />
        </Stack>
      )}
      <SwitchChildren value={selectedPicker}>
        <SwitchChildrenItem value={PickerType.OneColor}>
          <CustomColorPicker
            color={selectedColor}
            onChange={handleColorChange}
            onChangeComplete={handleChange}
            // @ts-ignore
            disableAlpha={disableAlpha}
            presetColors={presetColors}
            error={error}
            popoverOpen={popoverOpen}
          />
        </SwitchChildrenItem>
        <SwitchChildrenItem value={PickerType.Gradient}>
          <CustomGradientPicker
            // @ts-ignore
            value={value}
            onChangeComplete={handleChange}
            disableAlpha={disableAlpha}
            presetColors={presetColors}
            disableGradientTools={disableGradientTools}
            error={error}
            popoverOpen={popoverOpen}
          />
        </SwitchChildrenItem>
      </SwitchChildren>
    </div>
  );
};

export default ColorPickerContent;
