import { Components } from '@mui/material/styles';

const MuiTypography: Components['MuiTypography'] = {
  defaultProps: {
    variantMapping: {
      h1: 'h1',
      h2: 'h2',
      h2Bold: 'h2',
      h3: 'h3',
      h3Bold: 'h3',
      h4: 'h4',
      h4Bold: 'h4',
      h5: 'h5',
      h5Bold: 'h5',
      h5Medium: 'h5',
      h6: 'h6',
      h6Bold: 'h6',
      subtitle1: 'h6',
      subtitle2: 'h6',
      body1: 'p',
      body2: 'p',
    },
  },
};

export default MuiTypography;
