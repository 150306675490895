import React, { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, IconButton, Stack, Typography } from '@mui/material';
import { Icons } from '@egym/ui';
import { DrawerConfig } from '../../../../../hooks/useDrawer/types';

type Props = {
  drawerConfig: DrawerConfig;
  onBackButtonClick: () => void;
  isConfirming: boolean;
  closeDrawer: () => void;
};

const DrawerHeader: FC<Props> = ({ drawerConfig, onBackButtonClick, isConfirming, closeDrawer }) => {
  const { t } = useTranslation();

  const HeaderActions = drawerConfig?.headerActions;

  return (
    <Stack
      {...drawerConfig?.headerProps}
      sx={{
        pt: 7,
        px: 8,
        position: 'sticky',
        bgcolor: 'common.white',
        zIndex: 10,
        top: 0,
        ...drawerConfig?.headerProps?.sx,
      }}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
        {drawerConfig?.withBackButton && (
          <IconButton
            onClick={onBackButtonClick}
            disabled={isConfirming}
            edge="start"
            sx={{ mr: 2, ml: 0, fontSize: '32px', p: 0 }}
          >
            <Icons.ArrowBack fontSize="inherit" />
          </IconButton>
        )}

        <Stack direction="column" sx={{ mr: 'auto' }}>
          {drawerConfig?.title && (
            <Typography variant="h5Bold" sx={{ fontSize: theme => theme.typography.pxToRem(28), lineHeight: 1.14 }}>
              {t(drawerConfig.title, drawerConfig.titleParams) as ReactNode}
            </Typography>
          )}
          {drawerConfig?.subtitle && (
            <Typography
              variant="body1Medium"
              sx={{
                textTransform: 'uppercase',
                color: 'grey.700',
                lineHeight: '1.75',
                mt: 1,
                mb: 6,
              }}
            >
              {t(drawerConfig.subtitle, drawerConfig.subtitleParams) as ReactNode}
            </Typography>
          )}
        </Stack>

        <Stack direction="row" alignItems="center" divider={<Divider flexItem orientation="vertical" />} spacing={2}>
          {HeaderActions && <HeaderActions {...drawerConfig?.headerActionsProps} />}
          <IconButton aria-label="close" sx={{ fontSize: '32px', p: 0 }} onClick={closeDrawer} disabled={isConfirming}>
            <Icons.Close fontSize="inherit" />
          </IconButton>
        </Stack>
      </Stack>

      {(drawerConfig?.subtitle || drawerConfig.headerDividerProps) && (
        <Divider
          flexItem
          {...drawerConfig.headerDividerProps}
          sx={{ ml: -8, width: ({ spacing }) => `calc(100% + ${spacing(16)})`, ...drawerConfig.headerDividerProps?.sx }}
        />
      )}
    </Stack>
  );
};

export default DrawerHeader;
