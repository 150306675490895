import { useCallback, useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { validateColorOnHighContrast } from '@helpers';
import { PredefinedTabIcon } from '@types';
import {
  UseWidgetIconWithBackgroundColorFieldResult,
  WidgetIconWithBackgroundColorFieldContainerProps,
} from '../../WidgetIconWithBackgroundColorFieldProps';

const useWidgetIconWithBackgroundColorField = ({
  previewerPalette,
  onCustomIconPickAction,
  onSelectPredefinedIconAction,
}: WidgetIconWithBackgroundColorFieldContainerProps): UseWidgetIconWithBackgroundColorFieldResult => {
  const formProps = useFormikContext<{ backgroundColor?: string; iconPredefinedId?: number }>();
  const {
    values: { backgroundColor, iconPredefinedId },
  } = formProps;

  const [highContrastWarning, setHighContrastWarning] = useState<{
    primary: boolean;
    source: 'colorTheme' | 'widget';
  } | null>(null);

  const [showHighContrastWarning, setShowHighContrastWarning] = useState<boolean>(true);

  const [colorPickerOpened, setColorPickerOpened] = useState<boolean>(false);

  useEffect(() => {
    if (colorPickerOpened) return;

    if (backgroundColor && backgroundColor !== previewerPalette.primary) {
      const isValid = validateColorOnHighContrast(backgroundColor);
      setHighContrastWarning({ primary: isValid, source: 'widget' });
    } else {
      const isValid = validateColorOnHighContrast(previewerPalette.primary);
      setHighContrastWarning({ primary: isValid, source: 'colorTheme' });
    }
  }, [colorPickerOpened, previewerPalette.primary, backgroundColor, setHighContrastWarning]);

  const onCustomIconPick = useCallback(() => {
    onCustomIconPickAction(formProps);
  }, [formProps, onCustomIconPickAction]);

  const onSelectPredefinedIcon = useCallback(
    (predefinedIcon: PredefinedTabIcon) => {
      onSelectPredefinedIconAction(predefinedIcon, formProps);
    },
    [formProps, onSelectPredefinedIconAction],
  );

  return {
    highContrastWarning,
    setHighContrastWarning,
    showHighContrastWarning,
    setShowHighContrastWarning,
    setColorPickerOpened,
    backgroundColor,
    iconPredefinedId,
    onCustomIconPick,
    onSelectPredefinedIcon,
  };
};

export default useWidgetIconWithBackgroundColorField;
