import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const MainTabHeaderDefaultIcon6: React.FC<SvgIconProps> = ({ htmlColor = '#FFF', sx, ...props }) => {
  return (
    <SvgIcon viewBox="0 0 48 48" {...props} sx={{ width: '48px', height: '48px', ...sx }}>
      <g fill={htmlColor} fillRule="evenodd">
        <path d="M41.935 14.522c-.287-1.08-1.414-1.708-2.541-1.473-4.879 1.041-10.31 1.493-15.394 1.493-5.083 0-10.515-.452-15.394-1.493-1.127-.235-2.254.393-2.541 1.473-.287 1.1.41 2.22 1.537 2.475 3.3.707 6.867 1.198 10.249 1.473v23.566c0 1.08.922 1.964 2.05 1.964 1.127 0 2.05-.884 2.05-1.964v-9.82h4.099v9.82c0 1.08.922 1.964 2.05 1.964 1.127 0 2.05-.884 2.05-1.964V18.47c3.381-.275 6.948-.766 10.228-1.473 1.148-.256 1.844-1.375 1.557-2.475z" />
        <path
          d="M24.5 13c2.475 0 4.5-2.025 4.5-4.5S26.975 4 24.5 4A4.513 4.513 0 0 0 20 8.5c0 2.475 2.025 4.5 4.5 4.5z"
          opacity=".6"
        />
      </g>
    </SvgIcon>
  );
};
