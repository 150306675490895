import React, { forwardRef, Suspense, useRef } from 'react';
import { I18nextProvider } from 'react-i18next';
import { toLocaleFormat } from '@egym/utils';
import AppPreviewer from './AppPreviewer';
import { AppPreviewerContainerProps } from './AppPreviewerProps';
import useAppPreviewer from './hooks/useAppPreviewer';
import i18nPreviewer from './i18n';

const AppPreviewerContainer = forwardRef<any, AppPreviewerContainerProps>((props, ref) => {
  const appPreviewerProps = useAppPreviewer(props, ref);
  // @ts-ignore
  const i18nInstance = useRef(i18nPreviewer.cloneInstance({ lng: toLocaleFormat(props.selectedLocale || 'en-US') }));

  return (
    <Suspense fallback={<span />}>
      <I18nextProvider i18n={i18nInstance.current}>
        <AppPreviewer {...props} {...appPreviewerProps} />
      </I18nextProvider>
    </Suspense>
  );
});

export default AppPreviewerContainer;
