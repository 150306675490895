import { Components } from '@mui/material/styles';

const MuiDialogContentText: Components['MuiDialogContentText'] = {
  styleOverrides: {
    root: {
      marginBottom: 0,
    },
  },
};

export default MuiDialogContentText;
