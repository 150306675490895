import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const BetterSleepIcon: React.FC<SvgIconProps> = ({ sx, htmlColor = '#4C4A4A', ...rest }) => {
  return (
    <SvgIcon sx={{ width: '20px', height: '20px', color: htmlColor, ...sx }} {...rest}>
      <path
        d="M14 2a1 1 0 1 1 0 2h-1v1c0 .02-.01.035-.011.055C17.488 5.521 21 9.129 21 13.5c0 1.957-.711 3.756-1.892 5.194l.963.963a.999.999 0 1 1-1.414 1.414l-.982-.981A9.287 9.287 0 0 1 12 22a9.287 9.287 0 0 1-5.675-1.91l-.982.981a.995.995 0 0 1-.707.293.999.999 0 0 1-.707-1.707l.963-.963C3.711 17.256 3 15.457 3 13.5c0-4.371 3.512-7.979 8.011-8.445L11 5V4h-1a1 1 0 1 1 0-2zm-2 5c-3.859 0-7 2.916-7 6.5S8.141 20 12 20s7-2.916 7-6.5S15.859 7 12 7zm2 3a1.001 1.001 0 0 1 .781 1.625L12.081 15H14a1 1 0 1 1 0 2h-4a1.001 1.001 0 0 1-.781-1.625l2.7-3.375H10a1 1 0 1 1 0-2zm4.282-5.699a1 1 0 0 1 1.404-.17l.788.615a1 1 0 0 1-1.232 1.576l-.788-.616a1.002 1.002 0 0 1-.172-1.405z"
        fill={htmlColor}
        fillRule="evenodd"
      />
    </SvgIcon>
  );
};

export default BetterSleepIcon;
