import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const ArrowBackIcon: React.FC<SvgIconProps> = ({ sx, htmlColor = '#4C4A4A', ...rest }) => {
  return (
    <SvgIcon viewBox="0 0 24 24" sx={{ width: '20px', height: '20px', ...sx }} {...rest}>
      <g fill="none" fillRule="evenodd">
        <g fill={htmlColor}>
          <g>
            <g>
              <path
                d="M15.682 18.679c-.393.395-1.013.426-1.441.094l-.103-.091-5.816-5.8c-.391-.39-.426-1.002-.102-1.432l.088-.103 5.815-6.014c.42-.433 1.11-.445 1.545-.025.4.388.44 1.008.114 1.44l-.09.106-5.065 5.24 5.052 5.038c.395.394.426 1.014.094 1.443l-.091.104z"
                transform="translate(-24 -53) translate(20 49) translate(4 4)"
              />
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export default ArrowBackIcon;
