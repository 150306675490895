import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { Uuid } from '@egym/types';
import { useSnackbar } from '@egym/ui';
import {
  getApplicationSettings,
  getBrandNameValidationResult,
  saveApplicationSettings,
  submitApplicationSettings,
  unsubmitApplicationSettings,
} from '@api';
import { ApplicationSettings, ApplicationSettingsStatus } from '@types';
import useClientApplication from '../useClientApplication';

const useClientApplicationSettings = (appUuid: Uuid) => {
  const { t } = useTranslation();
  const { isArchived, demo } = useClientApplication(appUuid);
  const { openSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const replaceAppSettingInCache = (applicationId: Uuid, updatedAppSettings: ApplicationSettings) => {
    queryClient.setQueryData(['appSettings', applicationId], (prevData: any) =>
      prevData
        ? {
            ...prevData,
            data: { ...prevData.data, ...updatedAppSettings },
          }
        : { data: { ...updatedAppSettings } },
    );
    openSnackbar(t('appSettings.labels.saveSuccess'));
  };

  const applicationSettings = useQuery(
    ['appSettings', appUuid],
    async () => getApplicationSettings({ urlParams: { applicationUuid: appUuid } }),
    {
      refetchOnMount: false,
      keepPreviousData: true,
      enabled: Boolean(appUuid),
      select: result => result.data,
      onError: (err: AxiosError) => {
        if (err.response?.status !== 404) {
          openSnackbar(t('common.netError'), { severity: 'error' });
        }
      },
    },
  );

  const saveSettings = useMutation(
    async (data: ApplicationSettings) =>
      saveApplicationSettings({ payload: data, urlParams: { applicationUuid: appUuid } }),
    {
      onSuccess: async res => replaceAppSettingInCache(appUuid, res.data),
      onError: () => {
        openSnackbar(t('common.netError'), { severity: 'error' });
      },
    },
  );

  const submitSettings = useMutation(
    async () => submitApplicationSettings({ urlParams: { applicationUuid: appUuid } }),
    {
      onSuccess: async res => replaceAppSettingInCache(appUuid, res.data),
      onError: () => {
        openSnackbar(t('common.netError'), { severity: 'error' });
      },
    },
  );

  const unsubmitSettings = useMutation(
    async () => unsubmitApplicationSettings({ urlParams: { applicationUuid: appUuid } }),
    {
      onSuccess: async res => replaceAppSettingInCache(appUuid, res.data),
      onError: () => {
        openSnackbar(t('common.netError'), { severity: 'error' });
      },
    },
  );

  const isViewMode = useMemo(
    () =>
      (applicationSettings.isSuccess &&
        applicationSettings.data.status !== ApplicationSettingsStatus.NOT_COMPLETED &&
        applicationSettings.data.status !== ApplicationSettingsStatus.REJECTED) ||
      isArchived,
    [isArchived, applicationSettings.isSuccess, applicationSettings.data?.status],
  );

  const validateBrandName = async (brandName: string): Promise<AxiosResponse> => {
    const cache = queryClient.getQueryData<AxiosResponse>(['validateBrandName', appUuid, brandName]);
    if (!cache) {
      try {
        const response = await getBrandNameValidationResult({
          urlParams: { applicationUuid: appUuid },
          queryParams: { brandName },
        });
        await queryClient.setQueryData(['validateBrandName', appUuid, brandName], response);
        return response;
      } catch (e) {
        await queryClient.setQueryData(['validateBrandName', appUuid, brandName], e.response);
        return e.response;
      }
    }
    return cache;
  };

  return {
    applicationSettings,
    isViewMode,
    isArchived,
    demo,
    saveSettings,
    submitSettings,
    unsubmitSettings,
    validateBrandName,
  };
};

export default useClientApplicationSettings;
