import React, { PropsWithChildren } from 'react';
import { ContainerProps } from '@mui/material';
import { SxProps } from '@mui/system';
import { ConditionalRender, Container, LoadingContainer } from '@egym/ui';
import Header from '../Header';
import { HeaderContainerProps } from '../Header/HeaderProps';

type Props = {
  wrapperSx?: SxProps;
  contentSx?: SxProps;
  loading?: boolean;
  withHeader?: boolean;
  headerProps?: HeaderContainerProps;
  contentContainerProps?: ContainerProps;
} & PropsWithChildren;

const PageContainer: React.FC<Props> = ({
  children,
  wrapperSx,
  contentSx,
  loading,
  withHeader,
  headerProps,
  contentContainerProps,
}) => {
  return (
    <Container maxWidth={false} disableGutters sx={{ minWidth: theme => theme.breakpoints.values.lg, ...wrapperSx }}>
      {withHeader && <Header {...headerProps} />}
      <Container maxWidth="lg" sx={{ pb: 5, ...contentSx }} {...contentContainerProps}>
        <ConditionalRender condition={loading}>
          <LoadingContainer />
          <>{children}</>
        </ConditionalRender>
      </Container>
    </Container>
  );
};

export default PageContainer;
