import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const MainTabHeaderDefaultIcon21: React.FC<SvgIconProps> = ({ htmlColor = '#FFF', sx, ...props }) => {
  return (
    <SvgIcon viewBox="0 0 48 48" {...props} sx={{ width: '48px', height: '48px', ...sx }}>
      <g fill={htmlColor} fillRule="evenodd">
        <path d="m10.515 10.742-.14.067-.14.07-.137.072A6 6 0 0 0 7 16.203v5.988l.005.456a20.027 20.027 0 0 0 6.407 14.22c2.237 2.071 4.995 3.886 8.261 5.458l.62.293a4 4 0 0 0 3.385.013c3.553-1.642 6.527-3.557 8.91-5.764A20 20 0 0 0 41 22.19v-5.988a6 6 0 0 0-3.515-5.461L25.657 5.359a4 4 0 0 0-3.314 0l-11.828 5.383zM24 9l11.828 5.382A2 2 0 0 1 37 16.202v5.99a16 16 0 0 1-5.13 11.74C29.815 35.836 27.19 37.525 24 39l-.592-.28c-2.924-1.407-5.35-3.003-7.279-4.788a16 16 0 0 1-5.124-11.336L11 22.191v-5.988a2 2 0 0 1 1.032-1.75l.14-.07L24 9z" />
        <path
          d="M29.912 16.784a2 2 0 0 1 3.27 2.297l-.094.135-8.038 10.5a2 2 0 0 1-2.888.304l-.12-.111-4.962-5a2 2 0 0 1 2.713-2.935l.127.117 3.346 3.373 6.646-8.68z"
          opacity=".6"
        />
      </g>
    </SvgIcon>
  );
};
