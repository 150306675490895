import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { SxProps } from '@mui/system';
import { Icons, Stack, Typography } from '@egym/ui';

type Props = {
  wrapperSx?: SxProps;
};

const ActionRequiredIcon: FC<Props> = ({ wrapperSx }) => {
  const { t } = useTranslation();

  return (
    <Stack direction="row" alignItems="center" sx={{ ...wrapperSx }}>
      <Icons.WarningAmber sx={{ color: 'warning.main', pr: 2 }} />
      <Typography variant="body2Bold" sx={{ color: 'warning.main', textTransform: 'uppercase' }}>
        {t('common.labels.actionRequired') as ReactNode}
      </Typography>
    </Stack>
  );
};

export default ActionRequiredIcon;
