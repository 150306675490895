import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, FormControlLabel, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { FieldProps } from 'formik';
import { PartialBy } from '@egym/types';
import { ConditionalRender } from '../../utils';

type CheckboxFormFieldProps = {
  label: string;
  wrapperSx?: SxProps;
  fieldSx?: SxProps;
  isViewMode?: boolean;
  viewModeSx?: SxProps;
  isDisabledMode?: boolean;
};

type Props = PartialBy<FieldProps, 'form' | 'meta'> & CheckboxFormFieldProps;

const CheckboxFormField: React.FC<Props> = ({
  wrapperSx,
  fieldSx,
  label,
  field,
  isViewMode,
  viewModeSx,
  isDisabledMode,
}) => {
  const { t } = useTranslation();
  const labelViewModeSx = isViewMode
    ? {
        ...wrapperSx,
        alignItems: 'normal',
        cursor: 'default',
        ml: 0,
        py: 4,
      }
    : {
        ...wrapperSx,
        color: isDisabledMode ? 'grey.700' : 'none',
      };

  return (
    <FormControlLabel
      control={
        <ConditionalRender condition={isViewMode}>
          <Typography sx={viewModeSx}>
            {field.value ? (t('common.buttons.yes') as ReactNode) : (t('common.buttons.no') as ReactNode)}
          </Typography>
          <Checkbox
            checked={field.value || false}
            onChange={e => field.onChange({ ...e, target: { name: e.target.name, value: e.target.checked } })}
            name={field.name}
            disabled={isViewMode || isDisabledMode}
            sx={{ ...fieldSx }}
          />
        </ConditionalRender>
      }
      label={isViewMode ? <Typography variant="caption">{t(label) as ReactNode}</Typography> : (t(label) as ReactNode)}
      labelPlacement={isViewMode ? 'top' : 'end'}
      sx={labelViewModeSx}
    />
  );
};

export default CheckboxFormField;
