import { atom } from 'recoil';
import { SnackbarConfig } from '../types';

const keys = {
  message: 'snackbarGlobalState.message',
};

const atoms = {
  message: atom<SnackbarConfig | null>({
    key: keys.message,
    default: null,
  }),
};

export const snackbarGlobalState = {
  keys,
  atoms,
};
