import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const FacebookIcon: React.FC<SvgIconProps> = ({ sx, htmlColor = '#4C4A4A', ...rest }) => {
  return (
    <SvgIcon viewBox="0 0 24 24" sx={{ width: '20px', height: '20px', ...sx }} {...rest}>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <path d="M0 0H24V24H0z" transform="translate(-1820 -1352) translate(1820 1352)" />
            <path
              fill={htmlColor}
              d="M12 3c-4.971 0-9 4.054-9 9.055 0 4.52 3.291 8.265 7.594 8.945v-6.327H8.309v-2.618h2.285V10.06c0-2.27 1.344-3.524 3.399-3.524.985 0 2.015.176 2.015.176v2.229h-1.135c-1.118 0-1.467.698-1.467 1.415v1.698h2.496l-.399 2.618h-2.097V21C17.709 20.32 21 16.574 21 12.055 21 7.054 16.971 3 12 3"
              transform="translate(-1820 -1352) translate(1820 1352)"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export default FacebookIcon;
