import React, { ReactNode, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Stack, Typography, Box } from '@egym/ui';
import { PredefinedTabIcon } from '@types';
import PredefinedIcon from './components/PredefinedIcon';

type Props = {
  handleClose?: () => void; // passed from Popover
  onSelectIcon: (icon: PredefinedTabIcon) => void;
  icons: PredefinedTabIcon[];
  predefinedIconId?: number | null;
};

const PredefinedIconsLibraryPopoverContent: React.FC<Props> = ({
  icons,
  handleClose,
  onSelectIcon,
  predefinedIconId,
}) => {
  const { t } = useTranslation();
  const [selectedIcon, setSelectedIcon] = useState<PredefinedTabIcon | undefined>(
    icons.find(icon => icon.id === predefinedIconId),
  );

  const onConfirm = useCallback(() => {
    if (selectedIcon) {
      onSelectIcon(selectedIcon);
    }
    if (handleClose) handleClose();
  }, [selectedIcon, handleClose, onSelectIcon]);

  return (
    <Stack sx={{ position: 'relative', maxWidth: '590px', maxHeight: '500px' }}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        p={5}
        pb={3}
        sx={{
          position: 'sticky',
          top: 0,
          bgcolor: 'common.white',
          zIndex: 10,
          borderBottom: '1px solid',
          borderColor: 'grey.300',
        }}
      >
        <Typography variant="h6Bold">{t('appDesign.labels.selectIcon') as ReactNode}</Typography>
      </Stack>
      <Box sx={{ display: 'grid', gridGap: '26px', gridTemplateColumns: 'repeat(6, 70px)', p: 5, maxHeight: '100%' }}>
        {icons.map(icon => (
          <PredefinedIcon key={icon.id} {...icon} selectedIcon={selectedIcon} setSelectedIcon={setSelectedIcon} />
        ))}
      </Box>
      <Stack
        direction="row"
        p={5}
        sx={{
          position: 'sticky',
          bottom: 0,
          bgcolor: 'common.white',
          zIndex: 10,
          borderTop: '1px solid',
          borderColor: 'grey.300',
        }}
        spacing={3}
      >
        <Button
          text="common.labels.select"
          color="primary"
          variant="contained"
          disabled={!selectedIcon}
          onClick={onConfirm}
        />
        <Button text="common.buttons.cancel" variant="outlined" color="inherit" onClick={handleClose} />
      </Stack>
    </Stack>
  );
};

export default PredefinedIconsLibraryPopoverContent;
