import React, { ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PaperProps, TypographyProps } from '@mui/material';
import { SxProps } from '@mui/system';
import { Icons } from '../../icons';
import { Box } from '../../layout';
import { Paper } from '../../surfaces';
import { Typography } from '../Typography';
import { getWrapperStyles } from './HintTile.styles';

type Props = {
  title?: string;
  titleTypographyProps?: TypographyProps<any, any>;
  wrapperSx?: SxProps;
  paperProps?: PaperProps;
  texts: string[];
  textsTypographyProps?: TypographyProps<any, any>;
  withIcons?: boolean;
  withWrapper?: boolean;
  textsWrapperSx?: SxProps;
  textItemWrapperSx?: SxProps;
  getSeparator?: (translatedText: string) => ReactNode;
};

export const HintTile: React.FC<Props> = ({
  title,
  titleTypographyProps,
  texts,
  textsTypographyProps,
  wrapperSx,
  paperProps,
  withIcons = true,
  withWrapper = true,
  textsWrapperSx,
  textItemWrapperSx,
  getSeparator,
}) => {
  const { t } = useTranslation();

  const wrapperStyles = useMemo(() => getWrapperStyles(withIcons, withWrapper), [withIcons, withWrapper]);

  return (
    <Paper sx={{ ...wrapperStyles, ...wrapperSx }} {...paperProps}>
      {title && (
        <Typography variant="subtitle2Bold" {...titleTypographyProps}>
          {t(title)}
        </Typography>
      )}
      <Box sx={textsWrapperSx}>
        {texts.map(text => (
          <Box key={text} sx={{ display: 'flex', ...textItemWrapperSx }}>
            {withIcons && <Icons.Info sx={{ mr: 1, mt: 1 }} fontSize="small" color="primary" />}
            <Typography variant="subtitle2" {...textsTypographyProps}>
              {t(text)}
              {getSeparator && getSeparator(t(text))}
            </Typography>
          </Box>
        ))}
      </Box>
    </Paper>
  );
};
