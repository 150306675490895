import { Language } from '@egym/types';
import { toLocaleFormat } from '@egym/utils';
import { allLanguages, languageIconMap } from './languagesAndIcons';

type ReturnProps = {
  languages: Language[];
  splittedLanguages: Language[];
};

const useLanguages = (): ReturnProps => {
  const notSupportedLocales = ['iw-IL', 'no-NO'];

  const languages = allLanguages
    .map(language => ({
      ...language,
      locale: toLocaleFormat(language.locale),
      icon: languageIconMap[language.locale],
    }))
    .filter(language => !notSupportedLocales.includes(language.locale));

  const splittedLanguages = allLanguages.reduce<Language[]>((acc, language) => {
    return acc.find(accLang => accLang.locale === language.locale.split('-')[0])
      ? acc
      : [
          ...acc,
          {
            locale: language.locale.split('-')[0],
            name: language.name.split(' ')[0],
          },
        ];
  }, []);

  return {
    languages,
    splittedLanguages,
  };
};

export default useLanguages;
