import React, { ReactNode } from 'react';
import { Stack } from '@mui/material';
import { useFormatDate } from '@egym/ui';
import { AppLayoutFeatureComponentProps } from '../../../../AppPreviewerProps';
import { StyledBreadcrumbs } from './PreviewActivityHistoryItem.styles';

type Props = AppLayoutFeatureComponentProps & {
  image: string;
  date: Date;
  name: string;
  activityPoints: number;
  breadcrumbs: { text: string; params?: Record<string, any> }[];
};

const PreviewActivityHistoryItem: React.FC<Props> = ({
  tFunction,
  palette,
  previewerState,
  image,
  name,
  date,
  activityPoints,
  breadcrumbs,
}) => {
  const { format } = useFormatDate(previewerState.selectedLocale);

  return (
    <Stack direction="row" sx={{ py: '12px' }}>
      <img src={image} alt={name} style={{ width: '78px', height: '78px', marginRight: '15px' }} />
      <Stack direction="column">
        <span
          style={{
            lineHeight: '16px',
            fontSize: '10px',
            fontWeight: 500,
            color: 'rgb(86, 84, 84)',
            marginBottom: '4px',
          }}
        >
          {format(date, 'eee, LLL dd')}
        </span>
        <span style={{ fontSize: '14px', fontWeight: 700, lineHeight: '18px', marginBottom: '1px' }}>
          {tFunction(name) as ReactNode}
        </span>
        <StyledBreadcrumbs separator="&bull;" sx={{ fontSize: '11px', color: 'rgb(33, 33, 33)', lineHeight: '16px' }}>
          {breadcrumbs.map(breadcrumb => (
            <span key={breadcrumb.text}>{tFunction(breadcrumb.text, breadcrumb.params) as ReactNode}</span>
          ))}
        </StyledBreadcrumbs>
        <span
          style={{
            fontSize: '11px',
            lineHeight: '16px',
            color: palette.primary,
            fontWeight: 600,
            background: palette.primaryTransparent,
            padding: '2px 6px',
            borderRadius: '4px',
            width: 'fit-content',
            textTransform: 'lowercase',
            marginTop: '4px',
          }}
        >
          {tFunction('appPreviewer.labels.activityPointsCount', { count: activityPoints }) as ReactNode}
        </span>
      </Stack>
    </Stack>
  );
};

export default PreviewActivityHistoryItem;
