import { Components } from '@mui/material/styles';

const MuiTextField: Components['MuiTextField'] = {
  defaultProps: {
    SelectProps: {
      MenuProps: {
        PaperProps: {
          sx: {
            maxHeight: '400px !important',
          },
        },
      },
    },
  },
};

export default MuiTextField;
