import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const MainTabHeaderDefaultIcon26: React.FC<SvgIconProps> = ({ htmlColor = '#FFF', sx, ...props }) => {
  return (
    <SvgIcon viewBox="0 0 48 48" {...props} sx={{ width: '48px', height: '48px', ...sx }}>
      <g fill={htmlColor} fillRule="evenodd">
        <path d="M11.272 11.272c7.03-7.03 18.426-7.03 25.456 0 7.03 7.03 7.03 18.426 0 25.456-7.03 7.03-18.426 7.03-25.456 0-7.03-7.03-7.03-18.426 0-25.456zm2.829 2.829c-5.468 5.467-5.468 14.331 0 19.798 5.467 5.468 14.331 5.468 19.798 0 5.468-5.467 5.468-14.331 0-19.798-5.467-5.468-14.331-5.468-19.798 0z" />
        <path
          d="m13.393 10.565 4.9 4.899c1.286-1.484 2.296-2.98 2.91-4.295.356-.765.55-1.413.585-1.87a.99.99 0 0 0-.01-.295.335.335 0 0 0 .062.103l.039.044 2.828-2.829c2.004 2.004 1.118 5.584-1.485 9.338l-.366.513c-.188.257-.384.514-.587.771l-.415.515a30.98 30.98 0 0 1-.722.842l2.867 2.87 9.9-9.899 2.829 2.829-9.9 9.898 2.798 2.799.484-.446.542-.485.269-.236.535-.457.53-.437.524-.417.518-.396c3.603-2.695 6.709-3.787 8.472-2.216l.124.117-2.829 2.828c.206.206.436.303.616.32l-.124.026-.072.018c-.464.12-1.147.433-1.962.922-1.421.853-3.094 2.14-4.79 3.694l4.265 4.266-2.829 2.829-4.265-4.266c-1.555 1.697-2.842 3.37-3.695 4.791-.489.815-.803 1.498-.922 1.962l-.03.13-.013.066a1.002 1.002 0 0 0-.227-.511l-.094-.105-2.828 2.829c-1.74-1.74-.66-4.907 2.1-8.596l.395-.518.417-.524.437-.53.457-.535.477-.54.244-.27.446-.485-2.799-2.798-10.606 10.607-2.828-2.828 10.606-10.608-2.87-2.868c-.28.25-.56.49-.842.723l-.515.415c-.257.203-.514.4-.771.587l-.513.366c-3.67 2.544-7.17 3.448-9.199 1.617l-.139-.132 2.829-2.828a.402.402 0 0 0-.147-.1.99.99 0 0 0 .294.01c.458-.035 1.106-.23 1.87-.587 1.315-.613 2.813-1.624 4.296-2.909l-4.899-4.9 2.828-2.828z"
          opacity=".6"
        />
      </g>
    </SvgIcon>
  );
};
