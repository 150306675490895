import { ApplicationStatus } from '@types';
import { tableQuerySelector, tableStateAtom } from './helpers';

const keys = {
  table: 'applicationsTable.table',
  queryParams: 'applicationsTable.queryParams',
};

const atoms = {
  table: tableStateAtom(
    keys.table,
    {
      filtering: {
        status: ApplicationStatus.InProgress,
        demo: false,
      },
      sorting: {
        createdAt: 'desc',
      },
    },
    ['status', 'demo'],
  ),
};

const selectors = {
  queryParams: tableQuerySelector(keys.queryParams, atoms.table),
};

export const applicationsTableState = {
  keys,
  atoms,
  selectors,
};
