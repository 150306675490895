import { FC } from 'react';
import BackButton from './BackButton';
import { BackButtonContainerProps } from './BackButtonProps';
import useBackButton from './hooks/useBackButton';

const BackButtonContainer: FC<BackButtonContainerProps> = props => {
  const backButtonProps = useBackButton(props);

  return <BackButton {...props} {...backButtonProps} />;
};

export default BackButtonContainer;
