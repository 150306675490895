import { styled } from '@mui/material/styles';
import { Tabs, tabsClasses } from '@egym/ui';

export const StyledTabs = styled(Tabs, { shouldForwardProp: propName => propName !== 'background' })<{
  background: string;
}>`
  &.${tabsClasses.root} {
    min-height: 24px;
    background: ${props => props.background};
    border-radius: 8px;
    padding: 2px;
    margin-bottom: 10px;
  }

  & .${tabsClasses.flexContainer} {
    align-items: center;
    height: 100%;
  }

  & .${tabsClasses.indicator} {
    background-color: transparent;
  }
`;
