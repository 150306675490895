import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { AppLayoutFeatureComponentProps } from '../../../../AppPreviewerProps';
import PreviewContainer from '../../../PreviewContainer';
import { bioAgeItemsMock } from './data';
import StatsCarouselItem from './StatsCarouselItem';

const StatsCarousel: React.FC<AppLayoutFeatureComponentProps> = ({ tFunction, palette }) => {
  return (
    <PreviewContainer wrapperSx={{ px: '15px', width: '100%', boxSizing: 'border-box' }}>
      <Swiper slidesPerGroup={2} spaceBetween={15} centeredSlides={false} slidesPerView={2} grabCursor>
        {bioAgeItemsMock.map(it => {
          return (
            <SwiperSlide key={it.id}>
              <StatsCarouselItem tFunction={tFunction} palette={palette} key={it.id} {...it} />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </PreviewContainer>
  );
};

export default StatsCarousel;
