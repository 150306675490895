import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { getDefaultAppLayoutTabs, prepareCustomLayoutTabs, transformDefaultTabForPreviewer } from '@helpers';
import { useClientApplicationDesignByRouter } from '@hooks';
import { AppDesignLayoutTab, AppDesignLayoutTabWidget, ApplicationLayout, ApplicationPackage } from '@types';

export type UseApplicationDesignLayoutTabsProps = {
  appLayout?: ApplicationLayout;
  appPackage?: ApplicationPackage;
  customAppLayoutTabs?: AppDesignLayoutTab[];
  customAppLayoutWidgets?: AppDesignLayoutTabWidget[];
};

const useApplicationDesignLayoutTabs = ({
  appLayout,
  appPackage,
  customAppLayoutTabs,
  customAppLayoutWidgets,
}: UseApplicationDesignLayoutTabsProps) => {
  const { t } = useTranslation();
  const { appLocales } = useClientApplicationDesignByRouter();
  const defaultAppLayoutTabs = useMemo(
    () => getDefaultAppLayoutTabs({ appLayout, appPackage }),
    [appLayout, appPackage],
  );

  const appLayoutTabs = useMemo(() => {
    if (customAppLayoutTabs?.length) {
      return prepareCustomLayoutTabs(
        customAppLayoutTabs,
        defaultAppLayoutTabs,
        customAppLayoutWidgets || [],
        t as TFunction,
        appLocales,
      );
    }

    return defaultAppLayoutTabs.map((tab, index) =>
      transformDefaultTabForPreviewer(tab, index, t as TFunction, appLocales),
    );
  }, [customAppLayoutTabs, defaultAppLayoutTabs, customAppLayoutWidgets, t, appLocales]);

  return {
    appLayoutTabs,
  };
};

export default useApplicationDesignLayoutTabs;
