import { Uuid } from '@egym/types';
import { useRouter } from '@egym/ui';
import useClientApplicationSettings from '../useClientApplicationSettings';

const useClientApplicationSettingsByRouter = () => {
  const { urlParams } = useRouter<{ uuid: Uuid }>();

  return useClientApplicationSettings(urlParams.uuid);
};

export default useClientApplicationSettingsByRouter;
