import React, { ReactNode, useCallback, useMemo, useRef } from 'react';
import { RGBColor } from 'react-color';
import { useTranslation } from 'react-i18next';
import { useDebounce, useUpdateEffect } from 'react-use';
import { Box, SxProps } from '@mui/system';
import { Checkboard as ReactColorCheckboard } from 'react-color/lib/components/common';
import { CheckboardProps } from 'react-color/lib/components/common/Checkboard';
import { ErrorTooltip, Stack } from '@egym/ui';

type Props = {
  wrapperSx?: SxProps;
  rgb?: RGBColor;
  colorString?: string;
  onClick?: (e: any) => void;
  error?: string;
  popoverOpen?: boolean;
} & CheckboardProps;

const ColorDisplayWithCheckboard: React.FC<Props> = ({
  wrapperSx,
  colorString,
  onClick,
  error,
  popoverOpen,
  ...rest
}) => {
  const { t } = useTranslation();
  const [openTooltip, setOpenTooltip] = React.useState(false);
  const errorRef = useRef<string | null>(null);

  useDebounce(
    () => {
      errorRef.current = error || null;
    },
    200,
    [error],
  );

  const handleCloseTooltip = useCallback(() => {
    setOpenTooltip(false);
  }, []);

  const handleOpenTooltip = useCallback(() => {
    setOpenTooltip(true);
  }, []);

  useUpdateEffect(() => {
    if (error) {
      setOpenTooltip(Boolean(error));
    }
  }, [error]);

  const bgColor = useMemo(() => {
    if (colorString) return colorString;

    if (!rest.rgb) return 'transparent';

    const { r, g, b, a } = rest.rgb;

    return `rgba(${r}, ${g}, ${b}, ${a})`;
  }, [rest.rgb, colorString]);

  return (
    <Stack
      onClick={onClick}
      sx={{
        cursor: onClick ? 'pointer' : undefined,
        position: 'relative',
        width: '24px',
        height: '24px',
        boxShadow: 1,
        border: '1px solid',
        borderRadius: '1px',
        boxSizing: 'border-box',
        ...wrapperSx,
      }}
    >
      <ReactColorCheckboard {...rest} />
      <ErrorTooltip
        placement="right"
        title={(t(error || errorRef.current || '') as ReactNode) || ''}
        open={openTooltip && Boolean(error) && popoverOpen}
        onOpen={handleOpenTooltip}
        onClose={handleCloseTooltip}
      >
        <Box sx={{ position: 'absolute', width: '100%', height: '100%', backgroundColor: bgColor }} />
      </ErrorTooltip>
    </Stack>
  );
};

export default ColorDisplayWithCheckboard;
