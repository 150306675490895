import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const EditIcon: React.FC<SvgIconProps> = ({ sx, htmlColor = '#878787', ...props }) => {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props} sx={{ width: '24px', height: '24px', ...sx }}>
      <defs>
        <filter colorInterpolationFilters="auto" id="a">
          <feColorMatrix
            in="SourceGraphic"
            values="0 0 0 0 0.164706 0 0 0 0 0.164706 0 0 0 0 0.164706 0 0 0 1.000000 0"
          />
        </filter>
      </defs>
      <g transform="translate(-108 -24)" filter="url(#a)" fill="none" fillRule="evenodd">
        <path d="M108 24h24v24h-24z" />
        <path
          d="M113 43.14v-1.414l10.313-10.315 1.416 1.416-10.315 10.313H113zm12.435-13.849a1.004 1.004 0 0 1 1.415 0 1.004 1.004 0 0 1 0 1.414l-.707.707-1.415-1.415.707-.706zm.708 4.95 2.122-2.122a3.007 3.007 0 0 0 0-4.243h-.001a3.007 3.007 0 0 0-4.243 0l-2.122 2.121L111 40.898v4.242h18v-2h-11.758l8.901-8.899z"
          fill={htmlColor}
        />
      </g>
    </SvgIcon>
  );
};
