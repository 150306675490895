import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Stack } from '@egym/ui';
import { AppPreviewerPalette } from '../../../../AppPreviewerProps';
import { IconWrapper } from '../../../Icons';
import ArrowBackIcon from '../../../Icons/ArrowBackIcon';
import NotificationsIcon from '../../../Icons/NotificationsIcon';
import PreviewContainer from '../../../PreviewContainer';

type Props = {
  palette: AppPreviewerPalette;
};

const ProfileHeader: React.FC<Props> = ({ palette }) => {
  const { t } = useTranslation();
  return (
    <Stack
      sx={{
        position: 'sticky',
        top: 0,
        background: 'white',
        height: '75px',
        pb: '8px',
        boxSizing: 'border-box',
        boxShadow: '0 0.5px 0 rgba(0, 0, 0, 0.1)',
        zIndex: 1,
      }}
      justifyContent="flex-end"
    >
      <PreviewContainer>
        <Stack
          direction="row"
          alignItems="center"
          sx={{
            width: '100%',
            background: 'common.white',
            position: 'relative',
            justifyContent: 'space-between',
          }}
        >
          <IconWrapper bgColor={palette.primaryTransparent}>
            <ArrowBackIcon htmlColor={palette.primary} />
          </IconWrapper>
          <span
            style={{
              fontSize: '16px',
              fontWeight: 500,
              color: '#212121',
            }}
          >
            {t('appPreviewer.labels.myProfile') as ReactNode}
          </span>
          <Box sx={{ width: '32px', height: '32px', position: 'relative' }}>
            <Box
              sx={{
                position: 'absolute',
                right: '-2px',
                top: '-2px',
                width: '8px',
                height: '8px',
                background: '#e53935',
                borderRadius: '50%',
              }}
            />
            <IconWrapper bgColor={palette.primaryTransparent}>
              <NotificationsIcon htmlColor={palette.primary} />
            </IconWrapper>
          </Box>
        </Stack>
      </PreviewContainer>
    </Stack>
  );
};

export default ProfileHeader;
