import React, { ReactNode, useMemo } from 'react';
import ReactQuill from 'react-quill';
import { FormControl, FormHelperText, TextFieldProps } from '@mui/material';
import { SxProps } from '@mui/system';
import { CustomEventHandler } from '@egym/types';
import { debounce } from '@egym/utils';
import { Box } from '../../layout/Box';
import {
  viewModeWrapperStyles,
  editableAreaViewModeStyles,
  wrapperStyles,
  editableAreaStyles,
  getEditorWrapperStyles,
} from './RichTextEditor.styles';

export type Props = {
  name: string;
  value: string;
  onChange: CustomEventHandler;
  error?: boolean;
  helperText?: ReactNode;
  FormHelperTextProps?: TextFieldProps['FormHelperTextProps'];
  isViewMode?: boolean;
  sx?: SxProps;
  viewModeSx?: SxProps;
  editableAreaSx?: SxProps;
  toolbar?: (string | Record<any, any>)[][];
};

const debounceOnChange = debounce(500);

const RichTextEditor: React.FC<Props> = ({
  name,
  value,
  sx,
  viewModeSx,
  isViewMode,
  onChange,
  error,
  helperText,
  FormHelperTextProps,
  toolbar,
  editableAreaSx,
}) => {
  const editorWrapperSx = useMemo(
    () => ({
      ...getEditorWrapperStyles(Boolean(error)),
      ...sx,
      ...(isViewMode
        ? {
            ...viewModeWrapperStyles,
            ...viewModeSx,
          }
        : {}),
    }),
    [isViewMode, sx, viewModeSx, error],
  );

  const editableAreaWrapperStyles = useMemo(
    () => (isViewMode ? editableAreaViewModeStyles : { ...editableAreaStyles, ...editableAreaSx }),
    [isViewMode, editableAreaSx],
  );

  // https://github.com/quilljs/quill/issues/1940#issuecomment-791892715
  const onContentChange = debounceOnChange(newValue => {
    onChange(newValue);
  });

  const modules = useMemo(() => {
    return {
      toolbar: !isViewMode ? toolbar : false,
      history: {
        delay: 2000,
        maxStack: 500,
        userOnly: true,
      },
    };
  }, [isViewMode, toolbar]);

  return (
    <FormControl sx={wrapperStyles} error={error} key={name}>
      <Box sx={editorWrapperSx}>
        <ReactQuill
          theme="snow"
          defaultValue={value}
          onChange={onContentChange}
          readOnly={isViewMode}
          modules={modules}
        >
          <Box sx={editableAreaWrapperStyles} />
        </ReactQuill>
      </Box>
      {!isViewMode && helperText && (
        <FormHelperText error={error} {...FormHelperTextProps}>
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default RichTextEditor;
