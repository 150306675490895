import { inputBaseClasses } from '@mui/material';
import { styled } from '@mui/system';
import { TextField } from '../TextField';

export const StyledTextField = styled(TextField)`
  & .${inputBaseClasses.root} {
    border-radius: 0;
  }
  & .${inputBaseClasses.input} {
    padding: 4px 8px !important;
    width: 40px;
    height: 24px;
    font-size: 12px;
    box-sizing: border-box;
    -moz-appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
`;
