import React, { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { SxProps } from '@mui/system';
import { Alert, Box, Icons, Stack } from '@egym/ui';
import { AppLayoutFeatureEnum } from '@components';
import { AppDesignLayoutTabWidget } from '@types';

type Props = {
  wrapperSx?: SxProps;
  featuresCount?: number;
  widget?: AppDesignLayoutTabWidget;
};

const MoveFeaturesToTheLibraryAlert: FC<Props> = ({ wrapperSx, featuresCount = 2, widget }) => {
  const { t } = useTranslation();
  return (
    <Alert
      icon={<Icons.TipsAndUpdatesOutlined fontSize="medium" htmlColor="#19808E" />}
      severity="info"
      color="info"
      variant="standard"
      sx={{
        borderRadius: 2,
        maxWidth: '100%',
        border: '1px solid',
        borderColor: 'info.main',
        color: 'info.main',
        bgcolor: 'rgba(26, 127, 142, 0.03)',
        ...wrapperSx,
      }}
    >
      <Stack>
        {widget?.name === AppLayoutFeatureEnum.PreviewGroupWebWidget ? (
          <Box>
            {
              t('appDesign.labels.availableGroupFeatureInLibrary', {
                count: widget?.preferences?.innerWidgets?.length || 0,
              }) as ReactNode
            }
          </Box>
        ) : (
          <Box>{t('appDesign.labels.availableFeatureInLibrary', { count: featuresCount }) as ReactNode}</Box>
        )}
        <Box>
          {
            t('appDesign.labels.addFeatureBackHint', {
              count: widget?.preferences?.innerWidgets?.length || featuresCount,
              buttonName: t('common.buttons.addFeature'),
            }) as ReactNode
          }
        </Box>
      </Stack>
    </Alert>
  );
};

export default MoveFeaturesToTheLibraryAlert;
