import { useCallback } from 'react';
import DOMPurify from 'dompurify';

DOMPurify.addHook('beforeSanitizeElements', currentNode => {
  if (currentNode.textContent?.startsWith(']>')) {
    currentNode.parentNode?.removeChild(currentNode);
  }
});

const useSafeHtml = (sanitizeOptions: DOMPurify.Config = {}) =>
  useCallback(dirtyHtml => DOMPurify.sanitize(dirtyHtml, sanitizeOptions), [sanitizeOptions]);

export default useSafeHtml;
