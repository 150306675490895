import React from 'react';
import { Box, Container, Stack, Typography } from '@egym/ui';
import GlobalLanguageSelector from '../GlobalLanguageSelector';
import BackButton from './components/BackButton';
import { HeaderProps } from './HeaderProps';

const Header: React.FC<HeaderProps> = ({
  wrapperSx,
  backButton,
  application,
  headerTools,
  containerProps,
  topContainerProps,
  shouldShowApplication = true,
}) => {
  return (
    <Stack
      sx={{
        borderBottom: '1px solid',
        borderColor: 'grey.300',
        bgcolor: 'common.white',
        position: 'sticky',
        top: 0,
        zIndex: 10,
        ...wrapperSx,
      }}
    >
      <Container maxWidth="lg" {...containerProps}>
        <Stack>
          <Container maxWidth="lg" disableGutters {...topContainerProps}>
            <Stack
              sx={{ width: '100%', py: 5, height: headerTools ? '16px' : '18px' }}
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              {backButton ? (
                <BackButton backButton={backButton} />
              ) : (
                <Box component="img" sx={{ width: '81px', height: '30px' }} src="/images/egym-digital-logo.png" />
              )}
              <Stack direction="row" alignItems="center" spacing={2}>
                <GlobalLanguageSelector />
                {shouldShowApplication && application && (
                  <Typography variant="body2Medium">{application.name}</Typography>
                )}
              </Stack>
            </Stack>
          </Container>
          {headerTools}
        </Stack>
      </Container>
    </Stack>
  );
};

export default Header;
