import React, { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@egym/ui';
import { MoveFeaturesToTheLibraryAlert } from '../Warnings';

type Props = {
  tabName: string;
  featuresCount?: number;
};

const DeleteTabWithFeaturesDialogText: FC<Props> = ({ tabName, featuresCount = 2 }) => {
  const { t } = useTranslation();

  return (
    <Stack>
      <Typography variant="body1">
        {t('appDesign.labels.deleteTabDialog.description', { tabName }) as ReactNode}
      </Typography>
      <Typography variant="body1">
        {t('appDesign.labels.deleteTabDialog.featuresWillBeRemoved') as ReactNode}
      </Typography>
      <MoveFeaturesToTheLibraryAlert wrapperSx={{ mt: 6 }} featuresCount={featuresCount} />
    </Stack>
  );
};

export default DeleteTabWithFeaturesDialogText;
