import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const MainTabHeaderDefaultIcon9: React.FC<SvgIconProps> = ({ htmlColor = '#FFF', sx, ...props }) => {
  return (
    <SvgIcon viewBox="0 0 48 48" {...props} sx={{ width: '48px', height: '48px', ...sx }}>
      <g fill={htmlColor} fillRule="evenodd">
        <path
          d="M38.683 22.894c-.28-.56-.28-1.228.001-1.788l3.105-6.211A2.001 2.001 0 0 0 40 12H28v-2a2 2 0 0 0-2-2H14v4h10v2a2 2 0 0 0 2 2h10.764l-1.658 3.316a6.03 6.03 0 0 0-.001 5.368L36.764 28H28v-2a2 2 0 0 0-2-2H14v4h10v2a2 2 0 0 0 2 2h14a2 2 0 0 0 1.789-2.894l-3.106-6.212z"
          opacity=".6"
        />
        <path d="M16 40h-2V6a2 2 0 0 0-4 0v34H8a2 2 0 0 0 0 4h8a2 2 0 0 0 0-4" />
      </g>
    </SvgIcon>
  );
};
