import React, { FC, ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@egym/ui';
import { getWidgetNameTKey } from '@helpers';
import { AppDesignLayoutTabWidget } from '@types';
import { AppLayoutFeatureEnum } from '../AppPreviewer';
import { MoveFeaturesToTheLibraryAlert } from '../Warnings';

type Props = {
  widget: AppDesignLayoutTabWidget;
};

const RemovePermanentlyFeatureConfirmDialogText: FC<Props> = ({ widget }) => {
  const { t } = useTranslation();

  const widgetName = useMemo(() => {
    return t(getWidgetNameTKey(widget.name, widget.preferences));
  }, [t, widget.name, widget.preferences]);

  return (
    <Stack>
      <Typography variant="body1">
        {t('appDesign.labels.confirmPermanentlyRemoveFeature', { widgetName }) as ReactNode}
      </Typography>
      {widget.name === AppLayoutFeatureEnum.PreviewGroupWebWidget &&
        Boolean(widget?.preferences?.innerWidgets?.length) && (
          <MoveFeaturesToTheLibraryAlert wrapperSx={{ mt: 6 }} featuresCount={1} widget={widget} />
        )}
    </Stack>
  );
};

export default RemovePermanentlyFeatureConfirmDialogText;
