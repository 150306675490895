import React, { forwardRef, useMemo } from 'react';
import { Skeleton } from '@mui/material';
import { ConditionalRender, Stack } from '@egym/ui';
import { Orientation } from '@types';
import { AppLayoutFeatureComponentProps } from '../../../../AppPreviewerProps';
import PreviewContainer from '../../../PreviewContainer';
import PreviewFeatureTitle from '../../../PreviewFeatureTitle';
import GroupWidgetSkeletonItem from './components/GroupWidgetSkeletonItem';

const PreviewGroupWidgetSkeleton = forwardRef<HTMLDivElement, AppLayoutFeatureComponentProps>(
  ({ previewerId, tFunction, palette, wrapperSx, previewerState: { selectedLocale }, widgetProps }, ref) => {
    const title = useMemo(() => {
      return widgetProps?.preferences?.title?.[selectedLocale as string] || widgetProps?.preferences?.title?.en_US;
    }, [selectedLocale, widgetProps?.preferences?.title]);

    return (
      <PreviewContainer ref={ref} wrapperSx={wrapperSx}>
        <ConditionalRender condition={Boolean(title)}>
          <PreviewFeatureTitle tFunction={tFunction} title={title} palette={palette} />
          <Stack
            direction="row"
            spacing={2}
            alignItems="flex-end"
            sx={{ mb: '8px', height: previewerId ? '21px' : undefined }}
          >
            <Skeleton
              variant="rectangular"
              animation={false}
              sx={{ borderRadius: 2, backgroundColor: 'rgb(158, 158, 158)' }}
              width={61}
              height={10}
            />
            <Skeleton
              variant="rectangular"
              animation={false}
              sx={{ borderRadius: 2, backgroundColor: 'rgb(158, 158, 158)' }}
              width={38}
              height={10}
            />
          </Stack>
        </ConditionalRender>
        <ConditionalRender condition={widgetProps?.preferences?.orientation === Orientation.Horizontal}>
          <Stack direction="row" spacing={2}>
            <GroupWidgetSkeletonItem key={0} order={0} orientation={Orientation.Horizontal} />
            <GroupWidgetSkeletonItem key={1} order={1} orientation={Orientation.Horizontal} />
          </Stack>
          <Stack direction="column" spacing={2}>
            <GroupWidgetSkeletonItem key={0} order={0} orientation={Orientation.Vertical} />
            <GroupWidgetSkeletonItem key={1} order={1} orientation={Orientation.Vertical} />
            {/* hide 3rd item inside previewer */}
            {!previewerId && <GroupWidgetSkeletonItem key={2} order={1} orientation={Orientation.Vertical} />}
          </Stack>
        </ConditionalRender>
      </PreviewContainer>
    );
  },
);

export default PreviewGroupWidgetSkeleton;
