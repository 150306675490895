import React, { useMemo } from 'react';
import { CustomPickerProps } from 'react-color';
import { SxProps } from '@mui/system';
import { InjectedColorProps } from 'react-color/lib/components/common/ColorWrap';
import { SketchPickerProps } from 'react-color/lib/components/sketch/Sketch';
import { Stack, Divider } from '@egym/ui';
import Alpha from '../Alpha';
import ColorDisplayWithCheckboard from '../ColorDisplayWithCheckboard';
import EditableInput from '../EditableInput';
import Hue from '../Hue';
import PresetColors from '../PresetColors';
import Saturation from '../Saturation';
import Slider from '../Slider';

type Props = {
  wrapperSx?: SxProps;
  disableAlpha?: boolean;
  presetColors?: SketchPickerProps['presetColors'];
  error?: string;
  popoverOpen?: boolean;
} & CustomPickerProps<any> &
  InjectedColorProps;

const ColorPicker: React.FC<Props> = ({ wrapperSx, disableAlpha, presetColors, error, popoverOpen, ...props }) => {
  const hexNumbers = useMemo(() => props.hex?.replace('#', ''), [props.hex]);

  return (
    <Stack sx={{ boxSizing: 'border-box', pb: 2, ...wrapperSx }} alignItems="center">
      <Saturation {...props} wrapperSx={{ px: 3 }} />
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ width: '100%', px: 3, boxSizing: 'border-box', mt: 1.5 }}
      >
        <Stack sx={{ width: 'calc(100% - 31px)' }}>
          <Hue size={disableAlpha ? 'default' : 'small'} {...props} />
          {!disableAlpha && <Alpha wrapperSx={{ mt: 1 }} {...props} />}
        </Stack>
        <ColorDisplayWithCheckboard
          wrapperSx={{ height: disableAlpha ? '24px' : '28px' }}
          error={error}
          popoverOpen={popoverOpen}
          {...props}
        />
      </Stack>
      <Slider wrapperSx={{ px: 3, boxSizing: 'border-box' }} {...props} />
      <Stack
        direction="row"
        sx={{ mt: 2, width: '100%', position: 'relative', px: 3, boxSizing: 'border-box' }}
        justifyContent="flex-start"
      >
        <EditableInput label="hex" value={hexNumbers} wrapperSx={{ width: '72px', mr: 3 }} {...props} />
        <EditableInput
          label="r"
          value={props.rgb?.r}
          wrapperSx={{ width: '36px', mr: 1 }}
          dragLabel="true"
          dragMax="255"
          {...props}
        />
        <EditableInput
          label="g"
          value={props.rgb?.g}
          wrapperSx={{ width: '36px', mr: 1 }}
          dragLabel="true"
          dragMax="255"
          {...props}
        />
        <EditableInput
          label="b"
          value={props.rgb?.b}
          wrapperSx={{ width: '36px', mr: disableAlpha ? 0 : 1 }}
          dragLabel="true"
          dragMax="255"
          {...props}
        />
        {!disableAlpha && <EditableInput label="a" value={props.rgb?.a} wrapperSx={{ width: '36px' }} {...props} />}
      </Stack>
      {Boolean(presetColors?.length) && (
        <>
          <Divider flexItem sx={{ my: 3, borderColor: 'grey.200' }} orientation="horizontal" variant="fullWidth" />
          <PresetColors presetColors={presetColors} wrapperSx={{ px: 3 }} {...props} />
        </>
      )}
    </Stack>
  );
};

export default ColorPicker;
