import { useEffectOnce } from 'react-use';
import { getPreviewerScale } from '../../../../../../helpers';
import { SIGN_IN_LOGO_DIMENSIONS } from '../../constants';

const useSignInLogoInit = ({
  previewerId,
  logoWrapperRef,
  logoElementRef,
  initialLeft,
  initialTop,
  initialWidth,
  initialHeight,
  signInBackgroundWrapperRef,
  constraintsRef,
  setReady,
}) => {
  useEffectOnce(() => {
    setTimeout(() => {
      if (
        logoWrapperRef.current &&
        logoElementRef.current &&
        typeof initialLeft === 'number' &&
        typeof initialTop === 'number' &&
        typeof initialWidth === 'number'
      ) {
        const scale = getPreviewerScale(previewerId);

        const previewerRect = signInBackgroundWrapperRef.current.getBoundingClientRect();
        const previewerWidth = previewerRect.width;
        const previewerHeight = previewerRect.height;
        const constraintsRect = constraintsRef.current.getBoundingClientRect();

        const widthRatio = SIGN_IN_LOGO_DIMENSIONS[0] / previewerWidth;
        const heightRatio = SIGN_IN_LOGO_DIMENSIONS[1] / previewerHeight;

        const logoWidthResult = initialWidth / widthRatio;

        const logoLeftResult =
          initialLeft / widthRatio - logoWidthResult / 2 - (constraintsRect.left - previewerRect.left);

        // eslint-disable-next-line no-param-reassign
        logoWrapperRef.current.style.left = `${logoLeftResult / scale}px`;
        // eslint-disable-next-line no-param-reassign
        logoElementRef.current.style.width = `${logoWidthResult / scale}px`;

        const svg = logoElementRef.current?.getElementsByTagName('svg')?.[0];
        const bbox = svg?.getBBox();

        let logoHeightResult;

        if (initialHeight) {
          logoHeightResult = initialHeight / heightRatio;
          // eslint-disable-next-line no-param-reassign
          logoElementRef.current.style.height = `${logoHeightResult / scale}px`;
        } else if (svg && bbox.width && bbox.height) {
          const dimensionRatio = bbox.width / bbox.height;
          logoHeightResult = logoWidthResult / dimensionRatio;
          // eslint-disable-next-line no-param-reassign
          logoElementRef.current.style.height = `${logoHeightResult / scale}px`;
        }

        const logoTopResult =
          initialTop / heightRatio -
          ((logoElementRef.current.offsetHeight as unknown as number) * scale) / 2 -
          (constraintsRect.top - previewerRect.top);

        // eslint-disable-next-line no-param-reassign
        logoWrapperRef.current.style.top = `${logoTopResult / scale}px`;

        setReady(true);
      }
    }, 200);
  });
};

export default useSignInLogoInit;
