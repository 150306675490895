import { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormikConfig, FormikProps } from 'formik';
import { map, mergeAll } from 'ramda';
import { customYup, toLocaleApiFormat, toLocaleFormat } from '@egym/utils';
import { appLayoutFeatureMap, fullTabToCompactFeatureMap } from '@components';
import { FeatureSize } from '@constants';
import { getRemainingFullTabToCompactWidgets, getWidgetNameTKey } from '@helpers';
import { AppDesignLayoutTabType, MoveWidgetsFormValues, WidgetWithTab } from '@types';
import useWidgetPreferencesForm from '../../../../hooks/useWidgetPreferencesForm';
import { layoutWidgetToTabWidget } from '../../../../hooks/useWidgetPreferencesForm/helpers';
import {
  FullTabWidgetPreferencesFormContainerProps,
  FullTabWidgetPreferencesFormikProps,
  FullTabWidgetPreferencesFormValues,
  PreviewComponentConfings,
  SizeOptions,
  UseFullTabWidgetPreferencesFormResult,
} from '../../FullTabWidgetPreferencesFormProps';

const useFullTabWidgetPreferencesForm = (
  props: FullTabWidgetPreferencesFormContainerProps,
  ref,
): UseFullTabWidgetPreferencesFormResult => {
  const { currentWidget, sizeOption, updateWidget, replaceWidgets, appLayoutTabs } = props;
  const {
    innerRef,
    appLocales,
    availableLanguages,
    openLanguagesMenuTab,
    onSubmit: baseSubmit,
    currentLocale,
  } = useWidgetPreferencesForm(props, ref);
  const { t } = useTranslation();
  const moveRemainingFeaturesFormRef = useRef<FormikProps<MoveWidgetsFormValues> | null>(null);

  const onSubmit = useCallback(
    async (values, helpers) => {
      if (moveRemainingFeaturesFormRef.current) {
        await moveRemainingFeaturesFormRef.current.setTouched({
          currentWidgets: moveRemainingFeaturesFormRef.current.values.currentWidgets.map(() => ({
            newTabId: true,
          })),
        });
        await moveRemainingFeaturesFormRef.current?.submitForm();
        if (moveRemainingFeaturesFormRef.current.isValid) {
          const fullTabToCompactWidgets = getRemainingFullTabToCompactWidgets(
            appLayoutTabs,
            moveRemainingFeaturesFormRef.current.values.currentWidgets,
          )
            .map(it => ({
              props: it,
              newTabId: it.tabId,
              prevTabId: it.tabId,
              shouldBeRemoved: false,
            }))
            .filter(it => it.props.name !== fullTabToCompactFeatureMap[currentWidget.name]);

          replaceWidgets([
            ...moveRemainingFeaturesFormRef.current.values.currentWidgets.map(it => ({
              props: it.props,
              newTabId: it.newTabId,
              prevTabId: it.props.tabId,
              shouldBeRemoved: it.shouldBeRemoved,
            })),
            ...fullTabToCompactWidgets,
          ]);
          updateWidget({
            id: currentWidget.id,
            name: sizeOption[FeatureSize.FullTab],
            preferences: null,
          });
          await baseSubmit(values, helpers);
        }
      } else {
        await baseSubmit(values, helpers);
      }
    },
    [currentWidget.id, currentWidget.name, appLayoutTabs, replaceWidgets, updateWidget, sizeOption, baseSubmit],
  );

  const formConfig = useMemo<FormikConfig<FullTabWidgetPreferencesFormValues>>(
    () => ({
      enableReinitialize: true,
      initialValues: {
        id: currentWidget.id,
        name: currentWidget.name,
        title: mergeAll(
          map(
            (locale: string) => ({
              [locale]:
                currentWidget.preferences?.title?.[locale] ||
                t(getWidgetNameTKey(currentWidget.name), { lng: toLocaleFormat(locale) }),
            }),
            map(locale => toLocaleApiFormat(locale), appLocales || []),
          ),
        ),
      },
      validationSchema: customYup.object().shape({
        title: customYup
          .object()
          .nullable()
          .when('name', {
            is: name => name === sizeOption[FeatureSize.Compact],
            then: () =>
              customYup
                .object()
                .nullable()
                .required()
                .shape(
                  mergeAll(
                    map(
                      (locale: string) => ({
                        [locale]: customYup.string().nullable().required(t('common.validation.required')),
                      }),
                      map(locale => toLocaleApiFormat(locale), appLocales || []),
                    ),
                  ),
                ),
          }),
      }),
      innerRef,
      onSubmit,
      validateOnChange: false,
      validateOnBlur: false,
      validateOnMount: false,
    }),
    [currentWidget, appLocales, onSubmit, t, innerRef, sizeOption],
  );

  const fullTabPreviewAppLayoutTab = (component, name) => ({
    id: 1,
    name: {},
    type: AppDesignLayoutTabType.Custom,
    icon: '',
    order: 1,
    features: [
      {
        props: {
          id: 1,
          order: 0,
          name,
        },
        component,
      },
    ],
    selected: true,
  });

  const previewComponentConfigs = useMemo(
    () =>
      map<SizeOptions, PreviewComponentConfings>(
        name => ({
          component: appLayoutFeatureMap[name],
          previewWidgetProps: {
            appPreviewerTab: fullTabPreviewAppLayoutTab(appLayoutFeatureMap[name], name),
          },
        }),
        sizeOption,
      ),
    [sizeOption],
  );

  const [isFullTab, setFullTab] = useState<boolean>(currentWidget.name === sizeOption[FeatureSize.FullTab]);
  const onFormChanged = useCallback(
    ({ values: { id, name, title } }: FullTabWidgetPreferencesFormikProps) => {
      setFullTab(name === sizeOption[FeatureSize.FullTab]);
      updateWidget({
        id,
        name,
        preferences: name === sizeOption[FeatureSize.Compact] ? { title } : null,
      });
    },
    [updateWidget, sizeOption, setFullTab],
  );

  const widgetWithTab = useMemo<WidgetWithTab>(
    () => layoutWidgetToTabWidget(currentWidget, appLayoutTabs),
    [currentWidget, appLayoutTabs],
  );

  const currentAppLoutTab = useMemo(
    () => appLayoutTabs.find(tab => tab.id === currentWidget.tabId),
    [appLayoutTabs, currentWidget.tabId],
  );

  return {
    formConfig,
    previewComponentConfigs,
    availableLanguages,
    currentLocale,
    openLanguagesMenuTab,
    onFormChanged,
    isFullTab,
    widgetWithTab,
    moveRemainingFeaturesFormRef,
    currentAppLoutTab,
  };
};

export default useFullTabWidgetPreferencesForm;
