import React from 'react';
import { FormControlLabel, FormControlLabelProps, Switch, SwitchProps } from '@mui/material';
import { SxProps } from '@mui/system';

type Props = Omit<SwitchProps, 'sx'> &
  Omit<FormControlLabelProps, 'control' | 'sx'> & { switchSx?: SxProps; formControlSx?: SxProps };

const SwitchWithLabel: React.FC<Props> = ({ switchSx, formControlSx, ...props }) => {
  return <FormControlLabel control={<Switch {...props} sx={switchSx} />} sx={formControlSx} {...props} />;
};

export default SwitchWithLabel;
