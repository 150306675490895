import React from 'react';
import { ProgressBar } from '@components';
import { AppDesignProgressBarContainerProps } from './AppDesignProgressBarProps';
import useAppDesignProgressBar from './hooks/useAppDesignProgressBar/useAppDesignProgressBar';

const AppDesignProgressBarContainer: React.FC<AppDesignProgressBarContainerProps> = props => {
  const appDesignProgressBarProps = useAppDesignProgressBar();

  return <ProgressBar {...props} {...appDesignProgressBarProps} />;
};

export default AppDesignProgressBarContainer;
