import { useQuery } from '@tanstack/react-query';
import { Uuid } from '@egym/types';
import { APPLICATION_DETAILS_WITH_PROGRESS_BASE_URL, getApplicationDetails, useEventSourceQuery } from '@api';
import { ApplicationAssetArchiveProgress } from '@types';

export const applicationDetailsQueryKeys = {
  all: ['adminApplicationDetails'] as const,
  details: () => [...applicationDetailsQueryKeys.all, 'detail'] as const,
  detail: (appUuid: Uuid) => [...applicationDetailsQueryKeys.details(), String(appUuid)] as const,
  detailWithProgress: (appUuid: Uuid) =>
    [...applicationDetailsQueryKeys.details(), 'progress', String(appUuid)] as const,
};

const useAdminApplicationDetails = (applicationUuid: Uuid) => {
  const adminApplicationDetailsQuery = useQuery(
    applicationDetailsQueryKeys.detail(applicationUuid),
    async () => getApplicationDetails({ urlParams: { applicationUuid } }),
    {
      enabled: false,
      refetchOnMount: false,
      keepPreviousData: true,
    },
  );
  const adminApplicationDetailsWithProgressQuery = useEventSourceQuery<ApplicationAssetArchiveProgress>(
    applicationDetailsQueryKeys.detailWithProgress(applicationUuid),
    APPLICATION_DETAILS_WITH_PROGRESS_BASE_URL,
    { urlParams: { applicationUuid } },
  );

  return { adminApplicationDetailsQuery, adminApplicationDetailsWithProgressQuery };
};

export default useAdminApplicationDetails;
