import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const CholesterolLvlIcon: React.FC<SvgIconProps> = ({ sx, htmlColor = '#4C4A4A', ...rest }) => {
  return (
    <SvgIcon sx={{ width: '20px', height: '20px', color: htmlColor, ...sx }} {...rest}>
      <path
        d="M16.5 2A6.5 6.5 0 0 1 23 8.5c0 3.214-2.03 6.21-5.364 8.959a30.583 30.583 0 0 1-3.554 2.513c-.424.257-.818.484-1.173.678l-.122.066-.34.178a1 1 0 0 1-.894 0l-.232-.12-.504-.276a30.36 30.36 0 0 1-.9-.526 30.583 30.583 0 0 1-3.553-2.513C3.03 14.709 1 11.714 1 8.5A6.5 6.5 0 0 1 7.5 2c1.71 0 3.31.668 4.5 1.814l.214-.197A6.475 6.475 0 0 1 16.5 2zm0 2a4.49 4.49 0 0 0-3.682 1.919 1 1 0 0 1-1.636 0A4.49 4.49 0 0 0 7.5 4 4.5 4.5 0 0 0 3 8.5c0 2.474 1.72 5.01 4.636 7.416a28.612 28.612 0 0 0 3.321 2.347c.377.229.727.431 1.043.605l.243-.136c.25-.14.517-.297.8-.47a28.612 28.612 0 0 0 3.32-2.346C19.282 13.51 21 10.974 21 8.5A4.5 4.5 0 0 0 16.5 4zM9.106 8.553a1 1 0 0 1 1.751-.067l2.187 3.644 1.124-1.685A1 1 0 0 1 15 10h2a1 1 0 0 1 0 2h-1.465l-1.703 2.555a1 1 0 0 1-1.69-.04l-2.062-3.438-.186.37A1 1 0 0 1 9 12H7a1 1 0 0 1 0-2h1.382z"
        fill={htmlColor}
        fillRule="nonzero"
      />
    </SvgIcon>
  );
};

export default CholesterolLvlIcon;
