import { useCallback, useRef } from 'react';
import { getPreviewerScale } from '../../../../../../helpers';

const doDragListeners: any[] = [];

const useSignInLogoDrag = ({
  previewerId,
  handleElementRef,
  logoWrapperRef,
  logoElementRef,
  constraintsRef,
  onChange,
}) => {
  const dragDataRef = useRef<{ startX: number; startY: number }>({ startX: 0, startY: 0 });

  const doDrag = useCallback(
    event => {
      const constraintsRect = constraintsRef.current.getBoundingClientRect();
      const logoRect = logoElementRef.current.getBoundingClientRect();

      const scale = getPreviewerScale(previewerId);

      let newLeft = event.clientX - constraintsRect.left;
      let newTop = event.clientY - constraintsRect.top;

      if (event.clientX <= constraintsRect.left) {
        newLeft = 0;
      }

      if (event.clientY <= constraintsRect.top) {
        newTop = 0;
      }

      if (event.clientX + logoRect.width >= constraintsRect.right) {
        newLeft = constraintsRect.width - logoRect.width;
      }

      if (event.clientY + logoRect.height >= constraintsRect.bottom) {
        newTop = constraintsRect.height - logoRect.height;
      }

      // eslint-disable-next-line no-param-reassign
      logoWrapperRef.current.style.left = `${newLeft / scale}px`;
      // eslint-disable-next-line no-param-reassign
      logoWrapperRef.current.style.top = `${newTop / scale}px`;
    },
    [constraintsRef, logoElementRef, logoWrapperRef, previewerId],
  );

  const onDragEnd = useCallback(() => {
    // document.documentElement.onpointermove = null;
    // document.documentElement.onpointerup = null;
    document.documentElement.removeEventListener('pointermove', doDrag, false);
    document.documentElement.removeEventListener('pointerup', onDragEnd, false);
    onChange();
  }, [doDrag, onChange]);

  const initDrag = useCallback(
    event => {
      dragDataRef.current.startX = event.clientX;
      dragDataRef.current.startY = event.clientY;

      document.documentElement.addEventListener('pointermove', doDrag, false);
      document.documentElement.addEventListener('pointerup', onDragEnd, false);

      doDragListeners.push(doDrag);
    },
    [doDrag, onDragEnd],
  );

  const init = () => {
    document.documentElement.removeEventListener('pointermove', doDrag, false);
    document.documentElement.removeEventListener('pointerup', onDragEnd, false);
    handleElementRef.current.removeEventListener('mousedown', initDrag, false);
    doDragListeners.forEach(listener => {
      document.documentElement.removeEventListener('pointermove', listener, false);
    });

    if (handleElementRef.current) handleElementRef.current.addEventListener('mousedown', initDrag, false);
  };

  return {
    init,
  };
};

export default useSignInLogoDrag;
