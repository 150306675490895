// https://next.material-ui.com/components/time-picker/
import React, { ReactNode } from 'react';
import { SxProps } from '@mui/system';
import { TimePicker as MuiTimePicker, TimePickerProps as MuiTimePickerProps } from '@mui/x-date-pickers';
import { TextField, TextFieldProps } from '../TextField';

export type TimePickerProps = Omit<MuiTimePickerProps<any, any>, 'renderInput'> & {
  helperText?: ReactNode;
  sx?: SxProps;
  FormHelperTextProps?: TextFieldProps['FormHelperTextProps'];
  error?: boolean;
  onBlur?: () => void;
  name?: string;
};

export const TimePicker: React.FC<TimePickerProps> = ({
  sx,
  helperText,
  FormHelperTextProps,
  onChange,
  onBlur,
  error,
  name,
  ...rest
}) => {
  return (
    <MuiTimePicker
      onChange={onChange}
      renderInput={params => (
        <TextField
          name={name}
          {...params}
          error={error}
          sx={sx}
          helperText={helperText}
          FormHelperTextProps={FormHelperTextProps}
          onBlur={onBlur}
        />
      )}
      className="timePickerWrapper"
      {...rest}
    />
  );
};
