import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const AppsEditIcon: React.FC<SvgIconProps> = ({ htmlColor = '#000', sx, ...props }) => {
  return (
    <SvgIcon viewBox="0 0 48 48" {...props} sx={{ width: '48px', height: '48px', ...sx }}>
      <defs>
        <filter colorInterpolationFilters="auto" id="a">
          <feColorMatrix
            in="SourceGraphic"
            values="0 0 0 0 0.796078 0 0 0 0 0.796078 0 0 0 0 0.796078 0 0 0 1.000000 0"
          />
        </filter>
      </defs>
      <g transform="translate(-268 -784)" filter="url(#a)" fill="none" fillRule="evenodd" opacity=".8">
        <path d="M268 784h48v48h-48z" />
        <g fill={htmlColor} fillRule="nonzero">
          <path d="M288 792h8v8h-8zM276 816h8v8h-8zM276 804h8v8h-8zM276 792h8v8h-8zM300 792h8v8h-8zM290 819.72V824h4.2l11.96-11.94-4.24-4.24zM296 808.06V804h-8v8h4.06zM309.7 807.12l-2.82-2.82a.99.99 0 0 0-1.42 0l-2.12 2.12 4.24 4.24 2.12-2.12c.4-.4.4-1.02 0-1.42z" />
        </g>
      </g>
    </SvgIcon>
  );
};
