import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const MainTabHeaderDefaultIcon16: React.FC<SvgIconProps> = ({ htmlColor = '#FFF', sx, ...props }) => {
  return (
    <SvgIcon viewBox="0 0 48 48" {...props} sx={{ width: '48px', height: '48px', ...sx }}>
      <g fill={htmlColor} fillRule="evenodd">
        <path d="M31.66 5.003c-2.974.059-5.553.842-7.617 2.325l-.048.037-.026-.02C21.812 5.785 19.106 5 15.98 5 8.544 5 3 10.332 3 19.737c0 9.616 8.063 17.662 18.71 22.615l.645.294 1.639.74 1.64-.735C36.576 37.754 45 29.56 45 19.737 45 10.332 39.456 5 32.019 5l-.359.003zM32.02 9C36.189 9 41 11.526 41 19.737c0 6.649-5.241 12.132-10.814 15.847l-.632.413c-.316.202-.633.399-.95.59l-.632.374-.63.359-.624.342-.31.165-.617.318-.607.302-.598.285c-.197.092-.393.181-.586.268l-.507-.231-.599-.284a42.572 42.572 0 0 1-.916-.457l-.62-.326a43.504 43.504 0 0 1-.314-.169l-.629-.35C13.995 33.544 7 27.418 7 19.737 7 11.527 11.811 9 15.981 9c3.733 0 6.36 1.386 7.88 4.157l.139.264C25.497 10.474 28.17 9 32.019 9z" />
        <path
          d="M30.42 16.274a2 2 0 0 1 3.255 2.319l-.095.133-8.539 11a2 2 0 0 1-2.88.294l-.12-.111-4.96-5a2 2 0 0 1 2.712-2.935l.127.117 3.357 3.384 7.143-9.201z"
          opacity=".6"
        />
      </g>
    </SvgIcon>
  );
};
