import { Components } from '@mui/material/styles';

const MuiSvgIcon: Components['MuiSvgIcon'] = {
  styleOverrides: {
    fontSizeSmall: {
      fontSize: '14px',
    },
  },
};

export default MuiSvgIcon;
