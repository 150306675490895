import React from 'react';
import { TabProps } from '@mui/material/Tab';
import { AppPreviewerPalette } from '../../AppPreviewerProps';
import { StyledTab } from './PreviewTab.styles';

type Props = TabProps & { palette: Partial<AppPreviewerPalette> };

const PreviewTab: React.FC<Props> = ({ palette, ...rest }) => {
  return <StyledTab primaryColor={palette.primary} disableRipple {...rest} />;
};

export default PreviewTab;
