import { PredefinedTabIcon } from '@types';

export const TabImmunePredefinedIcon: PredefinedTabIcon = {
  id: 4,
  path: '/images/previewerDefaultImages/tabs/AppTabDefaultIcon4.svg',
};

export const TabRewardsPredefinedIcon: PredefinedTabIcon = {
  id: 6,
  path: '/images/previewerDefaultImages/tabs/AppTabDefaultIcon6.svg',
};

export const TabWorkoutsPredefinedIcon: PredefinedTabIcon = {
  id: 8,
  path: '/images/previewerDefaultImages/tabs/AppTabDefaultIcon8.svg',
};

export const TabRunningPredefinedIcon: PredefinedTabIcon = {
  id: 9,
  path: '/images/previewerDefaultImages/tabs/AppTabDefaultIcon9.svg',
};

export const TabClassesPredefinedIcon: PredefinedTabIcon = {
  id: 10,
  path: '/images/previewerDefaultImages/tabs/AppTabDefaultIcon10.svg',
};

export const TabClubPredefinedIcon: PredefinedTabIcon = {
  id: 11,
  path: '/images/previewerDefaultImages/tabs/AppTabDefaultIcon11.svg',
};

export const TabPrivacyPredefinedIcon: PredefinedTabIcon = {
  id: 14,
  path: '/images/previewerDefaultImages/tabs/AppTabDefaultIcon14.svg',
};

export const TabChallengePredefinedIcon: PredefinedTabIcon = {
  id: 22,
  path: '/images/previewerDefaultImages/tabs/AppTabDefaultIcon22.svg',
};

export const TabVirtualWorkoutsPredefinedIcon: PredefinedTabIcon = {
  id: 23,
  path: '/images/previewerDefaultImages/tabs/AppTabDefaultIcon23.svg',
};

export const TabActivityOutlinedPredefinedIcon: PredefinedTabIcon = {
  id: 25,
  path: '/images/previewerDefaultImages/tabs/AppTabDefaultIcon25.svg',
};

export const TabTrackWorkoutPredefinedIcon: PredefinedTabIcon = {
  id: 27,
  path: '/images/previewerDefaultImages/tabs/AppTabDefaultIcon27.svg',
};

export const DefaultCustomTabIcon: PredefinedTabIcon = {
  id: 26,
  path: '/images/previewerDefaultImages/tabs/AppTabDefaultIcon26.svg',
};

export const predefinedAppTabIcons: PredefinedTabIcon[] = [
  {
    id: 1,
    path: '/images/previewerDefaultImages/tabs/AppTabDefaultIcon1.svg',
  },
  {
    id: 2,
    path: '/images/previewerDefaultImages/tabs/AppTabDefaultIcon2.svg',
  },
  {
    id: 3,
    path: '/images/previewerDefaultImages/tabs/AppTabDefaultIcon3.svg',
  },
  TabImmunePredefinedIcon,
  {
    id: 5,
    path: '/images/previewerDefaultImages/tabs/AppTabDefaultIcon5.svg',
  },
  TabRewardsPredefinedIcon,
  {
    id: 7,
    path: '/images/previewerDefaultImages/tabs/AppTabDefaultIcon7.svg',
  },
  TabWorkoutsPredefinedIcon,
  TabRunningPredefinedIcon,
  TabClassesPredefinedIcon,
  TabClubPredefinedIcon,
  {
    id: 12,
    path: '/images/previewerDefaultImages/tabs/AppTabDefaultIcon12.svg',
  },
  {
    id: 13,
    path: '/images/previewerDefaultImages/tabs/AppTabDefaultIcon13.svg',
  },
  TabPrivacyPredefinedIcon,
  {
    id: 15,
    path: '/images/previewerDefaultImages/tabs/AppTabDefaultIcon15.svg',
  },
  {
    id: 16,
    path: '/images/previewerDefaultImages/tabs/AppTabDefaultIcon16.svg',
  },
  {
    id: 17,
    path: '/images/previewerDefaultImages/tabs/AppTabDefaultIcon17.svg',
  },
  {
    id: 18,
    path: '/images/previewerDefaultImages/tabs/AppTabDefaultIcon18.svg',
  },
  {
    id: 19,
    path: '/images/previewerDefaultImages/tabs/AppTabDefaultIcon19.svg',
  },
  {
    id: 20,
    path: '/images/previewerDefaultImages/tabs/AppTabDefaultIcon20.svg',
  },
  {
    id: 21,
    path: '/images/previewerDefaultImages/tabs/AppTabDefaultIcon21.svg',
  },
  TabVirtualWorkoutsPredefinedIcon,
  TabChallengePredefinedIcon,
  {
    id: 24,
    path: '/images/previewerDefaultImages/tabs/AppTabDefaultIcon24.svg',
  },
  TabActivityOutlinedPredefinedIcon,
  DefaultCustomTabIcon,
  TabTrackWorkoutPredefinedIcon,
];
