import React, { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert } from '@egym/ui';
import { AppLayoutFeatureEnum } from '@components';
import { getWidgetNameTKey } from '@helpers';
import { MoveToIcon } from '@svg';
import { AppDesignLayoutTabWidget, WidgetWithTab } from '@types';

type Props = {
  widget: WidgetWithTab;
  group: AppDesignLayoutTabWidget;
  currentLocale?: string;
};

const FeatureUsedAlert: FC<Props> = ({ widget, group, currentLocale }) => {
  const { t } = useTranslation();

  return widget.addedWidget?.name === AppLayoutFeatureEnum.PreviewWebWidget && group ? (
    <Alert
      icon={<MoveToIcon htmlColor="#19808E" />}
      severity="info"
      color="info"
      variant="standard"
      sx={{
        borderRadius: 2,
        maxWidth: '100%',
        border: '1px solid',
        borderColor: 'info.main',
        color: 'info.main',
      }}
    >
      {
        t('appDesign.labels.widgets.moveWebWidgetToGroupHint', {
          widgetName: getWidgetNameTKey(widget.name, widget.addedWidget?.preferences, currentLocale),
          fromTabName: widget.addedOnTab?.nameTranslated || widget.addedOnTab?.name.en_US,
          groupName: getWidgetNameTKey(group.name, group.preferences, currentLocale),
        }) as ReactNode
      }
    </Alert>
  ) : (
    <Alert
      icon={<MoveToIcon htmlColor="#19808E" />}
      severity="info"
      color="info"
      variant="standard"
      sx={{
        borderRadius: 2,
        maxWidth: '100%',
        border: '1px solid',
        borderColor: 'info.main',
        color: 'info.main',
      }}
    >
      {
        t('appDesign.labels.widgets.switchFeatureTabHint', {
          fromTabName: widget.addedOnTab?.nameTranslated || widget.addedOnTab?.name.en_US,
          widgetName: t(getWidgetNameTKey(widget.name, widget.addedWidget?.preferences, currentLocale)),
        }) as ReactNode
      }
    </Alert>
  );
};

export default FeatureUsedAlert;
