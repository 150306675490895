import { useCallback, useImperativeHandle, useRef, useState } from 'react';
import { calculateOutputParameters } from '../../helpers';
import { SignInLogoContainerProps, UseSignInLogoProps } from '../../SignInLogoProps';
import useSignInLogoDrag from '../useSignInLogoDrag';
import useSignInLogoInit from '../useSignInLogoInit';
import useSignInLogoResize from '../useSignInLogoResize';

const useSignInLogo = (
  {
    signInBackgroundWrapperRef,
    initialLeft,
    initialTop,
    initialWidth,
    initialHeight,
    onAdjustSignInLogo,
    setCanModify,
    constraintsRef,
    id,
    isViewMode,
  }: SignInLogoContainerProps,
  ref,
): UseSignInLogoProps => {
  const [ready, setReady] = useState(false);
  const logoElementRef = useRef<HTMLImageElement>(null);
  const resizerElementRef = useRef<HTMLButtonElement>(null);
  const handleElementRef = useRef<HTMLButtonElement>(null);
  const logoWrapperRef = useRef<HTMLDivElement>(null);

  const onChange = useCallback(() => {
    if (onAdjustSignInLogo && logoElementRef.current) {
      const logoRect = logoElementRef.current.getBoundingClientRect();
      const previewerRect = signInBackgroundWrapperRef.current.getBoundingClientRect();

      const result = calculateOutputParameters(logoRect, previewerRect);

      onAdjustSignInLogo(result);
    }
  }, [onAdjustSignInLogo, signInBackgroundWrapperRef]);

  const { init: initResize } = useSignInLogoResize({
    previewerId: id,
    logoElementRef,
    logoWrapperRef,
    resizerElementRef,
    signInBackgroundWrapperRef,
    constraintsRef,
    onChange,
  });
  const { init: initDrag } = useSignInLogoDrag({
    previewerId: id,
    handleElementRef,
    logoWrapperRef,
    logoElementRef,
    constraintsRef,
    onChange,
  });

  useSignInLogoInit({
    previewerId: id,
    logoElementRef,
    logoWrapperRef,
    signInBackgroundWrapperRef,
    constraintsRef,
    initialHeight,
    initialLeft,
    initialTop,
    initialWidth,
    setReady,
  });

  const init = useCallback(() => {
    if (!isViewMode) {
      setCanModify(true);

      initResize();
      initDrag();
    }
  }, [initDrag, initResize, isViewMode, setCanModify]);

  useImperativeHandle(ref, () => ({
    init,
  }));

  return {
    ready,
    init,
    logoElementRef,
    handleElementRef,
    resizerElementRef,
    logoWrapperRef,
  };
};

export default useSignInLogo;
