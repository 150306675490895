import React from 'react';
import { TableColumn, TableColumnFilterType, TableState } from '../../TableProps';
import TableFilterDatesRange from './TableFilterDatesRange';
import TableFilterDropdown from './TableFilterDropdown';
import TableInputSearch from './TableInputSearch';

type Props = {
  column: TableColumn;
  updateTableStateFilters: (fieldNames: string[], value: any) => void;
  tableState: TableState;
};

const TableFilter: React.FC<Props> = ({ column, updateTableStateFilters, tableState }) => {
  if (!column.filter) return null;

  if (column.filter.type === TableColumnFilterType.Search) {
    return (
      <TableInputSearch column={column} updateTableStateFilters={updateTableStateFilters} tableState={tableState} />
    );
  }

  if (column.filter.type === TableColumnFilterType.Select) {
    return (
      <TableFilterDropdown column={column} updateTableStateFilters={updateTableStateFilters} tableState={tableState} />
    );
  }

  if (column.filter.type === TableColumnFilterType.DateRange) {
    return (
      <TableFilterDatesRange
        column={column}
        updateTableStateFilters={updateTableStateFilters}
        tableState={tableState}
      />
    );
  }

  return null;
};

export default TableFilter;
