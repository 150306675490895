import { useCallback, useMemo } from 'react';
import { omit } from 'ramda';
import { useRecoilState } from 'recoil';
import { snackbarGlobalState } from './state';
import { SnackbarConfigOptions } from './types';

export * from './types';

const useSnackbar = () => {
  const [message, setMessage] = useRecoilState(snackbarGlobalState.atoms.message);

  const isOpen = useMemo(() => Boolean(message?.open && message.text), [message]);
  const messageOptions = useMemo(
    () => omit(['textParams', 'autoHideDuration', 'anchorOrigin'], message?.options),
    [message?.options],
  );

  const close = useCallback(() => {
    setMessage(prev => ({
      ...prev,
      open: false,
    }));
  }, [setMessage]);

  const openSnackbar = useCallback(
    (text: string, options: SnackbarConfigOptions = {}) => {
      setMessage({
        text,
        options: {
          ...options,
          autoHideDuration: options.autoHideDuration || 10000,
          elevation: options.elevation || 7,
          variant: options.variant || 'filled',
          severity: options.severity || 'success',
          anchorOrigin: options.anchorOrigin || { vertical: 'top', horizontal: 'center' },
        },
        open: true,
      });
    },
    [setMessage],
  );

  return {
    isOpen,
    openSnackbar,
    close,
    message,
    messageOptions,
  };
};

export default useSnackbar;
