import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const DaDkFlagIcon: React.FC<SvgIconProps> = ({ sx, ...props }) => {
  return (
    <SvgIcon viewBox="0 0 640 480" {...props} sx={{ width: '18px', ...sx }}>
      <path fill="#c8102e" d="M0 0h640.1v480H0z" />
      <path fill="#fff" d="M205.7 0h68.6v480h-68.6z" />
      <path fill="#fff" d="M0 205.7h640.1v68.6H0z" />
    </SvgIcon>
  );
};

export default DaDkFlagIcon;
