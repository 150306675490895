import { FC } from 'react';
import { Skeleton, SkeletonProps, Stack } from '@mui/material';

const SkeletonBase: FC<SkeletonProps & { gradientDegree: number }> = ({ sx, gradientDegree, ...rest }) => {
  return (
    <Skeleton
      variant="rectangular"
      animation={false}
      sx={{ borderRadius: '4px', background: `linear-gradient(${gradientDegree}deg, #e0e0e0, #eee 97%)`, ...sx }}
      {...rest}
    />
  );
};

const EmptyFeaturesSkeleton: FC = () => {
  return (
    <Stack sx={{ height: '100%', px: '15px', pt: '10px' }}>
      <Stack flexDirection="row" alignItems="center" justifyContent="space-between" sx={{ mb: '8px' }}>
        <SkeletonBase gradientDegree={80} width={96} height={12} />
        <SkeletonBase gradientDegree={80} width={53} height={8} />
      </Stack>
      <SkeletonBase gradientDegree={60} height={133} />
      <Stack flexDirection="row" alignItems="center" justifyContent="space-between" sx={{ mt: '22px', mb: '8px' }}>
        <SkeletonBase gradientDegree={80} width={96} height={12} />
        <SkeletonBase gradientDegree={80} width={53} height={8} />
      </Stack>
      <Stack flexDirection="row" alignItems="center" justifyContent="space-between">
        <SkeletonBase gradientDegree={50} width="50%" height={103} sx={{ mr: '3px' }} />
        <SkeletonBase gradientDegree={50} width="50%" height={103} sx={{ ml: '3px' }} />
      </Stack>
    </Stack>
  );
};

export default EmptyFeaturesSkeleton;
