import { forwardRef } from 'react';
import GroupWebWidgetPreferencesForm from './GroupWebWidgetPreferencesForm';
import { GroupWebWidgetPreferencesFormContainerProps } from './GroupWebWidgetPreferencesFormProps';
import useGroupWebWidgetPreferencesForm from './hooks/useGroupWebWidgetPreferencesForm';

const GroupWebWidgetPreferencesFormContainer = forwardRef<any, GroupWebWidgetPreferencesFormContainerProps>(
  (props, ref) => {
    const result = useGroupWebWidgetPreferencesForm(props, ref);

    return <GroupWebWidgetPreferencesForm {...props} {...result} />;
  },
);

export default GroupWebWidgetPreferencesFormContainer;
