import React, { FC, ReactNode } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Field } from 'formik';
import { ColorPickerFormField, Stack, Typography } from '@egym/ui';
import ColorContrastAlert from 'src/pages/AppDesign/components/AppDesignForm/components/ColorContrastAlert';
import ColorContrastBadgeContent from 'src/pages/AppDesign/components/AppDesignForm/components/ColorContrastBadgeContent';
import { RewardIconBackgroundColorFieldProps } from './RewardIconBackgroundColorFieldProps';

const RewardIconBackgroundColorField: FC<RewardIconBackgroundColorFieldProps> = ({
  presetColors,
  showHighContrastWarning,
  setShowHighContrastWarning,
  highContrastWarning,
  setColorPickerOpened,
  setHighContrastWarning,
}) => {
  const { t } = useTranslation();
  return (
    <Stack spacing={5}>
      <Typography variant="h5Bold">{t('common.labels.icon') as ReactNode}</Typography>
      {highContrastWarning?.primary && highContrastWarning?.source === 'widget' && showHighContrastWarning && (
        <ColorContrastAlert
          message={
            <Trans
              i18nKey="appDesign.validation.backgroundFillColorIsTooLight"
              components={[<Typography component="span" variant="body1Bold" />]}
            />
          }
          onClose={() => setShowHighContrastWarning(false)}
          wrapperSx={{ my: 4 }}
        />
      )}
      <Field
        name="backgroundColor"
        component={ColorPickerFormField}
        fieldLabel="appDesign.labels.iconBackgroundColor"
        presetColors={presetColors}
        onPopoverOpen={() => setColorPickerOpened(true)}
        onPopoverClose={() => setColorPickerOpened(false)}
        badgeProps={
          highContrastWarning?.primary &&
          (highContrastWarning?.source === 'colorTheme' || !showHighContrastWarning) && {
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
            badgeContent: (
              <ColorContrastBadgeContent
                onClick={() => {
                  setShowHighContrastWarning(true);
                  setHighContrastWarning({ ...highContrastWarning, source: 'widget' });
                }}
              />
            ),
          }
        }
      />
    </Stack>
  );
};

export default RewardIconBackgroundColorField;
