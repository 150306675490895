import { ColorThemeEnum } from '@types';

export const colorThemeColors = {
  [ColorThemeEnum.EXTREME_RED]: {
    primary: 'rgba(196, 32, 28, 1)',
    secondary: 'rgba(61, 61, 61, 1)',
  },
  [ColorThemeEnum.ORANGE_ACTION]: {
    primary: 'rgba(199, 80, 0, 1)',
    secondary: 'rgba(0, 0, 0, 1)',
  },
  [ColorThemeEnum.ORANGE_ACCENT]: {
    primary: 'rgba(0, 0, 0, 1)',
    secondary: 'rgba(209, 73, 0, 1)',
  },
  [ColorThemeEnum.ROYAL_BLUE]: {
    primary: 'rgba(1, 35, 90, 1)',
    secondary: 'rgba(0, 96, 215, 1)',
  },
  [ColorThemeEnum.BLUE_WAVE]: {
    primary: 'rgba(15, 107, 255, 1)',
    secondary: 'rgba(2, 59, 129, 1)',
  },
  [ColorThemeEnum.NATURAL_GREEN]: {
    primary: 'rgba(2, 136, 80, 1)',
    secondary: 'rgba(1, 80, 47, 1)',
  },
  [ColorThemeEnum.AVOCADO_GREEN]: {
    primary: 'rgba(87, 128, 0, 1)',
    secondary: 'rgba(50, 50, 50, 1)',
  },
  [ColorThemeEnum.TWILIGHT_SPARKLE]: {
    primary: 'rgba(54, 58, 115, 1)',
    secondary: 'rgba(183, 9, 82, 1)',
  },
  [ColorThemeEnum.PINK]: {
    primary: 'rgba(0, 0, 0, 1)',
    secondary: 'rgba(201, 0, 173, 1)',
  },
  [ColorThemeEnum.MIDNIGHT]: {
    primary: 'rgba(1, 35, 90, 1)',
    secondary: 'rgba(70, 22, 183, 1)',
  },
};

export const colorThemeOptions = Object.keys(colorThemeColors) as ColorThemeEnum[];
