import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const NotificationsIcon: React.FC<SvgIconProps> = ({ sx, htmlColor = '#4C4A4A', ...rest }) => {
  return (
    <SvgIcon viewBox="0 0 24 24" sx={{ width: '24px', height: '24px', ...sx }} {...rest}>
      <g fill="none" fillRule="evenodd">
        <g fill={htmlColor}>
          <g>
            <g>
              <path
                d="M14 19v.524c-.025.972-1.283 1.72-2.642 1.402-.83-.187-1.358-.785-1.358-1.421v-.486s0-.019.025-.019H14zm-.366-14.236v.154c2.295.774 3.83 2.961 3.83 5.574v4.296l1.264 1.335c.181.193.271.445.271.716v.174c.018.542-.397.987-.92.987H5.938C5.415 18 5 17.555 5 16.994v-.174c0-.271.09-.523.271-.716l1.264-1.316V10.51c0-2.593 1.518-4.78 3.794-5.554.012 0 .016-.009.017-.02v-.173c0-.581.254-1.142.705-1.452 1.229-.851 2.583.174 2.583 1.452z"
                transform="translate(-327 -53) translate(323 49) translate(4 4)"
              />
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export default NotificationsIcon;
