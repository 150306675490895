import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const MainTabHeaderDefaultIcon12: React.FC<SvgIconProps> = ({ htmlColor = '#FFF', sx, ...props }) => {
  return (
    <SvgIcon viewBox="0 0 48 48" {...props} sx={{ width: '48px', height: '48px', ...sx }}>
      <g fill={htmlColor} fillRule="evenodd">
        <path d="M31.024 18.886a2 2 0 0 1 .92 2.674l-4.547 9.327A4 4 0 1 1 24 29l-.136.003 4.485-9.196a2 2 0 0 1 2.675-.921zM24 9c9.83 0 17.82 7.88 17.997 17.67L42 27v7a2 2 0 0 1-1.85 1.995L40 36h-6a2 2 0 0 1-.15-3.995L34 32h4v-5c0-7.628-6.1-13.83-13.687-13.997L24 13c-7.628 0-13.83 6.1-13.997 13.687L10 27v5h4a2 2 0 0 1 1.995 1.85L16 34a2 2 0 0 1-1.85 1.995L14 36H8a2 2 0 0 1-1.995-1.85L6 34v-7c0-9.941 8.059-18 18-18z" />
        <path
          d="M15.036 25.402a2 2 0 1 1-2 3.464 2 2 0 0 1 2-3.464zm20 0a2 2 0 1 1-2 3.464 2 2 0 0 1 2-3.464zm-17-7a2 2 0 1 1-2 3.464 2 2 0 0 1 2-3.464zm7-3a2 2 0 1 1-2 3.464 2 2 0 0 1 2-3.464z"
          opacity=".6"
        />
      </g>
    </SvgIcon>
  );
};
