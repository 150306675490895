// https://material-ui.com/components/buttons/
import React, { ChangeEvent, ReactNode, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { LoadingButton as MuiLoadableButton, LoadingButtonProps as MuiLoadingButtonProps } from '@mui/lab';
import { styled } from '@mui/material/styles';

export type UploadButtonProps = {
  id: string;
  text: ReactNode;
  accept?: string | string[];
  onUpload(files: File[]);
  multiple?: boolean;
};

type Props = UploadButtonProps & MuiLoadingButtonProps;

const Input = styled('input')({
  display: 'none',
});

export const UploadButton: React.FC<Props> = ({ id, text, accept, onUpload, multiple, ...rest }) => {
  const { t } = useTranslation();
  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onUpload(e.target.files ? Array.from(e.target.files) : []);
      // eslint-disable-next-line no-param-reassign
      e.target.value = '';
    },
    [onUpload],
  );

  return (
    <label htmlFor={id}>
      <Input
        accept={Array.isArray(accept) ? accept.join(', ') : accept}
        id={id}
        multiple={multiple}
        type="file"
        onChange={onChange}
      />
      <MuiLoadableButton variant="contained" component="span" {...rest}>
        {typeof text === 'string' ? (t(text) as ReactNode) : text}
      </MuiLoadableButton>
    </label>
  );
};
