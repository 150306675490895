import { useMemo } from 'react';
import { compactToFullTabFeatureMap } from '@components';
import { UseWidgetPreviewResult, WidgetPreviewContainerProps } from '../../WidgetPreviewProps';

const useWidgetPreview = ({ widget }: WidgetPreviewContainerProps): UseWidgetPreviewResult => {
  const isFullTabWidget = useMemo(() => {
    return Object.values(compactToFullTabFeatureMap).includes(widget.props.name);
  }, [widget.props.name]);

  return {
    isFullTabWidget,
  };
};

export default useWidgetPreview;
