import React, { forwardRef } from 'react';
import { Paper, Stack, Divider } from '@egym/ui';
import { AppLayoutFeatureComponentProps } from '../../../../AppPreviewerProps';
import PreviewContainer from '../../../PreviewContainer';
import PreviewFeatureTitle from '../../../PreviewFeatureTitle';
import PreviewWorkoutTracking from '../PreviewWorkoutTracking';
import { mockActivityHistory } from './data';
import PreviewActivityHistoryItem from './PreviewActivityHistoryItem';

const PreviewActivityHistory = forwardRef<HTMLDivElement, AppLayoutFeatureComponentProps>(
  ({ tFunction, palette, previewerState, wrapperSx }, ref) => {
    return (
      <PreviewContainer ref={ref} wrapperSx={wrapperSx}>
        <PreviewFeatureTitle
          tFunction={tFunction}
          palette={palette}
          title="appPreviewer.labels.latestActivity"
          withViewAll
        />
        <Paper
          sx={{
            px: '15px',
            py: '5px',
            textAlign: 'left',
            fontSize: '14px',
            color: 'rgb(33,33,33)',
            borderRadius: '8px',
            boxShadow: '0 4px 6px rgba(223, 223, 223, 0.7)',
          }}
        >
          <Stack divider={<Divider flexItem sx={{ borderColor: 'rgb(244, 244, 244)' }} />}>
            {mockActivityHistory.map(historyEntry => {
              return (
                <PreviewActivityHistoryItem
                  tFunction={tFunction}
                  palette={palette}
                  previewerState={previewerState}
                  key={historyEntry.id}
                  {...historyEntry}
                />
              );
            })}
          </Stack>
        </Paper>
        <PreviewWorkoutTracking
          palette={palette}
          previewerState={previewerState}
          tFunction={tFunction}
          wrapperSx={{ px: 0, mt: '10px' }}
        />
      </PreviewContainer>
    );
  },
);

export default PreviewActivityHistory;
