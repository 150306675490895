import { useCallback, useMemo } from 'react';
import { useUpdateEffect } from 'react-use';
import { useRecoilState } from 'recoil';
import useRouter from '../useRouter';
import { contentDialogGlobalState } from './state';
import { ContentDialogConfig } from './types';

export * from './types';

const useContentDialog = () => {
  const [dialogConfig, setDialogConfig] = useRecoilState(contentDialogGlobalState.atoms.contentDialogConfig);
  const { pathname } = useRouter();
  const isOpen = useMemo(() => Boolean(dialogConfig?.open), [dialogConfig]);

  const openContentDialog = useCallback(
    (config: ContentDialogConfig) => {
      setDialogConfig({
        shouldCloseOnPathnameChange: true,
        ...config,
        open: true,
      });
    },
    [setDialogConfig],
  );

  const showCancelButton = useMemo(() => {
    return dialogConfig?.showCancelButton || dialogConfig?.onClose || dialogConfig?.showCancelButton === undefined;
  }, [dialogConfig]);

  const closeContentDialog = useCallback(() => {
    if (dialogConfig?.onClose) {
      dialogConfig.onClose();
    }
    setDialogConfig(prev => ({ ...prev, open: false }));
  }, [dialogConfig, setDialogConfig]);

  useUpdateEffect(() => {
    if (dialogConfig?.shouldCloseOnPathnameChange) {
      setDialogConfig(prev => ({
        ...prev,
        open: false,
      }));
    }
  }, [pathname]);

  return {
    isOpen,
    dialogConfig,
    openContentDialog,
    closeContentDialog,
    showCancelButton,
  };
};

export default useContentDialog;
