import { useAppSettingsProgressByRouter } from '@hooks';
import { UseAppSettingsProgressBarResult } from '../../AppSettingsProgressBarProps';

const useAppSettingsProgressBar = (): UseAppSettingsProgressBarResult => {
  const { doneStepsCount, allStepsCount } = useAppSettingsProgressByRouter();

  return {
    doneStepsCount,
    allStepsCount,
  };
};

export default useAppSettingsProgressBar;
