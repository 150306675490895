import React, { ReactNode } from 'react';
import { CustomPickerProps } from 'react-color';
import { useTranslation } from 'react-i18next';
import { SxProps } from '@mui/system';
import { SketchPickerProps } from 'react-color/lib/components/sketch/Sketch';
import { Stack, Tooltip, Typography } from '@egym/ui';
import ColorDisplayWithCheckboard from '../ColorDisplayWithCheckboard';

type Props = {
  wrapperSx?: SxProps;
  title?: string;
  presetColors: SketchPickerProps['presetColors'];
} & CustomPickerProps<any>;

const PresetColors: React.FC<Props> = ({ wrapperSx, title, presetColors, onChange }) => {
  const { t } = useTranslation();

  const handleChange = (newColor, e) => {
    onChange(
      {
        hex: newColor,
        // @ts-ignore
        source: 'hex',
      },
      e,
    );
  };

  return (
    <Stack sx={{ width: '100%', boxSizing: 'border-box', ...wrapperSx }}>
      <Typography
        variant="captionBold"
        sx={{ textTransform: 'uppercase', letterSpacing: '0.52px', lineHeight: '20px', mb: 1 }}
      >
        {t(title || 'appDesign.labels.colorPresets') as ReactNode}
      </Typography>
      <Stack direction="row" alignItems="center" justifyContent="flex-start">
        {presetColors?.map(presetColor => {
          const colorString = typeof presetColor === 'string' ? presetColor : presetColor.color;
          const colorTitle = typeof presetColor === 'object' ? presetColor.title : '';
          const key = `${colorString}${(typeof presetColor === 'object' && presetColor.title) || ''}`;
          return (
            <Tooltip
              title={
                <Typography sx={{ textTransform: 'lowercase', fontSize: '11px' }}>
                  {t(`appDesign.labels.presetColors.${colorTitle}`) as ReactNode}
                </Typography>
              }
              key={key}
            >
              <div>
                <ColorDisplayWithCheckboard
                  onClick={e => handleChange(colorString, e)}
                  wrapperSx={{ width: '18px', height: '18px', mr: 2 }}
                  colorString={colorString}
                />
              </div>
            </Tooltip>
          );
        })}
      </Stack>
    </Stack>
  );
};

export default PresetColors;
