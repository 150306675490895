import React, { forwardRef, useMemo } from 'react';
import { Skeleton } from '@mui/material';
import { ConditionalRender, Stack } from '@egym/ui';
import { AppLayoutFeatureComponentProps } from '@components';
import PreviewContainer from '../../../PreviewContainer';
import PreviewFeatureTitle from '../../../PreviewFeatureTitle';

const PreviewSingleBasicWidgetSkeleton = forwardRef<HTMLDivElement, AppLayoutFeatureComponentProps>(
  ({ previewerId, wrapperSx, widgetProps, tFunction, previewerState: { selectedLocale }, palette }, ref) => {
    const title = useMemo(() => {
      return widgetProps?.preferences?.title?.[selectedLocale as string] || widgetProps?.preferences?.title?.en_US;
    }, [selectedLocale, widgetProps?.preferences?.title]);

    return (
      <PreviewContainer ref={ref} wrapperSx={wrapperSx}>
        <ConditionalRender condition={Boolean(title)}>
          <PreviewFeatureTitle tFunction={tFunction} title={title} palette={palette} />
          <Stack
            direction="row"
            spacing={2}
            alignItems="flex-end"
            sx={{ mb: '8px', height: previewerId ? '21px' : undefined }}
          >
            <Skeleton
              variant="rectangular"
              animation={false}
              sx={{ borderRadius: 2, backgroundColor: 'rgb(158, 158, 158)' }}
              width={97}
              height={10}
            />
          </Stack>
        </ConditionalRender>
        <Stack direction="column" spacing={2}>
          <Skeleton
            component="div"
            variant="rectangular"
            animation={false}
            sx={{ borderRadius: '7px', backgroundColor: 'rgb(206, 206, 206)' }}
            width="100%"
            height={80}
          >
            <Stack
              direction="row"
              height="100%"
              width="100%"
              alignItems="center"
              justifyContent="space-between"
              visibility="unset"
            >
              <Stack direction="column" spacing={2} sx={{ ml: '15px' }}>
                <Skeleton
                  variant="rectangular"
                  animation={false}
                  sx={{ borderRadius: 2, backgroundColor: 'rgb(237, 237, 237)' }}
                  width={91}
                  height={8}
                />
                <Skeleton
                  variant="rectangular"
                  animation={false}
                  sx={{ borderRadius: 2, backgroundColor: 'rgb(237, 237, 237)' }}
                  width={118}
                  height={8}
                />
              </Stack>
              <Skeleton
                variant="circular"
                animation={false}
                width={36}
                height={36}
                sx={{ backgroundColor: 'rgb(189, 189, 189)', mr: '20px' }}
              />
            </Stack>
          </Skeleton>
        </Stack>
      </PreviewContainer>
    );
  },
);

export default PreviewSingleBasicWidgetSkeleton;
