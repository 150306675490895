import React, { useImperativeHandle, useMemo, useRef } from 'react';
import { useEffectOnce, useUpdateEffect, useWindowSize, useLockBodyScroll, useToggle } from 'react-use';
import { footerHeight, headerHeight } from 'src/styles/constants';
import { AppPreviewerContainerProps, UseAppPreviewerProps } from '../../AppPreviewerProps';
import useAppPreviewerState from '../useAppPreviewerState';

const getScale = (
  windowHeight: number,
  deviceHeight: number,
  extraHeightsToFitInWindow: number[] = [],
  pageHeaderHeight = headerHeight,
  pageFooterHeight = footerHeight,
) => {
  const windowHeightWithHeader = windowHeight - pageHeaderHeight - pageFooterHeight;

  const extraHeightsSum = extraHeightsToFitInWindow.reduce((acc, height) => acc + height, 0);

  const deviceHeightWithExtraElements = deviceHeight + 40 + extraHeightsSum;

  return windowHeightWithHeader < deviceHeightWithExtraElements
    ? Number((windowHeightWithHeader / deviceHeightWithExtraElements).toFixed(4))
    : 1;
};

const useAppPreviewer = (
  {
    id,
    size,
    route,
    palette,
    appLayout,
    appPackage,
    fitToWindow,
    mainTabHeaderIcon,
    appIconLink,
    appIconBackground,
    iosAppIconName,
    signInScreenProps,
    locales,
    disabled,
    extraHeightsToFitInView,
    pageHeaderHeight,
    pageFooterHeight,
  }: AppPreviewerContainerProps,
  ref,
): UseAppPreviewerProps => {
  const [lockedBody, toggleLockedBody] = useToggle(false);
  const signInScreenRef = useRef();

  useImperativeHandle(ref, () => ({
    signInScreenRef,
  }));
  const { height: windowHeight } = useWindowSize();
  const scrollRef = React.useRef(null);
  const {
    changePackage,
    changeLayout,
    changeCurrentRoute,
    changePalette,
    changeSize,
    previewerState,
    containerSize,
    changeAppIconLink,
    changeMainTabIconLink,
    changeAppIconBackground,
    changePreviewerState,
    changeDisabled,
  } = useAppPreviewerState(id);

  useLockBodyScroll(lockedBody);

  const deviceScale = useMemo(() => {
    const extraHeightToFitInView: number[] = [...(extraHeightsToFitInView || [])];
    if (locales && locales.length > 1) extraHeightToFitInView.push(64);

    return fitToWindow
      ? getScale(windowHeight, containerSize.deviceHeight, extraHeightToFitInView, pageHeaderHeight, pageFooterHeight)
      : 1;
  }, [
    extraHeightsToFitInView,
    locales,
    fitToWindow,
    windowHeight,
    containerSize.deviceHeight,
    pageHeaderHeight,
    pageFooterHeight,
  ]);

  const deviceMargin = useMemo(() => {
    return fitToWindow && deviceScale < 1 ? `0 auto ${-containerSize.deviceHeight * (1 - deviceScale)}px` : 0;
  }, [fitToWindow, deviceScale, containerSize.deviceHeight]);

  const sizes = useMemo(() => {
    return {
      ...containerSize,
      deviceScale,
      deviceMargin,
    };
  }, [deviceScale, deviceMargin, containerSize]);

  useEffectOnce(() => {
    changeCurrentRoute(route);
    changeSize(size);
    changeMainTabIconLink(mainTabHeaderIcon);

    if (signInScreenProps) {
      // @ts-ignore
      changePreviewerState({ signInScreenProps });
    }
    if (iosAppIconName) {
      changePreviewerState({ iosAppIconName });
    }
    if (appLayout) {
      changeLayout(appLayout);
    }
    if (appPackage) {
      changePackage(appPackage);
    }
    if (palette) {
      changePalette(palette);
    }
    if (appIconLink) {
      changeAppIconLink(appIconLink);
    }
    if (appIconBackground) {
      changeAppIconBackground(appIconBackground);
    }
    if (typeof disabled === 'boolean') {
      changeDisabled(disabled);
    }
  });

  useUpdateEffect(() => {
    if (signInScreenProps) {
      // @ts-ignore
      changePreviewerState({ signInScreenProps });
    }
  }, [signInScreenProps, id]);

  useUpdateEffect(() => {
    if (iosAppIconName) {
      changePreviewerState({ iosAppIconName });
    }
  }, [iosAppIconName, id]);

  useUpdateEffect(() => {
    if (appIconLink) {
      changeAppIconLink(appIconLink);
    }
  }, [appIconLink, id]);

  useUpdateEffect(() => {
    changeMainTabIconLink(mainTabHeaderIcon);
  }, [mainTabHeaderIcon, id]);

  useUpdateEffect(() => {
    if (appIconBackground) {
      changeAppIconBackground(appIconBackground);
    }
  }, [appIconBackground, id]);

  useUpdateEffect(() => {
    changeCurrentRoute(route);
  }, [route, id]);

  useUpdateEffect(() => {
    if (palette) {
      changePalette(palette);
    }
  }, [palette, id]);

  useUpdateEffect(() => {
    changeSize(size);
  }, [size, id]);

  useUpdateEffect(() => {
    if (appLayout) {
      changeLayout(appLayout);
    }
  }, [appLayout, id]);

  useUpdateEffect(() => {
    if (appPackage) {
      changePackage(appPackage);
    }
  }, [appPackage, id]);

  const handleWheel = () => {
    toggleLockedBody(true);
  };

  const onMouseLeave = () => {
    toggleLockedBody(false);
  };

  return {
    ready: Boolean(previewerState.size && previewerState.route),
    currentRoute: previewerState.route,
    containerSize: sizes,
    changeCurrentRoute,
    scrollRef,
    handleWheel,
    onMouseLeave,
    signInScreenRef,
  };
};

export default useAppPreviewer;
