import { SIGN_IN_LOGO_DIMENSIONS } from './constants';

export const calculateOutputParameters = (logoRect, previewerRect) => {
  const widthRatio = SIGN_IN_LOGO_DIMENSIONS[0] / previewerRect.width;
  const heightRatio = SIGN_IN_LOGO_DIMENSIONS[1] / previewerRect.height;

  return {
    left: Math.round((logoRect.x - previewerRect.x + logoRect.width / 2) * widthRatio),
    top: Math.round((logoRect.y - previewerRect.y + logoRect.height / 2) * heightRatio),
    width: Math.round(logoRect.width * widthRatio),
    height: Math.round(logoRect.height * heightRatio),
  };
};
