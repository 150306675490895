import React from 'react';
import usePreviewerWellDoneRoute from './hooks/usePreviewerWellDoneRoute';
import PreviewerWellDoneRoute from './PreviewerWellDoneRoute';
import { PreviewerWellDoneRouteContainerProps } from './PreviewerWellDoneRouteProps';

const PreviewerWellDoneRouteContainer: React.FC<PreviewerWellDoneRouteContainerProps> = props => {
  const previewerWellDoneRoute = usePreviewerWellDoneRoute(props);

  return <PreviewerWellDoneRoute {...props} {...previewerWellDoneRoute} />;
};

export default PreviewerWellDoneRouteContainer;
