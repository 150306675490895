import React, { Dispatch, SetStateAction, useMemo } from 'react';
import { SxProps } from '@mui/system';
import { Box } from '@egym/ui';
import { generateGradientStyle } from '../../helpers';
import { GradientPickerType, GradientPoint } from '../../types';
import GradientSliderPoint from './GradientSliderPoint';

type Props = {
  points: GradientPoint[];
  wrapperSx?: SxProps;
  activeGradientPointIndex: number;
  setActiveGradientPointIndex: Dispatch<SetStateAction<number>>;
  updateGradientLeft: (left: number, index: number) => void;
  disableGradientTools?: boolean;
};

const GradientSlider: React.FC<Props> = ({
  points,
  wrapperSx,
  activeGradientPointIndex,
  setActiveGradientPointIndex,
  updateGradientLeft,
  disableGradientTools,
}) => {
  const sliderRef = React.useRef(null);

  const gradientStyle = useMemo(() => {
    return generateGradientStyle(points, GradientPickerType.Linear, 90);
  }, [points]);

  return (
    <Box
      ref={sliderRef}
      sx={{
        position: 'relative',
        width: '100%',
        height: '24px',
        borderRadius: '3px',
        ...wrapperSx,
      }}
      style={{
        background: gradientStyle,
      }}
    >
      {points.map((point, index) => {
        return (
          <GradientSliderPoint
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            points={points}
            updateGradientLeft={updateGradientLeft}
            point={point}
            isActive={index === activeGradientPointIndex}
            setActiveGradientPointIndex={setActiveGradientPointIndex}
            index={index}
            sliderRef={sliderRef}
            disabled={disableGradientTools}
          />
        );
      })}
    </Box>
  );
};

export default GradientSlider;
