import React from 'react';
import { useUpdateEffect } from 'react-use';
import { FormikProps, useFormikContext } from 'formik';

type Props = {
  onFormChanged: (form: FormikProps<any>) => void;
};

const FormikOnChange: React.FC<Props> = ({ onFormChanged }) => {
  const ctx = useFormikContext<any>();

  useUpdateEffect(() => {
    onFormChanged(ctx);
  }, [ctx.values, ctx.dirty, ctx.isSubmitting]);

  return null;
};

export default FormikOnChange;
