import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const MainTabHeaderDefaultIcon22: React.FC<SvgIconProps> = ({ htmlColor = '#FFF', sx, ...props }) => {
  return (
    <SvgIcon viewBox="0 0 48 48" {...props} sx={{ width: '48px', height: '48px', ...sx }}>
      <g fill={htmlColor} fillRule="evenodd">
        <path d="M20 6a2 2 0 0 1 1.995 1.85L22 8v7.27c0 4.168-3.002 7.632-7 8.513V40a2 2 0 1 1-4 0V23.783c-3.915-.862-6.875-4.202-6.996-8.255L4 15.269V8a2 2 0 0 1 3.995-.15L8 8v7.27C8 17.863 10.221 20 13 20c2.703 0 4.88-2.022 4.995-4.521l.005-.21V8a2 2 0 0 1 2-2zm10.318 1.704a7.075 7.075 0 0 1 9.561.181A13.278 13.278 0 0 1 44 17.5v1.021c0 4.49-2.974 8.285-7 9.245V40a2 2 0 1 1-4 0V27.766c-4.026-.96-7-4.755-7-9.245V17.5c0-3.634 1.49-7.109 4.12-9.615zm6.803 3.078a3.075 3.075 0 0 0-4.242 0A9.278 9.278 0 0 0 30 17.5v1.021C30 21.576 32.27 24 35 24s5-2.424 5-5.479V17.5c0-2.54-1.04-4.967-2.88-6.718z" />
        <rect width="4" height="10" rx="2" opacity=".6" transform="translate(11 6)" />
      </g>
    </SvgIcon>
  );
};
