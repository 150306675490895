import React from 'react';
import { Button } from '@egym/ui';
import { ShareIcon } from '@svg';
import { AppDesignSharePreviewButtonProps } from './AppDesignSharePreviewButtonProps';

const AppDesignSharePreviewButton: React.FC<AppDesignSharePreviewButtonProps> = ({ onShareClick }) => {
  return (
    <Button
      onClick={onShareClick}
      variant="text"
      startIcon={<ShareIcon sx={{ mb: 0.5 }} />}
      color="inherit"
      text="appDesign.labels.shareAppPreview"
      sx={{ textTransform: 'uppercase' }}
    />
  );
};

export default AppDesignSharePreviewButton;
