import React, { PropsWithChildren, useState } from 'react';
import { useUpdateEffect } from 'react-use';
import { useFormikContext } from 'formik';

const getFirstErrorKey = (object, keys: string[] = []) => {
  const firstErrorKey = Object.keys(object)[0];

  if (typeof object[firstErrorKey] === 'object') {
    return getFirstErrorKey(object[firstErrorKey], [...keys, firstErrorKey]);
  }
  return [...keys, firstErrorKey].join('.');
};

export const FormikScrollToError: React.FC<PropsWithChildren> = ({ children }) => {
  const { submitCount, isValid, errors } = useFormikContext();
  const [initialSubmitCount, setInitialSubmitCount] = useState(submitCount);

  useUpdateEffect(() => {
    if (!isValid && submitCount > initialSubmitCount) {
      const firstErrorKey = getFirstErrorKey(errors);

      if (global.window.document.getElementsByName(firstErrorKey).length) {
        global.window.document.getElementsByName(firstErrorKey)[0].scrollIntoView({ block: 'center' });
        global.window.document.getElementsByName(firstErrorKey)[0].focus();
      }
      setInitialSubmitCount(submitCount);
    }
  }, [submitCount, isValid]);

  return <>{children}</>;
};
