import { useEffect } from 'react';

const useLockBodyScroll = trigger => {
  useEffect(() => {
    if (trigger) {
      document.body.classList.add('lock-scroll');
    } else {
      document.body.classList.remove('lock-scroll');
    }
  }, [trigger]);
};

export default useLockBodyScroll;
