import React from 'react';
import { Box } from '@mui/system';
import { GradientPickerType } from '../../../../types';

type Props = {
  type: GradientPickerType;
  selected: boolean;
  onClick: () => void;
};

const gradientBackground = {
  [GradientPickerType.Linear]: 'linear-gradient(to bottom, #fff, #4d4d4d);',
  [GradientPickerType.Radial]: 'radial-gradient(circle at 50% 50%, #fbfbfb, #575757 70%);',
};

const GradientTypeButton: React.FC<Props> = ({ type, selected, onClick }) => {
  return (
    <Box
      onClick={onClick}
      sx={{
        border: '1px solid',
        borderColor: selected ? 'primary.main' : 'transparent',
        borderRadius: '50%',
      }}
    >
      <Box
        sx={{
          border: '1px solid',
          borderColor: 'common.white',
          borderRadius: '50%',
          width: '24px',
          height: '24px',
          background: gradientBackground[type],
          cursor: 'pointer',
        }}
      />
    </Box>
  );
};

export default GradientTypeButton;
