import { uniq } from 'ramda';

const castArray = (item: any): any[] => (Array.isArray(item) ? item : [item]);

export enum FileFormatName {
  XML = 'XML',
  JSON = 'JSON',
  CSV = 'CSV',
  ZIP = 'ZIP',
  TAR = 'TAR',
  PNG = 'PNG',
  JPG = 'JPEG',
  SVG = 'SVG',
  SH = 'SH',
  BIN = 'BIN',
}

export const mimeTypes = {
  [FileFormatName.XML]: 'text/xml',
  [FileFormatName.JSON]: 'applications/json',
  [FileFormatName.CSV]: [
    'text/csv',
    'applications/vnd.ms-excel',
    'application/vnd.apple.numbers',
    'application/x-iwork-numbers-sffnumbers',
  ],
  [FileFormatName.ZIP]: ['applications/zip', 'applications/x-zip-compressed'],
  [FileFormatName.TAR]: 'applications/x-tar',
  [FileFormatName.PNG]: 'image/png',
  [FileFormatName.JPG]: 'image/jpeg',
  [FileFormatName.SVG]: 'image/svg+xml',
  [FileFormatName.SH]: ['text/x-sh', 'applications/x-sh', 'text/x-shellscript', 'applications/x-shellscript'],
  [FileFormatName.BIN]: ['applications/octet-stream', 'applications/macbinary'],
};

export const getMimeTypesByFormatNames = (formatNames: FileFormatName | FileFormatName[]) => {
  return castArray(formatNames).flatMap(formatName => mimeTypes[formatName]);
};

export const imageMimeTypes = getMimeTypesByFormatNames([FileFormatName.SVG, FileFormatName.JPG, FileFormatName.PNG]);

export const mimeTypesToHumanReadableFormats = mimeTypesInput => {
  return uniq(
    mimeTypesInput.map(mimeType =>
      Object.keys(mimeTypes).find(formatName => castArray(mimeTypes[formatName]).includes(mimeType)),
    ),
  );
};

export const convertFileFormatsToAcceptableStructure = (accept: FileFormatName[]) => {
  return accept.reduce((result, format) => {
    const formatType = mimeTypes[format];
    return Array.isArray(formatType)
      ? formatType.reduce((res, type) => {
          return {
            ...res,
            [`${type}`]: [`.${format.toLowerCase()}`],
          };
        }, result)
      : {
          ...result,
          [`${formatType}`]: [`.${format.toLowerCase()}`],
        };
  }, {});
};

export const getBytesFromKb = (num: number, bytesBase = 1000): number => num * bytesBase;
export const getBytesFromMb = (num: number, bytesBase = 1000): number => num * bytesBase ** 2;
export const bytesToKb = (num, bytesBase = 1000) => num / bytesBase;
export const bytesToMb = (num, bytesBase = 1000) => num / bytesBase ** 2;
export const bytesToGb = (num, bytesBase = 1000) => num / bytesBase ** 3;

export const getReadableFileSize = (bytes, bytesSize = 1000) => {
  const gbSize = bytesToGb(bytes, bytesSize);
  const mbSize = bytesToMb(bytes, bytesSize);

  if (gbSize > 1) {
    return { size: gbSize, unit: 'Gb' };
  }
  if (mbSize > 1) {
    return { size: mbSize, unit: 'Mb' };
  }

  return { size: bytesToKb(bytes, bytesSize), unit: 'kB' };
};
