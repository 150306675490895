import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const MainTabHeaderDefaultIcon19: React.FC<SvgIconProps> = ({ htmlColor = '#FFF', sx, ...props }) => {
  return (
    <SvgIcon viewBox="0 0 48 48" {...props} sx={{ width: '48px', height: '48px', ...sx }}>
      <g fill={htmlColor} fillRule="evenodd">
        <path
          d="M39.867 5a3.133 3.133 0 0 1 3.128 2.956l.005.177V19.63a2 2 0 0 1-3.995.149l-.005-.15V9H9v30h12.5a2 2 0 0 1 1.995 1.85l.005.15a2 2 0 0 1-1.85 1.995L21.5 43H8.133a3.133 3.133 0 0 1-3.128-2.956L5 39.867V8.133a3.133 3.133 0 0 1 2.956-3.128L8.133 5h31.734zM37.25 24.768a1.5 1.5 0 0 1 .549 2.049L37.115 28c3.278.01 5.885 2.343 5.885 6.221C43 39.234 40.165 43 34.5 43c-5.532 0-8.5-3.861-8.5-8.779C26 30.335 28.618 28 31.906 28c.602 0 1.16.037 1.675.123l1.62-2.806a1.5 1.5 0 0 1 2.049-.55zM37.094 31c-1.245 0-2.11.166-2.594 1.05-.484-.884-1.35-1.05-2.594-1.05-1.35 0-2.906.758-2.906 3.221C29 36.941 30.294 40 34.5 40c4.368 0 5.5-3.032 5.5-5.779C40 31.758 38.443 31 37.094 31z"
          fillRule="nonzero"
        />
        <path
          d="M16 30a2 2 0 1 1 0 4 2 2 0 0 1 0-4zm4-8a2 2 0 1 1 0 4h-4a2 2 0 1 1 0-4h4zm5-8a2 2 0 1 1 0 4h-9a2 2 0 1 1 0-4h9z"
          opacity=".6"
        />
      </g>
    </SvgIcon>
  );
};
