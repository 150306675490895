import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const CoinIcon: React.FC<SvgIconProps> = ({ sx, htmlColor = '#4C4A4A', ...rest }) => {
  return (
    <SvgIcon viewBox="0 0 140 100" sx={{ width: '120px', height: '800px', ...sx }} {...rest}>
      <g id="Community-–-PRO" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="02-Community" transform="translate(-215.000000, -693.000000)">
          <g id="Group-13" transform="translate(20.000000, 659.000000)">
            <g id="Widgets-/-Rewards" transform="translate(0.000000, 34.000000)">
              <g id="Group-3" transform="translate(195.000000, 0.000000)">
                <rect id="img" x="0" y="1" width="140" height="100" />
                <path
                  d="M69.8197979,19 C87.5923952,19 102,33.550593 102,51.4998772 C102,69.4491614 87.5926383,84 69.8197979,84 C67.8326092,84 65.8877321,83.8165458 64,83.4685477 C78.9943751,80.7019998 90.3604042,67.4422169 90.3604042,51.4998772 C90.3604042,35.5577831 78.9943751,22.2977546 64,19.5314523 C65.8877321,19.1832086 67.8326092,19 69.8197979,19 Z M88.8200038,19 C106.592731,19 121,33.5510842 121,51.5001228 C121,69.4491614 106.592731,84 88.8200038,84 C86.8328278,84 84.8879631,83.8165458 83,83.4685477 C97.9945223,80.7019998 109.360235,67.4422169 109.360235,51.4998772 C109.360235,35.5577831 97.9945223,22.2977546 83,19.5314523 C84.8879631,19.1832086 86.8328278,19 88.8200038,19 Z M51.4992624,20 C68.8968178,20 83,34.1030721 83,51.5 C83,68.8971737 68.8968178,83 51.4995083,83 C34.1026905,83 20,68.8969279 20,51.5 C20,34.1030721 34.1026905,20 51.4992624,20 Z M51.5,30 C39.6258779,30 30,39.6258779 30,51.5 C30,63.3741221 39.6258779,73 51.5,73 C63.3741221,73 73,63.3741221 73,51.5 C73,39.6258779 63.3741221,30 51.5,30 Z M54.625,39.671875 L54.625,58.9375 L59.421875,58.9375 L59.421875,63 L44.046875,63 L44.046875,58.9375 L48.953125,58.9375 L48.953125,46.640625 L44.046875,46.640625 L44.046875,42.84375 L44.5849609,42.8349609 C45.390625,42.8085938 45.390625,42.8085938 46.1875,42.703125 C47.25,42.5625 47.25,42.5625 47.890625,42.25 C48.640625,41.875 48.640625,41.875 49.0546875,41.2421875 C49.4169922,40.6884766 49.4169922,40.6884766 49.5101318,39.9014893 L49.53125,39.671875 L54.625,39.671875 Z"
                  id="ic"
                  fillOpacity="0.382701847"
                  fill={htmlColor}
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export default CoinIcon;
