import { getFileFromUrl } from '@egym/api';
import { MLString, Uuid } from '@egym/types';
import { getOriginalFilePath } from '@egym/utils';
import { AppPreviewerTab } from '@components';
import { isGStoreResource } from '@helpers';
import { AppDesignLayoutTabWidget, WidgetViewStyle, WidgetWithTab } from '@types';

type FormValues = {
  id?: number | Uuid | null;
  title?: MLString | null;
  url?: string | null;
  callToAction?: MLString | null;
  image?: File[] | string;
  imagePredefinedId?: number;
  imageOverlay?: string;
  icon?: File[] | string;
  iconPredefinedId?: number;
  backgroundColor?: string;
};

export const convertToStyledWidgetState = (
  style: WidgetViewStyle,
  values: FormValues,
): Partial<AppDesignLayoutTabWidget> => {
  let result = {
    id: values.id,
    preferences: {
      style,
      title: values.title,
      url: values.url,
      callToAction: values.callToAction,
    },
  } as any;

  if (style === WidgetViewStyle.Image) {
    const image = Array.isArray(values.image) && values.image.length ? values.image[0] : (values.image as any);
    const originalFile =
      !values.imagePredefinedId && Array.isArray(values.image) && values.image.length > 1 ? values.image[1] : undefined;
    result = {
      ...result,
      preferences: {
        ...result.preferences,
        asset: {
          id: values.imagePredefinedId,
          link: typeof image === 'string' ? image : undefined,
          file: image instanceof File ? image : undefined,
          originalFile: originalFile instanceof File ? originalFile : undefined,
        },
        assetColor: values.imageOverlay,
      },
    };
  }

  if (style === WidgetViewStyle.Icon) {
    if (values.icon) {
      const icon = Array.isArray(values.icon) && values.icon.length ? values.icon[0] : (values.icon as any);
      result = {
        ...result,
        preferences: {
          ...result.preferences,
          asset: {
            id: values.iconPredefinedId,
            link: typeof icon === 'string' ? icon : undefined,
            file: icon instanceof File ? icon : undefined,
          },
          assetColor: values.backgroundColor,
        },
      };
    } else {
      result = {
        ...result,
        preferences: {
          ...result.preferences,
          asset: null,
          assetColor: values.backgroundColor,
        },
      };
    }
  }
  return result;
};

type ImageFormValues = {
  style: WidgetViewStyle | null;
  imagePredefinedId?: number | null;
  image?: File[] | string;
};

export const getOriginalAsset = async ({ style, imagePredefinedId, image }: ImageFormValues): Promise<File | null> => {
  if (style === WidgetViewStyle.Image && !imagePredefinedId) {
    if (typeof image === 'string' && isGStoreResource(image)) {
      try {
        // eslint-disable-next-line
        return await getFileFromUrl(getOriginalFilePath(image));
      } catch {
        return null;
      }
    }
    if (Array.isArray(image) && image.length === 2) {
      return image[1];
    }
    return null;
  }
  return null;
};

export const layoutWidgetToTabWidget = (
  widget: AppDesignLayoutTabWidget,
  appLayoutTabs: AppPreviewerTab[],
): WidgetWithTab => ({
  id: widget.id,
  name: widget.name,
  disabled: widget.disabled,
  preferences: widget.preferences,
  addedOnTab: widget.tabId ? appLayoutTabs.find(tab => tab.id === widget.tabId) : null,
});
