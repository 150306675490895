import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const MainTabHeaderDefaultIcon24: React.FC<SvgIconProps> = ({ htmlColor = '#FFF', sx, ...props }) => {
  return (
    <SvgIcon viewBox="0 0 48 48" {...props} sx={{ width: '48px', height: '48px', ...sx }}>
      <g fill={htmlColor} fillRule="evenodd">
        <path d="M11 6a2 2 0 0 1 1.907 1.396l.042.157.905 3.949 25.155.115a2 2 0 0 1 1.978 2.227l-.022.147-3.5 18.383a2 2 0 0 1-1.806 1.62L35.5 34h-19a2 2 0 0 1-1.907-1.396l-.042-.157L9.406 10H8a2 2 0 0 1-1.995-1.85L6 8a2 2 0 0 1 1.85-1.995L8 6h3zm3.771 9.505L18.093 30h15.751l2.741-14.394-21.814-.101z" />
        <g opacity=".6" transform="translate(12 37)">
          <circle cx="2.5" cy="2.5" r="2.5" />
          <circle cx="23.5" cy="2.5" r="2.5" />
        </g>
      </g>
    </SvgIcon>
  );
};
