import React, { useMemo } from 'react';
import { TableCell as MuiTableCell } from '@mui/material';
import { SystemStyleObject } from '@mui/system';
import { TableColumn } from '@egym/ui';
import { getByStringPath } from '@egym/utils';

type Props = {
  column: TableColumn;
  row: any;
  rowIndex: number;
  tdProps?: Record<string, any>;
  isViewMode?: boolean;
  wrappedSx?: SystemStyleObject;
};

const TableBodyCell: React.FC<Props> = ({ column, row, rowIndex, tdProps, isViewMode, wrappedSx }) => {
  const getByFieldName = useMemo(() => getByStringPath(column.field), [column.field]);
  const rawValue = getByFieldName(row);
  const value = column.accessor ? column.accessor({ value: rawValue }) : rawValue;
  const bodyCellSx = typeof column.bodyCellSx === 'function' ? column.bodyCellSx({ row, value }) : column.bodyCellSx;
  return (
    <MuiTableCell
      variant="body"
      scope="row"
      width={column.width}
      sx={{ ...wrappedSx, ...bodyCellSx }}
      align={column.numeric ? 'right' : 'left'}
      data-testid={`table-body-cell-${column.field}-${rowIndex}`}
    >
      {column.renderCell
        ? column.renderCell({ row, value, rawValue, tdProps: { ...tdProps, ...column.tdProps }, isViewMode })
        : value || '-'}
    </MuiTableCell>
  );
};

export default TableBodyCell;
