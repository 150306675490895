import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const FlagIcon: React.FC<SvgIconProps> = ({ sx, htmlColor = '#4C4A4A', ...rest }) => {
  return (
    <SvgIcon viewBox="0 0 24 24" sx={{ width: '20px', height: '20px', ...sx }} {...rest}>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <path d="M0 0H24V24H0z" transform="translate(-1820 -2679) translate(1820 2679)" />
            <path
              fill={htmlColor}
              d="M17.553 12.342L18.382 14H14v-1c0-.553-.447-1-1-1H7V6h5v1c0 .553.448 1 1 1h5.382l-.829 1.659c-.419.84-.419 1.842 0 2.683zm1.789-.894c-.14-.281-.14-.615 0-.895l1.553-3.106c.155-.309.138-.677-.044-.972C20.668 6.18 20.347 6 20 6h-6V5c0-.553-.447-1-1-1H7V3c0-.553-.447-1-1-1-.552 0-1 .447-1 1v17H4c-.552 0-1 .447-1 1 0 .553.448 1 1 1h4c.553 0 1-.447 1-1 0-.553-.447-1-1-1H7v-6h5v1c0 .553.448 1 1 1h7c.347 0 .668-.179.851-.474.182-.295.199-.663.044-.973l-1.553-3.105z"
              transform="translate(-1820 -2679) translate(1820 2679)"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export default FlagIcon;
