import React, { forwardRef } from 'react';
import { Stack } from '@mui/material';
import { Box, SxProps } from '@mui/system';

type Props = {
  checked?: boolean;
  wrapperSx?: SxProps;
  onMouseDown?: (event: any) => void;
  style?: React.CSSProperties;
};

const Pointer = forwardRef<HTMLDivElement, Props>(({ checked, wrapperSx, onMouseDown, style }, ref) => {
  return (
    <Stack
      ref={ref}
      onMouseDown={onMouseDown}
      sx={{
        width: '12px',
        height: '18px',
        border: '3px solid',
        borderColor: 'common.white',
        borderRadius: '1px',
        boxSizing: 'border-box',
        mt: '3px',
        transform: 'translateX(-50%)',
        boxShadow: 3,
        cursor: 'pointer',
        ...wrapperSx,
      }}
      style={style}
      alignItems="center"
      justifyContent="center"
    >
      {checked && <Box sx={{ width: '2px', height: '8px', bgcolor: 'common.white' }} />}
    </Stack>
  );
});

export default Pointer;
