import React, { ReactNode, useMemo } from 'react';
import { TFunction } from 'i18next';
import { Box, Paper, Stack } from '@egym/ui';
import { AppPreviewerPalette } from '@components';
import { AppDesignLayoutTabWidget } from '@types';
import { FacebookIcon, IconWrapper, InstagramIcon, TwitterIcon, YoutubeIcon } from '../../../Icons';

type Props = {
  tFunction: TFunction;
  palette: Partial<AppPreviewerPalette>;
  name: string;
  address: string;
  widgetProps?: Partial<AppDesignLayoutTabWidget>;
};

const PreviewHomeLocationCard: React.FC<Props> = ({ tFunction, palette, name, address, widgetProps }) => {
  const imagePath = useMemo<string | null>(() => {
    if (widgetProps?.preferences?.asset?.file) {
      return widgetProps?.preferences?.asset?.file?.preview
        ? widgetProps.preferences.asset.file.preview
        : URL.createObjectURL(widgetProps.preferences.asset.file);
    }
    if (typeof widgetProps?.preferences?.asset?.link === 'string') {
      const { link } = widgetProps.preferences.asset;
      return link.startsWith('/') ? link : `/${link}`;
    }
    return '/images/previewerDefaultImages/features/default-feature-img-club-profile-photo.png';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widgetProps?.preferences?.asset?.link, widgetProps?.preferences?.asset?.file?.name]);
  return (
    <Paper
      sx={{
        position: 'relative',
        overflow: 'hidden',
        borderRadius: '8px',
        boxShadow: '0 4px 6px rgba(223, 223, 223, 0.7)',
      }}
    >
      <div
        style={{
          height: '70px',
          width: '100%',
          background: imagePath ? `url(${imagePath}) no-repeat center/cover` : palette.primary,
          boxSizing: 'border-box',
          borderRadius: '8px',
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          overflow: 'hidden',
          position: 'relative',
        }}
      >
        {widgetProps?.preferences?.assetColor && (
          <div
            style={{
              position: 'absolute',
              background: widgetProps.preferences.assetColor,
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
            }}
          />
        )}
      </div>
      <Box sx={{ color: 'rgb(76, 74, 74)', fontSize: '13px', p: '10px' }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Stack>
            <span style={{ fontSize: '14px', fontWeight: 600, lineHeight: '20px', marginBottom: '1px' }}>
              {tFunction(name) as ReactNode}
            </span>
            <span style={{ marginBottom: '5px' }}>{tFunction(address) as ReactNode}</span>
            <span style={{ color: palette.primary, marginBottom: '15px', fontWeight: 500 }}>
              {tFunction('appPreviewer.labels.viewWorkingHours') as ReactNode}
            </span>
            <span style={{ marginBottom: '6px' }}>
              {tFunction('appPreviewer.labels.subscribeStayUpToDate') as ReactNode}
            </span>
            <Stack direction="row">
              <IconWrapper bgColor={palette.primaryTransparent} wrapperSx={{ mr: '6px' }}>
                <YoutubeIcon htmlColor={palette.primary} />
              </IconWrapper>
              <IconWrapper bgColor={palette.primaryTransparent} wrapperSx={{ mr: '6px' }}>
                <TwitterIcon htmlColor={palette.primary} />
              </IconWrapper>
              <IconWrapper bgColor={palette.primaryTransparent} wrapperSx={{ mr: '6px' }}>
                <FacebookIcon htmlColor={palette.primary} />
              </IconWrapper>
              <IconWrapper bgColor={palette.primaryTransparent}>
                <InstagramIcon htmlColor={palette.primary} />
              </IconWrapper>
            </Stack>
          </Stack>
        </Stack>
      </Box>
    </Paper>
  );
};

export default PreviewHomeLocationCard;
