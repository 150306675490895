import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const MobileSettingsIcon: React.FC<SvgIconProps> = ({ htmlColor = '#000', sx, ...props }) => {
  return (
    <SvgIcon viewBox="0 0 48 48" {...props} sx={{ width: '48px', height: '48px', ...sx }}>
      <defs>
        <filter colorInterpolationFilters="auto" id="a">
          <feColorMatrix
            in="SourceGraphic"
            values="0 0 0 0 0.796078 0 0 0 0 0.796078 0 0 0 0 0.796078 0 0 0 1.000000 0"
          />
        </filter>
      </defs>
      <g transform="translate(-696 -784)" filter="url(#a)" fill="none" fillRule="evenodd" opacity=".8">
        <path d="M696 784h48v48h-48z" />
        <path d="M710 824h20v2h-20zM710 790h20v2h-20z" />
        <path
          d="m739.62 809.48-1.64-1.26v-.44l1.6-1.26c.32-.24.4-.68.2-1.02l-1.7-2.96a.79.79 0 0 0-.7-.4c-.1 0-.2.02-.3.06l-1.9.76c-.16-.1-.22-.14-.38-.22l-.3-2.02a.817.817 0 0 0-.8-.72h-3.42c-.4 0-.74.3-.8.68l-.28 2.02c-.06.04-.14.06-.2.1l-.18.12-1.9-.76a.803.803 0 0 0-1 .34l-1.7 2.96c-.2.34-.12.78.2 1.02l1.6 1.26v.46l-1.6 1.26a.78.78 0 0 0-.2 1.02l1.7 2.96c.14.26.42.4.7.4.1 0 .2-.02.3-.06l1.9-.74c.16.1.24.14.4.22l.3 2.02c.06.4.4.68.8.68h3.42c.4 0 .74-.3.8-.68l.3-2.02c.06-.04.14-.06.2-.1l.18-.12 1.9.76a.803.803 0 0 0 1-.34l1.7-2.96a.78.78 0 0 0-.2-1.02zM732 811c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3zm-2 9h-20v-24h20v2h4v-8c0-2.2-1.8-4-4-4h-20c-2.2 0-4 1.8-4 4v36c0 2.2 1.8 4 4 4h20c2.2 0 4-1.8 4-4v-8h-4v2zm-20-30h20v2h-20v-2zm20 36h-20v-2h20v2z"
          fill={htmlColor}
          fillRule="nonzero"
        />
      </g>
    </SvgIcon>
  );
};
