import { useRecoilCallback, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useDrawer } from '@egym/ui';
import { AppPreviewerTab, PreviewerRoute } from '@components';
import { AppDesignMenuItemEnum, DesignValidationError } from '@types';
import { AppDesignMenuItemConfig } from '../AppDesignProps';
import { appDesignState } from './appDesignState';

const useAppDesignState = () => {
  const allMenuItems = useRecoilValue<AppDesignMenuItemConfig[]>(appDesignState.selectors.allMenuItems);
  const previewerLocale = useRecoilValue<string>(appDesignState.selectors.previewerLocaleApiFormat);
  const setPreviewerLocale = useSetRecoilState<string>(appDesignState.atoms.previewerLocale);
  const [isSavingChanges, setSavingChanges] = useRecoilState(appDesignState.atoms.savingChanges);
  const [isFormChanged, setFormChanged] = useRecoilState(appDesignState.atoms.formChanged);
  const [sentForReviewCount, setSentForReviewCount] = useRecoilState(appDesignState.atoms.sentForReviewCount);
  const { closeDrawer } = useDrawer();

  const tabsOrderMenuItem = useRecoilValue<AppDesignMenuItemConfig>(
    appDesignState.atoms.menuItem(AppDesignMenuItemEnum.TabsOrder),
  );

  const setAppLanguagesMenuItem = useSetRecoilState<AppDesignMenuItemConfig>(
    appDesignState.atoms.menuItem(AppDesignMenuItemEnum.AppLanguages),
  );

  const setAppLayoutMenuItem = useSetRecoilState<AppDesignMenuItemConfig>(
    appDesignState.atoms.menuItem(AppDesignMenuItemEnum.AppLayout),
  );
  const setColorThemeMenuItem = useSetRecoilState<AppDesignMenuItemConfig>(
    appDesignState.atoms.menuItem(AppDesignMenuItemEnum.ColorTheme),
  );
  const setAppIconMenuItem = useSetRecoilState<AppDesignMenuItemConfig>(
    appDesignState.atoms.menuItem(AppDesignMenuItemEnum.AppIcon),
  );
  const setSignInMenuItem = useSetRecoilState<AppDesignMenuItemConfig>(
    appDesignState.atoms.menuItem(AppDesignMenuItemEnum.SignInScreen),
  );
  const setMainTabBrandingMenuItem = useSetRecoilState<AppDesignMenuItemConfig>(
    appDesignState.atoms.menuItem(AppDesignMenuItemEnum.MainTabBranding),
  );

  const selectedMenuItem = useRecoilValue<AppDesignMenuItemConfig>(appDesignState.selectors.selectedMenuItem);
  const selectedLayoutTabMenuItem = useRecoilValue<AppPreviewerTab | undefined>(
    appDesignState.selectors.selectedLayoutTabMenuItem,
  );

  const handleMenuItemChange = useRecoilCallback(
    ({ set, snapshot }) =>
      async (value: AppDesignMenuItemEnum, appLayoutTab?: AppPreviewerTab) => {
        closeDrawer();
        const currentSelectedMenuItem = await snapshot.getPromise<AppDesignMenuItemConfig>(
          appDesignState.selectors.selectedMenuItem,
        );

        if (value === currentSelectedMenuItem.menuItemName) {
          if (appLayoutTab !== currentSelectedMenuItem.selectedLayoutTab) {
            set(appDesignState.atoms.menuItem(currentSelectedMenuItem.menuItemName), prevState => ({
              ...prevState,
              selectedLayoutTab: appLayoutTab,
              previewerProps: { ...prevState.previewerProps, activeWidgetId: null, hideLayoutRouteFooter: false },
            }));
          }
          return;
        }

        set(appDesignState.atoms.menuItem(value), prevState => ({
          ...prevState,
          selected: true,
          selectedLayoutTab: appLayoutTab,
        }));

        set(appDesignState.atoms.menuItem(currentSelectedMenuItem.menuItemName), prevState => ({
          ...prevState,
          selected: false,
          previewerProps: { ...prevState.previewerProps, activeWidgetId: null, hideLayoutRouteFooter: false },
        }));
      },
    [],
  );

  const handleSelectedPreviewerRouteChange = useRecoilCallback(({ set, snapshot }) => {
    return async (value: PreviewerRoute, previewerProps?: any) => {
      const currentSelectedMenuItem = await snapshot.getPromise<AppDesignMenuItemConfig>(
        appDesignState.selectors.selectedMenuItem,
      );
      set(appDesignState.atoms.menuItem(currentSelectedMenuItem.menuItemName), prevState => ({
        ...prevState,
        previwerRoute: value,
        previewerProps,
      }));
    };
  }, []);

  const addActiveWidgetPreviewerRouteParam = useRecoilCallback(({ set, snapshot }) => {
    return async (tabId: number, activeWidgetId: number | string, activeWidgetGroupId?: number | null) => {
      const currentSelectedMenuItem = await snapshot.getPromise<AppDesignMenuItemConfig>(
        appDesignState.selectors.selectedMenuItem,
      );

      set(appDesignState.atoms.menuItem(currentSelectedMenuItem.menuItemName), prevState => ({
        ...prevState,
        previewerProps: {
          ...prevState.previewerProps,
          tabId,
          activeWidgetId,
          activeWidgetGroupId,
          hideLayoutRouteFooter: Boolean(activeWidgetId),
        },
      }));
    };
  });

  const clearActiveWidgetPreviewerRouteParam = useRecoilCallback(({ set, snapshot }) => async () => {
    const currentSelectedMenuItem = await snapshot.getPromise<AppDesignMenuItemConfig>(
      appDesignState.selectors.selectedMenuItem,
    );

    set(appDesignState.atoms.menuItem(currentSelectedMenuItem.menuItemName), prevState => ({
      ...prevState,
      previewerProps: {
        ...prevState.previewerProps,
        activeWidgetId: null,
        activeWidgetGroupId: null,
        hideLayoutRouteFooter: false,
      },
    }));
  });

  const setReviewValidationErrors = useSetRecoilState<DesignValidationError[]>(
    appDesignState.atoms.reviewValidationErrors,
  );

  return {
    handleMenuItemChange,
    allMenuItems,
    tabsOrderMenuItem,
    selectedMenuItem,
    selectedLayoutTabMenuItem,
    isFormChanged,
    isSavingChanges,
    setFormChanged,
    setSavingChanges,
    sentForReviewCount,
    setSentForReviewCount,
    setAppLanguagesMenuItem,
    setAppLayoutMenuItem,
    setColorThemeMenuItem,
    setAppIconMenuItem,
    setSignInMenuItem,
    setMainTabBrandingMenuItem,
    handleSelectedPreviewerRouteChange,
    previewerLocale,
    setPreviewerLocale,
    addActiveWidgetPreviewerRouteParam,
    clearActiveWidgetPreviewerRouteParam,
    setReviewValidationErrors,
  };
};

export default useAppDesignState;
