import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FormikConfig } from 'formik';
import { map, mergeAll } from 'ramda';
import { customYup, toLocaleApiFormat, toLocaleFormat } from '@egym/utils';
import { defaultWidgetStrings } from '@constants';
import { LngNamespace } from 'src/i18n';
import useWidgetPreferencesForm from '../../../../hooks/useWidgetPreferencesForm';
import {
  SimpleTitleCustomizedWidgetPreferencesFormContainerProps,
  SimpleTitleCustomizedWidgetPreferencesFormikProps,
  SimpleTitleCustomizedWidgetPreferencesFormValues,
  UseSimpleTitleCustomizedWidgetPreferencesFormResult,
} from '../../SimpleTitleCustomizedWidgetPreferencesFormProps';

const useSimpleTitleCustomizedWidgetPreferencesForm = (
  props: SimpleTitleCustomizedWidgetPreferencesFormContainerProps,
  ref,
): UseSimpleTitleCustomizedWidgetPreferencesFormResult => {
  const { t } = useTranslation();
  const { currentWidget, updateWidget } = props;
  const { innerRef, appLocales, availableLanguages, openLanguagesMenuTab, onSubmit } = useWidgetPreferencesForm(
    props,
    ref,
  );

  const { t: previewerT } = useTranslation(LngNamespace.Previewer);

  const formProps = useMemo<FormikConfig<SimpleTitleCustomizedWidgetPreferencesFormValues>>(() => {
    const defaultLocalizedString = fieldName => {
      return availableLanguages.reduce((acc, value) => {
        return {
          ...acc,
          [value.locale]: previewerT(defaultWidgetStrings[currentWidget.name][fieldName], {
            lng: toLocaleFormat(value.locale),
          }),
        };
      }, {});
    };
    return {
      enableReinitialize: true,
      initialValues: {
        id: currentWidget.id,
        title: mergeAll(
          map(
            (locale: string) => ({
              [locale]: currentWidget.preferences?.title?.[locale] || defaultLocalizedString('title')[locale],
            }),
            map(locale => toLocaleApiFormat(locale), appLocales || []),
          ),
        ),
      },
      validationSchema: customYup.object().shape({
        // @ts-ignore
        title: customYup
          .object()
          .required()
          .nullable()
          .shape(
            mergeAll(
              map(
                (locale: string) => ({
                  [locale]: customYup.string().nullable().required(t('common.validation.required')),
                }),
                map(locale => toLocaleApiFormat(locale), appLocales || []),
              ),
            ),
          ),
      }),
      innerRef,
      onSubmit,
      validateOnChange: false,
      validateOnBlur: false,
      validateOnMount: false,
    };
  }, [
    currentWidget.preferences?.title,
    currentWidget.name,
    currentWidget.id,
    appLocales,
    innerRef,
    onSubmit,
    availableLanguages,
    previewerT,
    t,
  ]);

  const onFormChanged = useCallback(
    ({ values: { id, title } }: SimpleTitleCustomizedWidgetPreferencesFormikProps) => {
      updateWidget({
        id,
        preferences: { title },
      });
    },
    [updateWidget],
  );

  return {
    formProps,
    availableLanguages,
    openLanguagesMenuTab,
    onFormChanged,
  };
};

export default useSimpleTitleCustomizedWidgetPreferencesForm;
