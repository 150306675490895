import React, { ChangeEvent, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { InputAdornment } from '@mui/material';
import { Language } from '@egym/types';
import { Paper, TextField, Icons, MenuList, MenuItem, ConditionalRender } from '@egym/ui';

type Props = {
  handleClose?: () => void; // passed from Popover
  filteredPossibleLanguages: Language[];
  searchValue: string;
  onSearchChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onPossibleLanguageSelect: (selectedLocale: string) => void;
};

const PopoverContent: React.FC<Props> = ({
  handleClose,
  searchValue,
  onSearchChange,
  filteredPossibleLanguages,
  onPossibleLanguageSelect,
}) => {
  const { t } = useTranslation();

  return (
    <Paper sx={{ px: 3, pb: 3, position: 'relative', width: '230px' }}>
      <TextField
        sx={{
          position: 'sticky',
          top: '0',
          left: '0',
          py: 3,
          width: '100%',
          bgcolor: 'common.white',
        }}
        placeholder={t('common.labels.searchLanguage')}
        value={searchValue}
        onChange={onSearchChange}
        InputProps={{
          sx: {
            input: {
              pl: '0 !important',
            },
          },
          startAdornment: (
            <InputAdornment position="start">
              <Icons.Search />
            </InputAdornment>
          ),
        }}
      />

      <MenuList
        sx={{
          maxHeight: '200px',
          width: '100%',
          overflow: 'auto',
          p: 0,
          textAlign: 'center',
        }}
      >
        <ConditionalRender condition={Boolean(filteredPossibleLanguages.length)}>
          <>
            {filteredPossibleLanguages.map(({ name, icon: Icon, locale }) => {
              return (
                <MenuItem
                  key={name}
                  onClick={() => {
                    onPossibleLanguageSelect(locale);
                    if (handleClose) handleClose();
                  }}
                >
                  {Icon && <Icon sx={{ mr: 2 }} />}
                  {name}
                </MenuItem>
              );
            })}
          </>
          <ConditionalRender condition={Boolean(searchValue)}>
            {t('common.labels.noSearchResults') as ReactNode}
          </ConditionalRender>
        </ConditionalRender>
      </MenuList>
    </Paper>
  );
};

export default PopoverContent;
