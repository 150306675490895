import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const MainTabHeaderDefaultIcon8: React.FC<SvgIconProps> = ({ htmlColor = '#FFF', sx, ...props }) => {
  return (
    <SvgIcon viewBox="0 0 48 48" {...props} sx={{ width: '48px', height: '48px', ...sx }}>
      <g fill={htmlColor} fillRule="evenodd">
        <path d="M36 30c0 1.103-.897 2-2 2h-4a2 2 0 0 0-2 2v4c0 1.103-.897 2-2 2H14c-1.103 0-2-.897-2-2V10c0-1.102.897-2 2-2h20c1.103 0 2 .898 2 2v20zM34 4H14c-3.309 0-6 2.692-6 6v28c0 3.309 2.691 6 6 6h12c3.309 0 6-2.691 6-6v-2h2c3.309 0 6-2.691 6-6V10c0-3.308-2.691-6-6-6z" />
        <path
          d="M24 20.401a2.4 2.4 0 1 1 .002-4.802A2.4 2.4 0 0 1 24 20.401m4.983.936A5.957 5.957 0 0 0 30 18c0-3.308-2.691-6-6-6s-6 2.692-6 6c0 1.235.375 2.383 1.017 3.337a9.966 9.966 0 0 0-3.67 3.666 2 2 0 1 0 3.466 1.994A6 6 0 0 1 24 24a6 6 0 0 1 5.187 2.997 1.996 1.996 0 0 0 2.73.737 2 2 0 0 0 .736-2.731 9.966 9.966 0 0 0-3.67-3.666"
          opacity=".6"
        />
      </g>
    </SvgIcon>
  );
};
