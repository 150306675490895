import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const EveningIcon: React.FC<SvgIconProps> = ({ sx, htmlColor = '#4C4A4A', ...rest }) => {
  return (
    <SvgIcon viewBox="0 0 24 24" sx={{ width: '20px', height: '20px', ...sx }} {...rest}>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <path d="M0 0H24V24H0z" transform="translate(-164 -2383) translate(164 2383)" />
            <path
              fill={htmlColor}
              d="M13 20c.552 0 1 .448 1 1s-.448 1-1 1h-2c-.552 0-1-.448-1-1s.448-1 1-1zm3-4c.552 0 1 .448 1 1s-.448 1-1 1H8c-.552 0-1-.448-1-1s.448-1 1-1zM2 14c-.552 0-1-.448-1-1s.448-1 1-1h4.083C6.559 9.162 9.026 7 12 7c2.974 0 5.441 2.162 5.917 5H22c.552 0 1 .448 1 1s-.448 1-1 1h-7c-.552 0-1-.448-1-1s.448-1 1-1h.874c-.444-1.726-2.01-3-3.874-3-1.864 0-3.43 1.274-3.874 3H9c.552 0 1 .448 1 1s-.448 1-1 1zm1.54-9.423c.391-.39 1.025-.39 1.415 0L6.37 5.991c.39.39.39 1.024 0 1.414-.39.39-1.024.39-1.414 0L3.54 5.991c-.39-.39-.39-1.023 0-1.414zm15.557-.124c.39-.39 1.023-.39 1.414 0 .39.39.39 1.024 0 1.414l-1.414 1.414c-.39.39-1.024.39-1.414 0-.39-.39-.39-1.023 0-1.414zM12 1c.552 0 1 .448 1 1v2c0 .552-.448 1-1 1s-1-.448-1-1V2c0-.552.448-1 1-1z"
              transform="translate(-164 -2383) translate(164 2383)"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export default EveningIcon;
