import { atom } from 'recoil';
import { FormDialogConfig } from '../types';

const keys = {
  formDialogConfig: 'formDialogGlobalState.formDialogConfig',
};

const atoms = {
  formDialogConfig: atom<FormDialogConfig | null>({
    key: keys.formDialogConfig,
    default: null,
  }),
};

export const formDialogGlobalState = {
  keys,
  atoms,
};
