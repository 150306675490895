import { invertObj } from 'ramda';
import { PreviewGroupWebWidget } from './components';
import PreviewActivityFeed from './components/PreviewActivityFeed';
import PreviewActivityHistory from './components/PreviewActivityHistory';
import PreviewActivityLevel from './components/PreviewActivityLevel';
import PreviewActivityLevelOnTheTab from './components/PreviewActivityLevelOnTheTab';
import PreviewBioAge from './components/PreviewBioAge';
import PreviewBioAgeOnTheTab from './components/PreviewBioAgeOnTheTab';
import PreviewChallenges from './components/PreviewChallenges';
import PreviewConnectedApps from './components/PreviewConnectedApps';
import PreviewDeals from './components/PreviewDeals';
import PreviewExtras from './components/PreviewExtras';
import PreviewFindAClass from './components/PreviewFindAClass';
import PreviewFindAClassOnTheTab from './components/PreviewFindAClassOnTheTab';
import PreviewGoalCenter from './components/PreviewGoalCenter';
import PreviewGuestPass from './components/PreviewGuestPass';
import PreviewLocationFinder from './components/PreviewLocationFinder';
import PreviewClassNotification from './components/PreviewNotificationCenter';
import PreviewRanking from './components/PreviewRanking';
import PreviewRankingOnTheTab from './components/PreviewRankingOnTheTab';
import PreviewReferAFriend from './components/PreviewReferAFriend';
import PreviewRequestTrainer from './components/PreviewRequestTrainer';
import PreviewRewards from './components/PreviewRewards';
import PreviewTrainingPlans from './components/PreviewTrainingPlans';
import PreviewWebWidget from './components/PreviewWebWidget';
import { AppLayoutFeatureEnum } from './enums';

export const appLayoutFeatureMap = {
  [AppLayoutFeatureEnum.PreviewTrainingPlans]: PreviewTrainingPlans,
  [AppLayoutFeatureEnum.PreviewActivityHistory]: PreviewActivityHistory,
  // [AppLayoutFeatureEnum.PreviewWorkoutTracking]: PreviewWorkoutTracking,
  [AppLayoutFeatureEnum.PreviewConnectedApps]: PreviewConnectedApps,
  [AppLayoutFeatureEnum.PreviewNotificationCenter]: PreviewClassNotification,
  [AppLayoutFeatureEnum.PreviewFindAClass]: PreviewFindAClass,
  [AppLayoutFeatureEnum.PreviewChallenges]: PreviewChallenges,
  [AppLayoutFeatureEnum.PreviewExtras]: PreviewExtras,
  [AppLayoutFeatureEnum.PreviewLocationFinder]: PreviewLocationFinder,
  [AppLayoutFeatureEnum.PreviewActivityFeed]: PreviewActivityFeed,
  [AppLayoutFeatureEnum.PreviewActivityLevel]: PreviewActivityLevel,
  [AppLayoutFeatureEnum.PreviewRanking]: PreviewRanking,
  [AppLayoutFeatureEnum.PreviewBioAge]: PreviewBioAge,
  // [AppLayoutFeatureEnum.PreviewExploreSchedule]: PreviewExploreSchedule,
  [AppLayoutFeatureEnum.PreviewDeals]: PreviewDeals,
  // [AppLayoutFeatureEnum.PreviewVirtualClasses]: PreviewVirtualClasses,
  [AppLayoutFeatureEnum.PreviewRequestTrainer]: PreviewRequestTrainer,
  [AppLayoutFeatureEnum.PreviewGoalCenter]: PreviewGoalCenter,
  [AppLayoutFeatureEnum.PreviewActivityLevelOnTheTab]: PreviewActivityLevelOnTheTab,
  [AppLayoutFeatureEnum.PreviewBioAgeOnTheTab]: PreviewBioAgeOnTheTab,
  [AppLayoutFeatureEnum.PreviewFindAClassOnTheTab]: PreviewFindAClassOnTheTab,
  // [AppLayoutFeatureEnum.PreviewVirtualClassesOnTheTab]: PreviewVirtualClassesOnTheTab,
  [AppLayoutFeatureEnum.PreviewReferAFriend]: PreviewReferAFriend,
  [AppLayoutFeatureEnum.PreviewRewards]: PreviewRewards,
  [AppLayoutFeatureEnum.PreviewGuestPass]: PreviewGuestPass,
  [AppLayoutFeatureEnum.PreviewRankingOnTheTab]: PreviewRankingOnTheTab,
  [AppLayoutFeatureEnum.PreviewWebWidget]: PreviewWebWidget,
  [AppLayoutFeatureEnum.PreviewGroupWebWidget]: PreviewGroupWebWidget,
};

export const compactToFullTabFeatureMap = {
  // [AppLayoutFeatureEnum.PreviewVirtualClasses]: AppLayoutFeatureEnum.PreviewVirtualClassesOnTheTab,
  [AppLayoutFeatureEnum.PreviewFindAClass]: AppLayoutFeatureEnum.PreviewFindAClassOnTheTab,
  [AppLayoutFeatureEnum.PreviewActivityLevel]: AppLayoutFeatureEnum.PreviewActivityLevelOnTheTab,
  [AppLayoutFeatureEnum.PreviewBioAge]: AppLayoutFeatureEnum.PreviewBioAgeOnTheTab,
  [AppLayoutFeatureEnum.PreviewRanking]: AppLayoutFeatureEnum.PreviewRankingOnTheTab,
};

export const fullTabToCompactFeatureMap = invertObj(compactToFullTabFeatureMap) as Record<
  AppLayoutFeatureEnum,
  AppLayoutFeatureEnum
>;
