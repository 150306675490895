import { DesignValidationError, DesignValidationErrorId } from '@types';

export const COLOR_CONTRAST_THRESHOLD = 4.5;

type Params = {
  tabId?: number;
  widgetId?: string | number;
};

export const validationErrors: Record<DesignValidationErrorId, (params: Params) => DesignValidationError> = {
  defaultTabName: ({ tabId }) => ({
    errorId: 'defaultTabName',
    message: {
      popup: 'appDesign.validation.popup.defaultTabName',
      tooltip: 'appDesign.validation.tooltip.defaultTabName',
      alert: 'appDesign.validation.alert.defaultTabName',
    },
    section: 'settings',
    tabId,
  }),
  tabIsEmpty: ({ tabId }) => ({
    errorId: 'tabIsEmpty',
    message: {
      popup: 'appDesign.validation.popup.tabIsEmpty',
      tooltip: 'appDesign.validation.tooltip.tabIsEmpty',
      alert: 'appDesign.validation.alert.tabIsEmpty',
    },
    section: 'features',
    tabId,
  }),
  widgetGroupIsNotConfigured: ({ tabId, widgetId }) => ({
    errorId: 'widgetGroupIsNotConfigured',
    message: {
      popup: 'appDesign.validation.popup.widgetGroupIsNotConfigured',
      tooltip: 'appDesign.validation.tooltip.widgetGroupIsNotConfigured',
      alert: 'appDesign.validation.alert.widgetGroupIsNotConfigured',
    },
    section: 'features',
    tabId,
    widgetId,
  }),
};
