import { FC } from 'react';
import { SxProps } from '@mui/system';
import { Box } from '@egym/ui';

type Props = {
  wrapperSx?: SxProps;
  iconLink: string;
  width?: number;
  height?: number;
};

const TabIconPreview: FC<Props> = ({ wrapperSx, iconLink, width = 24, height = 24 }) => {
  return (
    <Box
      sx={{
        backgroundColor: 'rgb(142, 139, 139)',
        mask: `url(${iconLink}) no-repeat center/contain`,
        ...wrapperSx,
      }}
      width={width}
      height={height}
    />
  );
};

export default TabIconPreview;
