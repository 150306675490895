import React, { FC, useCallback, useMemo } from 'react';
import { alpha, Stack, SvgIconProps } from '@mui/material';
import { Box } from '@egym/ui';
import { TabIconPreview } from '@components';
import { PredefinedTabIcon } from '@types';

type Props = {
  id: number;
  path: string;
  component?: FC<SvgIconProps>;
  selectedIcon?: PredefinedTabIcon;
  setSelectedIcon: (icon: PredefinedTabIcon) => void;
};

const PredefinedIcon: React.FC<Props> = ({ id, path, component: Component, selectedIcon, setSelectedIcon }) => {
  const isSelected = useMemo(() => {
    return selectedIcon?.id === id;
  }, [selectedIcon, id]);

  const onSetSelectedIcon = useCallback(() => {
    setSelectedIcon({ id, component: Component, path });
  }, [setSelectedIcon, id, Component, path]);

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      onClick={onSetSelectedIcon}
      sx={{
        width: '70px',
        height: '70px',
        cursor: 'pointer',
        p: 2.5,
        bgcolor: ({ palette }) => (isSelected ? alpha(palette.primary.main, 0.08) : 'common.white'),
        border: '1px solid',
        borderRadius: 2,
        borderWidth: isSelected ? '2px' : '1px',
        borderColor: isSelected ? 'primary.main' : 'grey.400',
        boxSizing: 'border-box',
      }}
    >
      {Component ? (
        <Box sx={{ width: '48px', height: '48px' }}>
          <Component
            sx={{ width: 'auto', height: 'auto', maxWidth: '100%', maxHeight: '100%' }}
            htmlColor={isSelected ? '#c75300' : '#757575'}
          />
        </Box>
      ) : (
        <TabIconPreview
          iconLink={path}
          wrapperSx={{ bgcolor: isSelected ? 'primary.main' : '#757575' }}
          width={32}
          height={32}
        />
      )}
    </Stack>
  );
};

export default PredefinedIcon;
