import { useCallback, useRef } from 'react';
import { isNaN } from 'formik';
import { getPreviewerScale } from '../../../../../../helpers';

const useSignInLogoResize = ({
  previewerId,
  logoWrapperRef,
  logoElementRef,
  signInBackgroundWrapperRef,
  constraintsRef,
  resizerElementRef,
  onChange,
}) => {
  const resizeDataRef = useRef<{
    startX: number;
    startY: number;
    startWidth: number;
    startHeight: number;
    ratio: number;
    scale: number;
    minDimension: number;
  }>({
    startX: 0,
    startY: 0,
    startWidth: 0,
    startHeight: 0,
    ratio: 0,
    scale: 1,
    minDimension: 17,
  });

  const doResize = useCallback(
    event => {
      if (
        logoWrapperRef.current &&
        logoElementRef.current &&
        document.defaultView &&
        signInBackgroundWrapperRef.current
      ) {
        const { startX, startWidth, ratio, scale, minDimension } = resizeDataRef.current;

        let newWidth = startWidth + event.clientX - startX;

        const constraintsRect = constraintsRef.current.getBoundingClientRect();
        const logoRect = logoElementRef.current.getBoundingClientRect();

        // const logoWrapperTransform = document.defaultView.getComputedStyle(logoWrapperRef.current).transform;

        // const translate = logoWrapperTransform.match(/[+-]?\d+(\.\d+)?/g);
        // const translateX = translate?.[4] as unknown as number;
        // const translateY = translate?.[5] as unknown as number;

        let newHeight = newWidth / ratio;

        // const newLeft = logoRect.left - containerRect.left + (translateX || 0);
        // const newTop = logoRect.top - containerRect.top + (translateY || 0);

        const logoLeft = logoRect.left - constraintsRect.left;
        const logoTop = logoRect.top - constraintsRect.top;

        const isRightBorder = newWidth + logoLeft >= constraintsRect.width;
        const isBottomBorder = newHeight + logoTop >= constraintsRect.height;

        if (isRightBorder && isBottomBorder) {
          return;
        }
        if (isRightBorder) {
          newWidth = constraintsRect.width - logoLeft;
          newHeight = newWidth / ratio;
        } else if (isBottomBorder) {
          newHeight = constraintsRect.height - logoTop;
          newWidth = newHeight * ratio;
        }
        if (!isNaN(newWidth) && !isNaN(newHeight) && newWidth > minDimension && newHeight > minDimension) {
          // eslint-disable-next-line no-param-reassign
          logoElementRef.current.style.width = `${newWidth / scale}px`;
          // eslint-disable-next-line no-param-reassign
          logoElementRef.current.style.height = `${newHeight / scale}px`;
        }
      }
    },
    [constraintsRef, logoElementRef, logoWrapperRef, signInBackgroundWrapperRef],
  );

  const stopResize = useCallback(() => {
    document.documentElement.removeEventListener('pointermove', doResize, false);
    document.documentElement.removeEventListener('pointerup', stopResize, false);
    onChange();
  }, [doResize, onChange]);

  const initResize = useCallback(
    event => {
      const scale = getPreviewerScale(previewerId);

      resizeDataRef.current.startX = event.clientX;
      resizeDataRef.current.startY = event.clientY;
      resizeDataRef.current.scale = scale;
      if (logoElementRef.current && document.defaultView) {
        resizeDataRef.current.startWidth =
          parseInt(document.defaultView.getComputedStyle(logoElementRef.current).width, 10) * scale;
        resizeDataRef.current.startHeight =
          parseInt(document.defaultView.getComputedStyle(logoElementRef.current).height, 10) * scale;
        resizeDataRef.current.ratio = resizeDataRef.current.startWidth / resizeDataRef.current.startHeight;
      }
      document.documentElement.addEventListener('pointermove', doResize, false);
      document.documentElement.addEventListener('pointerup', stopResize, false);
    },
    [doResize, logoElementRef, previewerId, stopResize],
  );

  const init = useCallback(() => {
    if (resizerElementRef.current) resizerElementRef.current.addEventListener('mousedown', initResize, false);
  }, [initResize, resizerElementRef]);

  return {
    init,
  };
};

export default useSignInLogoResize;
