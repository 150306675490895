import { BreakpointsOptions } from '@mui/system';

const getBreakpoints = (): BreakpointsOptions => {
  return {
    values: {
      xl: 1536,
      lg: 1264,
      md: 900,
      sm: 640,
      xs: 0,
    },
  };
};

export default getBreakpoints;
