import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Stack } from '@egym/ui';
import { AppLayoutFeatureComponentProps } from '../../../../AppPreviewerProps';
import PreviewContainer from '../../../PreviewContainer';
import PreviewFeatureTitle from '../../../PreviewFeatureTitle';
import { mockHealthBenefits } from './data';
import PreviewHealthBenefitItem from './PreviewHealthBenefitItem';

const PreviewHealthBenefits: React.FC<AppLayoutFeatureComponentProps> = ({ tFunction, palette, wrapperSx }) => {
  const [slideActiveIndex, setSlideActiveIndex] = useState<number>(1);

  return (
    <div>
      <PreviewContainer wrapperSx={wrapperSx}>
        <PreviewFeatureTitle tFunction={tFunction} palette={palette} title="appPreviewer.labels.healthBenefits" />
      </PreviewContainer>

      <Stack>
        <Swiper
          style={{ padding: '0 11.25px', maxWidth: '100%', boxSizing: 'border-box' }}
          onActiveIndexChange={s => setSlideActiveIndex(s.activeIndex)}
          slidesPerView={1}
          initialSlide={1}
          centeredSlides
          spaceBetween={5}
        >
          {mockHealthBenefits.map(it => {
            return (
              <SwiperSlide key={it.id} style={{ width: '90%' }}>
                <PreviewHealthBenefitItem tFunction={tFunction} key={it.id} {...it} />
              </SwiperSlide>
            );
          })}
        </Swiper>
        <Stack direction="row" alignItems="center" justifyContent="center" sx={{ mt: '10px' }} spacing="7px">
          {mockHealthBenefits.map((it, index) => {
            return (
              <div
                key={it.id}
                style={{
                  width: '7px',
                  height: '7px',
                  borderRadius: '50%',
                  background: slideActiveIndex === index ? 'rgb(0, 0, 0)' : 'rgb(234,234,234)',
                }}
              />
            );
          })}
        </Stack>
      </Stack>
    </div>
  );
};

export default PreviewHealthBenefits;
