import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const IllustrationAddFullTabFeatureAndMoveRemaining: React.FC<SvgIconProps> = ({ sx, ...props }) => {
  return (
    <SvgIcon
      viewBox="0 0 354 188"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      sx={{ width: '354px', height: '188px', ...sx }}
    >
      <g fill="none" fillRule="evenodd">
        <g transform="translate(250 3)">
          <rect stroke="#9E9E9E" strokeWidth="3" fill="#FFF" x="-1.5" y="-1.5" width="104" height="185" rx="7" />
          <rect fill="#CECECE" x="7" y="7" width="87" height="152" rx="4" />
          <rect fill="#EDEDED" x="15" y="19" width="32" height="6" rx="3" />
          <rect fill="#EDEDED" x="15" y="31" width="46" height="6" rx="3" />
          <rect fill="#CECECE" x="15" y="167" width="12" height="8" rx="3" />
          <rect fill="#CECECE" x="45" y="167" width="12" height="8" rx="3" />
          <rect fill="#CECECE" x="75" y="167" width="12" height="8" rx="3" />
        </g>
        <g transform="translate(3 3)">
          <rect stroke="#9E9E9E" strokeWidth="3" fill="#FFF" x="-1.5" y="-1.5" width="104" height="185" rx="7" />
          <g transform="translate(7 7)">
            <rect fill="#CECECE" width="87" height="44" rx="4" />
            <rect fill="#EDEDED" x="8" y="12" width="32" height="6" rx="3" />
            <rect fill="#EDEDED" x="8" y="24" width="46" height="6" rx="3" />
            <g transform="translate(0 52)">
              <rect stroke="#EDEDED" strokeWidth="4" fill="#FFF" x="2" y="2" width="83" height="40" rx="4" />
              <rect fill="#EDEDED" x="8" y="12" width="59" height="6" rx="3" />
              <rect fill="#EDEDED" x="8" y="24" width="32" height="6" rx="3" />
            </g>
            <rect stroke="#EDEDED" strokeWidth="4" x="2" y="106" width="83" height="40" rx="4" />
            <rect fill="#EDEDED" x="8" y="116" width="32" height="6" rx="3" />
            <rect fill="#EDEDED" x="8" y="128" width="46" height="6" rx="3" />
          </g>
          <rect fill="#CECECE" x="15" y="167" width="12" height="8" rx="3" />
          <rect fill="#CECECE" x="45" y="167" width="12" height="8" rx="3" />
          <rect fill="#CECECE" x="75" y="167" width="12" height="8" rx="3" />
        </g>
        <path
          d="M119 88a7.002 7.002 0 0 1 6.93 6H128v2l-2.071.001A7.002 7.002 0 0 1 112 95a7 7 0 0 1 7-7zm0 2a5 5 0 1 0 0 10 5 5 0 0 0 0-10zm15 4v2h-3v-2h3zm6 0v2h-3v-2h3zm6 0v2h-3v-2h3zm6 0v2h-3v-2h3zm6 0v2h-3v-2h3zm6 0v2h-3v-2h3zm6 0v2h-3v-2h3zm5.741-.155.495 1.938c-.45.115-.913.185-1.383.208L174.5 96H173v-2h1.5c.424 0 .84-.052 1.241-.155zm3.542-3.382 1.913.584a6.994 6.994 0 0 1-1.603 2.755l-.226.23-1.39-1.438a4.999 4.999 0 0 0 1.306-2.131zm2.217-5.598v3h-2v-3h2zm0-6v3h-2v-3h2zm0-6v3h-2v-3h2zm0-6v3h-2v-3h2zm0-6v3h-2v-3h2zm0-6v3h-2v-3h2zm0-6v3h-2v-3h2zm0-6v3h-2v-3h2zm-1.876-6.182 1.965.374c-.04.206-.066.414-.08.625L181.5 38v1.865h-2V38c0-.445.042-.886.124-1.317zM228 25l14 7-14 7V25zm-43.98 6.452.709 1.87a5.008 5.008 0 0 0-1.854 1.234l-.188.21-1.525-1.295a7.012 7.012 0 0 1 2.858-2.019zM190.27 31v2h-3v-2h3zm6 0v2h-3v-2h3zm6 0v2h-3v-2h3zm6 0v2h-3v-2h3zm6 0v2h-3v-2h3zm6 0v2h-3v-2h3zm6 0v2h-3v-2h3z"
          fill="#757575"
          fillRule="nonzero"
        />
        <circle cx="10" cy="10" r="10" fill="#C75300" transform="translate(79.5 57)" />
        <g transform="translate(79.5 62)">
          <circle fill="#C75300" cx="10" cy="58" r="10" />
          <g fill="#FFF">
            <path d="M7.5 2v7h-2V4a2 2 0 0 1 2-2z" />
            <path d="M5.5 4v1h6V2h-4a2 2 0 0 0-2 2zM11.5 0v7l4-3.5z" />
          </g>
          <g fill="#FFF">
            <path d="M7.5 55v7h-2v-5a2 2 0 0 1 2-2z" />
            <path d="M5.5 57v1h6v-3h-4a2 2 0 0 0-2 2zM11.5 53v7l4-3.5z" />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};
