import { AppLayoutFeatureEnum, AppPreviewerTab } from '@components';
import { getAddedWidgetsByName } from '@helpers';
import { AppDesignLayoutTabWidget, RemainingWidget } from '@types';
import { editableFeatureConfigs } from '../../constants';

export const getRemainingWidgetsToEdit = (remainingWidgets: RemainingWidget[], newAppLayoutTabs: AppPreviewerTab[]) => {
  const getNewAddedWidgets = getAddedWidgetsByName(newAppLayoutTabs);

  let remainingWidgetsToEdit = remainingWidgets
    .filter(({ shouldBeRemoved, prevTabId, newTabId }) => !shouldBeRemoved && prevTabId && newTabId)
    .map(widgetToEdit => {
      const addedWidgets = getNewAddedWidgets(widgetToEdit.props.name);
      const addedWidget = addedWidgets.find(it => it.id === widgetToEdit.props.id) || addedWidgets[0];

      return { ...widgetToEdit, props: addedWidget };
    })
    .filter(it => it.props);

  const newTabsIds = remainingWidgetsToEdit.map(it => it.newTabId);

  // if NOTIFICATIONS_CENTER moved to another tab, then edit features that were on a new tab as well, to update their order prop.
  const hasRemainingWidgetWithNotification = remainingWidgetsToEdit.some(
    remainingWidget => remainingWidget.props?.name === AppLayoutFeatureEnum.PreviewNotificationCenter,
  );

  // siblingWidgetId === 0 means it should append at the top
  const hasRemainingWidgetAtTheTop = remainingWidgetsToEdit.some(
    remainingWidget => remainingWidget.siblingWidgetId === 0,
  );

  if (hasRemainingWidgetWithNotification || hasRemainingWidgetAtTheTop) {
    const tabWithNotifications = hasRemainingWidgetWithNotification
      ? newAppLayoutTabs.find(tab =>
          tab.features.some(it => it.props.name === AppLayoutFeatureEnum.PreviewNotificationCenter),
        )
      : undefined;

    const destinationTabsFeatures = hasRemainingWidgetAtTheTop
      ? newAppLayoutTabs.filter(tab => newTabsIds.includes(tab.id)).flatMap(tab => tab.features)
      : [];

    const auxFeaturesToEdit =
      [...(tabWithNotifications?.features || []), ...destinationTabsFeatures].filter(
        feature => !remainingWidgetsToEdit.some(it => it.props?.id === feature.props.id),
      ) || [];

    // @ts-ignore
    remainingWidgetsToEdit = [...remainingWidgetsToEdit, ...auxFeaturesToEdit];
  }

  return remainingWidgetsToEdit.reduce<RemainingWidget[]>((acc, it) => {
    if (it.props.name === AppLayoutFeatureEnum.PreviewGroupWebWidget) {
      const newInnerWidgets = it.props?.preferences?.innerWidgets.map(innerWidget => {
        return {
          ...innerWidget,
          tabId: it.newTabId || it.props.tabId,
        };
      });

      return [...acc, it, ...newInnerWidgets];
    }

    return [...acc, it];
  }, []);
};

export const getIsFeatureEditable = (widgetName: AppLayoutFeatureEnum) =>
  Object.keys(editableFeatureConfigs).includes(widgetName);

export const getCanResetFeature = (widget: AppDesignLayoutTabWidget) =>
  widget.preferences && getIsFeatureEditable(widget.name) && widget.name !== AppLayoutFeatureEnum.PreviewWebWidget;

export const getFeatureEditIcon = (widgetName: AppLayoutFeatureEnum) =>
  editableFeatureConfigs[widgetName]?.editIconComponent;
