import React, { CSSProperties, FC, useMemo } from 'react';
import { ConditionalRender } from '@egym/ui';
import { AppLayoutFeatureComponentProps } from '@components';
import { Orientation, WidgetViewStyle } from '@types';
import HorizontalContent from './components/HorizontalContent';
import VerticalContent from './components/VerticalContent';

type Props = Pick<AppLayoutFeatureComponentProps, 'widgetProps' | 'palette' | 'previewerState' | 'tFunction'> & {
  title?: string;
  titleKey?: string;
  titleFallback?: string;
  orientation?: Orientation;
  styles?: CSSProperties;
  defaultImagePath?: string;
  defaultViewStyle?: WidgetViewStyle;
};

const WebWidgetContent: FC<Props> = ({
  styles,
  widgetProps,
  palette,
  title,
  titleKey,
  titleFallback,
  orientation,
  tFunction,
  previewerState: { selectedLocale },
  defaultImagePath,
  defaultViewStyle,
}) => {
  const viewStyle = useMemo(
    () => widgetProps?.preferences?.style || defaultViewStyle,
    [defaultViewStyle, widgetProps?.preferences?.style],
  );

  const imagePath = useMemo<string | null>(() => {
    if (widgetProps?.preferences?.asset?.file) {
      return widgetProps?.preferences?.asset?.file?.preview
        ? widgetProps.preferences.asset.file.preview
        : URL.createObjectURL(widgetProps.preferences.asset.file);
    }
    if (typeof widgetProps?.preferences?.asset?.link === 'string') {
      const { link } = widgetProps.preferences.asset;
      return link.startsWith('/') ? link : `/${link}`;
    }
    if (defaultImagePath) {
      return defaultImagePath;
    }
    return null;
  }, [widgetProps?.preferences?.asset, defaultImagePath]);

  const background = useMemo(() => {
    switch (viewStyle) {
      case WidgetViewStyle.Image:
        return imagePath ? `url(${imagePath}) no-repeat center/cover` : palette.primary;
      case WidgetViewStyle.Icon:
        return widgetProps?.preferences?.assetColor ? widgetProps?.preferences?.assetColor : palette.primary;
      default:
        return undefined;
    }
  }, [imagePath, palette.primary, viewStyle, widgetProps?.preferences?.assetColor]);

  const titleResult = useMemo(() => {
    return (
      title ||
      widgetProps?.preferences?.[titleKey as string]?.[selectedLocale as string] ||
      widgetProps?.preferences?.[titleKey as string]?.en_US ||
      tFunction(titleFallback as string)
    );
  }, [selectedLocale, tFunction, title, titleFallback, titleKey, widgetProps?.preferences]);

  return (
    <ConditionalRender condition={orientation === Orientation.Horizontal}>
      <HorizontalContent
        background={background}
        viewStyle={viewStyle}
        assetColor={widgetProps?.preferences?.assetColor}
        title={titleResult}
        imagePath={imagePath}
        styles={styles}
      />
      <VerticalContent
        background={background}
        viewStyle={viewStyle}
        assetColor={widgetProps?.preferences?.assetColor}
        callToActionProps={{
          title: titleResult,
          withArrow: !orientation,
        }}
        imagePath={imagePath}
        styles={styles}
      />
    </ConditionalRender>
  );
};

export default WebWidgetContent;
