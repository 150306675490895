import { Components } from '@mui/material/styles';

const MuiAutocomplete: Components['MuiAutocomplete'] = {
  styleOverrides: {
    popupIndicator: {
      color: 'inherit',
    },
    input: {
      padding: '3.5px 4px 3.5px 6px !important',
    },
  },
};

export default MuiAutocomplete;
