import { RGBColor } from 'react-color';
import Color from 'color';
import { take } from 'ramda';
import { SketchPickerProps } from 'react-color/lib/components/sketch/Sketch';
import { GradientPickerType, GradientPoint } from '../../types';

// linear-gradient(45deg,rgba(117, 49, 117, 1) 0%,rgba(52, 178, 182, 1) 10%)
// radial-gradient(rgba(105, 152, 0, 1) 0%,rgba(50, 50, 50, 1) 100%)

export const getGradientStringPoints = (gradientString): GradientPoint[] => {
  const rgbaColors = gradientString.match(/rgba\(.*?\)/g).map(rgbaString => {
    return Color(rgbaString).object();
  });
  const leftPercent = ['0%', '100%']; // gradientString.match(/\d+%/g); - to parse points from gradient string

  return rgbaColors.map((rgbaColorObj, index) => {
    return {
      rgb: { ...rgbaColorObj, a: rgbaColorObj.alpha || 1 },
      left: leftPercent[index].replace('%', ''),
    };
  });
};

const getPointsFromPlainColor = (value, presetColors: { color: string }[]): GradientPoint[] => {
  let pointsRes: GradientPoint[] = [];

  if (!value && presetColors.length) {
    pointsRes = take(2, presetColors).map((presetColor, index) => {
      const presetColorObj = Color(presetColor.color).object() as Omit<RGBColor, 'a'> & { alpha: number };

      return {
        rgb: {
          ...presetColorObj,
          a: presetColorObj.alpha || 1,
        },
        left: !index ? 0 : 100,
      };
    });
  } else if (typeof value === 'object') {
    pointsRes.push({
      rgb: {
        r: value.r,
        g: value.g,
        b: value.b,
        a: value.a || 1,
      },
      left: 0,
    });

    const nextColor = (presetColors[1] || presetColors[0])?.color || 'rgba(0, 0, 0, 1)';

    const nextColorObj = Color(nextColor).object() as Omit<RGBColor, 'a'> & { alpha: number };

    pointsRes.push({
      rgb: {
        ...nextColorObj,
        a: nextColorObj.alpha || 1,
      },
      left: 100,
    });
  } else {
    pointsRes.push({
      rgb: {
        r: 255,
        g: 255,
        b: 255,
        a: 1,
      },
      left: 0,
    });
    pointsRes.push({
      rgb: {
        r: 0,
        g: 0,
        b: 0,
        a: 1,
      },
      left: 100,
    });
  }

  return pointsRes;
};

const getPoints = (value, presetColors) => {
  let pointsRes: GradientPoint[] = [];

  if (!value || typeof value === 'object') {
    pointsRes = getPointsFromPlainColor(value, presetColors);
  } else if (value.includes('gradient')) {
    pointsRes = getGradientStringPoints(value);
  }

  return pointsRes;
};

const getGradientDegree = gradientString => {
  const degreesString = gradientString.match(/\d+deg/g)?.[0];

  return degreesString?.replace('deg', '');
};

export const getGradientPropsFromString = (value, presetColors: SketchPickerProps['presetColors']) => {
  const points = getPoints(value, presetColors);
  const gradientDegree = value?.includes && value?.includes('gradient') ? getGradientDegree(value) : 0;
  const gradientType =
    value?.includes && value?.includes('radial') ? GradientPickerType.Radial : GradientPickerType.Linear;

  return {
    points,
    gradientDegree,
    gradientType,
  };
};
