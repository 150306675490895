import React, { ReactNode } from 'react';
import { Box } from '@mui/material';
import { SxProps } from '@mui/system';
import { TFunction } from 'i18next';
import { Icons } from '@egym/ui';
import { AppPreviewerPalette } from '../../AppPreviewerProps';

type Props = {
  tFunction: TFunction;
  palette: Partial<AppPreviewerPalette>;
  title: string;
  titleParams?: Record<string, string>;
  withViewAll?: boolean;
  wrapperSx?: SxProps;
};

const PreviewFeatureTitle: React.FC<Props> = ({ tFunction, palette, title, titleParams, withViewAll, wrapperSx }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: '7px',
        ...wrapperSx,
      }}
    >
      <span style={{ color: 'rgb(76, 74, 74)', fontSize: '16px', lineHeight: '22px', fontWeight: 600 }}>
        {tFunction(title, titleParams) as ReactNode}
      </span>
      {withViewAll && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '20px',
            color: palette.primary,
          }}
        >
          <span style={{ fontSize: '14px', letterSpacing: -0.3 }}>
            {tFunction('appPreviewer.labels.viewAll') as ReactNode}
          </span>
          <Icons.KeyboardArrowRight sx={{ mt: '2px' }} fontSize="inherit" />
        </div>
      )}
    </Box>
  );
};

export default PreviewFeatureTitle;
