import React, { ReactNode } from 'react';
import { alpha } from '@mui/material';
import { differenceInCalendarDays } from 'date-fns';
import { TFunction } from 'i18next';
import { Box, Icons, Paper, Stack } from '@egym/ui';
import { AppPreviewerPalette } from '../../../../AppPreviewerProps';
import { TabWorkoutsIcon2 } from '../../../Icons';
import styles from './PreviewChallengeCard.module.scss';

type Props = {
  tFunction: TFunction;
  palette: Partial<AppPreviewerPalette>;
  name: string;
  description: string;
  date: Date;
};

const PreviewChallengeCard: React.FC<Props> = ({ tFunction, palette, name, date }) => {
  return (
    <Paper
      sx={{
        position: 'relative',
        overflow: 'hidden',
        borderRadius: '8px',
        boxShadow: '0 4px 6px rgba(223, 223, 223, 0.7)',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '12px',
          left: '10px',
          background: 'rgb(63, 175, 150)',
          px: '8px',
          py: '3px',
          fontSize: '11px',
          color: 'white',
          borderRadius: '4px',
          fontWeight: 500,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
        }}
      >
        <Icons.Check sx={{ mr: '1px' }} fontSize="small" />
        {tFunction('appPreviewer.labels.joined') as ReactNode}
      </Box>
      <Box
        component="img"
        sx={{ height: '110px', width: '100%', objectFit: 'cover', objectPosition: 'topp' }}
        src="/images/previewerDefaultImages/features/challenges1.png"
      />
      <Box sx={{ color: 'rgb(76, 74, 74)', fontSize: '13px', p: '10px' }}>
        <Stack>
          <span
            style={{
              fontSize: '15px',
              fontWeight: 600,
              lineHeight: '20px',
              marginBottom: '4px',
              color: 'rgb(33, 33, 33)',
            }}
          >
            {tFunction(name) as ReactNode}
          </span>
          <Stack direction="row" alignItems="center" spacing={1.5}>
            <span
              style={{
                fontSize: '11px',
                fontWeight: 600,
                lineHeight: '16px',
                color: 'rgb(76, 74, 74)',
                background: 'rgb(244, 244, 244)',
                padding: '2px 6px',
                borderRadius: '4px',
                width: 'fit-content',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <TabWorkoutsIcon2 sx={{ width: '16px', height: '16px', mr: '3px' }} htmlColor="#4a4c4c" />
              {tFunction('appPreviewer.labels.workouts') as ReactNode}
            </span>
            <span style={{ fontSize: '11px', fontWeight: 600 }}>
              {
                tFunction('appPreviewer.labels.startInShort', {
                  count: differenceInCalendarDays(date, new Date()),
                }) as ReactNode
              }
            </span>
          </Stack>
        </Stack>
        <Box sx={{ height: '1px', my: '10px', backgroundColor: 'rgba(151, 151, 151, .2)' }} />
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Stack display="inline-flex" direction="row" className={styles.rankingWrapper}>
            <img
              style={{ width: '32px', height: '32px', border: '2px solid white', borderRadius: '50%' }}
              src="/images/previewerDefaultImages/avatars/@2ximg3.png"
              alt="1st"
            />
            <img
              style={{ width: '32px', height: '32px', border: '2px solid white', borderRadius: '50%' }}
              src="/images/previewerDefaultImages/avatars/@2ximg2.png"
              alt="1st"
            />
            <div
              style={{
                width: '32px',
                height: '32px',
                lineHeight: '32px',
                borderRadius: '50%',
                border: '2px solid white',
                background: 'rgb(224, 224, 224)',
                color: 'rgb(0, 0, 0)',
                textAlign: 'right',
                fontSize: '8px',
              }}
            >
              <span style={{ paddingRight: '5px' }}>28+</span>
            </div>
          </Stack>
          <span
            style={{
              textTransform: 'lowercase',
              fontSize: '11px',
              fontWeight: 500,
              marginRight: 'auto',
              transform: 'translateX(-18px)',
            }}
          >
            {tFunction('appPreviewer.labels.joined') as ReactNode}
          </span>

          <Paper
            sx={{
              px: '12px',
              py: '6px',
              background: palette.primary ? alpha(palette.primary, 0.1) : undefined,
              color: palette.primary,
              fontSize: '15px',
              fontWeight: 500,
            }}
            elevation={0}
          >
            {tFunction('appPreviewer.labels.leaderboard') as ReactNode}
          </Paper>
        </Stack>
      </Box>
    </Paper>
  );
};

export default PreviewChallengeCard;
