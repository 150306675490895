import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const ThThFlagIcon: React.FC<SvgIconProps> = ({ sx, ...props }) => {
  return (
    <SvgIcon viewBox="0 0 30 20" {...props} sx={{ width: '18px', ...sx }}>
      <g id="Thailand_flag" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect id="Rectangle" fill="#A51A32" x="0" y="0" width="30" height="3" />
        <rect
          id="Rectangle-Copy-3"
          fill="#A51A32"
          transform="translate(15.000000, 18.500000) rotate(-180.000000) translate(-15.000000, -18.500000) "
          x="-1.11910481e-13"
          y="17"
          width="30"
          height="3"
        />
        <rect id="Rectangle-Copy" fill="#F4F5F8" x="0" y="3" width="30" height="3" />
        <rect
          id="Rectangle-Copy-4"
          fill="#F4F5F8"
          transform="translate(15.000000, 15.500000) rotate(-180.000000) translate(-15.000000, -15.500000) "
          x="-1.19015908e-13"
          y="14"
          width="30"
          height="3"
        />
        <rect id="Rectangle-Copy-2" fill="#2C2A4A" x="0" y="6" width="30" height="8" />
      </g>
    </SvgIcon>
  );
};

export default ThThFlagIcon;
