import React, { forwardRef, ReactNode, useState } from 'react';
import { SwitchChildren, SwitchChildrenItem } from '@egym/ui';
import { AppLayoutFeatureComponentProps } from '../../../../AppPreviewerProps';
import PreviewContainer from '../../../PreviewContainer';
import PreviewFeatureTitle from '../../../PreviewFeatureTitle';
import { PreviewTabs, PreviewTab } from '../../../PreviewTabs';
import ClassItem from './ClassItem';
import { bookedClasses, upcomingClasses } from './data';

const PreviewFindAClass = forwardRef<HTMLDivElement, AppLayoutFeatureComponentProps>(
  ({ tFunction, palette, previewerState, wrapperSx, widgetProps }, ref) => {
    const [selectedTab, setSelectedTab] = useState<number>(0);

    return (
      <PreviewContainer ref={ref} wrapperSx={wrapperSx}>
        <PreviewFeatureTitle
          tFunction={tFunction}
          palette={palette}
          title={
            widgetProps?.preferences?.title?.[previewerState.selectedLocale as string] ??
            widgetProps?.preferences?.title?.en_US ??
            'appPreviewer.labels.defaultFeaturesTitles.classes'
          }
          withViewAll
        />
        <PreviewTabs
          palette={palette}
          variant="fullWidth"
          value={selectedTab}
          onChange={(event: React.SyntheticEvent, newValue: number) => {
            setSelectedTab(newValue);
          }}
        >
          <PreviewTab palette={palette} label={tFunction('appPreviewer.labels.upcoming') as ReactNode} />
          <PreviewTab palette={palette} label={tFunction('appPreviewer.labels.booked') as ReactNode} />
        </PreviewTabs>
        <SwitchChildren value={selectedTab}>
          <SwitchChildrenItem value={0}>
            {upcomingClasses.map(upcomingClass => {
              return (
                <ClassItem
                  tFunction={tFunction}
                  key={upcomingClass.id}
                  {...upcomingClass}
                  bookedClass={false}
                  palette={palette}
                  previewerState={previewerState}
                />
              );
            })}
          </SwitchChildrenItem>
          <SwitchChildrenItem value={1}>
            {bookedClasses.map(bookedClass => {
              return (
                <ClassItem
                  tFunction={tFunction}
                  key={bookedClass.id}
                  {...bookedClass}
                  bookedClass
                  palette={palette}
                  previewerState={previewerState}
                />
              );
            })}
          </SwitchChildrenItem>
        </SwitchChildren>
      </PreviewContainer>
    );
  },
);

export default PreviewFindAClass;
