import { FC, RefObject } from 'react';
import { SxProps } from '@mui/system';
import { TFunction } from 'i18next';
import { Uuid } from '@egym/types';
import { AppDesignLayoutTabType, AppDesignLayoutTabWidget, ApplicationLayout, ApplicationPackage } from '@types';

export enum PreviewerRoute {
  Layout = 'app-previwer/:package/:layout/:tab',
  AppIconIos = 'app-previwer/app-icon/ios',
  AppIconAndroid = 'app-previwer/app-icon/android',
  SignInScreen = 'app-previewer/sign-in-screen',
  Profile = 'app-previewer/profile',
  WellDone = 'app-previewer/well-done',
  FindClass = 'app-previewer/find-class',
  About = 'app-previewer/about',
}

export type AppPreviewerRouterProps = {
  route: PreviewerRoute;
};

export type AppPreviewerSizeProps = {
  size: 'x-small' | 'small' | 'medium' | 'large' | 'x-large';
};

export type AppPreviewerPalette = {
  primary: string;
  secondary: string;
  dashboard3BackgroundPrimary?: string;
  dashboard3BackgroundSecondary?: string;
  primaryTransparent?: string;
};

export type AppPreviewerTab = {
  id: number;
  name: Record<string, string>;
  nameTranslated?: string;
  type: AppDesignLayoutTabType;
  icon: string;
  iconPredefinedId?: number;
  order: number;
  isDemoTab?: boolean;
  features: {
    props: AppDesignLayoutTabWidget;
    component: FC<AppLayoutFeatureComponentProps>;
  }[];
  isDefaultTab?: boolean;
  selected?: boolean;
  defaultTitleKey?: string;
};

export type PreviewerState = {
  route: PreviewerRoute;
  palette: AppPreviewerPalette;
  size?: AppPreviewerSizeProps['size'];
  selectedTab?: number;
  appPackage?: ApplicationPackage;
  appLayout?: ApplicationLayout;
  mainTabIconLink?: string | null;
  appIconLink?: string | null;
  appIconBackground?: string | null;
  iosAppIconName?: string | null;
  selectedLocale?: string;
  signInScreenProps?: {
    signInLogo?: string | null;
    signInLogoDisabled?: boolean;
    signInPredefinedImageId?: number | null;
    signInBackgroundImage?: string | null;
    signInBackgroundImageOverlayColor?: string;
    signInLogoPositionLeft?: number;
    signInLogoPositionTop?: number;
    signInLogoWidth?: number;
    signInLogoHeight?: number;
    signInLogoRotate?: number;
  };
  disabled?: boolean;
};

export type AppLayoutFeatureComponentProps = {
  previewerId?: string;
  activeWidgetId?: number | Uuid | null;
  activeWidgetGroupId?: number | null;
  palette: Partial<AppPreviewerPalette>;
  previewerState: Partial<PreviewerState>;
  widgetProps?: Partial<AppDesignLayoutTabWidget>;
  tFunction: TFunction;
  wrapperSx?: SxProps;
  itemWrapperSx?: SxProps;
};

export type AppPreviewerContainerProps = {
  id: string;
  wrapperSx?: SxProps;
  route: AppPreviewerRouterProps['route'];
  size: AppPreviewerSizeProps['size'];
  appPackage?: ApplicationPackage;
  appLayout?: ApplicationLayout;
  locales?: string[] | null;
  selectedLocale?: string;
  onChangeSelectedLocale?: (locale: string, source?: string) => void;
  mainTabHeaderIcon?: string | File[] | null;
  appIconLink?: string | File[] | null;
  appIconBackground?: string | null;
  iosAppIconName?: string | null;
  signInScreenProps?: {
    signInLogo?: string | File[] | null;
    signInLogoDisabled?: boolean;
    signInPredefinedImageId?: number | null;
    signInBackgroundImage?: string | File | File[] | null;
    signInBackgroundImageOverlayColor?: string;
    signInLogoPositionLeft?: number;
    signInLogoPositionTop?: number;
    signInLogoWidth?: number;
    signInLogoHeight?: number;
    signInLogoRotate?: number;
  };
  onAdjustSignInLogo?: (result: { left: number; top: number; width: number; height: number }) => void;
  palette?: AppPreviewerPalette;
  disabled?: boolean;
  tabId?: number;
  activeWidgetId?: number | string | null;
  activeWidgetGroupId?: number | null;
  onClick?: () => void;
  hideLayoutRouteFooter?: boolean;
  fitToWindow?: boolean;
  showDemoTab?: boolean;
  showTabWithNotification?: boolean;
  appLayoutTabs?: AppPreviewerTab[];
  isViewMode?: boolean;
  fadeTimeout?: number;
  extraHeightsToFitInView?: number[];
  pageHeaderHeight?: number;
  pageFooterHeight?: number;
};

export type UseAppPreviewerProps = {
  containerSize: {
    deviceWidth: number;
    deviceHeight: number;
    contentWidth: number;
    contentHeight: number;
    topRadius: number;
    bottomRadius: number;
    contentScale: number;
    deviceScale: number;
    deviceMargin: string;
  };
  currentRoute: PreviewerRoute;
  changeCurrentRoute: (to: PreviewerRoute) => void;
  scrollRef: RefObject<HTMLElement>;
  ready: boolean;
  handleWheel: () => void;
  onMouseLeave: () => void;
  signInScreenRef: RefObject<any>;
};

export type AppPreviewerProps = AppPreviewerContainerProps & UseAppPreviewerProps;
