import { Uuid } from '@egym/types';
import { useRouter } from '@egym/ui';
import useClientApplication from '../useClientApplication';

const useClientApplicationByRouter = () => {
  const { urlParams } = useRouter<{ uuid: Uuid }>();

  return useClientApplication(urlParams.uuid);
};

export default useClientApplicationByRouter;
