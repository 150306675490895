export const activityFeedItems = [
  {
    id: 0,
    type: 'completedWorkout',
    avatar: '/images/previewerDefaultImages/avatars/@2ximg2.png',
    fullName: 'Brittany Cash',
    dateHours: 0,
    dateMinutes: 24,
  },
  {
    id: 1,
    type: 'joinedChallenge',
    avatar: '/images/previewerDefaultImages/features/icChallenge@2x.png',
    count: 14,
    challengeName: 'appPreviewer.labels.mockChallenges.0.name',
    dateHours: 1,
    dateMinutes: 33,
  },
  {
    id: 2,
    type: 'completedGoal',
    avatar: '/images/previewerDefaultImages/avatars/@2ximg.png',
    fullName: 'Peter Biggs',
    goalName: 'appPreviewer.labels.workoutsPerMonth',
    goalNameParams: {
      countWorkouts: 10,
      countMonths: 1,
    },
    dateHours: 3,
    dateMinutes: 12,
  },
];
