import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Fade,
  Paper,
  Stack,
  Table as MuiTable,
  TableContainer as MuiTableContainer,
  TablePagination as MuiTablePagination,
} from '@mui/material';
import { Loader, TableColumn } from '@egym/ui';
import { ConditionalRender } from '../../utils';
import TableBody from './components/TableBody';
import TableEmptyState from './components/TableEmptyState';
import TableHead from './components/TableHead';
import { availableRowsPerPageOptions } from './config';
import { GroupedTableColumn, TableProps } from './TableProps';

const Table: React.FC<TableProps> = ({
  wrapperSx,
  columns,
  rowsPaginated,
  checkboxSelectionProp,
  selectedRowIds,
  handleSelectAllClick,
  handleRowClick,
  totalRowsCount,
  rowsPerPage,
  page,
  handleChangePage,
  handleChangeRowsPerPage,
  loading,
  noRowsLabel = 'common.labels.noData',
  noRowsWithFiltersLabel = 'common.labels.noDataWithFilters',
  size = 'small',
  headersCellSx,
  tdProps,
  renderSubComponent,
  totalColumnsCount,
  isSelectAllChecked,
  isSelectAllIndeterminate,
  showCheckAll = true,
  getIsCheckboxSelected,
  getIsCheckboxIndeterminate,
  indeterminateRowIds,
  expandAll,
  tableState,
  updateTableStateSorting,
  updateTableStateFilters,
  hasExternalPagination,
  hasFilterableColumns,
  hasSelectedFilters,
  hasData,
  isViewMode,
  ...rest
}) => {
  const { t } = useTranslation();

  return (
    <Paper sx={{ width: '100%' }} elevation={0}>
      <MuiTableContainer
        sx={{
          borderRadius: 1,
          ...wrapperSx,
          boxShadow: 'none',
          position: 'relative',
        }}
      >
        {loading && (
          <Fade
            in={loading}
            style={{
              transitionDelay: loading ? '800ms' : '0ms',
            }}
            unmountOnExit
          >
            <Stack
              alignItems="center"
              justifyContent="center"
              sx={{
                position: 'absolute',
                left: 0,
                top: 0,
                width: '100%',
                height: '100%',
                bgcolor: 'action.disabled',
                zIndex: 10,
              }}
            >
              <Loader />
            </Stack>
          </Fade>
        )}
        <MuiTable size={size} {...rest}>
          <TableHead
            columns={columns}
            hasData={hasData}
            checkboxSelectionProp={checkboxSelectionProp}
            onSelectAllClick={handleSelectAllClick}
            totalRowsCount={totalRowsCount}
            renderSubComponent={renderSubComponent}
            isSelectAllChecked={isSelectAllChecked}
            isSelectAllIndeterminate={isSelectAllIndeterminate}
            showCheckAll={showCheckAll}
            tableState={tableState}
            updateTableStateSorting={updateTableStateSorting}
            hasFilterableColumns={hasFilterableColumns}
            updateTableStateFilters={updateTableStateFilters}
            hasSelectedFilters={hasSelectedFilters}
            headersCellSx={headersCellSx}
            isViewMode={isViewMode}
          />
          <ConditionalRender condition={!rowsPaginated.length}>
            <TableEmptyState
              noRowsLabel={noRowsLabel}
              noRowsWithFiltersLabel={noRowsWithFiltersLabel}
              totalColumnsCount={totalColumnsCount}
              hasSelectedFilters={hasSelectedFilters}
            />
            <TableBody
              columns={columns.flatMap(column => (column as GroupedTableColumn).children || [column as TableColumn])}
              rows={rowsPaginated}
              checkboxSelectionProp={checkboxSelectionProp}
              selectedRows={selectedRowIds}
              onRowClick={handleRowClick}
              tdProps={tdProps}
              renderSubComponent={renderSubComponent}
              totalColumnsCount={totalColumnsCount}
              getIsCheckboxSelected={getIsCheckboxSelected}
              getIsCheckboxIndeterminate={getIsCheckboxIndeterminate}
              indeterminateRowIds={indeterminateRowIds}
              expandAll={expandAll}
              isViewMode={isViewMode}
            />
          </ConditionalRender>
        </MuiTable>
      </MuiTableContainer>
      {(totalRowsCount > rowsPerPage || hasExternalPagination) && !!rowsPaginated.length && (
        <MuiTablePagination
          rowsPerPageOptions={availableRowsPerPageOptions}
          component="div"
          count={totalRowsCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={t('common.labels.rowsPerPage') as ReactNode}
          labelDisplayedRows={({ from, to, count }) =>
            t('common.labels.paginationRowsLabel', { from, to, count }) as ReactNode
          }
        />
      )}
    </Paper>
  );
};

export default Table;
