import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useConfirmDialog } from '../../../hooks';
import { Button } from '../../inputs';
import { SafeHtml } from '../../layout';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '../Dialog';

export const ConfirmDialog = () => {
  const { t } = useTranslation();

  const { isOpen, closeConfirmDialog, cancelConfirmDialog, confirm, dialogConfig, showCancelButton, isLoading } =
    useConfirmDialog();

  const TextComponent = dialogConfig?.text;

  return (
    <Dialog
      data-testid="confirm-dialog"
      open={isOpen}
      onClose={closeConfirmDialog}
      fullWidth
      maxWidth={dialogConfig?.maxWidth ?? 'sm'}
      {...dialogConfig?.muiDialogProps}
    >
      {dialogConfig?.title && (
        <DialogTitle onClose={closeConfirmDialog} isLoading={isLoading} wrapperSx={dialogConfig.dialogTitleSx}>
          {t(dialogConfig.title, dialogConfig?.titleParams) as ReactNode}
        </DialogTitle>
      )}
      <DialogContent sx={{ pt: 3, pb: 10, px: 8 }} {...dialogConfig?.contentProps}>
        {typeof TextComponent === 'function' ? (
          <TextComponent {...dialogConfig?.textParams} />
        ) : (
          // @ts-ignore
          dialogConfig?.text?.map(({ key, text, textParams, ...otherTextProps }, i, arr) => (
            <DialogContentText
              key={key || text}
              variant="body1"
              color="inherit"
              {...otherTextProps}
              sx={{ pb: i === arr.length - 1 ? 0 : 3, ...otherTextProps.sx }}
            >
              <SafeHtml component="span" dirtyHtml={t(text, textParams || dialogConfig?.textParams)} />
            </DialogContentText>
          ))
        )}
      </DialogContent>
      <DialogActions sx={{ pt: 5, pb: 10, px: 8 }} disableSpacing>
        {showCancelButton && (
          <Button
            variant="outlined"
            color="inherit"
            disabled={isLoading}
            onClick={cancelConfirmDialog}
            text={t(dialogConfig?.cancelText || 'common.buttons.cancel') as ReactNode}
            sx={{ minWidth: '100px' }}
            {...dialogConfig?.cancelButtonProps}
          />
        )}
        {dialogConfig?.onConfirm && (
          <Button
            data-testid="dialog-confirm-button"
            variant="contained"
            color="primary"
            onClick={confirm}
            text={t(dialogConfig?.confirmText || 'common.buttons.yes') as ReactNode}
            sx={{ ml: 4, minWidth: '100px' }}
            loading={isLoading}
            autoFocus
            {...dialogConfig?.confirmButtonProps}
          />
        )}
      </DialogActions>
    </Dialog>
  );
};
