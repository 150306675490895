import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, TypographyProps } from '../Typography';

type Props = {
  text: string;
} & TypographyProps;

export const Text: React.FC<Props> = ({ text, ...rest }) => {
  const { t } = useTranslation();

  return <Typography {...rest}>{t(text) as ReactNode}</Typography>;
};
