import { FC } from 'react';
import useMoveWidgetsForm from './hooks/useMoveWidgetsForm';
import MoveWidgetsForm from './MoveWidgetsForm';
import { MoveWidgetsFormContainerProps } from './MoveWidgetsFormProps';

const MoveWidgetsFormContainer: FC<MoveWidgetsFormContainerProps> = props => {
  const result = useMoveWidgetsForm(props);

  return <MoveWidgetsForm {...props} {...result} />;
};

export default MoveWidgetsFormContainer;
