import { FC } from 'react';

export const getCanUseGA = (shouldWarn = false) => {
  const isDev = process.env.NODE_ENV === 'development';

  if (isDev && shouldWarn) {
    // eslint-disable-next-line no-console
    console.warn('[@egym/analytics/googleTagManager] Google Analytics is disabled in dev mode');
  }

  return !isDev;
};

export const embedGTM = id => {
  if (!getCanUseGA(true)) return;

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });

  const f = document.getElementsByTagName('script')[0];
  const j = document.createElement('script');

  j.async = true;
  j.src = `https://www.googletagmanager.com/gtm.js?id=${id}`;

  f.parentNode?.insertBefore(j, f);
};

type GTMFrameProps = {
  id: string;
};

export const GTMFrame: FC<GTMFrameProps> = ({ id }) => {
  if (!getCanUseGA()) return null;

  return (
    <noscript>
      <iframe
        title="gtagiframe"
        src={`https://www.googletagmanager.com/ns.html?id=${id}`}
        height="0"
        width="0"
        style={{ display: 'none', visibility: 'hidden' }}
      />
    </noscript>
  );
};

type DataLayerConfig = {
  event: string;
  properties: Record<string, string | number | undefined>;
};

export const pushToDataLayer = (config: DataLayerConfig) => {
  if (!getCanUseGA()) return;

  window.dataLayer.push(config);
};
