import { v4 as uuidv4 } from 'uuid';
import { uniqByFieldName } from '@egym/utils';
import {
  AppLayoutFeatureEnum,
  AppPreviewerTab,
  compactToFullTabFeatureMap,
  fullTabToCompactFeatureMap,
  getIsFeatureFullTab,
} from '@components';
import {
  nonRemovableFeatures,
  predefinedHorizontalWidgetImages,
  predefinedVerticalWidgetImages,
  redundantWidgets,
} from '@constants';
import {
  AppDesignLayoutTabWidget,
  Orientation,
  PredefinedTabImage,
  RemainingWidget,
  WidgetViewStyle,
  WidgetWithTab,
} from '@types';
import { getDefaultPreviewerFeature } from './appDesign';

export const getIsFeatureUnremovable = (widgetName: AppLayoutFeatureEnum) => nonRemovableFeatures.includes(widgetName);

export const getTabsAddedWidgets = (appLayoutTabs: AppPreviewerTab[]) =>
  appLayoutTabs.reduce<AppDesignLayoutTabWidget[]>((acc, tab) => {
    if (tab.features) {
      return [
        ...acc,
        ...tab.features?.map(feature => feature.props),
        ...(tab.features?.flatMap(feature => feature.props.preferences?.innerWidgets || []) || []),
      ];
    }

    return acc;
  }, []);

export const getAddedWidgetByName = (
  appLayoutTabs: AppPreviewerTab[],
  widgetsWithoutTab: AppDesignLayoutTabWidget[] = [],
) => {
  const addedWidgets = getTabsAddedWidgets(appLayoutTabs);

  return widgetName =>
    [...addedWidgets, ...widgetsWithoutTab].find(it =>
      [widgetName, compactToFullTabFeatureMap[widgetName], fullTabToCompactFeatureMap[widgetName]].includes(it.name),
    );
};

export const getAddedWidgetsByName = (
  appLayoutTabs: AppPreviewerTab[],
  widgetsWithoutTab: AppDesignLayoutTabWidget[] = [],
) => {
  const addedWidgets = getTabsAddedWidgets(appLayoutTabs);

  return widgetName =>
    [...addedWidgets, ...widgetsWithoutTab].filter(it =>
      [widgetName, compactToFullTabFeatureMap[widgetName], fullTabToCompactFeatureMap[widgetName]].includes(it.name),
    );
};

const getEstimatedWidgetOrder = (
  siblingWidgetId: number | null,
  widget: WidgetWithTab,
  tab: AppPreviewerTab,
  position: 0 | 1,
) => {
  if (widget.groupId) {
    const group = tab.features.find(feature => feature.props.id === widget.groupId);

    return siblingWidgetId && group
      ? (group.props.preferences?.innerWidgets.find(innerWidget => innerWidget.id === siblingWidgetId)?.order || 0) +
          position
      : position;
  }

  const notificationsFeatureInTheTab = tab.features.find(
    it => it.props.name === AppLayoutFeatureEnum.PreviewNotificationCenter,
  );

  // PreviewNotificationCenter always on top
  return siblingWidgetId && widget.name !== AppLayoutFeatureEnum.PreviewNotificationCenter
    ? (tab.features.find(feature => feature.props.id === siblingWidgetId)?.props.order || 0) + position
    : (notificationsFeatureInTheTab && notificationsFeatureInTheTab.props.order + 1) || 0;
};

const sortWidgetsByOrder = (widgetA, widgetB) => widgetA.props.order - widgetB.props.order;
const sortInnerWidgetsByOrder = (widgetA, widgetB) => widgetA.order - widgetB.order;

export const extendPreviewerTabsWithNewFeature = (
  prevAppLayoutTabs: AppPreviewerTab[],
  widget: WidgetWithTab,
  destinationTabId: number,
  prevWidgetId,
  position: 0 | 1,
): AppPreviewerTab[] => {
  // remove new widget from the previous tab or group if added
  let result = prevAppLayoutTabs.map(tab => ({
    ...tab,
    features:
      tab.id === widget.addedOnTab?.id
        ? tab.features.reduce<any>((acc, feature) => {
            if (feature.props.id === widget.addedWidget?.id) return acc;
            if (feature.props.id === widget.addedWidget?.groupId) {
              return [
                ...acc,
                {
                  ...feature,
                  props: {
                    ...feature.props,
                    preferences: {
                      ...feature.props.preferences,
                      innerWidgets: feature.props.preferences?.innerWidgets.filter(
                        innerWidget => innerWidget.id !== widget.addedWidget?.id,
                      ),
                    },
                  },
                },
              ];
            }

            return [...acc, feature];
          }, [])
        : tab.features,
  }));

  // add new widget to the current tab
  result = result.map(tab => {
    let newFeatures = tab.features;

    if (tab.id === destinationTabId) {
      const order = getEstimatedWidgetOrder(prevWidgetId, widget, tab, position);

      const widgetId = widget.id || uuidv4();

      if (widget.groupId) {
        newFeatures = tab.features.map(feature => {
          const isGroupWidget = feature.props.id === widget.groupId;

          if (!isGroupWidget) return feature;

          const newInnerWidgets = [
            ...(feature.props.preferences?.innerWidgets.filter(innerWidget => innerWidget.id !== widget.id) || []),
            {
              disabled: false,
              id: widgetId,
              name: widget.name,
              tabId: tab.id,
              groupId: widget.groupId,
              preferences: widget.preferences || null,
              order,
            },
          ]
            .map(it => ({
              ...it,
              order: it.id === widgetId || it.order < order ? it.order : it.order + 1,
            }))
            .sort(sortInnerWidgetsByOrder);

          return {
            ...feature,
            props: {
              ...feature.props,
              preferences: {
                ...feature.props.preferences,
                innerWidgets: newInnerWidgets,
              },
            },
          };
        });
      } else {
        newFeatures = [
          ...tab.features,
          getDefaultPreviewerFeature({
            id: widgetId,
            name: widget.name,
            tabId: tab.id,
            preferences: widget.preferences || null,
            order,
          }),
        ]
          .filter(feature => {
            if (feature.props.name === widget.name) return true;

            // if new widget is a full tab widget then remove remaining features in the tab
            return !getIsFeatureFullTab(widget.name);
          })
          .map(feature => {
            return {
              ...feature,
              props: {
                ...feature.props,
                order:
                  feature.props.id === widgetId || feature.props.order < order
                    ? feature.props.order
                    : feature.props.order + 1,
              },
            };
          })
          .sort(sortWidgetsByOrder);
      }
    }

    return {
      ...tab,
      features: newFeatures,
    };
  });

  return result;
};

export const extendPreviewerTabsWithNewFeatures = (
  oldAppLayoutStructure: AppPreviewerTab[],
  appLayoutStructure: AppPreviewerTab[],
  newWidgets: RemainingWidget[],
) => {
  return newWidgets.reduce((acc, newWidget) => {
    if (!newWidget.shouldBeRemoved && newWidget.newTabId) {
      const addedWidgets = getAddedWidgetsByName(oldAppLayoutStructure)(newWidget.props.name);
      const addedWidget = addedWidgets.find(it => it.id === newWidget.props.id) || addedWidgets[0];

      const widgetWithTab = {
        id: newWidget.props.id,
        name: newWidget.props.name,
        preferences: newWidget.props.preferences,
        createdAt: newWidget.props.createdAt,
        disabled: newWidget.props.disabled,
        groupId: newWidget.props.groupId,
        order: newWidget.props.order,
        addedWidget: addedWidget || null,
        addedOnTab: oldAppLayoutStructure.find(tab => tab.id === addedWidget?.tabId) || null,
      };

      // insert after specified widget or put them all to the end (find last widget id)
      const remainingSiblingWidgetId =
        newWidget.siblingWidgetId ??
        acc
          .find(tab => tab.id === newWidget.newTabId)
          ?.features.slice()
          .reverse()[0]?.props.id;

      return extendPreviewerTabsWithNewFeature(acc, widgetWithTab, newWidget.newTabId, remainingSiblingWidgetId, 1);
    }

    return acc;
  }, appLayoutStructure);
};

export const getRemainingFullTabToCompactWidgets = (
  appLayoutStructure: AppPreviewerTab[],
  remainingWidgets: RemainingWidget[],
): AppDesignLayoutTabWidget[] => {
  const fullTabToCompactWidgets = remainingWidgets.reduce<AppDesignLayoutTabWidget[]>((acc, widget) => {
    const correspondingTab = appLayoutStructure.find(tab => tab.id === widget.newTabId);

    if (correspondingTab) {
      const fullTabFeatures = correspondingTab.features
        .filter(it => getIsFeatureFullTab(it.props.name))
        .map(it => ({
          ...it.props,
          name: fullTabToCompactFeatureMap[it.props.name],
        }));

      return [...acc, ...fullTabFeatures];
    }

    return acc;
  }, []);

  return uniqByFieldName('id')(fullTabToCompactWidgets) as AppDesignLayoutTabWidget[];
};

export const normalizeWidgets = (widgets: AppDesignLayoutTabWidget[]) => {
  const allWidgets = widgets.filter(widget => !redundantWidgets.includes(widget.name));

  return allWidgets.reduce<AppDesignLayoutTabWidget[]>((acc, widget, _, all) => {
    if (widget.groupId) return acc;

    const newItem = {
      ...widget,
      preferences:
        widget.name === AppLayoutFeatureEnum.PreviewGroupWebWidget
          ? {
              ...widget.preferences,
              innerWidgets: all.filter(it => it.groupId === widget.id),
            }
          : widget.preferences,
    };

    return [...acc, newItem];
  }, []);
};

export const updateInnerWidgetPreferencesAsset = (
  preferences: AppDesignLayoutTabWidget['preferences'],
  toOrientation: Orientation | undefined,
): AppDesignLayoutTabWidget['preferences'] => {
  if (preferences?.style === WidgetViewStyle.Image && preferences?.asset?.id) {
    const images: PredefinedTabImage[] =
      toOrientation === Orientation.Horizontal ? predefinedHorizontalWidgetImages : predefinedVerticalWidgetImages;

    return {
      ...preferences,
      asset: {
        id: preferences?.asset?.id,
        link: images.find(it => it.id === preferences?.asset?.id)?.path,
      },
    };
  }

  return preferences;
};
