import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const MainTabHeaderDefaultIcon25: React.FC<SvgIconProps> = ({ htmlColor = '#FFF', sx, ...props }) => {
  return (
    <SvgIcon viewBox="0 0 48 48" {...props} sx={{ width: '48px', height: '48px', ...sx }}>
      <g fill={htmlColor} fillRule="evenodd">
        <path
          d="M27.036 6.624a2 2 0 0 1 2.38-1.58l.148.037 8.5 2.5a2 2 0 0 1-.984 3.875l-.144-.037L30.55 9.54l-4.086 21.336a2 2 0 0 1-2.193 1.611l-.147-.023a2 2 0 0 1-1.611-2.193l.023-.147 4.5-23.5z"
          opacity=".6"
        />
        <path d="M24 13c5.784 0 10.686 3.778 12.373 9H37a2 2 0 1 1 0 4h-.293l-2.27 14.313a2 2 0 0 1-1.813 1.68l-.162.007H15.538a2 2 0 0 1-1.975-1.687L11.292 26H11a2 2 0 1 1 0-4h.627c1.687-5.222 6.589-9 12.373-9zm8.657 13H15.342l1.904 12h13.507l1.904-12zM24 17a9 9 0 0 0-8.064 5h16.128A9 9 0 0 0 24 17z" />
      </g>
    </SvgIcon>
  );
};
