import React, { FC, ReactNode } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Field, Form, Formik } from 'formik';
import { withFormikDevtools } from 'formik-devtools-extension';
import {
  Box,
  ColorPickerFormField,
  ConditionalRender,
  Divider,
  FileUploadFormField,
  FormikOnChangeWithDebounce,
  FormikScrollToError,
  LinkCore,
  MultiLanguageTextField,
  PickerType,
  RadioGroupFormField,
  Stack,
  Typography,
} from '@egym/ui';
import { FileFormatName, getBytesFromMb } from '@egym/utils';
import { AppLayoutFeatureEnum, appLayoutFeatureMap, PredefinedImagesLibraryPopover } from '@components';
import { predefinedVerticalWidgetImages, predefinedWidgetIcons } from '@constants';
import { WidgetViewStyle } from '@types';
import { LngNamespace } from 'src/i18n';
import { convertToStyledWidgetState } from '../../hooks/useWidgetPreferencesForm/helpers';
import WidgetIconWithBackgroundColorField from '../WidgetIconWIthBackgroundColorField/WidgetIconWithBackgroundColorFieldContainer';
import { BasicWidgetPreferencesFormProps } from './BasicWidgetPreferencesFormProps';

const BasicWidgetPreferencesForm: FC<BasicWidgetPreferencesFormProps> = ({
  formProps,
  availableLanguages,
  openLanguagesMenuTab,
  presetColors,
  onSelectPredefinedIcon,
  onSelectPredefinedImage,
  currentLocale,
  onFormChanged,
  previewerPalette,
  onCustomIconPick,
  onCustomImagePick,
  imageDimensions,
}) => {
  const { t } = useTranslation();
  const { t: previewerT } = useTranslation(LngNamespace.Previewer) as any;
  const PreviewComponent = appLayoutFeatureMap[AppLayoutFeatureEnum.PreviewWebWidget];

  return (
    <Formik {...formProps}>
      {props =>
        withFormikDevtools(
          props,
          <Form placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            <FormikOnChangeWithDebounce onFormChanged={onFormChanged} debounce={300} />
            <FormikScrollToError>
              <Stack spacing={7} direction="column" sx={{ px: 8, maxWidth: '710px', boxSizing: 'border-box' }}>
                <Stack spacing={5} sx={{ pt: 7 }}>
                  <Typography variant="h5Bold">{t('common.labels.style') as ReactNode}</Typography>
                  <Stack>
                    <Field
                      id="style"
                      name="style"
                      component={RadioGroupFormField}
                      hiddenLabel
                      row
                      optionsSpacing={6}
                      optionsWrapperSx={{
                        width: '100%',
                        justifyContent: 'space-between',
                      }}
                      formControlLabelWrapperSx={{
                        width: '50%',
                      }}
                      optionLabelTypographyProps={{
                        variant: 'body1',
                      }}
                      options={[
                        { value: WidgetViewStyle.Image, label: 'common.labels.image', sx: { p: 0, mr: 3 } },
                        { value: WidgetViewStyle.Icon, label: 'common.labels.icon', sx: { p: 0, mr: 3 } },
                      ]}
                    />
                    <Stack direction="row" spacing={6} sx={{ justifyContent: 'space-between', mt: 4 }}>
                      {Object.values(WidgetViewStyle).map(style => (
                        <Box
                          key={style}
                          sx={{
                            p: 5,
                            width: '50%',
                            maxHeight: '142px',
                            borderRadius: 3,
                            overflow: 'hidden',
                            backgroundImage: 'linear-gradient(to bottom, #fafafa, #f2f2f2)',
                            fontFamily:
                              'Inv Maison Neue, Maison Neue,"-apple-system", BlinkMacSystemFont, Open Sans, "open-sans","sans-serif"',
                          }}
                        >
                          <PreviewComponent
                            wrapperSx={{ p: 0 }}
                            palette={previewerPalette}
                            previewerState={{ selectedLocale: currentLocale }}
                            widgetProps={convertToStyledWidgetState(style, props.values)}
                            tFunction={previewerT}
                          />
                        </Box>
                      ))}
                    </Stack>
                  </Stack>
                </Stack>
                <Divider flexItem />
                <ConditionalRender condition={props.values?.style === WidgetViewStyle.Image}>
                  <Stack spacing={5}>
                    <Typography variant="h5Bold">{t('common.labels.image') as ReactNode}</Typography>
                    <Field
                      name="image"
                      component={FileUploadFormField}
                      accept={[FileFormatName.PNG, FileFormatName.JPG]}
                      acceptHintsCommon={[
                        {
                          tKey: 'common.fileUpload.atLeastWidthAndHeight',
                          tKeyParams: { widthPixels: imageDimensions.width, heightPixels: imageDimensions.height },
                        },
                      ]}
                      minImageDimension={[imageDimensions.width, imageDimensions.height]}
                      cropperProps={{
                        ratio: imageDimensions.ratio,
                      }}
                      maxSize={getBytesFromMb(10)}
                      onFilePick={() => onCustomImagePick(props)}
                      wrapperSx={{ mt: 10 }}
                      uploadPaneProps={{
                        justifyContent: 'space-between',
                        spacing: 6,
                      }}
                      previewWrapperSx={{
                        height: '100%',
                        width: '100%',
                        justifyContent: 'flex-start',
                        alignItems: 'baseline',
                        p: 0,
                      }}
                      previewImageProps={(filePreviewResult: string) => ({
                        sx: {
                          height: '90px',
                          width: '100%',
                          background: `url(${filePreviewResult}) no-repeat center/100%`,
                        },
                        component: 'div',
                      })}
                      uploadPaneWrapperSx={{
                        minHeight: '300px',
                        '& > *': {
                          width: '50%',
                          height: '100%',
                          flex: 1,
                        },
                      }}
                      uploadImageTool={
                        <Stack spacing={8} sx={{ ml: 5, mt: 8, mb: 7 }}>
                          <Field
                            name="imageOverlay"
                            component={ColorPickerFormField}
                            fieldLabel="common.labels.imageOverlay"
                            presetColors={presetColors}
                            pickers={[PickerType.OneColor, PickerType.Gradient]}
                          />
                          <PredefinedImagesLibraryPopover
                            onSelectImage={image => onSelectPredefinedImage(image, props)}
                            images={predefinedVerticalWidgetImages}
                            predefinedImageId={props.values.imagePredefinedId || 1}
                            wrapperSx={{
                              '& > button': {
                                justifyContent: 'initial',
                              },
                            }}
                          />
                        </Stack>
                      }
                    />
                  </Stack>
                </ConditionalRender>
                <ConditionalRender condition={props.values?.style === WidgetViewStyle.Icon}>
                  <WidgetIconWithBackgroundColorField
                    previewerPalette={previewerPalette}
                    presetColors={presetColors}
                    predefinedIcons={predefinedWidgetIcons}
                    onCustomIconPickAction={onCustomIconPick}
                    onSelectPredefinedIconAction={onSelectPredefinedIcon}
                  />
                </ConditionalRender>
                <Divider flexItem />
                <Stack spacing={5}>
                  <Typography variant="h5Bold">{t('appDesign.labels.widgets.name') as ReactNode}*</Typography>
                  <MultiLanguageTextField name="title" languages={availableLanguages} inputProps={{ maxLength: 20 }} />
                </Stack>
                <Divider flexItem />
                <Stack spacing={5}>
                  <Typography variant="h5Bold">{t('appDesign.labels.widgets.callToAction') as ReactNode}</Typography>
                  <MultiLanguageTextField
                    name="callToAction"
                    languages={availableLanguages}
                    inputProps={{ maxLength: 35 }}
                  />
                </Stack>
                <Divider flexItem />
                <Box sx={{ mt: 10 }}>
                  <Typography variant="body2" sx={{ color: 'grey.500' }}>
                    <Trans
                      i18nKey="appDesign.labels.customizeLayout.tabsSettingsDescription"
                      components={[
                        <LinkCore
                          component="button"
                          sx={{ textDecoration: 'underline' }}
                          color="inherit"
                          onClick={() => openLanguagesMenuTab(props)}
                        />,
                      ]}
                    />
                  </Typography>
                </Box>
              </Stack>
            </FormikScrollToError>
          </Form>,
        )
      }
    </Formik>
  );
};

export default BasicWidgetPreferencesForm;
