import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const MainTabHeaderDefaultIcon23: React.FC<SvgIconProps> = ({ htmlColor = '#FFF', sx, ...props }) => {
  return (
    <SvgIcon viewBox="0 0 48 48" {...props} sx={{ width: '48px', height: '48px', ...sx }}>
      <g fill={htmlColor} fillRule="evenodd">
        <path d="M38 12a5 5 0 0 1 5 5v4a5.002 5.002 0 0 1-3.999 4.9L39 38a5 5 0 0 1-5 5H14a5 5 0 0 1-5-5V25.9A5.002 5.002 0 0 1 5 21v-4a5 5 0 0 1 5-5zM22 26h-9v12a1 1 0 0 0 1 1h8V26zm13 0h-9v13h8a1 1 0 0 0 .993-.883L35 38V26zm3-10H10a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h28a1 1 0 0 0 1-1v-4a1 1 0 0 0-1-1z" />
        <path
          d="M35.369 7.476c1.263 3.125-.326 6.651-3.461 7.918-2.543 1.027-5.95.696-7.953-.967-2.001 1.663-5.409 1.994-7.95.967-3.136-1.267-4.725-4.793-3.462-7.918 1.263-3.125 4.855-4.558 7.99-3.29 1.296.523 2.508 1.456 3.424 2.589.914-1.133 2.126-2.066 3.422-2.59 3.135-1.267 6.727.166 7.99 3.291zm-16.346.445c-1.177-.475-2.432.025-2.837 1.027-.404 1.002.15 2.234 1.328 2.71 1.522.615 3.875.147 4.138-.502.262-.649-1.106-2.62-2.629-3.235zm9.866 0c-1.523.616-2.89 2.586-2.629 3.235.263.65 2.615 1.117 4.138.501 1.177-.475 1.732-1.707 1.328-2.709-.405-1.002-1.66-1.502-2.837-1.027z"
          opacity=".6"
        />
      </g>
    </SvgIcon>
  );
};
