export const fillGroupWithWidgets = (innerWidgets: any[] = []) => {
  if (innerWidgets.length > 1) return innerWidgets;

  const result = Array(2);
  const innerWidget = innerWidgets[0];

  if (innerWidget) {
    if (innerWidget.order > 0) {
      result[0] = null;
      result[1] = innerWidget;
    } else {
      result[0] = innerWidget;
      result[1] = null;
    }
  } else {
    result[0] = null;
    result[1] = null;
  }

  return result;
};
