import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const ShareIcon: React.FC<SvgIconProps> = ({ sx, htmlColor = '#4C4A4A', ...rest }) => {
  return (
    <SvgIcon viewBox="0 0 24 24" sx={{ width: '20px', height: '20px', ...sx }} {...rest}>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <path d="M0 0H24V24H0z" transform="translate(-164 -1726) translate(164 1726)" />
            <path
              fill={htmlColor}
              d="M9 7.999v2H7c-.552 0-1 .448-1 1v8c0 .552.448 1 1 1h10c.552 0 1-.448 1-1v-8c0-.552-.448-1-1-1h-2v-2h2c1.654 0 3 1.346 3 3v8c0 1.654-1.346 3-3 3H7c-1.654 0-3-1.346-3-3v-8c0-1.654 1.346-3 3-3h2zm2.618-6.923c.244-.101.52-.101.764 0 .123.05.234.124.326.217l3.241 3.24c.391.392.391 1.024 0 1.415-.195.195-.45.293-.707.293-.256 0-.512-.098-.707-.293L12.951 4.11l.05.888v9c0 .553-.448 1-1 1-.554 0-1-.447-1-1v-9l.052-.895-1.588 1.844c-.39.39-1.024.39-1.414 0-.39-.391-.39-1.023 0-1.414l3.241-3.241c.092-.093.203-.166.326-.217z"
              transform="translate(-164 -1726) translate(164 1726)"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export default ShareIcon;
