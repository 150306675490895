import React, { PropsWithChildren } from 'react';
import { SxProps } from '@mui/system';
import { Box } from '../../layout/Box';
import { panelContentStyles } from './Panel.styles';

type Props = {
  sx?: SxProps;
} & PropsWithChildren;

const PanelContent: React.FC<Props> = ({ sx, children }) => {
  return <Box sx={{ ...panelContentStyles, ...sx }}>{children}</Box>;
};

export default PanelContent;
