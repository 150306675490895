import { useCallback, useMemo } from 'react';
import { useUpdateEffect } from 'react-use';
import { useRecoilState } from 'recoil';
import useRouter from '../useRouter';
import { formDialogGlobalState } from './state';
import { FormDialogConfig } from './types';

export * from './types';

const useFormDialog = () => {
  const [formDialogConfig, setFormDialogConfig] = useRecoilState(formDialogGlobalState.atoms.formDialogConfig);
  const { pathname } = useRouter();
  const isOpen = useMemo(() => Boolean(formDialogConfig?.open), [formDialogConfig]);

  const maxWidth = useMemo(() => formDialogConfig?.maxWidth ?? 'md', [formDialogConfig]);

  const openFormDialog = useCallback(
    (config: FormDialogConfig) => {
      setFormDialogConfig({
        shouldCloseOnPathnameChange: true,
        ...config,
        open: true,
      });
    },
    [setFormDialogConfig],
  );

  const showCancelButton = useMemo(() => {
    return (
      formDialogConfig?.showCancelButton ||
      formDialogConfig?.onClose ||
      formDialogConfig?.showCancelButton === undefined
    );
  }, [formDialogConfig]);

  const showConfirmButton = useMemo(() => {
    return formDialogConfig?.showConfirmButton || formDialogConfig?.showConfirmButton === undefined;
  }, [formDialogConfig]);

  const closeFormDialog = useCallback(() => {
    if (formDialogConfig?.onClose) {
      formDialogConfig.onClose();
    }
    setFormDialogConfig(prev => ({ ...prev, open: false }));
  }, [formDialogConfig, setFormDialogConfig]);

  useUpdateEffect(() => {
    if (formDialogConfig?.shouldCloseOnPathnameChange) {
      setFormDialogConfig(prev => ({
        ...prev,
        open: false,
      }));
    }
  }, [pathname]);

  return {
    isOpen,
    formDialogConfig,
    openFormDialog,
    closeFormDialog,
    showCancelButton,
    showConfirmButton,
    maxWidth,
  };
};

export default useFormDialog;
