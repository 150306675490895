import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const JaJpFlagIcon: React.FC<SvgIconProps> = ({ sx, ...props }) => {
  return (
    <SvgIcon viewBox="0 0 640 480" {...props} sx={{ width: '18px', ...sx }}>
      <defs>
        <clipPath id="a">
          <path fillOpacity=".7" d="M-88 32h640v480H-88z" />
        </clipPath>
      </defs>
      <g fillRule="evenodd" strokeWidth="1pt" clipPath="url(#a)" transform="translate(88 -32)">
        <path fill="#fff" d="M-128 32h720v480h-720z" />
        <circle cx="523.1" cy="344.1" r="194.9" fill="#d30000" transform="translate(-168.4 8.6) scale(.76554)" />
      </g>
    </SvgIcon>
  );
};

export default JaJpFlagIcon;
