import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { getFileFromUrl } from '@egym/api';
import { Uuid } from '@egym/types';
import { useSnackbar } from '@egym/ui';
import {
  deleteAppDesignCustomTabById,
  deleteAppDesignCustomTabs,
  getAppDesignCustomTabs,
  postAppDesignCustomTabIcon,
  postAppDesignCustomTabs,
  putAppDesignCustomTabById,
  putAppDesignCustomTabs,
} from '@api';
import { transformTabForApi } from '@helpers';
import { AppDesignLayoutTab } from '@types';
import { AppDesignTabSettingsFormValues } from 'src/pages/AppDesign/AppDesignProps';
import { appDesignTabsWidgetsKeys } from '../useAppDesignTabsWidgets/useAppDesignTabsWidgets';

export type UseAppDesignCustomTabsProps = {
  appUuid: Uuid;
  enabledQuery?: boolean;
};

export const appDesignCustomTabsKeys = {
  all: ['appDesignTabs'] as const,
  list: (appId: Uuid) => [...appDesignCustomTabsKeys.all, appId] as const,
};

const useAppDesignCustomTabs = ({ appUuid, enabledQuery = false }: UseAppDesignCustomTabsProps) => {
  const queryClient = useQueryClient();
  const { openSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const replaceCustomTabsInCache = useCallback(
    async newTabs => {
      queryClient.setQueryData(appDesignCustomTabsKeys.list(appUuid), (prevData: any) => {
        return {
          ...prevData,
          data: newTabs?.data || newTabs,
        };
      });
    },
    [appUuid, queryClient],
  );

  const appDesignLayoutTabsQuery = useQuery(
    appDesignCustomTabsKeys.list(appUuid),
    async () => getAppDesignCustomTabs({ urlParams: { applicationUuid: appUuid || '' } }),
    {
      select: result => result.data,
      enabled: Boolean(enabledQuery && appUuid),
      keepPreviousData: true,
      refetchOnMount: false,
    },
  );

  const postAppDesignCustomTabIconMutation = useMutation(
    async ({ tabId, file, predefinedId }: { tabId: number; file: File; predefinedId?: string | number }) => {
      const formData = new FormData();
      formData.append('icon', file, file.name || '');
      if (predefinedId) {
        formData.append('iconPredefinedId', String(predefinedId));
      }
      return postAppDesignCustomTabIcon({
        payload: formData,
        urlParams: { applicationUuid: appUuid, tabId },
      });
    },
  );

  const createAppDesignLayoutTabsMutation = useMutation(async (tabs: AppDesignLayoutTab[]) => {
    const { data: createdTabs } = await postAppDesignCustomTabs({
      payload: tabs.map(tab => ({ ...tab, icon: undefined })),
      urlParams: { applicationUuid: appUuid },
    });

    const promises = createdTabs.map(async createdTab => {
      const payloadTab = tabs.find(tab => tab.order === createdTab.order);
      if (payloadTab && payloadTab?.icon?.link) {
        const file = await getFileFromUrl(payloadTab?.icon?.link);

        const result = await postAppDesignCustomTabIconMutation.mutateAsync({
          tabId: createdTab.id,
          file,
          predefinedId: payloadTab?.icon?.id,
        });
        return result.data;
      }
      return createdTab;
    });

    return Promise.all(promises);
  });

  const editAppDesignLayoutTabsMutation = useMutation(async (tabs: AppDesignLayoutTab[]) =>
    putAppDesignCustomTabs({
      payload: tabs.map(tab => ({ ...tab, icon: undefined })),
      urlParams: { applicationUuid: appUuid },
    }),
  );

  const deleteAppDesignLayoutTabByIdMutation = useMutation(
    async ({ tabId }: { tabId: number }) =>
      deleteAppDesignCustomTabById({
        urlParams: { applicationUuid: appUuid, tabId },
      }),
    {
      onSuccess: async (_, { tabId }) => {
        queryClient.setQueryData(appDesignCustomTabsKeys.list(appUuid), (prevData: any) => ({
          ...prevData,
          data: prevData?.data?.filter(it => it.id !== tabId),
        }));
        await queryClient.invalidateQueries(appDesignTabsWidgetsKeys.all);
      },
      onError: () => openSnackbar(t('common.netError'), { severity: 'error' }),
    },
  );

  const editAppDesignLayoutTabByIdMutation = useMutation(
    async ({ id, name, order, icon, iconPredefinedId }: AppDesignTabSettingsFormValues) => {
      let result;
      result = await putAppDesignCustomTabById({
        payload: { id, localizedName: name, order },
        urlParams: { applicationUuid: appUuid, tabId: id },
      });

      if (icon?.[0] instanceof File) {
        result = await postAppDesignCustomTabIconMutation.mutateAsync({
          tabId: id,
          file: icon?.[0],
          predefinedId: iconPredefinedId,
        });
      }

      return result;
    },
    {
      onSuccess: response => {
        queryClient.setQueryData(appDesignCustomTabsKeys.list(appUuid), (prevData: any) => ({
          ...prevData,
          data: prevData?.data?.map(it => (it.id === response.data.id ? response.data : it)),
        }));
      },
      onError: () => openSnackbar(t('common.netError'), { severity: 'error' }),
    },
  );

  const deleteAppDesignLayoutTabsMutation = useMutation(async () =>
    deleteAppDesignCustomTabs({ urlParams: { applicationUuid: appUuid } }),
  );

  const editTabs = useCallback(
    async appLayoutTabs => {
      const convertedTabs: AppDesignLayoutTab[] = appLayoutTabs.map(tab => transformTabForApi(tab));

      const tabsResult = await editAppDesignLayoutTabsMutation.mutateAsync(convertedTabs);

      await replaceCustomTabsInCache(tabsResult);

      return tabsResult.data;
    },
    [editAppDesignLayoutTabsMutation, replaceCustomTabsInCache],
  );

  return {
    appDesignLayoutTabsQuery,
    createAppDesignLayoutTabsMutation,
    editAppDesignLayoutTabsMutation,
    editAppDesignLayoutTabByIdMutation,
    deleteAppDesignLayoutTabsMutation,
    deleteAppDesignLayoutTabByIdMutation,
    replaceCustomTabsInCache,
    editTabs,
  };
};

export default useAppDesignCustomTabs;
