import { forwardRef } from 'react';
import BasicWidgetPreferencesForm from './BasicWidgetPreferencesForm';
import { BasicWidgetPreferencesFormContainerProps } from './BasicWidgetPreferencesFormProps';
import useBasicWidgetPreferencesForm from './hooks/useBasicWidgetPreferencesForm';

const BasicWidgetPreferencesFormContainer = forwardRef<any, BasicWidgetPreferencesFormContainerProps>((props, ref) => {
  const result = useBasicWidgetPreferencesForm(props, ref);
  return <BasicWidgetPreferencesForm {...props} {...result} />;
});

export default BasicWidgetPreferencesFormContainer;
