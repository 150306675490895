import { useCallback, useMemo, useState } from 'react';
import { createTheme } from '@mui/material';
import { Theme } from '@mui/material/styles';
import getBreakpoints from './breakpoints';
import { themeComponents } from './components';
import getPalette from './palette';
import typographyOptions from './typography';

type ReturnProps = {
  theme: Theme;
  togglePaletteMode: () => void;
};

const useTheme = (): ReturnProps => {
  // const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  // use light theme as default for now
  const prefersDarkMode = false;
  const [darkMode, setDarkMode] = useState(prefersDarkMode);

  const togglePaletteMode = useCallback(() => {
    setDarkMode(prev => !prev);
  }, []);

  const paletteOptions = useMemo(() => getPalette(darkMode), [darkMode]);
  const breakpointsOptions = useMemo(() => getBreakpoints(), []);

  const theme = useMemo(() => {
    return createTheme({
      components: themeComponents,
      breakpoints: breakpointsOptions,
      palette: paletteOptions,
      typography: typographyOptions,
      spacing: 4,
      shape: {
        borderRadius: 4,
      },
    });
  }, [paletteOptions, breakpointsOptions]);

  // eslint-disable-next-line no-console
  console.log('theme', theme);

  return { theme, togglePaletteMode };
};

export default useTheme;
