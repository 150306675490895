import React, { PropsWithChildren } from 'react';
// https://next.material-ui.com/api/dialog-title/
import { IconButton, Stack } from '@mui/material';
import { SxProps } from '@mui/system';
import { Typography } from '../../../dataDisplay/Typography';
import { Icons } from '../../../icons';

type Props = {
  onClose?: () => void;
  isLoading?: boolean;
  wrapperSx?: SxProps;
} & PropsWithChildren;

export const DialogTitle: React.FC<Props> = ({ onClose, isLoading, children, wrapperSx }) => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      py={7}
      px={8}
      sx={{
        position: 'sticky',
        top: 0,
        bgcolor: 'common.white',
        zIndex: 10,
        borderBottom: '1px solid #d8d8d8',
        ...wrapperSx,
      }}
    >
      <Typography data-testid="dialog-title" variant="h5Bold" sx={{ fontSize: theme => theme.typography.pxToRem(28) }}>
        {children}
      </Typography>
      {onClose && (
        <IconButton
          data-testid="dialog-header-close"
          aria-label="close"
          onClick={onClose}
          size="small"
          disabled={isLoading}
        >
          <Icons.Close />
        </IconButton>
      )}
    </Stack>
  );
};
