import { atom } from 'recoil';
import { ContentDialogConfig } from '../types';

type DialogState = ContentDialogConfig | null;

const keys = {
  contentDialogConfig: 'contentDialogGlobalState.contentDialogConfig',
};

const atoms = {
  contentDialogConfig: atom<DialogState>({
    key: keys.contentDialogConfig,
    default: null,
  }),
};

export const contentDialogGlobalState = {
  keys,
  atoms,
};
