import { putSuffixToFilenameInPath } from '../string';

export const createImage = url =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', error => reject(error));
    image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues on CodeSandbox
    image.src = url;
  });

function getRadianAngle(degreeValue) {
  return (degreeValue * Math.PI) / 180;
}

export async function getCroppedImg({ imageSrc, imageType }, pixelCrop, rotation = 0) {
  const image = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  // @ts-ignore
  const maxSize = Math.max(image.width, image.height);
  const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

  // set each dimensions to double largest dimension to allow for a safe area for the
  // image to rotate in without being clipped by canvas context
  canvas.width = safeArea;
  canvas.height = safeArea;

  // translate canvas context to a central location on image to allow rotating around the center.
  ctx?.translate(safeArea / 2, safeArea / 2);
  ctx?.rotate(getRadianAngle(rotation));
  ctx?.translate(-safeArea / 2, -safeArea / 2);

  // draw rotated image and store data.
  // @ts-ignore
  ctx?.drawImage(image, safeArea / 2 - image.width * 0.5, safeArea / 2 - image.height * 0.5);
  const data = ctx?.getImageData(0, 0, safeArea, safeArea);

  // set canvas width to final desired crop size - this will clear existing context
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  // paste generated rotate image with correct offsets for x,y crop values.
  if (data) {
    ctx?.putImageData(
      data,
      // @ts-ignore
      Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
      // @ts-ignore
      Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y),
    );
  }

  // As Base64 string
  // return canvas.toDataURL('image/jpeg');

  // As a blob
  return new Promise(resolve => {
    canvas.toBlob(file => {
      resolve(file);
    }, imageType || 'image/png');
  });
}

export const blobToDataURL = (blob: Blob, svgAsText = true): Promise<string> => {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = () => reject(reader.error);
    reader.onabort = () => reject(new Error('Read aborted'));
    if (svgAsText && blob.type === 'image/svg+xml') {
      reader.readAsText(blob);
    } else {
      reader.readAsDataURL(blob);
    }
  });
};

export const getUrlFromFile = async (file: string | File | File[], svgAsText = true) => {
  if (typeof file === 'string') return file;

  return Array.isArray(file) ? blobToDataURL(file[0], svgAsText) : blobToDataURL(file, svgAsText);
};

export const imageSize = (url: string): Promise<{ width: number; height: number }> => {
  const img = document.createElement('img');
  const promise = new Promise<{ width: number; height: number }>((resolve, reject) => {
    img.onload = () => {
      const width = img.naturalWidth;
      const height = img.naturalHeight;
      resolve({ width, height });
    };
    img.onerror = reject;
  });
  img.src = url;
  return promise;
};

export const getOriginalFilePath = path => putSuffixToFilenameInPath(path, '_original');
