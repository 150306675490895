import { atom } from 'recoil';
import { filteringPrefix } from '@globalState';
import { ApplicationStatus, DemoApplication } from '@types';

const keys = {
  selectedTab: 'adminDashboard.tab',
  downloadArchiveProgress: 'adminDashboard.downloadArchiveProgress',
};
const parsedSearch = new URLSearchParams(window.location.search);

const getSelectedTab = () => {
  const appStatus = (parsedSearch.get(`${filteringPrefix}status`) as ApplicationStatus) || ApplicationStatus.InProgress;
  if (parsedSearch.get(`${filteringPrefix}demo`) === 'true') {
    return appStatus === ApplicationStatus.Archived ? DemoApplication.ArchivedDemo : DemoApplication.Demo;
  }
  return appStatus;
};

const atoms = {
  selectedTab: atom<ApplicationStatus | DemoApplication>({
    key: keys.selectedTab,
    default: getSelectedTab(),
  }),
};

export const adminDashboardState = {
  keys,
  atoms,
};
