import { Uuid } from '@egym/types';
import { useRouter } from '@egym/ui';
import { useDeleteTab } from '@hooks';

const useDeleteTabByRouter = () => {
  const { urlParams } = useRouter<{ uuid: Uuid }>();

  return useDeleteTab({ appUuid: urlParams.uuid });
};

export default useDeleteTabByRouter;
