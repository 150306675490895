import { useCallback } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { isEmpty } from 'ramda';
import { getFileFromUrl } from '@egym/api';
import { Uuid } from '@egym/types';
import { deleteWidgets, getAllWidgets, postAllWidgets, postWidgetAsset } from '@api';
import { isGStoreResource, normalizeWidgets } from '@helpers';
import { AppDesignLayoutTabWidget, WidgetViewStyle } from '@types';

export const appDesignTabsWidgetsKeys = {
  all: ['appDesignWidgets'],
  list: (appUuid: Uuid) => [...appDesignTabsWidgetsKeys.all, appUuid],
  byTab: (appUuid: Uuid) => (tabId: number) => [...appDesignTabsWidgetsKeys.list(appUuid), String(tabId)],
};

export type UseAppDesignTabsWidgetsProps = {
  appUuid: Uuid;
  enabledAllWidgetsQuery?: boolean;
};

const useAppDesignTabsWidgets = ({ appUuid, enabledAllWidgetsQuery = true }: UseAppDesignTabsWidgetsProps) => {
  const queryClient = useQueryClient();

  const replaceAllWidgetsInCache = (newWidgets: AppDesignLayoutTabWidget[]) => {
    queryClient.setQueryData(appDesignTabsWidgetsKeys.list(appUuid), (prevData: any) => ({
      ...prevData,
      data: newWidgets,
    }));
  };

  const allWidgetsQuery = useQuery(
    appDesignTabsWidgetsKeys.list(appUuid),
    async () => getAllWidgets({ urlParams: { applicationUuid: String(appUuid) } }),
    {
      enabled: Boolean(enabledAllWidgetsQuery && appUuid),
      select: result => normalizeWidgets(result.data),
      keepPreviousData: true,
      refetchOnMount: false,
    },
  );

  const postAppDesignCustomWidgetAssetMutation = useMutation(
    async ({
      widgetId,
      style,
      predefinedId,
      asset,
      originalAsset,
    }: {
      widgetId: number;
      style: WidgetViewStyle;
      predefinedId?: string | number;
      asset: File | string;
      originalAsset?: File;
    }) => {
      const formData = new FormData();
      const file = asset instanceof File ? asset : await getFileFromUrl(asset);
      formData.append('asset', file, file.name ?? '');
      if (originalAsset) {
        formData.append('originalAsset', originalAsset, originalAsset.name ?? file.name ?? '');
      }
      if (predefinedId) {
        formData.append('assetPredefinedId', String(predefinedId));
      }
      return postWidgetAsset({
        payload: formData,
        urlParams: { applicationUuid: appUuid, widgetId, style },
      });
    },
  );

  const createOrEditAllWidgetsMutation = useMutation(
    async (widgets: AppDesignLayoutTabWidget[]) => {
      const widgetsForMutate = widgets.map(widget => ({
        ...widget,
        id: typeof widget.id === 'number' ? widget.id : null,
        preferences: widget.preferences ? { ...widget.preferences, asset: undefined } : null,
      }));

      const updatedWidgets = await postAllWidgets({
        urlParams: { applicationUuid: String(appUuid) },
        payload: widgetsForMutate,
      });

      const widgetWithAsset = widgets
        .filter(
          ({ preferences }) =>
            preferences?.asset &&
            (preferences.asset.file || (preferences.asset.link && !isGStoreResource(preferences.asset.link))),
        )
        .map(it => {
          if (typeof it.id !== 'number') {
            // if new widget then find id by combination of unique params
            const newId = updatedWidgets.data.find(widget => {
              return (
                it.tabId === widget.tabId &&
                it.order === widget.order &&
                (it.groupId ? it.groupId === widget.groupId : true)
              );
            })?.id;
            return {
              ...it,
              id: newId,
            };
          }
          return it;
        })
        .filter(({ id }) => id);

      if (widgetWithAsset.length) {
        const widgetsWithAssetResult = await Promise.all(
          widgetWithAsset.map(({ id, preferences }) => {
            return postAppDesignCustomWidgetAssetMutation.mutateAsync({
              widgetId: id as number,
              style: preferences?.style,
              predefinedId: preferences?.asset?.id,
              asset: preferences?.asset?.file ?? preferences?.asset?.link,
              originalAsset: preferences?.asset?.originalFile,
            });
          }),
        );
        return updatedWidgets.data.map(
          widget => widgetsWithAssetResult.find(it => it.data?.id === widget.id)?.data || widget,
        );
      }

      return updatedWidgets.data;
    },
    {
      onSuccess: data => replaceAllWidgetsInCache(data),
    },
  );

  const deleteWidgetsMutation = useMutation(
    async ({ ids }: { ids: number[]; shouldReplaceState: boolean }) =>
      deleteWidgets({ urlParams: { applicationUuid: String(appUuid) }, payload: { ids } }),
    {
      onSuccess: (_, { ids, shouldReplaceState }) => {
        if (shouldReplaceState) {
          queryClient.setQueryData(appDesignTabsWidgetsKeys.list(appUuid), (prevData: any) => ({
            ...prevData,
            data: prevData.data.filter(it => !ids.includes(it.id)),
          }));
        }
      },
    },
  );

  const relieveWidgets = useCallback(
    async (widgets: AppDesignLayoutTabWidget[], shouldReplaceState = true) => {
      // if feature has some preferences saved, then do not delete it but only disable
      const customizedWidgetsToHide = widgets
        .filter(widget => widget.preferences && !isEmpty(widget.preferences))
        .map(result => ({ ...result, disabled: true, tabId: null, groupId: null }));

      // other features with empty preferences could be deleted
      const widgetIdsToDelete = widgets
        .filter(widget => !customizedWidgetsToHide.find(({ id }) => id === widget.id))
        .map(widget => widget.id as number);

      if (customizedWidgetsToHide.length) {
        await createOrEditAllWidgetsMutation.mutateAsync(customizedWidgetsToHide);
      }

      if (widgetIdsToDelete.length) {
        await deleteWidgetsMutation.mutateAsync({ ids: widgetIdsToDelete, shouldReplaceState });
      }
    },
    [deleteWidgetsMutation, createOrEditAllWidgetsMutation],
  );

  return {
    allWidgetsQuery,
    createOrEditAllWidgetsMutation,
    deleteWidgetsMutation,
    relieveWidgets,
  };
};

export default useAppDesignTabsWidgets;
