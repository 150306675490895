import React, { PropsWithChildren } from 'react';

type Props = {
  // eslint-disable-next-line react/no-unused-prop-types
  value: any;
} & PropsWithChildren;

export const SwitchChildrenItem: React.FC<Props> = ({ children }) => {
  return <>{children}</>;
};
