import { appDesignTabsCount } from '@constants';
import { allSettingsProgressStepsCount, getIsAppleDevProgramAvailable } from '@helpers';
import {
  ApplicationDesignStatus,
  ApplicationPackage,
  ApplicationSearchDto,
  ApplicationSettingsStatus,
  ApplicationType,
  FormProgress,
} from '@types';

export const applicationTypeOptions = [
  {
    value: ApplicationType.Standalone,
    label: `apps.labels.appType.${ApplicationType.Standalone}`,
  },
  {
    value: ApplicationType.Container,
    label: `apps.labels.appType.${ApplicationType.Container}`,
  },
];

export const appPackageBasicOption = {
  value: ApplicationPackage.Basic,
  label: `apps.labels.appPackage.${ApplicationPackage.Basic}`,
};

export const appPackageAdvancedOption = {
  value: ApplicationPackage.Advanced,
  label: `apps.labels.appPackage.${ApplicationPackage.Advanced}`,
};

export const appPackageProOption = {
  value: ApplicationPackage.Pro,
  label: `apps.labels.appPackage.${ApplicationPackage.Pro}`,
};

const availableSettingsStatuses = {
  [ApplicationSettingsStatus.SUBMITTED]: [ApplicationSettingsStatus.IN_REVIEW],
  [ApplicationSettingsStatus.IN_REVIEW]: [
    ApplicationSettingsStatus.APPROVED,
    ApplicationSettingsStatus.REJECTED,
    ApplicationSettingsStatus.SUBMITTED,
  ],
  [ApplicationSettingsStatus.APPROVED]: [ApplicationSettingsStatus.SUBMITTED],
};

export const getAvailableSettingsStatuses = (status: string): { name: any; title: string }[] => {
  const localize = (name: ApplicationSettingsStatus) => ({ name, title: `apps.labels.settingsStatus.${name}` });
  return availableSettingsStatuses[status]?.map(it => localize(it)) || [];
};

const availableDesignStatuses = {
  [ApplicationDesignStatus.SUBMITTED]: [ApplicationDesignStatus.IN_REVIEW],
  [ApplicationDesignStatus.IN_REVIEW]: [
    ApplicationDesignStatus.APPROVED,
    ApplicationDesignStatus.REJECTED,
    ApplicationDesignStatus.SUBMITTED,
  ],
  [ApplicationDesignStatus.APPROVED]: [ApplicationDesignStatus.SUBMITTED],
};

export const getAvailableDesignStatuses = (status: string): { name: any; title: string }[] => {
  const localize = (name: ApplicationDesignStatus) => ({ name, title: `apps.labels.settingsStatus.${name}` });
  return availableDesignStatuses[status]?.map(it => localize(it)) || [];
};

export const getDesignProgress = ({
  designStatus,
  designFormProgress,
}: ApplicationSearchDto): FormProgress | undefined =>
  designStatus === ApplicationDesignStatus.NOT_COMPLETED
    ? designFormProgress || { doneSteps: 0, allSteps: appDesignTabsCount }
    : undefined;

export const getSettingsProgress = ({
  settingsStatus,
  settingsFormProgress,
  appType,
}: ApplicationSearchDto): FormProgress | undefined =>
  settingsStatus === ApplicationSettingsStatus.NOT_COMPLETED
    ? settingsFormProgress || {
        doneSteps: 0,
        allSteps: allSettingsProgressStepsCount(getIsAppleDevProgramAvailable(appType)),
      }
    : undefined;
