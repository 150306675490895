import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Option } from '@egym/types';
import { MenuItem, MenuList, TableColumn } from '@egym/ui';

type Props = {
  column: TableColumn;
  handleClose?: () => void; // passed from Popover
  options: Option[];
  selectedValue: any;
  onSelect: (value: Option['value']) => void;
};

const TableFilterDropdownMenuList: React.FC<Props> = ({ column, options, onSelect, selectedValue, handleClose }) => {
  const { t } = useTranslation();

  return (
    <MenuList
      sx={{
        maxHeight: '200px',
        width: '100%',
        overflow: 'auto',
        p: 0,
        textAlign: 'center',
      }}
      data-testid={`table-filter-${column.field}-select-options`}
    >
      {options.map(({ label, value }) => {
        return (
          <MenuItem
            selected={value === selectedValue}
            key={value}
            onClick={() => {
              onSelect(value);
              if (handleClose) handleClose();
            }}
            data-testid={`table-filter-${column.field}-select-option-${value}`}
          >
            {t(label) as ReactNode}
          </MenuItem>
        );
      })}
    </MenuList>
  );
};

export default TableFilterDropdownMenuList;
