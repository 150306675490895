import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const MainTabHeaderDefaultIcon27: React.FC<SvgIconProps> = ({ htmlColor = '#FFF', sx, ...props }) => {
  return (
    <SvgIcon viewBox="0 0 48 48" {...props} sx={{ width: '48px', height: '48px', ...sx }}>
      <g fill={htmlColor} fillRule="evenodd">
        <path
          d="M24 5a2 2 0 1 1 0 4C15.716 9 9 15.716 9 24c0 8.284 6.716 15 15 15 8.284 0 15-6.716 15-15a2 2 0 1 1 4 0c0 10.493-8.507 19-19 19S5 34.493 5 24 13.507 5 24 5z"
          opacity=".6"
        />
        <path d="M24 13a2 2 0 1 1 0 4 7 7 0 1 0 6.996 7.24L31 24a2 2 0 1 1 4 0c0 6.075-4.925 11-11 11s-11-4.925-11-11 4.925-11 11-11zm11.5-8A1.5 1.5 0 0 1 37 6.5v2.378l2.94-2.939a1.5 1.5 0 0 1 2.224 2.008l-.103.114-2.94 2.938L41.5 11a1.5 1.5 0 0 1 0 3l-5.379-.001-11.06 11.062a1.5 1.5 0 0 1-2.225-2.008l.103-.114L34 11.878V6.5A1.5 1.5 0 0 1 35.5 5z" />
      </g>
    </SvgIcon>
  );
};
