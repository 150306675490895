import { createApiRequest } from '@egym/api';
import { AppDesignLayoutTab, AppDesignLayoutTabPayload } from '@types';
import {
  APPLICATION_DESIGN_TABS_BASE_URL,
  APPLICATION_DESIGN_TAB_BY_ID_URL,
  APPLICATION_DESIGN_TAB_ICON_URL,
} from './constants';

export const getAppDesignCustomTabs = createApiRequest<AppDesignLayoutTab[], unknown, { applicationUuid: string }>(
  APPLICATION_DESIGN_TABS_BASE_URL,
);

export const postAppDesignCustomTabs = createApiRequest<
  AppDesignLayoutTab[],
  AppDesignLayoutTab[],
  { applicationUuid: string }
>(APPLICATION_DESIGN_TABS_BASE_URL, 'post');

export const putAppDesignCustomTabs = createApiRequest<
  AppDesignLayoutTab[],
  AppDesignLayoutTab[],
  { applicationUuid: string }
>(APPLICATION_DESIGN_TABS_BASE_URL, 'put');

export const deleteAppDesignCustomTabs = createApiRequest<unknown, unknown, { applicationUuid: string }>(
  APPLICATION_DESIGN_TABS_BASE_URL,
  'delete',
);

export const putAppDesignCustomTabById = createApiRequest<
  AppDesignLayoutTab,
  AppDesignLayoutTabPayload,
  { applicationUuid: string; tabId: number }
>(APPLICATION_DESIGN_TAB_BY_ID_URL, 'put');

export const deleteAppDesignCustomTabById = createApiRequest<
  unknown,
  unknown,
  { applicationUuid: string; tabId: number }
>(APPLICATION_DESIGN_TAB_BY_ID_URL, 'delete');

export const postAppDesignCustomTabIcon = createApiRequest<
  AppDesignLayoutTab,
  FormData, // { image: binary }
  { applicationUuid: string; tabId: number }
>(APPLICATION_DESIGN_TAB_ICON_URL, 'post');
