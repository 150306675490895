import React, { useMemo } from 'react';
import { SxProps } from '@mui/system';
import { FieldProps } from 'formik';
import { FormFieldWithLabel, FormFieldWithLabelProps } from '@egym/ui';
import { getByStringPath } from '@egym/utils';
import FileUpload from '../FileUpload';
import { FileUploadContainerProps } from '../FileUpload/FileUploadProps';

type Props = {
  wrapperSx?: SxProps;
  fieldSx?: SxProps;
  labelSx?: SxProps;
  label?: string;
  hiddenLabel?: boolean;
  isViewMode?: boolean;
  disabled?: boolean;
  previewResult?: boolean;
  helperText?: string;
} & FormFieldWithLabelProps &
  FieldProps &
  FileUploadContainerProps;

export const FileUploadFormField: React.FC<Props> = ({
  wrapperSx,
  labelSx,
  fieldSx,
  label,
  hiddenLabel,
  isViewMode,
  disabled,
  helperText,
  field,
  form,
  disablePreview,
  paneSx,
  labelTypographyProps,
  ...rest
}) => {
  const getByFieldName = useMemo(() => getByStringPath(field.name), [field.name]);

  return (
    <FormFieldWithLabel
      wrapperSx={wrapperSx}
      labelSx={labelSx}
      label={label}
      hiddenLabel={hiddenLabel}
      isViewMode={isViewMode}
      labelTypographyProps={labelTypographyProps}
    >
      {({ fieldSx: labelFieldSx, helperTextSx: labelHelperTextSx }) => {
        const fileUploadSx = { ...labelFieldSx, ...fieldSx };

        return (
          <FileUpload
            {...rest}
            {...field}
            error={Boolean(getByFieldName(form.errors))}
            helperText={helperText || getByFieldName(form.errors)}
            setError={error => form.setFieldError(field.name, error)}
            FormHelperTextProps={{
              sx: labelHelperTextSx,
              ...rest.FormHelperTextProps,
            }}
            isViewMode={isViewMode}
            paneSx={paneSx}
            disabled={disabled}
            disablePreview={disablePreview}
            fileUploadWrapperSx={fileUploadSx}
          />
        );
      }}
    </FormFieldWithLabel>
  );
};
