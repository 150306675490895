import { useCallback, useEffect, useRef, useState } from 'react';
import useThrottledOnScroll from '../useThrottledOnScroll';

const getItemsClient = items => items.map(({ hash }) => ({ hash, node: document.getElementById(hash) }));

const useScrollSpy = (ref, items) => {
  const itemsWithNodeRef = useRef([]);
  useEffect(() => {
    itemsWithNodeRef.current = getItemsClient(items);
  }, [items]);

  const [activeState, setActiveState] = useState(null);

  const findActiveIndex = useCallback(() => {
    let active;

    for (let i = itemsWithNodeRef.current.length - 1; i >= 0; i -= 1) {
      const item = itemsWithNodeRef.current[i] as any;

      if (process.env.NODE_ENV !== 'production') {
        if (!item.node) {
          // eslint-disable-next-line no-console
          console.error(`Missing node on the item ${JSON.stringify(item, null, 2)}`);
        }
      }

      if (item.node && item.node.offsetTop <= ref.current.scrollTop + ref.current.clientHeight / 4) {
        active = item;
        break;
      }
    }

    if (active && activeState !== active.hash) {
      setActiveState(active.hash);
    }
  }, [activeState, ref]);

  useThrottledOnScroll(ref, items.length > 0 ? findActiveIndex : null, 166);

  return activeState;
};

export default useScrollSpy;
