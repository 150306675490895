import React from 'react';
import { useUpdateEffect } from 'react-use';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { useRecoilValue } from 'recoil';
import { ConfirmDialog, Snackbar, FormDialog, ContentDialog, localeState, dateLocaleMap, Drawer } from '@egym/ui';
import { DrawerProvider } from 'src/packages/ui/src/hooks/useDrawer';
import { appUuidState } from '@globalState';
import Layout from './Layout';

type Props = {
  handleNewRecoilKey: (newKey?: string) => void;
};

const Root: React.FC<Props> = ({ handleNewRecoilKey }) => {
  const currentLocale = useRecoilValue(localeState.atoms.currentLocale);

  const appUuid = useRecoilValue(appUuidState.atoms.appUuid);

  useUpdateEffect(() => {
    handleNewRecoilKey(appUuid);
  }, [appUuid]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={dateLocaleMap[currentLocale]}>
      <DrawerProvider>
        <Layout />
        <Drawer appUuid={appUuid} />
      </DrawerProvider>
      <ConfirmDialog />
      <FormDialog />
      <ContentDialog />
      <Snackbar />
      <ReactQueryDevtools initialIsOpen={false} />
    </LocalizationProvider>
  );
};

export default Root;
