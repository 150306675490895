import React, { ReactNode } from 'react';
import { TFunction } from 'i18next';
import { Stack, Box } from '@egym/ui';
import { AppPreviewerPalette } from '../../../../AppPreviewerProps';

type Props = {
  tFunction: TFunction;
  palette: Partial<AppPreviewerPalette>;
  avatar: string;
  fullName?: string;
  dateHours: number;
  dateMinutes: number;
};

const PreviewActivityFeedItemCompletedWorkout: React.FC<Props> = ({
  tFunction,
  avatar,
  fullName,
  dateHours,
  dateMinutes,
}) => {
  return (
    <Stack
      direction="row"
      justifyContent="flex-start"
      sx={{ pl: '9px', pr: '7px', py: '10px', ':not(:last-child)': { borderBottom: '1px solid rgb(244, 244, 244)' } }}
    >
      <Box sx={{ position: 'relative', minWidth: '38px', width: '38px', height: '38px', mr: '8px', mt: '2px' }}>
        <Box component="img" src={avatar} sx={{ width: '100%', height: '100%' }} />
      </Box>

      <Stack sx={{ fontSize: '13px', color: 'rgb(33, 33, 33)', textAlign: 'left' }}>
        <span>
          <strong style={{ fontWeight: 700 }}>{fullName}</strong>{' '}
          {tFunction('appPreviewer.labels.completedWorkout') as ReactNode}
        </span>
        <span style={{ marginTop: '4px' }}>+ {tFunction('appPreviewer.labels.more', { count: 2 }) as ReactNode}</span>
        <span style={{ fontSize: '11px', fontWeight: 500, marginTop: '5px', color: 'rgb(76, 74, 74)' }}>
          {dateHours
            ? (tFunction('appPreviewer.labels.durationHoursMinutesShortAgo', {
                hours: dateHours,
                minutes: dateMinutes,
              }) as ReactNode)
            : (tFunction('appPreviewer.labels.durationMinutesShortAgo', {
                hours: dateHours,
                minutes: dateMinutes,
              }) as ReactNode)}
        </span>
      </Stack>
    </Stack>
  );
};

export default PreviewActivityFeedItemCompletedWorkout;
