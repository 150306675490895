import { FC, ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { alpha } from '@mui/material';
import { Stack, Typography } from '@egym/ui';
import {
  IllustrationFeatureSizeChangeRequired,
  IllustrationWebWidgetLayoutChangeFromHorizontalToVertical,
  IllustrationWebWidgetLayoutChangeFromVerticalToHorizontal,
} from '@svg';
import { Orientation } from '@types';

type Props = {
  label: string;
  labelParams: {
    widgetName: string;
    anotherWidgetName?: string;
    destinationTabName?: string;
    destinationGroupName?: string;
    fromLayout?: Orientation;
    toLayout?: Orientation;
  };
};

const FeatureSizeChangeRequiredDialogText: FC<Props> = ({ label, labelParams }) => {
  const { t } = useTranslation();

  const Illustration = useMemo(() => {
    switch (labelParams.fromLayout) {
      case Orientation.Vertical:
        return IllustrationWebWidgetLayoutChangeFromVerticalToHorizontal;
      case Orientation.Horizontal:
        return IllustrationWebWidgetLayoutChangeFromHorizontalToVertical;
      default:
        return IllustrationFeatureSizeChangeRequired;
    }
  }, [labelParams.fromLayout]);

  const labelParamsResult = useMemo(() => {
    return {
      ...labelParams,
      fromLayout:
        (labelParams.fromLayout &&
          (labelParams.fromLayout === Orientation.Vertical
            ? t('common.labels.vertical')
            : t('common.labels.horizontal'))) ||
        undefined,
      toLayout:
        (labelParams.toLayout &&
          (labelParams.toLayout === Orientation.Vertical
            ? t('common.labels.vertical')
            : t('common.labels.horizontal'))) ||
        undefined,
    };
  }, [labelParams, t]);

  return (
    <Stack spacing={5} data-testid="feature-change-required">
      <Typography data-testid="feature-change-required-title" variant="body1">
        {t(label, labelParamsResult) as ReactNode}
      </Typography>
      <Stack
        alignItems="center"
        justifyContent={labelParams.fromLayout ? 'flex-end' : 'center'}
        sx={{
          bgcolor: ({ palette: { warning } }) => alpha(warning.main, 0.05),
          height: '220px',
          width: '576px',
          borderRadius: 1,
        }}
      >
        <Illustration />
      </Stack>
    </Stack>
  );
};

export default FeatureSizeChangeRequiredDialogText;
