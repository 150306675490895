import React, { Dispatch, MutableRefObject, SetStateAction, useCallback, useRef } from 'react';
import { generateGradientStyle } from '../../../helpers';
import { GradientPickerType, GradientPoint } from '../../../types';
import Pointer from '../../Pointer';

const getPercentage = (current, max) => (100 * current) / max;

type Props = {
  points: GradientPoint[];
  point: GradientPoint;
  isActive: boolean;
  setActiveGradientPointIndex: Dispatch<SetStateAction<number>>;
  updateGradientLeft: (left: number, index: number) => void;
  index: number;
  sliderRef: MutableRefObject<HTMLDivElement | null>;
  disabled?: boolean;
};

const GradientSliderPoint: React.FC<Props> = ({
  points,
  point,
  isActive,
  setActiveGradientPointIndex,
  index,
  updateGradientLeft,
  sliderRef,
  disabled,
}) => {
  const pointRef = useRef<HTMLDivElement>(null);
  const diff = React.useRef<number>(null);

  const handleMouseMove = useCallback(
    event => {
      if (!diff.current || !sliderRef.current || !pointRef.current) return;

      let newX = event.clientX - (diff.current || 0) - sliderRef.current.getBoundingClientRect().left;

      const end = sliderRef.current.offsetWidth - (pointRef.current?.offsetWidth || 0);

      newX = Math.min(Math.max(newX, 0), end);

      const newPercentage = getPercentage(newX, end);

      // eslint-disable-next-line no-param-reassign
      sliderRef.current.style.background = generateGradientStyle(
        points.map((p, idx) => ({ ...p, left: idx === index ? newPercentage : p.left })),
        GradientPickerType.Linear,
        90,
      );
      pointRef.current.style.left = `${newPercentage}%`;
    },
    [sliderRef, index, points],
  );

  const handleMouseUp = useCallback(() => {
    const percent = Number(pointRef.current?.style.left.match(/[0-9]+/)?.[0] || 0);
    updateGradientLeft(percent, index);

    // @ts-ignore
    // eslint-disable-next-line no-param-reassign
    sliderRef.current.style.background = undefined;
    document.removeEventListener('mouseup', handleMouseUp);
    document.removeEventListener('mousemove', handleMouseMove);
  }, [handleMouseMove, updateGradientLeft, index, sliderRef]);

  const handleMouseDown = useCallback(
    event => {
      setActiveGradientPointIndex(index);

      if (disabled) return;
      // @ts-ignore
      diff.current = event.clientX - pointRef.current.getBoundingClientRect().left;
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    },
    [disabled, setActiveGradientPointIndex, index, handleMouseMove, handleMouseUp],
  );

  return (
    <Pointer
      ref={pointRef}
      wrapperSx={{ position: 'absolute', transform: 'translateX(-50%)', boxShadow: 5 }}
      style={{ left: `${point.left}%` }}
      onMouseDown={handleMouseDown}
      checked={isActive}
    />
  );
};

export default GradientSliderPoint;
