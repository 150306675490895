import React from 'react';
import useTableBodyEditableCell from './hooks/useTableBodyEditableCell';
import TableBodyEditableCell from './TableBodyEditableCell';
import { TableBodyEditableCellContainerProps } from './TableBodyEditableCellProps';

const TableBodyEditableCellContainer: React.FC<TableBodyEditableCellContainerProps> = props => {
  const tableBodyEditableCellProps = useTableBodyEditableCell(props);

  return <TableBodyEditableCell {...props} {...tableBodyEditableCellProps} />;
};

export default TableBodyEditableCellContainer;
