export const panelHeaderStyles = {
  py: 2.5,
  px: 5,
  bgcolor: 'grey.100',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderTopRightRadius: '4px',
  borderTopLeftRadius: '4px',
};

export const panelContentStyles = {
  p: 5,
};

export const panelFooterStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  py: 1,
  px: 5,
  bgcolor: 'grey.100',
  borderBottomLeftRadius: '4px',
  borderBottomRightRadius: '4px',
};
