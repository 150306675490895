import { Option } from '@egym/types';
import { ApplicationStatus, DemoApplication } from '@types';

export const adminDashboardTabs: Option[] = [
  {
    label: `apps.labels.status.${ApplicationStatus.InProgress}`,
    value: ApplicationStatus.InProgress,
  },
  {
    label: `apps.labels.status.${ApplicationStatus.Released}`,
    value: ApplicationStatus.Released,
  },
  {
    label: 'apps.labels.demo.title',
    value: DemoApplication.Demo,
  },
  {
    label: `apps.labels.status.${ApplicationStatus.Archived}`,
    value: ApplicationStatus.Archived,
  },
];
