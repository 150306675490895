import React from 'react';
import FileUpload from './FileUpload';
import { FileUploadContainerProps } from './FileUploadProps';
import useFileUpload from './hooks/useFileUpload';

const FileUploadContainer: React.FC<FileUploadContainerProps> = props => {
  const fileUploadProps = useFileUpload(props);

  return <FileUpload {...props} {...fileUploadProps} />;
};

export default FileUploadContainer;
