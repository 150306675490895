import { useRecoilCallback, useRecoilValue } from 'recoil';
import { getUrlFromFile } from '@egym/utils';
import { ApplicationLayout, ApplicationPackage } from '@types';
import { AppPreviewerPalette, AppPreviewerSizeProps, PreviewerRoute, PreviewerState } from '../../AppPreviewerProps';
import { appPreviewerState } from '../../state';

const useAppPreviewerState = (id: string) => {
  const previewerState = useRecoilValue(appPreviewerState.atoms.previewer(id));
  const palette = useRecoilValue(appPreviewerState.selectors.palette(id));
  const containerSize = useRecoilValue(appPreviewerState.selectors.size(id));

  const changeCurrentRoute = useRecoilCallback(
    ({ set }) =>
      (to: PreviewerRoute) => {
        set(appPreviewerState.atoms.previewer(id), prev => ({ ...prev, route: to }));
      },
    [id],
  );

  const changePalette = useRecoilCallback(
    ({ set }) =>
      (newPalette: AppPreviewerPalette) => {
        set(appPreviewerState.atoms.previewer(id), prev => ({ ...prev, palette: newPalette }));
      },
    [id],
  );

  const changeSize = useRecoilCallback(
    ({ set }) =>
      (newSize: AppPreviewerSizeProps['size']) => {
        set(appPreviewerState.atoms.previewer(id), prev => ({ ...prev, size: newSize }));
      },
    [id],
  );

  const changePackage = useRecoilCallback(
    ({ set }) => {
      return (appPackage: ApplicationPackage) => {
        set(appPreviewerState.atoms.previewer(id), prev => ({ ...prev, appPackage }));
      };
    },
    [id],
  );

  const changeLayout = useRecoilCallback(
    ({ set }) => {
      return (appLayout: ApplicationLayout) => {
        set(appPreviewerState.atoms.previewer(id), prev => ({ ...prev, appLayout }));
      };
    },
    [id],
  );

  const changeSelectedTab = useRecoilCallback(
    ({ set }) => {
      return (tabId: number) => {
        set(appPreviewerState.atoms.previewer(id), prev => ({ ...prev, selectedTab: tabId }));
      };
    },
    [id],
  );

  const changeAppIconLink = useRecoilCallback(
    ({ set }) => {
      return async (appIcon: string | File[]) => {
        if (!appIcon) return;

        const appIconLink = await getUrlFromFile(appIcon);

        set(appPreviewerState.atoms.previewer(id), prev => {
          return { ...prev, appIconLink };
        });
      };
    },
    [id],
  );

  const changeMainTabIconLink = useRecoilCallback(
    ({ set }) => {
      return async (mainTabIcon: string | File[] | null | undefined) => {
        const mainTabIconLink = mainTabIcon ? await getUrlFromFile(mainTabIcon) : null;

        set(appPreviewerState.atoms.previewer(id), prev => {
          return { ...prev, mainTabIconLink };
        });
      };
    },
    [id],
  );

  const changeAppIconBackground = useRecoilCallback(
    ({ set }) => {
      return (appIconBackground: string) => {
        set(appPreviewerState.atoms.previewer(id), prev => ({ ...prev, appIconBackground }));
      };
    },
    [id],
  );

  const changeSelectedLocale = useRecoilCallback(
    ({ set }) => {
      return (selectedLocale: string) => {
        if (selectedLocale) {
          set(appPreviewerState.atoms.previewer(id), prev => ({ ...prev, selectedLocale }));
        }
      };
    },
    [id],
  );

  const changePreviewerState = useRecoilCallback(
    ({ set }) => {
      return async (partialPreviewerState: Partial<PreviewerState>) => {
        if (partialPreviewerState?.signInScreenProps?.signInBackgroundImage) {
          // eslint-disable-next-line no-param-reassign
          partialPreviewerState.signInScreenProps = {
            ...partialPreviewerState.signInScreenProps,
            signInBackgroundImage: await getUrlFromFile(partialPreviewerState.signInScreenProps?.signInBackgroundImage),
          };
        }

        if (partialPreviewerState?.signInScreenProps?.signInLogo) {
          // eslint-disable-next-line no-param-reassign
          partialPreviewerState.signInScreenProps = {
            ...partialPreviewerState.signInScreenProps,
            signInLogo: await getUrlFromFile(partialPreviewerState.signInScreenProps?.signInLogo),
          };
        }

        set(appPreviewerState.atoms.previewer(id), prev => ({ ...prev, ...partialPreviewerState }));
      };
    },
    [id],
  );

  const changeDisabled = useRecoilCallback(
    ({ set }) => {
      return (disabled: boolean) => {
        set(appPreviewerState.atoms.previewer(id), prev => ({ ...prev, disabled }));
      };
    },
    [id],
  );

  return {
    previewerState,
    palette,
    containerSize,
    changeCurrentRoute,
    changePalette,
    changeSize,
    changePackage,
    changeLayout,
    changeSelectedTab,
    changeAppIconLink,
    changeMainTabIconLink,
    changeAppIconBackground,
    changePreviewerState,
    changeSelectedLocale,
    changeDisabled,
  };
};

export default useAppPreviewerState;
