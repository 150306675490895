import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { Box, Stack } from '@egym/ui';
import { AppPreviewerPalette, PreviewWebWidget, PreviewWebWidgetContent } from '@components';
import { Orientation, WidgetViewStyle } from '@types';
import { LngNamespace } from 'src/i18n';
import useAppDesignState from 'src/pages/AppDesign/state/useAppDesignState';
import { convertToStyledWidgetState } from '../../../../hooks/useWidgetPreferencesForm/helpers';
import { WebWidgetPreferencesFormValues } from '../../WebWidgetPreferencesFormProps';

type Props = {
  previewerPalette: AppPreviewerPalette;
  orientation?: Orientation;
};

const PreviewWebWidgetStylesOptions: FC<Props> = ({ previewerPalette, orientation }) => {
  const { t: previewerT } = useTranslation(LngNamespace.Previewer) as any;
  const { previewerLocale } = useAppDesignState();
  const { values } = useFormikContext<WebWidgetPreferencesFormValues>();

  const widgetPropsOptions = useMemo(() => {
    return Object.values(WidgetViewStyle).map(style => {
      const widgetProps = convertToStyledWidgetState(style, values);

      return {
        style,
        widgetProps,
      };
    });
  }, [values]);

  const PreviewComponent = useMemo(() => {
    switch (orientation) {
      case Orientation.Vertical:
      case Orientation.Horizontal:
        return PreviewWebWidgetContent;
      default:
        return PreviewWebWidget;
    }
  }, [orientation]);

  return (
    <Stack direction="row" spacing={6} sx={{ justifyContent: 'space-between', mt: 4 }}>
      {widgetPropsOptions.map(({ style, widgetProps }) => (
        <Box
          key={style}
          sx={{
            p: 5,
            width: '50%',
            maxHeight: orientation === Orientation.Horizontal ? undefined : '142px',
            borderRadius: 3,
            overflow: 'hidden',
            backgroundImage: 'linear-gradient(to bottom, #fafafa, #f2f2f2)',
            fontFamily:
              'Inv Maison Neue, Maison Neue,"-apple-system", BlinkMacSystemFont, Open Sans, "open-sans","sans-serif"',
          }}
        >
          <PreviewComponent
            wrapperSx={{ p: 0 }}
            palette={previewerPalette}
            previewerState={{ selectedLocale: previewerLocale }}
            widgetProps={widgetProps}
            tFunction={previewerT}
            titleKey="title"
            titleFallback="appPreviewer.labels.webWidget"
            orientation={orientation}
            styles={
              orientation === Orientation.Horizontal
                ? { height: '276px', width: '100%', fontSize: '22px', padding: '25px' }
                : undefined
            }
          />
        </Box>
      ))}
    </Stack>
  );
};

export default PreviewWebWidgetStylesOptions;
