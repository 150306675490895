import { createApiRequest } from '@egym/api';
import { Paginated, Uuid } from '@egym/types';
import {
  ActualizeDemoApplicationFormValues,
  ApplicationDto,
  ApplicationFormValues,
  ApplicationSearchDto,
  ApplicationStatus,
  ApplicationStatusesCount,
  DemoApplicationFormValues,
} from '@types';

const APPLICATIONS_BASE_URL = 'applications';
const APPLICATION_BY_ID_URL = `${APPLICATIONS_BASE_URL}/:applicationUuid`;
const APPLICATION_STATUS_URL = `${APPLICATION_BY_ID_URL}/status`;
const APPLICATION_SETTINGS_STATUS_URL = `${APPLICATION_BY_ID_URL}/settings/status`;
const APPLICATION_DESIGN_STATUS_URL = `${APPLICATION_BY_ID_URL}/design/status`;
const APPLICATIONS_STATUSES_COUNT_URL = `${APPLICATIONS_BASE_URL}/statuses/count`;
const DEMO_APPLICATION_BASE_URL = `${APPLICATIONS_BASE_URL}/demo`;
const DEMO_APPLICATION_BY_ID_URL = `${DEMO_APPLICATION_BASE_URL}/:applicationUuid`;
const ACTUALIZE_DEMO_APPLICATION_BY_ID_URL = `${DEMO_APPLICATION_BASE_URL}/:applicationUuid/actualize`;

export const searchApplications = createApiRequest<Paginated<ApplicationSearchDto>>(APPLICATIONS_BASE_URL);

export const postApplication = createApiRequest<ApplicationDto, ApplicationFormValues>(APPLICATIONS_BASE_URL, 'post');

export const getAdminApplication = createApiRequest<ApplicationDto, unknown, { applicationUuid: Uuid }>(
  APPLICATION_BY_ID_URL,
);

export const putApplication = createApiRequest<ApplicationDto, ApplicationFormValues, { applicationUuid: Uuid }>(
  APPLICATION_BY_ID_URL,
  'put',
);

export const postApplicationStatus = createApiRequest<
  unknown,
  { status: ApplicationStatus },
  { applicationUuid: Uuid }
>(APPLICATION_STATUS_URL, 'post');

export const postSettingsStatus = createApiRequest<undefined, { status: string }, { applicationUuid: Uuid }>(
  APPLICATION_SETTINGS_STATUS_URL,
  'post',
);

export const postDesignStatus = createApiRequest<undefined, { status: string }, { applicationUuid: Uuid }>(
  APPLICATION_DESIGN_STATUS_URL,
  'post',
);

export const getApplicationsStatusesCount = createApiRequest<ApplicationStatusesCount>(APPLICATIONS_STATUSES_COUNT_URL);

export const postDemoApplication = createApiRequest<ApplicationDto, DemoApplicationFormValues>(
  DEMO_APPLICATION_BASE_URL,
  'post',
);

export const putDemoApplication = createApiRequest<
  ApplicationDto,
  DemoApplicationFormValues,
  { applicationUuid: Uuid }
>(DEMO_APPLICATION_BY_ID_URL, 'put');

export const actualizeDemoApplication = createApiRequest<
  ApplicationDto,
  ActualizeDemoApplicationFormValues,
  { applicationUuid: Uuid }
>(ACTUALIZE_DEMO_APPLICATION_BY_ID_URL, 'post');
