import React from 'react';
import { Trans } from 'react-i18next';
import { Stack, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { HintTile, Box } from '@egym/ui';
import { FileFormatName } from '@egym/utils';
import { Icons } from '../../../../icons';
import { uploadPaneStyles } from '../../FileUpload.styles';
import AvailableFileFormatsText from './components/AvailableFileFormatsText';

type Props = {
  wrapperSx?: SxProps;
  disabled?: boolean;
  hintsResult?: string[];
  accept?: FileFormatName[];
  acceptHints?: Record<FileFormatName, { tKey: string; tKeyParams?: Record<string, string | number> }[]>;
  acceptHintsCommon?: { tKey: string; tKeyParams?: Record<string, string | number> }[];
};

const FileUploadPane: React.FC<Props> = ({
  disabled,
  hintsResult,
  wrapperSx,
  accept,
  acceptHints,
  acceptHintsCommon,
}) => {
  return (
    <Stack sx={{ ...uploadPaneStyles(disabled), ...wrapperSx }}>
      <Icons.FileUpload color="inherit" fontSize="inherit" />
      <Typography variant="body1Bold" component="span" color="text.primary" sx={{ my: 2.5 }}>
        <Trans
          i18nKey="common.fileUpload.dropFile"
          components={[<Typography component="span" variant="body1Bold" sx={{ textDecoration: 'underline' }} />]}
        />
      </Typography>
      <AvailableFileFormatsText accept={accept} acceptHints={acceptHints} acceptHintsCommon={acceptHintsCommon} />
      {hintsResult && Boolean(hintsResult.length) && (
        <HintTile
          wrapperSx={{ width: 'auto', mt: 2, fontSize: '12px' }}
          texts={hintsResult}
          withWrapper={false}
          withIcons={false}
          textsTypographyProps={{ variant: 'caption', color: 'grey.600' }}
          textsWrapperSx={{ display: 'inline-block', fontSize: '12px', textAlign: 'center' }}
          textItemWrapperSx={{ display: 'inline', fontSize: '12px' }}
          getSeparator={translatedText => {
            if (!translatedText.endsWith('.'))
              return (
                <Box component="span" sx={{ mr: 1 }}>
                  .
                </Box>
              );
            return null;
          }}
        />
      )}
    </Stack>
  );
};

export default FileUploadPane;
