import React, { forwardRef, useEffect, useMemo } from 'react';
import { SafeHtml, SafeHtmlProps } from '@egym/ui';

export const SvgRenderer = forwardRef<any, SafeHtmlProps>(({ dirtyHtml, id, ...rest }, ref) => {
  const svgWrapperId = useMemo(() => (id ? `svg-renderer-${id}` : undefined), [id]);

  useEffect(() => {
    if (dirtyHtml && svgWrapperId) {
      setTimeout(() => {
        const svg = document.getElementById(svgWrapperId)?.getElementsByTagName('svg')?.[0];

        const bbox = svg?.getBBox();

        if (svg && bbox) {
          const viewBox = `${bbox.x} ${bbox.y} ${bbox.width} ${bbox.height}`;
          svg?.setAttribute('viewBox', viewBox);
        }
      }, 100);
    }
  }, [dirtyHtml, id, svgWrapperId]);

  return <SafeHtml ref={ref} id={svgWrapperId} dirtyHtml={dirtyHtml} {...rest} />;
});
