import { useCallback, useEffect, useMemo, useState } from 'react';
import { partialRight, pipe } from 'ramda';
import { getByStringPath } from '@egym/utils';
import { TableBodyEditableCellContainerProps, UseTableBodyEditableCellResult } from '../../TableBodyEditableCellProps';

const useTableBodyEditableCell = ({
  tdProps,
  column,
  row,
}: TableBodyEditableCellContainerProps): UseTableBodyEditableCellResult => {
  const [isEditing, setIsEditing] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const options = useMemo(
    () => (column.options && (typeof column.options === 'function' ? column.options(row) : column.options)) ?? null,
    [column, row],
  );

  const getByFieldName = useMemo(
    () =>
      column.toOption
        ? pipe(getByStringPath(column.field), partialRight(column.toOption, [options]))
        : getByStringPath(column.field),
    [column.field, column.toOption, options],
  );
  const [editValue, setEditValue] = useState();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const validateValue = useCallback(
    (value: any) => {
      try {
        if (column.validate) {
          column.validate(value);
        }
        return undefined;
      } catch (e) {
        return e.message;
      }
    },
    [column],
  );

  useEffect(() => {
    const value = getByFieldName(row);
    setEditValue(value);
    setErrorMessage(validateValue(value));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setEditValue, getByFieldName, row, options, validateValue, setErrorMessage]);

  const id = useMemo(() => `editable_cell_${column.field.replaceAll('.', '_')}`, [column.field]);

  const toggleIsEditing = useCallback(() => {
    setIsEditing(prevState => !prevState);
    setErrorMessage(undefined);
  }, []);

  const onChangeValue = useCallback(
    event => {
      setEditValue(event.target.value);
      setErrorMessage(validateValue(event.target.value));
    },
    [validateValue],
  );

  const submit = useCallback(async () => {
    const value = getByFieldName(row);
    const shouldReset = errorMessage || editValue === value;
    if (shouldReset) {
      setEditValue(value);
      setErrorMessage(validateValue(value));
    } else if (tdProps?.updateField && !errorMessage) {
      setIsSubmitting(true);
      try {
        await tdProps.updateField(column.field, editValue, row);
      } catch (error) {
        setEditValue(value);
        setErrorMessage(error?.localizedMessage);
      } finally {
        setIsSubmitting(false);
      }
    }
    setIsEditing(false);
  }, [tdProps, column.field, editValue, getByFieldName, row, errorMessage, setErrorMessage, validateValue]);

  const onInputBlur = useCallback(
    async event => {
      if (![`${id}_cancel`, `${id}_save`].includes(event.relatedTarget?.id)) {
        await submit();
      }
    },
    [id, submit],
  );

  const cancel = useCallback(() => {
    setIsEditing(false);
    setEditValue(getByFieldName(row));
    setErrorMessage(validateValue(getByFieldName(row)));
  }, [getByFieldName, row, validateValue]);

  const onInputKeyPress = useCallback(
    async event => {
      // on 'Enter' key press
      if (event.charCode === 13) {
        await submit();
      }
    },
    [submit],
  );

  return {
    isEditing,
    toggleIsEditing,
    onChangeValue,
    editValue,
    submit,
    cancel,
    id,
    onInputKeyPress,
    isSubmitting,
    onInputBlur,
    errorMessage,
    options,
  };
};

export default useTableBodyEditableCell;
