import React from 'react';
import AppDesignSharePreviewButton from './AppDesignSharePreviewButton';
import useAppDesignSharePreviewButton from './hooks/useAppDesignSharePreviewButton';

const AppDesignSharePreviewButtonContainer: React.FC = props => {
  const appDesignSharePreviewButtonProps = useAppDesignSharePreviewButton();

  return <AppDesignSharePreviewButton {...props} {...appDesignSharePreviewButtonProps} />;
};

export default AppDesignSharePreviewButtonContainer;
