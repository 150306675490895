import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const PlPlFlagIcon: React.FC<SvgIconProps> = ({ sx, ...props }) => {
  return (
    <SvgIcon viewBox="0 0 640 480" {...props} sx={{ width: '18px', ...sx }}>
      <g fillRule="evenodd">
        <path fill="#fff" d="M640 480H0V0h640z" />
        <path fill="#dc143c" d="M640 480H0V240h640z" />
      </g>
    </SvgIcon>
  );
};

export default PlPlFlagIcon;
