import React, { Suspense, useCallback, useState } from 'react';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';
import { StyledEngineProvider } from '@mui/material/styles';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { RecoilRoot } from 'recoil';
import { useTheme, ThemeProvider } from '@egym/ui';
import i18n from 'src/i18n';
import Root from 'src/pages/Root';
import 'src/shared/api/interceptors';

// https://react-query.tanstack.com/guides/important-defaults
const queryClient = new QueryClient({
  defaultOptions: { queries: { retry: false, refetchOnWindowFocus: false } },
  logger: {
    log: () => {},
    warn: () => {},
    error: () => {},
  },
});

const App: React.FC = () => {
  const { theme } = useTheme();

  const [recoilKey, setRecoilKey] = useState<string>();

  const handleNewRecoilKey = useCallback((newKey?: string) => {
    setRecoilKey(newKey);
  }, []);

  return (
    <BrowserRouter>
      <I18nextProvider i18n={i18n}>
        <QueryClientProvider client={queryClient}>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <Suspense fallback={<span />}>
                <RecoilRoot key={recoilKey}>
                  <Root handleNewRecoilKey={handleNewRecoilKey} />
                </RecoilRoot>
              </Suspense>
            </ThemeProvider>
          </StyledEngineProvider>
        </QueryClientProvider>
      </I18nextProvider>
    </BrowserRouter>
  );
};

export default App;
