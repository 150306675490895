import React from 'react';
import { Stack } from '@mui/material';
import { Box } from '@mui/system';

type Props = {
  checked?: boolean;
};

const PointerSmall: React.FC<Props> = ({ checked }) => {
  return (
    <Stack
      sx={{
        width: '8px',
        height: '8px',
        border: '2px solid',
        borderColor: 'common.white',
        borderRadius: '1px',
        boxSizing: 'border-box',
        mt: '1.5px',
        transform: 'translateX(-50%)',
        boxShadow: 3,
      }}
      alignItems="center"
      justifyContent="center"
    >
      {checked && <Box sx={{ width: '2px', height: '2px', bgcolor: 'common.white' }} />}
    </Stack>
  );
};

export default PointerSmall;
