import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const FaqAndSupportIcon: React.FC<SvgIconProps> = ({ sx, htmlColor = '#4C4A4A', ...rest }) => {
  return (
    <SvgIcon viewBox="0 0 24 24" sx={{ width: '24px', height: '24px', ...sx }} {...rest}>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <path d="M0 0H24V24H0z" transform="translate(-716 -764) translate(716 764)" />
            <path
              fill={htmlColor}
              d="M15 2c1.654 0 3 1.346 3 3v14c0 1.654-1.346 3-3 3H5c-1.654 0-3-1.346-3-3V5c0-1.654 1.346-3 3-3h10zm6 8c.552 0 1 .447 1 1v10l-1 1-1-1V11c0-.553.447-1 1-1zm-6-6H5c-.552 0-1 .448-1 1v14c0 .552.448 1 1 1h10c.552 0 1-.448 1-1V5c0-.552-.448-1-1-1zm-5 6c.552 0 1 .447 1 1 0 .553-.448 1-1 1H7c-.553 0-1-.447-1-1 0-.553.447-1 1-1h3zm3-4c.552 0 1 .447 1 1 0 .553-.448 1-1 1H7c-.553 0-1-.447-1-1 0-.553.447-1 1-1h6z"
              transform="translate(-716 -764) translate(716 764)"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export default FaqAndSupportIcon;
