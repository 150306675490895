import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Stack, UploadedImage } from '@egym/ui';
import { PreviewerRoute } from '../../AppPreviewerProps';
import useAppPreviewerState from '../../hooks/useAppPreviewerState';
import { IconWrapper } from '../Icons';
import ArrowBackIcon from '../Icons/ArrowBackIcon';
import PreviewContainer from '../PreviewContainer';

type Props = {
  // eslint-disable-next-line react/no-unused-prop-types
  value: PreviewerRoute;
  id: string;
};

const PreviewerAboutRoute: React.FC<Props> = ({ id }) => {
  const { t } = useTranslation();
  const {
    previewerState: { appIconLink, appIconBackground },
    palette,
  } = useAppPreviewerState(id);

  return (
    <Box style={{ background: '#f9f9f9', position: 'relative', height: '100%' }}>
      <Stack
        sx={{
          position: 'sticky',
          top: 0,
          background: 'white',
          height: '75px',
          pb: '8px',
          boxSizing: 'border-box',
          boxShadow: '0 0.5px 0 rgba(0, 0, 0, 0.1)',
          zIndex: 1,
        }}
        justifyContent="flex-end"
      >
        <PreviewContainer>
          <Stack
            direction="row"
            alignItems="center"
            sx={{
              width: '100%',
              background: 'common.white',
              position: 'relative',
              justifyContent: 'space-between',
            }}
          >
            <IconWrapper bgColor={palette.primaryTransparent}>
              <ArrowBackIcon htmlColor={palette.primary} />
            </IconWrapper>
            <span
              style={{
                fontSize: '16px',
                fontWeight: 500,
                color: '#212121',
              }}
            >
              {t('appPreviewer.labels.about') as ReactNode}
            </span>
            <IconWrapper bgColor={palette.primaryTransparent} wrapperSx={{ visibility: 'hidden' }}>
              <ArrowBackIcon htmlColor={palette.primary} />
            </IconWrapper>
          </Stack>
        </PreviewContainer>
      </Stack>
      <Stack
        className="hide-scrollbar"
        style={{
          width: '100%',
          boxSizing: 'border-box',
          height: 'calc(100% - 156px)',
          overflow: 'scroll',
        }}
      >
        <Stack alignItems="center" justifyContent="center" py="50px">
          <UploadedImage
            id={`previewer-about-${id}`}
            wrapperSx={{
              alignItems: 'center',
              justifyContent: 'center',
              width: '146px',
              height: '146px',
              background: appIconBackground || 'grey.200',
              borderRadius: '11px',
              padding: '20px',
              boxSizing: 'border-box',
            }}
            imageSx={{
              maxWidth: '100%',
              maxHeight: '100%',
            }}
            file={{ preview: appIconLink || '' }}
          />
        </Stack>
        <Stack sx={{ background: 'white', color: palette.primary, p: '15px', fontSize: '14px' }} spacing="15px">
          <span>{t('appPreviewer.labels.copyrightPolicy') as ReactNode}</span>
          <span>{t('appPreviewer.labels.privacyPolicy') as ReactNode}</span>
          <span>{t('appPreviewer.labels.termsOfUse') as ReactNode}</span>
          <span>{t('appPreviewer.labels.termsOfUseRewards') as ReactNode}</span>
        </Stack>
      </Stack>
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{
          background: 'white',
          height: '80px',
          boxSizing: 'border-box',
          zIndex: 1,
          color: '#4c4a4a',
          fontWeight: 500,
          paddingBottom: '20px',
        }}
      >
        <span style={{ fontSize: '11px', marginBottom: '2px' }}>
          {t('appPreviewer.labels.poweredByEGYM') as ReactNode}
        </span>
        <span style={{ fontSize: '11px' }}>
          {t('appPreviewer.labels.appVersion', { version: '1.38' }) as ReactNode}
        </span>
      </Stack>
    </Box>
  );
};

export default PreviewerAboutRoute;
