import React, { ReactNode } from 'react';
import { differenceInCalendarDays } from 'date-fns';
import { Paper, Stack, useFormatDate } from '@egym/ui';
import { getPercent } from '@egym/utils';
import { AppLayoutFeatureComponentProps } from '../../../../AppPreviewerProps';
import { CalendarIcon } from '../../../Icons';

type Props = AppLayoutFeatureComponentProps & {
  name: string;
  description1: string;
  description2: string;
  classDescription?: string;
  date: Date;
  spotsLeft: number;
  totalSpots: number;
  bookedClass?: boolean;
};

const ClassItem: React.FC<Props> = ({
  tFunction,
  palette,
  previewerState,
  name,
  description1,
  description2,
  classDescription,
  date,
  spotsLeft,
  totalSpots,
  bookedClass,
}) => {
  const { format } = useFormatDate(previewerState.selectedLocale);

  return (
    <Paper
      sx={{
        color: '#4C4A4A',
        marginBottom: '7px',
        borderRadius: '8px',
        boxShadow: '0 4px 6px rgba(223, 223, 223, 0.7)',
      }}
    >
      <Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          py="5px"
          pr="10px"
          pl="7px"
          borderBottom="1px solid rgb(236, 236, 236)"
        >
          <Stack direction="row" alignItems="center">
            <CalendarIcon />
            <span style={{ fontSize: '11px', marginLeft: '3px' }}>{format(date, 'ccc, LLL d p')}</span>
          </Stack>
          {bookedClass ? (
            <span
              style={{
                fontSize: '11px',
                fontWeight: 600,
                color: 'rgb(63, 175, 150)',
              }}
            >
              {
                tFunction('appPreviewer.labels.startIn', {
                  count: differenceInCalendarDays(date, new Date()),
                }) as ReactNode
              }
            </span>
          ) : (
            <Stack alignItems="flex-end">
              <span
                style={{
                  fontSize: '11px',
                  fontWeight: 600,
                  marginBottom: '2px',
                  color: spotsLeft <= 3 ? 'rgb(245, 166, 35)' : 'rgb(63, 175, 150)',
                }}
              >
                {tFunction('appPreviewer.labels.spotsLeft', { count: spotsLeft }) as ReactNode}
              </span>

              <div
                style={{
                  width: '70px',
                  height: '3px',
                  background: 'rgb(235, 235, 235)',
                  borderRadius: '2px',
                  overflow: 'hidden',
                }}
              >
                <div
                  style={{
                    width: `${getPercent(spotsLeft, totalSpots)}%`,
                    height: '100%',
                    background: spotsLeft <= 3 ? 'rgb(245, 166, 35)' : 'rgb(63, 175, 150)',
                    marginLeft: 'auto',
                  }}
                />
              </div>
            </Stack>
          )}
        </Stack>

        <Stack py="8px" px="10px" sx={{ color: 'rgb(76, 74, 74)', fontSize: '13px' }}>
          <span style={{ fontSize: '14px', fontWeight: 600, lineHeight: '20px', marginBottom: '1px' }}>
            {tFunction(name) as ReactNode}
          </span>
          <span style={{ marginBottom: '2px' }}>{tFunction(description1) as ReactNode}</span>
          <span style={{ marginBottom: '4px' }}>{tFunction(description2) as ReactNode}</span>
          {bookedClass && (
            <span style={{ marginBottom: '10px', color: palette.primary, fontWeight: 600 }}>
              {tFunction('appPreviewer.labels.addReminder') as ReactNode}
            </span>
          )}
          {bookedClass && (
            <Stack>
              <span style={{ fontSize: '12px', fontWeight: 600, lineHeight: '20px', marginBottom: '1px' }}>
                {tFunction('appPreviewer.labels.classDescription') as ReactNode}
              </span>
              {classDescription && (
                <span style={{ fontSize: '12px', marginBottom: '7px' }}>
                  {tFunction(classDescription) as ReactNode}
                </span>
              )}
            </Stack>
          )}
        </Stack>
      </Stack>
    </Paper>
  );
};

export default ClassItem;
