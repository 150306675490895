import { useCallback, useImperativeHandle, useMemo, useRef } from 'react';
import { FormikProps } from 'formik';
import { useRecoilValue } from 'recoil';
import { Language } from '@egym/types';
import { localeState, useLanguages } from '@egym/ui';
import { toLocaleApiFormat } from '@egym/utils';
import { AnalyticsEvents } from '@constants';
import { useClientApplicationDesign, useTrackEvent } from '@hooks';
import { AppDesignMenuItemEnum } from '@types';
import useAppDesignState from 'src/pages/AppDesign/state/useAppDesignState';
import { WidgetPreferencesFormContainerProps, WidgetPreferencesFormResult } from '../../WidgetPreferencesFormProps';

const useWidgetPreferencesForm = (
  { saveWidgets, applicationId }: WidgetPreferencesFormContainerProps,
  ref,
): WidgetPreferencesFormResult => {
  const innerRef = useRef<FormikProps<any>>(null);
  useImperativeHandle(
    ref,
    () => ({
      nestedFormRef: innerRef,
    }),
    [],
  );

  const onSubmit = useCallback(
    async (values, { resetForm }) => {
      await saveWidgets();
      // https://github.com/jaredpalmer/formik/issues/3129
      // https://codesandbox.io/s/formik-resetform-bug-repro-workaround-cn0w9
      resetForm({ values });
    },
    [saveWidgets],
  );

  const { appLocales } = useClientApplicationDesign(applicationId);
  const { languages } = useLanguages();

  const availableLanguages = useMemo(() => {
    const availableLocales = appLocales || ['en_US'];
    return (
      availableLocales
        .map(locale => toLocaleApiFormat(locale))
        .map(locale => languages.find(lang => toLocaleApiFormat(lang.locale) === locale) as Language)
        .map(lang => ({ ...lang, locale: toLocaleApiFormat(lang?.locale) })) || []
    );
  }, [appLocales, languages]);

  const currentLocale = toLocaleApiFormat(useRecoilValue(localeState.atoms.currentLocale));

  const { handleMenuItemChange } = useAppDesignState();
  const trackEvent = useTrackEvent(applicationId);
  const openLanguagesMenuTab = useCallback(
    async ({ dirty, submitForm, isValid }: FormikProps<any>) => {
      if (dirty && isValid) {
        await submitForm();
      }
      await handleMenuItemChange(AppDesignMenuItemEnum.AppLanguages);
      trackEvent(AnalyticsEvents.A_Design_Menu_Item_Selected, {
        selectedTab: AppDesignMenuItemEnum.AppLanguages,
      });
    },
    [handleMenuItemChange, trackEvent],
  );

  return {
    innerRef,
    onSubmit,
    appLocales,
    availableLanguages,
    currentLocale,
    openLanguagesMenuTab,
  };
};

export default useWidgetPreferencesForm;
