import { SxProps } from '@mui/system';

export const wrapperStyles: SxProps = {
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'center',
  flexDirection: 'column',
  width: '100%',
};

export const labelStyles = {
  pr: 2.5,
  width: 'auto',
  height: '37px',
  boxSizing: 'border-box',
  display: 'flex',
  alignItems: 'center',
};

export const fieldStyles: SxProps = {
  flex: 1,
  width: '100%',
};

export const viewModeFieldStyles: SxProps = {
  minHeight: '20px',
  display: 'flex',
  alignItems: 'center',
};

export const helperTextStyles: SxProps = {
  fontSize: '14px',
  lineHeight: '20px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
};
