import { SxProps } from '@mui/system';

export const wrapperStyles: SxProps = {
  position: 'relative',
};

export const contentStyles: SxProps = {
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  fontFamily: 'Inv Maison Neue, Maison Neue,"-apple-system", BlinkMacSystemFont, Open Sans, "open-sans","sans-serif"',
};
