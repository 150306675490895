import React, { CSSProperties, FC } from 'react';
import { UploadedImage } from '@egym/ui';
import { WidgetViewStyle } from '@types';
import Overlay from '../Overlay';

type Props = {
  background?: string;
  viewStyle?: WidgetViewStyle;
  title?: string;
  imagePath: string | null;
  assetColor?: string;
  styles?: CSSProperties;
};

const HorizontalContent: FC<Props> = ({ styles, background, viewStyle, assetColor, title, imagePath }) => {
  return (
    <div
      style={{
        background,
        height: '146px',
        width: '50%',
        minWidth: '132px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        color: 'white',
        padding: '15px',
        boxSizing: 'border-box',
        borderRadius: '8px',
        overflow: 'hidden',
        fontSize: '18px',
        position: 'relative',
        boxShadow: '0 4px 6px rgba(223, 223, 223, 0.7)',
        ...styles,
      }}
    >
      {viewStyle === WidgetViewStyle.Icon && imagePath && (
        <UploadedImage
          imageSx={{ maxHeight: '50px', maxWidth: '50px' }}
          file={{
            preview: imagePath,
            isSvg: true,
          }}
        />
      )}
      {viewStyle === WidgetViewStyle.Image && <Overlay assetColor={assetColor} />}
      <span
        style={{
          position: 'relative',
          fontSize: styles?.fontSize || (title && title.length > 20 ? '13px' : '14px'),
          fontWeight: 500,
          lineHeight: '22px',
          color: 'white',
          marginTop: 'auto',
        }}
      >
        {title}
      </span>
    </div>
  );
};

export default HorizontalContent;
