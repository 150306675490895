import React, { CSSProperties, FC } from 'react';
import { UploadedImage } from '@egym/ui';
import { WidgetViewStyle } from '@types';
import CallToAction, { CallToActionProps } from '../CallToAction';
import Overlay from '../Overlay';

type Props = {
  background?: string;
  viewStyle?: WidgetViewStyle;
  imagePath: string | null;
  assetColor?: string;
  styles?: CSSProperties;
  callToActionProps: CallToActionProps;
};

const VerticalContent: FC<Props> = ({ styles, background, viewStyle, callToActionProps, imagePath, assetColor }) => {
  return (
    <div
      style={{
        background,
        height: '80px',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        color: 'white',
        padding: '8px 15px',
        boxSizing: 'border-box',
        borderRadius: '8px',
        overflow: 'hidden',
        fontSize: '18px',
        position: 'relative',
        boxShadow: '0 4px 6px rgba(223, 223, 223, 0.7)',
        ...styles,
      }}
    >
      {viewStyle === WidgetViewStyle.Image && <Overlay assetColor={assetColor} />}
      <CallToAction {...callToActionProps} />
      {viewStyle === WidgetViewStyle.Icon && imagePath && (
        <UploadedImage
          wrapperSx={{ marginLeft: 'auto' }}
          imageSx={{ maxHeight: '50px', maxWidth: '50px' }}
          file={{
            preview: imagePath,
            isSvg: true,
          }}
        />
      )}
    </div>
  );
};

export default VerticalContent;
