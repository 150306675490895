import { LinearProgress, linearProgressClasses } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledLinearProgress = styled(LinearProgress, {
  shouldForwardProp: propName => propName !== 'realValue',
})<{ realValue: number }>`
  & .${linearProgressClasses.bar} {
    border-radius: 12px;
    background-color: ${props => (props.realValue ? '#17843B' : 'transparent')};
  }
`;
