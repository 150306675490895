import React, { useMemo, useState } from 'react';
import { useDebounce, useEffectOnce } from 'react-use';
import { AutocompleteProps, IconButton } from '@mui/material';
import { SxProps, TypographyProps } from '@mui/system';
import { FieldProps } from 'formik';
import { Option } from '@egym/types';
import {
  Autocomplete,
  ConditionalRender,
  FormFieldWithLabel,
  Icons,
  Loader,
  TextField,
  TextFieldProps,
  Typography,
} from '@egym/ui';
import { getByStringPath } from '@egym/utils';

type AutocompleteFormFieldProps = {
  label?: string;
  name: string;
  options: Option[];
  onLoadOptions?: (value: any) => Promise<void>;
  isLoading: boolean;
  wrapperSx?: SxProps;
  labelSx?: SxProps;
  fieldSx?: SxProps;
  viewModeFieldSx?: SxProps;
  isViewMode?: boolean;
  hiddenLabel?: boolean;
  showErrorFirst?: boolean;
  markAsOptional?: boolean;
  viewModeTypographyProps?: TypographyProps;
  inputProps: TextFieldProps;
};

type Props = AutocompleteProps<Option, any, any, any> & FieldProps & AutocompleteFormFieldProps;

export const AutocompleteFormField: React.FC<Props> = ({
  label,
  options,
  onLoadOptions,
  isLoading,
  wrapperSx,
  labelSx,
  fieldSx,
  viewModeFieldSx,
  hiddenLabel,
  id,
  isViewMode,
  showErrorFirst,
  markAsOptional,
  viewModeTypographyProps,
  field,
  form,
  inputProps,
  onChange,
  ...props
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const getByFieldName = useMemo(() => getByStringPath(field.name), [field.name]);
  const errorText = useMemo(
    () => getByFieldName(form.touched) && getByFieldName(form.errors),
    [form.touched, form.errors, getByFieldName],
  );

  const initialOption = useMemo<Option | null>(() => {
    const initialValue = getByFieldName(form.initialValues);
    return (initialValue && options.find(option => option.value === initialValue)) ?? null;
  }, [getByFieldName, form.initialValues, options]);

  const [, cancelDebounce] = useDebounce(
    async () => {
      if (onLoadOptions && open) {
        await onLoadOptions(field.value);
      }
    },
    700,
    [field.value, open],
  );

  useEffectOnce(() => {
    cancelDebounce();
  });

  return (
    <FormFieldWithLabel
      id={id}
      label={label}
      hiddenLabel={hiddenLabel}
      wrapperSx={wrapperSx}
      labelSx={labelSx}
      isViewMode={isViewMode}
      markAsOptional={markAsOptional}
    >
      {({ fieldSx: labelFieldSx, viewModeFieldSx: labelViewModeFieldSx, helperTextSx: labelHelperTextSx }) => {
        const typographySx = { ...labelViewModeFieldSx, ...viewModeFieldSx };
        const autocompleteSx = { ...labelFieldSx, ...fieldSx };

        return (
          <ConditionalRender condition={isViewMode}>
            <Typography variant="body1" sx={typographySx} {...viewModeTypographyProps}>
              {initialOption?.label ?? ''}
            </Typography>
            <Autocomplete
              {...props}
              sx={autocompleteSx}
              open={open}
              options={options}
              loading={isLoading}
              defaultValue={initialOption ? [initialOption] : null}
              renderInput={params => {
                return (
                  // @ts-ignore
                  <TextField
                    {...params}
                    {...field}
                    {...inputProps}
                    label={hiddenLabel ? null : label}
                    error={Boolean(getByFieldName(form.touched) && getByFieldName(form.errors))}
                    helperText={showErrorFirst && errorText ? errorText : inputProps?.helperText ?? errorText}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {isLoading ? <Loader size={20} /> : null}
                          <ConditionalRender condition={!props.disabled}>
                            <IconButton onClick={() => setOpen(!open)} size="small">
                              {open ? <Icons.ArrowDropUp /> : <Icons.ArrowDropDown />}
                            </IconButton>
                          </ConditionalRender>
                        </>
                      ),
                    }}
                    FormHelperTextProps={{
                      sx: labelHelperTextSx,
                      ...inputProps?.FormHelperTextProps,
                    }}
                  />
                );
              }}
              onChange={(event: any, value: any, reason: any, details: any) => {
                form.setFieldValue(field.name, value?.value);
                if (onChange) {
                  onChange(event, value, reason, details);
                }
              }}
              onOpen={() => setOpen(true)}
              onClose={() => setOpen(false)}
            />
          </ConditionalRender>
        );
      }}
    </FormFieldWithLabel>
  );
};
