import React from 'react';
import usePreviewerLanguageSelector from './hooks/usePreviewerLanguageSelector';
import PreviewerLanguageSelector from './PreviewerLanguageSelector';
import { PreviewerLanguageSelectorContainerProps } from './PreviewerLanguageSelectorProps';

const PreviewerLanguageSelectorContainer: React.FC<PreviewerLanguageSelectorContainerProps> = props => {
  const previewerLanguageSelector = usePreviewerLanguageSelector(props);

  return <PreviewerLanguageSelector {...props} {...previewerLanguageSelector} />;
};

export default PreviewerLanguageSelectorContainer;
