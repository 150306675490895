import { curry, apply } from 'ramda';
import { Option } from '@egym/types';

export * from './string';
export * from './date';
export * from './file';
export * from './dom';
export * from './object';
export * from './array';
export * from './styles';
export * from './locales';

export const goToAppPage = (pathname: string): void => {
  const { protocol, host } = window.location;

  window.location.assign(`${protocol}//${host}/${pathname}`);
};

export const getOptionsFromEntity = (
  entities: Record<string, any>[],
  labelProp = 'name',
  valueProp = 'uuid',
  descriptionProp = 'description',
): Option[] => {
  return entities.map(entity => ({
    label: entity[labelProp],
    value: entity[valueProp],
    description: entity[descriptionProp],
  }));
};

export const castArray = (item: any): any[] => (Array.isArray(item) ? item : [item]);

export const decodeHtml = htmlString => {
  return htmlString
    .replace(/&quot;/g, '"')
    .replace(/&gt;/g, '>')
    .replace(/&lt;/g, '<')
    .replace(/&nbsp;/g, ' ')
    .replace(/&amp;/g, '&');
};

export const extractTextFromHtml = htmlString => {
  if (!htmlString) return htmlString;

  const stripTagsRegExp = /<[A-Za-z]+(\s+("[^"]*"|'[^']*'|[^>])+|\/)?>|<\/[A-Za-z]+>/g;

  return decodeHtml(htmlString.replace(stripTagsRegExp, ''));
};

/**
 * Debounce
 *
 * @param {Boolean} immediate If true run `fn` at the start of the timeout
 * @param  timeMs {Number} Debounce timeout
 * @param  fn {Function} Function to debounce
 *
 * @return {Number} timeout
 * @example
 *
 *		const say = (x) => console.log(x)
 *		const debouncedSay = debounce_(false, 1000, say)();
 *
 *		debouncedSay("1")
 *		debouncedSay("2")
 *		debouncedSay("3")
 *
 */
const debounceCarried = curry((immediate, timeMs, fn) => {
  let timeout;

  return (...args) => {
    const later = () => {
      timeout = null;

      if (!immediate) {
        apply(fn, args);
      }
    };

    const callNow = immediate && !timeout;

    clearTimeout(timeout);
    timeout = setTimeout(later, timeMs);

    if (callNow) {
      apply(fn, args);
    }

    return timeout;
  };
});

export const debounceImmediate = debounceCarried(true);

export const debounce = debounceCarried(false);

export const reorder = (list, sourceIndex, destinationIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(sourceIndex, 1);
  result.splice(destinationIndex, 0, removed);

  return result;
};

export const getPercent = (partialNumber, total) => {
  return Math.round((partialNumber * 100) / total);
};

export const wait = async ms => {
  return new Promise<void>(resolve => {
    setTimeout(() => {
      resolve();
    }, ms);
  });
};
