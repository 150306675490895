import React, { forwardRef, ReactNode } from 'react';
import { Paper, Stack } from '@egym/ui';
import { AppLayoutFeatureComponentProps } from '../../../../AppPreviewerProps';
import PreviewContainer from '../../../PreviewContainer';
import PreviewFeatureTitle from '../../../PreviewFeatureTitle';

const PreviewActivityLevel = forwardRef<HTMLDivElement, AppLayoutFeatureComponentProps>(
  ({ tFunction, palette, wrapperSx, previewerState, widgetProps }, ref) => {
    return (
      <PreviewContainer ref={ref} wrapperSx={wrapperSx}>
        <PreviewFeatureTitle
          tFunction={tFunction}
          palette={palette}
          title={
            widgetProps?.preferences?.title?.[previewerState.selectedLocale as string] ??
            widgetProps?.preferences?.title?.en_US ??
            'appPreviewer.labels.activityLevel'
          }
        />
        <Paper sx={{ borderRadius: '8px', boxShadow: '0 4px 6px rgba(223, 223, 223, 0.7)', overflow: 'hidden' }}>
          <Stack direction="row" alignItems="center" sx={{ p: '12px 15px', background: '#74b0d8', color: 'white' }}>
            <img
              style={{ width: '38px', height: '38px', marginRight: '10px' }}
              src="/images/previewerDefaultImages/features/activityLevel/@2xlevel-diamond.png"
              alt="diamond"
            />
            <Stack>
              <span style={{ fontSize: '11px', lineHeight: '16px', fontWeight: 500 }}>
                {tFunction('appPreviewer.labels.currentLevel') as ReactNode}
              </span>
              <span style={{ fontSize: '13px', lineHeight: '20px', fontWeight: 700 }}>
                {tFunction('appPreviewer.labels.levelCount', { count: 6 }) as ReactNode}:{' '}
                {tFunction('appPreviewer.labels.diamond') as ReactNode}
              </span>
            </Stack>
            <Stack sx={{ ml: 'auto' }}>
              <span style={{ fontSize: '11px', lineHeight: '16px', fontWeight: 500 }}>
                {tFunction('appPreviewer.labels.pointsToNextLevel') as ReactNode}
              </span>
              <span style={{ fontSize: '13px', lineHeight: '20px', fontWeight: 700 }}>2799</span>
            </Stack>
          </Stack>
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{
              position: 'relative',
              height: '230px',
            }}
          >
            <img
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '200px',
              }}
              src="/images/previewerDefaultImages/features/activityLevel/progress.png"
              alt="progress ring"
            />
            <span style={{ fontSize: '25px', letterSpacing: '-0.5px', color: 'rgb(33, 33, 33)', fontWeight: 700 }}>
              8399
            </span>
            <span style={{ fontSize: '14px', lineHeight: '20px', fontWeight: 400, marginBottom: '15px' }}>
              {tFunction('appPreviewer.labels.activityPoints') as ReactNode}
            </span>
            <span style={{ fontSize: '12px', fontWeight: 700 }}>
              {tFunction('appPreviewer.labels.daysLeftCount', { count: 8 }) as ReactNode}
            </span>
          </Stack>
        </Paper>
      </PreviewContainer>
    );
  },
);

export default PreviewActivityLevel;
