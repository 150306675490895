import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const StarIcon: React.FC<SvgIconProps> = ({ sx, ...props }) => {
  return (
    <SvgIcon viewBox="0 0 38 37" {...props} sx={{ width: '38px', height: '37px', ...sx }}>
      <path
        d="M19 30.674 7.244 36.18 8.848 23.3l-8.87-9.48 12.748-2.455L19 0l6.274 11.364 12.747 2.456-8.87 9.479 1.605 12.881z"
        fill="#BDBDBD"
        fillRule="evenodd"
      />
    </SvgIcon>
  );
};
