import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const MainTabHeaderDefaultIcon29: React.FC<SvgIconProps> = ({ htmlColor = '#FFF', sx, ...props }) => {
  return (
    <SvgIcon viewBox="0 0 48 48" {...props} sx={{ width: '48px', height: '48px', ...sx }}>
      <g fill={htmlColor} fillRule="evenodd">
        <path d="M37 5a6 6 0 0 1 6 6v26a6 6 0 0 1-6 6H11a6 6 0 0 1-6-6V11a6 6 0 0 1 6-6zm0 4H11a2 2 0 0 0-2 2v26a2 2 0 0 0 2 2h26a2 2 0 0 0 2-2V11a2 2 0 0 0-2-2zm-13 3.5c6.351 0 11.5 5.149 11.5 11.5a1.5 1.5 0 0 1-1.5 1.5H14a1.5 1.5 0 0 1-1.5-1.5c0-6.351 5.149-11.5 11.5-11.5zm0 3a8.503 8.503 0 0 0-8.368 7h16.735a8.501 8.501 0 0 0-8.112-6.996z" />
        <rect transform="rotate(-60 28.856 -4.82)" y="3" width="8" height="3" rx="1.5" opacity=".6" />
      </g>
    </SvgIcon>
  );
};
