import React, { forwardRef, ReactNode, useState } from 'react';
import { Paper, Stack, UploadedImage } from '@egym/ui';
import { AppLayoutFeatureComponentProps } from '../../../../AppPreviewerProps';
import { CloseIcon, FlagIcon } from '../../../Icons';
import PreviewContainer from '../../../PreviewContainer';
import styles from './PreviewNotificationCenter.module.scss';

const PreviewNotificationCenter = forwardRef<HTMLDivElement, AppLayoutFeatureComponentProps>(
  ({ tFunction, previewerId, previewerState: { appIconBackground, appIconLink, disabled }, wrapperSx }, ref) => {
    const [showNotification, setShowNotification] = useState(true);

    return showNotification ? (
      <PreviewContainer ref={ref} wrapperSx={wrapperSx}>
        <Paper
          component={Stack}
          sx={{
            boxShadow: '0 4px 6px rgba(223, 223, 223, 0.7)',
            borderRadius: '12px',
            background: 'rgb(249, 249, 249)',
            minHeight: '64px',
            p: '12px',
          }}
        >
          <Stack direction="row" alignItems="center" sx={{ position: 'relative', mb: '8px' }}>
            <UploadedImage
              id={`previewer-class-notification-${previewerId}`}
              key={appIconLink}
              wrapperSx={{
                minWidth: '32px',
                width: '32px',
                height: '32px',
                boxSizing: 'border-box',
                borderRadius: '8px',
                p: '5px',
                background: appIconBackground || 'rgba(0, 0, 0, 0.1)',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              imageSx={{
                maxWidth: '100%',
                maxHeight: '100%',
                height: 'auto',
              }}
              file={{ preview: appIconLink || '' }}
              fallbackElement={<FlagIcon sx={{ width: '20px', height: '20px' }} htmlColor="white" />}
            />
            <span
              style={{
                fontSize: '11px',
                fontWeight: 500,
                color: 'rgba(0, 0, 0, 0.4)',
                lineHeight: '15px',
                marginBottom: '1px',
                marginLeft: 'auto',
              }}
            >
              {tFunction('appPreviewer.labels.durationMinutesShortAgo', { minutes: 12 }) as ReactNode}
            </span>
            <CloseIcon
              sx={{ ml: '3px', cursor: !disabled ? 'pointer' : undefined, width: '24px', height: '24px' }}
              htmlColor="rgb(135, 135, 135)"
              onClick={() => !disabled && setShowNotification(false)}
            />
          </Stack>
          <span
            style={{
              fontSize: '13px',
              fontWeight: 600,
              color: 'rgb(0, 0, 0)',
              lineHeight: '20px',
              marginBottom: '5px',
            }}
          >
            {tFunction('appPreviewer.labels.classNotificationTitle') as ReactNode}
          </span>
          <span
            style={{
              fontSize: '13px',
              fontWeight: 400,
              color: 'rgb(0, 0, 0)',
              lineHeight: '18px',
              maxHeight: '36px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
            className={styles.description}
          >
            {tFunction('appPreviewer.labels.classNotificationDescription') as ReactNode}
          </span>
        </Paper>
      </PreviewContainer>
    ) : null;
  },
);

export default PreviewNotificationCenter;
