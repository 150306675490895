import { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { validateColorOnHighContrast } from '@helpers';
import {
  RewardIconBackgroundColorFieldContainerProps,
  UseRewardIconBackgroundColorFieldResult,
} from '../../RewardIconBackgroundColorFieldProps';

const useRewardIconBackgroundColorField = ({
  previewerPalette,
}: RewardIconBackgroundColorFieldContainerProps): UseRewardIconBackgroundColorFieldResult => {
  const {
    values: { backgroundColor },
  } = useFormikContext<{ backgroundColor?: string }>();

  const [highContrastWarning, setHighContrastWarning] = useState<{
    primary: boolean;
    source: 'colorTheme' | 'widget';
  } | null>(null);

  const [showHighContrastWarning, setShowHighContrastWarning] = useState<boolean>(true);

  const [colorPickerOpened, setColorPickerOpened] = useState<boolean>(false);

  useEffect(() => {
    if (colorPickerOpened) return;

    if (backgroundColor && backgroundColor !== previewerPalette.primary) {
      const isValid = validateColorOnHighContrast(backgroundColor);
      setHighContrastWarning({ primary: isValid, source: 'widget' });
    } else {
      const isValid = validateColorOnHighContrast(previewerPalette.primary);
      setHighContrastWarning({ primary: isValid, source: 'colorTheme' });
    }
  }, [colorPickerOpened, previewerPalette.primary, backgroundColor, setHighContrastWarning]);

  return {
    showHighContrastWarning,
    setShowHighContrastWarning,
    setColorPickerOpened,
    highContrastWarning,
    setHighContrastWarning,
  };
};

export default useRewardIconBackgroundColorField;
