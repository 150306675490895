import React from 'react';
import { alpha } from '@mui/material';
import { TabsProps } from '@mui/material/Tabs';
import { AppPreviewerPalette } from '../../AppPreviewerProps';
import { StyledTabs } from './PreviewTabs.styles';

type Props = TabsProps & { palette: Partial<AppPreviewerPalette> };

const PreviewTabs: React.FC<Props> = ({ palette, ...props }) => {
  return <StyledTabs background={palette.primary ? alpha(palette.primary, 0.1) : 'transparent'} {...props} />;
};

export default PreviewTabs;
