import React, { forwardRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { AppLayoutFeatureComponentProps } from '../../../../AppPreviewerProps';
import PreviewContainer from '../../../PreviewContainer';
import PreviewFeatureTitle from '../../../PreviewFeatureTitle';
import { trainingTemplatesMock } from './data';
import PreviewTrainingPlanItem from './PreviewTrainingPlanItem';

const PreviewTrainingPlans = forwardRef<HTMLDivElement, AppLayoutFeatureComponentProps>(
  ({ tFunction, palette, wrapperSx, itemWrapperSx }, ref) => {
    return (
      <div ref={ref}>
        <PreviewContainer wrapperSx={wrapperSx}>
          <PreviewFeatureTitle
            tFunction={tFunction}
            palette={palette}
            title="appPreviewer.labels.trainingPlans"
            withViewAll
          />
        </PreviewContainer>

        <Swiper
          style={{ padding: '0 11.25px', maxWidth: '100%', boxSizing: 'border-box' }}
          slidesPerView={1}
          initialSlide={1}
          centeredSlides
          spaceBetween={5}
        >
          {trainingTemplatesMock.map(plan => {
            return (
              <SwiperSlide key={plan.id} style={{ width: '90%' }}>
                <PreviewTrainingPlanItem tFunction={tFunction} key={plan.id} wrapperSx={itemWrapperSx} {...plan} />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    );
  },
);

export default PreviewTrainingPlans;
