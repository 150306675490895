import React from 'react';
import usePreviewerProfileRoute from './hooks/usePreviewerProfileRoute';
import PreviewerProfileRoute from './PreviewerProfileRoute';
import { PreviewerProfileRouteContainerProps } from './PreviewerProfileRouteProps';

const PreviewerProfileRouteContainer: React.FC<PreviewerProfileRouteContainerProps> = props => {
  const previewerProfileRoute = usePreviewerProfileRoute(props);

  return <PreviewerProfileRoute {...props} {...previewerProfileRoute} />;
};

export default PreviewerProfileRouteContainer;
