import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const MainTabHeaderDefaultIcon4: React.FC<SvgIconProps> = ({ htmlColor = '#FFF', sx, ...props }) => {
  return (
    <SvgIcon viewBox="0 0 48 48" {...props} sx={{ width: '48px', height: '48px', ...sx }}>
      <g fill={htmlColor} fillRule="evenodd">
        <path d="M10 8c-1.102 0-2 .898-2 2v19.172l4.586-4.586A2 2 0 0 1 14 24h16c1.103 0 2-.896 2-2V10c0-1.102-.897-2-2-2H10zM6 36a2.001 2.001 0 0 1-2-2V10c0-3.309 2.691-6 6-6h20c3.308 0 6 2.691 6 6v12c0 3.309-2.692 6-6 6H14.828l-7.414 7.414A1.996 1.996 0 0 1 6 36z" />
        <path
          d="M38 12.201h-2v3.6h2c1.212 0 2.201.986 2.201 2.199v19.654l-4.928-4.925A1.8 1.8 0 0 0 34 32.201H18c-1.213 0-2.2-.988-2.2-2.201v-2h-.972l-2.597 2.598A5.808 5.808 0 0 0 18 35.801h15.255l7.473 7.472a1.801 1.801 0 0 0 1.961.391A1.802 1.802 0 0 0 43.8 42V18a5.806 5.806 0 0 0-5.8-5.799"
          opacity=".6"
        />
      </g>
    </SvgIcon>
  );
};
