import { atom } from 'recoil';

const keys = {
  appUuid: 'global.appUuid',
};

const atoms = {
  appUuid: atom<string | undefined>({
    key: keys.appUuid,
    default: undefined,
  }),
};

export const appUuidState = {
  keys,
  atoms,
};
