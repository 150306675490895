import React from 'react';
import { styled } from '@mui/material/styles';
import { Tooltip as EgymTooltip, TooltipProps, tooltipClasses } from '@egym/ui';

export const Tooltip = styled(({ className, ...props }: TooltipProps) => (
  <EgymTooltip {...props} classes={{ popper: className }} arrow />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    fontSize: theme.typography.pxToRem(14),
    padding: `${theme.spacing(4)} ${theme.spacing(2.5)}`,
    backgroundColor: theme.palette.common.black,
  },
}));

export const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <EgymTooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.shadows[1],
  },
}));
