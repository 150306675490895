import { createContext, FC, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useStateWithHistory, useUpdateEffect } from 'react-use';
import useLockBodyScroll from '../useLockBodyScroll';
import useRouter from '../useRouter';
import { DrawerConfig } from './types';

const DrawerContext = createContext<{
  isOpen: boolean;
  isConfirming: boolean;
  drawerConfig?: DrawerConfig;
  openDrawer: (drawerConfig: Partial<DrawerConfig>) => void;
  closeDrawer: () => void;
  onBackButtonClick: () => void;
  onConfirmClick: () => void;
}>({
  isOpen: false,
  isConfirming: false,
  openDrawer: () => {},
  closeDrawer: () => {},
  onBackButtonClick: () => {},
  onConfirmClick: () => {},
});

export const DrawerProvider: FC<PropsWithChildren> = ({ children }) => {
  const [drawerConfig, setDrawerConfig, drawerConfigHistory] = useStateWithHistory<DrawerConfig | undefined>();
  const isOpen = useMemo(() => Boolean(drawerConfig?.open), [drawerConfig?.open]);
  const [isConfirming, setConfirming] = useState(false);
  const [isRequestToClose, setRequestToClose] = useState(!isOpen);
  const { pathname } = useRouter();

  useLockBodyScroll(isOpen);

  const openDrawer = useCallback(
    (config: Partial<DrawerConfig>) => {
      setDrawerConfig({
        shouldCloseOnPathnameChange: true,
        ...config,
        open: true,
      });
      setRequestToClose(false);
    },
    [setDrawerConfig],
  );

  const closeDrawer = useCallback(() => {
    setRequestToClose(true);
  }, [setRequestToClose]);

  useEffect(() => {
    if (!isConfirming && isRequestToClose) {
      if (drawerConfig?.onClose) {
        drawerConfig.onClose();
      }
      setDrawerConfig(prev => ({ ...prev, withBackButton: false, open: false }));
      setRequestToClose(false);
    }
  }, [isConfirming, isRequestToClose, setRequestToClose, drawerConfig, setDrawerConfig]);

  const onBackButtonClick = useCallback(() => {
    if (!drawerConfig?.withBackButton) return;

    if (drawerConfig?.onBack) {
      drawerConfig.onBack();
    }
    drawerConfigHistory.back(1);
  }, [drawerConfig, drawerConfigHistory]);

  useUpdateEffect(() => {
    if (drawerConfig?.shouldCloseOnPathnameChange) {
      setDrawerConfig(prev => ({
        ...prev,
        open: false,
      }));
    }
  }, [pathname]);

  const onConfirmClick = useCallback(async () => {
    if (!drawerConfig?.showConfirmButton) return;
    setConfirming(true);
    try {
      if (drawerConfig?.onConfirm) {
        await drawerConfig.onConfirm();
      }
    } finally {
      setConfirming(false);
    }
  }, [setConfirming, drawerConfig]);

  return (
    <DrawerContext.Provider
      value={{ isOpen, isConfirming, drawerConfig, openDrawer, closeDrawer, onBackButtonClick, onConfirmClick }}
    >
      {children}
    </DrawerContext.Provider>
  );
};

const useDrawer = () => useContext(DrawerContext);

export default useDrawer;
