import React from 'react';
import usePreviewerLayoutRoute from './hooks/usePreviewerLayoutRoute';
import PreviewerLayoutRoute from './PreviewerLayoutRoute';
import { PreviewerLayoutRouteContainerProps } from './PreviewerLayoutRouteProps';

const PreviewerLayoutRouteContainer: React.FC<PreviewerLayoutRouteContainerProps> = props => {
  const previewerLayoutRoute = usePreviewerLayoutRoute(props);

  return <PreviewerLayoutRoute {...props} {...previewerLayoutRoute} />;
};

export default PreviewerLayoutRouteContainer;
