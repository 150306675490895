import React, { ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Box, UploadedImage } from '@egym/ui';
import { AppLayoutFeatureComponentProps } from '../../../../AppPreviewerProps';
import DefaultLogo from '../../../DefaultLogo';
import { IconWrapper, PlusIcon, ScanIcon } from '../../../Icons';

const headerHeight = 170;

type Props = Omit<AppLayoutFeatureComponentProps, 'tFunction'> & {
  showWelcomeScreen: boolean;
  gradient: string;
  activeWidgetId?: number | string | null;
};

const PreviewHeader: React.FC<Props> = ({
  previewerId,
  palette,
  previewerState: { mainTabIconLink },
  showWelcomeScreen,
  gradient,
  activeWidgetId,
}) => {
  const { t } = useTranslation();

  const greetingText = useMemo(() => t('appPreviewer.labels.greeting', { name: 'Lukas' }), [t]);

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        sx={{
          backgroundImage: gradient,
          backgroundSize: `100% ${headerHeight}px`,
          paddingTop: '25px',
          paddingBottom: '14px',
          px: '15px',
          position: 'sticky',
          height: '71px',
          boxSizing: 'border-box',
          top: 0,
          zIndex: 3,
          boxShadow: `0 0px 1px ${palette.primaryTransparent}`,
          filter: activeWidgetId ? 'blur(2px)' : null,
        }}
      >
        <Box sx={{ width: '32px', height: '32px', overflow: 'hidden', position: 'relative' }}>
          <div
            style={{
              width: '8px',
              height: '8px',
              position: 'absolute',
              top: 0,
              right: 0,
              background: 'white',
              border: `1px solid ${palette.primary}`,
              borderRadius: '50%',
              boxSizing: 'border-box',
              zIndex: 1,
            }}
          >
            <div
              style={{
                width: '5px',
                height: '5px',
                position: 'absolute',
                left: '50%',
                top: '50%',
                transform: 'translate(-50%, -50%)',
                background: palette.secondary,
                borderRadius: '50%',
                boxSizing: 'border-box',
              }}
            />
          </div>
          <Box
            component="img"
            src="/images/previewerDefaultImages/avatars/Lukas.png"
            sx={{ position: 'absolute', top: '-1px', left: '-3px', width: '38px' }}
          />
        </Box>
        <Stack direction="row" alignItems="center" sx={{ ml: 'auto' }}>
          <IconWrapper>
            <PlusIcon htmlColor="#ffffff" />
          </IconWrapper>
          <IconWrapper wrapperSx={{ ml: '8px' }}>
            <ScanIcon htmlColor="#ffffff" />
          </IconWrapper>
        </Stack>
      </Stack>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="flex-start"
        sx={{
          boxSizing: 'border-box',
          position: 'sticky',
          top: '71px',
          px: '15px',
          backgroundImage: gradient,
          backgroundSize: `100% ${headerHeight}px`,
          backgroundPosition: 'bottom',
          boxShadow: `0 0px 1px ${palette.primaryTransparent}`,
          visibility: showWelcomeScreen ? 'visible' : 'hidden',
          height: showWelcomeScreen ? '99px' : 0,
          pb: showWelcomeScreen ? '15px' : 0,
          filter: activeWidgetId ? 'blur(2px)' : null,
        }}
      >
        <Stack
          sx={{
            color: 'white',
            flex: 1,
            maxWidth: !mainTabIconLink ? '150px' : undefined,
          }}
        >
          <span
            style={{
              marginBottom: '12px',
              fontSize: '22px',
              fontWeight: 'bold',
              letterSpacing: '-1px',
            }}
          >
            {greetingText}
          </span>
          <span style={{ fontSize: '13px', marginBottom: '10px', letterSpacing: '-0.5px' }}>
            {t('appPreviewer.labels.welcomeMessage') as ReactNode}
          </span>
        </Stack>
        {mainTabIconLink ? (
          <UploadedImage
            id={`previewer-header-${previewerId}`}
            wrapperSx={{
              alignItems: 'center',
              justifyContent: 'center',
              maxWidth: '120px',
              width: '100%',
              height: '100%',
              boxSizing: 'border-box',
              marginLeft: 'auto',
            }}
            imageSx={{
              maxWidth: '100%',
              maxHeight: '100%',
              height: 'auto',
              marginLeft: 'auto',
              svg: { maxWidth: '100%', maxHeight: '100%', height: '100%', width: 'auto', marginLeft: 'auto' },
            }}
            file={{ preview: mainTabIconLink }}
          />
        ) : (
          <DefaultLogo />
        )}
      </Stack>
    </>
  );
};

export default PreviewHeader;
