import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const ResizeIcon: React.FC<SvgIconProps> = ({ sx, htmlColor = '#878787', ...props }) => {
  return (
    <SvgIcon viewBox="0 0 16 16" {...props} sx={{ width: '16px', height: '16px', ...sx }}>
      <defs>
        <filter colorInterpolationFilters="auto" id="jjqo9nyjha">
          <feColorMatrix
            in="SourceGraphic"
            values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0"
          />
        </filter>
      </defs>
      <g transform="translate(-918 -271)" filter="url(#jjqo9nyjha)" fill="none" fillRule="evenodd">
        <path d="M918 271h16v16h-16z" />
        <path
          d="m925.123 271.667 1.333 1.333H920v12h12v-6.457l1.333 1.333v4.457a2 2 0 0 1-2 2h-10.666a2 2 0 0 1-2-2v-10.666a2 2 0 0 1 2-2h4.456zM928.667 271.667l4.666 4.666v-4.666z"
          fill={htmlColor}
          fillRule="nonzero"
        />
        <path fill={htmlColor} fillRule="nonzero" d="m927.333 282.333-4.666-4.666v4.666z" />
        <path stroke={htmlColor} strokeWidth="2" d="m924 281 8-8" />
      </g>
    </SvgIcon>
  );
};
