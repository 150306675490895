import React, { ChangeEvent, MouseEvent, ReactNode, useCallback, useMemo, useState } from 'react';
import { useUpdateEffect } from 'react-use';
import {
  Checkbox as MuiCheckbox,
  Collapse as MuiCollapse,
  TableCell as MuiTableCell,
  TableRow as MuiTableRow,
} from '@mui/material';
import { Icons } from '../../../../icons';
import { IconButton } from '../../../../inputs';
import { TableColumn, TableRenderSubComponentParams, TableRowType } from '../../TableProps';
import TableBodyCell from '../TableBodyCell';
import TableBodyEditableCell from '../TableBodyEditableCell';

type Props = {
  checkboxSelectionProp?: string;
  row: any;
  rowIndex: number;
  columns: TableColumn[];
  selectedRows: string[];
  onRowClick: (event: MouseEvent<unknown> | ChangeEvent<HTMLInputElement>, row: any) => void;
  tdProps?: Record<string, any>;
  renderSubComponent?: (params: TableRenderSubComponentParams) => ReactNode;
  totalColumnsCount: number;
  getIsCheckboxSelected?: (row: TableRowType) => boolean;
  getIsCheckboxIndeterminate?: (row: TableRowType) => boolean;
  indeterminateRowIds?: string[];
  expandAll?: boolean;
  isViewMode?: boolean;
};

const TableBodyRow: React.FC<Props> = ({
  row,
  rowIndex,
  columns,
  checkboxSelectionProp,
  selectedRows,
  onRowClick,
  tdProps,
  renderSubComponent,
  totalColumnsCount,
  getIsCheckboxSelected,
  getIsCheckboxIndeterminate,
  indeterminateRowIds,
  expandAll,
  isViewMode,
}) => {
  const [isOpenSubComponent, setIsOpenSubComponent] = useState(expandAll);

  useUpdateEffect(() => {
    setIsOpenSubComponent(expandAll);
  }, [expandAll]);

  const handleExpandRowClick = useCallback(event => {
    event.stopPropagation();

    setIsOpenSubComponent(prevIsOpenSubComponent => !prevIsOpenSubComponent);
  }, []);

  const isCheckboxSelected = useMemo(() => {
    if (getIsCheckboxSelected) {
      return getIsCheckboxSelected(row);
    }

    return Boolean(checkboxSelectionProp && selectedRows.includes(row[checkboxSelectionProp]));
  }, [checkboxSelectionProp, getIsCheckboxSelected, row, selectedRows]);

  const isCheckboxIndeterminate = useMemo(() => {
    if (indeterminateRowIds && checkboxSelectionProp) {
      return indeterminateRowIds.includes(row[checkboxSelectionProp]);
    }

    return getIsCheckboxIndeterminate ? getIsCheckboxIndeterminate(row) : false;
  }, [getIsCheckboxIndeterminate, row, checkboxSelectionProp, indeterminateRowIds]);

  const isNotValid = useMemo(() => typeof row?.valid === 'boolean' && !row.valid, [row]);

  const rowErrorStyles = useMemo(() => (isNotValid ? { bgcolor: 'tableErrorRow.main' } : {}), [isNotValid]);

  const cellErrorStyles = useMemo(() => (isNotValid ? { borderColor: 'error.main' } : {}), [isNotValid]);

  return (
    <>
      <MuiTableRow
        selected={isCheckboxSelected}
        hover={!!checkboxSelectionProp && !renderSubComponent}
        sx={{ cursor: !!checkboxSelectionProp && !renderSubComponent ? 'pointer' : 'default', ...rowErrorStyles }}
        onClick={event => !renderSubComponent && onRowClick(event, row)}
      >
        {checkboxSelectionProp && (
          <MuiTableCell sx={{ ...cellErrorStyles }} padding="checkbox" variant="body">
            <MuiCheckbox
              color="primary"
              checked={isCheckboxSelected}
              indeterminate={isCheckboxIndeterminate}
              onChange={event => onRowClick(event, row)}
            />
          </MuiTableCell>
        )}
        {columns
          .filter(column => !(column.hidden && column.hidden(isViewMode)))
          .map(column => {
            return column.editable && !isViewMode ? (
              <TableBodyEditableCell
                key={column.field}
                column={column}
                row={row}
                rowIndex={rowIndex}
                tdProps={tdProps}
                wrappedSx={cellErrorStyles}
              />
            ) : (
              <TableBodyCell
                key={column.field}
                column={column}
                row={row}
                rowIndex={rowIndex}
                tdProps={tdProps}
                isViewMode={isViewMode}
                wrappedSx={cellErrorStyles}
              />
            );
          })}
        {renderSubComponent && (
          <MuiTableCell sx={{ width: '50px', pr: 2, ...cellErrorStyles }}>
            <IconButton aria-label="expand row" size="small" onClick={handleExpandRowClick}>
              {isOpenSubComponent ? <Icons.KeyboardArrowUp /> : <Icons.KeyboardArrowDown />}
            </IconButton>
          </MuiTableCell>
        )}
      </MuiTableRow>
      {renderSubComponent && (
        <MuiTableRow>
          <MuiTableCell sx={{ p: 0, ...cellErrorStyles }} colSpan={totalColumnsCount}>
            <MuiCollapse in={isOpenSubComponent} timeout="auto" unmountOnExit>
              {renderSubComponent({ row })}
            </MuiCollapse>
          </MuiTableCell>
        </MuiTableRow>
      )}
    </>
  );
};

export default TableBodyRow;
