import { forwardRef } from 'react';
import useWebWidgetPreferencesForm from './hooks/useWebWidgetPreferencesForm';
import WebWidgetPreferencesForm from './WebWidgetPreferencesForm';
import { WebWidgetPreferencesFormContainerProps } from './WebWidgetPreferencesFormProps';

const WebWidgetPreferencesFormContainer = forwardRef<any, WebWidgetPreferencesFormContainerProps>((props, ref) => {
  const result = useWebWidgetPreferencesForm(props, ref);
  return <WebWidgetPreferencesForm {...props} {...result} />;
});

export default WebWidgetPreferencesFormContainer;
