import { FC } from 'react';
import useRewardIconBackgroundColorField from './hooks/useRewardIconBackgroundColorField';
import RewardIconBackgroundColorField from './RewardIconBackgroundColorField';
import { RewardIconBackgroundColorFieldContainerProps } from './RewardIconBackgroundColorFieldProps';

const RewardIconBackgroundColorFieldContainer: FC<RewardIconBackgroundColorFieldContainerProps> = props => {
  const result = useRewardIconBackgroundColorField(props);
  return <RewardIconBackgroundColorField {...props} {...result} />;
};

export default RewardIconBackgroundColorFieldContainer;
