import React, { FC, useMemo } from 'react';
import { Skeleton } from '@mui/material';
import { SxProps } from '@mui/system';
import { ConditionalRender, Stack } from '@egym/ui';
import { StarIcon } from '@svg';
import { Orientation } from '@types';

type Props = {
  orientation: Orientation;
  order: number;
  wrapperSx?: SxProps;
  width?: number;
};

const GroupWidgetSkeletonItem: FC<Props> = ({ wrapperSx, width, orientation, order }) => {
  const linesWidths = useMemo(() => {
    switch (orientation) {
      case Orientation.Horizontal:
        return order === 0 ? [75, 100] : [82, 45];
      case Orientation.Vertical:
      default:
        return order === 0 ? [91, 118] : [118, 67];
    }
  }, [order, orientation]);

  return (
    <ConditionalRender condition={orientation === Orientation.Vertical}>
      <Skeleton
        variant="rectangular"
        component="div"
        animation={false}
        width={`${width || 100}%`}
        height={80}
        sx={{ borderRadius: '7px', backgroundColor: 'rgb(206, 206, 206)', ...wrapperSx }}
      >
        <Stack
          direction="row"
          height="100%"
          width="100%"
          alignItems="center"
          justifyContent="space-between"
          visibility="unset"
        >
          <Stack direction="column" spacing={2} sx={{ ml: '15px' }}>
            <Skeleton
              variant="rectangular"
              animation={false}
              sx={{ borderRadius: '7.5px', backgroundColor: 'rgb(237, 237, 237)' }}
              width={linesWidths?.[0] || 118}
              height={8}
            />
            <Skeleton
              variant="rectangular"
              animation={false}
              sx={{ borderRadius: '7.5px', backgroundColor: 'rgb(237, 237, 237)' }}
              width={linesWidths?.[1] || 67}
              height={8}
            />
          </Stack>
          {order === 0 ? (
            <Skeleton
              variant="circular"
              animation={false}
              width={36}
              height={36}
              sx={{ backgroundColor: 'rgb(189, 189, 189)', mr: '20px' }}
            />
          ) : (
            <StarIcon sx={{ mr: '20px' }} />
          )}
        </Stack>
      </Skeleton>
      <Skeleton
        variant="rectangular"
        component="div"
        animation={false}
        width={`${width || 100}%`}
        height={146}
        sx={{ borderRadius: '7px', backgroundColor: 'rgb(206, 206, 206)', ...wrapperSx }}
      >
        <Stack
          direction="column"
          height="100%"
          width="100%"
          alignItems="left"
          justifyContent="space-between"
          visibility="unset"
        >
          {order === 0 ? (
            <Skeleton
              variant="circular"
              animation={false}
              width={36}
              height={36}
              sx={{ backgroundColor: 'rgb(189, 189, 189)', mt: '15px', ml: '15px' }}
            />
          ) : (
            <StarIcon sx={{ ml: '15px', mt: '15px' }} />
          )}

          <Stack direction="column" spacing={2} sx={{ p: '15px', pb: 5 }}>
            <Skeleton
              variant="rectangular"
              animation={false}
              sx={{ borderRadius: '7.5px', backgroundColor: 'rgb(237, 237, 237)' }}
              width={`${linesWidths?.[0] || 82}%`}
              height={8}
            />
            <Skeleton
              variant="rectangular"
              animation={false}
              sx={{ borderRadius: '7.5px', backgroundColor: 'rgb(237, 237, 237)' }}
              width={`${linesWidths?.[1] || 45}%`}
              height={8}
            />
          </Stack>
        </Stack>
      </Skeleton>
    </ConditionalRender>
  );
};

export default GroupWidgetSkeletonItem;
