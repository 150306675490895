import React, { forwardRef, ReactElement, useEffect, useState } from 'react';
import { usePromise } from 'react-use';
import { Stack } from '@mui/material';
import { SxProps, SystemStyleObject, BoxProps } from '@mui/system';
import { useQuery } from '@tanstack/react-query';
import { getTextFromSvgUrl } from '@egym/api';
import { Box, ConditionalRender, SvgRenderer } from '@egym/ui';

type Props = {
  id?: string;
  wrapperSx?: SystemStyleObject;
  imageSx?: SxProps;
  imageProps?: (filePreviewResult: string) => BoxProps;
  file: {
    preview: string;
    path?: string;
    isSvg?: boolean;
  };
  fallbackElement?: ReactElement;
  uploadImageTool?: ReactElement | null;
};

const UploadedImage = forwardRef<HTMLImageElement, Props>(
  ({ id, wrapperSx, imageSx, imageProps, file, fallbackElement, uploadImageTool }, ref) => {
    const [filePreviewResult, setFilePreviewResult] = useState<string>(file.preview);
    const mounted = usePromise();
    const svgTextQuery = useQuery(['svgTextFromUrl', file.preview], async () => getTextFromSvgUrl(file.preview), {
      enabled: false,
      refetchOnMount: false,
      keepPreviousData: true,
    });

    useEffect(() => {
      if (!file.preview) return;
      (async () => {
        if (file.isSvg || file.preview.endsWith('.svg')) {
          const svgText = await mounted(svgTextQuery.refetch());
          setFilePreviewResult(svgText.data || '');
        } else {
          setFilePreviewResult(file.preview);
        }
      })();
      // eslint-disable-next-line
    }, [file.preview]);

    return (
      <Stack sx={wrapperSx}>
        <ConditionalRender condition={Boolean(file.preview)}>
          <>
            {filePreviewResult.includes('<svg') ? (
              <SvgRenderer
                ref={ref}
                id={id}
                sx={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  svg: { maxWidth: '100%', maxHeight: '100%', height: '100%', width: '100%' },
                  ...imageSx,
                }}
                dirtyHtml={filePreviewResult}
              />
            ) : (
              <Box
                ref={ref}
                sx={imageSx}
                component="img"
                src={filePreviewResult}
                alt={file.path}
                {...(imageProps ? imageProps(filePreviewResult) : null)}
              />
            )}
          </>
          {fallbackElement}
        </ConditionalRender>
        {uploadImageTool}
      </Stack>
    );
  },
);

export default UploadedImage;
