import { Uuid } from '@egym/types';
import { useRouter } from '@egym/ui';
import useAppDesignTabsWidgets from '../useAppDesignTabsWidgets';
import { UseAppDesignTabsWidgetsProps } from '../useAppDesignTabsWidgets/useAppDesignTabsWidgets';

type Props = Omit<UseAppDesignTabsWidgetsProps, 'appUuid'>;

const useAppDesignTabsWidgetsByRouter = (props?: Props) => {
  const { urlParams } = useRouter<{ uuid: Uuid }>();

  return useAppDesignTabsWidgets({ appUuid: urlParams.uuid, ...props });
};

export default useAppDesignTabsWidgetsByRouter;
