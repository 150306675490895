import { Uuid } from '@egym/types';
import { useRouter } from '@egym/ui';
import { useGymLocations } from '@hooks';

const useGymLocationsByRouter = () => {
  const { urlParams } = useRouter<{ uuid: Uuid }>();

  return useGymLocations(urlParams.uuid);
};

export default useGymLocationsByRouter;
