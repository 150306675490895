import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const MainTabHeaderDefaultIcon7: React.FC<SvgIconProps> = ({ htmlColor = '#FFF', sx, ...props }) => {
  return (
    <SvgIcon viewBox="0 0 48 48" {...props} sx={{ width: '48px', height: '48px', ...sx }}>
      <g fill={htmlColor} fillRule="evenodd">
        <path
          d="M19 23c0-2.756 2.243-5 5-5s5 2.244 5 5c0 2.757-2.243 5-5 5s-5-2.243-5-5m14.906 13.094c.086.086.165.182.248.27.36-.295.711-.602 1.046-.931.051-.052.11-.108.167-.177.031-.037.064-.073.097-.107a16.4 16.4 0 0 0 1.425-1.715c-.054-.056-.104-.117-.16-.173a18.066 18.066 0 0 0-6.239-4.042A8.958 8.958 0 0 0 33 23c0-4.962-4.037-9-9-9s-9 4.038-9 9a8.96 8.96 0 0 0 2.514 6.223 18.107 18.107 0 0 0-6.399 4.218c.437.593.901 1.17 1.422 1.708.035.037.07.075.103.115.057.069.117.125.174.183.332.326.682.63 1.038.923A14.04 14.04 0 0 1 24 32c3.729 0 7.249 1.456 9.906 4.094"
          opacity=".6"
        />
        <path d="M24 8C15.178 8 8 15.178 8 24c0 4.171 1.611 8.13 4.537 11.149.035.037.07.075.103.115.057.069.117.125.174.183C15.803 38.378 19.779 40 24 40s8.198-1.622 11.2-4.567c.051-.052.11-.108.167-.177.031-.037.064-.073.097-.107C38.389 32.13 40 28.17 40 24c0-8.822-7.178-16-16-16m0 36c-5.274 0-10.247-2.028-14-5.712a6.613 6.613 0 0 1-.383-.405C5.994 34.121 4 29.194 4 24 4 12.972 12.972 4 24 4s20 8.972 20 20c0 5.194-1.995 10.122-5.618 13.886a7.312 7.312 0 0 1-.347.368C34.248 41.972 29.275 44 24 44" />
      </g>
    </SvgIcon>
  );
};
