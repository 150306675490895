import React, { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton, Icons, Stack, Typography } from '@egym/ui';

type Props = {
  link: string;
};

const AppDesignSharePreviewButtonContent: React.FC<Props> = ({ link }) => {
  const { t } = useTranslation();
  const [isLinkCopied, setIsLinkCopied] = useState(false);

  return (
    <>
      <Typography variant="body1Bold">{t('appDesign.labels.shareAppPreviewViaLink') as ReactNode}:</Typography>
      <Stack direction="row" alignItems="center">
        <Typography noWrap variant="body2">
          {link}
        </Typography>
        <IconButton
          edge="end"
          size="medium"
          sx={{ bgcolor: 'transparent' }}
          onClick={() => {
            navigator.clipboard.writeText(link);
            setIsLinkCopied(true);
          }}
        >
          {isLinkCopied ? <Icons.Check fontSize="inherit" /> : <Icons.FileCopyOutlined fontSize="inherit" />}
        </IconButton>
      </Stack>
    </>
  );
};

export default AppDesignSharePreviewButtonContent;
