import React, { PropsWithChildren } from 'react';
import { TypographyProps } from '@mui/material';
import { SxProps } from '@mui/system';
import { Paper } from '../../surfaces/Paper';
import PanelContent from './PanelContent';
import PanelFooter from './PanelFooter';
import PanelHeader from './PanelHeader';

type Props = {
  title?: string;
  titleTypographyProps?: TypographyProps<any, any>;
  headerTools?: React.ReactNode;
  footerTools?: React.ReactNode;
  sx?: SxProps;
  headerSx?: SxProps;
  contentSx?: SxProps;
  footerSx?: SxProps;
  onClick?: () => void;
} & PropsWithChildren;

const Panel: React.FC<Props> = ({
  sx,
  headerSx,
  contentSx,
  footerSx,
  title,
  titleTypographyProps,
  headerTools,
  footerTools,
  children,
  onClick,
}) => {
  return (
    <Paper sx={sx} onClick={onClick}>
      {(title || headerTools) && (
        <PanelHeader sx={headerSx} title={title} tools={headerTools} titleTypographyProps={titleTypographyProps} />
      )}
      <PanelContent sx={contentSx}>{children}</PanelContent>
      {footerTools && <PanelFooter sx={footerSx} tools={footerTools} />}
    </Paper>
  );
};

export default Panel;
