import { FC } from 'react';
import { SvgIconProps } from '@mui/material';
import { PartialBy, Uuid } from '@egym/types';
import { AppLayoutFeatureComponentProps, AppLayoutFeatureEnum, AppPreviewerTab } from '@components';
import {
  ApplicationClientDto,
  ApplicationDesignStatus,
  ApplicationLayout,
  ApplicationPackage,
  FormProgress,
} from '../applications';

export enum ColorThemeEnum {
  EXTREME_RED = 'EXTREME_RED',
  ORANGE_ACTION = 'ORANGE_ACTION',
  ORANGE_ACCENT = 'ORANGE_ACCENT',
  ROYAL_BLUE = 'ROYAL_BLUE',
  BLUE_WAVE = 'BLUE_WAVE',
  NATURAL_GREEN = 'NATURAL_GREEN',
  AVOCADO_GREEN = 'AVOCADO_GREEN',
  TWILIGHT_SPARKLE = 'TWILIGHT_SPARKLE',
  PINK = 'PINK',
  MIDNIGHT = 'MIDNIGHT',
  CUSTOM = 'CUSTOM',
}

export enum AppDesignLayoutTabType {
  Activity = 'ACTIVITY',
  BioAge = 'BIO_AGE',
  Classes = 'CLASSES',
  Club = 'CLUB',
  Community = 'COMMUNITY',
  Compete = 'COMPETE',
  Custom = 'CUSTOM',
  Home = 'HOME',
  Perks = 'PERKS',
  Progress = 'PROGRESS',
  // todo: remove VIDEO after https://egym.atlassian.net/browse/WT-564
  Video = 'VIDEO',
  Wellness = 'WELLNESS',
  Workouts = 'WORKOUTS',
}

export enum AppDesignWidgetCategory {
  WebWidget = 'WebWidget',
  Facility = 'Facility',
  Social = 'Social',
  Workouts = 'Workouts',
  Progress = 'Progress',
  Perks = 'Perks',
}

export type ApplicationDesignDto = {
  id: Uuid;
  layout: ApplicationLayout;
  status: ApplicationDesignStatus;
  progress?: FormProgress;
  locales: string[];
  appIcon: {
    backgroundColor?: string;
    link?: string;
  };
  colorTheme: ColorThemeEnum | null;
  customColors: {
    dashboard3BackgroundPrimary?: string;
    dashboard3BackgroundSecondary?: string;
    primary: string | null;
    secondary: string | null;
  };
  signIn: {
    predefinedImageLink?: string;
    predefinedImageId?: number;
    customImageId?: string;
    customImageLink?: string;
    backgroundImageOverlayColor?: string;
    logoLink?: string;
    logoDisabled?: boolean;
    logoPositionLeft?: number;
    logoPositionTop?: number;
    logoWidth?: number;
    logoHeight?: number;
    logoRotate?: number;
  };
  mainTabHeader: {
    iconLink?: string | null;
    iconPredefinedId?: number | null;
  };
  mainTabBrandingTabVisited?: boolean;
};

export type CustomSignInBackgroundImage = {
  id: Uuid;
  link: string;
  createdAt: string;
};

export enum AppDesignMenuItemEnum {
  AppLanguages = 'AppLanguages',
  AppLayout = 'AppLayout',
  ColorTheme = 'ColorTheme',
  MainTabBranding = 'MainTabBranding',
  AppIcon = 'AppIcon',
  SignInScreen = 'SignInScreen',
  TabsOrder = 'TabsOrder',
  TabCustomization = 'TabCustomization',
}

export type AppDesignLayoutTab = {
  id: number;
  type: AppDesignLayoutTabType;
  order: number;
  localizedName: Record<string, string>;
  icon?: {
    id?: number;
    link?: string;
  };
};

export type AppDesignLayoutTabWidget = {
  id: number | Uuid | null; // Uuid is used internally, not a part of model
  name: AppLayoutFeatureEnum;
  tabId?: number | null;
  groupId?: number | null;
  order: number;
  disabled?: boolean;
  preferences?: Record<string, any> | null;
  createdAt?: Date | string;
  updatedAt?: Date | string;
};

export type AppDesignLayoutTabPayload = Pick<AppDesignLayoutTab, 'id' | 'localizedName'> & { order?: number };

export type DefaultAppDesignLayoutTab = {
  type: AppDesignLayoutTabType;
  isDemoTab?: boolean;
  defaultIcon: PredefinedTabIcon;
  defaultTitle: string;
  defaultFeatures: AppLayoutFeatureEnum[];
};

export type PredefinedTabIcon = {
  id: number;
  path: string;
  component?: FC<SvgIconProps>;
};

export type PredefinedImage = {
  id: number;
  path: string;
};

export type DefaultAppLayoutConfig = Record<
  ApplicationPackage,
  { layouts: Record<ApplicationLayout, DefaultAppDesignLayoutTab[]>; extraFeatures: AppLayoutFeatureEnum[] }
>;

export type PredefinedTabImage = Omit<PredefinedTabIcon, 'component'>;

export type WidgetWithTab = PartialBy<AppDesignLayoutTabWidget, 'id' | 'order'> & {
  addedWidget?: AppDesignLayoutTabWidget | null;
  addedOnTab?: AppPreviewerTab | null;
};

export type RemainingWidget = {
  props: AppDesignLayoutTabWidget;
  siblingWidgetId?: number;
  newTabId?: number | null;
  prevTabId?: number | null;
  shouldBeRemoved: boolean;
};

export type MoveWidgetsFormValues = {
  currentWidgets: ({
    component: FC<AppLayoutFeatureComponentProps>;
    canBeRemoved: boolean;
  } & RemainingWidget)[];
};

export enum WidgetViewStyle {
  Image = 'IMAGE',
  Icon = 'ICON',
}

export type DesignValidationErrorId = 'tabIsEmpty' | 'defaultTabName' | 'widgetGroupIsNotConfigured';

export type DesignValidationError = {
  errorId: DesignValidationErrorId;
  message: {
    popup: string;
    tooltip: string;
    alert: string;
  };
  section?: 'features' | 'settings';
  menuItem?: AppDesignMenuItemEnum;
  tabId?: AppPreviewerTab['id'] | null;
  widgetId?: AppDesignLayoutTabWidget['id'];
  validatedOnSubmit?: boolean;
};

export type AppDesignCustomColors = {
  primary: string | null;
  secondary: string | null;
  dashboard3BackgroundPrimary?: string;
  dashboard3BackgroundSecondary?: string;
};

export type AppDesignFormValues = {
  layout: ApplicationClientDto['layout'] | null;
  appIcon: string | File[] | null;
  locales: string[];
  appIconBackground?: string;
  signInLogo?: string | File[] | null;
  signInPredefinedImageId?: number | null;
  signInBackgroundImage?: string | File | null;
  signInCustomImageId?: string | null;
  signInBackgroundImageOverlayColor?: string;
  signInLogoPositionLeft?: number;
  signInLogoPositionTop?: number;
  signInLogoWidth?: number;
  signInLogoHeight?: number;
  signInLogoRotate?: number;
  signInLogoDisabled?: boolean;
  colorTheme?: ColorThemeEnum | null;
  customColors?: AppDesignCustomColors;
  appLayoutTabs: AppPreviewerTab[];
  mainTabHeaderIcon: string | File[] | null;
  mainTabPredefinedIconId?: number | null;
  mainTabBrandingTabVisited?: boolean;
};

export enum WebWidgetType {
  Single = 'Single',
  Group = 'Group',
}

export enum Orientation {
  Vertical = 'VERTICAL',
  Horizontal = 'HORIZONTAL',
}
