import React, { ReactNode, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SxProps } from '@mui/system';
import { Button, Stack, Typography, Box } from '@egym/ui';
import { PredefinedImage } from '@types';
import PredefinedViewImage from './components/PredefinedViewImage';

type Props = {
  handleClose?: () => void; // passed from Popover
  onSelectImage: (image: PredefinedImage) => void;
  images: PredefinedImage[];
  predefinedImageId?: number | null;
  gridSx?: SxProps;
};

const PredefinedImagesLibraryPopoverContent: React.FC<Props> = ({
  images,
  handleClose,
  onSelectImage,
  predefinedImageId,
  gridSx,
}) => {
  const { t } = useTranslation();
  const [selectedImage, setSelectedImage] = useState<PredefinedImage | undefined>(
    images.find(image => image.id === predefinedImageId),
  );

  const onConfirm = useCallback(() => {
    if (selectedImage) {
      onSelectImage(selectedImage);
    }
    if (handleClose) handleClose();
  }, [selectedImage, handleClose, onSelectImage]);

  return (
    <Stack sx={{ position: 'relative', maxWidth: '590px', maxHeight: '500px', overflowY: 'scroll' }}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        p={5}
        pb={3}
        sx={{
          position: 'sticky',
          top: 0,
          bgcolor: 'common.white',
          zIndex: 10,
          borderBottom: '1px solid',
          borderColor: 'grey.300',
        }}
      >
        <Typography variant="h6Bold">{t('appDesign.labels.selectImage') as ReactNode}</Typography>
      </Stack>
      <Box
        sx={{
          display: 'grid',
          gridGap: '26px',
          gridTemplateColumns: 'repeat(2, 262px)',
          p: 5,
          maxHeight: '100%',
          ...gridSx,
        }}
      >
        {images.map(icon => (
          <PredefinedViewImage
            key={icon.id}
            {...icon}
            selectedImage={selectedImage}
            setSelectedImage={setSelectedImage}
          />
        ))}
      </Box>
      <Stack
        direction="row"
        p={5}
        sx={{
          position: 'sticky',
          bottom: 0,
          bgcolor: 'common.white',
          zIndex: 10,
          borderTop: '1px solid',
          borderColor: 'grey.300',
        }}
        spacing={3}
      >
        <Button
          text="common.labels.select"
          color="primary"
          variant="contained"
          disabled={!selectedImage}
          onClick={onConfirm}
        />
        <Button text="common.buttons.cancel" variant="outlined" color="inherit" onClick={handleClose} />
      </Stack>
    </Stack>
  );
};

export default PredefinedImagesLibraryPopoverContent;
