import React, { useMemo } from 'react';
import { ColorResult } from 'react-color';
import { SxProps } from '@mui/system';
import { FieldProps } from 'formik';
import { getByStringPath } from '@egym/utils';
import ColorPicker, { ColorPickerProps } from '../ColorPicker';
import { FormFieldWithLabel } from '../FormFieldWithLabel';

type Props = {
  wrapperSx?: SxProps;
  fieldSx?: SxProps;
  labelSx?: SxProps;
  disabled?: boolean;
  label?: string;
  helperText?: string;
  onChange?: (value: string) => void;
} & ColorPickerProps &
  FieldProps;

export const ColorPickerFormField: React.FC<Props> = ({
  wrapperSx,
  fieldSx,
  label,
  labelSx,
  helperText,
  field,
  form,
  disabled,
  onChange,
  ...rest
}) => {
  const getByFieldName = useMemo(() => getByStringPath(field.name), [field.name]);

  const handleChange = (colorResult: ColorResult | string) => {
    if (disabled) return;
    if (!colorResult) return;

    if (typeof colorResult === 'string') {
      field.onChange({ target: { name: field.name, value: colorResult } });
      if (onChange) onChange(colorResult);
    } else {
      const { r, g, b, a } = colorResult.rgb;
      const rgbString = `rgba(${r}, ${g}, ${b}, ${a})`;

      if (colorResult) {
        field.onChange({ target: { name: field.name, value: rgbString } });
        if (onChange) onChange(rgbString);
      }
    }
  };

  return (
    <FormFieldWithLabel wrapperSx={wrapperSx} labelSx={labelSx} label={label} hiddenLabel isViewMode={disabled}>
      {({ fieldSx: labelFieldSx, helperTextSx: labelHelperTextSx }) => {
        const colorPickerFieldSx = { ...labelFieldSx, ...fieldSx };

        return (
          <ColorPicker
            {...rest}
            {...field}
            handleChange={handleChange}
            colorPickerFieldSx={colorPickerFieldSx}
            error={rest.error || getByFieldName(form.errors)}
            helperText={helperText || getByFieldName(form.errors)}
            disabled={disabled}
            FormHelperTextProps={{
              sx: labelHelperTextSx,
              ...rest.FormHelperTextProps,
            }}
          />
        );
      }}
    </FormFieldWithLabel>
  );
};
