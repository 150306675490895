import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const EditPencilIcon: React.FC<SvgIconProps> = ({ sx, htmlColor = '#4C4A4A', ...rest }) => {
  return (
    <SvgIcon viewBox="0 0 24 24" sx={{ width: '24px', height: '24px', ...sx }} {...rest}>
      <g fill="none" fillRule="evenodd">
        <g fill={htmlColor}>
          <g>
            <g>
              <g>
                <g>
                  <path
                    d="M5.177 15.432l3.396 3.451-2.351.105c-.638.029-1.177-.465-1.206-1.102-.002-.044-.001-.087.002-.13l.159-2.324zM17.882 4.43l.01.01 1.696 1.72c.444.449.444 1.172 0 1.622L9.454 18.053 6.136 14.69 16.248 4.44c.448-.455 1.18-.46 1.634-.011z"
                    transform="translate(-296 -534) translate(20 399) translate(16 114) translate(252 13) translate(8 8)"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export default EditPencilIcon;
