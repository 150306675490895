import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const IllustrationWebWidgetLayoutChangeFromHorizontalToVertical: React.FC<SvgIconProps> = ({ sx, ...props }) => {
  return (
    <SvgIcon
      viewBox="0 0 457 189"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
      sx={{ width: '457px', height: '189px', ...sx }}
    >
      <defs>
        <path id="3u712e45ua" d="M0 0h457v189H0z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="55fzqrsaub" fill="#fff">
          <use xlinkHref="#3u712e45ua" />
        </mask>
        <g mask="url(#55fzqrsaub)">
          <rect stroke="#9E9E9E" strokeWidth="5" fill="#FFF" x="2.5" y="2.5" width="184" height="215" rx="22" />
          <rect stroke="#EDEDED" strokeWidth="6" x="21" y="176" width="147" height="50" rx="4" />
          <g transform="translate(18 42)">
            <rect stroke="#EDEDED" strokeWidth="6" x="3" y="3" width="66" height="86" rx="4" />
            <circle fill="#EDEDED" cx="25.5" cy="25.5" r="11.5" />
            <rect fill="#EDEDED" x="14" y="60" width="16.552" height="6" rx="3" />
            <rect fill="#EDEDED" x="14" y="72" width="32" height="6" rx="3" />
          </g>
          <g transform="translate(99 42)">
            <rect fill="#CECECE" width="72" height="92" rx="4" />
            <circle fill="#9E9E9E" cx="23.5" cy="25.5" r="11.5" />
            <rect fill="#EDEDED" x="12" y="60" width="28.087" height="6" rx="3" />
            <rect fill="#EDEDED" x="12" y="72" width="38" height="6" rx="3" />
          </g>
          <rect fill="#EDEDED" x="18" y="20" width="101" height="10" rx="5" />
          <rect fill="#EDEDED" x="18" y="151" width="51" height="10" rx="5" />
          <rect stroke="#9E9E9E" strokeWidth="5" fill="#FFF" x="270.5" y="2.5" width="184" height="215" rx="22" />
          <g transform="translate(286 107)">
            <rect stroke="#EDEDED" strokeWidth="6" x="3" y="3" width="147" height="50" rx="4" />
            <rect stroke="#EDEDED" strokeWidth="6" x="3" y="68" width="147" height="50" rx="4" />
            <rect fill="#EDEDED" x="12" y="21" width="41.391" height="6" rx="3" />
            <rect fill="#EDEDED" x="12" y="33" width="56" height="6" rx="3" />
            <circle fill="#EDEDED" cx="126.5" cy="25.5" r="11.5" />
          </g>
          <g transform="translate(286 42)">
            <rect fill="#CECECE" width="153" height="56" rx="4" />
            <circle fill="#9E9E9E" cx="126.5" cy="28.5" r="11.5" />
            <g transform="translate(12 19)" fill="#EDEDED">
              <rect width="41.391" height="6" rx="3" />
              <rect y="12" width="56" height="6" rx="3" />
            </g>
          </g>
          <rect fill="#EDEDED" x="286" y="20" width="101" height="10" rx="5" />
          <path
            d="M202 100a7.002 7.002 0 0 1 6.93 6H211v2l-2.071.001A7.002 7.002 0 0 1 195 107a7 7 0 0 1 7-7zm0 2a5 5 0 1 0 0 10 5 5 0 0 0 0-10zm15 4v2h-3v-2h3zm6 0v2h-3v-2h3zm5.42-.623.97 1.749a6.965 6.965 0 0 1-3.063.866L226 108v-2c.86 0 1.686-.216 2.42-.623zm2.573-4.102 1.997.108a6.965 6.965 0 0 1-.895 3.062l-.165.276-1.693-1.065c.448-.712.71-1.526.756-2.381zm2.007-5.91v3h-2v-3h2zm0-6v3h-2v-3h2zm0-6v3h-2v-3h2zm0-6v3h-2v-3h2zM248 63l14 7-14 7V63zm-14.984 8.084 1.424 1.405a5 5 0 0 0-1.172 1.892l-.084.269-1.926-.539a6.994 6.994 0 0 1 1.758-3.027zM239.27 69v2H238c-.373 0-.74.04-1.098.12l-.266.068-.544-1.925a6.989 6.989 0 0 1 1.583-.256L238 69h1.269zm6 0v2h-3v-2h3z"
            fill="#C75300"
            fillRule="nonzero"
          />
        </g>
      </g>
    </SvgIcon>
  );
};
