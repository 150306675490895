export const rankingOnTheTabData = [
  {
    avatar: '/images/previewerDefaultImages/avatars/@2ximg.png',
    name: 'Peter',
    activityPoints: '11200',
  },
  {
    avatar: '/images/previewerDefaultImages/avatars/@2ximg2.png',
    name: 'Brittany',
    activityPoints: '10570',
  },
  {
    me: true,
    avatar: '/images/previewerDefaultImages/avatars/Lukas.png',
    name: 'Lukas',
    activityPoints: '8399',
  },
  {
    avatar: '/images/previewerDefaultImages/avatars/Jordi.png',
    name: 'Jordi',
    activityPoints: '7301',
  },
  {
    avatar: '/images/previewerDefaultImages/avatars/Irene.png',
    name: 'Irene',
    activityPoints: '6202',
  },
  {
    avatar: '/images/previewerDefaultImages/avatars/Karlien.png',
    name: 'Karlien',
    activityPoints: '8399',
  },
  {
    avatar: '/images/previewerDefaultImages/avatars/Edward.png',
    name: 'Edward',
    activityPoints: '8399',
  },
  {
    avatar: '/images/previewerDefaultImages/avatars/Sidnee.png',
    name: 'Sidnee',
    activityPoints: '8399',
  },
];
