import React, { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Option } from '@egym/types';
import { Box, Stack, Typography } from '@egym/ui';
import { AppPreviewerPalette, MoveWidgetsFormFields, PreviewerState } from '@components';
import { MoveWidgetsFormValues } from '@types';
import ActionRequiredIcon from '../ActionRequiredIcon';

type Props = {
  tabName: string;
  currentWidgets: MoveWidgetsFormValues['currentWidgets'];
  previewerPalette: AppPreviewerPalette;
  previewerState: Partial<PreviewerState>;
  tabOptions: Option[];
};

const DeleteTabAndMoveCoreFeatures: FC<Props> = ({
  tabName,
  currentWidgets,
  previewerState,
  previewerPalette,
  tabOptions,
}) => {
  const { t } = useTranslation();

  return (
    <Stack sx={{ mt: 4 }}>
      <Typography variant="body1">
        {t('appDesign.labels.deleteTabDialog.description', { tabName }) as ReactNode}
      </Typography>
      <Box>
        <Typography component="span" variant="body1" sx={{ mr: 1 }}>
          {t('appDesign.labels.deleteTabDialog.featuresWillBeRemoved') as ReactNode}
        </Typography>
        <Typography component="span" variant="body1">
          {t('appDesign.labels.deleteTabDialog.featuresAvailableInLibrary') as ReactNode}
        </Typography>
      </Box>
      <ActionRequiredIcon wrapperSx={{ mt: 6, mb: 2 }} />
      <Typography variant="body1" sx={{ pb: 5 }}>
        {t('appDesign.labels.tabHasCoreFeatures') as ReactNode}
      </Typography>
      <MoveWidgetsFormFields
        currentWidgets={currentWidgets}
        previewerPalette={previewerPalette}
        previewerState={previewerState}
        tabsOptions={tabOptions}
        onlyCoreFeatures
      />
    </Stack>
  );
};

export default DeleteTabAndMoveCoreFeatures;
