import React, { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { SvgIconProps, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Chip, Icons, Stack, tooltipClasses, TooltipProps } from '@egym/ui';
import { LanguageListItem } from '../../MultiLanguageSelectorFormFieldProps';

type Props = {
  language: LanguageListItem;
  itemsLength: number;
  onRemoveAddedLanguage: (localeToRemove: string) => void;
  defaultLocale?: string;
  Icon?: FC<SvgIconProps<'svg', unknown>>;
};

const ChipTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} arrow />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    fontSize: theme.typography.pxToRem(12),
    textAlign: 'center',
    maxWidth: '205px',
    padding: theme.spacing(1.5),
  },
}));

const SortableLanguageChip: FC<Props> = ({ language, itemsLength, onRemoveAddedLanguage, defaultLocale, Icon }) => {
  const { t } = useTranslation();

  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: language.id,
    disabled: itemsLength < 2,
  });

  const style = {
    zIndex: transform?.x || transform?.y ? 999 : 1,
    transform: CSS.Transform.toString({ x: transform?.x || 0, y: transform?.y || 0, scaleX: 1, scaleY: 1 }),
    transition,
  };

  return (
    <ChipTooltip
      title={(t('common.labels.defaultLanguageTooltip', { language: language.name }) as ReactNode) || ''}
      disableHoverListener={language.locale !== defaultLocale}
      arrow
    >
      <Chip
        ref={setNodeRef}
        style={style}
        {...attributes}
        {...listeners}
        key={language.locale}
        variant="outlined"
        color="default"
        icon={
          Icon && (
            <Stack direction="row" alignItems="center">
              {itemsLength > 1 && <Icons.DragIndicator fontSize="small" sx={{ ml: 2 }} />}
              <Icon sx={{ ml: '8px !important' }} />
            </Stack>
          )
        }
        label={language.name}
        sx={{
          height: '32px',
          borderRadius: 4,
          mb: 3,
          ':not(:last-child)': {
            mr: 3,
          },
          opacity: isDragging ? 0.5 : undefined,
          cursor: 'grab',
          backgroundColor: 'common.white',
        }}
        onDelete={
          itemsLength > 1 && String(language.locale) !== defaultLocale
            ? event => {
                event.stopPropagation();
                onRemoveAddedLanguage(language.locale);
              }
            : undefined
        }
        deleteIcon={<Icons.Close sx={{ fontSize: '16px !important' }} />}
      />
    </ChipTooltip>
  );
};

export default SortableLanguageChip;
