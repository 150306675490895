import { useCallback, useEffect } from 'react';

let throttleTimer;
const noop = () => {};

const throttle = (callback, time) => {
  let timeout;

  if (!throttleTimer) {
    throttleTimer = true;
    timeout = setTimeout(() => {
      callback();
      throttleTimer = false;
    }, time);
  }

  return timeout;
};

const useThrottledOnScroll = (ref, callback, delay) => {
  const throttledCallback = useCallback(() => {
    if (callback) return throttle(callback, delay);
    return noop;
  }, [callback, delay]);

  useEffect(() => {
    if (ref.current) {
      ref.current.addEventListener('scroll', throttledCallback);
      return () => {
        if (ref.current) {
          // eslint-disable-next-line react-hooks/exhaustive-deps
          ref.current.removeEventListener('scroll', throttledCallback);
        }
      };
    }
  }, [ref, throttledCallback]);
};

export default useThrottledOnScroll;
