import { tableQuerySelector, tableStateAtom } from './helpers';

const keys = {
  table: 'gymLocationsTable.table',
  queryParams: 'gymLocationsTable.queryParams',
};

const atoms = {
  table: tableStateAtom(keys.table),
};

const selectors = {
  queryParams: tableQuerySelector(keys.queryParams, atoms.table),
};

export const gymLocationsTableState = {
  keys,
  atoms,
  selectors,
};
