import { parse } from 'date-fns';
import { format as dateFnsTzFormat, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';

export const convertDate = (value, fromUTC = true, locale?) => {
  const timestamp = typeof value === 'string' ? Date.parse(value) : value;

  const currentTimezone = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone || '';

  return fromUTC
    ? utcToZonedTime(timestamp, currentTimezone, { locale })
    : zonedTimeToUtc(timestamp, currentTimezone, { locale });
};

export const formatDate = (value, format = 'PPp', fromUTC = true, locale?) => {
  const currentTimezone = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone || '';

  const convertedDate = convertDate(value, fromUTC, locale);

  return dateFnsTzFormat(convertedDate, format, { timeZone: currentTimezone, locale });
};

export const getCurrentUTCTimestamp = () => {
  const current = new Date();

  return current.getTime() - current.getTimezoneOffset();
};

export const timeStringToDate = (value: string, format = 'kk:mm:ss') => {
  if (!value) return null;

  let valueRes = value;

  if (value && value.split(':')[0] === '00') valueRes = value.replace('00', '24');

  return parse(valueRes, format, Date.now());
};
