import React from 'react';
import { Box } from '@mui/material';
import { SxProps } from '@mui/system';
import { LoaderProps, Loader } from '@egym/ui';

type Props = {
  loader?: LoaderProps;
  wrapperSx?: SxProps;
};

export const LoadingContainer: React.FC<Props> = ({ loader, wrapperSx }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        py: 10,
        width: '100%',
        ...wrapperSx,
      }}
    >
      <Loader {...loader} />
    </Box>
  );
};
