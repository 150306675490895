import React, { FC } from 'react';
import { Icons } from '@egym/ui';

export type CallToActionProps = {
  title?: string;
  withArrow?: boolean;
};

const CallToAction: FC<CallToActionProps> = ({ title, withArrow }) => {
  return title ? (
    <div style={{ position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
      <span
        style={{
          fontSize: title.length > 20 ? '14px' : '15px',
          lineHeight: '22px',
          fontWeight: 500,
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          display: '-webkit-box',
          WebkitBoxOrient: 'vertical',
          WebkitLineClamp: '2',
          wordWrap: 'break-word',
          wordBreak: 'break-word',
        }}
      >
        {title}
      </span>
      {withArrow && <Icons.KeyboardArrowRight sx={{ mt: '1px' }} fontSize="medium" />}
    </div>
  ) : null;
};

export default CallToAction;
