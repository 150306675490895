import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { TableCell as MuiTableCell, TableSortLabel } from '@mui/material';
import { SystemStyleObject } from '@mui/system';
import { ConditionalRender, TableColumn, TableState, Typography } from '@egym/ui';

type Props = {
  column: TableColumn;
  headersCellSx?: SystemStyleObject;
  tableState: TableState;
  updateTableStateSorting: (fieldName: string) => void;
};

const ThColumnCell: React.FC<Props> = ({ column, headersCellSx, tableState, updateTableStateSorting }) => {
  const { t } = useTranslation();
  return (
    <MuiTableCell
      variant="head"
      component="th"
      key={column.field}
      align={column.numeric ? 'right' : 'left'}
      padding={column.disablePadding ? 'none' : 'normal'}
      width={column.width}
      sx={{ py: 5.5, ...headersCellSx, ...column.headerCellSx }}
      data-testid={`table-header-cell-${column.field}`}
    >
      <ConditionalRender condition={column.sortable}>
        <TableSortLabel
          active={Boolean(tableState.sorting?.[column.field])}
          direction={tableState.sorting?.[column.field]}
          onClick={() => updateTableStateSorting(column.field)}
        >
          <Typography variant="body2Bold">{t(column.headerTitle) as ReactNode}</Typography>
        </TableSortLabel>
        <Typography variant="body2Bold">{t(column.headerTitle) as ReactNode}</Typography>
      </ConditionalRender>
    </MuiTableCell>
  );
};

export default ThColumnCell;
