import React, { ReactNode } from 'react';
import { CustomPickerProps } from 'react-color';
import { useTranslation } from 'react-i18next';
import { SxProps } from '@mui/system';
import { Saturation as ReactColorSaturation } from 'react-color/lib/components/common';
import { Stack, Typography } from '@egym/ui';

type Props = { wrapperSx?: SxProps } & CustomPickerProps<ReactColorSaturation>;

const Saturation: React.FC<Props> = ({ wrapperSx, ...rest }) => {
  const { t } = useTranslation();

  return (
    <Stack sx={{ width: '100%', boxSizing: 'border-box', ...wrapperSx }}>
      <Typography
        variant="captionBold"
        sx={{ textTransform: 'uppercase', letterSpacing: '0.52px', lineHeight: '20px', mb: 1 }}
      >
        {t('appDesign.labels.colorPicker') as ReactNode}
      </Typography>
      <Stack sx={{ position: 'relative', width: '100%', height: '150px' }}>
        <ReactColorSaturation {...rest} />
      </Stack>
    </Stack>
  );
};

export default Saturation;
