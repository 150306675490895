import { ChangeEvent, MouseEvent, ReactNode } from 'react';
import { TableProps as MuiTableProps } from '@mui/material';
import { SxProps } from '@mui/system';
import { SystemStyleObject } from '@mui/system/styleFunctionSx/styleFunctionSx';
import { SetterOrUpdater } from 'recoil';
import { Option } from '@egym/types';
import { AvailableRowsPerPage } from 'src/packages/ui/src/components/dataDisplay/Table/config';

export type RowWithId<Row> = { id: string | number } & Row;

export type TableRowType = RowWithId<Record<string, any>>;

export type TableRenderCellParams<TData = TableRowType, TDProps = any> = {
  row: TData;
  value: string;
  rawValue: any;
  tdProps?: TDProps;
  isViewMode?: boolean;
};

export type TableRenderEditableCellParams<TData = TableRowType, TDProps = any> = TableRenderCellParams<
  TData,
  TDProps
> & {
  isEditing?: boolean;
  errorMessage?: string;
  toggleIsEditing: () => void;
};

export type TableRenderSubComponentParams<TData = any> = {
  row: TData;
};

export enum TableColumnFilterType {
  Search = 'search',
  Select = 'select',
  DateRange = 'dateRange',
}

export type TableColumn<TData = any> = {
  field: string;
  accessor?: ({ value }) => any;
  headerTitle: string;
  width?: number;
  renderCell?: (params: TableRenderCellParams<TData>) => ReactNode;
  renderEditableCell?: (params: TableRenderEditableCellParams<TData>) => ReactNode;
  numeric?: boolean;
  disablePadding?: boolean;
  editable?: boolean;
  options?: Option[] | ((row: TData) => Option[]);
  toOption?: (value: any, options?: Option[] | null) => Option | undefined;
  validate?: (value: any) => void;
  sortable?: boolean;
  tdProps?: Record<string, any>;
  headerCellSx?: SystemStyleObject;
  bodyCellSx?: SystemStyleObject | ((params: { row: TData; value: string }) => SystemStyleObject);
  hidden?: (isViewMode?: boolean) => boolean;
  filter?: {
    type: TableColumnFilterType;
    filterProps?: any;
  };
};

export type GroupedTableColumn<TData = any> = {
  parentHeaderTitle: string;
  children: TableColumn<TData>[];
  parentHeaderCellSx?: SxProps;
};

export type TableState = {
  sorting?: Record<string, 'asc' | 'desc'>;
  filtering?: Record<string, string | boolean>;
  pagination?: { page?: number; size?: number };
};

export type TableContainerProps = {
  wrapperSx?: SystemStyleObject;
  columns: (TableColumn | GroupedTableColumn)[];
  rows: TableRowType[];
  checkboxSelectionProp?: string;
  showCheckAll?: boolean;
  defaultRowsPerPage?: AvailableRowsPerPage;
  loading?: boolean;
  noRowsLabel?: string;
  noRowsWithFiltersLabel?: string;
  size?: MuiTableProps['size'];
  headersCellSx?: SystemStyleObject;
  tdProps?: Record<string, any>;
  renderSubComponent?: (params: TableRenderSubComponentParams) => ReactNode;
  subTable?: boolean;
  onSelectAllClick?: (checked: boolean) => void;
  onSelectRowClick?: (event: MouseEvent<unknown> | ChangeEvent<HTMLInputElement>, row: TableRowType) => void;
  controlledSelectedRowIds?: string[];
  getIsCheckboxSelected?: (row: TableRowType) => boolean;
  getIsCheckboxIndeterminate?: (row: TableRowType) => boolean;
  indeterminateRowIds?: string[];
  expandAll?: boolean;
  onTableStateChange?: SetterOrUpdater<TableState>;
  tableState?: TableState;
  totalElements?: number;
  isViewMode?: boolean;
};

export type UseTableResult = {
  rowsPaginated: TableRowType[];
  selectedRowIds: string[];
  handleSelectAllClick: (event: ChangeEvent<HTMLInputElement>) => void;
  handleRowClick: (event: MouseEvent<unknown> | ChangeEvent<HTMLInputElement>, row: TableRowType) => void;
  rowsSelectedCount: number;
  totalRowsCount: number;
  rowsPerPage: number;
  page: number;
  handleChangePage: (event: MouseEvent<HTMLButtonElement> | null, newPage: number) => void;
  handleChangeRowsPerPage: (event: ChangeEvent<HTMLInputElement>) => void;
  totalColumnsCount: number;
  isSelectAllChecked: boolean;
  isSelectAllIndeterminate: boolean;
  tableState: TableState;
  updateTableStateSorting: (fieldName: string) => void;
  updateTableStateFilters: (fieldNames: string[], value: any) => void;
  hasExternalPagination: boolean;
  hasFilterableColumns: boolean;
  hasSelectedFilters: boolean;
  hasData: boolean;
};

export type TableProps = TableContainerProps & UseTableResult;
