import React, { ReactElement, ReactNode } from 'react';
import { Stack } from '@mui/material';
import { TFunction } from 'i18next';
import { AppPreviewerPalette } from '../../../../AppPreviewerProps';
import { StyledBreadcrumbs } from './PreviewActivityHistoryItemV2.styles';

type Props = {
  tFunction: TFunction;
  palette: Partial<AppPreviewerPalette>;
  icon: ReactElement;
  name: string;
  activityPoints: number;
  breadcrumbs: { text: string; params?: Record<string, any> }[];
};

const PreviewActivityHistoryItemV2: React.FC<Props> = ({
  tFunction,
  palette,
  icon,
  name,
  activityPoints,
  breadcrumbs,
}) => {
  return (
    <Stack direction="row" alignItems="center" sx={{ py: '12px', px: '10px' }}>
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{
          background: 'rgb(244, 244, 244)',
          width: '50px',
          height: '50px',
          borderRadius: '50%',
          border: '1px solid',
          borderColor: 'rgb(234, 234, 234)',
          mr: '15px',
        }}
      >
        {icon}
      </Stack>
      <Stack direction="column">
        <span
          style={{
            fontSize: '14px',
            fontWeight: 600,
            lineHeight: '18px',
            marginBottom: '1px',
            color: 'rgb(33, 33, 33)',
          }}
        >
          {tFunction(name) as ReactNode}
        </span>
        <StyledBreadcrumbs separator="&bull;" sx={{ fontSize: '12px', color: 'rgb(41, 41, 41)', lineHeight: '16px' }}>
          {breadcrumbs.map(breadcrumb => {
            return <span key={breadcrumb.text}>{tFunction(breadcrumb.text, breadcrumb.params) as ReactNode}</span>;
          })}
        </StyledBreadcrumbs>
        <span
          style={{
            fontSize: '11px',
            lineHeight: '16px',
            color: palette.primary,
            fontWeight: 600,
            background: palette.primaryTransparent,
            padding: '2px 6px',
            borderRadius: '4px',
            width: 'fit-content',
            textTransform: 'lowercase',
            marginTop: '4px',
          }}
        >
          {tFunction('appPreviewer.labels.activityPointsCount', { count: activityPoints }) as ReactNode}
        </span>
      </Stack>
    </Stack>
  );
};

export default PreviewActivityHistoryItemV2;
