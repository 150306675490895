import React, { forwardRef, ReactNode } from 'react';
import { differenceInCalendarDays, endOfMonth, subDays } from 'date-fns';
import { Box, Stack, useFormatDate } from '@egym/ui';
import { AppLayoutFeatureComponentProps } from '@components';
import { IconWrapper, InfoOutlinedIcon } from '../../../Icons';
import PreviewContainer from '../../../PreviewContainer';
import { rankingOnTheTabData } from './data';

const PreviewRankingOnTheTab = forwardRef<HTMLDivElement, AppLayoutFeatureComponentProps>(
  ({ tFunction, palette, previewerState }, ref) => {
    const { format } = useFormatDate(previewerState.selectedLocale);

    return (
      <div ref={ref} style={{ position: 'relative', backgroundColor: 'white', marginBottom: '-20px' }}>
        <div style={{ boxShadow: '0 10px 20px rgba(95, 95, 95, 0.1)' }}>
          <PreviewContainer>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <IconWrapper bgColor={palette.primaryTransparent} wrapperSx={{ visibility: 'hidden' }}>
                <InfoOutlinedIcon htmlColor={palette.primary} />
              </IconWrapper>
              <span
                style={{
                  fontSize: '18px',
                  fontWeight: 600,
                  color: '#212121',
                }}
              >
                {format(new Date(), 'LLLL')} {tFunction('appPreviewer.labels.ranking') as ReactNode}
              </span>
              <IconWrapper bgColor={palette.primaryTransparent}>
                <InfoOutlinedIcon htmlColor={palette.primary} />
              </IconWrapper>
            </Stack>
          </PreviewContainer>

          <PreviewContainer>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ mt: '7px', position: 'relative', height: '60px' }}
            >
              <Stack alignItems="center" sx={{ maxWidth: '85px' }}>
                <span style={{ fontSize: '15px', fontWeight: 600 }}>
                  3rd{' '}
                  <span style={{ textTransform: 'capitalize', textAlign: 'center' }}>
                    {tFunction('appPreviewer.labels.place') as ReactNode}
                  </span>
                </span>
                <span
                  style={{
                    fontSize: '13px',
                    fontWeight: 400,
                    color: 'rgb(76, 74, 74)',
                    textTransform: 'lowercase',
                    textAlign: 'center',
                  }}
                >
                  {tFunction('appPreviewer.labels.inRanking') as ReactNode}
                </span>
              </Stack>
              <Box
                sx={{
                  position: 'absolute',
                  width: '60px',
                  height: '60px',
                  overflow: 'hidden',
                  left: '50%',
                  transform: 'translateX(-50%)',
                }}
              >
                <Box
                  component="img"
                  src="/images/previewerDefaultImages/avatars/Lukas.png"
                  sx={{ position: 'absolute', width: '70px', height: '70px', left: '-4px', top: '-2px' }}
                />
              </Box>
              <Stack alignItems="center" sx={{ maxWidth: '85px' }}>
                <span style={{ fontSize: '15px', fontWeight: 600 }}>8399</span>
                <span
                  style={{
                    fontSize: '13px',
                    fontWeight: 400,
                    color: 'rgb(76, 74, 74)',
                    textTransform: 'lowercase',
                    textAlign: 'center',
                  }}
                >
                  {tFunction('appPreviewer.labels.activityPoints') as ReactNode}
                </span>
              </Stack>
            </Stack>
          </PreviewContainer>

          <PreviewContainer wrapperSx={{ pb: '15px' }}>
            <Stack>
              <div
                style={{
                  marginTop: '25px',
                  padding: '5px',
                  paddingTop: '20px',
                  paddingBottom: '10px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  position: 'relative',
                  backgroundColor: 'rgb(244, 244, 244)',
                  width: '100%',
                  boxSizing: 'border-box',
                  borderRadius: '8px',
                  color: 'rgb(76, 74, 74)',
                  fontSize: '13px',
                  textAlign: 'center',
                  lineHeight: '16px',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    position: 'absolute',
                    top: '0px',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    color: 'white',
                    backgroundColor: palette.primary,
                    borderRadius: '15px',
                    minHeight: '25px',
                    padding: '5px',
                    paddingLeft: '15px',
                    paddingRight: '15px',
                    boxSizing: 'border-box',
                    width: 'fit-content',
                  }}
                >
                  {
                    tFunction('appPreviewer.labels.daysToGo', {
                      count: differenceInCalendarDays(endOfMonth(new Date()), subDays(new Date(), 1)),
                    }) as ReactNode
                  }
                </div>
                {tFunction('appPreviewer.labels.rankingHint') as ReactNode}
              </div>
            </Stack>
          </PreviewContainer>
        </div>

        <Stack sx={{ py: '10px' }}>
          {rankingOnTheTabData.map((rankItem, index) => {
            return (
              <PreviewContainer
                key={rankItem.name}
                wrapperSx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  color: 'rgb(76, 74, 74)',
                  py: '8px',
                  backgroundColor: rankItem.me ? 'rgb(244, 244, 244)' : undefined,
                  ':not(:last-child)': { borderBottom: '1px solid rgb(244, 244, 244)' },
                }}
              >
                <span style={{ fontSize: '13px' }}>{index + 1}</span>
                <div
                  style={{
                    position: 'relative',
                    width: '40px',
                    height: '40px',
                    borderRadius: '50%',
                    marginLeft: '15px',
                    marginRight: '10px',
                    boxSizing: 'border-box',
                    overflow: 'hidden',
                  }}
                >
                  {rankItem.name === 'Lukas' ? (
                    <img
                      style={{ position: 'absolute', left: '-4px', top: '-2px', width: '46px', height: '46px' }}
                      src={rankItem.avatar}
                      alt={rankItem.name}
                    />
                  ) : (
                    <img
                      style={{ position: 'absolute', left: '0', top: '0', width: '100%', height: '100%' }}
                      src={rankItem.avatar}
                      alt={rankItem.name}
                    />
                  )}
                </div>

                <span style={{ fontSize: '14px' }}>{rankItem.name}</span>

                <span style={{ fontSize: '14px', fontWeight: 600, marginLeft: 'auto' }}>{rankItem.activityPoints}</span>
              </PreviewContainer>
            );
          })}
        </Stack>
      </div>
    );
  },
);

export default PreviewRankingOnTheTab;
