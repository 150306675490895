import { AppPreviewerPalette, PreviewerRoute } from '../../AppPreviewerProps';

export type PreviewerProfileRouteContainerProps = {
  value: PreviewerRoute;
  id: string;
};

export type UsePreviewerProfileRouteResult = {
  palette: AppPreviewerPalette;
  shownFeatures: Array<ProfileFeature>;
};

export enum ProfileFeature {
  EDIT_PROFILE,
  SETTINGS,
  FAQ_AND_SUPPORT,
  CONNECTED_APPS,
  MY_ACCOUNT,
  CHECKIN_HISTORY,
  MOBILE_FEEDBACK,
}

export type PreviewerProfileRouteProps = PreviewerProfileRouteContainerProps & UsePreviewerProfileRouteResult;
