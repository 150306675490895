export const bioAgeItemsMock = [
  {
    id: 0,
    name: 'appPreviewer.labels.strength',
    years: 24,
    image: '/images/previewerDefaultImages/features/analysis/muscles.svg',
    checkMuscle: true,
  },
  {
    id: 1,
    name: 'appPreviewer.labels.flexibility',
    years: 30,
    image: '/images/previewerDefaultImages/features/analysis/ic_flexibility.svg',
    checkMuscle: false,
  },
  {
    id: 2,
    name: 'appPreviewer.labels.cardio',
    years: 27,
    image: '/images/previewerDefaultImages/features/analysis/cardio.svg',
    checkMuscle: false,
  },
];
