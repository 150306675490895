import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const MainTabHeaderDefaultIcon10: React.FC<SvgIconProps> = ({ htmlColor = '#FFF', sx, ...props }) => {
  return (
    <SvgIcon viewBox="0 0 48 48" {...props} sx={{ width: '48px', height: '48px', ...sx }}>
      <g fill={htmlColor} fillRule="evenodd">
        <path d="m35.133 29.437 1.664 10.175c.38 2.295-1.952 4.129-4.048 3.108l-.146-.075-8.602-4.744-8.6 4.742c-2.087 1.156-4.47-.597-4.22-2.868l.021-.16 1.664-10.177-7.033-7.189c-1.596-1.63-.781-4.427 1.434-4.892l.173-.031 9.645-1.472 4.33-9.204c1.012-2.14 3.964-2.198 5.082-.17l.089.173 4.33 9.202 9.64 1.47c2.29.344 3.202 3.108 1.73 4.796l-.119.128-7.034 7.188zM23.999 10.552l-3.55 7.548a2.877 2.877 0 0 1-1.97 1.582l-.188.035-8.128 1.239 5.943 6.074c.608.62.902 1.478.82 2.33l-.026.196-1.382 8.454 7.12-3.924a2.835 2.835 0 0 1 2.546-.091l.185.093 7.113 3.922-1.38-8.449c-.143-.853.099-1.724.657-2.382l.134-.148 5.942-6.074-8.128-1.24a2.867 2.867 0 0 1-2.07-1.451l-.087-.17L24 10.553z" />
        <path
          d="m28.923 24.074-2.181 2.229a.275.275 0 0 0-.073.236l.515 3.149c.035.217-.183.384-.37.28l-2.695-1.486a.245.245 0 0 0-.237 0l-2.696 1.487c-.187.103-.405-.064-.37-.281l.515-3.149a.274.274 0 0 0-.073-.236l-2.18-2.23c-.152-.154-.068-.423.14-.454l3.014-.46a.257.257 0 0 0 .192-.146l1.348-2.865a.25.25 0 0 1 .456 0l1.348 2.865a.256.256 0 0 0 .192.146l3.014.46c.208.03.292.3.14.455"
          opacity=".6"
        />
      </g>
    </SvgIcon>
  );
};
