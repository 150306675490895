import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const MainTabHeaderDefaultIcon28: React.FC<SvgIconProps> = ({ htmlColor = '#FFF', sx, ...props }) => {
  return (
    <SvgIcon viewBox="0 0 48 48" {...props} sx={{ width: '48px', height: '48px', ...sx }}>
      <g fill={htmlColor} fillRule="evenodd">
        <path d="M24 34c3.441 0 6.334.857 8.673 2.297 1.45.891 2.41 1.814 2.927 2.503a2 2 0 0 1-3.096 2.527l-.152-.185-.156-.167-.16-.157a8.668 8.668 0 0 0-1.46-1.115C28.855 38.643 26.685 38 24 38c-2.684 0-4.854.643-6.577 1.703a8.668 8.668 0 0 0-1.46 1.115l-.16.157-.155.167-.048.058a2 2 0 1 1-3.2-2.4c.517-.69 1.478-1.612 2.927-2.503C17.667 34.857 20.559 34 24 34zm0-14a6 6 0 1 1 0 12 6 6 0 0 1 0-12zm0 4a2 2 0 1 0 0 4 2 2 0 0 0 0-4z" />
        <path
          d="M14 19a2 2 0 1 1 0 4c-1.989 0-3.592.594-4.88 1.585-.447.344-.82.71-1.121 1.07l-.142.176-.142.198a2 2 0 0 1-3.43-2.058c.42-.7 1.202-1.638 2.396-2.556C8.64 19.906 11.089 19 14 19zm20 0c2.911 0 5.358.906 7.32 2.415 1.193.918 1.975 1.856 2.395 2.556a2 2 0 0 1-3.34 2.195l-.138-.208-.094-.127-.142-.176c-.3-.36-.674-.726-1.12-1.07C37.59 23.594 35.989 23 34 23a2 2 0 1 1 0-4zM14 5a6 6 0 1 1 0 12 6 6 0 0 1 0-12zm20 0a6 6 0 1 1 0 12 6 6 0 0 1 0-12zM14 9a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm20 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4z"
          opacity=".6"
        />
      </g>
    </SvgIcon>
  );
};
