import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const NormBloodPressureIcon: React.FC<SvgIconProps> = ({ sx, htmlColor = '#4C4A4A', ...rest }) => {
  return (
    <SvgIcon sx={{ width: '20px', height: '20px', color: htmlColor, ...sx }} {...rest}>
      <path
        d="M7 2a5 5 0 0 1 1.001 9.9L8 18a2 2 0 1 0 4 0v-5a4 4 0 0 1 7.907-.86A3.001 3.001 0 0 1 22 15v4a3 3 0 0 1-6 0v-4c0-1.247.762-2.317 1.845-2.77A2 2 0 0 0 14 13v5a4 4 0 1 1-8 0v-6.1A5 5 0 0 1 7 2zm12 12a1 1 0 0 0-1 1v4a1 1 0 0 0 2 0v-4a1 1 0 0 0-1-1zM7 4a3 3 0 1 0 0 6 3 3 0 0 0 0-6zm-.172 1.758a1 1 0 0 1 1.414 1.414l-.414.414a1 1 0 0 1-1.414-1.414z"
        fill={htmlColor}
        fillRule="nonzero"
      />
    </SvgIcon>
  );
};

export default NormBloodPressureIcon;
