import React from 'react';
import { BetterSleepIcon, BrainIcon, CholesterolLvlIcon, LongLifeIcon, NormBloodPressureIcon } from '../../../Icons';

export const mockHealthBenefits = [
  {
    id: 0,
    icon: <NormBloodPressureIcon htmlColor="rgb(150, 75, 56)" />,
    name: 'appPreviewer.labels.healthBenefitsData.0.name',
    description: 'appPreviewer.labels.healthBenefitsData.0.description',
  },
  {
    id: 1,
    icon: <BetterSleepIcon htmlColor="rgb(150, 75, 56)" />,
    name: 'appPreviewer.labels.healthBenefitsData.1.name',
    description: 'appPreviewer.labels.healthBenefitsData.1.description',
  },
  {
    id: 2,
    icon: <CholesterolLvlIcon htmlColor="rgb(150, 75, 56)" />,
    name: 'appPreviewer.labels.healthBenefitsData.2.name',
    description: 'appPreviewer.labels.healthBenefitsData.2.description',
  },
  {
    id: 3,
    icon: <BrainIcon htmlColor="rgb(150, 75, 56)" />,
    name: 'appPreviewer.labels.healthBenefitsData.3.name',
    description: 'appPreviewer.labels.healthBenefitsData.3.description',
  },
  {
    id: 4,
    icon: <LongLifeIcon htmlColor="rgb(150, 75, 56)" />,
    name: 'appPreviewer.labels.healthBenefitsData.4.name',
    description: 'appPreviewer.labels.healthBenefitsData.4.description',
  },
];
