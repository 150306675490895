import React, { ReactNode } from 'react';
import { SxProps } from '@mui/system';
import { motion } from 'framer-motion';
import { TFunction } from 'i18next';
import { Box, Stack } from '@egym/ui';
import { AppPreviewerPalette, AppPreviewerTab } from '../../../../AppPreviewerProps';
import TabIconPreview from '../../../TabIconPreview';

const transition = {
  type: 'tween',
  duration: 0.4,
};

type Props = {
  tFunction: TFunction;
  wrapperSx?: SxProps;
  tabs: AppPreviewerTab[];
  palette: AppPreviewerPalette;
  highlightPreviewLayoutFooterButtons?: boolean;
  changeSelectedTab?: (tabId: number) => void;
};

const PreviewerFooter: React.FC<Props> = ({
  tFunction,
  wrapperSx,
  tabs,
  highlightPreviewLayoutFooterButtons = false,
  changeSelectedTab,
  palette,
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-around',
        width: '100%',
        boxSizing: 'border-box',
        height: '80px',
        bottom: 0,
        left: 0,
        borderTop: '1px solid',
        borderColor: '#d5d5d5',
        boxShadow: '0 -0.5px 10px rgba(0, 0, 0, 0.3)',
        background: 'white',
        zIndex: 3,
        paddingLeft: '3px',
        paddingRight: '3px',
        ...wrapperSx,
      }}
    >
      {tabs.map(tab => (
        <motion.div
          key={tab.id}
          layout
          transition={transition}
          style={{
            maxWidth: '70px',
            flexGrow: 1,
            flexBasis: 0,
            height: 'fit-content',
            marginLeft: '1px',
            marginRight: '1px',
            marginTop: '7px',
          }}
        >
          <Box
            component="button"
            key={tab.id}
            sx={{
              width: '100%',
              height: '100%',
              cursor: changeSelectedTab ? 'pointer' : undefined,
              px: '2px',
              pb: '7px',
              pt: '3px',
              color: tab.selected ? palette.primary : 'rgb(142, 139, 139)',
              bgcolor: tab.selected || !highlightPreviewLayoutFooterButtons ? 'transparent' : 'rgba(65, 199, 215, 0.2)',
              border: '1px solid',
              borderRadius: '2px',
              borderColor:
                tab.selected || !highlightPreviewLayoutFooterButtons ? 'transparent' : 'rgba(26, 127, 142, 0.8)',
              fontFamily:
                'Inv Maison Neue, Maison Neue,"-apple-system", BlinkMacSystemFont, Open Sans, "open-sans","sans-serif"',
              ':hover': {
                bgcolor:
                  tab.selected || !highlightPreviewLayoutFooterButtons ? 'transparent' : 'rgba(65, 199, 215, 0.15)',
              },
            }}
            onClick={() => {
              if (changeSelectedTab) {
                changeSelectedTab(tab.id);
              }
            }}
          >
            <Stack alignItems="center" justifyContent="center">
              <TabIconPreview
                wrapperSx={{ backgroundColor: tab.selected ? palette.primary : 'rgb(142, 139, 139)' }}
                iconLink={tab.icon}
              />
              <span
                style={{
                  marginTop: '3px',
                  fontSize: '10px',
                  fontWeight: 500,
                  maxWidth: tabs.length === 5 ? '100%' : '110%',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {tFunction(tab.nameTranslated || '') as ReactNode}
              </span>
            </Stack>
          </Box>
        </motion.div>
      ))}
    </Box>
  );
};

export default PreviewerFooter;
