import React from 'react';
import { TypographyProps } from '@mui/material';
import { SxProps } from '@mui/system';
import { Box } from '../../layout/Box';
import { Text } from '../Text';
import { panelHeaderStyles } from './Panel.styles';

type Props = {
  sx?: SxProps;
  title?: string;
  titleTypographyProps?: TypographyProps<any, any>;
  tools?: React.ReactNode;
};

const PanelHeader: React.FC<Props> = ({ sx, title, titleTypographyProps, tools }) => {
  return (
    <Box sx={{ ...panelHeaderStyles, ...sx }}>
      {title && <Text text={title} variant="body2" {...titleTypographyProps} />}
      {tools}
    </Box>
  );
};

export default PanelHeader;
