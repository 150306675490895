import React, { ReactNode, useMemo } from 'react';
import { ColorChangeHandler, ColorResult } from 'react-color';
import { useTranslation } from 'react-i18next';
import { alpha, FormControl } from '@mui/material';
import { SxProps } from '@mui/system';
import Color from 'color';
import { SketchPickerProps } from 'react-color/lib/components/sketch/Sketch';
import { Box, Icons, Popover, Stack, Typography, Badge, BadgeProps } from '@egym/ui';
import { TextFieldProps } from '../TextField';
import ColorPickerContent from './ColorPickerContent';
import { PickerType } from './types';

export type ColorPickerProps = {
  error?: string;
  helperText?: ReactNode;
  badgeProps?: BadgeProps | false;
  FormHelperTextProps?: TextFieldProps['FormHelperTextProps'];
  colorPickerFieldSx?: SxProps;
  colorPickerFieldAnchorSx?: SxProps;
  fieldLabel?: string;
  handleChange: ColorChangeHandler;
  value?: string;
  presetColors?: SketchPickerProps['presetColors'];
  disabled?: boolean;
  disableAlpha?: boolean;
  disableGradientTools?: boolean;
  pickers?: PickerType[];
  onPopoverOpen?: () => void;
  onPopoverClose?: () => void;
};

const ColorPicker: React.FC<ColorPickerProps> = ({
  value,
  error,
  fieldLabel,
  badgeProps,
  handleChange,
  presetColors,
  colorPickerFieldSx,
  colorPickerFieldAnchorSx,
  disabled,
  disableAlpha,
  disableGradientTools,
  pickers = [PickerType.OneColor],
  onPopoverOpen,
  onPopoverClose,
}) => {
  const { t } = useTranslation();

  const rgbValue = useMemo<ColorResult['rgb'] | string | undefined>(() => {
    if (!value) return undefined;

    if (value.includes('gradient')) {
      return value;
    }

    const rgbaObject = Color(value).object();

    return {
      ...rgbaObject,
      a: rgbaObject.alpha || 1,
    } as ColorResult['rgb'];
  }, [value]);

  const anchorBackgroundColor = useMemo(() => {
    if (value?.includes && value?.includes('gradient')) {
      return alpha(Color(value.match(/rgba\(.*?\)/g)?.[0]).string(), 0.03);
    }

    return value ? alpha(value, 0.03) : 'transparent';
  }, [value]);
  return (
    <FormControl error={Boolean(error)} sx={{ ...colorPickerFieldSx }}>
      <Popover
        onAnchorClick={onPopoverOpen}
        onClose={onPopoverClose}
        disabled={disabled}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          sx={{
            borderRadius: 1,
            py: 2,
            px: 2,
            cursor: disabled ? 'default' : 'pointer',
            minWidth: '200px',
            transition: 'background-color 0.2s linear',
            fontSize: '16px', // font-size for error icon only
            ':hover': !disabled
              ? {
                  background: anchorBackgroundColor,
                }
              : {},
            ...colorPickerFieldAnchorSx,
          }}
        >
          <Badge
            badgeContent={0}
            {...(typeof badgeProps === 'object' ? badgeProps : null)}
            sx={{ mr: 3, ...(typeof badgeProps === 'object' ? badgeProps.sx : null) }}
          >
            <Box
              className={!value ? 'crossed' : undefined}
              sx={{
                background: value || undefined,
                width: '32px',
                height: '32px',
                boxSizing: 'border-box',
                boxShadow: '0 0 2px rgba(223, 223, 223)',
                border: '2px solid',
                borderColor: error ? 'error.main' : '#cbcbcb',
                borderRadius: 0.5,
              }}
            />
          </Badge>
          {fieldLabel && (
            <Typography
              variant="body2Bold"
              color={error ? 'error.main' : 'text.primary'}
              sx={{ textTransform: 'uppercase', letterSpacing: '0.5px' }}
            >
              {t(fieldLabel) as ReactNode}
            </Typography>
          )}
          {error && <Icons.ErrorOutline sx={{ ml: 2 }} fontSize="inherit" color="error" />}
        </Stack>
        <ColorPickerContent
          pickers={pickers}
          value={rgbValue}
          handleChange={handleChange}
          presetColors={presetColors}
          disableAlpha={disableAlpha}
          disableGradientTools={disableGradientTools}
          error={error}
        />
      </Popover>
    </FormControl>
  );
};

export default ColorPicker;
