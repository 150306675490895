import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const MainTabHeaderDefaultIcon15: React.FC<SvgIconProps> = ({ htmlColor = '#FFF', sx, ...props }) => {
  return (
    <SvgIcon viewBox="0 0 48 48" {...props} sx={{ width: '48px', height: '48px', ...sx }}>
      <g fill={htmlColor} fillRule="evenodd">
        <path d="M40.97 22.586a1 1 0 0 0-1.414 0l-7.07 7.07-14.143-14.141 7.071-7.071a1 1 0 0 0 0-1.415L24 5.615a1 1 0 0 0-1.414 0l-16.97 16.97a1 1 0 0 0 0 1.415l1.413 1.414a1 1 0 0 0 1.415 0l7.07-7.07 14.143 14.141-7.071 7.071a1 1 0 0 0 0 1.415L24 42.385a1 1 0 0 0 1.414 0l16.97-16.97a1 1 0 0 0 0-1.415l-1.413-1.414zm-2.828 12.728-2.828 2.828.707.707a1 1 0 0 0 1.414 0l1.414-1.414a1 1 0 0 0 0-1.414l-.707-.707zM11.98 9.15a1 1 0 0 0-1.414 0l-1.414 1.414a1 1 0 0 0 0 1.414l.707.707 2.828-2.828-.707-.707z" />
        <g opacity=".6" transform="scale(-1 1) rotate(-45 15.107 67.198)">
          <rect width="5" height="18" rx="1" />
          <rect transform="rotate(-180 33.5 9)" x="31" width="5" height="18" rx="1" />
        </g>
      </g>
    </SvgIcon>
  );
};
