import { FC, ReactNode, RefObject } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import { FormikProps } from 'formik';
import { Typography } from '@egym/ui';
import { AppPreviewerPalette, AppPreviewerTab, PreviewerState } from '@components';
import { getWidgetNameTKey } from '@helpers';
import { IllustrationAddFullTabFeatureAndMoveRemaining } from '@svg';
import { MoveWidgetsFormValues, WidgetWithTab } from '@types';
import { MoveWidgetsForm } from '../../MoveWidgetsForm';
import ActionRequiredIcon from '../ActionRequiredIcon';

type Props = {
  widget: WidgetWithTab;
  appLayoutTabs: AppPreviewerTab[];
  previewerPalette: AppPreviewerPalette;
  previewerState: Partial<PreviewerState>;
  moveRemainingFeaturesFormRef: RefObject<FormikProps<MoveWidgetsFormValues> | null>;
};

const AddFullTabAndMoveRemainingFeatures: FC<Props> = ({
  widget,
  appLayoutTabs,
  previewerPalette,
  previewerState,
  moveRemainingFeaturesFormRef,
}) => {
  const { t } = useTranslation();

  return (
    <Stack
      sx={{
        position: 'relative',
        maxWidth: '100%',
      }}
    >
      <ActionRequiredIcon />
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{ mt: 2, bgcolor: 'rgba(150, 112, 0, 0.05)', height: '225px', width: '100%', borderRadius: 1 }}
      >
        <IllustrationAddFullTabFeatureAndMoveRemaining />
      </Stack>
      <Typography variant="body1" sx={{ my: 5 }}>
        {
          t('appDesign.labels.addFullTabMoveRemainingFeatures', {
            widgetName: t(getWidgetNameTKey(widget.name)),
          }) as ReactNode
        }
      </Typography>
      <MoveWidgetsForm
        moveRemainingFeaturesFormRef={moveRemainingFeaturesFormRef}
        widget={widget}
        appLayoutTabs={appLayoutTabs}
        previewerPalette={previewerPalette}
        previewerState={previewerState}
      />
    </Stack>
  );
};

export default AddFullTabAndMoveRemainingFeatures;
