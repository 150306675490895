// https://next.material-ui.com/components/snackbars/#customized-snackbars
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Snackbar as MuiSnackbar } from '@mui/material';
import { useSnackbar } from '../../../hooks';
import { Alert } from '../Alert';

export const Snackbar = () => {
  const { t } = useTranslation();
  const { isOpen, close, message, messageOptions } = useSnackbar();

  return (
    <MuiSnackbar
      open={isOpen}
      autoHideDuration={message?.options?.autoHideDuration}
      anchorOrigin={message?.options?.anchorOrigin}
      onClose={close}
    >
      <Alert onClose={close} {...messageOptions}>
        {Array.isArray(message?.text)
          ? message?.text.map(it => <p key={it}>{t(it, message?.options?.textParams) as ReactNode}</p>)
          : message?.text && (t(message.text, message?.options?.textParams) as ReactNode)}
      </Alert>
    </MuiSnackbar>
  );
};

export default Snackbar;
