import { FC } from 'react';
import useWidgetPreview from './hooks/useWidgetPreview';
import WidgetPreview from './WidgetPreview';
import { WidgetPreviewContainerProps } from './WidgetPreviewProps';

const WidgetPreviewContainer: FC<WidgetPreviewContainerProps> = props => {
  const result = useWidgetPreview(props);

  return <WidgetPreview {...props} {...result} />;
};

export default WidgetPreviewContainer;
