import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { SxProps } from '@mui/system';
import { SystemStyleObject } from '@mui/system/styleFunctionSx/styleFunctionSx';
import { Button, Icons, Popover, Stack, Typography } from '@egym/ui';
import { PredefinedImage } from '@types';
import PredefinedImagesLibraryPopoverContent from './components/PredefinedImagesLibraryPopoverContent';

type Props = {
  onSelectImage: (image: PredefinedImage) => void;
  images: PredefinedImage[];
  predefinedImageId?: number | null;
  hintText?: string;
  buttonText?: string;
  wrapperSx?: SystemStyleObject;
  gridSx?: SxProps;
};

const PredefinedImagesLibraryPopover: React.FC<Props> = ({
  onSelectImage,
  predefinedImageId,
  images,
  hintText = 'appDesign.labels.doNotHaveAnImage',
  buttonText = 'common.buttons.addFromLibrary',
  wrapperSx,
  gridSx,
}) => {
  const { t } = useTranslation();

  return (
    <Stack spacing={3} sx={wrapperSx}>
      <Typography variant="body1" sx={{ color: 'grey.700' }}>
        {t(hintText) as ReactNode}
      </Typography>
      <Popover
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Button
          startIcon={<Icons.Add />}
          text={buttonText}
          color="inherit"
          variant="text"
          sx={{ p: 0, textTransform: 'uppercase' }}
        />
        <PredefinedImagesLibraryPopoverContent
          images={images}
          predefinedImageId={predefinedImageId}
          onSelectImage={onSelectImage}
          gridSx={gridSx}
        />
      </Popover>
    </Stack>
  );
};

export default PredefinedImagesLibraryPopover;
