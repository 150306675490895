import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const CholesterolLvlIcon: React.FC<SvgIconProps> = ({ sx, htmlColor = '#4C4A4A', ...rest }) => {
  return (
    <SvgIcon sx={{ width: '20px', height: '20px', color: htmlColor, ...sx }} {...rest}>
      <path
        d="M11.35 2.241a.995.995 0 0 1 1.3 0c.301.257 7.35 6.362 7.35 11.76 0 4.41-3.589 8-8 8s-8-3.59-8-8c0-5.397 7.049-11.502 7.35-11.76zM12 4.346c-.641.6-1.438 1.415-2.241 2.345.151.234.241.51.241.81C10 8.328 9.327 9 8.5 9c-.164 0-.319-.034-.467-.082C6.883 10.598 6 12.41 6 14c0 3.309 2.691 6 6 6 1.148 0 2.219-.33 3.132-.893A1.488 1.488 0 0 1 15 18.5c0-.826.673-1.5 1.5-1.5.217 0 .422.05.608.133A5.956 5.956 0 0 0 18 14c0-3.337-3.87-7.657-6-9.654zm2.121 7.18a.999.999 0 1 1 1.414 1.414L12 16.475a.993.993 0 0 1-.707.293.993.993 0 0 1-.707-.293l-2.12-2.121a.999.999 0 1 1 1.413-1.414l1.414 1.414z"
        fill={htmlColor}
        fillRule="evenodd"
      />
    </SvgIcon>
  );
};

export default CholesterolLvlIcon;
