import React, { forwardRef, useMemo } from 'react';
import { ConditionalRender, Stack } from '@egym/ui';
import { AppDesignLayoutTabWidget, Orientation } from '@types';
import { AppLayoutFeatureComponentProps } from '../../../../AppPreviewerProps';
import PreviewContainer from '../../../PreviewContainer';
import PreviewFeatureTitle from '../../../PreviewFeatureTitle';
import { fillGroupWithWidgets } from '../../componentHelpers';
import PreviewGroupWidgetSkeleton from '../PreviewGroupWidgetSkeleton';
import GroupWidgetSkeletonItem from '../PreviewGroupWidgetSkeleton/components/GroupWidgetSkeletonItem';
import { PreviewWebWidgetContent } from '../PreviewWebWidget';

const PreviewGroupWebWidget = forwardRef<HTMLDivElement, AppLayoutFeatureComponentProps>((props, ref) => {
  const {
    activeWidgetId,
    activeWidgetGroupId,
    tFunction,
    widgetProps,
    wrapperSx,
    previewerState,
    previewerState: { selectedLocale },
    palette,
  } = props;

  const title = useMemo(() => {
    return widgetProps?.preferences?.title?.[selectedLocale as string] || widgetProps?.preferences?.title?.en_US;
  }, [selectedLocale, widgetProps?.preferences?.title]);

  const innerWidgets = fillGroupWithWidgets(widgetProps?.preferences?.innerWidgets);

  return (
    <ConditionalRender condition={Boolean(widgetProps?.preferences?.innerWidgets?.length)}>
      <PreviewContainer ref={ref} wrapperSx={{ px: 0, ...(activeWidgetGroupId === widgetProps?.id ? {} : wrapperSx) }}>
        <PreviewFeatureTitle tFunction={tFunction} title={title} palette={palette} wrapperSx={{ px: '15px' }} />
        <Stack
          className="hide-scrollbar"
          spacing={2}
          direction={widgetProps?.preferences?.orientation === Orientation.Vertical ? 'column' : 'row'}
          sx={{
            overflowX: 'scroll',
            px: '15px',
          }}
        >
          {innerWidgets.map((innerWidget: AppDesignLayoutTabWidget | null, index) => {
            if (innerWidget) {
              return (
                <PreviewWebWidgetContent
                  key={innerWidget.id}
                  widgetProps={innerWidget}
                  orientation={widgetProps?.preferences?.orientation}
                  palette={palette}
                  tFunction={tFunction}
                  titleKey="title"
                  titleFallback="appPreviewer.labels.webWidget"
                  previewerState={previewerState}
                  styles={
                    activeWidgetGroupId &&
                    activeWidgetGroupId === widgetProps?.id &&
                    activeWidgetId &&
                    activeWidgetId !== innerWidget.id
                      ? {
                          filter: 'blur(4px)',
                        }
                      : undefined
                  }
                />
              );
            }

            return (
              <GroupWidgetSkeletonItem
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                width={widgetProps?.preferences?.orientation === Orientation.Horizontal ? 50 : undefined}
                orientation={widgetProps?.preferences?.orientation}
                order={index}
              />
            );
          })}
        </Stack>
      </PreviewContainer>
      <PreviewGroupWidgetSkeleton ref={ref} {...props} />
    </ConditionalRender>
  );
});

export default PreviewGroupWebWidget;
