import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const MorningIcon: React.FC<SvgIconProps> = ({ sx, htmlColor = '#4C4A4A', ...rest }) => {
  return (
    <SvgIcon viewBox="0 0 24 24" sx={{ width: '20px', height: '20px', ...sx }} {...rest}>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <path d="M0 0H24V24H0z" transform="translate(-164 -2235) translate(164 2235)" />
            <path
              fill={htmlColor}
              d="M14.088 20c.552 0 1 .448 1 1s-.448 1-1 1h-4c-.552 0-1-.448-1-1s.448-1 1-1zm-2.09-12c3.312 0 6 2.688 6 6 0 .694-.117 1.37-.341 2.001l3.43-.001c.553 0 1 .448 1 1s-.447 1-1 1h-18c-.551 0-1-.448-1-1s.449-1 1-1l3.252.001c-.224-.632-.341-1.307-.341-2.001 0-3.312 2.688-6 6-6zm0 2c-2.208 0-4 1.792-4 4 0 .723.188 1.407.538 2h6.924c.35-.593.538-1.277.538-2 0-2.208-1.792-4-4-4zM3 12c.552 0 1 .448 1 1s-.448 1-1 1H1c-.552 0-1-.448-1-1s.448-1 1-1zm20 0c.552 0 1 .448 1 1s-.448 1-1 1h-2c-.552 0-1-.448-1-1s.448-1 1-1zM3.54 5.577c.391-.39 1.025-.39 1.415 0L6.37 6.991c.39.39.39 1.024 0 1.414-.39.39-1.024.39-1.414 0L3.54 6.991c-.39-.39-.39-1.023 0-1.414zm15.557-.124c.39-.39 1.023-.39 1.414 0 .39.39.39 1.024 0 1.414l-1.414 1.414c-.39.39-1.024.39-1.414 0-.39-.39-.39-1.023 0-1.414zM12 2c.552 0 1 .448 1 1v2c0 .552-.448 1-1 1s-1-.448-1-1V3c0-.552.448-1 1-1z"
              transform="translate(-164 -2235) translate(164 2235)"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export default MorningIcon;
