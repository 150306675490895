import React from 'react';
import { CustomPickerProps } from 'react-color';
import { Stack } from '@mui/material';
import { SxProps } from '@mui/system';
import { Hue as ReactColorHue } from 'react-color/lib/components/common';
import Pointer from '../Pointer';
import PointerSmall from '../PointerSmall';

type Props = { wrapperSx?: SxProps; size: 'small' | 'default' } & CustomPickerProps<ReactColorHue>;

const Hue: React.FC<Props> = ({ wrapperSx, size = 'default', ...rest }) => {
  return (
    <Stack sx={{ position: 'relative', height: size === 'small' ? '12px' : '24px', width: '100%', ...wrapperSx }}>
      <ReactColorHue
        // @ts-ignore
        pointer={size === 'small' ? PointerSmall : Pointer}
        {...rest}
      />
    </Stack>
  );
};

export default Hue;
