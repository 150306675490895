import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { alpha } from '@mui/material';
import { FormikConfig } from 'formik';
import { map, mergeAll } from 'ramda';
import { v4 as uuidv4 } from 'uuid';
import { getFileFromUrl } from '@egym/api';
import { FileUploadCropper, useContentDialog } from '@egym/ui';
import { blobToDataURL, customYup, toLocaleApiFormat } from '@egym/utils';
import { predefinedVerticalWidgetImages, predefinedHorizontalWidgetImages, predefinedWidgetIcons } from '@constants';
import { getGStoreResource, isGStoreResource } from '@helpers';
import { Orientation, PredefinedImage, PredefinedTabIcon, WidgetViewStyle } from '@types';
import { getImageOverlayGradientPreview } from 'src/pages/AppDesign/components/AppDesignForm/components/WidgetsLibrary/hooks/useWidgetsLibrary/helpers';
import { getPresetColors } from 'src/pages/AppDesign/helpers';
import useWidgetPreferencesForm from '../../../../hooks/useWidgetPreferencesForm';
import { convertToStyledWidgetState, getOriginalAsset } from '../../../../hooks/useWidgetPreferencesForm/helpers';
import {
  UseWebWidgetPreferencesFormResult,
  WebWidgetPreferencesFormContainerProps,
  WebWidgetPreferencesFormikProps,
  WebWidgetPreferencesFormValues,
} from '../../WebWidgetPreferencesFormProps';

const useWebWidgetPreferencesForm = (
  props: WebWidgetPreferencesFormContainerProps,
  ref,
): UseWebWidgetPreferencesFormResult => {
  const { t } = useTranslation();
  const { currentWidget, previewerPalette, updateWidget, orientation } = props;
  const { innerRef, appLocales, availableLanguages, openLanguagesMenuTab, onSubmit, currentLocale } =
    useWidgetPreferencesForm(props, ref);
  const { openContentDialog, closeContentDialog } = useContentDialog();
  const formConfig = useMemo<FormikConfig<WebWidgetPreferencesFormValues>>(() => {
    const initialStyle = currentWidget.preferences?.style || WidgetViewStyle.Image;
    const isUploadedAsset = isGStoreResource(currentWidget.preferences?.asset?.link);

    const predefinedImage =
      orientation === Orientation.Horizontal ? predefinedHorizontalWidgetImages[0] : predefinedVerticalWidgetImages[0];

    return {
      enableReinitialize: false,
      initialValues: {
        id: currentWidget.id,
        style: initialStyle,
        url: currentWidget.preferences?.url,
        title: mergeAll(
          map(
            (locale: string) => ({
              [locale]: currentWidget.preferences?.title?.[locale] ?? '',
            }),
            map(locale => toLocaleApiFormat(locale), appLocales || []),
          ),
        ),
        callToAction: !orientation
          ? mergeAll(
              map(
                (locale: string) => ({
                  [locale]: currentWidget.preferences?.callToAction?.[locale] ?? '',
                }),
                map(locale => toLocaleApiFormat(locale), appLocales || []),
              ),
            )
          : undefined,
        icon:
          initialStyle === WidgetViewStyle.Icon && isUploadedAsset
            ? getGStoreResource(currentWidget.preferences?.asset?.link)
            : predefinedWidgetIcons[0].path,
        iconPredefinedId:
          initialStyle === WidgetViewStyle.Icon && isUploadedAsset
            ? currentWidget.preferences?.asset?.id
            : predefinedWidgetIcons[0].id,
        image:
          initialStyle === WidgetViewStyle.Image && isUploadedAsset
            ? getGStoreResource(currentWidget.preferences?.asset?.link)
            : predefinedImage.path,
        imagePredefinedId:
          initialStyle === WidgetViewStyle.Image && isUploadedAsset
            ? currentWidget.preferences?.asset?.id
            : predefinedImage.id,
        backgroundColor:
          initialStyle === WidgetViewStyle.Icon
            ? currentWidget.preferences?.assetColor ?? previewerPalette.primary
            : previewerPalette.primary,
        imageOverlay:
          initialStyle === WidgetViewStyle.Image
            ? currentWidget.preferences?.assetColor ??
              getImageOverlayGradientPreview(
                alpha(previewerPalette.primary, 0.5),
                alpha(previewerPalette.secondary ?? previewerPalette.primary, 0.5),
              )
            : getImageOverlayGradientPreview(
                alpha(previewerPalette.primary, 0.5),
                alpha(previewerPalette.secondary ?? previewerPalette.primary, 0.5),
              ),
      },
      validationSchema: customYup.object().shape({
        // @ts-ignore
        url: customYup.string().required().nullable().url2(t('common.validation.url')),
        title: customYup
          .object()
          .required()
          .nullable()
          .shape(
            mergeAll(
              map(
                (locale: string) => ({
                  [locale]: customYup.string().nullable().required(t('common.validation.required')),
                }),
                map(locale => toLocaleApiFormat(locale), appLocales || []),
              ),
            ),
          ),
      }),
      innerRef,
      onSubmit,
      validateOnChange: true,
      validateOnBlur: true,
      validateOnMount: true,
    };
  }, [
    t,
    currentWidget.preferences?.style,
    currentWidget.preferences?.asset?.link,
    currentWidget.preferences?.asset?.id,
    currentWidget.preferences?.url,
    currentWidget.preferences?.assetColor,
    currentWidget.preferences?.title,
    currentWidget.preferences?.callToAction,
    currentWidget.id,
    appLocales,
    orientation,
    previewerPalette.primary,
    previewerPalette.secondary,
    innerRef,
    onSubmit,
  ]);

  const presetColors = useMemo(
    () => getPresetColors(previewerPalette, 0.5, { color: 'rgba(0, 0, 0, 1)', title: 'black' }),
    [previewerPalette],
  );

  const onSelectPredefinedIcon = useCallback(
    async (
      predefinedIcon: PredefinedTabIcon,
      { setFieldValue, values: { iconPredefinedId } }: WebWidgetPreferencesFormikProps,
    ) => {
      if (iconPredefinedId === predefinedIcon.id) return;
      const iconFile = await getFileFromUrl(predefinedIcon.path);
      setFieldValue('icon', [iconFile]);
      setFieldValue('iconPredefinedId', predefinedIcon.id);
    },
    [],
  );

  const onSelectPredefinedImage = useCallback(
    async (
      predefinedImage: PredefinedImage,
      { setFieldValue, values: { imagePredefinedId } }: WebWidgetPreferencesFormikProps,
    ) => {
      if (imagePredefinedId === predefinedImage.id) return;
      const imageFile = await getFileFromUrl(predefinedImage.path);
      setFieldValue('image', [imageFile]);
      setFieldValue('imagePredefinedId', predefinedImage.id);
    },
    [],
  );

  const onFormChanged = useCallback(
    ({ values }: WebWidgetPreferencesFormikProps) => {
      if (values.style) {
        const converted = convertToStyledWidgetState(values.style, values);
        updateWidget({
          groupId: currentWidget.groupId,
          ...converted,
        });
      }
    },
    [currentWidget.groupId, updateWidget],
  );

  const onCustomImagePick = (files: File[], { setFieldValue }) => {
    setFieldValue('imagePredefinedId', undefined);
    setFieldValue('image', files);
  };

  const onCustomIconPick = ({ setFieldValue }) => {
    setFieldValue('iconPredefinedId', undefined);
  };

  const onCropConfirm = useCallback(
    (croppedImage: Blob, originalFile: File, formikProps: WebWidgetPreferencesFormikProps) => {
      const cropFileName =
        originalFile.name && originalFile.name.includes('.')
          ? `${uuidv4()}.${originalFile.name.split('.').pop()}`
          : uuidv4();
      onCustomImagePick(
        [new File([croppedImage], cropFileName, { type: croppedImage.type }), originalFile],
        formikProps,
      );
      closeContentDialog();
    },
    [closeContentDialog],
  );

  const imageDimensions = useMemo(() => {
    const width = orientation === Orientation.Horizontal ? 490 : 1005;
    const height = orientation === Orientation.Horizontal ? 540 : 300;

    return {
      width,
      height,
      ratio: width / height,
    };
  }, [orientation]);

  const onCropOriginalFile = useCallback(
    async (formikProps: WebWidgetPreferencesFormikProps) => {
      const originalAsset = await getOriginalAsset(formikProps.values);
      if (originalAsset) {
        const originalAssetUrl = await blobToDataURL(originalAsset);
        openContentDialog({
          title: 'common.cropper.cropImage',
          component: FileUploadCropper,
          showCancelButton: false,
          contentProps: {
            sx: {
              pt: 0,
            },
          },
          componentProps: {
            droppedFile: {
              ...originalAsset,
              preview: originalAssetUrl,
              path: originalAssetUrl,
            },
            aspect: imageDimensions.ratio,
            onCropConfirm: croppedImage => onCropConfirm(croppedImage, originalAsset, formikProps),
          },
        });
      }
    },
    [openContentDialog, imageDimensions.ratio, onCropConfirm],
  );

  return {
    formConfig,
    availableLanguages,
    openLanguagesMenuTab,
    presetColors,
    onSelectPredefinedIcon,
    onSelectPredefinedImage,
    currentLocale,
    onFormChanged,
    onCustomIconPick,
    onCustomImagePick,
    onCropOriginalFile,
    imageDimensions,
  };
};

export default useWebWidgetPreferencesForm;
