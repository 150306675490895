import React, { ReactElement } from 'react';
import { BoxProps, SxProps } from '@mui/system';
import { Stack, UploadedImage } from '@egym/ui';
import { AcceptedFile } from '../../FileUploadProps';
import { previewImageStyles, previewStyles } from './PreviewResult.styles';

type Props = {
  name: string;
  previewResultWrapperSx?: SxProps;
  previewWrapperSx?: SxProps;
  previewImageSx?: SxProps;
  files: AcceptedFile[];
  uploadImageTool?: ReactElement | null;
  previewTool?: ReactElement | null;
  previewImageProps?: (filePreviewResult: string) => BoxProps;
};

const PreviewResult: React.FC<Props> = ({
  name,
  previewImageSx,
  previewWrapperSx,
  files,
  previewTool,
  uploadImageTool,
  previewImageProps,
}) => {
  const uploadedImageWrapperSx = {
    ...previewStyles,
    ...previewWrapperSx,
    // @ts-ignore
    background: previewWrapperSx?.background || '#f5f5f5',
  };

  return (
    <Stack sx={{ position: 'relative' }}>
      <UploadedImage
        id={`file-upload-preview-result-${name}`}
        // @ts-ignore
        wrapperSx={uploadedImageWrapperSx}
        imageSx={{ ...previewImageStyles, ...previewImageSx }}
        imageProps={previewImageProps}
        file={files[0]}
        uploadImageTool={uploadImageTool}
      />
      {previewTool}
    </Stack>
  );
};

export default PreviewResult;
