import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { equals, map, prop } from 'ramda';
import { useConfirmDialog, useFormDialog, useSnackbar } from '@egym/ui';
import { customYup } from '@egym/utils';
import {
  AppPreviewerPalette,
  AppPreviewerTab,
  DeleteTabWithFeaturesDialogText,
  getIsFeatureFullTab,
  PreviewerState,
  TabIconPreview,
} from '@components';
import {
  extendPreviewerTabsWithNewFeatures,
  getIsFeatureUnremovable,
  getRemainingFullTabToCompactWidgets,
} from '@helpers';
import { RemainingWidget } from '@types';
import { getRemainingWidgetsToEdit } from '../../../pages/AppDesign/components/AppDesignForm/components/TabCustomizationFormSection/components/TabCustomizationFeatures/components/TabCustomizationFeaturesForm/hooks/useTabCustomizationFeaturesForm/helpers';
import { DeleteTabAndMoveCoreFeatures } from '../../components/ActionRequired';
import useAppDesignCustomTabs from '../useAppDesignCustomTabs';
import useAppDesignTabsWidgetsByRouter from '../useAppDesignTabsWidgetsByRouter';

type DeleteTabArgs = {
  tab: AppPreviewerTab;
  appLayoutTabs: AppPreviewerTab[];
  onSuccess?: () => void;
  previewerPalette?: AppPreviewerPalette;
  previewerState?: Partial<PreviewerState>;
};

const useDeleteTab = ({ appUuid }) => {
  const { openConfirmDialog } = useConfirmDialog();
  const { openFormDialog, closeFormDialog } = useFormDialog();
  const { openSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const { appDesignLayoutTabsQuery, editTabs, deleteAppDesignLayoutTabByIdMutation } = useAppDesignCustomTabs({
    appUuid,
    enabledQuery: false,
  });
  const { createOrEditAllWidgetsMutation } = useAppDesignTabsWidgetsByRouter({
    enabledAllWidgetsQuery: false,
  });

  const onConfirmDelete = useCallback(
    async (tab: AppPreviewerTab, appLayoutTabs: AppPreviewerTab[], onSuccess?: () => void) => {
      if (
        // order changed ?
        appDesignLayoutTabsQuery.isSuccess &&
        !equals(
          map(it => it.id, appLayoutTabs),
          map(it => it.id, appDesignLayoutTabsQuery.data),
        )
      ) {
        await editTabs(appLayoutTabs);
      }

      await deleteAppDesignLayoutTabByIdMutation.mutateAsync({ tabId: tab.id });

      const textParams = { tabName: tab.nameTranslated || tab.name.en_US };
      openSnackbar('appDesign.labels.tabDeleteSuccess', {
        textParams,
      });

      if (onSuccess) onSuccess();
    },
    [
      appDesignLayoutTabsQuery.data,
      appDesignLayoutTabsQuery.isSuccess,
      deleteAppDesignLayoutTabByIdMutation,
      editTabs,
      openSnackbar,
    ],
  );

  const openConfirmationDialog = useCallback(
    async ({ tab, appLayoutTabs, onSuccess }: DeleteTabArgs) => {
      const textParams = { tabName: tab.nameTranslated || tab.name.en_US };

      openConfirmDialog({
        title: 'common.buttons.deleteTab',
        titleParams: textParams,
        text: tab.features.length ? DeleteTabWithFeaturesDialogText : 'appDesign.labels.deleteTabDialog.description',
        textParams,
        onConfirm: async () => onConfirmDelete(tab, appLayoutTabs, onSuccess),
        confirmText: 'common.buttons.delete',
        confirmButtonProps: {
          color: 'error',
        },
        cancelButtonProps: {
          color: 'inherit',
        },
      });
    },
    [onConfirmDelete, openConfirmDialog],
  );

  const openMoveCoreFeaturesFormDialog = useCallback(
    async ({ tab, onSuccess, appLayoutTabs, previewerPalette, previewerState }: DeleteTabArgs) => {
      const textParams = { tabName: tab.nameTranslated || tab.name.en_US };

      const tabOptions = appLayoutTabs
        .filter(it => it.id !== tab.id)
        .map(it => {
          return {
            label: it.nameTranslated || it.name.en_US,
            description: it.features.some(tabFeature => getIsFeatureFullTab(tabFeature.props.name))
              ? t('appDesign.labels.changeFullTabToCompactOnDeleteTab')
              : undefined,
            value: it.id,
            icon: TabIconPreview,
            iconProps: {
              wrapperSx: { backgroundColor: 'rgb(142, 139, 139)', width: '16px', height: '16px', mr: 3 },
              iconLink: it.icon,
            },
          };
        });

      openFormDialog({
        title: 'common.buttons.deleteTab',
        titleParams: textParams,
        formProps: {
          enableReinitialize: true,
          initialValues: {
            currentWidgets:
              tab.features
                .filter(feature => getIsFeatureUnremovable(feature.props.name))
                .map(feature => ({
                  ...feature,
                  prevTabId: feature.props.tabId,
                  newTabId: tabOptions.length === 1 ? tabOptions[0].value : undefined,
                  shouldBeRemoved: false,
                  canBeRemoved: false,
                })) || [],
          },
          onSubmit: async values => {
            const newAppLayoutTabs = extendPreviewerTabsWithNewFeatures(
              appLayoutTabs,
              appLayoutTabs,
              values.currentWidgets,
            );

            const remainingWidgetsToEdit = getRemainingWidgetsToEdit(
              values.currentWidgets,
              newAppLayoutTabs,
            ) as RemainingWidget[];

            // change widget names from full tab to compact
            const fullTabToCompactWidgets = getRemainingFullTabToCompactWidgets(
              newAppLayoutTabs,
              remainingWidgetsToEdit,
            );

            const widgetsToEdit = [...remainingWidgetsToEdit.map(prop('props')), ...fullTabToCompactWidgets];

            if (widgetsToEdit?.length) {
              await createOrEditAllWidgetsMutation.mutateAsync(widgetsToEdit);
            }

            await onConfirmDelete(tab, newAppLayoutTabs, onSuccess);

            closeFormDialog();
          },
          validateOnMount: false,
          validationSchema: customYup.object().shape({
            currentWidgets: customYup.array().of(
              customYup.object().shape({
                newTabId: customYup.number().required(t('common.errors.chooseNewTab')),
              }),
            ),
          }),
        },
        formComponent: DeleteTabAndMoveCoreFeatures,
        formComponentProps: {
          ...textParams,
          appLayoutTabs,
          previewerPalette,
          previewerState,
          tabOptions,
        },
        confirmText: 'common.buttons.deleteTab',
        confirmButtonProps: {
          color: 'error',
        },
        cancelButtonProps: {
          color: 'inherit',
        },
        muiDialogProps: {
          PaperProps: {
            sx: {
              maxWidth: '700px',
            },
          },
        },
      });
    },
    [closeFormDialog, createOrEditAllWidgetsMutation, onConfirmDelete, openFormDialog, t],
  );

  return useCallback(
    async ({ tab, appLayoutTabs, onSuccess, previewerPalette, previewerState }: DeleteTabArgs) => {
      const hasCoreFeatures = tab.features.some(feature => getIsFeatureUnremovable(feature.props.name));

      if (hasCoreFeatures) {
        await openMoveCoreFeaturesFormDialog({
          tab,
          appLayoutTabs,
          onSuccess,
          previewerPalette,
          previewerState,
        });
      } else {
        await openConfirmationDialog({ tab, appLayoutTabs, onSuccess });
      }
    },
    [openConfirmationDialog, openMoveCoreFeaturesFormDialog],
  );
};

export default useDeleteTab;
