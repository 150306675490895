import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const ShareFeedbackIcon: React.FC<SvgIconProps> = ({ sx, htmlColor = '#4C4A4A', ...rest }) => {
  return (
    <SvgIcon viewBox="0 0 24 24" sx={{ width: '24px', height: '24px', ...sx }} {...rest}>
      <g fill="none" fillRule="evenodd">
        <g fill={htmlColor}>
          <g>
            <g>
              <g>
                <path
                  d="M19.06 6h-1.013v1.823h1.013c.615 0 1.116.499 1.116 1.113v9.952l-2.498-2.494c-.171-.171-.403-.267-.645-.267h-8.11c-.614 0-1.114-.5-1.114-1.115V14h-.493L6 15.315c.152 1.478 1.405 2.634 2.924 2.634h7.732l3.787 3.784c.174.174.408.267.645.267.117 0 .236-.023.349-.07.34-.14.563-.473.563-.842V8.936C22 7.317 20.682 6 19.06 6"
                  transform="translate(-24 -630) translate(0 610) translate(20 16) translate(4 4)"
                />
                <path
                  d="M5 5c-.551 0-1 .449-1 1v9.586l2.293-2.293C6.481 13.105 6.735 13 7 13h8c.552 0 1-.448 1-1V6c0-.551-.448-1-1-1H5zM3 19c-.129 0-.259-.024-.383-.076C2.244 18.77 2 18.404 2 18V6c0-1.654 1.345-3 3-3h10c1.654 0 3 1.346 3 3v6c0 1.655-1.346 3-3 3H7.414l-3.707 3.707C3.515 18.898 3.261 19 3 19z"
                  transform="translate(-24 -630) translate(0 610) translate(20 16) translate(4 4)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export default ShareFeedbackIcon;
