export enum PickerType {
  OneColor = 'OneColor',
  Gradient = 'Gradient',
}

export enum GradientPickerType {
  Linear = 'linear',
  Radial = 'radial',
}

export type GradientPoint = {
  left: number;
  rgb: {
    r: number;
    g: number;
    b: number;
    a: number;
  };
  hsl?: {
    h: number;
    s: number;
    l: number;
    a: number;
  };
  hsv?: {
    h: number;
    s: number;
    v: number;
    a: number;
  };
  hex?: string;
};
