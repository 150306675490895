import React, { Suspense } from 'react';
import { Box } from '@egym/ui';
import { Footer } from '@components';
import { useAppPages } from '@hooks';
import { minPageHeight } from 'src/styles/constants';

const Layout: React.FC = () => {
  const { appRoutes } = useAppPages();

  return (
    <Box>
      <Suspense fallback={<>...</>}>
        <Box sx={{ minHeight: minPageHeight }}>{appRoutes}</Box>
        <Footer />
      </Suspense>
    </Box>
  );
};

export default Layout;
