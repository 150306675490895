export enum AvailableRowsPerPage {
  XSmall = 5,
  Small = 10,
  Medium = 25,
  Large = 50,
  XLarge = 100,
}

export const availableRowsPerPageOptions = [
  AvailableRowsPerPage.XSmall,
  AvailableRowsPerPage.Small,
  AvailableRowsPerPage.Medium,
  AvailableRowsPerPage.Large,
  AvailableRowsPerPage.XLarge,
];
