import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const MainTabHeaderDefaultIcon20: React.FC<SvgIconProps> = ({ htmlColor = '#FFF', sx, ...props }) => {
  return (
    <SvgIcon viewBox="0 0 48 48" {...props} sx={{ width: '48px', height: '48px', ...sx }}>
      <g fill={htmlColor} fillRule="evenodd">
        <path d="M13.63 8.513c4.085 0 7.929 1.591 10.817 4.48l1.217 1.214c.028.025.065.034.093.06l4.558 4.391c-.573.338-1.113.687-1.61 1.016-1.629 1.088-2.692 1.751-3.997 1.751-1.304 0-2.37-.663-3.996-1.751-.79-.526-1.687-1.094-2.68-1.584l2.908-2.907c-2.05-1.74-4.592-2.733-7.31-2.733h-2.256a1.967 1.967 0 0 1-1.969-1.969c0-1.088.88-1.968 1.969-1.968zm17.122 2.02a3.844 3.844 0 1 1 5.433 5.435 3.844 3.844 0 0 1-5.433-5.435z" />
        <g opacity=".6">
          <path d="M41.284 34.52c-.686-.313-1.386-.9-2.129-1.522-1.428-1.195-3.202-2.682-5.688-2.682-2.486 0-4.26 1.487-5.689 2.682-1.127.945-2.106 1.763-3.158 1.763-1.054 0-2.03-.818-3.16-1.763-1.426-1.198-3.203-2.682-5.686-2.682-2.486 0-4.263 1.487-5.689 2.682-.796.664-1.546 1.294-2.28 1.586a1.969 1.969 0 0 0-1.103 2.556 1.973 1.973 0 0 0 2.556 1.103c1.325-.526 2.402-1.432 3.355-2.227 1.13-.948 2.106-1.764 3.16-1.764 1.052 0 2.028.816 3.159 1.764 1.428 1.195 3.202 2.682 5.688 2.682 2.486 0 4.26-1.487 5.689-2.682 1.127-.948 2.106-1.764 3.158-1.764 1.054 0 2.03.816 3.16 1.764.911.764 1.853 1.552 3.026 2.083a1.972 1.972 0 0 0 2.607-.97c.45-.992.017-2.16-.976-2.61M9.288 29.34c1.31-.525 2.382-1.422 3.327-2.212 1.127-.948 2.103-1.764 3.158-1.764 1.054 0 2.03.816 3.16 1.76 1.426 1.199 3.203 2.683 5.689 2.683 2.48 0 4.257-1.484 5.686-2.68 1.127-.947 2.103-1.763 3.158-1.763 1.054 0 2.033.816 3.163 1.764.906.759 1.845 1.546 3.009 2.078.993.458 2.16.022 2.612-.97a1.967 1.967 0 0 0-.97-2.607c-.686-.315-1.383-.9-2.123-1.519-1.428-1.195-3.206-2.682-5.691-2.682-2.483 0-4.26 1.484-5.686 2.68-1.13.947-2.104 1.763-3.158 1.763-1.055 0-2.033-.816-3.16-1.764-1.43-1.195-3.206-2.68-5.69-2.68-2.485 0-4.26 1.485-5.685 2.683-.79.661-1.538 1.285-2.267 1.578a1.972 1.972 0 0 0-1.093 2.561 1.973 1.973 0 0 0 2.561 1.092" />
        </g>
      </g>
    </SvgIcon>
  );
};
