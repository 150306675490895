import React from 'react';
import { Language } from '@egym/types';
import { ConditionalRender, MenuItem, MenuList, Typography } from '@egym/ui';

type Props = {
  handleClose?: () => void; // passed from Popover
  languages: Language[];
  selectedLanguage?: Language;
  onLanguageSelect: (selectedLocale: string) => void;
};

const GlobalLanguageSelectorPopoverContent: React.FC<Props> = ({
  handleClose,
  languages,
  selectedLanguage,
  onLanguageSelect,
}) => {
  return (
    <MenuList
      sx={{
        maxHeight: '200px',
        width: '100%',
        overflow: 'auto',
        p: 0,
        textAlign: 'center',
      }}
    >
      <ConditionalRender condition={Boolean(languages.length)}>
        <>
          {languages.map(({ name, icon: Icon, locale }) => {
            return (
              <MenuItem
                selected={selectedLanguage?.locale === locale}
                key={name}
                onClick={() => {
                  onLanguageSelect(locale);
                  if (handleClose) handleClose();
                }}
              >
                {Icon && <Icon sx={{ mr: 2 }} />}
                <Typography variant="body2Medium">{name}</Typography>
              </MenuItem>
            );
          })}
        </>
      </ConditionalRender>
    </MenuList>
  );
};

export default GlobalLanguageSelectorPopoverContent;
