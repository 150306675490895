import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { Stack, Box } from '@egym/ui';
import { toLocaleFormat } from '@egym/utils';
import { AppPreviewer, PreviewerRoute } from '@components';
import { AppDesignLayoutTabWidget } from '@types';
import { LngNamespace } from 'src/i18n';
import { WidgetPreviewProps } from './WidgetPreviewProps';

const WidgetPreview: FC<WidgetPreviewProps> = ({
  previewerId,
  widget,
  widget: { props: widgetProps, component: Component, componentProps },
  previewerPalette,
  previewerState,
  isFullTabWidget,
  appLayoutTabs,
  wrapperSx,
  componentWrapperSx,
  scrollAreaSx,
  appPreviewerProps,
}) => {
  const { t } = useTranslation(LngNamespace.Previewer as string);

  const tFunction = useCallback<TFunction>(
    (tkey, options) => t(tkey, { lng: toLocaleFormat(previewerState?.selectedLocale || 'en-US'), ...options }),
    [previewerState?.selectedLocale, t],
  );

  return (
    <Stack
      data-testid={`widget-preview-${widgetProps.name}`}
      sx={{
        position: 'relative',
        width: '365px',
        minHeight: isFullTabWidget ? '250px' : 'auto',
        backgroundImage: 'linear-gradient(to bottom, #fafafa, #f2f2f2)',
        overflow: 'hidden',
        borderRadius: 3,
        fontFamily:
          'Inv Maison Neue, Maison Neue,"-apple-system", BlinkMacSystemFont, Open Sans, "open-sans","sans-serif"',
        alignItems: 'center',
        justifyContent: 'center',
        ...wrapperSx,
      }}
    >
      {isFullTabWidget ? (
        <AppPreviewer
          id={String(widget.props.name)}
          palette={previewerPalette}
          size="small"
          route={PreviewerRoute.Layout}
          appIconLink={previewerState?.appIconLink}
          appIconBackground={previewerState?.appIconBackground}
          mainTabHeaderIcon={previewerState?.mainTabIconLink}
          appLayoutTabs={appLayoutTabs}
          disabled
          selectedLocale={previewerState?.selectedLocale}
          {...appPreviewerProps}
        />
      ) : (
        <Box
          className="hide-scrollbar"
          sx={{
            px: 1.25,
            py: 5,
            width: '100%',
            maxHeight: undefined,
            overflowY: 'scroll',
            scrollBehavior: 'smooth',
            overscrollBehavior: 'contain',
            ...scrollAreaSx,
          }}
        >
          <Component
            palette={previewerPalette}
            previewerState={previewerState}
            widgetProps={widgetProps as Partial<AppDesignLayoutTabWidget>}
            tFunction={tFunction}
            wrapperSx={componentWrapperSx}
            previewerId={previewerId}
            {...componentProps}
          />
        </Box>
      )}
    </Stack>
  );
};

export default WidgetPreview;
