import React, { forwardRef, ReactNode } from 'react';
import { Paper, Stack } from '@egym/ui';
import { AppLayoutFeatureComponentProps } from '../../../../AppPreviewerProps';
import PreviewContainer from '../../../PreviewContainer';
import PreviewFeatureTitle from '../../../PreviewFeatureTitle';

const PreviewBioAge = forwardRef<HTMLDivElement, AppLayoutFeatureComponentProps>(
  ({ tFunction, palette, wrapperSx, previewerState, widgetProps }, ref) => {
    return (
      <PreviewContainer ref={ref} wrapperSx={wrapperSx}>
        <PreviewFeatureTitle
          tFunction={tFunction}
          palette={palette}
          title={
            widgetProps?.preferences?.title?.[previewerState.selectedLocale as string] ??
            widgetProps?.preferences?.title?.en_US ??
            'appPreviewer.labels.analysis'
          }
        />
        <Paper
          sx={{
            textAlign: 'left',
            fontSize: '14px',
            color: 'rgb(33,33,33)',
            borderRadius: '8px',
            boxShadow: '0 4px 6px rgba(223, 223, 223, 0.7)',
          }}
        >
          <Stack direction="row" justifyContent="space-between" sx={{ height: '115px' }}>
            <Stack sx={{ height: '100%', boxSizing: 'border-box', pl: '15px', pb: '15px' }} justifyContent="flex-end">
              <span style={{ fontSize: '12px', lineHeight: '17px', color: 'rgb(76, 74, 74)' }}>
                {tFunction('appPreviewer.labels.yourBioAge') as ReactNode}
              </span>
              <span style={{ fontSize: '25px', fontWeight: 700, letterSpacing: '-0.5px', color: 'rgb(33, 33, 33)' }}>
                {tFunction('appPreviewer.labels.countYears', { count: 36 }) as ReactNode}
              </span>
            </Stack>
            <img
              style={{ width: '139px', height: '115px' }}
              src="/images/previewerDefaultImages/features/maleBioAge2X@2x.png"
              alt="bio age"
            />
          </Stack>
        </Paper>
      </PreviewContainer>
    );
  },
);

export default PreviewBioAge;
