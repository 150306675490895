import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const FiltersIcon: React.FC<SvgIconProps> = ({ sx, htmlColor = '#4C4A4A', ...rest }) => {
  return (
    <SvgIcon viewBox="0 0 24 24" sx={{ width: '24px', height: '24px', ...sx }} {...rest}>
      <g fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
        <g>
          <g>
            <g fill={htmlColor} transform="translate(-25 -108) translate(0 88) translate(25 20)">
              <g>
                <path
                  fill="red"
                  stroke={htmlColor}
                  strokeWidth="1.5"
                  d="M12 12V0M7 12V0M2 12V0M0 2h4m1 8h4m1-4h4"
                  transform="translate(5 6)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export default FiltersIcon;
