import { compile } from 'path-to-regexp';
import URL from 'url-parse';
import i18n from '../../../../../i18n';
import { REG_EXPS } from '../../constants';

export const compilePath = (path: string) => (params: Record<string, string>) => {
  if (['https://', 'http://'].some(protocol => path.startsWith(protocol))) {
    const { origin, pathname, query } = new URL(path);

    const buildURL = compile(pathname);
    return `${origin}${buildURL(params)}${query}`;
  }

  const buildURL = compile(path);
  return buildURL(params);
};

export const prependSymbolIfExists = (symbol, str) => {
  return str ? `${symbol}${str}` : '';
};

export const kilometersToMiles = valueKm => {
  const kmToMilesCoof = 0.62137119;

  return Math.round(valueKm * kmToMilesCoof * 100) / 100;
};

export const milesToKilometers = valueMiles => {
  const milesToKmCoof = 1.609344;

  return Math.round(valueMiles * milesToKmCoof * 10) / 10;
};

export const unitsConverters = {
  kilometers: {
    miles: kilometersToMiles,
  },
  miles: {
    kilometers: milesToKilometers,
  },
};

export const getResponseTErrorKey =
  (pattern, defaultKey = 'common.netError') =>
  (status, fieldName) => {
    try {
      const responseErrorKey = compile(pattern)({ status, fieldName });

      return i18n.exists(responseErrorKey) ? responseErrorKey : defaultKey;
    } catch {
      return defaultKey;
    }
  };

export const toSnakeCase = str => {
  if (!str) return '';

  return str
    .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    .map(x => x.toLowerCase())
    .join('_');
};

export const capitalize = str => {
  if (!str || str.length <= 1) return str;
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const isValidHttpUrl = str => {
  if (!str) {
    return false;
  }
  return new RegExp(REG_EXPS.URL, 'i').test(str);
};

export const putSuffixToFilenameInPath = (path: string, suffix: string): string => {
  return path
    .split('/')
    .map((value, index, pathElements) => {
      if (index === pathElements.length - 1) {
        return value.includes('.')
          ? `${value.split('.').slice(0, -1).join('.')}${suffix}.${value.substr(value.lastIndexOf('.') + 1)}`
          : `${value}${suffix}`;
      }
      return value;
    })
    .join('/');
};
