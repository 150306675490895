import React from 'react';
import { Paper, Stack, Divider } from '@egym/ui';
import { AppLayoutFeatureComponentProps } from '../../../../AppPreviewerProps';
import PreviewContainer from '../../../PreviewContainer';
import PreviewFeatureTitle from '../../../PreviewFeatureTitle';
import { mockActivityHistoryV2 } from './data';
import PreviewActivityHistoryItemV2 from './PreviewActivityHistoryItemV2';

const PreviewActivityHistoryV2: React.FC<AppLayoutFeatureComponentProps> = ({ tFunction, palette, wrapperSx }) => {
  return (
    <div>
      <PreviewContainer wrapperSx={wrapperSx}>
        <PreviewFeatureTitle
          tFunction={tFunction}
          wrapperSx={{ mb: '2px' }}
          palette={palette}
          title="appPreviewer.labels.latestActivity"
          withViewAll
        />
      </PreviewContainer>
      <Paper
        elevation={0}
        sx={{
          textAlign: 'left',
          fontSize: '14px',
          color: 'rgb(33,33,33)',
        }}
      >
        <Stack divider={<Divider flexItem sx={{ borderColor: 'rgb(244, 244, 244)' }} />}>
          {mockActivityHistoryV2.map(historyEntry => {
            return (
              <PreviewActivityHistoryItemV2
                tFunction={tFunction}
                palette={palette}
                key={historyEntry.id}
                {...historyEntry}
              />
            );
          })}
        </Stack>
      </Paper>
    </div>
  );
};

export default PreviewActivityHistoryV2;
