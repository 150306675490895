import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const CloseIcon: React.FC<SvgIconProps> = ({ sx, htmlColor = '#4C4A4A', ...rest }) => {
  return (
    <SvgIcon viewBox="0 0 24 24" sx={{ width: '20px', height: '20px', ...sx }} {...rest}>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <path d="M0 0H24V24H0z" transform="translate(-164 -1800) translate(164 1800)" />
            <path
              fill={htmlColor}
              d="M13.414 12l5.293-5.293c.391-.39.391-1.023 0-1.414-.39-.39-1.023-.39-1.414 0L12 10.586 6.707 5.293c-.39-.39-1.023-.39-1.414 0-.39.391-.39 1.023 0 1.414L10.586 12l-5.293 5.293c-.39.391-.39 1.023 0 1.414.195.195.451.293.707.293.256 0 .512-.098.707-.293L12 13.414l5.293 5.293c.195.195.451.293.707.293.256 0 .512-.098.707-.293.391-.39.391-1.023 0-1.414L13.414 12z"
              transform="translate(-164 -1800) translate(164 1800)"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export default CloseIcon;
