import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const MainTabHeaderDefaultIcon5: React.FC<SvgIconProps> = ({ htmlColor = '#FFF', sx, ...props }) => {
  return (
    <SvgIcon viewBox="0 0 48 48" {...props} sx={{ width: '48px', height: '48px', ...sx }}>
      <g fill={htmlColor} fillRule="evenodd">
        <path d="M24 4A19.866 19.866 0 0 0 9.858 9.859c-.022.022-.038.049-.058.071V8a1.8 1.8 0 0 0-3.6 0v6A1.8 1.8 0 0 0 8 15.8h6a1.8 1.8 0 1 0 0-3.599h-.777C16.177 9.496 19.969 8 24 8c8.822 0 16 7.178 16 16 0 8.823-7.178 16-16 16S8 32.823 8 24a2 2 0 0 0-4 0c0 11.029 8.972 20 20 20s20-8.971 20-20c0-11.028-8.972-20-20-20" />
        <path
          d="M21 30.8c.461 0 .921-.176 1.272-.527l10-10a1.797 1.797 0 0 0 0-2.545 1.798 1.798 0 0 0-2.544 0L21 26.455l-2.728-2.727a1.798 1.798 0 0 0-2.544 0 1.797 1.797 0 0 0 0 2.545l4 4c.351.351.811.527 1.272.527"
          opacity=".6"
        />
      </g>
    </SvgIcon>
  );
};
