import { AppLayoutFeatureEnum } from '@components';
import { AppDesignLayoutTabType, ApplicationLayout, ApplicationPackage, DefaultAppLayoutConfig } from '@types';
import {
  TabActivityOutlinedPredefinedIcon,
  TabChallengePredefinedIcon,
  TabClassesPredefinedIcon,
  TabClubPredefinedIcon,
  TabImmunePredefinedIcon,
  TabPrivacyPredefinedIcon,
  TabRewardsPredefinedIcon,
  TabRunningPredefinedIcon,
  TabTrackWorkoutPredefinedIcon,
  TabWorkoutsPredefinedIcon,
} from './appTabIcons';

export const defaultAppLayoutsConfig: DefaultAppLayoutConfig = {
  [ApplicationPackage.Basic]: {
    extraFeatures: [],
    // @ts-ignore
    layouts: {
      [ApplicationLayout.Workouts]: [
        {
          isDemoTab: true,
          type: AppDesignLayoutTabType.Workouts,
          defaultIcon: TabTrackWorkoutPredefinedIcon,
          defaultTitle: 'appPreviewer.labels.defaultTabsTitles.workouts',
          defaultFeatures: [
            AppLayoutFeatureEnum.PreviewTrainingPlans,
            AppLayoutFeatureEnum.PreviewActivityHistory,
            // AppLayoutFeatureEnum.PreviewWorkoutTracking,
            AppLayoutFeatureEnum.PreviewConnectedApps,
          ],
        },
        {
          type: AppDesignLayoutTabType.Club,
          defaultIcon: TabPrivacyPredefinedIcon,
          defaultTitle: 'appPreviewer.labels.defaultTabsTitles.club',
          defaultFeatures: [
            AppLayoutFeatureEnum.PreviewNotificationCenter,
            AppLayoutFeatureEnum.PreviewFindAClass,
            AppLayoutFeatureEnum.PreviewChallenges,
            AppLayoutFeatureEnum.PreviewExtras,
            AppLayoutFeatureEnum.PreviewLocationFinder,
            AppLayoutFeatureEnum.PreviewActivityFeed,
          ],
        },
        {
          type: AppDesignLayoutTabType.Progress,
          defaultIcon: TabChallengePredefinedIcon,
          defaultTitle: 'appPreviewer.labels.defaultTabsTitles.progress',
          defaultFeatures: [
            AppLayoutFeatureEnum.PreviewActivityLevel,
            AppLayoutFeatureEnum.PreviewRanking,
            AppLayoutFeatureEnum.PreviewBioAge,
          ],
        },
      ],
    },
  },
  [ApplicationPackage.Advanced]: {
    extraFeatures: [],
    // @ts-ignore
    layouts: {
      [ApplicationLayout.Community]: [
        {
          type: AppDesignLayoutTabType.Home,
          defaultIcon: TabClubPredefinedIcon,
          defaultTitle: 'appPreviewer.labels.defaultTabsTitles.home',
          defaultFeatures: [
            AppLayoutFeatureEnum.PreviewNotificationCenter,
            AppLayoutFeatureEnum.PreviewFindAClass,
            // AppLayoutFeatureEnum.PreviewExploreSchedule,
            AppLayoutFeatureEnum.PreviewLocationFinder,
          ],
        },
        {
          isDemoTab: true,
          type: AppDesignLayoutTabType.Community,
          defaultIcon: TabPrivacyPredefinedIcon,
          defaultTitle: 'appPreviewer.labels.defaultTabsTitles.community',
          defaultFeatures: [
            AppLayoutFeatureEnum.PreviewExtras,
            AppLayoutFeatureEnum.PreviewChallenges,
            AppLayoutFeatureEnum.PreviewActivityFeed,
            AppLayoutFeatureEnum.PreviewDeals,
          ],
        },
        {
          type: AppDesignLayoutTabType.Wellness,
          defaultIcon: TabWorkoutsPredefinedIcon,
          defaultTitle: 'appPreviewer.labels.defaultTabsTitles.wellness',
          defaultFeatures: [
            // AppLayoutFeatureEnum.PreviewVirtualClasses,
            AppLayoutFeatureEnum.PreviewTrainingPlans,
            AppLayoutFeatureEnum.PreviewActivityHistory,
            // AppLayoutFeatureEnum.PreviewWorkoutTracking,
            AppLayoutFeatureEnum.PreviewRequestTrainer,
          ],
        },
        {
          type: AppDesignLayoutTabType.Progress,
          defaultIcon: TabChallengePredefinedIcon,
          defaultTitle: 'appPreviewer.labels.defaultTabsTitles.progress',
          defaultFeatures: [
            AppLayoutFeatureEnum.PreviewActivityLevel,
            AppLayoutFeatureEnum.PreviewBioAge,
            AppLayoutFeatureEnum.PreviewRanking,
            AppLayoutFeatureEnum.PreviewGoalCenter,
          ],
        },
      ],
      [ApplicationLayout.WorkoutsAndProgress]: [
        {
          type: AppDesignLayoutTabType.Home,
          defaultIcon: TabClubPredefinedIcon,
          defaultTitle: 'appPreviewer.labels.defaultTabsTitles.home',
          defaultFeatures: [
            AppLayoutFeatureEnum.PreviewNotificationCenter,
            AppLayoutFeatureEnum.PreviewFindAClass,
            AppLayoutFeatureEnum.PreviewExtras,
            AppLayoutFeatureEnum.PreviewLocationFinder,
            AppLayoutFeatureEnum.PreviewDeals,
          ],
        },
        {
          type: AppDesignLayoutTabType.Compete,
          defaultIcon: TabChallengePredefinedIcon,
          defaultTitle: 'appPreviewer.labels.defaultTabsTitles.compete',
          defaultFeatures: [
            AppLayoutFeatureEnum.PreviewChallenges,
            AppLayoutFeatureEnum.PreviewRanking,
            AppLayoutFeatureEnum.PreviewActivityFeed,
          ],
        },
        {
          isDemoTab: true,
          type: AppDesignLayoutTabType.Workouts,
          defaultIcon: TabWorkoutsPredefinedIcon,
          defaultTitle: 'appPreviewer.labels.defaultTabsTitles.workouts',
          defaultFeatures: [
            AppLayoutFeatureEnum.PreviewTrainingPlans,
            AppLayoutFeatureEnum.PreviewActivityHistory,
            // AppLayoutFeatureEnum.PreviewWorkoutTracking,
            // AppLayoutFeatureEnum.PreviewVirtualClasses,
            AppLayoutFeatureEnum.PreviewGoalCenter,
            AppLayoutFeatureEnum.PreviewRequestTrainer,
          ],
        },
        {
          type: AppDesignLayoutTabType.Activity,
          defaultIcon: TabActivityOutlinedPredefinedIcon,
          defaultTitle: 'appPreviewer.labels.defaultTabsTitles.activity',
          defaultFeatures: [AppLayoutFeatureEnum.PreviewActivityLevelOnTheTab],
        },
        {
          type: AppDesignLayoutTabType.BioAge,
          defaultIcon: TabImmunePredefinedIcon,
          defaultTitle: 'appPreviewer.labels.defaultTabsTitles.bioAge',
          defaultFeatures: [AppLayoutFeatureEnum.PreviewBioAgeOnTheTab],
        },
      ],
      [ApplicationLayout.ClubAndClasses]: [
        {
          isDemoTab: true,
          type: AppDesignLayoutTabType.Classes,
          defaultIcon: TabClassesPredefinedIcon,
          defaultTitle: 'appPreviewer.labels.defaultTabsTitles.classes',
          defaultFeatures: [AppLayoutFeatureEnum.PreviewFindAClassOnTheTab],
        },
        {
          type: AppDesignLayoutTabType.Club,
          defaultIcon: TabPrivacyPredefinedIcon,
          defaultTitle: 'appPreviewer.labels.defaultTabsTitles.club',
          defaultFeatures: [
            AppLayoutFeatureEnum.PreviewNotificationCenter,
            AppLayoutFeatureEnum.PreviewExtras,
            AppLayoutFeatureEnum.PreviewLocationFinder,
            AppLayoutFeatureEnum.PreviewActivityFeed,
            AppLayoutFeatureEnum.PreviewDeals,
          ],
        },
        // {
        //   type: AppDesignLayoutTabType.Video,
        //   defaultIcon: TabVirtualWorkoutsPredefinedIcon,
        //   defaultTitle: 'appPreviewer.labels.defaultTabsTitles.video',
        //   defaultFeatures: [AppLayoutFeatureEnum.PreviewVirtualClassesOnTheTab],
        // },
        {
          type: AppDesignLayoutTabType.Workouts,
          defaultIcon: TabTrackWorkoutPredefinedIcon,
          defaultTitle: 'appPreviewer.labels.defaultTabsTitles.workouts',
          defaultFeatures: [
            AppLayoutFeatureEnum.PreviewTrainingPlans,
            AppLayoutFeatureEnum.PreviewActivityHistory,
            // AppLayoutFeatureEnum.PreviewWorkoutTracking,
            AppLayoutFeatureEnum.PreviewRequestTrainer,
          ],
        },
        {
          type: AppDesignLayoutTabType.Progress,
          defaultIcon: TabChallengePredefinedIcon,
          defaultTitle: 'appPreviewer.labels.defaultTabsTitles.progress',
          defaultFeatures: [
            AppLayoutFeatureEnum.PreviewChallenges,
            AppLayoutFeatureEnum.PreviewActivityLevel,
            AppLayoutFeatureEnum.PreviewBioAge,
            AppLayoutFeatureEnum.PreviewRanking,
            AppLayoutFeatureEnum.PreviewGoalCenter,
          ],
        },
      ],
    },
  },
  [ApplicationPackage.Pro]: {
    extraFeatures: [AppLayoutFeatureEnum.PreviewGuestPass],
    // @ts-ignore
    layouts: {
      [ApplicationLayout.Community]: [
        {
          type: AppDesignLayoutTabType.Home,
          defaultIcon: TabClubPredefinedIcon,
          defaultTitle: 'appPreviewer.labels.defaultTabsTitles.home',
          defaultFeatures: [
            AppLayoutFeatureEnum.PreviewNotificationCenter,
            AppLayoutFeatureEnum.PreviewFindAClass,
            // AppLayoutFeatureEnum.PreviewExploreSchedule,
            AppLayoutFeatureEnum.PreviewLocationFinder,
          ],
        },
        {
          isDemoTab: true,
          type: AppDesignLayoutTabType.Community,
          defaultIcon: TabPrivacyPredefinedIcon,
          defaultTitle: 'appPreviewer.labels.defaultTabsTitles.community',
          defaultFeatures: [
            AppLayoutFeatureEnum.PreviewExtras,
            AppLayoutFeatureEnum.PreviewChallenges,
            AppLayoutFeatureEnum.PreviewReferAFriend,
            AppLayoutFeatureEnum.PreviewRewards,
            AppLayoutFeatureEnum.PreviewActivityFeed,
            AppLayoutFeatureEnum.PreviewDeals,
          ],
        },
        {
          type: AppDesignLayoutTabType.Wellness,
          defaultIcon: TabWorkoutsPredefinedIcon,
          defaultTitle: 'appPreviewer.labels.defaultTabsTitles.wellness',
          defaultFeatures: [
            // AppLayoutFeatureEnum.PreviewVirtualClasses,
            AppLayoutFeatureEnum.PreviewTrainingPlans,
            AppLayoutFeatureEnum.PreviewActivityHistory,
            // AppLayoutFeatureEnum.PreviewWorkoutTracking,
            AppLayoutFeatureEnum.PreviewRequestTrainer,
          ],
        },
        {
          type: AppDesignLayoutTabType.Progress,
          defaultIcon: TabChallengePredefinedIcon,
          defaultTitle: 'appPreviewer.labels.defaultTabsTitles.progress',
          defaultFeatures: [
            AppLayoutFeatureEnum.PreviewActivityLevel,
            AppLayoutFeatureEnum.PreviewBioAge,
            AppLayoutFeatureEnum.PreviewRanking,
            AppLayoutFeatureEnum.PreviewGoalCenter,
          ],
        },
      ],
      [ApplicationLayout.WorkoutsAndProgress]: [
        {
          type: AppDesignLayoutTabType.Home,
          defaultIcon: TabClubPredefinedIcon,
          defaultTitle: 'appPreviewer.labels.defaultTabsTitles.home',
          defaultFeatures: [
            AppLayoutFeatureEnum.PreviewNotificationCenter,
            AppLayoutFeatureEnum.PreviewFindAClass,
            AppLayoutFeatureEnum.PreviewExtras,
            AppLayoutFeatureEnum.PreviewReferAFriend,
            AppLayoutFeatureEnum.PreviewRewards,
            AppLayoutFeatureEnum.PreviewLocationFinder,
            AppLayoutFeatureEnum.PreviewDeals,
          ],
        },
        {
          type: AppDesignLayoutTabType.Compete,
          defaultIcon: TabChallengePredefinedIcon,
          defaultTitle: 'appPreviewer.labels.defaultTabsTitles.compete',
          defaultFeatures: [
            AppLayoutFeatureEnum.PreviewChallenges,
            AppLayoutFeatureEnum.PreviewRanking,
            AppLayoutFeatureEnum.PreviewActivityFeed,
          ],
        },
        {
          isDemoTab: true,
          type: AppDesignLayoutTabType.Workouts,
          defaultIcon: TabWorkoutsPredefinedIcon,
          defaultTitle: 'appPreviewer.labels.defaultTabsTitles.workouts',
          defaultFeatures: [
            AppLayoutFeatureEnum.PreviewTrainingPlans,
            AppLayoutFeatureEnum.PreviewActivityHistory,
            // AppLayoutFeatureEnum.PreviewWorkoutTracking,
            // AppLayoutFeatureEnum.PreviewVirtualClasses,
            AppLayoutFeatureEnum.PreviewGoalCenter,
            AppLayoutFeatureEnum.PreviewRequestTrainer,
          ],
        },
        {
          type: AppDesignLayoutTabType.Activity,
          defaultIcon: TabActivityOutlinedPredefinedIcon,
          defaultTitle: 'appPreviewer.labels.defaultTabsTitles.activity',
          defaultFeatures: [AppLayoutFeatureEnum.PreviewActivityLevelOnTheTab],
        },
        {
          type: AppDesignLayoutTabType.BioAge,
          defaultIcon: TabImmunePredefinedIcon,
          defaultTitle: 'appPreviewer.labels.defaultTabsTitles.bioAge',
          defaultFeatures: [AppLayoutFeatureEnum.PreviewBioAgeOnTheTab],
        },
      ],
      [ApplicationLayout.ClubAndClasses]: [
        {
          isDemoTab: true,
          type: AppDesignLayoutTabType.Classes,
          defaultIcon: TabClassesPredefinedIcon,
          defaultTitle: 'appPreviewer.labels.defaultTabsTitles.classes',
          defaultFeatures: [AppLayoutFeatureEnum.PreviewFindAClassOnTheTab],
        },
        {
          type: AppDesignLayoutTabType.Club,
          defaultIcon: TabPrivacyPredefinedIcon,
          defaultTitle: 'appPreviewer.labels.defaultTabsTitles.club',
          defaultFeatures: [
            AppLayoutFeatureEnum.PreviewNotificationCenter,
            AppLayoutFeatureEnum.PreviewExtras,
            AppLayoutFeatureEnum.PreviewLocationFinder,
            AppLayoutFeatureEnum.PreviewReferAFriend,
            AppLayoutFeatureEnum.PreviewRewards,
            AppLayoutFeatureEnum.PreviewActivityFeed,
            AppLayoutFeatureEnum.PreviewDeals,
          ],
        },
        // {
        //   type: AppDesignLayoutTabType.Video,
        //   defaultIcon: TabVirtualWorkoutsPredefinedIcon,
        //   defaultTitle: 'appPreviewer.labels.defaultTabsTitles.video',
        //   defaultFeatures: [AppLayoutFeatureEnum.PreviewVirtualClassesOnTheTab],
        // },
        {
          type: AppDesignLayoutTabType.Workouts,
          defaultIcon: TabTrackWorkoutPredefinedIcon,
          defaultTitle: 'appPreviewer.labels.defaultTabsTitles.workouts',
          defaultFeatures: [
            AppLayoutFeatureEnum.PreviewTrainingPlans,
            AppLayoutFeatureEnum.PreviewActivityHistory,
            // AppLayoutFeatureEnum.PreviewWorkoutTracking,
            AppLayoutFeatureEnum.PreviewRequestTrainer,
          ],
        },
        {
          type: AppDesignLayoutTabType.Progress,
          defaultIcon: TabChallengePredefinedIcon,
          defaultTitle: 'appPreviewer.labels.defaultTabsTitles.progress',
          defaultFeatures: [
            AppLayoutFeatureEnum.PreviewChallenges,
            AppLayoutFeatureEnum.PreviewActivityLevel,
            AppLayoutFeatureEnum.PreviewBioAge,
            AppLayoutFeatureEnum.PreviewRanking,
            AppLayoutFeatureEnum.PreviewGoalCenter,
          ],
        },
      ],
      [ApplicationLayout.ClubAndPerks]: [
        {
          type: AppDesignLayoutTabType.Club,
          defaultIcon: TabPrivacyPredefinedIcon,
          defaultTitle: 'appPreviewer.labels.defaultTabsTitles.club',
          defaultFeatures: [
            AppLayoutFeatureEnum.PreviewNotificationCenter,
            AppLayoutFeatureEnum.PreviewExtras,
            AppLayoutFeatureEnum.PreviewFindAClass,
            AppLayoutFeatureEnum.PreviewChallenges,
            AppLayoutFeatureEnum.PreviewActivityFeed,
            AppLayoutFeatureEnum.PreviewLocationFinder,
          ],
        },
        {
          isDemoTab: true,
          type: AppDesignLayoutTabType.Perks,
          defaultIcon: TabRewardsPredefinedIcon,
          defaultTitle: 'appPreviewer.labels.defaultTabsTitles.perks',
          defaultFeatures: [
            AppLayoutFeatureEnum.PreviewReferAFriend,
            AppLayoutFeatureEnum.PreviewRewards,
            AppLayoutFeatureEnum.PreviewDeals,
          ],
        },
        {
          type: AppDesignLayoutTabType.Workouts,
          defaultIcon: TabRunningPredefinedIcon,
          defaultTitle: 'appPreviewer.labels.defaultTabsTitles.workouts',
          defaultFeatures: [
            AppLayoutFeatureEnum.PreviewTrainingPlans,
            AppLayoutFeatureEnum.PreviewActivityHistory,
            // AppLayoutFeatureEnum.PreviewWorkoutTracking,
            AppLayoutFeatureEnum.PreviewRequestTrainer,
          ],
        },
        {
          type: AppDesignLayoutTabType.Progress,
          defaultIcon: TabChallengePredefinedIcon,
          defaultTitle: 'appPreviewer.labels.defaultTabsTitles.progress',
          defaultFeatures: [
            AppLayoutFeatureEnum.PreviewActivityLevel,
            AppLayoutFeatureEnum.PreviewBioAge,
            AppLayoutFeatureEnum.PreviewRanking,
            AppLayoutFeatureEnum.PreviewGoalCenter,
          ],
        },
      ],
    },
  },
};
