import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const HistoryIcon: React.FC<SvgIconProps> = ({ sx, htmlColor = '#4C4A4A', ...rest }) => {
  return (
    <SvgIcon viewBox="0 0 24 24" sx={{ width: '24px', height: '24px', ...sx }} {...rest}>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <path d="M0 0H24V24H0z" transform="translate(-716 -2679) translate(716 2679)" />
            <path
              fill={htmlColor}
              d="M13 2c5.514 0 10 4.486 10 10s-4.486 10-10 10c-2.678 0-5.188-1.042-7.069-2.935-.39-.391-.388-1.025.004-1.414.391-.39 1.024-.387 1.414.004C8.852 19.167 10.858 20 13 20c4.411 0 8-3.589 8-8s-3.589-8-8-8c-4.043 0-7.387 3.018-7.918 6.918l.211-.211c.391-.391 1.023-.391 1.414 0s.391 1.023 0 1.414l-2 2c-.195.195-.451.293-.707.293-.256 0-.512-.098-.707-.293l-2-2c-.391-.391-.391-1.023 0-1.414s1.023-.391 1.414 0l.342.342C3.529 5.979 7.808 2 13 2zm0 6c.553 0 1 .447 1 1v2.586l2.535 2.535c.391.39.391 1.024 0 1.414-.195.196-.451.293-.707.293-.256 0-.512-.097-.707-.293l-2.828-2.828C12.105 12.52 12 12.266 12 12V9c0-.553.447-1 1-1z"
              transform="translate(-716 -2679) translate(716 2679)"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export default HistoryIcon;
