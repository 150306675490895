import React, { forwardRef } from 'react';
import { Paper } from '@egym/ui';
import { AppLayoutFeatureComponentProps } from '../../../../AppPreviewerProps';
import PreviewContainer from '../../../PreviewContainer';
import PreviewFeatureTitle from '../../../PreviewFeatureTitle';
import { activityFeedItems } from './data';
import PreviewActivityFeedItemCompletedGoal from './PreviewActivityFeedItemCompletedGoal';
import PreviewActivityFeedItemCompletedWorkout from './PreviewActivityFeedItemCompletedWorkout';
import PreviewActivityFeedItemJoinedChallenge from './PreviewActivityFeedItemJoinedChallenge';

const PreviewActivityFeed = forwardRef<HTMLDivElement, AppLayoutFeatureComponentProps>(
  ({ tFunction, palette, wrapperSx, widgetProps, previewerState }, ref) => {
    return (
      <PreviewContainer ref={ref} wrapperSx={wrapperSx}>
        <PreviewFeatureTitle
          tFunction={tFunction}
          palette={palette}
          title={
            widgetProps?.preferences?.title?.[previewerState.selectedLocale as string] ||
            'appPreviewer.labels.activityFeed'
          }
          withViewAll
        />
        <Paper
          sx={{
            py: '5px',
            textAlign: 'center',
            fontSize: '14px',
            color: 'rgb(33,33,33)',
            borderRadius: '8px',
            boxShadow: '0 4px 6px rgba(223, 223, 223, 0.7)',
          }}
        >
          {activityFeedItems.map(activityFeedItem => {
            switch (activityFeedItem.type) {
              case 'completedWorkout': {
                return (
                  <PreviewActivityFeedItemCompletedWorkout
                    tFunction={tFunction}
                    palette={palette}
                    key={activityFeedItem.id}
                    {...activityFeedItem}
                  />
                );
              }
              case 'completedGoal': {
                return (
                  <PreviewActivityFeedItemCompletedGoal
                    tFunction={tFunction}
                    palette={palette}
                    key={activityFeedItem.id}
                    {...activityFeedItem}
                  />
                );
              }
              case 'joinedChallenge': {
                return (
                  <PreviewActivityFeedItemJoinedChallenge
                    tFunction={tFunction}
                    palette={palette}
                    key={activityFeedItem.id}
                    {...activityFeedItem}
                  />
                );
              }
              default:
                return null;
            }
          })}
        </Paper>
      </PreviewContainer>
    );
  },
);

export default PreviewActivityFeed;
