import React, { forwardRef } from 'react';
import { AppLayoutFeatureComponentProps } from '../../../../AppPreviewerProps';
import PreviewContainer from '../../../PreviewContainer';
import PreviewFeatureTitle from '../../../PreviewFeatureTitle';
import { homeLocationItem } from './data';
import PreviewHomeLocationCard from './PreviewHomeLocationCard';

const PreviewLocationFinder = forwardRef<HTMLDivElement, AppLayoutFeatureComponentProps>(
  ({ tFunction, palette, wrapperSx, widgetProps, previewerState }, ref) => {
    return (
      <PreviewContainer ref={ref} wrapperSx={wrapperSx}>
        <PreviewFeatureTitle
          tFunction={tFunction}
          title={
            widgetProps?.preferences?.title?.[previewerState.selectedLocale as string] ||
            'appPreviewer.labels.homeLocation'
          }
          withViewAll
          palette={palette}
        />
        <PreviewHomeLocationCard
          tFunction={tFunction}
          palette={palette}
          widgetProps={widgetProps}
          {...homeLocationItem}
        />
      </PreviewContainer>
    );
  },
);

export default PreviewLocationFinder;
