import React, { ReactNode } from 'react';
import { TFunction } from 'i18next';
import { Stack, Box } from '@egym/ui';
import { AppPreviewerPalette } from '../../../../AppPreviewerProps';

type Props = {
  tFunction: TFunction;
  palette: Partial<AppPreviewerPalette>;
  avatar: string;
  challengeName?: string;
  count?: number;
  dateHours: number;
  dateMinutes: number;
};

const PreviewActivityFeedItemJoinedChallenge: React.FC<Props> = ({
  tFunction,
  avatar,
  challengeName,
  count,
  dateHours,
  dateMinutes,
}) => {
  return (
    <Stack
      direction="row"
      justifyContent="flex-start"
      sx={{ pl: '9px', pr: '7px', py: '10px', ':not(:last-child)': { borderBottom: '1px solid rgb(244, 244, 244)' } }}
    >
      <Box
        sx={{
          position: 'relative',
          width: '38px',
          height: '38px',
          mr: '8px',
          mt: '2px',
          bgcolor: 'rgb(224, 224, 224)',
          borderRadius: '50%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box component="img" src={avatar} sx={{ width: '22px', height: '22px' }} />
      </Box>

      <Stack sx={{ fontSize: '13px', color: 'rgb(33, 33, 33)', textAlign: 'left', flex: 1 }}>
        <span>
          {tFunction('appPreviewer.labels.peopleJoined', { count }) as ReactNode}{' '}
          <strong style={{ fontWeight: 700 }}>{tFunction(challengeName || '') as ReactNode}</strong>
        </span>
        <span style={{ fontSize: '11px', fontWeight: 500, marginTop: '5px', color: 'rgb(76, 74, 74)' }}>
          {dateHours
            ? (tFunction('appPreviewer.labels.durationHoursMinutesShortAgo', {
                hours: dateHours,
                minutes: dateMinutes,
              }) as ReactNode)
            : (tFunction('appPreviewer.labels.durationMinutesShortAgo', {
                hours: dateHours,
                minutes: dateMinutes,
              }) as ReactNode)}
        </span>
      </Stack>
    </Stack>
  );
};

export default PreviewActivityFeedItemJoinedChallenge;
