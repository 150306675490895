import React, { FC, ReactNode } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useToggle } from 'react-use';
import { styled } from '@mui/material/styles';
import { Collapse, IconButton, Icons, Paper, Stack, Typography } from '@egym/ui';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#FBFBFB',
  ...theme.typography.body2,
  padding: theme.spacing(2.5),
  borderBottom: '1px solid',
  borderBottomColor: '#E7E7E7',
  borderRadius: 0,
}));

const CollapsedItem: FC<{ title: string; index: number }> = ({ title, index }) => {
  const [on, toggle] = useToggle(false);
  return (
    <Item
      key={title}
      elevation={0}
      sx={({ spacing }) => ({
        '&:first-of-type': {
          borderTopLeftRadius: spacing(1.75),
          borderTopRightRadius: spacing(1.75),
        },
        '&:last-of-type': {
          borderBottomLeftRadius: spacing(1.75),
          borderBottomRightRadius: spacing(1.75),
        },
      })}
    >
      <Stack>
        <Stack direction="row" justifyContent="space-between" height="40px" alignItems="center" onClick={toggle}>
          <Typography variant="body1">{title}</Typography>
          <IconButton aria-label="expand" size="small">
            {on ? <Icons.KeyboardArrowUp /> : <Icons.KeyboardArrowDown />}
          </IconButton>
        </Stack>
      </Stack>
      <Collapse in={on}>
        <Typography variant="body1">
          <Trans
            i18nKey={`appDesign.legalAccessibilityDialog.regions.${index}.content`}
            components={[<Typography component="span" variant="body1Bold" />]}
          />
        </Typography>
      </Collapse>
    </Item>
  );
};

const LegalAccessibilityRequirementsDialog: React.FC = () => {
  const { t } = useTranslation();
  const regions = t('appDesign.legalAccessibilityDialog.regions', { returnObjects: true }) as { title: string }[];

  return (
    <Stack>
      <Typography variant="body1" sx={{ marginBottom: 2.5 }}>
        {t('appDesign.legalAccessibilityDialog.annotation') as ReactNode}
      </Typography>
      {regions.map((region, index) => (
        <CollapsedItem title={region.title} index={index} key={region.title} />
      ))}
    </Stack>
  );
};

export default LegalAccessibilityRequirementsDialog;
