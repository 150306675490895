import { Components } from '@mui/material/styles';

const MuiBreadcrumbs: Components['MuiBreadcrumbs'] = {
  styleOverrides: {
    li: {
      display: 'flex',
    },
  },
};

export default MuiBreadcrumbs;
