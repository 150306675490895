import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { Option, Uuid } from '@egym/types';
import { useSnackbar } from '@egym/ui';
import { getOptionsFromEntity } from '@egym/utils';
import { getMemberManagementSoftware } from '@api';

export const mmsNotInTheListValue = -1;

const useMemberManagementSoftware = (applicationId: Uuid) => {
  const { openSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const memberManagementSoftware = useQuery(
    ['memberManagementSoftware', applicationId],
    async () => getMemberManagementSoftware({ queryParams: { size: 1000 } }),
    {
      refetchOnMount: false,
      keepPreviousData: true,
      select: result => {
        return result.data;
      },
      onError: () => {
        openSnackbar(t('common.netError'), { severity: 'error' });
      },
    },
  );

  const memberManagementSoftwareOptions = useMemo<Option[]>(() => {
    if (!memberManagementSoftware.isFetched || !memberManagementSoftware.data)
      return [{ label: t('common.labels.none'), value: '' }];
    return [
      ...getOptionsFromEntity(memberManagementSoftware.data.content, 'name', 'id'),
      { label: t('appSettings.labels.mmsNotInTheList'), value: mmsNotInTheListValue },
    ];
  }, [t, memberManagementSoftware]);

  return {
    memberManagementSoftware,
    memberManagementSoftwareOptions,
  };
};

export default useMemberManagementSoftware;
