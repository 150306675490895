import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const TabWorkoutsIcon2: React.FC<SvgIconProps> = ({ sx, htmlColor = '#4C4A4A', ...rest }) => {
  return (
    <SvgIcon viewBox="0 0 24 24" sx={{ width: '24px', height: '24px', ...sx }} {...rest}>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <path d="M0 0H24V24H0z" transform="translate(-164 -2828) translate(164 2828)" />
            <path
              fill={htmlColor}
              d="M20.885 6.146c.391-.184.856-.017 1.04.374.158.335.058.725-.22.946-1.236.979-2.609 1.662-4.021 2.241-.676.273-1.37.505-2.075.704l-1.273 2.863 2.24-.12.058-.002c.611-.034 1.135.437 1.168 1.05.009.179-.026.354-.094.509l-2.302 5.199c-.194.437-.706.636-1.143.442-.412-.182-.61-.647-.474-1.066l1.236-3.753-3.468.185L3.256 19.6c-.442.206-.966.015-1.174-.427-.197-.425-.026-.93.382-1.151l7.15-3.846 1.2-2.966c-2.168.18-4.314.188-6.476.049-.397-.024-.698-.368-.672-.764.023-.346.285-.621.616-.667l.018-.001c2.879-.41 5.803-.67 8.584-1.144 1.373-.235 2.727-.59 4.068-1.009 1.34-.417 2.663-.93 3.909-1.516zM13.728 3.5c1.038.008 1.878.845 1.89 1.885.01 1.029-.829 1.902-1.853 1.926-1.042.025-1.934-.865-1.928-1.923.007-1.021.881-1.894 1.891-1.888z"
              transform="translate(-164 -2828) translate(164 2828)"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export default TabWorkoutsIcon2;
