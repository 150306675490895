import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const BrainIcon: React.FC<SvgIconProps> = ({ sx, htmlColor = '#4C4A4A', ...rest }) => {
  return (
    <SvgIcon sx={{ width: '20px', height: '20px', color: htmlColor, ...sx }} {...rest}>
      <path
        d="M18 17a1 1 0 0 0-1 1c0 1.103-.897 2-2 2s-2-.897-2-2v-5.99l.001-.01-.001-.005V9.443a3.953 3.953 0 0 0 2.001.557 1 1 0 1 0 0-2c-1.103 0-2-.897-2-2L13 5.997A2.003 2.003 0 0 1 15 4c1.103 0 2 .897 2 2a1 1 0 0 0 1 1c1.103 0 2 .897 2 2s-.897 2-2 2h-1a1 1 0 1 0 0 2h1c1.103 0 2 .897 2 2s-.897 2-2 2m-9 3c-1.103 0-2-.897-2-2a1 1 0 0 0-1-1c-1.103 0-2-.897-2-2s.897-2 2-2h1a1 1 0 1 0 0-2H6c-1.103 0-2-.897-2-2s.897-2 2-2a1 1 0 0 0 1-1c0-1.103.897-2 2-2s2 .897 2 2v6.01A2.002 2.002 0 0 1 9.001 14a1 1 0 1 0 0 2c.731 0 1.408-.212 1.999-.556V18c0 1.103-.897 2-2 2m11.618-8A3.974 3.974 0 0 0 22 9a4.002 4.002 0 0 0-3.108-3.892A4.002 4.002 0 0 0 15 2c-1.2 0-2.267.542-3 1.382A3.974 3.974 0 0 0 9 2a4.002 4.002 0 0 0-3.892 3.108A4.002 4.002 0 0 0 2 9c0 1.2.542 2.267 1.382 3A3.974 3.974 0 0 0 2 15a4.002 4.002 0 0 0 3.108 3.892A4.002 4.002 0 0 0 9 22c1.2 0 2.267-.542 3-1.382.733.84 1.8 1.382 3 1.382a4.002 4.002 0 0 0 3.892-3.108A4.002 4.002 0 0 0 22 15c0-1.2-.542-2.267-1.382-3"
        fill={htmlColor}
        fillRule="evenodd"
      />
    </SvgIcon>
  );
};

export default BrainIcon;
