import React, { ReactNode, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useUpdateEffect } from 'react-use';
import { Icons } from '../../../../../icons';
import { Button } from '../../../../../inputs/Button';
import { IconButton } from '../../../../../inputs/IconButton';
import { Stack } from '../../../../../layout/Stack';
import { Popover } from '../../../../../utils/Popover';
import { TableColumn, TableState } from '../../../TableProps';
import TableFilterDropdownMenuList from './TableFilterDropdownMenuList';

type Props = {
  column: TableColumn;
  updateTableStateFilters: (fieldNames: string[], value: any) => void;
  tableState: TableState;
};

const TableFilterDropdown: React.FC<Props> = ({ column, updateTableStateFilters, tableState }) => {
  const { t } = useTranslation();
  const [selectedValue, setSelectedValue] = useState(tableState.filtering?.[column.field]);
  const fieldNames = useMemo(
    () => column.filter?.filterProps?.fields || [column.field],
    [column.field, column.filter?.filterProps?.fields],
  );

  const selectedOption = useMemo(() => {
    return column.filter?.filterProps.options.find(option => option.value === selectedValue);
  }, [selectedValue, column.filter?.filterProps.options]);

  const onSelect = useCallback(
    newValue => {
      setSelectedValue(newValue);
      updateTableStateFilters(fieldNames, newValue);
    },
    [fieldNames, updateTableStateFilters],
  );

  const onCancel = useCallback(
    e => {
      e.stopPropagation();
      setSelectedValue(undefined);
      updateTableStateFilters(fieldNames, null);
    },
    [fieldNames, updateTableStateFilters],
  );

  useUpdateEffect(() => {
    if (!tableState.filtering?.[fieldNames[0]] && selectedOption) {
      setSelectedValue(undefined);
    }
  }, [fieldNames, tableState.filtering?.[fieldNames[0]]]);

  return column.filter?.filterProps.options ? (
    <Popover
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <Stack display="inline-flex" direction="row" alignItems="center">
        <Button
          variant="text"
          type="button"
          color="inherit"
          text={
            <Stack
              direction="row"
              alignItems="center"
              data-testid={`table-filter-${column.field}-select-status-${
                selectedOption ? selectedOption.value : 'not-selected'
              }`}
            >
              {t(selectedOption ? selectedOption.label : 'common.labels.select') as ReactNode}
              <Icons.KeyboardArrowDown />
            </Stack>
          }
          sx={{ px: 0, py: 1.25, fontWeight: 400, textTransform: 'capitalize' }}
          data-testid={`table-filter-${column.field}-select-button`}
        />
        {selectedOption && (
          <IconButton
            sx={{ fontSize: '20px' }}
            edge="end"
            onClick={onCancel}
            size="large"
            data-testid={`table-filter-${column.field}-select-cancel`}
          >
            <Icons.CancelOutlined fontSize="inherit" color="action" />
          </IconButton>
        )}
      </Stack>
      <TableFilterDropdownMenuList
        column={column}
        onSelect={onSelect}
        options={column.filter?.filterProps.options}
        selectedValue={selectedValue}
      />
    </Popover>
  ) : null;
};

export default TableFilterDropdown;
