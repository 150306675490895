import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { DndContext, MeasuringStrategy, DragOverlay, rectIntersection } from '@dnd-kit/core';
import { rectSortingStrategy, SortableContext } from '@dnd-kit/sortable';
import { Box, Chip, ConditionalRender, Icons, Stack, Typography } from '@egym/ui';
import PopoverRoot from './components/PopoverRoot';
import SortableLanguageChip from './components/SortableLanguageChip';
import { MultiLanguageSelectorFormFieldProps } from './MultiLanguageSelectorFormFieldProps';

const noop = () => {
  // to nothing
};

const MultiLanguageSelectorFormField: React.FC<MultiLanguageSelectorFormFieldProps> = ({
  wrapperSx,
  languageSelectorSx,
  selectLanguageLabelSx,
  selectLanguageLabel,
  filteredPossibleLanguages,
  possibleLanguages,
  addedLanguages,
  onSearchChange,
  searchValue,
  onPossibleLanguageSelect,
  onRemoveAddedLanguage,
  isViewMode,
  onDragEnd,
  onDragOver,
  onDragStart,
  defaultLocale,
  sensors,
  activeDraggingLanguage,
  limit,
  onAddLanguageClick,
}) => {
  const { t } = useTranslation();

  const ActiveDraggingLanguageIcon = activeDraggingLanguage?.icon;

  return (
    <Box sx={wrapperSx}>
      <Stack direction="column" alignItems="flex-start" sx={languageSelectorSx}>
        {selectLanguageLabel && (
          <Typography
            variant="h6"
            component="label"
            htmlFor="selectLang"
            sx={{ width: '180px', ...selectLanguageLabelSx }}
          >
            {t(selectLanguageLabel) as ReactNode}
          </Typography>
        )}
        <ConditionalRender condition={isViewMode}>
          <Stack direction="row" flexWrap="wrap">
            {addedLanguages.map(({ icon: Icon, name }) => (
              <Chip
                key={name}
                variant="outlined"
                color="default"
                icon={Icon && <Icon sx={{ ml: '8px !important' }} />}
                label={name}
                sx={{
                  height: '32px',
                  borderRadius: 4,
                  mb: 3,
                  ':not(:last-child)': {
                    mr: 3,
                  },
                }}
              />
            ))}
          </Stack>
          <Stack direction="column" alignItems="flex-start" justifyContent="flex-start" spacing={3}>
            <Stack direction="row" flexWrap="wrap">
              <DndContext
                sensors={sensors}
                collisionDetection={rectIntersection}
                onDragEnd={onDragEnd}
                onDragOver={onDragOver}
                onDragStart={onDragStart}
                measuring={{ droppable: { strategy: MeasuringStrategy.Always, frequency: 20 } }}
              >
                <SortableContext
                  items={addedLanguages.map(it => ({ ...it, id: it.locale }))}
                  strategy={rectSortingStrategy}
                >
                  {addedLanguages.map(({ icon: Icon, name, locale, shortName }) => (
                    <SortableLanguageChip
                      key={name}
                      language={{ id: locale, locale, shortName, icon: Icon, name }}
                      Icon={Icon}
                      onRemoveAddedLanguage={onRemoveAddedLanguage}
                      itemsLength={addedLanguages.length}
                      defaultLocale={defaultLocale}
                    />
                  ))}
                </SortableContext>
                <DragOverlay>
                  {activeDraggingLanguage ? (
                    <Chip
                      variant="outlined"
                      color="default"
                      icon={
                        ActiveDraggingLanguageIcon && (
                          <Stack direction="row" alignItems="center">
                            <Icons.DragIndicator fontSize="small" sx={{ ml: 2 }} />
                            <ActiveDraggingLanguageIcon sx={{ ml: '8px !important' }} />
                          </Stack>
                        )
                      }
                      label={activeDraggingLanguage.name}
                      sx={{
                        height: '32px',
                        borderRadius: 4,
                        backgroundColor: 'common.white',
                        cursor: 'grabbing',
                      }}
                      deleteIcon={<Icons.Close sx={{ fontSize: '16px !important', cursor: 'pointer' }} />}
                      onDelete={String(activeDraggingLanguage.locale) !== defaultLocale ? noop : undefined}
                    />
                  ) : null}
                </DragOverlay>
              </DndContext>
            </Stack>
            <ConditionalRender condition={!limit || addedLanguages.length < limit}>
              <PopoverRoot
                filteredPossibleLanguages={filteredPossibleLanguages}
                possibleLanguages={possibleLanguages}
                onSearchChange={onSearchChange}
                searchValue={searchValue}
                onPossibleLanguageSelect={onPossibleLanguageSelect}
                onAddLanguageClick={onAddLanguageClick}
              />
            </ConditionalRender>
          </Stack>
        </ConditionalRender>
      </Stack>
    </Box>
  );
};

export default MultiLanguageSelectorFormField;
