export const sessionStorageEffectRaw =
  (key: string) =>
  ({ setSelf, onSet }) => {
    const savedValue = sessionStorage.getItem(key);
    if (savedValue) {
      setSelf(savedValue);
    }
    onSet(newValue => {
      if (newValue) {
        sessionStorage.setItem(key, newValue);
      } else {
        sessionStorage.removeItem(key);
      }
    });
  };

export const localStorageEffectRaw =
  (key: string) =>
  ({ setSelf, onSet }) => {
    const savedValue = localStorage.getItem(key);
    if (savedValue) {
      setSelf(savedValue);
    }
    onSet(newValue => {
      if (newValue) {
        localStorage.setItem(key, newValue);
      } else {
        localStorage.removeItem(key);
      }
    });
  };
