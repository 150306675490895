import React, { forwardRef, ReactNode, useMemo } from 'react';
import { alpha, darken } from '@mui/material';
import { Box, Stack } from '@egym/ui';
import { WidgetViewStyle } from '@types';
import { AppLayoutFeatureComponentProps } from '../../../../AppPreviewerProps';
import { CoinIcon } from '../../../Icons';
import PreviewContainer from '../../../PreviewContainer';
import PreviewFeatureTitle from '../../../PreviewFeatureTitle';

const PreviewRewards = forwardRef<HTMLDivElement, AppLayoutFeatureComponentProps>(
  ({ tFunction, palette, wrapperSx, widgetProps, previewerState }, ref) => {
    const currentStyle = widgetProps?.preferences?.style || WidgetViewStyle.Icon;
    const imagePath = useMemo<string | null>(() => {
      if (widgetProps?.preferences?.asset?.file) {
        return widgetProps?.preferences?.asset?.file?.preview
          ? widgetProps.preferences.asset.file.preview
          : URL.createObjectURL(widgetProps.preferences.asset.file);
      }
      if (typeof widgetProps?.preferences?.asset?.link === 'string') {
        const { link } = widgetProps.preferences.asset;
        return link.startsWith('/') ? link : `/${link}`;
      }
      return null;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [widgetProps?.preferences?.asset?.link, widgetProps?.preferences?.asset?.file?.name]);
    return (
      <PreviewContainer ref={ref} wrapperSx={wrapperSx}>
        <PreviewFeatureTitle
          tFunction={tFunction}
          title={
            widgetProps?.preferences?.title?.[previewerState.selectedLocale as string] || 'appPreviewer.labels.rewards'
          }
          palette={palette}
        />
        {currentStyle === WidgetViewStyle.Image && (
          <Box
            sx={{
              position: 'relative',
            }}
          >
            <div
              style={{
                height: '80px',
                width: '100%',
                background: imagePath
                  ? `url(${imagePath}) no-repeat center/cover`
                  : palette.primary &&
                    `linear-gradient(90deg, ${palette.primary}, ${alpha(palette.primary, 0.9)}, ${alpha(
                      palette.primary,
                      0.75,
                    )})`,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                color: 'white',
                padding: '8px 15px',
                paddingRight: 0,
                boxSizing: 'border-box',
                borderRadius: '8px',
                overflow: 'hidden',
                position: 'relative',
              }}
            >
              {widgetProps?.preferences?.assetColor && (
                <div
                  style={{
                    position: 'absolute',
                    background: widgetProps.preferences.assetColor,
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                  }}
                />
              )}
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ width: '100%', position: 'relative' }}
              >
                <Stack>
                  <span style={{ fontSize: '13px', lineHeight: '20px', fontWeight: 400 }}>
                    {tFunction('appPreviewer.labels.myRewardPoints') as ReactNode}
                  </span>
                  <span style={{ fontSize: '25px', fontWeight: 700 }}>9500</span>
                </Stack>
              </Stack>
            </div>
          </Box>
        )}
        {currentStyle === WidgetViewStyle.Icon && (
          <div
            style={{
              height: '90px',
              width: '100%',
              background:
                widgetProps?.preferences?.assetColor ||
                (palette.primary
                  ? `linear-gradient(90deg, ${palette.primary}, ${alpha(palette.primary, 0.9)}, ${alpha(
                      palette.primary,
                      0.75,
                    )})`
                  : undefined),
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              color: 'white',
              padding: '8px 15px',
              paddingRight: 0,
              boxSizing: 'border-box',
              borderRadius: '8px',
              overflow: 'hidden',
            }}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ width: '100%', position: 'relative' }}
            >
              <Stack>
                <span style={{ fontSize: '13px', lineHeight: '20px', fontWeight: 400 }}>
                  {tFunction('appPreviewer.labels.myRewardPoints') as ReactNode}
                </span>
                <span style={{ fontSize: '25px', fontWeight: 700 }}>9500</span>
              </Stack>
              <CoinIcon
                htmlColor={
                  widgetProps?.preferences?.assetColor
                    ? darken(widgetProps?.preferences?.assetColor, 0.3)
                    : palette.primary
                }
                sx={{ position: 'absolute', top: '50%', transform: 'translateY(-50%)', right: '0px' }}
              />
            </Stack>
          </div>
        )}
      </PreviewContainer>
    );
  },
);

export default PreviewRewards;
