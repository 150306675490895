import { addBusinessDays, subBusinessDays } from 'date-fns';

const today = new Date();

export const mockDeals = [
  {
    id: 0,
    image: '/images/previewerDefaultImages/features/@2xnike.png',
    name: 'Nike Running Shoes',
    startDate: subBusinessDays(today, 2),
    endDate: addBusinessDays(today, 17),
    amountDiscount: 32.99,
    unlimitedUse: true,
  },
  {
    id: 1,
    image: '/images/previewerDefaultImages/features/@2xgarmin.png',
    name: 'Garmin',
    startDate: subBusinessDays(today, 5),
    endDate: addBusinessDays(today, 24),
    amountDiscount: 43.0,
    unlimitedUse: true,
  },
];
