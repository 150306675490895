import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const MainTabHeaderDefaultIcon18: React.FC<SvgIconProps> = ({ htmlColor = '#FFF', sx, ...props }) => {
  return (
    <SvgIcon viewBox="0 0 48 48" {...props} sx={{ width: '48px', height: '48px', ...sx }}>
      <g fill={htmlColor} fillRule="evenodd">
        <path d="M24 39c1.9 0 4.263-1.592 6.503-4.087l.406-.463.403-.483c.333-.41.662-.838.985-1.282l.384-.542.377-.556c.125-.188.248-.378.37-.57l.36-.584.351-.595.172-.302.336-.612.325-.62C36.777 24.755 38 20.782 38 17.137 38 9.335 31.737 3 24 3S10 9.335 10 17.138c0 3.645 1.223 7.618 3.028 11.165l.325.621.336.612.172.302.35.595.362.584c.121.192.244.382.369.57l.377.556.384.542c.323.444.652.873.985 1.282l.403.483.406.463C19.737 37.408 22.1 39 24 39zm0-4c-.14 0-.387-.115-.71-.33l-.255-.178-.281-.214-.305-.247-.324-.28-.342-.31-.176-.166-.363-.353-.372-.38-.38-.404-.385-.428a26.42 26.42 0 0 1-.194-.222l-.386-.46a25.39 25.39 0 0 1-.95-1.23C15.739 25.876 14 21.374 14 17.138 14 11.534 18.482 7 24 7s10 4.534 10 10.138c0 4.236-1.739 8.738-4.577 12.66-.31.427-.628.839-.95 1.23l-.386.46a26.42 26.42 0 0 1-.194.222l-.385.428-.38.404-.372.38-.363.353-.35.325-.168.151-.324.28-.305.247-.281.214-.256.179c-.322.214-.569.329-.709.329z" />
        <circle cx="24" cy="17" r="3" />
        <rect width="16" height="4" rx="2" opacity=".6" transform="translate(16 41)" />
      </g>
    </SvgIcon>
  );
};
