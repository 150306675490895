import React from 'react';
import { Navigate, RouteObject } from 'react-router';
import { pageUrls } from '@constants';

const Login = React.lazy(() => import(/* webpackChunkName: "Login" */ 'src/pages/LoginPage'));

const AdminDashboard = React.lazy(() => import(/* webpackChunkName: "AdminDashboard" */ 'src/pages/AdminDashboard'));

const AppsRoot = React.lazy(() => import(/* webpackChunkName: "AppsRoot" */ 'src/pages/AppsRoot'));
const AppByIdRoot = React.lazy(() => import(/* webpackChunkName: "AppByIdRoot" */ 'src/pages/AppByIdRoot'));
const MainClientScreen = React.lazy(
  () => import(/* webpackChunkName: "MainClientScreen" */ 'src/pages/MainClientScreen'),
);
const DemoPage = React.lazy(() => import(/* webpackChunkName: "DemoPage" */ 'src/pages/DemoPage'));

const AppDesignRoot = React.lazy(() => import(/* webpackChunkName: "AppDesignRoot" */ 'src/pages/AppDesignRoot'));
const AppDesign = React.lazy(() => import(/* webpackChunkName: "AppDesign" */ 'src/pages/AppDesign'));
const AppDesignReview = React.lazy(
  () => import(/* webpackChunkName: "AppDesignReview" */ 'src/pages/AppDesign/pages/AppDesignReview'),
);

const AppSettings = React.lazy(() => import(/* webpackChunkName: "AppSettings" */ 'src/pages/AppSettings'));

export type PrivateRouteObject = RouteObject & {
  isProtected?: boolean;
  role?: string;
  children?: PrivateRouteObject[];
};

const defaultAppPages: PrivateRouteObject[] = [
  {
    path: pageUrls.login,
    element: <Login />,
  },
  {
    path: pageUrls.adminDashboard,
    isProtected: true,
    role: 'ROLE_ADMIN',
    element: <AdminDashboard />,
  },
  {
    path: pageUrls.appsRoot,
    element: <AppsRoot />,
    children: [
      {
        path: pageUrls.appByUuidRoot(),
        element: <AppByIdRoot />,
        children: [
          {
            index: true,
            element: <MainClientScreen />,
          },
          {
            path: pageUrls.appMain(),
            element: <MainClientScreen />,
          },
          {
            path: pageUrls.appDemo(),
            element: <DemoPage />,
          },
          {
            path: pageUrls.appSettings(),
            element: <AppSettings />,
          },
          {
            path: pageUrls.appDesign(),
            element: <AppDesignRoot />,
            children: [
              {
                index: true,
                element: <AppDesign />,
              },
              {
                path: pageUrls.appDesignReview(),
                element: <AppDesignReview />,
              },
              {
                path: pageUrls.appDesignView(),
                element: <AppDesignReview readonly />,
              },
            ],
          },
          {
            path: `${pageUrls.appByUuidRoot()}/*`,
            element: <Navigate to="../main" />,
          },
        ],
      },
    ],
  },
  {
    path: '*',
    element: <Navigate to={pageUrls.adminDashboard} />,
  },
];

export default defaultAppPages;
