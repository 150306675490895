import React, { Dispatch, SetStateAction, useCallback } from 'react';
import { SxProps } from '@mui/system';
import { Icons } from '../../../../../../icons';
import { Stack } from '../../../../../../layout';
import { IconButton } from '../../../../../IconButton';
import { Slider } from '../../../../../Slider';

type Props = {
  wrapperSx?: SxProps;
  zoom: number;
  minZoom: number;
  maxZoom: number;
  zoomStep?: number;
  setZoom: Dispatch<SetStateAction<number>>;
};

const ZoomSlider: React.FC<Props> = ({ wrapperSx, zoom, minZoom, maxZoom, zoomStep = 0.1, setZoom }) => {
  const onZoomDecr = useCallback(() => {
    setZoom(prev => (prev <= minZoom ? prev : prev - zoomStep));
  }, [setZoom, minZoom, zoomStep]);

  const onZoomIncr = useCallback(() => {
    setZoom(prev => (prev >= maxZoom ? prev : prev + zoomStep));
  }, [setZoom, maxZoom, zoomStep]);

  return (
    <Stack direction="row" alignItems="center" sx={wrapperSx} spacing={2}>
      <IconButton aria-label="zoomout" onClick={onZoomDecr} size="small">
        <Icons.Remove />
      </IconButton>
      <Slider
        value={zoom}
        min={minZoom}
        max={maxZoom}
        step={zoomStep}
        aria-labelledby="Zoom"
        onChange={(e, changedZoom) => setZoom(Number(changedZoom))}
        sx={{ mt: 1 }}
      />
      <IconButton aria-label="zoomin" onClick={onZoomIncr} size="small">
        <Icons.Add />
      </IconButton>
    </Stack>
  );
};

export default ZoomSlider;
