import React, { MouseEventHandler } from 'react';
import { InputAdornment, TextField as MuiTextField, inputClasses, inputBaseClasses } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Icons } from '@egym/ui';

const TextFieldStyled = styled(MuiTextField)({
  [`${inputClasses.underline}:after`]: {
    borderBottomWidth: '1px',
  },
  [`${inputClasses.underline}.${inputClasses.root}.${inputBaseClasses.root}:before`]: {
    border: 'none',
  },
});

type Props = {
  value: any;
  onClick?: MouseEventHandler<any>;
  errorMessage?: string;
};

export const ReadOnlyErrorTextField: React.FC<Props> = ({ value, errorMessage, onClick }) => {
  return (
    <TextFieldStyled
      value={value}
      size="small"
      onClick={onClick}
      sx={{ fontSize: '0.875rem' }}
      InputProps={{
        style: { padding: 0 },
        readOnly: true,
        endAdornment: (
          <InputAdornment position="end">
            <Icons.ErrorOutline color="error" fontSize="inherit" />
          </InputAdornment>
        ),
      }}
      variant="standard"
      error
      helperText={errorMessage}
    />
  );
};
