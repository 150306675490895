import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const MainTabHeaderDefaultIcon2: React.FC<SvgIconProps> = ({ htmlColor = '#FFF', sx, ...props }) => {
  return (
    <SvgIcon viewBox="0 0 48 48" {...props} sx={{ width: '48px', height: '48px', ...sx }}>
      <g fill={htmlColor} fillRule="evenodd">
        <path d="M38 36H10c-1.103 0-2-.897-2-2V14c0-1.103.897-2 2-2h28c1.103 0 2 .897 2 2v4h-6a2 2 0 0 0 0 4h6v4h-6a2 2 0 0 0 0 4h6v4c0 1.103-.897 2-2 2m0-28H10c-3.309 0-6 2.691-6 6v20c0 3.309 2.691 6 6 6h28c3.309 0 6-2.691 6-6V14c0-3.309-2.691-6-6-6" />
        <path
          d="M20 24.2c-1.213 0-2.2-.987-2.2-2.2s.987-2.2 2.2-2.2 2.2.987 2.2 2.2-.987 2.2-2.2 2.2m8.653 4.803a9.959 9.959 0 0 0-3.67-3.666A5.96 5.96 0 0 0 26 22c0-3.309-2.691-6-6-6s-6 2.691-6 6a5.96 5.96 0 0 0 1.017 3.337 9.959 9.959 0 0 0-3.67 3.666 1.999 1.999 0 0 0 .736 2.73c.96.552 2.182.222 2.73-.736A5.998 5.998 0 0 1 20 28a5.998 5.998 0 0 1 5.187 2.997 1.996 1.996 0 0 0 2.73.736 1.999 1.999 0 0 0 .736-2.73"
          opacity=".6"
        />
      </g>
    </SvgIcon>
  );
};
