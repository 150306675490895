import arSA from 'date-fns/locale/ar-SA';
import bg from 'date-fns/locale/bg';
import ca from 'date-fns/locale/ca';
import da from 'date-fns/locale/da';
import de from 'date-fns/locale/de';
import el from 'date-fns/locale/el';
import enAU from 'date-fns/locale/en-AU';
import enCA from 'date-fns/locale/en-CA';
import enGB from 'date-fns/locale/en-GB';
import enUS from 'date-fns/locale/en-US';
import es from 'date-fns/locale/es';
import fi from 'date-fns/locale/fi';
import fr from 'date-fns/locale/fr';
import he from 'date-fns/locale/he';
import is from 'date-fns/locale/is';
import it from 'date-fns/locale/it';
import ja from 'date-fns/locale/ja';
import ko from 'date-fns/locale/ko';
import nb from 'date-fns/locale/nb';
import nl from 'date-fns/locale/nl';
import pl from 'date-fns/locale/pl';
import pt from 'date-fns/locale/pt';
import ro from 'date-fns/locale/ro';
import ru from 'date-fns/locale/ru';
import sv from 'date-fns/locale/sv';
import th from 'date-fns/locale/th';
import tr from 'date-fns/locale/tr';
import zhCN from 'date-fns/locale/zh-CN';
import zhTW from 'date-fns/locale/zh-TW';

export const dateLocaleMap = {
  'ar-AE': arSA,
  'ca-ES': ca,
  'da-DK': da,
  'de-DE': de,
  'en-AU': enAU,
  'en-CA': enCA,
  'en-GB': enGB,
  'en-US': enUS,
  'es-ES': es,
  'fi-FI': fi,
  'fr-FR': fr,
  'is-IS': is,
  'it-IT': it,
  'ja-JP': ja,
  'ko-KR': ko,
  'nb-NO': nb,
  'nl-NL': nl,
  'no-NO': nb,
  'pl-PL': pl,
  'pt-PT': pt,
  'ro-RO': ro,
  'ru-RU': ru,
  'sv-SE': sv,
  'tr-TR': tr,
  'zh-CN': zhCN,
  'zh-TW': zhTW,
  'th-TH': th,
  'he-IL': he,
  'bg-BG': bg,
  'el-GR': el,
  'es-MX': es,
};
