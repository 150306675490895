import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const IntelligentAssistantIcon: React.FC<SvgIconProps> = ({ sx, htmlColor = '#4C4A4A', ...rest }) => {
  return (
    <SvgIcon viewBox="0 0 24 24" sx={{ width: '20px', height: '20px', ...sx }} {...rest}>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <path d="M0 0H24V24H0z" transform="translate(-1820 -542) translate(1820 542)" />
            <path
              fill={htmlColor}
              d="M12 3c.553 0 1 .447 1 1v7c0 .553.447 1 1 1l.117-.007c.498-.057.883-.48.883-.993V8c0-.553.447-1 1-1 .553 0 1 .447 1 1v8c0 .553-.447 1-1 1-.553 0-1-.447-1-1v-1c0-.553-.447-1-1-1-.553 0-1 .447-1 1v5c0 .553-.447 1-1 1-.553 0-1-.447-1-1v-7c0-.553-.447-1-1-1-.553 0-1 .447-1 1v6c0 .553-.447 1-1 1-.553 0-1-.447-1-1V9c0-.553.447-1 1-1 .553 0 1 .447 1 1 0 .553.447 1 1 1 .553 0 1-.447 1-1V4c0-.553.447-1 1-1zm-8 7c.553 0 1 .447 1 1v4c0 .553-.447 1-1 1l-.117-.007C3.385 15.936 3 15.513 3 15v-4c0-.553.447-1 1-1zm16-2c.553 0 1 .447 1 1v2c0 .553-.447 1-1 1-.553 0-1-.447-1-1V9c0-.553.447-1 1-1z"
              transform="translate(-1820 -542) translate(1820 542)"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export default IntelligentAssistantIcon;
