import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const MainTabHeaderDefaultIcon31: React.FC<SvgIconProps> = ({ htmlColor = '#FFF', sx, ...props }) => {
  return (
    <SvgIcon viewBox="0 0 140 180" {...props} sx={{ width: '140px', height: '180px', paddingLeft: '4.5px', ...sx }}>
      <g stroke="none" fill="none" fillRule="evenodd">
        <path
          d="M109.380871,86.7228712 C115.233783,86.2302334 120.371507,83.8061878 124.675732,79.489401 C139.469234,64.6529823 139.019756,31.5875313 138.993572,30.1874278 C138.945085,27.5872356 136.790791,25.5045041 134.148713,25.5025947 L109.774588,25.4868417 L109.774588,15.3868113 C109.774588,14.0683367 108.688956,13 107.350219,13 L33.6493907,13 C32.310654,13 31.2250214,14.0683367 31.2250214,15.3868113 L31.2250214,25.485887 L6.85138173,25.5021173 C4.20978889,25.5040268 2.05549429,27.5867583 2.00652203,30.1869505 C1.97985397,31.5875313 1.53086076,64.6525049 16.3243625,79.4889237 C20.6285879,83.8057105 25.7667963,86.229756 31.6192239,86.7223938 C33.8578865,102.152174 45.5986224,114.597962 60.8028123,118.124237 L60.8028123,148.166076 L48.5195026,148.166076 C47.7267338,148.166076 46.9843919,148.547488 46.5310348,149.187631 L35.8638097,164.247933 C35.3469342,164.977342 35.2853552,165.929203 35.7033165,166.717805 C36.1207929,167.50593 36.9494423,168 37.8527623,168 C37.8527623,168 103.154605,168 103.158484,168 C104.497221,168 105.582854,166.931663 105.582854,165.613189 C105.582854,165.03558 105.337023,164.565379 105.027673,164.093267 L94.4700294,149.187154 C94.0166724,148.547011 93.2743305,148.165599 92.4815617,148.165599 L80.198252,148.165599 L80.198252,118.124715 C95.4014721,114.597962 107.142208,102.152651 109.380871,86.7228712 Z M128,37.0128823 C127.566898,47.5729995 125.220516,66.2656428 117.386975,74.7488526 C115.22281,77.0919935 112.788012,78.4937747 110,79 L110,37 L128,37.0128823 Z M13,37.0124053 L31,37 L31,79 C28.213607,78.493769 25.7809562,77.0957888 23.6181011,74.7564384 C15.7980647,66.2984198 13.4412799,47.5836164 13,37.0124053 Z M49,50.1325052 L63.5105469,48.1795031 L70,36 L76.4894531,48.1795031 L91,50.1325052 L80.5,59.6130158 L82.9789062,73 L70.0005068,66.6795031 L57.0221074,73 L59.5010137,59.6130158 L49,50.1325052 Z"
          fill={htmlColor}
          opacity=".6"
        />
      </g>
    </SvgIcon>
  );
};
