import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const IllustrationWebWidgetLayoutChangeFromVerticalToHorizontal: React.FC<SvgIconProps> = ({ sx, ...props }) => {
  return (
    <SvgIcon
      viewBox="0 0 457 189"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
      sx={{ width: '457px', height: '189px', ...sx }}
    >
      <defs>
        <path id="lfd6oo11ka" d="M0 0h457v189H0z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="6c2ffkbjwb" fill="#fff">
          <use xlinkHref="#lfd6oo11ka" />
        </mask>
        <g mask="url(#6c2ffkbjwb)">
          <g transform="translate(268)">
            <rect stroke="#9E9E9E" strokeWidth="5" fill="#FFF" x="2.5" y="2.5" width="184" height="215" rx="22" />
            <rect stroke="#EDEDED" strokeWidth="6" x="21" y="176" width="147" height="50" rx="4" />
            <g transform="translate(18 42)">
              <rect fill="#CECECE" width="72" height="92" rx="4" />
              <circle fill="#9E9E9E" cx="25.5" cy="25.5" r="11.5" />
              <rect fill="#EDEDED" x="14" y="60" width="16.552" height="6" rx="3" />
              <rect fill="#EDEDED" x="14" y="72" width="32" height="6" rx="3" />
            </g>
            <g transform="translate(99 42)">
              <rect stroke="#EDEDED" strokeWidth="6" x="3" y="3" width="66" height="86" rx="4" />
              <circle fill="#EDEDED" cx="23.5" cy="25.5" r="11.5" />
              <rect fill="#EDEDED" x="12" y="60" width="28.087" height="6" rx="3" />
              <rect fill="#EDEDED" x="12" y="72" width="38" height="6" rx="3" />
            </g>
            <rect fill="#EDEDED" x="18" y="20" width="101" height="10" rx="5" />
            <rect fill="#EDEDED" x="18" y="151" width="51" height="10" rx="5" />
          </g>
          <rect stroke="#9E9E9E" strokeWidth="5" fill="#FFF" x="2.5" y="2.5" width="184" height="215" rx="22" />
          <g transform="translate(18 107)">
            <rect stroke="#EDEDED" strokeWidth="6" x="3" y="3" width="147" height="50" rx="4" />
            <rect stroke="#EDEDED" strokeWidth="6" x="3" y="68" width="147" height="50" rx="4" />
            <rect fill="#EDEDED" x="12" y="21" width="41.391" height="6" rx="3" />
            <rect fill="#EDEDED" x="12" y="33" width="56" height="6" rx="3" />
            <circle fill="#EDEDED" cx="126.5" cy="25.5" r="11.5" />
          </g>
          <g transform="translate(18 42)">
            <rect fill="#CECECE" width="153" height="56" rx="4" />
            <circle fill="#9E9E9E" cx="126.5" cy="28.5" r="11.5" />
            <g transform="translate(12 19)" fill="#EDEDED">
              <rect width="41.391" height="6" rx="3" />
              <rect y="12" width="56" height="6" rx="3" />
            </g>
          </g>
          <rect fill="#EDEDED" x="18" y="20" width="101" height="10" rx="5" />
          <path
            d="m248 81.286 14 7-14 7v-14zm-16.272 5.66a4.97 4.97 0 0 0 1.472.329l.307.011h.757v2h-.771a6.994 6.994 0 0 1-2.194-.368L231 88.81l.727-1.864zm8.536.34v2h-3v-2h3zm6 0v2h-3v-2h3zm-17.639-4.097c.135.743.437 1.44.88 2.044l.171.222-1.546 1.269a6.98 6.98 0 0 1-1.407-2.86l-.066-.317 1.968-.358zm-2.045-5.824 2 .015-.022 3-2-.015.022-3zm-24.632-13.888a7.002 7.002 0 0 1 6.973 5.948l2.066-.015.014 2-2.065.016A7.002 7.002 0 0 1 195 70.53a7 7 0 0 1 6.948-7.052zm.015 2a5 5 0 1 0 .074 10 5 5 0 0 0-.074-10zm24.409 5.745a7.006 7.006 0 0 1 1.77 2.649l.107.305-1.903.615a4.995 4.995 0 0 0-1.141-1.911l-.2-.198 1.367-1.46zm-4.837-1.89a7.03 7.03 0 0 1 1.352.12l.33.07-.464 1.946a4.992 4.992 0 0 0-.897-.13l-.306-.006-1.549.011-.015-2 1.55-.011zm-4.549.033.015 2-3 .023-.014-2 3-.023z"
            fill="#C75300"
            fillRule="nonzero"
          />
        </g>
      </g>
    </SvgIcon>
  );
};
