import { useRecoilState } from 'recoil';
import { adminDashboardState } from './state';

const useAdminDashboardState = () => {
  const [selectedTab, setSelectedTab] = useRecoilState(adminDashboardState.atoms.selectedTab);
  return {
    selectedTab,
    setSelectedTab,
  };
};

export default useAdminDashboardState;
