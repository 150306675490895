import React from 'react';
import Header from './Header';
import { HeaderContainerProps } from './HeaderProps';
import useHeader from './hooks/useHeader';

const HeaderContainer: React.FC<HeaderContainerProps> = props => {
  const headerProps = useHeader();

  return <Header {...props} {...headerProps} />;
};

export default HeaderContainer;
