import React, { FC } from 'react';
import useWidgetIconWithBackgroundColorField from './hooks/useWidgetIconWIthBackgroundColorField';
import WidgetIconWithBackgroundColorField from './WidgetIconWithBackgroundColorField';
import { WidgetIconWithBackgroundColorFieldContainerProps } from './WidgetIconWithBackgroundColorFieldProps';

const WidgetIconWithBackgroundColorFieldContainer: FC<WidgetIconWithBackgroundColorFieldContainerProps> = props => {
  const result = useWidgetIconWithBackgroundColorField(props);
  return <WidgetIconWithBackgroundColorField {...props} {...result} />;
};

export default WidgetIconWithBackgroundColorFieldContainer;
