import React, { forwardRef, ReactNode } from 'react';
import { Stack, useFormatDate } from '@egym/ui';
import { AppLayoutFeatureComponentProps } from '../../../../AppPreviewerProps';
import { IconWrapper, IntelligentAssistantIcon, ShareIcon } from '../../../Icons';
import PreviewContainer from '../../../PreviewContainer';
import StatsCarousel from './StatsCarousel';

const PreviewBioAgeOnTheTab = forwardRef<HTMLDivElement, AppLayoutFeatureComponentProps>(
  ({ tFunction, palette, previewerState, wrapperSx }, ref) => {
    const { format } = useFormatDate(previewerState.selectedLocale);

    return (
      <div ref={ref} style={{ position: 'relative', height: '466px' }}>
        <PreviewContainer wrapperSx={wrapperSx}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ marginTop: '5px' }}>
            <IconWrapper bgColor={palette.primaryTransparent}>
              <IntelligentAssistantIcon htmlColor={palette.primary} />
            </IconWrapper>
            <IconWrapper bgColor={palette.primaryTransparent}>
              <ShareIcon htmlColor={palette.primary} />
            </IconWrapper>
          </Stack>
        </PreviewContainer>
        <div
          style={{
            position: 'absolute',
            top: '0px',
            width: '100%',
            height: '476px',
            background:
              'url(/images/previewerDefaultImages/features/analysis/@2xBio-age_male.png) no-repeat center top',
            backgroundSize: '95% 110%',
          }}
        />
        <Stack
          alignItems="center"
          sx={{
            position: 'absolute',
            bottom: '-20px',
            left: 0,
            background:
              'linear-gradient(0deg, rgb(237, 238, 240), rgba(246, 247, 250, 0.9), rgba(246, 247, 250, 0.8), rgba(249, 249, 250, 0.0))',
            width: '100%',
            height: '385px',
          }}
        >
          <Stack
            alignItems="center"
            justifyContent="center"
            style={{
              width: '149px',
              height: '149px',
              border: '2px solid rgba(255, 255, 255, 0.35)',
              borderRadius: '50%',
            }}
          >
            <Stack
              alignItems="center"
              justifyContent="center"
              style={{
                width: '131px',
                height: '131px',
                border: '2px solid rgba(255, 255, 255, 0.35)',
                borderRadius: '50%',
              }}
            >
              <Stack
                alignItems="center"
                justifyContent="center"
                style={{
                  width: '115px',
                  height: '115px',
                  borderRadius: '50%',
                  background: 'white',
                  boxShadow: '0 5px 10px rgba(0, 0, 0, 0.1)',
                }}
              >
                <span
                  style={{
                    display: 'inline-block',
                    maxWidth: '85px',
                    textAlign: 'center',
                    color: 'rgb(76, 74, 74)',
                    fontSize: '11px',
                    lineHeight: '18px',
                    marginBottom: '6px',
                  }}
                >
                  {tFunction('appPreviewer.labels.yourBioAge') as ReactNode}
                </span>
                <span
                  style={{
                    color: 'rgb(37, 37, 37)',
                    fontSize: '25px',
                    fontWeight: 700,
                    letterSpacing: '-0.5px',
                    marginBottom: '1px',
                  }}
                >
                  32
                </span>
                <span
                  style={{
                    color: 'rgb(33, 33, 33)',
                    fontSize: '14px',
                    fontWeight: 500,
                    letterSpacing: '-0.5px',
                    lineHeight: '18px',
                    textTransform: 'lowercase',
                  }}
                >
                  {tFunction('appPreviewer.labels.year', { count: 32 }) as ReactNode}
                </span>
              </Stack>
            </Stack>
          </Stack>
          <span style={{ marginTop: '-10px', marginBottom: '15px', fontSize: '11px', color: 'rgb(76, 74, 74)' }}>
            {tFunction('appPreviewer.labels.updatedOn', { dateString: format(new Date(), 'PP') }) as ReactNode}
          </span>
          <StatsCarousel tFunction={tFunction} palette={palette} previewerState={previewerState} />
        </Stack>
      </div>
    );
  },
);

export default PreviewBioAgeOnTheTab;
