import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const ScanIcon: React.FC<SvgIconProps> = ({ sx, htmlColor = '#4C4A4A', ...rest }) => {
  return (
    <SvgIcon viewBox="0 0 24 24" sx={{ width: '20px', height: '20px', ...sx }} {...rest}>
      <defs>
        <filter id="yejckqtyla" colorInterpolationFilters="auto">
          <feColorMatrix
            in="SourceGraphic"
            values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0"
          />
        </filter>
        <path id="k6kny6g9pb" d="M0 20L20 20 20 0 0 0z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g filter="url(#yejckqtyla)" transform="translate(-323.000000, -68.000000) translate(315.000000, 60.000000)">
            <g transform="translate(10.000000, 10.000000)">
              <path
                stroke={htmlColor}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M1 15v3c0 .553.447 1 1 1h3M19 5V2c0-.553-.447-1-1-1h-3M15 19h3c.553 0 1-.447 1-1v-3M5 1H2c-.553 0-1 .447-1 1v3"
              />
              <mask id="3cikna2h8c" fill={htmlColor}>
                <path id="k6kny6g9pb" d="M0 20L20 20 20 0 0 0z" />
              </mask>
              <path
                fill={htmlColor}
                d="M4.125 14L5.875 14 5.875 6 4.125 6zM14.125 14L15.875 14 15.875 6 14.125 6zM11.063 14L11.938 14 11.938 6 11.063 6zM7.031 14L7.469 14 7.469 6 7.031 6zM10.031 14L10.469 14 10.469 6 10.031 6zM13.031 14L13.469 14 13.469 6 13.031 6zM8 14L9 14 9 6 8 6z"
                mask="url(#3cikna2h8c)"
              />
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export default ScanIcon;
