import React, { ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Typography, Card } from '@mui/material';
import { SxProps } from '@mui/system';
import { StyledLinearProgress } from './ProgressBar.styles';

export type ProgressBarProps = {
  wrapperSx?: SxProps;
  doneStepsCount: number;
  allStepsCount: number;
};

const ProgressBar: React.FC<ProgressBarProps> = ({ wrapperSx, doneStepsCount, allStepsCount }) => {
  const { t } = useTranslation();
  const value = useMemo(() => {
    return (doneStepsCount / allStepsCount) * 100;
  }, [doneStepsCount, allStepsCount]);

  return (
    <Card
      sx={{
        width: '100%',
        boxShadow: 'none',
        backgroundColor: '#FAFAFA',
        borderRadius: '12px',
        paddingX: 6,
        paddingY: 3,
        ...wrapperSx,
      }}
    >
      <Stack sx={{ width: '350px', height: '100%', justifyContent: 'center' }} spacing={3}>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="body2">
            {t(doneStepsCount === allStepsCount ? 'common.labels.done' : 'common.labels.progress') as ReactNode}
          </Typography>
          <Typography variant="body2" color="gray">{`${doneStepsCount}/${allStepsCount}`}</Typography>
        </Stack>
        <Stack
          sx={{ width: '100%', height: '4px', position: 'relative' }}
          alignItems="center"
          direction="row"
          alignSelf="center"
        >
          <StyledLinearProgress
            sx={{
              width: '100%',
              height: '4px',
              '::before': { backgroundColor: '#ccc', opacity: 1 },
            }}
            variant="determinate"
            value={value}
            realValue={value}
            color="inherit"
          />
        </Stack>
      </Stack>
    </Card>
  );
};

export default ProgressBar;
