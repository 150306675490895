import { forwardRef } from 'react';
import useRewardsWidgetPreferencesForm from './hooks/useRewardsWidgetPreferencesForm';
import RewardsWidgetPreferencesForm from './RewardsWidgetPreferencesForm';
import { RewardsWidgetPreferencesFormContainerProps } from './RewardsWidgetPreferencesFormProps';

const RewardsWidgetPreferencesFormContainer = forwardRef<any, RewardsWidgetPreferencesFormContainerProps>(
  (props, ref) => {
    const result = useRewardsWidgetPreferencesForm(props, ref);
    return <RewardsWidgetPreferencesForm {...props} {...result} />;
  },
);

export default RewardsWidgetPreferencesFormContainer;
