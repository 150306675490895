import React, { forwardRef } from 'react';
import { WidgetViewStyle } from '@types';
import { AppLayoutFeatureComponentProps } from '../../../../AppPreviewerProps';
import PreviewBasicWidget from '../PreviewBasicWidget';

const PreviewExtras = forwardRef<HTMLDivElement, AppLayoutFeatureComponentProps>(
  ({ tFunction, palette, widgetProps, previewerState, wrapperSx }, ref) => {
    return (
      <PreviewBasicWidget
        palette={palette}
        wrapperSx={wrapperSx}
        previewerState={previewerState}
        widgetProps={widgetProps}
        tFunction={tFunction}
        defaultName="appPreviewer.labels.defaultFeaturesTitles.news"
        defaultCallToAction="appPreviewer.labels.stayUpdated"
        defaultViewStyle={WidgetViewStyle.Icon}
        defaultImagePath="/images/previewerDefaultImages/widgets/icons/widgetIcon11.svg"
        ref={ref}
      />
    );
  },
);

export default PreviewExtras;
