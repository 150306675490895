import React from 'react';
import { IconButton, InputAdornment, Stack, TableCell as MuiTableCell } from '@mui/material';
import { SelectField, TextField, ReadOnlyErrorTextField } from '@egym/ui';
import { Loader } from '../../../../feedback';
import { Icons } from '../../../../icons';
import { ConditionalRender } from '../../../../utils';
import { wrapperStyles } from './TableBodyEditableCell.styles';
import { TableBodyEditableCellProps } from './TableBodyEditableCellProps';

const TableBodyEditableCell: React.FC<TableBodyEditableCellProps> = ({
  wrappedSx,
  column,
  row,
  rowIndex,
  tdProps,
  isEditing,
  toggleIsEditing,
  onChangeValue,
  editValue,
  submit,
  cancel,
  id,
  onInputKeyPress,
  isSubmitting,
  onInputBlur,
  errorMessage,
  options,
}) => {
  const value = column.accessor ? column.accessor({ value: editValue }) : editValue;
  const bodyCellSx = typeof column.bodyCellSx === 'function' ? column.bodyCellSx({ row, value }) : column.bodyCellSx;
  return (
    <MuiTableCell
      variant="body"
      scope="row"
      padding="normal"
      width={column.width}
      align={column.numeric ? 'right' : 'left'}
      sx={{ ...wrappedSx, ...wrapperStyles(isEditing, errorMessage, id), ...bodyCellSx }}
      data-testid={`table-body-cell-${column.field}-${rowIndex}`}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <ConditionalRender condition={isEditing}>
          {options && column.toOption ? (
            <SelectField
              variant="standard"
              color="primary"
              options={options}
              value={editValue?.value || ''}
              size="small"
              sx={{ fontSize: '0.875rem' }}
              onChange={e =>
                onChangeValue({
                  ...e,
                  target: { value: options?.find(option => option.value === e.target.value) },
                })
              }
              onBlur={onInputBlur}
              onKeyPress={onInputKeyPress}
              inputProps={column.tdProps?.editableFieldInputProps?.inputProps}
              InputProps={{
                style: { padding: 0 },
                endAdornment: errorMessage ? (
                  <InputAdornment position="end">
                    <Icons.ErrorOutline color="error" fontSize="inherit" />
                  </InputAdornment>
                ) : null,
              }}
              helperText={errorMessage}
              error={!!errorMessage}
            />
          ) : (
            <TextField
              value={editValue || ''}
              onChange={onChangeValue}
              size="small"
              onBlur={onInputBlur}
              sx={{ fontSize: '0.875rem' }}
              onKeyPress={onInputKeyPress}
              inputProps={column.tdProps?.editableFieldInputProps?.inputProps}
              InputProps={{
                style: { padding: 0 },
                endAdornment: errorMessage ? (
                  <InputAdornment position="end">
                    <Icons.ErrorOutline color="error" fontSize="inherit" />
                  </InputAdornment>
                ) : null,
              }}
              autoFocus
              variant="standard"
              helperText={errorMessage}
              error={!!errorMessage}
              onFocus={event => event.target.select()}
            />
          )}
          <Stack direction="row" alignItems="center" justifyContent="center">
            {column.renderEditableCell ? (
              column.renderEditableCell({
                row,
                value,
                rawValue: editValue,
                tdProps: { ...tdProps, ...column.tdProps },
                isEditing,
                errorMessage,
                toggleIsEditing,
              })
            ) : (
              <ConditionalRender condition={!!errorMessage}>
                <ReadOnlyErrorTextField value={editValue} errorMessage={errorMessage} onClick={toggleIsEditing} />
                {value || '-'}
              </ConditionalRender>
            )}
          </Stack>
        </ConditionalRender>
        <Stack id={id} sx={{ visibility: 'hidden', pl: 1 }} alignItems="center" justifyContent="center">
          <ConditionalRender condition={isSubmitting}>
            <Loader sx={{ p: 1.5 }} size={16} thickness={6} disableShrink />
            <ConditionalRender condition={isEditing}>
              <Stack direction="row" alignItems="center" spacing={1}>
                <ConditionalRender condition={!errorMessage}>
                  <IconButton id={`${id}_save`} onClick={submit} size="small">
                    <Icons.Done fontSize="inherit" color="primary" />
                  </IconButton>
                </ConditionalRender>
                <IconButton id={`${id}_cancel`} onClick={cancel} size="small">
                  <Icons.Close fontSize="inherit" color="primary" />
                </IconButton>
              </Stack>
              <IconButton onClick={toggleIsEditing} size="small">
                <Icons.Edit fontSize="inherit" color="primary" />
              </IconButton>
            </ConditionalRender>
          </ConditionalRender>
        </Stack>
      </Stack>
    </MuiTableCell>
  );
};

export default TableBodyEditableCell;
