import { ApplicationLayout, ApplicationPackage } from '@types';
import AccountSettingsIcon from '../Icons/AccountSettingsIcon';
import ConnectedAppsIcon from '../Icons/ConnectedAppsIcon';
import EditProfileIcon from '../Icons/EditProfileIcon';
import FaqAndSupportIcon from '../Icons/FaqAndSupportIcon';
import HistoryIcon from '../Icons/HistoryIcon';
import MyAccountIcon from '../Icons/MyAccountIcon';
import ShareFeedbackIcon from '../Icons/ShareFeedbackIcon';
import { ProfileFeature } from './PreviewerProfileRouteProps';

export const profileFeatures = {
  [ApplicationPackage.Basic]: {
    [ApplicationLayout.Workouts]: [
      ProfileFeature.EDIT_PROFILE,
      ProfileFeature.SETTINGS,
      ProfileFeature.FAQ_AND_SUPPORT,
    ],
  },
  [ApplicationPackage.Advanced]: {
    [ApplicationLayout.Community]: [
      ProfileFeature.EDIT_PROFILE,
      ProfileFeature.SETTINGS,
      ProfileFeature.FAQ_AND_SUPPORT,
      ProfileFeature.CONNECTED_APPS,
      ProfileFeature.MY_ACCOUNT,
      ProfileFeature.CHECKIN_HISTORY,
    ],
    [ApplicationLayout.WorkoutsAndProgress]: [
      ProfileFeature.EDIT_PROFILE,
      ProfileFeature.SETTINGS,
      ProfileFeature.FAQ_AND_SUPPORT,
      ProfileFeature.CONNECTED_APPS,
      ProfileFeature.MY_ACCOUNT,
      ProfileFeature.CHECKIN_HISTORY,
    ],
    [ApplicationLayout.ClubAndClasses]: [
      ProfileFeature.EDIT_PROFILE,
      ProfileFeature.SETTINGS,
      ProfileFeature.FAQ_AND_SUPPORT,
      ProfileFeature.CONNECTED_APPS,
      ProfileFeature.MY_ACCOUNT,
      ProfileFeature.CHECKIN_HISTORY,
    ],
  },
  [ApplicationPackage.Pro]: {
    [ApplicationLayout.Community]: [
      ProfileFeature.EDIT_PROFILE,
      ProfileFeature.SETTINGS,
      ProfileFeature.FAQ_AND_SUPPORT,
      ProfileFeature.CONNECTED_APPS,
      ProfileFeature.MY_ACCOUNT,
      ProfileFeature.CHECKIN_HISTORY,
      ProfileFeature.MOBILE_FEEDBACK,
    ],
    [ApplicationLayout.WorkoutsAndProgress]: [
      ProfileFeature.EDIT_PROFILE,
      ProfileFeature.SETTINGS,
      ProfileFeature.FAQ_AND_SUPPORT,
      ProfileFeature.CONNECTED_APPS,
      ProfileFeature.MY_ACCOUNT,
      ProfileFeature.CHECKIN_HISTORY,
      ProfileFeature.MOBILE_FEEDBACK,
    ],
    [ApplicationLayout.ClubAndClasses]: [
      ProfileFeature.EDIT_PROFILE,
      ProfileFeature.SETTINGS,
      ProfileFeature.FAQ_AND_SUPPORT,
      ProfileFeature.CONNECTED_APPS,
      ProfileFeature.MY_ACCOUNT,
      ProfileFeature.CHECKIN_HISTORY,
      ProfileFeature.MOBILE_FEEDBACK,
    ],
    [ApplicationLayout.ClubAndPerks]: [
      ProfileFeature.EDIT_PROFILE,
      ProfileFeature.SETTINGS,
      ProfileFeature.FAQ_AND_SUPPORT,
      ProfileFeature.CONNECTED_APPS,
      ProfileFeature.MY_ACCOUNT,
      ProfileFeature.CHECKIN_HISTORY,
      ProfileFeature.MOBILE_FEEDBACK,
    ],
  },
};

export const profileMenuItemsConfig = {
  [ProfileFeature.EDIT_PROFILE]: {
    icon: EditProfileIcon,
    label: 'appPreviewer.labels.editProfile',
  },
  [ProfileFeature.SETTINGS]: {
    icon: AccountSettingsIcon,
    label: 'appPreviewer.labels.settings',
  },
  [ProfileFeature.FAQ_AND_SUPPORT]: {
    icon: FaqAndSupportIcon,
    label: 'appPreviewer.labels.faqAndSupport',
  },
  [ProfileFeature.CONNECTED_APPS]: {
    icon: ConnectedAppsIcon,
    label: 'appPreviewer.labels.connectedApps',
  },
  [ProfileFeature.MY_ACCOUNT]: {
    icon: MyAccountIcon,
    label: 'appPreviewer.labels.myAccount',
  },
  [ProfileFeature.CHECKIN_HISTORY]: {
    icon: HistoryIcon,
    label: 'appPreviewer.labels.checkinHistory',
  },
  [ProfileFeature.MOBILE_FEEDBACK]: {
    icon: ShareFeedbackIcon,
    label: 'appPreviewer.labels.shareFeedback',
  },
};
