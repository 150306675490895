import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const AccountSettingsIcon: React.FC<SvgIconProps> = ({ sx, htmlColor = '#4C4A4A', ...rest }) => {
  return (
    <SvgIcon viewBox="0 0 24 24" sx={{ width: '24px', height: '24px', ...sx }} {...rest}>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <g>
                <path
                  d="M0 0L24 0 24 24 0 24z"
                  transform="translate(-24 -374) translate(0 290) translate(20 80) translate(4 4)"
                />
                <path
                  fill={htmlColor}
                  d="M4.985 17.612L15.218 7.378l1.405 1.404L6.388 19.015H4.985v-1.403zM17.323 5.274c.385-.386 1.016-.387 1.404-.001.386.388.386 1.018 0 1.404l-.701.702-1.404-1.405.701-.7zm2.545 13.741H9.194l9.533-9.531 1.404-1.403c1.16-1.161 1.16-3.05 0-4.211v-.001c-1.163-1.16-3.052-1.157-4.21 0l-1.405 1.404L3.291 16.5c-.194.194-.291.448-.291.702v2.806c0 .548.444.992.992.992h15.876c.549 0 .993-.444.993-.992 0-.549-.444-.993-.993-.993z"
                  transform="translate(-24 -374) translate(0 290) translate(20 80) translate(4 4)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export default AccountSettingsIcon;
