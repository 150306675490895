import { styled } from '@mui/material/styles';
import { Tab, tabClasses } from '@egym/ui';

export const StyledTab = styled(Tab, { shouldForwardProp: propName => propName !== 'primaryColor' })<{
  primaryColor?: string;
}>`
  &.${tabClasses.root} {
    text-transform: none;
    min-width: 0;
    min-height: 24px;
    font-weight: 500;
    margin-right: 0;
    color: ${props => props.primaryColor};
    padding: 0;
    font-size: 11px;
    font-family: '-apple-system', BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif,
      'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  }

  &.${tabClasses.selected} {
    box-shadow: 0 3px 1px rgba(0, 0, 0, 0.04);
    color: white !important;
    background: ${props => props.primaryColor};
    border-radius: 8px;
    font-weight: 500;
  }
`;
