import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Uuid } from '@egym/types';
import {
  deleteCustomSignInBackgroundImage,
  getCustomSignInBackgroundImages,
  postSignInBackgroundImageCustom,
} from '@api';
import { getGStoreResource } from '@helpers';

export const customSignInImagesQueryKeys = {
  all: (appUuid: Uuid) => ['customSignInImages', String(appUuid)] as const,
};

const useCustomSignInImages = (appUuid: Uuid) => {
  const queryClient = useQueryClient();

  const customSignInImages = useQuery(
    customSignInImagesQueryKeys.all(appUuid),
    async () => {
      return getCustomSignInBackgroundImages({ urlParams: { applicationUuid: appUuid } });
    },
    {
      enabled: Boolean(appUuid),
      retryOnMount: false,
      refetchOnMount: false,
      keepPreviousData: true,
      select: result => result.data.map(it => ({ ...it, link: getGStoreResource(it.link) })),
    },
  );

  const uploadSignInBackgroundImageCustom = useMutation(
    async (file: File) => {
      const formData = new FormData();
      formData.append('image', file, 'sign_in_image.png');
      return postSignInBackgroundImageCustom({
        payload: formData,
        urlParams: { applicationUuid: appUuid },
      });
    },
    {
      onSuccess: async response =>
        queryClient.setQueryData(customSignInImagesQueryKeys.all(appUuid), (prevData: any) => ({
          ...prevData,
          data: [response.data, ...prevData.data],
        })),
    },
  );

  const removeCustomSignInBackgroundImage = useMutation(
    async (id: string) => {
      return deleteCustomSignInBackgroundImage({
        urlParams: { applicationUuid: appUuid, id: `${id}` },
      });
    },
    {
      onSuccess: async (response, id) => {
        queryClient.setQueryData(customSignInImagesQueryKeys.all(appUuid), (prevData: any) => ({
          ...prevData,
          data: prevData.data.filter(it => it.id !== id),
        }));
      },
    },
  );

  return {
    uploadSignInBackgroundImageCustom,
    customSignInImages,
    removeCustomSignInBackgroundImage,
  };
};

export default useCustomSignInImages;
