import React, { FC, ReactNode } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Field } from 'formik';
import { ColorPickerFormField, FileUploadFormField, Stack, Typography } from '@egym/ui';
import { FileFormatName, getBytesFromMb } from '@egym/utils';
import { PredefinedIconsLibraryPopover } from '@components';
import ColorContrastAlert from 'src/pages/AppDesign/components/AppDesignForm/components/ColorContrastAlert';
import ColorContrastBadgeContent from 'src/pages/AppDesign/components/AppDesignForm/components/ColorContrastBadgeContent';
import { WidgetIconWithBackgroundColorFieldProps } from './WidgetIconWithBackgroundColorFieldProps';

const WidgetIconWithBackgroundColorField: FC<WidgetIconWithBackgroundColorFieldProps> = ({
  highContrastWarning,
  showHighContrastWarning,
  setShowHighContrastWarning,
  onCustomIconPick,
  backgroundColor,
  presetColors,
  setColorPickerOpened,
  onSelectPredefinedIcon,
  predefinedIcons,
  iconPredefinedId,
  setHighContrastWarning,
}) => {
  const { t } = useTranslation();

  return (
    <Stack spacing={5}>
      <Typography variant="h5Bold">{t('common.labels.icon') as ReactNode}</Typography>
      {highContrastWarning?.primary && highContrastWarning?.source === 'widget' && showHighContrastWarning && (
        <ColorContrastAlert
          message={
            <Trans
              i18nKey="appDesign.validation.backgroundFillColorIsTooLight"
              components={[<Typography component="span" variant="body1Bold" />]}
            />
          }
          onClose={() => setShowHighContrastWarning(false)}
          wrapperSx={{ my: 4 }}
        />
      )}
      <Field
        name="icon"
        component={FileUploadFormField}
        accept={[FileFormatName.SVG]}
        onFilePick={onCustomIconPick}
        acceptHints={{
          [FileFormatName.SVG]: [{ tKey: 'common.fileUpload.vectorFile' }],
        }}
        acceptHintsCommon={[{ tKey: 'common.fileUpload.squareLayoutOnly' }]}
        maxSize={getBytesFromMb(2)}
        wrapperSx={{ mt: 10 }}
        uploadPaneProps={{
          justifyContent: 'space-between',
          spacing: 6,
        }}
        previewImageSx={{
          backgroundColor: backgroundColor || 'rgb(76, 74, 74)',
          height: '90px',
          '& > svg': {
            height: 'auto',
            width: 'auto',
            maxHeight: '50px',
          },
        }}
        previewWrapperSx={{
          height: '100%',
          width: '100%',
          justifyContent: 'flex-start',
          alignItems: 'baseline',
          p: 0,
        }}
        uploadPaneWrapperSx={{
          minHeight: '300px',
          '& > *': {
            width: '50%',
            height: '100%',
            flex: 1,
          },
        }}
        uploadImageTool={
          <Stack spacing={8} sx={{ ml: 5, mt: 8, mb: 7 }}>
            <Field
              name="backgroundColor"
              component={ColorPickerFormField}
              fieldLabel="appDesign.labels.iconBackgroundColor"
              presetColors={presetColors}
              colorPickerFieldAnchorSx={{ p: 0 }}
              onPopoverOpen={() => setColorPickerOpened(true)}
              onPopoverClose={() => setColorPickerOpened(false)}
              badgeProps={
                highContrastWarning?.primary &&
                (highContrastWarning?.source === 'colorTheme' || !showHighContrastWarning) && {
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right',
                  },
                  badgeContent: (
                    <ColorContrastBadgeContent
                      onClick={() => {
                        setShowHighContrastWarning(true);
                        setHighContrastWarning({ ...highContrastWarning, source: 'widget' });
                      }}
                    />
                  ),
                }
              }
            />
            <PredefinedIconsLibraryPopover
              onSelectIcon={onSelectPredefinedIcon}
              icons={predefinedIcons}
              predefinedIconId={iconPredefinedId || 1}
              wrapperSx={{
                '& > button': {
                  justifyContent: 'initial',
                },
              }}
            />
          </Stack>
        }
      />
    </Stack>
  );
};

export default WidgetIconWithBackgroundColorField;
