import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { Box, ConditionalRender } from '@egym/ui';
import { FileFormatName } from '@egym/utils';

type Props = {
  accept?: FileFormatName[];
  acceptHints?: Record<FileFormatName, { tKey: string; tKeyParams?: Record<string, string | number> }[]>;
  acceptHintsCommon?: { tKey: string; tKeyParams?: Record<string, string | number> }[];
};

const AvailableFileFormatsText: React.FC<Props> = ({ accept, acceptHints, acceptHintsCommon }) => {
  const { t } = useTranslation();

  return (
    <Box sx={{ fontSize: '12px', textAlign: 'center' }}>
      <Box>
        <Typography variant="captionBold">{t('common.fileUpload.supportedFileFormats') as ReactNode}: </Typography>

        {accept?.map((formatName, acceptIndex, acceptArr) => {
          return (
            <span key={formatName}>
              <Typography variant="captionBold">.{formatName}</Typography>
              {Boolean(acceptHints?.[formatName]?.length) &&
                acceptHints?.[formatName].map((acceptHint, hintsIndex, hintsArr) => {
                  return (
                    <Typography key={acceptHint.tKey} variant="caption" sx={{ textTransform: 'lowercase' }}>
                      {hintsIndex === 0 && ' '}
                      {hintsArr.length > 1 && hintsIndex === 0 && '('}
                      {t(acceptHint.tKey, acceptHint.tKeyParams) as ReactNode}
                      {hintsArr.length > 1 && (
                        <ConditionalRender condition={hintsIndex !== hintsArr.length - 1}>
                          <>, </>
                          <>)</>
                        </ConditionalRender>
                      )}
                    </Typography>
                  );
                })}
              {acceptArr.length > 1 && acceptIndex !== acceptArr.length - 1 && (
                <Typography variant="caption"> {t('common.labels.or') as ReactNode} </Typography>
              )}
            </span>
          );
        })}
      </Box>

      {Boolean(acceptHintsCommon?.length) &&
        acceptHintsCommon?.map((acceptCommonHint, hintsIndex) => {
          return hintsIndex === 0 ? (
            <Typography key={acceptCommonHint.tKey} variant="caption">
              {t(acceptCommonHint.tKey, acceptCommonHint.tKeyParams) as ReactNode}
            </Typography>
          ) : (
            <Typography key={acceptCommonHint.tKey} variant="caption" sx={{ textTransform: 'lowercase' }}>
              {', '}
              {t(acceptCommonHint.tKey, acceptCommonHint.tKeyParams) as ReactNode}
            </Typography>
          );
        })}
    </Box>
  );
};

export default AvailableFileFormatsText;
