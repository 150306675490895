import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const MainTabHeaderDefaultIcon3: React.FC<SvgIconProps> = ({ htmlColor = '#FFF', sx, ...props }) => {
  return (
    <SvgIcon viewBox="0 0 48 48" {...props} sx={{ width: '48px', height: '48px', ...sx }}>
      <g fill={htmlColor} fillRule="evenodd">
        <path d="M40.029 21.191c0 .53-.208 1.03-.582 1.404l-16.85 16.85a1.989 1.989 0 0 1-2.809 0L8.554 28.213a1.99 1.99 0 0 1 0-2.809l16.85-16.85a1.976 1.976 0 0 1 1.404-.581h13.22v13.22zm1.986-17.19H26.808c-1.59 0-3.087.62-4.212 1.745l-16.85 16.85A5.922 5.922 0 0 0 4 26.807c0 1.592.62 3.087 1.746 4.212L16.98 42.254a5.914 5.914 0 0 0 4.211 1.745c1.59 0 3.087-.62 4.212-1.745l16.85-16.85A5.918 5.918 0 0 0 44 21.19V5.986C44 4.89 43.11 4 42.015 4z" />
        <path
          d="M30.364 15.236a2.4 2.4 0 1 1-.002 4.8 2.4 2.4 0 0 1 .002-4.8m-6 2.4c0 3.308 2.691 6 6 6 3.308 0 6-2.692 6-6 0-3.309-2.692-6-6-6-3.309 0-6 2.691-6 6M22.586 31.071a2 2 0 0 1-2.72 2.93l-.109-.102-5.656-5.656a2 2 0 0 1 2.719-2.93l.109.101 5.657 5.657z"
          opacity=".6"
        />
      </g>
    </SvgIcon>
  );
};
