import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider } from '@mui/material';
import { Typography, Container, Stack, LinkCore } from '@egym/ui';
import { ReactComponent as LogoIcon } from 'src/shared/svg/logo.svg';
import { footerHeightPx } from '../../../styles/constants';
import { footerMenuItems } from './constants';

const Footer: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Container
      component="footer"
      maxWidth={false}
      disableGutters
      sx={{
        height: footerHeightPx,
        minWidth: theme => theme.breakpoints.values.lg,
        bgcolor: 'common.black',
        zIndex: 10,
        position: 'relative',
      }}
    >
      <Container maxWidth="lg" sx={{ height: '100%' }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
            sx={{ height: '100%', py: 2, boxSizing: 'border-box' }}
          >
            <Typography variant="body2" color="grey.500">
              {t('footer.copyrightText', { year: new Date().getFullYear() }) as ReactNode}
            </Typography>
            <Divider
              flexItem
              orientation="vertical"
              variant="middle"
              sx={{ borderColor: 'grey.500', mx: 2, height: '20px' }}
            />
            <LinkCore sx={{ textDecoration: 'none' }} href={footerMenuItems[2].href} target="_self" color="grey.600">
              <Typography variant="body2" color="grey.500">
                {t(footerMenuItems[2].tKey) as ReactNode}
              </Typography>
            </LinkCore>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Typography variant="body2" color="grey.500">
              {t('footer.poweredBy') as ReactNode}
            </Typography>
            <LogoIcon />
          </Stack>
        </Stack>
      </Container>
    </Container>
  );
};

export default Footer;
