import React, { forwardRef, ReactNode } from 'react';
import { Paper, Stack } from '@egym/ui';
import { AppLayoutFeatureComponentProps } from '../../../../AppPreviewerProps';
import PreviewContainer from '../../../PreviewContainer';
import PreviewFeatureTitle from '../../../PreviewFeatureTitle';

const PreviewGoalCenter = forwardRef<HTMLDivElement, AppLayoutFeatureComponentProps>(
  ({ tFunction, palette, wrapperSx, widgetProps, previewerState }, ref) => {
    return (
      <PreviewContainer ref={ref} wrapperSx={wrapperSx}>
        <PreviewFeatureTitle
          tFunction={tFunction}
          palette={palette}
          title={
            widgetProps?.preferences?.title?.[previewerState.selectedLocale as string] ||
            'appPreviewer.labels.goal_other'
          }
          withViewAll
        />
        <Paper
          sx={{
            textAlign: 'left',
            fontSize: '14px',
            color: 'rgb(33,33,33)',
            borderRadius: '8px',
            boxShadow: '0 4px 6px rgba(223, 223, 223, 0.7)',
            px: '15px',
            py: '15px',
          }}
        >
          <Stack>
            <span style={{ fontSize: '14px', fontWeight: 500, color: 'rgb(33, 33, 33)', marginBottom: '3px' }}>
              {tFunction('appPreviewer.labels.setupYourGoal', { count: 36 }) as ReactNode}
            </span>
            <span style={{ fontSize: '12px', lineHeight: '17px', color: 'rgb(76, 74, 74)', marginBottom: '10px' }}>
              {tFunction('appPreviewer.labels.goalWidgetDescription') as ReactNode}
            </span>

            <div
              style={{
                background: '#f4f4f4',
                padding: '10px 15px',
                fontSize: '13px',
                fontWeight: 500,
                borderRadius: '4px',
                textAlign: 'center',
              }}
            >
              {tFunction('appPreviewer.labels.addGoal') as ReactNode}
            </div>
          </Stack>
        </Paper>
      </PreviewContainer>
    );
  },
);

export default PreviewGoalCenter;
