import { CSSProperties } from 'react';
import { DeprecatedThemeOptions } from '@mui/material';

const typography: DeprecatedThemeOptions['typography'] = palette => {
  return {
    fontFamily: ['HelveticaNowDisplay-Regular', 'Helvetica Neue', 'sans-serif'].join(','),
    button: {
      textTransform: 'none',
      fontFamily: ['HelveticaNowDisplay-Bold', 'Helvetica Neue', 'sans-serif'].join(','),
    },
    smallBold: {
      fontSize: '10px',
      letterSpacing: '0.36px',
      lineHeight: '13px',
      color: palette.grey[600],
      fontFamily: ['HelveticaNowDisplay-Bold', 'Helvetica Neue', 'sans-serif'].join(','),
    },
    caption: {
      fontFamily: 'HelveticaNowDisplay-Regular,Helvetica Neue,sans-serif',
      fontSize: '0.75rem',
      letterSpacing: '0.4px',
      lineHeight: '16px',
      color: palette.grey[600],
    },
    captionMedium: {
      fontFamily: 'HelveticaNowDisplay-Medium,Helvetica Neue,sans-serif',
      fontSize: '0.75rem',
      letterSpacing: '0.4px',
      lineHeight: '16px',
    },
    captionBold: {
      fontSize: '0.75rem',
      letterSpacing: '0.4px',
      lineHeight: '16px',
      color: palette.grey[600],
      fontFamily: ['HelveticaNowDisplay-Bold', 'Helvetica Neue', 'sans-serif'].join(','),
    },
    subtitle1: {
      fontFamily: ['HelveticaNowDisplay-Medium', 'Helvetica Neue', 'sans-serif'].join(','),
      fontSize: '1.125rem',
      lineHeight: 1.75,
    },
    subtitle1Bold: {
      fontFamily: ['HelveticaNowDisplay-Bold', 'Helvetica Neue', 'sans-serif'].join(','),
      fontSize: '1.125rem',
      lineHeight: 1.75,
    },
    subtitle2Bold: {
      fontSize: '0.875rem',
      lineHeight: 1.57,
      fontFamily: ['HelveticaNowDisplay-Bold', 'Helvetica Neue', 'sans-serif'].join(','),
    },
    body1Bold: {
      fontFamily: ['HelveticaNowDisplay-Bold', 'Helvetica Neue', 'sans-serif'].join(','),
      fontSize: '1rem',
      lineHeight: 1.5,
    },
    body1Medium: {
      fontFamily: 'HelveticaNowDisplay-Medium,Helvetica Neue,sans-serif',
      fontSize: '1rem',
      lineHeight: 1.5,
    },
    body2Bold: {
      fontFamily: ['HelveticaNowDisplay-Bold', 'Helvetica Neue', 'sans-serif'].join(','),
      fontSize: '0.875rem',
      lineHeight: 1.43,
    },
    body2Medium: {
      fontFamily: 'HelveticaNowDisplay-Medium,Helvetica Neue,sans-serif',
      fontSize: '0.875rem',
      lineHeight: 1.43,
    },
    body2Caption: {
      fontFamily: ['HelveticaNowDisplay-Medium', 'Helvetica Neue', 'sans-serif'].join(','),
      color: palette.grey[500],
      fontSize: '0.875rem',
      fontWeight: 400,
      lineHeight: 1.43,
    },
    h2Bold: {
      fontFamily: ['HelveticaNowDisplay-Bold', 'Helvetica Neue', 'sans-serif'].join(','),
      fontSize: '3.75rem',
      letterSpacing: '-0.47px',
    },
    h3Bold: {
      fontFamily: ['HelveticaNowDisplay-Bold', 'Helvetica Neue', 'sans-serif'].join(','),
      fontSize: '3rem',
      lineHeight: 1.167,
    },
    h4: {
      fontFamily: 'HelveticaNowDisplay-Regular,Helvetica Neue,sans-serif',
      fontSize: '2rem',
      fontWeight: 400,
      lineHeight: 1.235,
    },
    h4Bold: {
      fontFamily: ['HelveticaNowDisplay-Bold', 'Helvetica Neue', 'sans-serif'].join(','),
      fontSize: '2rem',
      lineHeight: 1.235,
    },
    h5Bold: {
      fontFamily: ['HelveticaNowDisplay-Bold', 'Helvetica Neue', 'sans-serif'].join(','),
      fontSize: '1.5rem',
      lineHeight: 1.334,
    },
    h5Medium: {
      fontFamily: ['HelveticaNowDisplay-Medium', 'Helvetica Neue', 'sans-serif'].join(','),
      fontSize: '1.5rem',
      lineHeight: 1.334,
    },
    h6Bold: {
      fontFamily: ['HelveticaNowDisplay-Bold', 'Helvetica Neue', 'sans-serif'].join(','),
      fontSize: '1.25rem',
      lineHeight: 1.6,
    },
  };
};

declare module '@mui/material/styles' {
  interface TypographyVariants {
    smallBold: CSSProperties;
    captionMedium: CSSProperties;
    captionBold: CSSProperties;
    subtitle1Bold: CSSProperties;
    subtitle2Bold: CSSProperties;
    body1Bold: CSSProperties;
    body1Medium: CSSProperties;
    body2Bold: CSSProperties;
    body2Medium: CSSProperties;
    body2Caption: CSSProperties;
    h2Bold: CSSProperties;
    h3Bold: CSSProperties;
    h4Bold: CSSProperties;
    h5Bold: CSSProperties;
    h5Medium: CSSProperties;
    h6Bold: CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    smallBold?: CSSProperties;
    captionMedium?: CSSProperties;
    captionBold?: CSSProperties;
    subtitle1Bold?: CSSProperties;
    subtitle2Bold?: CSSProperties;
    body1Bold?: CSSProperties;
    body1Medium?: CSSProperties;
    body2Bold?: CSSProperties;
    body2Medium?: CSSProperties;
    body2Caption?: CSSProperties;
    h2Bold?: CSSProperties;
    h3Bold?: CSSProperties;
    h4Bold?: CSSProperties;
    h5Bold?: CSSProperties;
    h5Medium?: CSSProperties;
    h6Bold?: CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    smallBold: true;
    captionMedium: true;
    captionBold: true;
    subtitle1Bold: true;
    subtitle2Bold: true;
    body1Bold: true;
    body1Medium: true;
    body2Bold: true;
    body2Medium: true;
    body2Caption: true;
    h2Bold: true;
    h3Bold: true;
    h4Bold: true;
    h5Bold: true;
    h5Medium: true;
    h6Bold: true;
  }
}

export default typography;
