import { PaletteOptions } from '@mui/material/styles';

const getPalette = (darkMode): PaletteOptions => {
  // const mainPrimaryColor = darkMode ? '#FF8F3D' : '#C75300';
  const mainPrimaryColor = darkMode ? '#FF8F3D' : '#c75300';
  // const mainSecondaryColor = darkMode ? '#41C7D7' : '#19808E';
  const mainSecondaryColor = darkMode ? '#41C7D7' : '#f6f8fb';
  const mainSuccessColor = darkMode ? '#2ED364' : '#17843B';
  const mainErrorColor = darkMode ? '#FF5C77' : '#D92845';
  const mainWarningColor = darkMode ? '#E6B51B' : '#967000';
  const mainInfoColor = darkMode ? '#41C7D7' : '#19808E';
  const mainHeadingColor = darkMode ? '#949494' : '#333';
  const mainHeadingTextColor = darkMode ? '#333' : '#fff';
  const secondaryHeadingTextColor = darkMode ? '#333' : '#212529';
  const backgroundDefaultColor = darkMode ? '#303030' : '#f0eded';
  const backgroundPaperColor = darkMode ? '#424242' : '#fff';
  const tableErrorRowColor = darkMode ? '#FF5C77' : '#fef7f8';

  return {
    // mode: darkMode ? 'dark' : 'light',
    mode: 'light',
    primary: {
      main: mainPrimaryColor,
    },
    secondary: {
      main: mainSecondaryColor,
    },
    success: {
      main: mainSuccessColor,
    },
    error: {
      main: mainErrorColor,
    },
    warning: {
      main: mainWarningColor,
    },
    info: {
      main: mainInfoColor,
    },
    heading: {
      main: mainHeadingColor,
    },
    headingText: {
      main: mainHeadingTextColor,
    },
    secondaryHeadingText: {
      main: secondaryHeadingTextColor,
    },
    background: {
      default: backgroundDefaultColor,
      paper: backgroundPaperColor,
    },
    tableErrorRow: {
      main: tableErrorRowColor,
    },
  };
};

declare module '@mui/material/styles/createPalette' {
  interface Palette {
    heading: Palette['primary'];
    headingText: Palette['primary'];
    secondaryHeadingText: Palette['primary'];
    tableErrorRow: Palette['primary'];
  }

  interface PaletteOptions {
    heading: PaletteOptions['primary'];
    headingText: PaletteOptions['primary'];
    secondaryHeadingText: PaletteOptions['primary'];
    tableErrorRow: PaletteOptions['primary'];
  }
}

export default getPalette;
