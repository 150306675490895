import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import { Box } from '@egym/ui';
import { PreviewerRoute } from '@components';
import useAppPreviewerState from '../../hooks/useAppPreviewerState';
import { IconWrapper } from '../Icons';
import ArrowBackIcon from '../Icons/ArrowBackIcon';
import FiltersIcon from '../Icons/FiltersIcon';
import FindClassLocationIcon from '../Icons/FindClassLocationIcon';
import PreviewContainer from '../PreviewContainer';
import PreviewFindAClassOnTheTab from '../PreviewerLayoutRoute/components/PreviewFindAClassOnTheTab';

type Props = {
  value: PreviewerRoute.FindClass;
  id: string;
};

const PreviewerFindClassRoute: React.FC<Props> = ({ id }) => {
  const { palette, previewerState } = useAppPreviewerState(id);
  const { t } = useTranslation();

  return (
    <Box style={{ position: 'relative' }}>
      <Stack
        sx={{
          position: 'sticky',
          top: 0,
          background: 'white',
          height: '129px',
          pb: '16px',
          boxSizing: 'border-box',
          boxShadow: '0 0.5px 0 rgba(0, 0, 0, 0.1)',
          zIndex: 1,
        }}
        justifyContent="flex-end"
      >
        <PreviewContainer>
          <Stack
            direction="row"
            alignItems="center"
            sx={{
              width: '100%',
              background: 'common.white',
              position: 'relative',
              justifyContent: 'space-between',
              pb: '16px',
            }}
          >
            <IconWrapper bgColor={palette.primaryTransparent}>
              <ArrowBackIcon htmlColor={palette.primary} />
            </IconWrapper>
            <span
              style={{
                fontSize: '16px',
                fontWeight: 500,
                color: '#212121',
              }}
            >
              {t('appPreviewer.labels.findClass') as ReactNode}
            </span>
            <IconWrapper bgColor={palette.primaryTransparent}>
              <FindClassLocationIcon htmlColor={palette.primary} />
            </IconWrapper>
          </Stack>
          <Stack direction="row" spacing="5.4px">
            <Stack
              alignItems="center"
              direction="row"
              sx={{
                backgroundColor: palette.primaryTransparent,
                color: palette.primary,
                fontSize: '10px',
                fontWeight: 500,
                px: 1.5,
                pl: 1,
                py: 1.3,
                borderRadius: '4px',
              }}
            >
              <FiltersIcon
                htmlColor={palette.primary}
                style={{ width: '18px', height: '18px', marginTop: '2px', marginRight: '3px' }}
              />
              {t('appPreviewer.labels.filters') as ReactNode}
            </Stack>

            <Stack
              alignItems="center"
              direction="row"
              sx={{
                backgroundColor: palette.primary,
                color: 'white',
                fontSize: '10px',
                fontWeight: 500,
                px: 1.5,
                py: 1.3,
                borderRadius: '4px',
              }}
            >
              {t('appPreviewer.labels.allClasses') as ReactNode}
            </Stack>

            <Stack
              alignItems="center"
              direction="row"
              sx={{
                backgroundColor: 'white',
                border: `1px solid ${palette.primary}`,
                color: palette.primary,
                fontSize: '10px',
                fontWeight: 500,
                px: 1.5,
                py: 1.3,
                borderRadius: '4px',
              }}
            >
              {t('appPreviewer.labels.favorite') as ReactNode}
            </Stack>

            <Stack
              alignItems="center"
              direction="row"
              sx={{
                backgroundColor: 'white',
                border: `1px solid ${palette.primary}`,
                color: palette.primary,
                fontSize: '10px',
                fontWeight: 500,
                px: 1.5,
                py: 1.3,
                borderRadius: '4px',
              }}
            >
              {t('appPreviewer.labels.allActivities') as ReactNode}
            </Stack>
          </Stack>
        </PreviewContainer>
      </Stack>
      <Box
        className="hide-scrollbar"
        sx={{
          pt: '8px',
          maxHeight: 'calc(100% - 129px)',
          overflow: 'scroll',
          background: `linear-gradient(0deg, rgb(241, 242, 244), rgb(255,255,255))`,
        }}
      >
        <PreviewFindAClassOnTheTab
          tFunction={t}
          palette={palette}
          previewerState={previewerState}
          headerBg="white"
          contentBg="rgb(244, 244, 244)"
        />
      </Box>
    </Box>
  );
};

export default PreviewerFindClassRoute;
