import React, { forwardRef, ReactNode, useCallback, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, ClickAwayListener, Paper } from '@mui/material';
import { motion } from 'framer-motion';
import { Stack } from '@egym/ui';
import { PreviewerRoute } from '../../AppPreviewerProps';
import useAppPreviewerState from '../../hooks/useAppPreviewerState';
import SignInLogo from './components/SignInLogo';

type Props = {
  // eslint-disable-next-line react/no-unused-prop-types
  value: PreviewerRoute.SignInScreen;
  onAdjustSignInLogo?: (result: { left: number; top: number; width: number; height: number }) => void;
  isViewMode?: boolean;
  id: string;
};

const PreviewerSignInScreen = forwardRef<any, Props>(({ id, onAdjustSignInLogo, isViewMode }, ref) => {
  const {
    palette,
    previewerState: { signInScreenProps },
  } = useAppPreviewerState(id);
  const [canModify, setCanModify] = useState<boolean>(false);
  const { t } = useTranslation();
  const signInLogoConstraintsRef = useRef(null);
  const sigInBackgroundWrapperRef = useRef(null);

  const signInLogoRef = useRef();

  useImperativeHandle(ref, () => ({
    signInLogoRef,
  }));

  const onClickAway = useCallback(
    event => {
      const isOutsideClick = event.path.some(element => element.id?.includes('enable-sign-logo-modification'));

      if (!isOutsideClick) {
        setCanModify(false);
      }
    },
    [setCanModify],
  );

  return (
    <Stack sx={{ background: 'white', height: '100%' }}>
      <ClickAwayListener onClickAway={onClickAway}>
        <Box
          component={motion.div}
          ref={sigInBackgroundWrapperRef}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative',
            width: '100%',
            height: '537px',
            boxSizing: 'border-box',
          }}
        >
          {signInScreenProps?.signInBackgroundImage && (
            <Box
              component="img"
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                objectFit: 'cover',
              }}
              src={signInScreenProps.signInBackgroundImage}
            />
          )}
          {signInScreenProps?.signInBackgroundImageOverlayColor && (
            <Box
              sx={{
                position: 'absolute',
                left: 0,
                top: 0,
                width: '100%',
                height: '100%',
                background: signInScreenProps.signInBackgroundImageOverlayColor,
              }}
            />
          )}
          <Box
            ref={signInLogoConstraintsRef}
            sx={{
              position: 'absolute',
              width: '69.5%',
              height: '56.65%',
              top: '6.132%',
              left: '50%',
              transform: 'translateX(-50%)',
              outline: '2px dashed',
              outlineColor: canModify ? '#00ff44' : 'transparent',
              boxSizing: 'content-box',
            }}
          >
            {signInScreenProps?.signInLogo && !signInScreenProps?.signInLogoDisabled && (
              <SignInLogo
                key={signInScreenProps.signInLogo}
                id={id}
                logoSrc={signInScreenProps.signInLogo}
                signInBackgroundWrapperRef={sigInBackgroundWrapperRef}
                constraintsRef={signInLogoConstraintsRef}
                ref={signInLogoRef}
                initialLeft={signInScreenProps.signInLogoPositionLeft}
                initialTop={signInScreenProps.signInLogoPositionTop}
                initialWidth={signInScreenProps.signInLogoWidth}
                initialHeight={signInScreenProps.signInLogoHeight}
                onAdjustSignInLogo={onAdjustSignInLogo}
                canModify={canModify}
                setCanModify={setCanModify}
                isViewMode={isViewMode}
              />
            )}
          </Box>
        </Box>
      </ClickAwayListener>
      <Stack sx={{ background: 'white', px: '15px', height: 'calc(100% - 537px)', alignItems: 'center' }}>
        <Paper
          sx={{
            background: palette.primary,
            borderRadius: '8px',
            height: '38px',
            lineHeight: '38px',
            width: '100%',
            mt: '16px',
            color: 'white',
            fontSize: '14px',
            textAlign: 'center',
          }}
        >
          {t('appPreviewer.labels.logIn') as ReactNode}
        </Paper>
        <span style={{ color: 'rgb(33, 33, 33)', marginTop: '15px', fontSize: '14px', fontWeight: 500 }}>
          {t('appPreviewer.labels.learnMore') as ReactNode}
        </span>
      </Stack>
    </Stack>
  );
});

export default PreviewerSignInScreen;
