import { alpha } from '@mui/material';
import { atomFamily, selectorFamily } from 'recoil';
import { PreviewerRoute, PreviewerState } from './AppPreviewerProps';

const keys = {
  state: 'appPreviewer.state',
  palette: 'appPreviewer.palette',
  size: 'appPreviewer.size',
  scroll: 'appPreviewer.scroll',
};

const atoms = {
  previewer: atomFamily<PreviewerState, string>({
    key: keys.state,
    default: {
      palette: {
        primary: '#000000',
        secondary: '#c75300',
        dashboard3BackgroundPrimary: undefined,
        dashboard3BackgroundSecondary: undefined,
      },
      route: PreviewerRoute.Layout,
      selectedTab: 0,
      size: undefined,
      appPackage: undefined,
      appLayout: undefined,
      mainTabIconLink: undefined,
      appIconLink: undefined,
      appIconBackground: undefined,
      iosAppIconName: undefined,
      signInScreenProps: undefined,
      selectedLocale: undefined,
      disabled: undefined,
    },
  }),
};

const selectors = {
  palette: selectorFamily<PreviewerState['palette'], string>({
    key: keys.palette,
    get:
      id =>
      ({ get }) => {
        const { palette } = get(atoms.previewer(id));

        return {
          ...palette,
          dashboard3BackgroundPrimary: palette.dashboard3BackgroundPrimary || palette.primary,
          dashboard3BackgroundSecondary: palette.dashboard3BackgroundSecondary || palette.primary,
          primaryTransparent: alpha(palette.primary, 0.1),
        };
      },
  }),
  size: selectorFamily<any, string>({
    key: keys.size,
    get:
      id =>
      ({ get }) => {
        const { size } = get(atoms.previewer(id));

        let deviceWidth;
        let deviceHeight;
        let xPadding;
        let yPadding;
        let topRadius;
        let bottomRadius;
        let scale;

        const defaultSizes = {
          deviceWidth: 320,
          deviceHeight: 668,
          xPadding: 9,
          yPadding: 6,
          topRadius: 25,
          bottomRadius: 31,
        };

        switch (size) {
          case 'x-small': {
            deviceWidth = 102;
            deviceHeight = 215;
            xPadding = 4;
            yPadding = 1;
            topRadius = 30;
            bottomRadius = 35;
            scale = 0.315;
            break;
          }
          case 'small': {
            deviceWidth = 130;
            deviceHeight = 271.6;
            xPadding = 6;
            yPadding = 2.2;
            topRadius = 27;
            bottomRadius = 32;
            scale = 0.401;
            break;
          }
          case 'medium': {
            deviceWidth = 200;
            deviceHeight = 418;
            xPadding = 7;
            yPadding = 5;
            topRadius = 27;
            bottomRadius = 32;
            scale = 0.6219;
            break;
          }
          case 'x-large': {
            deviceWidth = 400;
            deviceHeight = 835;
            xPadding = 10;
            yPadding = 11;
            topRadius = 28;
            bottomRadius = 30;
            scale = 1.263;
            break;
          }
          case 'large':
          default: {
            deviceWidth = defaultSizes.deviceWidth;
            deviceHeight = defaultSizes.deviceHeight;
            xPadding = defaultSizes.xPadding;
            yPadding = defaultSizes.yPadding;
            topRadius = defaultSizes.topRadius;
            bottomRadius = defaultSizes.bottomRadius;
            scale = 1;
            break;
          }
        }

        const contentWidth = defaultSizes.deviceWidth - xPadding * 2;
        const contentHeight = defaultSizes.deviceHeight - yPadding * 2;

        return { deviceWidth, deviceHeight, contentWidth, contentHeight, topRadius, bottomRadius, contentScale: scale };
      },
  }),
};

export const appPreviewerState = {
  keys,
  atoms,
  selectors,
};
