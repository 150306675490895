import React from 'react';
import { styled } from '@mui/material/styles';
import { lighten } from '@mui/system/colorManipulator';
import { Tooltip as EgymTooltip, TooltipProps, tooltipClasses } from '../Tooltip';

export const ErrorTooltip = styled(({ className, ...props }: TooltipProps) => (
  <EgymTooltip {...props} classes={{ popper: className }} arrow />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.error.main,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: '400',
    padding: `${theme.spacing(2.5)} ${theme.spacing(4)}`,
    backgroundColor: lighten(theme.palette.error.light, 0.9),
    border: '1px solid',
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main,
  },
}));
