import { Uuid } from '@egym/types';
import { useRouter } from '@egym/ui';
import useAppDesignLayoutAndTabsWithFallback from '../useAppDesignLayoutAndTabsWithFallback';

const useAppDesignLayoutAndTabsWithFallbackByRouter = () => {
  const { urlParams } = useRouter<{ uuid: Uuid }>();

  return useAppDesignLayoutAndTabsWithFallback({ appUuid: urlParams.uuid });
};

export default useAppDesignLayoutAndTabsWithFallbackByRouter;
