import React, { ReactNode } from 'react';
import { Stack, Box } from '@mui/material';
import { SxProps } from '@mui/system';
import { TFunction } from 'i18next';
import { StyledBreadcrumbs } from './PreviewTrainingPlanItem.styles';

type Props = {
  tFunction: TFunction;
  image: string;
  name: string;
  kcalCount: number;
  exercisesCount: number;
  wrapperSx?: SxProps;
};

const PreviewTrainingPlanItem: React.FC<Props> = ({ tFunction, image, name, kcalCount, exercisesCount, wrapperSx }) => {
  return (
    <Box
      component="div"
      sx={{
        width: '100%',
        height: '170px',
        borderRadius: '8px',
        overflow: 'hidden',
        position: 'relative',
        boxShadow: '0 4px 6px rgba(223, 223, 223, 0.7)',
        ...wrapperSx,
      }}
    >
      <div
        style={{
          background: 'linear-gradient(0deg, rgb(3, 3, 3), rgba(0, 0, 0, 0.0))',
          position: 'absolute',
          width: '100%',
          height: '100%',
          top: 0,
          left: 0,
        }}
      >
        <Stack direction="column-reverse" sx={{ height: '100%', p: '15px', boxSizing: 'border-box' }}>
          <StyledBreadcrumbs separator="&bull;" sx={{ fontSize: '13px', color: 'white', lineHeight: '20px' }}>
            <span>{tFunction('appPreviewer.labels.kcalCount', { count: kcalCount }) as ReactNode}</span>
            <span>{tFunction('appPreviewer.labels.exercisesCount', { count: exercisesCount }) as ReactNode}</span>
          </StyledBreadcrumbs>
          <span style={{ fontSize: '18px', lineHeight: '24px', color: 'white', fontWeight: 700, marginBottom: '4px' }}>
            {tFunction(name) as ReactNode}
          </span>
        </Stack>
      </div>
      <img src={image} alt={name} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
    </Box>
  );
};

export default PreviewTrainingPlanItem;
