import React, { forwardRef, PropsWithChildren } from 'react';
import { SxProps } from '@mui/system';
import { Box } from '@egym/ui';

type Props = {
  wrapperSx?: SxProps;
};

const PreviewContainer = forwardRef<HTMLDivElement, PropsWithChildren<Props>>(({ children, wrapperSx }, ref) => {
  return (
    <Box ref={ref} sx={{ px: '15px', ...wrapperSx }}>
      {children}
    </Box>
  );
});

export default PreviewContainer;
