import React from 'react';
// https://next.material-ui.com/components/autocomplete/#virtualization
import { Autocomplete as MuiAutocomplete, AutocompleteProps } from '@mui/material';
import { Option } from '@egym/types';
import { Typography } from '../../dataDisplay/Typography';
import ListboxComponent from './Listbox';

type Props = AutocompleteProps<Option, any, any, any>;

export const Autocomplete: React.FC<Props> = props => {
  return (
    <MuiAutocomplete
      disableListWrap
      disableClearable
      autoHighlight
      ListboxComponent={ListboxComponent as React.ComponentType<React.HTMLAttributes<HTMLElement>>}
      getOptionLabel={(option: string | Option) => (typeof option === 'string' ? option : option.label)}
      isOptionEqualToValue={(option, value) => option.value === (value?.value ?? value)}
      renderOption={(renderOptionProps, option) => (
        <li {...renderOptionProps}>
          <Typography noWrap>{option.label}</Typography>
        </li>
      )}
      sx={{
        minWidth: '220px',
      }}
      {...props}
    />
  );
};
