import { addBusinessDays } from 'date-fns';

const today = new Date();

export const upcomingClasses = [
  {
    id: 1,
    name: 'appPreviewer.labels.mockUpcomingClasses.0.name',
    description1: 'appPreviewer.labels.mockUpcomingClasses.0.description1',
    description2: 'appPreviewer.labels.mockUpcomingClasses.0.description2',
    date: addBusinessDays(today, 7),
    spotsLeft: 12,
    totalSpots: 30,
  },
  {
    id: 2,
    name: 'appPreviewer.labels.mockUpcomingClasses.1.name',
    description1: 'appPreviewer.labels.mockUpcomingClasses.1.description1',
    description2: 'appPreviewer.labels.mockUpcomingClasses.1.description2',
    date: addBusinessDays(today, 5),
    spotsLeft: 3,
    totalSpots: 30,
  },
];

export const bookedClasses = [
  {
    ...upcomingClasses[1],
    date: addBusinessDays(today, 2),
    classDescription: 'appPreviewer.labels.mockUpcomingClasses.1.classDescription',
  },
];
