import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const IllustrationFeatureSizeChangeRequired: React.FC<SvgIconProps> = ({ sx, ...props }) => {
  return (
    <SvgIcon
      viewBox="0 0 354 188"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      sx={{ width: '354px', height: '188px', ...sx }}
    >
      <g transform="translate(3 3)" fill="none" fillRule="evenodd">
        <rect stroke="#9E9E9E" strokeWidth="3" fill="#FFF" x="-1.5" y="-1.5" width="104" height="185" rx="7" />
        <g transform="translate(247)">
          <rect stroke="#9E9E9E" strokeWidth="3" fill="#FFF" x="-1.5" y="-1.5" width="104" height="185" rx="7" />
          <g transform="translate(7 7)">
            <rect fill="#CECECE" width="87" height="44" rx="4" />
            <rect stroke="#EDEDED" strokeWidth="4" x="2" y="54" width="83" height="40" rx="4" />
            <rect stroke="#EDEDED" strokeWidth="4" x="2" y="106" width="83" height="40" rx="4" />
          </g>
          <rect fill="#CECECE" x="15" y="167" width="12" height="8" rx="3" />
          <rect fill="#CECECE" x="45" y="167" width="12" height="8" rx="3" />
          <rect fill="#CECECE" x="75" y="167" width="12" height="8" rx="3" />
        </g>
        <g transform="translate(7 7)">
          <rect fill="#CECECE" width="87" height="152" rx="4" />
          <g transform="translate(8 12)" fill="#EDEDED">
            <rect width="32" height="6" rx="3" />
            <rect x="247" width="32" height="6" rx="3" />
            <rect x="247" y="52" width="59" height="6" rx="3" />
            <rect x="247" y="104" width="32" height="6" rx="3" />
            <rect y="12" width="46" height="6" rx="3" />
            <rect x="247" y="12" width="46" height="6" rx="3" />
            <rect x="247" y="64" width="32" height="6" rx="3" />
            <rect x="247" y="116" width="46" height="6" rx="3" />
          </g>
        </g>
        <rect fill="#CECECE" x="15" y="167" width="12" height="8" rx="3" />
        <rect fill="#CECECE" x="45" y="167" width="12" height="8" rx="3" />
        <rect fill="#CECECE" x="75" y="167" width="12" height="8" rx="3" />
        <path
          d="M118 84a7.002 7.002 0 0 1 6.929 5.999L127 90v2h-2.07A7.002 7.002 0 0 1 111 91a7 7 0 0 1 7-7zm0 2a5 5 0 1 0 0 10 5 5 0 0 0 0-10zm15 4v2h-3v-2h3zm6 0v2h-3v-2h3zm6 0v2h-3v-2h3zm6 0v2h-3v-2h3zm6 0v2h-3v-2h3zm6 0v2h-3v-2h3zm6 0v2h-3v-2h3zm4.494-1.991 1.596 1.205a7.026 7.026 0 0 1-2.445 2.041l-.291.139-.816-1.826a5.022 5.022 0 0 0 1.956-1.56zm3.006-5.144V85c0 .192-.008.383-.023.572l-.029.284-1.985-.243a5.04 5.04 0 0 0 .033-.407L174.5 85v-2.135h2zm0-6v3h-2v-3h2zm0-6v3h-2v-3h2zm0-6v3h-2v-3h2zm0-6v3h-2v-3h2zm0-6v3h-2v-3h2zm0-6v3h-2v-3h2zm0-6v3h-2v-3h2zm0-6v3h-2v-3h2zM223 21l14 7-14 7v-6.001l-.731.001v-2l.731-.001V21zm-46.052 7.682 1.301 1.519a5.017 5.017 0 0 0-1.328 1.787l-.106.261-1.874-.7a7.012 7.012 0 0 1 2.007-2.867zM183.269 27v2H181.5c-.211 0-.42.013-.627.039l-.307.048-.372-1.965c.321-.06.647-.099.976-.114l.33-.008h1.769zm6 0v2h-3v-2h3zm6 0v2h-3v-2h3zm6 0v2h-3v-2h3zm6 0v2h-3v-2h3zm6 0v2h-3v-2h3zm6 0v2h-3v-2h3z"
          fill="#C75300"
          fillRule="nonzero"
        />
      </g>
    </SvgIcon>
  );
};
