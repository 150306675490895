import React, { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Icons } from '@egym/ui';
import { getWidgetNameTKey } from '@helpers';
import { WidgetWithTab } from '@types';

type Props = {
  widget: WidgetWithTab;
};

const NotificationPositionAlert: FC<Props> = ({ widget }) => {
  const { t } = useTranslation();

  return (
    <Alert
      icon={<Icons.VerticalAlignTop htmlColor="#19808E" />}
      severity="info"
      color="info"
      variant="standard"
      sx={{ borderRadius: 2, maxWidth: '100%', border: '1px solid', borderColor: 'info.main', color: 'info.main' }}
    >
      {
        t('appDesign.labels.widgets.notificationsWidgetPositionWarning', {
          widgetName: t(getWidgetNameTKey(widget.name)),
        }) as ReactNode
      }
    </Alert>
  );
};

export default NotificationPositionAlert;
