import { useMemo } from 'react';
import { Uuid } from '@egym/types';
import { appDesignProgressAllSteps, calculateAppDesignProgress } from '@helpers';
import useClientApplicationDesign from '../useClientApplicationDesign';

const useAppDesignProgress = (appUuid: Uuid) => {
  const { appDesign } = useClientApplicationDesign(appUuid);

  const doneStepsCount = useMemo(() => {
    if (!appDesign.isSuccess) return 0;

    return calculateAppDesignProgress(appDesign.data);
  }, [appDesign.isSuccess, appDesign.data]);

  return {
    doneStepsCount,
    allStepsCount: appDesignProgressAllSteps,
    completed: doneStepsCount === appDesignProgressAllSteps,
  };
};

export default useAppDesignProgress;
