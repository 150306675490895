import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const MainTabHeaderDefaultIcon30: React.FC<SvgIconProps> = ({ htmlColor = '#FFF', sx, ...props }) => {
  return (
    <SvgIcon viewBox="0 0 48 48" {...props} sx={{ width: '48px', height: '48px', ...sx }}>
      <g fill={htmlColor} fillRule="evenodd">
        <rect width="34" height="4" rx="2" opacity=".6" transform="translate(7 37)" />
        <path d="M10 32a3 3 0 0 1-2.995-2.824L7 29v-6a3 3 0 0 1 2.824-2.995L10 20h7v-4a3 3 0 0 1 2.824-2.995L20 13h7v-3a3 3 0 0 1 2.824-2.995L30 7h8a3 3 0 0 1 2.995 2.824L41 10v19a3 3 0 0 1-2.824 2.995L38 32H10zm7-8h-6v4h6v-4zm10-7h-6v11h6V17zm10-6h-6v17h6V11z" />
      </g>
    </SvgIcon>
  );
};
