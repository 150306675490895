import { useQuery } from '@tanstack/react-query';
import { Uuid } from '@egym/types';
import { getGymLocationsCount, getGymLocationsErrorCount } from '@api';

export const gymLocationsCountQueryName = 'gymLocationsCount';
export const gymLocationsErrorsCountQueryName = 'gymLocationsErrorsCount';

const useGymLocationsCount = (applicationUuid: Uuid) => {
  const gymLocationsCountResponse = useQuery(
    [gymLocationsCountQueryName, applicationUuid],
    async () => getGymLocationsCount({ urlParams: { applicationUuid } }),
    {
      enabled: Boolean(applicationUuid),
      select: result => result.data,
      keepPreviousData: true,
      refetchOnMount: false,
    },
  );
  const gymLocationsErrorsCountResponse = useQuery(
    [gymLocationsErrorsCountQueryName, applicationUuid],
    async () => getGymLocationsErrorCount({ urlParams: { applicationUuid } }),
    {
      enabled: Boolean(applicationUuid),
      select: result => result.data,
      keepPreviousData: true,
      refetchOnMount: false,
    },
  );
  return {
    gymLocationsCountResponse,
    gymLocationsErrorsCountResponse,
  };
};

export default useGymLocationsCount;
