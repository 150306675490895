import React, { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Stack } from '@egym/ui';
import { AppDesignLayoutTabWidget } from '@types';
import { MoveFeaturesToTheLibraryAlert } from '../Warnings';

type Props = {
  widgetName: string;
  text?: string;
  widget?: AppDesignLayoutTabWidget;
};

const RemoveFeatureConfirmDialogText: FC<Props> = ({ widgetName, text, widget }) => {
  const { t } = useTranslation();

  return (
    <Stack>
      <Typography variant="body1">
        {t(text ?? 'appDesign.labels.confirmRemoveFeature', { widgetName }) as ReactNode}
      </Typography>
      <MoveFeaturesToTheLibraryAlert wrapperSx={{ mt: 6 }} featuresCount={1} widget={widget} />
    </Stack>
  );
};

export default RemoveFeatureConfirmDialogText;
