import React, { FC } from 'react';
import { MenuItem, Stack, Typography } from '@mui/material';
import { Option } from '@egym/types';
import { MenuList } from '../MenuList';
import NestedMenuItem from '../NestedMenuItem';

type Props = {
  options: Option[];
  onSelectOption: (option: Option) => void;
};

const NestedMenuList: FC<Props> = ({ options, onSelectOption }) => {
  return (
    <MenuList>
      {options.map(option => {
        const { icon: Icon, iconProps, label, value, children } = option;

        return children ? (
          <NestedMenuItem
            key={value}
            option={option}
            label={
              Icon ? (
                <Stack direction="row" alignItems="center">
                  <Icon {...iconProps} />
                  <Typography variant="body1">{label}</Typography>
                </Stack>
              ) : (
                <Typography variant="body1">{label}</Typography>
              )
            }
            onSelectChildOption={onSelectOption}
            options={children}
          />
        ) : (
          <MenuItem
            key={value}
            data-testid={`nested-menu-item-${option.value}`}
            sx={{
              minWidth: '180px',
              maxWidth: '300px',
              py: 2.5,
              display: 'flex',
              alignItems: 'flex-start',
              flexDirection: 'column',
            }}
            onClick={() => onSelectOption(option)}
          >
            <Stack direction="row" alignItems="center">
              {Icon && <Icon {...iconProps} />}
              <Typography variant="body1">{label}</Typography>
            </Stack>
            {option.description && (
              <Typography
                className="select-item-description"
                component="div"
                variant="body2"
                sx={{ color: 'grey.600', whiteSpace: 'initial' }}
              >
                {option.description}
              </Typography>
            )}
          </MenuItem>
        );
      })}
    </MenuList>
  );
};

export default NestedMenuList;
