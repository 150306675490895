import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { alpha } from '@mui/material';
import { FormikConfig, FormikContextType, FormikProps } from 'formik';
import { map, mergeAll } from 'ramda';
import { getFileFromUrl } from '@egym/api';
import { customYup, toLocaleApiFormat, toLocaleFormat } from '@egym/utils';
import { defaultWidgetStrings, predefinedWidgetImagesMapping } from '@constants';
import { getGStoreResource } from '@helpers';
import { PredefinedImage, WidgetViewStyle } from '@types';
import { LngNamespace } from 'src/i18n';
import { getImageOverlayGradientPreview } from 'src/pages/AppDesign/components/AppDesignForm/components/WidgetsLibrary/hooks/useWidgetsLibrary/helpers';
import { getPresetColors } from 'src/pages/AppDesign/helpers';
import useWidgetPreferencesForm from '../../../../hooks/useWidgetPreferencesForm';
import { convertToStyledWidgetState } from '../../../../hooks/useWidgetPreferencesForm/helpers';
import {
  LocationFinderWidgetPreferencesFormContainerProps,
  LocationFinderWidgetPreferencesFormValues,
  UseLocationFinderWidgetPreferencesFormResult,
} from '../../LocationFinderWidgetPreferencesFormProps';

const useLocationFinderWidgetPreferencesForm = (
  props: LocationFinderWidgetPreferencesFormContainerProps,
  ref,
): UseLocationFinderWidgetPreferencesFormResult => {
  const { t } = useTranslation();
  const { currentWidget, previewerPalette, updateWidget } = props;
  const { innerRef, appLocales, availableLanguages, openLanguagesMenuTab, onSubmit, currentLocale } =
    useWidgetPreferencesForm(props, ref);

  const { t: previewerT } = useTranslation(LngNamespace.Previewer);

  const formProps = useMemo<FormikConfig<LocationFinderWidgetPreferencesFormValues>>(() => {
    const defaultLocalizedString = fieldName => {
      return availableLanguages.reduce((acc, value) => {
        return {
          ...acc,
          [value.locale]: previewerT(defaultWidgetStrings[currentWidget.name][fieldName], {
            lng: toLocaleFormat(value.locale),
          }),
        };
      }, {});
    };
    return {
      enableReinitialize: true,
      initialValues: {
        id: currentWidget.id,
        title: mergeAll(
          map(
            (locale: string) => ({
              [locale]: currentWidget.preferences?.title?.[locale] || defaultLocalizedString('title')[locale],
            }),
            map(locale => toLocaleApiFormat(locale), appLocales || []),
          ),
        ),
        image: currentWidget.preferences?.asset?.link
          ? getGStoreResource(currentWidget.preferences.asset.link)
          : predefinedWidgetImagesMapping[currentWidget.name].path,
        imagePredefinedId: currentWidget.preferences?.asset?.id
          ? currentWidget.preferences.asset.id
          : predefinedWidgetImagesMapping[currentWidget.name].id,
        imageOverlay:
          currentWidget.preferences?.assetColor ||
          getImageOverlayGradientPreview(
            alpha(previewerPalette.primary, 0.5),
            alpha(previewerPalette.secondary ?? previewerPalette.primary, 0.5),
          ),
      },
      validationSchema: customYup.object().shape({
        title: customYup
          .object()
          .required()
          .nullable()
          .shape(
            mergeAll(
              map(
                (locale: string) => ({
                  [locale]: customYup.string().nullable().required(t('common.validation.required')),
                }),
                map(locale => toLocaleApiFormat(locale), appLocales || []),
              ),
            ),
          ),
      }),
      innerRef,
      onSubmit,
      validateOnChange: false,
      validateOnBlur: false,
      validateOnMount: false,
    };
  }, [
    currentWidget.preferences?.asset,
    currentWidget.preferences?.assetColor,
    currentWidget.preferences?.title,
    currentWidget.name,
    currentWidget.id,
    appLocales,
    previewerPalette.primary,
    previewerPalette.secondary,
    innerRef,
    onSubmit,
    availableLanguages,
    previewerT,
    t,
  ]);

  const presetColors = useMemo(
    () => getPresetColors(previewerPalette, 0.5, { color: 'rgba(0, 0, 0, 1)', title: 'black' }),
    [previewerPalette],
  );

  const onSelectPredefinedImage = useCallback(
    async (
      predefinedImage: PredefinedImage,
      { setFieldValue, values: { imagePredefinedId } }: FormikProps<LocationFinderWidgetPreferencesFormValues>,
    ) => {
      if (imagePredefinedId === predefinedImage.id) return;
      const imageFile = await getFileFromUrl(predefinedImage.path);
      setFieldValue('image', [imageFile]);
      setFieldValue('imagePredefinedId', predefinedImage.id);
    },
    [],
  );

  const onFormChanged = useCallback(
    ({ values }: FormikContextType<LocationFinderWidgetPreferencesFormValues>) => {
      updateWidget(convertToStyledWidgetState(WidgetViewStyle.Image, values));
    },
    [updateWidget],
  );

  const onCustomFilePick = ({ setFieldValue }, fieldName) => {
    setFieldValue(fieldName, undefined);
  };

  const imageDimensions = useMemo(() => {
    const width = 1005;
    const height = 270;

    return {
      width,
      height,
      ratio: width / height,
    };
  }, []);

  return {
    formProps,
    availableLanguages,
    openLanguagesMenuTab,
    presetColors,
    onSelectPredefinedImage,
    currentLocale,
    onFormChanged,
    onCustomImagePick: ctx => onCustomFilePick(ctx, 'imagePredefinedId'),
    imageDimensions,
  };
};

export default useLocationFinderWidgetPreferencesForm;
