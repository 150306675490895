import React, { FC, useRef } from 'react';
import { useUpdateEffect } from 'react-use';
import { Drawer as MuiDrawer, Stack, Paper } from '@mui/material';
import { Uuid } from '@egym/types';
import { Button } from '@egym/ui';
import useDrawer from '../../../hooks/useDrawer';
import DrawerHeader from './components/DrawerHeader';

type Props = {
  appUuid?: Uuid;
};

export const Drawer: FC<Props> = ({ appUuid }) => {
  const { isOpen, isConfirming, drawerConfig, closeDrawer, onBackButtonClick, onConfirmClick } = useDrawer();
  const paperRef = useRef<HTMLDivElement | null>(null);

  const Component = drawerConfig?.component;

  useUpdateEffect(() => {
    if (paperRef.current) {
      paperRef.current?.scrollTo({ top: 0, left: 0 });
    }
  }, [drawerConfig?.component]);

  return isOpen ? (
    <MuiDrawer
      variant="permanent"
      ModalProps={{
        keepMounted: false,
      }}
      anchor={drawerConfig?.anchor || 'right'}
      PaperProps={{
        ...drawerConfig?.paperProps,
        ref: paperRef,
        sx: {
          width: {
            lg: '50%',
          },
          boxShadow: 5,
          ...drawerConfig?.paperProps?.sx,
        },
      }}
      open={isOpen}
    >
      <Stack sx={{ position: 'relative', flex: 1 }}>
        {drawerConfig?.title && (
          <DrawerHeader
            drawerConfig={drawerConfig}
            closeDrawer={closeDrawer}
            isConfirming={isConfirming}
            onBackButtonClick={onBackButtonClick}
          />
        )}
        {Component && (
          <Component
            {...drawerConfig?.componentProps}
            paperRef={paperRef}
            onBackButtonClick={onBackButtonClick}
            closeDrawer={closeDrawer}
            applicationId={appUuid}
          />
        )}
      </Stack>
      {drawerConfig?.showConfirmButton && (
        <Paper
          sx={{
            mt: 5,
            p: 8,
            position: 'sticky',
            bottom: '0px',
            bgcolor: 'common.white',
            zIndex: 10,
          }}
          elevation={4}
        >
          <Stack direction="row" alignItems="baseline" justifyContent="flex-start">
            <Button
              variant="contained"
              color="primary"
              onClick={onConfirmClick}
              text={drawerConfig?.confirmText ?? 'common.buttons.confirm'}
              sx={{ minWidth: '136px' }}
              loading={isConfirming}
              autoFocus
            />
            {drawerConfig?.showCancelButton && (
              <Button
                variant="outlined"
                color="inherit"
                disabled={isConfirming}
                text={drawerConfig?.cancelText ?? 'common.buttons.cancel'}
                sx={{ ml: 4, minWidth: '90px' }}
                onClick={drawerConfig?.withBackButton ? onBackButtonClick : closeDrawer}
              />
            )}
          </Stack>
        </Paper>
      )}
    </MuiDrawer>
  ) : null;
};
