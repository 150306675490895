import React from 'react';
import { Stack, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { PreviewerLanguageSelectorProps } from './PreviewerLanguageSelectorProps';

const PreviewerLanguageSelector: React.FC<PreviewerLanguageSelectorProps> = ({
  disabled,
  selectedLocale,
  handleChangeSelectedLocale,
  languages,
  wrapperSx,
}) => {
  return !disabled && languages && languages.length > 1 ? (
    <ToggleButtonGroup
      color="primary"
      value={selectedLocale}
      size="small"
      exclusive
      fullWidth
      onChange={(e, value) => {
        if (value) {
          handleChangeSelectedLocale(value, 'user');
        }
      }}
      sx={{
        ...wrapperSx,
      }}
    >
      {languages.map(({ locale, icon: Icon, shortName }, index) => (
        <ToggleButton
          key={locale}
          value={locale}
          color="primary"
          sx={{
            borderColor: ({ palette: { primary } }) => (selectedLocale === locale ? primary.main : undefined),
            borderRightColor: ({ palette: { primary } }) =>
              languages[index + 1] && selectedLocale === languages[index + 1].locale ? primary.main : undefined,
          }}
        >
          <Stack direction="row" spacing={1.5} alignItems="center">
            {Icon && <Icon fontSize="small" sx={{ width: '16px' }} />}
            {languages?.length < 4 && (
              <Typography
                variant="smallBold"
                color={selectedLocale === locale ? 'primary.main' : 'default'}
                sx={{ textTransform: 'uppercase' }}
              >
                {shortName}
              </Typography>
            )}
          </Stack>
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  ) : null;
};

export default PreviewerLanguageSelector;
