import React, { forwardRef, useMemo } from 'react';
import { AppLayoutFeatureComponentProps } from '../../../../AppPreviewerProps';
import PreviewContainer from '../../../PreviewContainer';
import PreviewFeatureTitle from '../../../PreviewFeatureTitle';
import PreviewSingleBasicWidgetSkeleton from '../PreviewSingleBasicWidgetSkeleton';
import WebWidgetContent from './components/WebWidgetContent';

const PreviewWebWidget = forwardRef<HTMLDivElement, AppLayoutFeatureComponentProps>((props, ref) => {
  const { wrapperSx, tFunction, palette, previewerState, widgetProps } = props;

  const callToAction = useMemo(() => {
    return (
      widgetProps?.preferences?.callToAction?.[previewerState.selectedLocale as string] ||
      widgetProps?.preferences?.callToAction?.en_US
    );
  }, [previewerState.selectedLocale, widgetProps?.preferences?.callToAction]);

  if (!widgetProps?.preferences?.style) {
    return <PreviewSingleBasicWidgetSkeleton {...props} />;
  }

  return (
    <PreviewContainer ref={ref} wrapperSx={wrapperSx}>
      <PreviewFeatureTitle
        tFunction={tFunction}
        title={
          widgetProps?.preferences?.title?.[previewerState.selectedLocale as string] ||
          widgetProps?.preferences?.title?.en_US ||
          'appPreviewer.labels.webWidget'
        }
        palette={palette}
      />
      <WebWidgetContent
        widgetProps={widgetProps}
        palette={palette}
        title={callToAction}
        previewerState={previewerState}
        tFunction={tFunction}
      />
    </PreviewContainer>
  );
});

export default PreviewWebWidget;
