import { createApiRequest } from '@egym/api';
import { Paginated, Uuid } from '@egym/types';
import { GymLocation, GymLocationPayload } from '@types';

const GYM_LOCATIONS_BASE_URL = 'client/applications/:applicationUuid/settings/locations';
const GYM_LOCATION_BY_ID_URL = `${GYM_LOCATIONS_BASE_URL}/:id`;
const GYM_LOCATIONS_COUNT_URL = `${GYM_LOCATIONS_BASE_URL}/count`;
const GYM_LOCATIONS_ERROR_COUNT_URL = `${GYM_LOCATIONS_BASE_URL}/errors/count`;
const GYM_LOCATION_TEMPLATE_URL = `${GYM_LOCATIONS_BASE_URL}/template`;
const GYM_LOCATION_UPLOAD_URL = `${GYM_LOCATIONS_BASE_URL}/upload`;

export const getGymLocations = createApiRequest<Paginated<GymLocation>, unknown, { applicationUuid: Uuid }>(
  GYM_LOCATIONS_BASE_URL,
);

export const getGymLocationsCount = createApiRequest<{ count: number }, unknown, { applicationUuid: Uuid }>(
  GYM_LOCATIONS_COUNT_URL,
);

export const postGymLocation = createApiRequest<GymLocation, GymLocationPayload>(GYM_LOCATIONS_BASE_URL, 'post');

export const deleteGymLocation = createApiRequest<unknown, unknown, { applicationUuid: Uuid; id: number }>(
  GYM_LOCATION_BY_ID_URL,
  'delete',
);

export const putGymLocation = createApiRequest<GymLocation, GymLocationPayload, { applicationUuid: Uuid; id: number }>(
  GYM_LOCATION_BY_ID_URL,
  'put',
);

export const getGymLocationTemplate = createApiRequest<Blob, unknown, { applicationUuid: Uuid }>(
  GYM_LOCATION_TEMPLATE_URL,
  'get',
  undefined,
  'blob',
);

export const uploadGymLocations = createApiRequest<unknown, FormData, { applicationUuid: Uuid }>(
  GYM_LOCATION_UPLOAD_URL,
  'post',
);

export const getGymLocationsErrorCount = createApiRequest<{ count: number }, unknown, { applicationUuid: Uuid }>(
  GYM_LOCATIONS_ERROR_COUNT_URL,
);
