import React, { forwardRef } from 'react';
import { AppLayoutFeatureComponentProps } from '@components';
import { WidgetViewStyle } from '@types';
import PreviewContainer from '../../../PreviewContainer';
import PreviewFeatureTitle from '../../../PreviewFeatureTitle';
import WebWidgetContent from '../PreviewWebWidget/components/WebWidgetContent';

type Props = {
  defaultName: string;
  defaultViewStyle?: WidgetViewStyle;
  defaultCallToAction: string;
  defaultImagePath?: string;
};

const PreviewBasicWidget = forwardRef<HTMLDivElement, AppLayoutFeatureComponentProps & Props>(
  (
    {
      wrapperSx,
      tFunction,
      palette,
      previewerState,
      widgetProps,
      defaultName,
      defaultViewStyle,
      defaultCallToAction,
      defaultImagePath,
    },
    ref,
  ) => {
    const callToAction =
      widgetProps?.preferences?.callToAction?.[previewerState.selectedLocale as string] ||
      tFunction(defaultCallToAction);

    return (
      <PreviewContainer ref={ref} wrapperSx={wrapperSx}>
        <PreviewFeatureTitle
          tFunction={tFunction}
          title={widgetProps?.preferences?.title?.[previewerState.selectedLocale as string] || defaultName}
          palette={palette}
        />
        <WebWidgetContent
          widgetProps={widgetProps}
          palette={palette}
          title={callToAction}
          previewerState={previewerState}
          tFunction={tFunction}
          defaultImagePath={defaultImagePath}
          defaultViewStyle={defaultViewStyle}
        />
      </PreviewContainer>
    );
  },
);

export default PreviewBasicWidget;
