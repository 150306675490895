import { forwardRef } from 'react';
import useLocationFinderWidgetPreferencesForm from './hooks/useLocationFinderWidgetPreferencesForm';
import LocationFinderWidgetPreferencesForm from './LocationFinderWidgetPreferencesForm';
import { LocationFinderWidgetPreferencesFormContainerProps } from './LocationFinderWidgetPreferencesFormProps';

const LocationFinderWidgetPreferencesFormContainer = forwardRef<any, LocationFinderWidgetPreferencesFormContainerProps>(
  (props, ref) => {
    const result = useLocationFinderWidgetPreferencesForm(props, ref);
    return <LocationFinderWidgetPreferencesForm {...props} {...result} />;
  },
);

export default LocationFinderWidgetPreferencesFormContainer;
