import React, { forwardRef, ReactNode } from 'react';
import { AppLayoutFeatureComponentProps } from '../../../../AppPreviewerProps';
import PreviewContainer from '../../../PreviewContainer';
import PreviewFeatureTitle from '../../../PreviewFeatureTitle';

const PreviewConnectedApps = forwardRef<HTMLDivElement, AppLayoutFeatureComponentProps>(
  ({ tFunction, palette, wrapperSx }, ref) => {
    return (
      <PreviewContainer ref={ref} wrapperSx={wrapperSx}>
        <PreviewFeatureTitle tFunction={tFunction} palette={palette} title="appPreviewer.labels.connectedApps" />
        <div
          style={{
            height: '95px',
            width: '100%',
            background: 'url(/images/previewerDefaultImages/features/img_connectedapps.png) no-repeat',
            backgroundSize: '100% 100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            color: 'white',
            padding: ' 8px 15px',
            boxSizing: 'border-box',
            borderRadius: '8px',
            overflow: 'hidden',
            fontSize: '18px',
            boxShadow: '0 4px 6px rgba(223, 223, 223, 0.7)',
          }}
        >
          <span
            style={{
              fontSize: '14px',
              lineHeight: '18px',
              fontWeight: 600,
              color: 'rgb(76, 74, 74)',
              maxWidth: '135px',
            }}
          >
            {tFunction('appPreviewer.labels.connectFitnessData') as ReactNode}
          </span>
        </div>
      </PreviewContainer>
    );
  },
);

export default PreviewConnectedApps;
