import React, { ReactElement, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, TypographyProps } from '@mui/material';
import { SxProps } from '@mui/system';
import { Typography } from '../../dataDisplay/Typography';
import { Box } from '../../layout/Box';
import {
  fieldStyles,
  helperTextStyles,
  labelStyles,
  viewModeFieldStyles,
  wrapperStyles,
} from './FormFieldWithLabel.styles';

export type FormFieldWithLabelProps = {
  wrapperSx?: SxProps;
  labelSx?: SxProps;
  label?: string;
  // if hiddenLabel true - hide materiul-ui integrated label and use custom one
  hiddenLabel?: boolean;
  id?: string;
  isViewMode?: boolean;
  labelTypographyProps?: TypographyProps<any, any>;
  markAsOptional?: boolean;
  children: ({
    fieldSx,
    viewModeFieldSx,
    helperTextSx,
  }: {
    fieldSx: SxProps;
    viewModeFieldSx: SxProps;
    helperTextSx: SxProps;
  }) => ReactElement;
};

type Props = FormFieldWithLabelProps;

export const FormFieldWithLabel: React.FC<Props> = React.memo(
  ({ wrapperSx, labelSx, label, hiddenLabel, id, children, labelTypographyProps, markAsOptional, isViewMode }) => {
    const { t } = useTranslation();
    const labelViewModeSx = label && isViewMode ? { height: 'inherit' } : undefined;
    const boxWrapperSx = { ...wrapperStyles, ...wrapperSx };

    return (
      <Box sx={boxWrapperSx}>
        {label && hiddenLabel && (
          <Typography
            sx={{ ...labelStyles, ...labelViewModeSx, ...labelSx }}
            component="label"
            variant={isViewMode ? 'caption' : 'body1Bold'}
            htmlFor={id}
            {...labelTypographyProps}
          >
            <Stack direction="row">
              {t(label) as ReactNode}
              {markAsOptional && !isViewMode && (
                <Typography component="span" variant="body1" ml={1}>
                  ({t('common.labels.optional') as ReactNode})
                </Typography>
              )}
            </Stack>
          </Typography>
        )}
        {children &&
          children({
            fieldSx: fieldStyles,
            viewModeFieldSx: viewModeFieldStyles,
            helperTextSx: helperTextStyles,
          })}
      </Box>
    );
  },
);
