import React, { forwardRef, ReactNode } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Icons, Paper, Stack, UploadedImage } from '@egym/ui';
import { AppLayoutFeatureComponentProps } from '@components';
import { FlagIcon } from '../../../Icons';
import PreviewContainer from '../../../PreviewContainer';

const PreviewGuestPass = forwardRef<HTMLDivElement, AppLayoutFeatureComponentProps>(
  (
    { tFunction, previewerId, palette, previewerState: { appIconBackground, appIconLink, selectedLocale }, wrapperSx },
    ref,
  ) => {
    // a little hack for <Trans />.
    // It doesn't translate string if passed tFunction with predefined 'lng' tOption
    const { t } = useTranslation('previewer');
    return (
      <PreviewContainer ref={ref} wrapperSx={wrapperSx}>
        <Paper
          sx={{
            boxShadow: '0 4px 6px rgba(223, 223, 223, 0.7)',
            borderRadius: '8px',
            background: 'white',
            p: '12px',
            minHeight: '64px',
            boxSizing: 'border-box',
          }}
        >
          <Stack direction="row" alignItems="center" sx={{ height: '100%' }}>
            <UploadedImage
              id={`previewer-guest-pass-${previewerId}`}
              key={appIconLink}
              wrapperSx={{
                minWidth: '40px',
                width: '40px',
                height: '40px',
                mr: '10px',
                boxSizing: 'border-box',
                borderRadius: '8px',
                p: '5px',
                background: appIconBackground || 'rgba(0, 0, 0, 0.1)',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              imageSx={{
                maxWidth: '100%',
                maxHeight: '100%',
                height: 'auto',
              }}
              file={{ preview: appIconLink || '' }}
              fallbackElement={<FlagIcon sx={{ width: '25px', height: '25px' }} htmlColor="white" />}
            />
            <Stack
              sx={{ height: '100%', py: '2px', boxSizing: 'border-box' }}
              justifyContent="space-between"
              alignItems="space-between"
            >
              <span style={{ fontSize: '12px', color: 'rgb(41, 41, 41)' }}>
                <Trans
                  t={t}
                  tOptions={{ lng: selectedLocale?.replace('_', '-') || 'en_US' }}
                  i18nKey="appPreviewer.labels.guestPassText"
                  components={[<span style={{ fontSize: '12px', fontWeight: 700 }} />]}
                />
              </span>
              <Stack direction="row" alignItems="center" sx={{ mt: '5px' }}>
                <span style={{ fontSize: '13px', fontWeight: 400, color: palette.primary }}>
                  {tFunction('appPreviewer.labels.guestPassActivate') as ReactNode}
                </span>
                <Icons.KeyboardArrowRight sx={{ mt: '1px' }} fontSize="small" htmlColor={palette.primary} />
              </Stack>
            </Stack>
          </Stack>
        </Paper>
      </PreviewContainer>
    );
  },
);

export default PreviewGuestPass;
