import React, { Dispatch, SetStateAction } from 'react';
import { Stack } from '@mui/material';
import { DegreePicker } from '../../../DegreePicker';
import { GradientPickerType } from '../../types';
import GradientTypeButton from './components/GradientTypeButton';

type Props = {
  selectedGradientType: GradientPickerType;
  setSelectedGradientType: Dispatch<SetStateAction<GradientPickerType>>;
  gradientDegree: number;
  setGradientDegree: Dispatch<SetStateAction<number>>;
};

const GradientTools: React.FC<Props> = ({
  selectedGradientType,
  setSelectedGradientType,
  gradientDegree,
  setGradientDegree,
}) => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={1.5}
      sx={{ px: 3, pt: 2.5, pb: 3, borderBottom: '1px solid', borderColor: 'grey.200' }}
    >
      <GradientTypeButton
        onClick={() => setSelectedGradientType(GradientPickerType.Linear)}
        type={GradientPickerType.Linear}
        selected={selectedGradientType === GradientPickerType.Linear}
      />
      <GradientTypeButton
        onClick={() => setSelectedGradientType(GradientPickerType.Radial)}
        type={GradientPickerType.Radial}
        selected={selectedGradientType === GradientPickerType.Radial}
      />
      {selectedGradientType === GradientPickerType.Linear && (
        <DegreePicker wrapperSx={{ ml: 'auto !important' }} value={gradientDegree} onChange={setGradientDegree} />
      )}
    </Stack>
  );
};

export default GradientTools;
