import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const MainTabHeaderDefaultIcon11: React.FC<SvgIconProps> = ({ htmlColor = '#FFF', sx, ...props }) => {
  return (
    <SvgIcon viewBox="0 0 48 48" {...props} sx={{ width: '48px', height: '48px', ...sx }}>
      <g fill={htmlColor} fillRule="evenodd">
        <path d="M37 8a6 6 0 0 1 6 6v22a6 6 0 0 1-6 6H11a6 6 0 0 1-6-6V14a6 6 0 0 1 6-6h26zm2 12H9v16a2 2 0 0 0 1.85 1.995L11 38h26a2 2 0 0 0 1.995-1.85L39 36V20zm-2-8H11a2 2 0 0 0-1.995 1.85L9 14v2h30v-2a2 2 0 0 0-1.85-1.995L37 12z" />
        <g opacity=".6" transform="translate(12 4)">
          <rect x="2" width="4" height="8" rx="2" />
          <rect transform="rotate(90 2.5 21)" x=".5" y="18.5" width="4" height="5" rx="2" />
          <rect transform="rotate(90 2.5 29)" x=".5" y="26.5" width="4" height="5" rx="2" />
          <rect transform="rotate(90 12 21)" x="10" y="18.5" width="4" height="5" rx="2" />
          <rect transform="rotate(90 12 29)" x="10" y="26.5" width="4" height="5" rx="2" />
          <rect transform="rotate(90 21.5 21)" x="19.5" y="18.5" width="4" height="5" rx="2" />
          <rect transform="rotate(90 21.5 29)" x="19.5" y="26.5" width="4" height="5" rx="2" />
          <rect x="18" width="4" height="8" rx="2" />
        </g>
      </g>
    </SvgIcon>
  );
};
