import React from 'react';
import GlobalLanguageSelector from './GlobalLanguageSelector';
import useGlobalLanguageSelector from './hooks';

const GlobalLanguageSelectorContainer: React.FC = () => {
  const props = useGlobalLanguageSelector();

  return <GlobalLanguageSelector {...props} />;
};

export default GlobalLanguageSelectorContainer;
