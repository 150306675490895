import { Components } from '@mui/material/styles';

const MuiInputBase: Components['MuiInputBase'] = {
  defaultProps: {
    size: 'medium',
  },
  styleOverrides: {
    sizeSmall: {
      fontSize: '14px',
    },
  },
};

export default MuiInputBase;
