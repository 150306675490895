import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const FindClassLocationIcon: React.FC<SvgIconProps> = ({ sx, htmlColor = '#4C4A4A', ...rest }) => {
  return (
    <SvgIcon viewBox="0 0 24 24" sx={{ width: '24px', height: '24px', ...sx }} {...rest}>
      <g fill="none" fillRule="evenodd">
        <g fill={htmlColor}>
          <g>
            <g>
              <path
                d="M12 2c4.97 0 9 3.98 9 8.889 0 2.807-1.63 5.45-4.297 7.897-.904.83-1.869 1.578-2.833 2.236-.582.397-1.04.678-1.312.832-.346.195-.77.195-1.116 0-.273-.154-.73-.435-1.312-.832-.964-.658-1.929-1.406-2.833-2.236C4.629 16.34 3 13.696 3 10.89 3 5.979 7.03 2 12 2zm0 1.583c-4.126 0-7.47 3.304-7.47 7.378 0 2.273 1.465 4.65 3.96 6.938.905.831 1.88 1.588 2.856 2.253.23.157.45.301.654.431.205-.13.424-.274.654-.431.975-.665 1.95-1.422 2.856-2.253 2.495-2.287 3.96-4.665 3.96-6.938 0-4.074-3.344-7.378-7.47-7.378zm0 3.973c1.243 0 2.25.994 2.25 2.222C14.25 11.005 13.243 12 12 12s-2.25-.995-2.25-2.222c0-1.228 1.007-2.222 2.25-2.222z"
                transform="translate(-110 -108) translate(106 104) translate(4 4)"
              />
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export default FindClassLocationIcon;
