import caEsCountriesCodes from 'localized-countries/data/ca_ES.json';
import deDeCountriesCodes from 'localized-countries/data/de_DE.json';
import enUsCountriesCodes from 'localized-countries/data/en_US.json';
import esEsCountriesCodes from 'localized-countries/data/es_ES.json';
import frFrCountriesCodes from 'localized-countries/data/fr_FR.json';
import nlNlCountriesCodes from 'localized-countries/data/nl_NL.json';
import svSeCountriesCodes from 'localized-countries/data/sv_SE.json';
import { Country } from '@egym/types';
import { toLocaleApiFormat } from '@egym/utils';

const codesToCountriesList = {
  en_US: enUsCountriesCodes,
  ca_ES: caEsCountriesCodes,
  es_ES: esEsCountriesCodes,
  de_DE: deDeCountriesCodes,
  fr_FR: frFrCountriesCodes,
  nl_NL: nlNlCountriesCodes,
  sv_SE: svSeCountriesCodes,
};

type ReturnProps = {
  countries: Country[];
};

const useCountries = (lang: string): ReturnProps => {
  const countries = Object.entries(codesToCountriesList[toLocaleApiFormat(lang)] ?? codesToCountriesList.en_US).map(
    ([code, name]) => ({
      code,
      name,
    }),
  ) as Country[];

  return {
    countries,
  };
};

export default useCountries;
