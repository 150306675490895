import { createApiRequest } from '@egym/api';
import { Uuid } from '@egym/types';
import { ApplicationClientDto } from '@types';

const APPLICATIONS_BASE_URL = 'client/applications';
const APPLICATION_BY_ID_URL = `${APPLICATIONS_BASE_URL}/:applicationUuid`;
const APPLICATION_SKIP_DEMO_URL = `${APPLICATIONS_BASE_URL}/:applicationUuid/skipDemo`;

export const getClientApplication = createApiRequest<ApplicationClientDto, unknown, { applicationUuid: Uuid }>(
  APPLICATION_BY_ID_URL,
);

export const postSkipDemoApplication = createApiRequest<ApplicationClientDto, unknown, { applicationUuid: Uuid }>(
  APPLICATION_SKIP_DEMO_URL,
  'post',
);
