// https://next.material-ui.com/components/radio-buttons/#heading-radio-group
import React, { ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FormHelperText, Stack, Typography, TypographyProps } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import { RadioProps } from '@mui/material/Radio/Radio';
import MuiRadioGroup, { RadioGroupProps } from '@mui/material/RadioGroup';
import { SxProps } from '@mui/system';
import { Option } from '@egym/types';
import { ConditionalRender } from '../../utils';
import { TextFieldProps } from '../TextField';

export type Props = {
  wrapperSx?: SxProps;
  optionsWrapperSx?: SxProps;
  viewModeSx?: SxProps;
  formControlLabelWrapperSx?: SxProps;
  isViewMode?: boolean;
  helperText?: ReactNode;
  FormHelperTextProps?: TextFieldProps['FormHelperTextProps'];
  error?: boolean;
  options: (Option & Partial<RadioProps>)[];
  hiddenLabel?: boolean;
  label?: string;
  disabled?: boolean;
  optionsSpacing?: number;
  optionLabelTypographyProps?: Partial<TypographyProps>;
  optionDescriptionTypographyProps?: Partial<TypographyProps>;
} & RadioGroupProps;

export const RadioGroup: React.FC<Props> = ({
  wrapperSx,
  optionsWrapperSx,
  viewModeSx,
  formControlLabelWrapperSx,
  isViewMode,
  helperText,
  error,
  FormHelperTextProps,
  options,
  value,
  onChange,
  name,
  row,
  hiddenLabel,
  label,
  defaultValue,
  disabled,
  optionsSpacing,
  optionLabelTypographyProps,
  optionDescriptionTypographyProps,
  onBlur,
}) => {
  const { t } = useTranslation();
  const selectedOption = useMemo<Option | undefined>(() => {
    return isViewMode ? options.find(option => option.value === value) : undefined;
  }, [options, isViewMode, value]);

  return (
    <ConditionalRender condition={isViewMode}>
      <Typography sx={viewModeSx} variant="body1">
        {selectedOption ? (t(selectedOption?.label) as ReactNode) : '-'}
      </Typography>
      <FormControl component="fieldset" sx={wrapperSx} error={error} disabled={disabled}>
        {!hiddenLabel && label && <FormLabel component="legend">{t(label) as ReactNode}</FormLabel>}
        <MuiRadioGroup
          row={row}
          defaultValue={defaultValue}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          name={name}
        >
          <Stack direction={row ? 'row' : 'column'} spacing={optionsSpacing || 1} sx={optionsWrapperSx}>
            {options.map(
              ({ value: optionValue, label: optionLabel, description: optionDescription, ...restOption }) => {
                return (
                  <FormControlLabel
                    sx={{ m: 0, ...formControlLabelWrapperSx }}
                    key={optionValue}
                    value={optionValue}
                    disableTypography
                    disabled={disabled}
                    label={
                      <Stack>
                        <Typography variant="body2" component="span" {...optionLabelTypographyProps}>
                          {t(optionLabel) as ReactNode}
                        </Typography>
                        {optionDescription && (
                          <Typography variant="body2Caption" component="span" {...optionDescriptionTypographyProps}>
                            {t(optionDescription) as ReactNode}
                          </Typography>
                        )}
                      </Stack>
                    }
                    control={<Radio disabled={isViewMode} color="primary" {...restOption} />}
                  />
                );
              },
            )}
          </Stack>
        </MuiRadioGroup>
        {helperText && (
          <FormHelperText error={error} {...FormHelperTextProps}>
            {helperText}
          </FormHelperText>
        )}
      </FormControl>
    </ConditionalRender>
  );
};
