import React from 'react';
import { CustomPickerProps } from 'react-color';
import { SxProps } from '@mui/system';
import { Alpha as ReactColorAlpha } from 'react-color/lib/components/common';
import { Stack } from '@egym/ui';
import PointerSmall from '../PointerSmall';

type Props = { wrapperSx?: SxProps } & CustomPickerProps<ReactColorAlpha>;

const Alpha: React.FC<Props> = ({ wrapperSx, ...rest }) => {
  return (
    <Stack sx={{ position: 'relative', height: '12px', width: '100%', ...wrapperSx }}>
      <ReactColorAlpha
        // @ts-ignore
        pointer={PointerSmall}
        {...rest}
      />
    </Stack>
  );
};

export default Alpha;
