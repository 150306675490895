import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const MoveToIcon: React.FC<SvgIconProps> = ({ sx, htmlColor = '#878787', ...props }) => {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props} sx={{ width: '24px', height: '24px', ...sx }}>
      <g id="icon/MoveTo" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Group-78-Copy">
          <g>
            <g id="Group-48">
              <rect id="Rectangle" x="0" y="0" width="24" height="24" />
              <path
                d="M6,11 L6,18 L4,18 L4,11 C4,9.9456382 4.81587779,9.08183488 5.85073766,9.00548574 L6,9 L16.67,9 L14.09,6.41 L15.5,5 L20.5,10 L15.5,15 L14.09,13.59 L16.67,11 L6,11 Z"
                id="Combined-Shape"
                fill={htmlColor}
                fillRule="nonzero"
              />
            </g>
            <g filter="url(#filter-2)" id="Group-77">
              <g transform="translate(4.000000, 5.000000)" />
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};
