import React, { PropsWithChildren } from 'react';

type Props = {
  value: any;
} & PropsWithChildren;

// @ts-ignore
export const SwitchChildren: React.FC<Props> = ({ value, children }) => {
  const childrenArray = React.Children.toArray(children);
  if (!childrenArray.length) return null;
  // filter out only children with a matching prop
  return (
    childrenArray.find(child => {
      // @ts-ignore
      return child.props.value === value;
    }) || null
  );
};
