import React, { useCallback, useMemo, useState } from 'react';
import EasyCropper, { CropperProps } from 'react-easy-crop';
import { SxProps } from '@mui/system';
import { Area, Point } from 'react-easy-crop/types';
import { Option } from '@egym/types';
import { getCroppedImg } from '@egym/utils';
import { Icons } from '../../icons';
import { Box, Grid } from '../../layout';
import { Paper } from '../../surfaces';
import { ConditionalRender } from '../../utils';
import { Button } from '../Button';
import { IconButton } from '../IconButton';
import { RadioGroup } from '../RadioGroup';
import { Slider } from '../Slider';
import { controlsStyles, cropContainerStyles, headerToolsStyles } from './Cropper.styles';

export const defaultAspectRatioOptions = [
  {
    label: '4 / 3',
    value: 4 / 3,
  },
  {
    label: '16 / 9',
    value: Number((16 / 9).toFixed(2)),
  },
];

type Props = Partial<CropperProps> & {
  file: File & {
    preview: string;
    name: string;
  };
  onCropConfirm: (file: any) => void;
  onCropCancel: () => void;
  ratioOptions?: Option[];
  showButtons?: boolean;
  wrapperSx?: SxProps;
  cropperWindowSx?: SxProps;
  buttonsWrapperSx?: SxProps;
};

export const Cropper: React.FC<Props> = ({
  file,
  crop: initialCrop = { x: 0, y: 0 },
  aspect: initialAspect = defaultAspectRatioOptions[0].value,
  onCropConfirm,
  onCropCancel,
  minZoom = 1,
  maxZoom = 4,
  ratioOptions,
  showButtons = true,
  wrapperSx,
  cropperWindowSx,
  buttonsWrapperSx,
}) => {
  const zoomStep = 0.1;
  const [crop, setCrop] = useState<Point>(initialCrop);
  const [zoom, setZoom] = useState(minZoom);
  const [aspect, setAspect] = useState(initialAspect);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState({ width: 0, height: 0, x: 0, y: 0 });

  const aspectRatioOptions = useMemo(
    () => (ratioOptions?.length ? ratioOptions : defaultAspectRatioOptions),
    [ratioOptions],
  );

  const onChangeAspectRatio = useCallback(e => {
    setAspect(Number(e.target.value));
  }, []);

  const onZoomDecr = useCallback(() => {
    setZoom(prev => (prev <= minZoom ? prev : prev - zoomStep));
  }, [minZoom]);

  const onZoomIncr = useCallback(() => {
    setZoom(prev => (prev >= maxZoom ? prev : prev + zoomStep));
  }, [maxZoom]);

  const onCropComplete = useCallback(async (croppedArea: Area, newCroppedAreaPixels: Area) => {
    setCroppedAreaPixels(newCroppedAreaPixels);
  }, []);

  const handleCropCancel = useCallback(() => {
    setCroppedAreaPixels({ width: 0, height: 0, x: 0, y: 0 });

    onCropCancel();
  }, [onCropCancel]);

  const handleCropConfirm = useCallback(async () => {
    try {
      // @ts-ignore
      const croppedImage = await getCroppedImg({ imageSrc: file.preview }, croppedAreaPixels);

      onCropConfirm(croppedImage);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('crop error', e);
    }
  }, [croppedAreaPixels, file, onCropConfirm]);

  return (
    <Box sx={wrapperSx}>
      <Paper sx={cropperWindowSx}>
        <ConditionalRender condition={aspectRatioOptions.length > 1}>
          <Box sx={headerToolsStyles}>
            <RadioGroup
              row
              name="aspectRatio"
              value={aspect}
              // @ts-ignore
              options={aspectRatioOptions}
              onChange={onChangeAspectRatio}
            />
          </Box>
        </ConditionalRender>
        <Box sx={cropContainerStyles}>
          <EasyCropper
            image={file.preview}
            crop={crop}
            zoom={zoom}
            minZoom={minZoom}
            maxZoom={maxZoom}
            onCropChange={setCrop}
            aspect={aspect}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
          />
        </Box>
        <Grid container spacing={2} alignItems="center" sx={controlsStyles}>
          <Grid item>
            <IconButton aria-label="zoomout" onClick={onZoomDecr} size="small">
              <Icons.Remove />
            </IconButton>
          </Grid>
          <Grid item xs>
            <Slider
              value={zoom}
              min={minZoom}
              max={maxZoom}
              step={zoomStep}
              aria-labelledby="Zoom"
              onChange={(e, changedZoom) => setZoom(Number(changedZoom))}
              sx={{ mt: 1 }}
            />
          </Grid>
          <Grid item>
            <IconButton aria-label="zoomin" onClick={onZoomIncr} size="small">
              <Icons.Add />
            </IconButton>
          </Grid>
        </Grid>
      </Paper>

      {showButtons && (
        <Grid container spacing={2} alignItems="center" sx={buttonsWrapperSx}>
          <Grid item xs={12} display="flex" justifyContent="flex-end">
            <Button
              text="common.buttons.cancel"
              variant="contained"
              color="secondary"
              onClick={handleCropCancel}
              sx={{ mr: 5 }}
            />
            <Button text="common.buttons.confirm" variant="contained" color="primary" onClick={handleCropConfirm} />
          </Grid>
        </Grid>
      )}
    </Box>
  );
};
