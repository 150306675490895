import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from '@egym/ui';
import { getClientApplication, postSkipDemoApplication } from '@api';
import { getAvailableAppLayouts, getIsAppleDevProgramAvailable } from '@helpers';
import { ApplicationStatus, ApplicationPackage } from '@types';

const useClientApplication = appUuid => {
  const { t } = useTranslation();
  const { openSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const clientApplicationQueryName = ['clientApplications', appUuid];

  const applicationResponse = useQuery(
    clientApplicationQueryName,
    async () => getClientApplication({ urlParams: { applicationUuid: appUuid } }),
    {
      enabled: Boolean(appUuid),
      refetchOnMount: false,
      keepPreviousData: true,
      select: result => result.data,
      onError: () => {
        openSnackbar(t('common.netError'), { severity: 'error' });
      },
    },
  );

  const availableLayouts = useMemo(() => {
    if (!applicationResponse.isSuccess) return [];

    return getAvailableAppLayouts(applicationResponse.data.appPackage);
  }, [applicationResponse.isSuccess, applicationResponse.data]);

  const markSkipDemo = useMutation(async () => postSkipDemoApplication({ urlParams: { applicationUuid: appUuid } }), {
    onSuccess: response => {
      queryClient.setQueryData(clientApplicationQueryName, (prevData: any) => ({
        ...prevData,
        data: response.data,
      }));
    },
    onError: () => {
      openSnackbar(t('common.netError'), { severity: 'error' });
    },
  });

  const isLoadingApp = useMemo(
    () => !applicationResponse.isFetched && !applicationResponse.isPreviousData,
    [applicationResponse.isFetched, applicationResponse.isPreviousData],
  );

  const isAppleDevProgramAvailable = useMemo(() => {
    if (!applicationResponse.isSuccess) return false;
    return getIsAppleDevProgramAvailable(applicationResponse.data.appType);
  }, [applicationResponse.isSuccess, applicationResponse.data]);

  const isArchived = useMemo(
    () => applicationResponse.isSuccess && applicationResponse.data?.status === ApplicationStatus.Archived,
    [applicationResponse.isSuccess, applicationResponse.data?.status],
  );

  const demo = useMemo(
    () => applicationResponse.isSuccess && applicationResponse.data?.demo,
    [applicationResponse.isSuccess, applicationResponse.data?.demo],
  );

  const isProPackage = useMemo(
    () => applicationResponse.isSuccess && applicationResponse.data?.appPackage === ApplicationPackage.Pro,
    [applicationResponse.isSuccess, applicationResponse.data?.appPackage],
  );

  return {
    applicationResponse,
    availableLayouts,
    markSkipDemo,
    isLoadingApp,
    isAppleDevProgramAvailable,
    isArchived,
    demo,
    isProPackage,
  };
};

export default useClientApplication;
