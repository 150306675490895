import React, { useRef, useEffect, useState, ReactNode } from 'react';
import { Tooltip, TooltipProps } from '@mui/material';

const OverflowTip: React.FC<Omit<TooltipProps, 'title' | 'children'> & { children?: ReactNode }> = ({
  children,
  ...props
}) => {
  const [hoverStatus, setHover] = useState(false);
  const textElementRef = useRef<HTMLDivElement>();

  const compareSize = () => {
    // @ts-ignore
    const compare = textElementRef.current.scrollWidth > textElementRef.current.clientWidth;
    setHover(compare);
  };

  useEffect(() => {
    compareSize();
    window.addEventListener('resize', compareSize);
  }, [children]);

  useEffect(
    () => () => {
      window.removeEventListener('resize', compareSize);
    },
    [children],
  );

  return (
    <Tooltip {...props} title={<div>{children}</div>} disableHoverListener={!hoverStatus}>
      <div
        // @ts-ignore
        ref={textElementRef}
        style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {children}
      </div>
    </Tooltip>
  );
};

export default OverflowTip;
