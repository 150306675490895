import React from 'react';
import { ProgressBar } from '@components';
import { AppSettingsProgressBarContainerProps } from './AppSettingsProgressBarProps';
import useAppSettingsProgressBar from './hooks/useAppSettingsProgressBar/useAppSettingsProgressBar';

const AppSettingsProgressBarContainer: React.FC<AppSettingsProgressBarContainerProps> = props => {
  const appSettingsProgressBarProps = useAppSettingsProgressBar();

  return <ProgressBar {...props} {...appSettingsProgressBarProps} />;
};

export default AppSettingsProgressBarContainer;
