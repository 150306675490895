export const trainingTemplatesMock = [
  {
    id: 0,
    image: '/images/previewerDefaultImages/features/training_template_2.png',
    name: 'Engine Fire Workout',
    kcalCount: 353,
    exercisesCount: 15,
  },
  {
    id: 1,
    image: '/images/previewerDefaultImages/features/training_template_3.png',
    name: 'Quick Morning Workout for Fat Burning',
    kcalCount: 312,
    exercisesCount: 12,
  },
  {
    id: 2,
    image: '/images/previewerDefaultImages/features/training_template_1.png',
    name: 'Morning Quick Workout',
    kcalCount: 271,
    exercisesCount: 11,
  },
];
